import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { MassCreationCargoService } from './mass-creation-cargo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/core/interfaces/user';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { AddressColumnCargo, ExcelField, FieldsColumnCargo } from 'src/app/core/interfaces/fieldsColumnCargo';
import { CargoFeature } from 'src/app/core/interfaces/cargoFeature';
import { Utils } from 'src/app/core/resources/utils';
import { ReadXLSX } from 'src/app/core/resources/readXLSX';
import { Company } from 'src/app/core/interfaces/company';
import { FileComponent } from 'src/app/shared/files/file.component';
import { runInDebugContext } from 'vm';
import { SelectCompanyComponent } from 'src/app/shared/select-company/select-company.component';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { RoutingMessages } from 'src/app/core/messages/routing-messages.enum';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';

@Component({
  selector: 'app-mass-creation-cargo',
  templateUrl: './mass-creation-cargo.component.html',
  styleUrls: ['./mass-creation-cargo.component.scss'],
  providers: [AuthService, Global]
})

export class MassCreationCargoComponent {

  @ViewChildren(FileComponent) fileComponent: QueryList<FileComponent>;
  massCreationCargoForm: FormGroup = new FormGroup({
    firstRow: new FormControl(3),
    finalRow: new FormControl(5),
    manifest: new FormControl(true, Validators.required),
  });
  fileControl = new FormControl(null, Validators.required);
  sheetNameControl = new FormControl('', Validators.required);

  jsonXLSX: any;
  sheets: Array<{ key: string, value: { key: string, value: string } }> = [];

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private spinner: NgxSpinnerService,
    private massCreationCargoService: MassCreationCargoService,
    private snackBarService: SnackBarService,
    private router: Router,
    public utils: Utils,
    private readXLSX: ReadXLSX,
    public dialog: MatDialog,
    private authService: AuthService,
  ) { }

  downloadFile() {
    const fileName = 'formato_masivo_base.xlsx';
    const filePath = `assets/xlsx/${fileName}`;

    const link = document.createElement('a');
    link.href = filePath;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handleFileInput(file: File) {
    if (!file) return;
    const extension = file.name.split('.').reverse()[0];
    if (extension.toLowerCase() !== 'xlsx')
      return this.snackBarService.openSnackBar("El archivo debe tener formato excel", undefined, 'error');
    this.jsonXLSX = null;
    this.sheetNameControl.setValue(null);
    this.readXLSX.readFile(
      [file],
      this.massCreationCargoForm.get('firstRow').value
    )
      .then((result: { titles, jsonXLSX, sheets, sheetName }) => {
        if (result.sheetName)
          this.sheetNameControl.setValue(result.sheetName);
        if (result.sheets)
          this.sheets = result.sheets;
        this.sheets.forEach(sheet => {
          result.jsonXLSX[sheet.key] = result.jsonXLSX[sheet.key].filter(row => row.some(field => field && field !== '-'));
        })
        this.jsonXLSX = result.jsonXLSX;
      })
      .catch(() => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(RoutingMessages.INVALID_FILE, undefined, 'error');
      });

  }

  onSubmit() {
    if (this.utils.errorMessagesCustomized(this.fileControl, 'archivo')) return;
    if (this.utils.errorMessagesCustomized(this.sheetNameControl, 'nombre de archivo')) return;
    this.massCreationCargoForm.get('finalRow').setValue(this.jsonXLSX[this.sheetNameControl.value.key].length - 1);
    if (this.massCreationCargoForm.get('finalRow').value < this.massCreationCargoForm.get('firstRow').value)
      return this.snackBarService.openSnackBar('La hoja de excel no contiene suficientes filas', undefined, 'alert');

    const fields: FieldsColumnCargo = this.massCreationCargoForm.value;
    const formData = new FormData();
    formData.append('fields', JSON.stringify(this.utils.cleanEmptyValues(fields)));
    formData.append('file', this.fileControl.value.fileData.file);
    formData.append('sheet', this.sheetNameControl.value.value);

    this.spinner.show();
    this.massCreationCargoService.massCreationCargo(formData).subscribe(
      (success) => {
        this.spinner.hide();
        this.showSuccessDialog();
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(error && error.error && error.error.message
          ? error.error.message
          : 'Se generó un error intentando crear los servicios', undefined, 'error');
      }
    );
  }

  private showSuccessDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: `Solicitudes de servicio en proceso`,
      icon: true,
      description: `Sus solicitudes de servicio se están procesando, se le notificará al correo ${this.authService.getUserSession().email} cuando estén listas`,
      hideBtnCancel: false,
      hideBtnConfirm: true,
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => this.router.navigate(['/cargo/list/request']));
  }

  get showSheetSelector(): boolean {
    return (this.sheets && this.sheets.length && this.sheets.length > 1) &&
      !!this.fileControl.value;
  }
}

import { Injectable } from "@angular/core";
import { SnackBarService } from "../services/snackBar.service";
import { Rndc } from "../messages/rndc.enum";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { CargoDetailComponent } from "src/app/modules/cargo/cargo-detail/cargo-detail.component";
import { environment } from "src/environments/environment";
import { Endpoints } from "../resources/endpoints";
import { HttpClient } from "@angular/common/http";
import { Vehicle } from "../interfaces/vehicle";
import { DetailVehicleComponent } from "src/app/modules/administration/vehicles/detail-vehicle/detail-vehicle.component";
import { User } from "../interfaces/user";
import { Cargo } from "../interfaces/cargo";
import { FormVehicleComponent } from "src/app/modules/administration/vehicles/form-vehicle/form-vehicle.component";
import { Driver } from "../models/driver";
import { ModalEnum } from "../enums/modal.enum";
import { CargoDriverDocumentidDialogComponent } from "src/app/modules/cargo/cargo-driver-documentid-dialog/cargo-driver-documentid-dialog.component";
import { Utils } from "../resources/utils";


@Injectable({
    providedIn: 'root',
})
export class RndcErrorSolver {
    constructor(
        private snackbarService: SnackBarService,
        private matDialog: MatDialog,
        private endpoints: Endpoints,
        private http: HttpClient,
        private utils: Utils
    ) { }

    public getErrorCode(error: string): string {
        try {
            return error.split(':')[0].slice(6);
        } catch (err) {
            return '';
        }
    }

    public noControlledError(driver: string, cargo: Cargo, vehicle: Vehicle): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.http.get(`${environment.urlServerTeclogi}${this.endpoints.urlDetailUserByDocument}${driver}`)
                .subscribe((user: User) => {
                    //No se encontró el usuario o el error no es procedente de falta de información
                    if (!user || !user.information || (user.information && user.information.documentTypeId && user.information.documentTypeName &&
                        vehicle.driver && vehicle.driver.documentTypeId && vehicle.driver.documentTypeName)) {
                        reject(true);
                        return;
                    }
                    const config = new MatDialogConfig();
                    config.data = { vehicle, driver: user };
                    config.maxHeight = ModalEnum.MAX_HEIGHT;
                    config.maxWidth = ModalEnum.MAX_WIDTH;
                    config.width = ModalEnum.SMALL_WIDTH;
                    config.autoFocus = false;
                    this.matDialog
                        .open(CargoDriverDocumentidDialogComponent, config)
                        .afterClosed()
                        .subscribe((result) => {
                            if (!result || !result.state) {
                                reject(false);
                                return;
                            }
                            this.userMinistry(cargo.id).subscribe(() => {
                                this.vehicleMinistry(cargo.id).subscribe(() => {
                                    this.snackbarService.openSnackBar("Se aplicó la solución, por favor verifique el Manifiesto nuevamente");
                                    resolve(true);
                                }, (error) => {
                                    if (error && error.error && error.error.message) this.snackbarService.openSnackBar(error.error.message, undefined, 'error');
                                    else this.snackbarService.openSnackBar("Ocurrió un error al intentar aplicar la solución automática", undefined, 'error');
                                    reject(false);
                                });
                            }, (error) => {
                                if (error && error.error && error.error.message) this.snackbarService.openSnackBar(error.error.message, undefined, 'error');
                                else this.snackbarService.openSnackBar("Ocurrió un error al intentar aplicar la solución automática", undefined, 'error');
                                reject(false);
                            })
                        })
                }, (error) => {
                    reject(true);
                })
        });
    }

    public MAN220(vehicle: Vehicle, cargo: Cargo): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const config = new MatDialogConfig();
            config.data = {
                title: "Valores del peso excedidos",
                descriptionHTML: `
                <p>Lea atentamente las instrucciones para solucionar este problema:</p>
                <ol>
                    <li>A continuación se desplegará el formulario del vehículo.</li>
                    <li>Verifique que el peso del trailer corresponde al peso registrado en la tarjeta de propiedad del trailer.</li>
                    <li>Luego, verifique en el detalle del servicio que el peso de remesa reportado sea el correcto (Tenga en cuenta la resolución 2460 del 2022).</li>
                    <li>Si una vez verificados estos parámetros el error se sigue presentando, <b>Y SOLO EN ESTE CASO</b> comuníquese con soporte técnico.</li>
                </ol>
                ¿Desea intentar resolver el error automáticamente?
                `,

            }
            this.matDialog
                .open(DialogComponent, config)
                .afterClosed()
                .subscribe((result) => {
                    if (result && this.utils.isDefined(result.state) && !result.state) {
                        reject(true);
                        return;
                    }
                    const config = new MatDialogConfig();
                    config.data = { vehicle, close: true };
                    config.maxHeight = ModalEnum.MAX_HEIGHT;
                    config.width = ModalEnum.LARGE_WIDTH;
                    config.maxWidth = ModalEnum.MAX_WIDTH;
                    this.matDialog
                        .open(DetailVehicleComponent, config)
                        .afterClosed()
                        .subscribe(() => {
                            const config = new MatDialogConfig();
                            config.data = { cargo };
                            config.maxHeight = ModalEnum.MAX_HEIGHT;
                            config.width = ModalEnum.LARGE_WIDTH;
                            config.maxWidth = ModalEnum.MAX_WIDTH;
                            this.matDialog
                                .open(CargoDetailComponent, config)
                                .afterClosed()
                                .subscribe(() => {
                                    resolve(true);
                                });
                        });
                });
        });
    }

    public MAN245(driver: string, cargo: Cargo, vehicle: Vehicle): Promise<User> {
        // Licencia de conducción
        return new Promise<User>((resolve, reject) => {
            this.http
                .get(`${environment.urlServerTeclogi}${this.endpoints.urlDetailUserByDocument}${driver}`)
                .subscribe((user: User) => {
                    if (!user || !user.driverLicenseCategory) {
                        reject(true);
                        return;
                    }
                    let invalid_licenses = []
                    for (const licenseCategory of user.driverLicenseCategory) {
                        if (!licenseCategory.active) {
                            invalid_licenses.push(invalid_licenses);
                        }
                    }

                    const config = new MatDialogConfig();
                    config.data = {
                        title: "Licencia de conducir inválida o categoría insuficiente",
                        descriptionHTML: `
                        <p>Lea atentamente las instrucciones para solucionar este problema:</p>
                        <ol>
                            <li>Verifique que el usuario tiene la licencia de conducción y la categoría necesaria en el RUNT.</li>
                            <li>Revise que en el sistema la licencia de conducción corresponda a los registros en el RUNT, de no ser así, valide otro estudio de seguridad. (Podría requerir llenar de nuevo la hoja de vida en algunos casos).</li>
                            <li>Si una vez verificados estos parámetros el error se sigue presentando, <b>Y SOLO EN ESTE CASO</b> comuníquese con soporte técnico.</li>                         
                        </ol>
                        ¿Desea intentar resolver el error automáticamente?
                        `,

                    }
                    this.matDialog
                        .open(DialogComponent, config)
                        .afterClosed()
                        .subscribe((result) => {
                            if (!result.state) {
                                reject(true);
                                return;
                            }
                            const config = new MatDialogConfig();
                            config.data = { vehicle }
                            config.maxHeight = ModalEnum.MAX_HEIGHT;
                            config.height = ModalEnum.MEDIUM_HEIGHT
                            config.width = ModalEnum.LARGE_WIDTH;
                            config.maxWidth = ModalEnum.MAX_WIDTH;
                            config.autoFocus = false;
                            this.matDialog
                                .open(FormVehicleComponent, config)
                                .afterClosed()
                                .subscribe(() => {
                                    this.userMinistry(cargo.id).subscribe(() => {
                                        this.http
                                            .get(`${environment.urlServerTeclogi}${this.endpoints.urlDetailUserByDocument}${driver}`)
                                            .subscribe((d) => {
                                                this.snackbarService.openSnackBar("Se aplicó la solución, por favor verifique el Manifiesto nuevamente");
                                                resolve(d);
                                            }, (error) => {
                                                if (error && error.error && error.error.message) this.snackbarService.openSnackBar(error.error.message, undefined, 'error');
                                                else this.snackbarService.openSnackBar("Ocurrió un error al intentar aplicar la solución automática", undefined, 'error');
                                                reject(false);
                                            });
                                    })
                                });

                        })
                }, (error) => {
                    reject(true);
                })
        });
    }

    public RTU140(cargo: Cargo, vehicle: Vehicle): Promise<Boolean> {
        return new Promise<Boolean>((resolve, reject) => {
            const config = new MatDialogConfig();
            config.data = {
                title: "La información del vehículo es incorrecta",
                descriptionHTML: `
                <p>Lea atentamente las instrucciones para solucionar este problema:</p>
                <ol>
                    <li>Verifique la información del conductor, administrador y propietario del vehículo.</li>
                    <li>Verifique la información básica del vehículo y del trailer (de ser el caso)</li>
                    <li>Verifique la documentación del vehículo, la validez de la RTM y SOAT.</li>
                    <li>Si una vez verificados el error se sigue presentando, <b>Y SOLO EN ESTE CASO</b> comuníquese con soporte técnico.</li>
                </ol>
                ¿Desea intentar resolver el error automáticamente?
                `,
                labelButton1: 'Cancelar',
                labelButton2: 'Validar información',
                hideBtnCancel: true,
                hideBtnConfirm: true,
            };
            this.matDialog
                .open(DialogComponent, config)
                .afterClosed()
                .subscribe((result) => {
                    if (!result || !result.state || !result.refuse
                        || result.refuse !== "Validar información") {
                        reject(true);
                        return;
                    }
                    const config = new MatDialogConfig();
                    config.data = { vehicle: vehicle, close: true };
                    config.maxHeight = ModalEnum.MAX_HEIGHT;
                    config.width = ModalEnum.LARGE_WIDTH;
                    config.maxWidth = ModalEnum.MAX_WIDTH;
                    config.autoFocus = false;
                    this.matDialog
                        .open(DetailVehicleComponent, config)
                        .afterClosed()
                        .subscribe(() => {
                            resolve(true);
                        });
                });
        });
    }

    private REM240(): void {
        // Empresa aseguradora no es correcta.
    }

    public VEH280(vehicle: Vehicle, cargo: Cargo): Promise<Driver> {
        // Los datos del propietario no coinciden
        return new Promise<Driver>((resolve, reject) => {
            const config = new MatDialogConfig();
            config.data = {
                title: "Los datos del propietario no coinciden",
                descriptionHTML: `
                <p>Lea atentamente las instrucciones para solucionar este problema:</p>
                <ol>
                    <li>Fíjese en la tarjeta de propiedad verifique que el número de cédula coincide exactamente.</li>
                    <li>Verifique que la fecha de expedición corresponde con la fecha de expedición en el soporte del documento.</li>
                    <li>Si es empresa verifique que el tipo de documento esté en NIT y si es persona que corresponda.</li>
                    <li>Si una vez verificados estos parámetros el error se sigue presentando, <b>Y SOLO EN ESTE CASO</b> comuníquese con soporte técnico.</li>
                </ol>
                ¿Desea intentar resolver el error automáticamente?
                `,

            }
            this.matDialog
                .open(DialogComponent, config)
                .afterClosed()
                .subscribe((result) => {
                    if (!result.state) {
                        reject(true);
                        return;
                    }
                    const config = new MatDialogConfig();
                    config.data = { vehicle, close: true };
                    config.maxHeight = ModalEnum.MAX_HEIGHT;
                    config.width = ModalEnum.LARGE_WIDTH;
                    config.maxWidth = ModalEnum.MAX_WIDTH;
                    config.autoFocus = false;
                    this.matDialog
                        .open(FormVehicleComponent, config)
                        .afterClosed()
                        .subscribe(() => {
                            this.userMinistry(cargo.id).subscribe(
                                (u: Driver) => {
                                    this.vehicleMinistry(cargo.id).subscribe(
                                        () => {
                                            this.snackbarService.openSnackBar("Se aplicó la solución, por favor verifique el Manifiesto nuevamente");
                                            resolve(u);
                                        }
                                    );
                                }, (error) => {
                                    if (error && error.error && error.error.message) this.snackbarService.openSnackBar(error.error.message, undefined, 'error');
                                    else this.snackbarService.openSnackBar("Ocurrió un error al intentar aplicar la solución automática", undefined, 'error');
                                    reject(false);
                                }
                            )
                        });
                });
        });
    }

    private TER050(vehicle: Vehicle, cargo: Cargo): Promise<Driver> {
        // Apellido del tercero
        return new Promise<Driver>((resolve, reject) => {
            const config = new MatDialogConfig();
            config.data = {
                title: "Los apellidos del tercero son incorrectos",
                descriptionHTML: `
                <p>Lea atentamente las instrucciones para solucinar este problema:</p>
                <ol>
                    <li>Fíjese en la tarjeta de propiedad verifique que el número de cédula coincide exactamente.</li>
                    <li>Verifique que la fecha de expedición corresponde con la fecha de expedición en el soporte del documento.</li>
                    <li>Si es empresa verifique que el tipo de documento esté en NIT y si es persona que corresponda.</li>
                    <li>Verifique el nombre y apellido completo tanto del conductor como del propietario.</li>
                    <li>Si una vez verificados estos parámetros el error se sigue presentando, <b>Y SOLO EN ESTE CASO</b> comuníquese con soporte técnico.</li>
                </ol>
                ¿Desea intentar resolver el error automáticamente?
                `,

            }
            this.matDialog
                .open(DialogComponent, config)
                .afterClosed()
                .subscribe((result) => {
                    if (!result.state) {
                        reject(true);
                        return;
                    }
                    const config = new MatDialogConfig();
                    config.data = { vehicle, close: true };
                    config.maxHeight = ModalEnum.MAX_HEIGHT;
                    config.width = ModalEnum.LARGE_WIDTH;
                    config.maxWidth = ModalEnum.MAX_WIDTH;
                    config.autoFocus = false;
                    this.matDialog
                        .open(FormVehicleComponent, config)
                        .afterClosed()
                        .subscribe(() => {
                            this.userMinistry(cargo.id).subscribe(
                                (u: Driver) => {
                                    this.snackbarService.openSnackBar("Se aplicó la solución, por favor verifique el Manifiesto nuevamente");
                                    resolve(u);
                                }, (error) => {
                                    if (error && error.error && error.error.message) this.snackbarService.openSnackBar(error.error.message, undefined, 'error');
                                    else this.snackbarService.openSnackBar("Ocurrió un error al intentar aplicar la solución automática", undefined, 'error');
                                    reject(false);
                                }
                            )
                        });
                });
        });
    }


    private vehicleMinistry(cargoId: string) {
        return this.http.post<Vehicle>(`${environment.urlServerTeclogi}${this.endpoints.fixVehicleMinistry}?cargoId=${cargoId}`, false);
    }

    private userMinistry(cargoId: string) {
        return this.http.post<User | Driver>(`${environment.urlServerTeclogi}${this.endpoints.fixUserMinistry}?cargoId=${cargoId}`, false);
    }
}

<div id="mainManualCreationCargo">

  <div class="col-md-12 mb-md-0 mb-12">

    <div class="row">

      <div class="d-flex flex-column flex-lg-row-reverse w-100">
        <div class="mw-100">
          <div class="container-options-itinerary">
            <div class="container-edit-itinerary" (click)="editItinerary()">
              <div class="title-edit-itinerary">
                Editar información
                <figure class="container-icon-edit">
                  <img src="/assets/svg/icons/icon-edit.png" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
        <ng-scrollbar #scrollable track="horizontal" pointerEventsMethod="scrollbar" class="w-100">
          <div class="container-itinerary-information">
            <div class="information-wrapper">
              <div class="title-itinerary">
                Origen
              </div>
              <div class="value-itinerary">
                {{manualCreationCargoService.cargoForm.value.cargoFeature.uploadDownload.origin.name}}
              </div>
              <div class="date-itinerary">
                {{manualCreationCargoService.cargoForm.value.dateLoad | dateFormat:'only-date'}}
              </div>
            </div>
            <div class="information-wrapper"
              *ngFor=" let destination of manualCreationCargoService.cargoForm.value.cargoFeature.uploadDownload.destination; let indexDestination=index;">
              <div class="title-itinerary">
                Destino {{indexDestination + 1}}
              </div>
              <div class="value-itinerary">
                {{destination.name}}
              </div>
              <div class="date-itinerary">
                {{destination.downloadDate | dateFormat:'only-date'}}
              </div>
            </div>
            <div class="information-document-wrapper">
              <div class="title-itinerary">
                Código identificador de cliente
              </div>
              <div class="value-itinerary">
                {{manualCreationCargoService?.cargoForm?.value?.numberDocumentSender ?
                manualCreationCargoService?.cargoForm?.value?.numberDocumentSender : '-'}}
              </div>
            </div>
          </div>
        </ng-scrollbar>
      </div>
      <div class="col-md-12 mb-md-0 mb-12">
        <div class="row">
          <div class="col-md-4 mb-md-0 mb-4 container-steps">

            <!-- Step 1 -->
            <div class="row step"
              [ngClass]="{'step-active': manualCreationCargoService.getComponentActive().getId() >= 1}"
              (click)="setRoute('stepOne', 1)">
              <div class="col-md-12 mb-md-0 mb-12 container-step">
                <div class="step-icon">
                  <img *ngIf="manualCreationCargoService.getComponentActive().getId() >= 1"
                    src="/assets/svg/icons/icon-barcode-active.svg" alt="">
                </div>
                <div class="step-title">
                  Tipo de carga
                </div>
                <div class="container-icon-correct" *ngIf="manualCreationCargoService.getComponentActive().getId() > 1">
                  <img src="/assets/svg/icons/icon-correct.svg" alt="">
                </div>
              </div>
            </div>
            <div class="row sub-steps" *ngIf="manualCreationCargoService.getComponentActive().getId() === 1">
              <ul>
                <li [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidTripType() }"
                  *ngIf="manualCreationCargoService.cargoForm.value.cargoModel.tripType.name !== 'Internacional'"
                  (click)="changeStep(manualCreationCargoService.steps.typeTrip)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Tipo de viaje</span>
                </li>
                <li
                  *ngIf="manualCreationCargoService.cargoForm.value.cargoModel.tripType.name === 'Importación' || manualCreationCargoService.cargoForm.value.cargoModel.tripType.name === 'Exportación'"
                  [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidRegimeType()}"
                  (click)="changeStep(manualCreationCargoService.steps.typeRegime)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Tipo de regimen</span>
                </li>
                <li *ngIf="manualCreationCargoService.cargoForm.value.cargoModel.tripType.name === 'Importación' ||
                        manualCreationCargoService.cargoForm.value.cargoModel.tripType.name === 'Nacional' ||
                        manualCreationCargoService.cargoForm.value.cargoModel.tripType.name === 'Internacional' ||
                        manualCreationCargoService.cargoForm.value.cargoModel.tripType.name === 'Exportación' ||
                        manualCreationCargoService.cargoForm.value.cargoModel.tripType.name === 'Urbana'"
                  [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidCargoType()}"
                  (click)="changeStep(manualCreationCargoService.steps.typeCargo)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Tipo de carga</span>
                </li>
                <li *ngIf="manualCreationCargoService.cargoForm.value.cargoModel.cargoType.name === 'Contenedor'"
                  [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidContainerMeasure()}"
                  (click)="changeStep(manualCreationCargoService.steps.containerMeasure)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Medidas del contenedor</span>
                </li>
                <li [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidServiceType()}"
                  (click)="changeStep(manualCreationCargoService.steps.typeService)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Tipo de servicio</span>
                </li>
              </ul>
            </div>
            <!-- Step 2 -->
            <div class="row step"
              [ngClass]="{'step-active': manualCreationCargoService.getComponentActive().getId() >= 2}"
              (click)="setRoute('groupStepsOne', 2)">
              <div class="col-md-12 mb-md-0 mb-12 container-step">
                <div class="step-icon">
                  <img *ngIf="manualCreationCargoService.getComponentActive().getId() >= 2"
                    src="/assets/svg/icons/icon-laptop-active.svg" alt="">
                  <img *ngIf="manualCreationCargoService.getComponentActive().getId() < 2"
                    src="/assets/svg/icons/icon-laptop.svg" alt="">
                </div>
                <div class="step-title">
                  Características del servicio
                </div>
                <div class="container-icon-correct" *ngIf="manualCreationCargoService.getComponentActive().getId() > 2">
                  <img src="/assets/svg/icons/icon-correct.svg" alt="">
                </div>
              </div>
            </div>
            <div class="row sub-steps" *ngIf="manualCreationCargoService.getComponentActive().getId() === 2">
              <ul>
                <li [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidVehicleType()}"
                  (click)="changeStep(manualCreationCargoService.steps.vehicleType)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Tipo de vehículo</span>
                </li>
                <li [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidVehicleQuantity()}"
                  (click)="changeStep(manualCreationCargoService.steps.quantityVehicles)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Cantidad de vehículo</span>
                </li>
                <li permission-display [module]="permission.cargo.module"
                  [functionality]="permission.cargo.assignLicensePlate"
                  [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidLicensePlate() && manualCreationCargoService.isValidVehicleQuantity()}"
                  (click)="changeStep(manualCreationCargoService.steps.assignVehicles)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Asignación de placas</span>
                </li>
                <li *ngIf="manualCreationCargoService.cargoForm.value.cargoModel.serviceType.name !== 'Contenedor vacío con carga de compensación'
                  && manualCreationCargoService.cargoForm.value.cargoModel.serviceType.name !== 'Contenedor vacío express'
                  && manualCreationCargoService.cargoForm.value.cargoModel.serviceType.name !== 'Carga consolidada'"
                  [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidProductType()}"
                  (click)="changeStep(manualCreationCargoService.steps.typeMerchandise)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Tipo de mercancía</span>
                </li>
                <li [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidContainerData()}"
                  (click)="changeStep(manualCreationCargoService.steps.optional)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Datos del Contenedor</span>
                </li>
                <li [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidDownloadAddressesCargoMeasure()}"
                  (click)="changeStep(manualCreationCargoService.steps.sizeLoad)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Medidas del servicio</span>
                </li>
              </ul>
            </div>
            <!-- Step 3 -->
            <div class="row step"
              [ngClass]="{'step-active': manualCreationCargoService.getComponentActive().getId() >= 3}"
              (click)="setRoute('groupStepsTwo', 3)">
              <div class="col-md-12 mb-md-0 mb-12 container-step">
                <div class="step-icon">
                  <img *ngIf="manualCreationCargoService.getComponentActive().getId() >= 3"
                    src="/assets/svg/icons/icon-trolley-active.svg" alt="">
                  <img *ngIf="manualCreationCargoService.getComponentActive().getId() < 3"
                    src="/assets/svg/icons/icon-trolley.svg" alt="">
                </div>
                <div class="step-title">
                  Cargue y descargue
                </div>
                <div class="container-icon-correct" *ngIf="manualCreationCargoService.getComponentActive().getId() > 3">
                  <img src="/assets/svg/icons/icon-correct.svg" alt="">
                </div>
              </div>
            </div>
            <div class="row sub-steps" *ngIf="manualCreationCargoService.getComponentActive().getId() === 3">
              <ul>
                <li
                  [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidUploadAddressesAdditionalData() && manualCreationCargoService.isValidAllDateTimeLoad()}"
                  (click)="changeStep(manualCreationCargoService.steps.upload)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Datos de cargue</span>
                </li>
                <li
                  [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidDownloadAddressesAdditionalData() && manualCreationCargoService.isValidAllDateTimeDowndload()}"
                  (click)="changeStep(manualCreationCargoService.steps.download)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Datos de descargue</span>
                </li>
                <li [ngClass]="{'sub-step-actice': true}" *ngIf="hasAdditionalCostsPermission"
                  (click)="changeStep(manualCreationCargoService.steps.additionalService)">
                  <span class="icon-circle-small"></span>
                  <span class="label-sub-step">Servicios adicionales</span>
                </li>
              </ul>
            </div>
            <!-- Step 4 -->
            <ng-container *ngIf="!authService.getCompany().validateFreight">
              <div class="row step"
                [ngClass]="{'step-active': manualCreationCargoService.getComponentActive().getId() >= 4}"
                (click)="setRoute('groupStepsThree', 4)">
                <div class="col-md-12 mb-md-0 mb-12 container-step">
                  <div class="step-icon">
                    <img *ngIf="manualCreationCargoService.getComponentActive().getId() >= 4"
                      src="/assets/svg/icons/icon-credit-card-active.svg" alt="">
                    <img *ngIf="manualCreationCargoService.getComponentActive().getId() < 4"
                      src="/assets/svg/icons/icon-credit-card.svg" alt="">
                  </div>
                  <div class="step-title">
                    Valor del envío
                  </div>
                  <div class="container-icon-correct"
                    *ngIf="manualCreationCargoService.getComponentActive().getId() > 4">
                    <img src="/assets/svg/icons/icon-correct.svg" alt="">
                  </div>
                </div>
              </div>
              <div class="row sub-steps" *ngIf="manualCreationCargoService.getComponentActive().getId() === 4">
                <ul>
                  <li
                    [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidCargoValue() && manualCreationCargoService.isValidMethodPayment()}"
                    (click)="changeStep(manualCreationCargoService.steps.cargoValue)">
                    <span class="icon-circle-small"></span>
                    <span class="label-sub-step">Valor del flete</span>
                  </li>
                  <li [ngClass]="{'sub-step-actice': manualCreationCargoService.isValidObservations()}"
                    (click)="changeStep(manualCreationCargoService.steps.methodPayment)">
                    <span class="icon-circle-small"></span>
                    <span class="label-sub-step">Observaciones</span>
                  </li>
                </ul>
              </div>
            </ng-container>
          </div>
          <div class="col-md-8 mb-md-0 mb-8 container-data-step">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
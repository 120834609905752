import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { CompaniesService } from '../../administration/companies/list-companies.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BasicGuide } from 'src/app/core/interfaces/basicGuide';
import { GuideNovelty } from 'src/app/core/interfaces/guideNovelty';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { EvidenceViewerComponent } from 'src/app/shared/evidence-viewer/evidence-viewer.component';
import { FileService } from 'src/app/shared/files/file.service';
import { Global } from 'src/app/core/resources/global';
import { StorageEndpoints } from 'src/app/core/resources/storage-endpoints';
import { Fmt } from 'src/app/core/messages/fmt';
import { UploadGuideService } from '../upload-guide/upload-guide.service';

@Component({
  selector: 'app-add-guide-novelty-form',
  templateUrl: './add-guide-novelty-form.component.html',
  styleUrls: ['./add-guide-novelty-form.component.scss']
})
export class AddGuideNoveltyFormComponent implements OnInit {
  form: FormGroup;
  permission = Permission;
  @ViewChild('documentFileInput', { static: false }) documentFileInput: ElementRef<HTMLInputElement>;
  filesToUpload: { file: File, url: string, type: 'image' | 'pdf' }[] = [];
  noveltyList: GuideNovelty[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddGuideNoveltyFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      id: string;
      guideId: string;
      companyId: string;
    },
    private snackBarService: SnackBarService,
    public utils: Utils,
    private global: Global,
    private spinner: NgxSpinnerService,
    private companiesService: CompaniesService,
    private uploadGuideService: UploadGuideService,
    private dialog: MatDialog,
    private fileService: FileService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.getNoveltyList();
  }

  private initForm() {
    this.form = new FormGroup({
      novelty: new FormControl('', Validators.required),
      evidences: new FormControl([]),
    });
  }

  private getNoveltyList() {
    this.spinner.show();
    this.companiesService.getGuideNovelties(this.data.companyId).subscribe(
      (response) => {
        this.spinner.hide();
        this.noveltyList = response && response.length ? response : [];
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        this.noveltyList = [];
      }
    );

  }

  handleFileInput(e: Event & { target: { files: FileList } }) {
    if (!e || !e.target || !e.target.files || !e.target.files.length) return;
    const formatsAllowed = ['pdf', 'jpeg', 'jpg', 'png'];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const extension = file.name.split('.').reverse()[0];
      if (!formatsAllowed.includes(extension.toLocaleLowerCase()) || !file.type) {
        this.snackBarService.openSnackBar(`El archivo ${i + 1} no es compatible con formatos ${formatsAllowed.join(', ')}`, 'x', 'error');
        continue;
      }
      this.addFile(file, extension);
    }
    if (this.documentFileInput && this.documentFileInput.nativeElement)
      this.documentFileInput.nativeElement.value = '';
  }

  private addFile(file: File, extension: string) {
    const fileToUpload: { file: File, url: string, type: 'image' | 'pdf' } = {
      file,
      url: URL.createObjectURL(file),
      type: extension === 'pdf' ? 'pdf' : 'image',
    };
    this.filesToUpload.push(fileToUpload);
  }

  deleteFile(index: number) {
    this.filesToUpload.splice(index, 1);
  }

  openImageNewTab(index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { evidences: this.filesToUpload.map(file => ({ url: file.url, type: file.type })), index };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialog.open(EvidenceViewerComponent, dialogConfig);
  }

  async onSubmit() {
    this.form.markAllAsTouched();
    if (this.utils.errorMessagesCustomized(this.form.get('novelty'), 'novedad')) return;

    this.spinner.show();
    const errorsFound = await this.loadFilesToStorage();
    if (errorsFound.length) {
      this.spinner.hide();
      return this.snackBarService.openSnackBar(
        `Ocurrió un error al cargar ${errorsFound.length > 1 ? 'los archivos' : 'el archivo'} ${errorsFound.join(', ')}`,
        undefined,
        'error'
      );
    }

    this.uploadGuideService.addNoveltyToGuide(this.data.id, this.form.value.novelty, this.form.value.evidences).subscribe(
      (response) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Novedad asignada correctamente', undefined, 'success');
        this.dialogRef.close({ state: true });
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    );
  }

  private async loadFilesToStorage(): Promise<string[]> {
    if (!this.filesToUpload.length || !this.filesToUpload.every(file => !!(file && file.file && file.file.name)))
      return [];
    try {
      let errorFound: string[] = [];
      for (let i = 0; i < this.filesToUpload.length; i++) {
        const file = this.filesToUpload[i].file;
        const path = Fmt.string(StorageEndpoints.guideNovelties, this.data.id);
        const response = await this.fileService.loadFileToStorage(path, file.name, file);
        if (!response) errorFound.push(file.name);
      }
      if (errorFound.length) return errorFound;
      this.form.get('evidences').setValue(this.filesToUpload.map(file => `${Fmt.string(StorageEndpoints.guideNovelties, this.data.id)}/${file.file.name}`));
      return [];
    } catch (error) {
      return this.filesToUpload.map(file => file.file.name);
    }
  }

}

<form class="modal-body wrapper-modal py-5 px-3 px-sm-5" [formGroup]="form" (submit)="onSubmit()">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <h3 class="text-center text-bold mx-3 mb-5">
        Asignar novedad
    </h3>
    <div class="row mt-4">
        <mat-form-field appearance="outline" class="col-12 px-0">
            <mat-label class="text-bold">Seleccionar novedad<span class="asterisk">*</span></mat-label>
            <mat-select formControlName="novelty">
                <mat-option *ngFor="let novelty of noveltyList" [value]="novelty"
                    [ngClass]="{'d-none': !novelty?.id || !novelty?.type}">
                    <i class="fas fa-exclamation-triangle" *ngIf="novelty?.type === 'Anomaly'"></i>
                    <span [ngClass]="{'ml-4': novelty?.type !== 'Anomaly'}">{{ novelty?.name }}</span>

                    <span class="text-bold" *ngIf="novelty?.type === 'Anomaly'"> (Bloqueante)</span>
                    <small class="text-muted" *ngIf="novelty?.companyId !== this.data.companyId">
                        (Predeterminada)</small>
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('novelty').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>

        <div class="alert alert-warning" *ngIf="!noveltyList.length">
            <p class="mb-0">No hay novedades disponibles, debe configurarlas en la sección de configuración de novedades
                de la guía</p>
        </div>


        <div class="info-container col-12" *ngIf="form.get('novelty').value?.description">
            <p class="text-bold mb-1">Descripción de la novedad</p>
            <p class="mb-0">{{ form.get('novelty').value.description }}</p>
        </div>
        <div class="col-12 mt-3 px-0" *ngIf="form.get('novelty').value">
            <label class="text-bold">Subir evidencias <span class="optional">(Opcional)</span></label>
            <div class="popup-photos">
                <div class="photo" *ngFor="let file of filesToUpload; let j = index;">
                    <i class="fa fa-times-circle icon-close icon-action" (click)="deleteFile( j )"
                        matTooltip="Eliminar"></i>
                    <img [src]="(file?.url | safe)" alt="" draggable="false" (click)="openImageNewTab(j)"
                        (error)="utils.onErrorRenderImage($event)">
                </div>
                <label class="photo photo-add" matTooltip="Agregar evidencia">
                    <input type="file" #documentFileInput class="display-none" (change)="handleFileInput($event)"
                        accept="application/pdf,image/png,image/jpeg" multiple>
                    <div class="icon-plus">
                        <i class="fas fa-plus "></i>
                    </div>
                </label>
            </div>
        </div>
    </div>
    <div class="button-container mt-4">
        <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
            (click)="dialogRef.close()" type="button">
            Cancelar
        </button>
        <button mat-raised-button color="primary" class="button-width-auto bg-color-primary" type="submit">
            Asignar
        </button>
    </div>
</form>
<form [formGroup]="menuService?.form" (ngSubmit)="saveAndContinue()" class="px-0 px-sm-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12" *ngIf="canSeeFinancialInformationSection">
                <label class="text-bold">Resumen de valores</label>
                <mat-list class="col-md-12 px-0 content-financial">
                    <!-- Valor de la tarifa -->
                    <mat-list-item class="row h-auto" *ngIf="hasPermissionShowRate">
                        <div class="w-100 item-financial">
                            <div>Valor de la tarifa</div>
                            <div class="item-price">
                                {{rate | currency:'COP':'code':'0.2'}}
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-list-item class="row h-auto ng-star-inserted">
                        <div class="w-100 item-financial">
                            <div>Valor total del servicio</div>
                            <div class="item-price">
                                {{totalCost | currency:'COP':'code':'0.2'}}
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-list-item class="row h-auto ng-star-inserted">
                        <div class="w-100 item-financial">
                            <div>
                                Valor de reteica ({{freeTaxes ? 0 : reteica}}%):
                            </div>
                            <div class="item-price">
                                {{(freeTaxes ? 0 : reteicaValue) | currency:'COP':'code':'0.2'}}
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-list-item class="row h-auto ng-star-inserted">
                        <div class="w-100 item-financial">
                            <div>Valor de retefuente ({{freeTaxes ? 0 : 1}}%)</div>
                            <div class="item-price">
                                {{(freeTaxes ? 0 : retefteValue) | currency:'COP':'code':'0.2'}}
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-list-item class="row h-auto"
                        *ngIf="menuService?.form?.get('additionalServices')?.value?.length && hasPermissionsCosts">
                        <div class="w-100 item-financial">
                            <div>Valor de servicios adicionales al flete</div>
                            <div class="item-price">
                                {{additionalServicesCost | currency:'COP':'code':'0.2'}}
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-list-item class="row h-auto"
                        *ngIf="menuService?.form?.get('additionalServices')?.value?.length && hasPermissionsRate">
                        <div class="w-100 item-financial">
                            <div>Valor de servicios adicionales a la tarifa</div>
                            <div class="item-price">
                                {{additionalServicesRate | currency:'COP':'code':'0.2'}}
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-list-item class="row h-auto" *ngIf="isTeclogiUser">
                        <div class="w-100 item-financial">
                            <div>Servicio App SaaS - Plataforma Digital Transporte (3%)</div>
                            <div class="item-price">
                                {{vehicleInsuranceDiscountCargo | currency:'COP':'code':'0.2'}}
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>

            <p class="col-12 mt-4 text-bold" *ngIf="canSeeAndEditPublicationZone">Publicación</p>
            <div class="col-md-12" *ngIf="canSeeAndEditPublicationZone">
                <mat-radio-group [formControl]="publicationZone" class="flex-column">
                    <mat-radio-button *ngFor="let zone of publicationZones" [value]="zone.value">
                        <p class="zone-radio-button mb-0">
                            <b>{{zone.name}}:</b> {{zone.description}}
                        </p>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div *ngIf="!canSeeFinancialInformationSection && !canSeeAndEditPublicationZone"
            class="no-permission-container">
            <i class="fas fa-exclamation-circle fa-lg m-2"></i>
            <h4 class="text-bold">No tienes permiso para acceder a esta información. Contacta a tu administrador para
                solicitar acceso.</h4>
        </div>
        <!-- Action Buttons Section -->
        <div class="button-container mt-4">
            <button mat-raised-button color="primary" class="button-width-auto primary-secondary button-small"
                [ngClass]="{'warn': true}" type="button" (click)="backToPreviousStep()">
                <span class="mat-button-wrapper">Atras</span>
            </button>
            <button class="mx-2" type="submit" mat-raised-button color="accent" *ngIf="canSeeAndEditPublicationZone">
                Guardar
            </button>
        </div>
        <div class="button-container">
            <button mat-raised-button color="accent" type="button" *ngIf="canCreateCargoFromRequest"
                (click)="createCargo()" [ngClass]="{'disabled-btn': !menuService?.isValidEntireCargo().isValid}"
                [matTooltip]="menuService?.isValidEntireCargo().error" matTooltipClass="tooltip__error">
                {{menuService?.cargo?.ministry? 'Crear remesas' : 'Finalizar solicitud'}}
            </button>
        </div>
    </div>
</form>
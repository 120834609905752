import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, Form, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectChange, MatDialogConfig, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { CompanyCompliment } from 'src/app/core/interfaces/companyCompliment';
import { Fingerprint } from 'src/app/core/interfaces/fingerprint';
import { DateManager } from 'src/app/core/managers/date.manager';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { FileService } from 'src/app/shared/files/file.service';
import { CompaniesService } from '../../administration/companies/list-companies.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Destination } from 'src/app/core/interfaces/destination';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { FulfillmentCargo, FulfillmentDocument, FulfillmentRequirement } from 'src/app/core/interfaces/fulfillmentDocument';
import { CargoService } from 'src/app/core/services/cargo.service';
import { Global } from 'src/app/core/resources/global';
import { StorageEndpoints } from 'src/app/core/resources/storage-endpoints';
import { Fmt } from 'src/app/core/messages/fmt';

@Component({
  selector: 'app-cargo-compliments',
  templateUrl: './cargo-compliments.component.html',
  styleUrls: ['./cargo-compliments.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CargoComplimentsComponent implements OnInit {

  @ViewChild('documentFileInput', { static: false }) documentFileInput: ElementRef<HTMLInputElement>;
  form: FormGroup;
  permission = Permission;
  cargoTripType: string;
  complimentStates = ['Completo', 'Completo con novedades', 'Incompleto', 'Sin validar'];
  documentStates = ['Validado', 'Validado con novedad', 'Validación en proceso', 'Sin validar'];
  companyDocuments: CompanyCompliment[] = [];
  documentsFlag: boolean = false;
  filesToUpload: File[] = [];
  companyId: string;
  constructor(
    public dialogRef: MatDialogRef<CargoComplimentsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cargo: Cargo;
      indexDestination: number;
      indexAddress: number;
    },
    private snackBarService: SnackBarService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    private companiesService: CompaniesService,
    private permissionRole: PermissionRole,
    private authService: AuthService,
    private dialog: MatDialog,
    private cargoService: CargoService,
    public global: Global,
  ) { }

  //INITIALIZE
  ngOnInit(): void {
    if (!this.data.cargo || !this.utils.isDefined(this.data.indexAddress) ||
      !this.utils.isDefined(this.data.indexDestination)) {
      this.snackBarService.openSnackBar(CargoMessages.UNABLE_TO_CHECK_COMPLIMENTS, undefined, 'error');
      this.dialogRef.close();
      return;
    }
    this.companyId = this.data.cargo.idCompany;
    this.initForm();
    this.getCompanyCompliments();

  }

  private initForm() {
    this.form = new FormGroup({
      cargoId: new FormControl(this.data.cargo.id),
      documentId: new FormControl(),
      addressName: new FormControl(this.destinationAddress.address),
      addressId: new FormControl(this.destinationAddress.id),
      destinationName: new FormControl(this.cargoDestination.name),
      destinationId: new FormControl(this.cargoDestination.id),
      generalState: new FormControl('Sin validar', Validators.required),
      observations: new FormControl(''),
      fulfillmentRequirements: new FormArray([])
    });
  }

  private getCompanyCompliments() {
    this.companiesService.getCompanyCompliments(this.companyId).subscribe(
      (success) => {
        if (success && success.length)
          this.companyDocuments = success;
        else this.companyDocuments = [];
        this.setCompanyCompliments();
        this.documentsFlag = true;
      },
      (error) => {
        this.getCargoData();
        this.documentsFlag = true;
      }
    )
  }

  private setCompanyCompliments() {
    this.cargoTripType = this.utils.getNestedValue(this.data.cargo, 'cargoModel.tripType.name');
    this.companyDocuments.forEach(document => {
      if (this.cargoTripType && document && document.tripTypes && document.tripTypes.includes(this.cargoTripType) && document.state)
        this.addCargoCompliment(document);
    });
    this.getCargoData();
  }

  private addCargoCompliment(document: CompanyCompliment) {
    if (!document) return;
    const requirement: FulfillmentRequirement = {
      title: document.title,
      fulfillmentRequirementId: `Company/${this.companyId}/FulfillmentRequirementCompany/${document.documentId}`,
      isPhysical: document.requirePhysicalDocument,
      fulfillmentState: 'Sin validar',
      observations: '',
      documentId: '',
      fulfillmentDocuments: []
    };
    if (document.example && document.example[0])
      requirement['example'] = document.example[0];

    this.addFulfillmentRequirement(requirement);
  }

  private addFulfillmentRequirement(requirement: FulfillmentRequirement) {
    const documentFormGroup = new FormGroup({
      title: new FormControl(requirement.title),
      fulfillmentRequirementId: new FormControl(requirement.fulfillmentRequirementId),
      isPhysical: new FormControl(requirement.isPhysical),
      fulfillmentState: new FormControl(requirement.fulfillmentState, Validators.required),
      observations: new FormControl(requirement.observations),
      documentId: new FormControl(requirement.documentId),
      fulfillmentDocuments: new FormArray([])
    });
    if (requirement.isPhysical)
      documentFormGroup.addControl('physicalDelivery', new FormControl(true));
    if (requirement.example)
      documentFormGroup.addControl('example', new FormControl(requirement.example));

    this.fulfillmentRequirements.push(documentFormGroup);

    if (requirement.fulfillmentDocuments && requirement.fulfillmentDocuments.length)
      requirement.fulfillmentDocuments.forEach(document => {
        this.addFulfillmentDocument(document, this.fulfillmentRequirements.length - 1);
      })
  }

  private getCargoData() {
    this.cargoService.getCargoFulFillment(this.data.cargo.id, this.data.indexAddress, this.data.indexDestination).subscribe(
      (fulfillmentCargo) => {
        if (!fulfillmentCargo || !fulfillmentCargo[0] || !fulfillmentCargo[0].documentId) {
          if (!this.fulfillmentRequirements.controls.length)
            this.form.disable();
          return;
        }
        this.form.get('documentId').setValue(fulfillmentCargo[0].documentId);
        if (fulfillmentCargo[0].generalState) this.form.get('generalState').setValue(fulfillmentCargo[0].generalState);
        if (fulfillmentCargo[0].observations) this.form.get('observations').setValue(fulfillmentCargo[0].observations);
        if (fulfillmentCargo[0].fulfillmentRequirements && fulfillmentCargo[0].fulfillmentRequirements.length)
          fulfillmentCargo[0].fulfillmentRequirements.forEach(requirement => {
            const index = this.fulfillmentRequirements.controls.findIndex(requirementAdded => {
              return requirementAdded.value.fulfillmentRequirementId === requirement.fulfillmentRequirementId;
            });
            if (index !== -1) {
              this.fulfillmentRequirements.at(index).patchValue(requirement);
              if (requirement.fulfillmentDocuments && requirement.fulfillmentDocuments.length)
                requirement.fulfillmentDocuments.forEach(doc => this.addFulfillmentDocument(doc, index))
            }
            else this.addFulfillmentRequirement(requirement);

            this.fulfillmentRequirements.controls.forEach((req, index) => {
              this.getFulfillmentDocuments(index).controls.forEach((doc, iD) => {
                this.getComplimentFile(doc.get('documentPath').value, 'cargo', index, iD);
              })
            })
          })
        if (!this.fulfillmentRequirements.controls.length)
          this.form.disable();
      }, (error) => {
        if (!this.fulfillmentRequirements.controls.length)
          this.form.disable();
      }
    )
  }

  private addFulfillmentDocument(document: FulfillmentDocument, documentIndex: number) {
    const documentGroup = new FormGroup({
      documentPath: new FormControl(document.documentPath),
      documentId: new FormControl(document.documentId),
      whoUploads: new FormControl(document.whoUploads),
      active: new FormControl(document.active),
      fingerprint: new FormGroup({
        date: new FormControl(document.fingerprint.date),
        userId: new FormControl(document.fingerprint.userId),
        userName: new FormControl(document.fingerprint.userName)
      }),
    });
    if (this.utils.isDefined(document.check) || document.whoUploads === 'Driver')
      documentGroup.addControl('check', new FormControl(!!document.check));
    if (this.utils.isDefined(document.url))
      documentGroup.addControl('url', new FormControl(document.url));
    if (this.utils.isDefined(document.deleteFingerprint))
      documentGroup.addControl('deleteFingerprint', new FormGroup({
        date: new FormControl(document.deleteFingerprint.date),
        userId: new FormControl(document.deleteFingerprint.userId),
        userName: new FormControl(document.deleteFingerprint.userName)
      }));

    if (this.getFulfillmentDocuments(documentIndex))
      this.getFulfillmentDocuments(documentIndex).push(documentGroup);
  }

  //Delete image
  confirmDelete(documentIndex: number, imageIndex: number) {
    const complimentsGroup: FormGroup = this.getFulfillmentDocuments(documentIndex).at(imageIndex) as FormGroup;
    if (!complimentsGroup) return;
    const imageName = complimentsGroup.get('documentPath').value;
    const index = this.filesToUpload.findIndex(file => file.name === imageName);
    if (index !== -1) {
      this.getFulfillmentDocuments(documentIndex).removeAt(imageIndex);
      this.filesToUpload.splice(index, 1);
      return;
    }
    complimentsGroup.get('active').setValue(false);
    if (complimentsGroup.get('check')) complimentsGroup.get('check').setValue(false);
    complimentsGroup.addControl('deleteFingerprint', new FormGroup({
      date: new FormControl(DateManager.dateToString(new Date())),
      userId: new FormControl(this.authService.getUserSession().information.document),
      userName: new FormControl(this.authService.getUserSession().information.name)
    }));
  }
  //change document state
  onChangeDocumentState($event: MatSelectChange, documentIndex: number) {
    let observationsFormControl: AbstractControl;
    if (this.fulfillmentRequirements && this.fulfillmentRequirements.at(documentIndex))
      observationsFormControl = this.fulfillmentRequirements.at(documentIndex).get('observations');
    if (!observationsFormControl) return;
    if ($event.value === 'Validado con novedad')
      observationsFormControl.setValidators([Validators.required]);
    else
      observationsFormControl.clearValidators();
    observationsFormControl.updateValueAndValidity();
  }

  //Handle Input
  handleFileInput(e: Event & { target: { files: FileList } }, documentIndex: number) {
    if (!e || !e.target || !e.target.files || !e.target.files.length) return;
    const formatsAllowed = ['pdf', 'jpeg', 'jpg', 'png'];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const extension = file.name.split('.').reverse()[0];
      if (!formatsAllowed.includes(extension.toLocaleLowerCase()) || !file.type) {
        this.snackBarService.openSnackBar(`El archivo ${i + 1} no es compatible con formatos ${formatsAllowed.join(', ')}`, 'x', 'error');
        continue;
      }
      this.addComplimentImage(file, documentIndex);
    }
    if (this.documentFileInput && this.documentFileInput.nativeElement)
      this.documentFileInput.nativeElement.value = '';
  }

  private async addComplimentImage(file: File, documentIndex: number) {
    const fulFillmentDocument: FulfillmentDocument = {
      documentPath: file.name,
      check: false,
      active: true,
      whoUploads: 'Fulfillment Team',
      fingerprint: {
        date: DateManager.dateToString(new Date()),
        userId: this.authService.getUserSession().information.document,
        userName: this.authService.getUserSession().information.name
      },
    };
    this.filesToUpload.push(file);
    const url: string = URL.createObjectURL(file);
    if (url) fulFillmentDocument['url'] = url;
    this.addFulfillmentDocument(fulFillmentDocument, documentIndex);
  }

  getComplimentFile(
    fileName: string,
    type: 'cargo' | 'company' = 'company',
    documentIndex?: number,
    imageIndex?: number,
  ) {
    if (!fileName) return;
    const companyUrl = `${Fmt.string(StorageEndpoints.companyFulfillment, this.companyId)}/${fileName}`;
    const cargoUrl = `${Fmt.string(StorageEndpoints.cargoFulfillment, this.data.cargo.id)}/${fileName}`;
    const url = type === 'cargo' ? cargoUrl : companyUrl;
    this.fileService.getURLDocument(url).subscribe(
      (response) => {
        if (response && response.code_ === "storage/object-not-found") {
          this.snackBarService.openSnackBar(FormMessages.ERROR_LOAD_RESOURCE, undefined, 'error');
          return;
        }
        if (type === 'cargo' && this.utils.isDefined(documentIndex) && this.utils.isDefined(imageIndex)) {
          const complimentsGroup: FormGroup = this.getFulfillmentDocuments(documentIndex).at(imageIndex) as FormGroup;
          if (complimentsGroup) complimentsGroup.addControl('url', new FormControl(response));
          return;
        }
        this.openImageNewTab(response, this.getExtension(fileName));
      }, () => {
        this.snackBarService.openSnackBar(FormMessages.ERROR_LOAD_RESOURCE, undefined, 'error');
      })
  }

  getExtension(fileName: string): 'PDF' | 'IMG' {
    if (fileName && fileName.includes('.')) {
      const extension = fileName.split('.').reverse()[0];
      return extension.toUpperCase() === 'PDF' ? 'PDF' : 'IMG';
    }
    return null;
  }

  openImageNewTab(url: string, type: 'PDF' | 'IMG') {
    if (!type || !url) {
      this.snackBarService.openSnackBar('Ocurrió un error al abrir el archivo', undefined, 'error');
      return;
    }
    const realURL = type === 'PDF'
      ? `<embed src="${url}" type="application/pdf" width="100%" height="100%">`
      : `<img src="${url}" alt="Image Preview">`;
    const newWindow = window.open();
    if (newWindow) {
      newWindow.document.write(realURL);
      newWindow.document.close();
    }
  }

  getFingerprintToolTip(document: FulfillmentDocument): string {
    let message: string = '';
    const fingerprint = document.active ? document.fingerprint : document.deleteFingerprint;
    if (fingerprint) {
      if (fingerprint.userName)
        message += (document.active ? 'Subido por: ' : 'Eliminado por: ') + fingerprint.userName + '\n';
      if (fingerprint.date)
        message += `Fecha: ${DateManager.formatDate(fingerprint.date, 'YYYY-MM-DD HH:mm ZZ', 'YYYY/MM/DD')}`;
    }
    return message;
  }

  hasActiveCompliments(documentType: 'Driver' | 'Fulfillment Team', documentIndex: number): boolean {
    const fulfillmentDocuments: FormArray = this.getFulfillmentDocuments(documentIndex);
    if (!fulfillmentDocuments || !fulfillmentDocuments.value || !fulfillmentDocuments.value.length) return false;
    const fulfillmentDocumentsFiltered: FulfillmentDocument[] = fulfillmentDocuments.value.filter((doc: FulfillmentDocument) => {
      return doc && doc.whoUploads === documentType;
    });
    const hasCompliments: boolean = !!(fulfillmentDocumentsFiltered && fulfillmentDocumentsFiltered.length);
    return (hasCompliments && this.hasDeletedPermission) ||
      (hasCompliments && fulfillmentDocumentsFiltered.some(image => image && image.active));
  }

  //Submit
  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      if (this.findErrors()) return;
      this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    let filesWithError = [];
    this.spinner.show();
    for (let i = 0; i < this.filesToUpload.length; i++) {
      const file = this.filesToUpload[i];
      const path = Fmt.string(StorageEndpoints.cargoFulfillment, this.data.cargo.id);
      this.fileService.loadFileToStorage(path, file.name, file)
        .then(() => this.filesToUpload.splice(i, 1))
        .catch(() => filesWithError.push(file.name));
    }
    if (filesWithError.length) {
      this.snackBarService.openSnackBar(`Los archivos ${filesWithError.join(', ')} no pudieron ser cargado correctamente`, undefined, 'error');
      this.spinner.hide();
      return;
    }
    let cargoCompliments: FulfillmentCargo = this.form.value;
    if (cargoCompliments.fulfillmentRequirements && cargoCompliments.fulfillmentRequirements.length) {
      cargoCompliments.fulfillmentRequirements.forEach((requirement) => {
        if (requirement.example) delete requirement.example;
        if (requirement.physicalDelivery) delete requirement.physicalDelivery;
        if (requirement.fulfillmentDocuments && requirement.fulfillmentDocuments.length)
          requirement.fulfillmentDocuments.forEach((image) => {
            if (image.whoUploads === 'Fulfillment Team')
              delete image.check;
            delete image.url;
            if (!cargoCompliments.documentId) {
              delete image.fingerprint;
              if (image.deleteFingerprint) delete image.deleteFingerprint;
            }
          });
      });
    }
    this.updateCargoCompliments(
      cargoCompliments,
      cargoCompliments.documentId
        ? "updateCargoFulFillment"
        : "createCargoFulFillment"
    );
  }

  private findErrors(): boolean {
    if (this.utils.errorMessagesCustomized(this.form.get('generalState'), 'estado del cumplido')) return true;
    let errorFound = false;
    this.fulfillmentRequirements.controls.forEach(requirement => {
      if (!errorFound) {
        const requirementName = requirement.get('title').value;
        if (this.utils.errorMessagesCustomized(requirement.get('fulfillmentState'), `estado del requisito ${requirementName}`))
          errorFound = true;
        else if (this.utils.errorMessagesCustomized(requirement.get('observations'), `novedades del requisito ${requirementName}`))
          errorFound = true;
      }
    })
    return errorFound;
  }

  private updateCargoCompliments(body: FulfillmentCargo, method: string) {
    this.cargoService[method](body).subscribe(
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(CargoMessages.FULFILLMENT_CARGO_SAVED);
        this.dialogRef.close();
      },
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    )
  }

  confirmClose() {
    if (!this.fulfillmentRequirements.controls.length || (!this.hasFullEditPermission && !this.hasPartialEditPermission)) {
      this.dialogRef.close();
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Estás seguro que deseas salir sin guardar?`,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state)
        this.dialogRef.close();
    });
  }

  //GETTERS
  get fulfillmentRequirements(): FormArray {
    return this.form.get('fulfillmentRequirements') as FormArray;
  }

  getFulfillmentDocuments(index: number): FormArray {
    return this.fulfillmentRequirements.at(index).get('fulfillmentDocuments') as FormArray;
  }

  isDriverDocument(documentIndex: number, imageIndex: number): boolean {
    const fulfillmentDocument: FormGroup = this.getFulfillmentDocuments(documentIndex).at(imageIndex) as FormGroup;
    return fulfillmentDocument && fulfillmentDocument.value && fulfillmentDocument.value.whoUploads === 'Driver';
  }
  isFulfillmentTeamDocument(documentIndex: number, imageIndex: number): boolean {
    const fulfillmentDocument: FormGroup = this.getFulfillmentDocuments(documentIndex).at(imageIndex) as FormGroup;
    return fulfillmentDocument && fulfillmentDocument.value && fulfillmentDocument.value.whoUploads === 'Fulfillment Team';
  }

  get hasFingerPrintPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.complimentsFingerprint);
  }

  get hasFullEditPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editCargoCompliments);
  }

  get hasPartialEditPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editCargoComplimentRequirements);
  }

  get hasIndividualEditPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editCargoComplimentRequirements);
  }

  get hasDeletedPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.readDeletedCargoCompliments);
  }

  get cargoDestination(): Destination {
    try {
      return this.data.cargo.cargoFeature.uploadDownload.destination[this.data.indexDestination];
    } catch (e) {
      return null;
    }
  }

  get destinationAddress(): AddressCargo {
    try {
      return this.cargoDestination.addresses[this.data.indexAddress];
    } catch (e) {
      return null;
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { CargoLocationReportComponent } from '../cargo-location-report/cargo-location-report.component';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Permission } from 'src/app/core/resources/permission';
import { CargoTrackingService } from '../cargo-tracking/cargo-tracking.service';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { LastPointLocation, Traceability } from 'src/app/core/interfaces/lastPointLocation';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { AngularFireStorage } from '@angular/fire/storage';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { EvidenceViewerComponent } from 'src/app/shared/evidence-viewer/evidence-viewer.component';
import { Global } from 'src/app/core/resources/global';
import { StorageEndpoints } from 'src/app/core/resources/storage-endpoints';
import { Fmt } from 'src/app/core/messages/fmt';
@Component({
  selector: 'app-cargo-history-news',
  templateUrl: './cargo-history-news.component.html',
  styleUrls: ['./cargo-history-news.component.scss']
})
export class CargoHistoryNewsComponent implements OnInit {
  permission = Permission;
  isSomeChanged: boolean = false;
  tracepoints: LastPointLocation[] = [];
  noveltyImages: { pointId: number, images: { url: string, type: 'image' | 'pdf' }[] }[] = [];
  filteredHistoryNoveltyPoints: LastPointLocation[] = [];
  constructor(
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<CargoHistoryNewsComponent>,
    public utils: Utils,
    private snackBarService: SnackBarService,
    public global: Global,
    public cargoTrackingService: CargoTrackingService,
    private permissionRole: PermissionRole,
    private _angularFireStorage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data: {
      tracepoints: Traceability,
      cargo: Cargo,
      title: string
    }) { }

  ngOnInit() {
    if (this.data && this.data.tracepoints) {
      this.tracepoints = this.data.tracepoints.tracepoints;
      this.getTracepointImages();
      this.filteredHistoryNoveltyPoints = this.tracepoints;
    }
  }

  filterTracepoints($event: LastPointLocation[]) {
    this.filteredHistoryNoveltyPoints = $event;
  }

  private getTracepointImages() {
    this.noveltyImages = [];
    if (this.tracepoints && this.tracepoints.length)
      this.tracepoints.forEach(async tracepoint => {
        const images = [];
        if (tracepoint.images && tracepoint.images.length)
          for (const image of tracepoint.images) {
            const url = await this.getDownloadURL(image) || this.global.pathNoImage;
            images.push({ url, type: 'image' });
          }
        this.noveltyImages.push({ pointId: tracepoint.id, images });
      })
  }

  public updateNovelty(novelty) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.data.cargo,
      report: {
        parent: "tripAnomalies",
      }
    }
    if (novelty.name || novelty.anomaly) {
      dialogConfig.data.report['name'] = novelty.name ? novelty.name : novelty.anomaly;
    }
    if (novelty.observation) {
      dialogConfig.data.report['observation'] = novelty.observation;
    }
    if (novelty.fingerprint && novelty.fingerprint.date) {
      dialogConfig.data.report['date'] = novelty.fingerprint.date;
    }
    if (novelty.temperature) {
      dialogConfig.data.report['temperature'] = novelty.temperature;
    }
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.matDialog.open(CargoLocationReportComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (!this.utils.isEmpty(result) && !this.utils.isEmpty(result.state) && result.state) {
        this.snackBarService.openSnackBar(CargoMessages.NOVELTY_UPDATED);
        if (result.cargo) {
          this.refreshTracepoints();
          this.isSomeChanged = true;
        }
      }
      if (!this.utils.isEmpty(result) && !this.utils.isEmpty(result.state) && !result.state) {
        this.snackBarService.openSnackBar(CargoMessages.NOVELTY_UPDATE_ERROR, undefined, 'error');
      }
    });
  }

  get canEditAnomaly(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editAnomalies);
  }

  public refreshTracepoints() {
    this.cargoTrackingService.getTracking(this.data.cargo.id).subscribe(
      (data: Traceability) => {
        this.tracepoints = data && data.tracepoints ? data.tracepoints : [];
      },
      error => {
        this.tracepoints = [];
      })
  }

  public closeDialog() {
    this.isSomeChanged
      ? this.dialogRef.close({
        state: true,
      })
      : this.dialogRef.close();
  }

  async getDownloadURL(fileName: string): Promise<string> {
    if (!fileName) return null;
    try {
      const path = Fmt.string(StorageEndpoints.cargoLocationReportEvidences, this.data.cargo.id);
      let pathReference = this._angularFireStorage.ref(`${path}/${fileName}`);
      const url = await pathReference.getDownloadURL().toPromise();
      return url;
    } catch (error) {
      return null;
    }
  }

  getNoveltyImages(pointId: number): { url: string, type: 'image' | 'pdf' }[] {
    const element = this.noveltyImages.find(image => image.pointId === pointId);
    return element ? element.images : [];
  }

  openImageNewTab(index: number, pointId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { evidences: this.getNoveltyImages(pointId), index };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.matDialog.open(EvidenceViewerComponent, dialogConfig);
  }

}

<form [formGroup]="form" (ngSubmit)="checkForm()" class="create-cargo__form py-4 px-2 px-sm-3 px-md-5 w-100">
    <div class="row">
        <h3 class="col-12 mt-3 mb-4 text-bold">Creación Solicitud Servicio de Escolta</h3>
        <p class="col-12 my-3 text-bold">
            Cliente interno
        </p>
        <div class="col-md-12">
            <app-select-company [options]="optionsCompany" [inputFormControl]="formAux.get('company')"
                [validate]="formValidate">
            </app-select-company>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>
                    Código identificador de cliente
                    <span class="optional">(opcional)</span>
                </mat-label>
                <input matInput type="text" formControlName="numberDocumentSender">
                <i matSuffix class="fas fa-info-circle color-secondary-dark cursor-pointer"
                    matTooltip="Es el número del documento interno de identificación del cliente."
                    matTooltipClass="tooltip__alert" matTooltipPosition="right"></i>
            </mat-form-field>
        </div>

        <p class="col-12 my-3 text-bold">
            Origen
        </p>
        <div class="col-12 p-0 d-flex flex-wrap align-items-baseline">
            <app-autocomplete-city [inputFormControl]="formAux.get('originCity')" class="col-12"
                [options]="originCityOptions" [validate]="formValidate">
            </app-autocomplete-city>
            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Fecha de origen<span class="asterisk">*</span></mat-label>
                <input matInput [min]="minDate" [matDatepicker]="pickerLoadDate"
                    [formControl]="formAux.get('originDate')" name="loadDate" (click)="pickerLoadDate.open()"
                    (dateChange)="updateDestinationDates()" readonly>
                <mat-datepicker-toggle matSuffix [for]="pickerLoadDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerLoadDate></mat-datepicker>
                <mat-error *ngIf="formAux.get('originDate').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-3">
                <mat-label>Hora de origen<span class="asterisk">*</span></mat-label>
                <mat-select [formControl]="formAux.get('originTime')">
                    <mat-option [value]="time.value" *ngFor="let time of timeList">
                        {{time.title}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formAux.get('originTime').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
            <div class="col-md-3 mb-3 mb-md-0">
                <mat-button-toggle-group appearance="standard" [formControl]="formAux.get('originTimeType')"
                    #group="matButtonToggleGroup">
                    <mat-button-toggle value="AM">
                        AM
                    </mat-button-toggle>
                    <mat-button-toggle value="PM">
                        PM
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <app-autocomplete-thirdparty class="col-md-6" [options]="originThirdPartyOptions"
                [inputFormControl]="formAux.get('originThirdParty')" [validate]="formValidate">
            </app-autocomplete-thirdparty>
            <app-autocomplete-thirdparty-address [inputFormControl]="formAux.get('originThirdPartyConsignment')"
                class="col-md-6" [validate]="formValidate" [options]="{
                    title: 'Dirección de remitente',
                    appearance: 'outline',
                    companyId:formAux.get('originThirdParty').value?.document,
                    municipalityCode: formAux?.get('originCity')?.value?.id,
                    thirdParty: formAux.get('originThirdParty').value,
                    cleanOnChangeOptions: true
                }">
            </app-autocomplete-thirdparty-address>

        </div>
        <div class="col-12 d-flex flex-wrap align-items-baseline"
            *ngFor="let destination of getDestinations; let i = index">
            <p class="col-12 p-0 my-3 text-bold d-flex justify-content-between">
                Destino {{i + 1}}
                <i matTooltip="Eliminar destino" class="fas fa-times-circle cursor-pointer" *ngIf="!!i"
                    (click)="deleteDestination(i)"></i>
            </p>
            <div class="col-12 p-0 row align-items-baseline">
                <app-autocomplete-city [inputFormControl]="destination.get('destinationCity')" class="col-md-6"
                    [options]="destinationCityOptions" [validate]="formValidate">
                </app-autocomplete-city>
                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Fecha de destino<span class="asterisk">*</span></mat-label>
                    <input matInput [min]="minDate" [matDatepicker]="pickerEndDate"
                        [formControl]="destination.get('destinationDate')" name="endDate" (click)="pickerEndDate.open()"
                        (dateChange)="updateDestinationDates()" readonly>
                    <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEndDate></mat-datepicker>
                    <mat-error *ngIf="destination.get('destinationDate').errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                </mat-form-field>

                <div class="col-12 row align-items-baseline pl-1 pl-md-5"
                    *ngFor="let address of getDestinationAddresses(i); let j = index">
                    <p class="col-12 text-bold d-flex justify-content-between">
                        Dirección {{j + 1}}
                        <i matTooltip="Eliminar dirección" class="fas fa-times-circle cursor-pointer" *ngIf="!!j"
                            (click)="deleteDestinationAddress(i, j)"></i>
                    </p>
                    <app-autocomplete-thirdparty class="col-md-6" [options]="destinationThirdPartyOptions"
                        [inputFormControl]="address.get('destinationThirdParty')" [validate]="formValidate">
                    </app-autocomplete-thirdparty>
                    <app-autocomplete-thirdparty-address
                        [inputFormControl]="address.get('destinationThirdPartyConsignment')" class="col-md-6"
                        [validate]="formValidate" [options]="{
                            title: 'Dirección de destinatario',
                            appearance: 'outline',
                            companyId:address.get('destinationThirdParty').value?.document,
                            municipalityCode: destination?.get('destinationCity')?.value?.id,
                            thirdParty: address.get('destinationThirdParty').value,
                            cleanOnChangeOptions: true
                        }">
                    </app-autocomplete-thirdparty-address>
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Hora de destino<span class="asterisk">*</span></mat-label>
                        <mat-select [formControl]="address.get('destinationTime')">
                            <mat-option [value]="time.value" *ngFor="let time of timeList">
                                {{time.title}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="address.get('destinationTime').errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                    </mat-form-field>
                    <div class="col-md-3 mb-3 mb-md-0">
                        <mat-button-toggle-group appearance="standard"
                            [formControl]="address.get('destinationTimeType')" #group="matButtonToggleGroup">
                            <mat-button-toggle value="AM">
                                AM
                            </mat-button-toggle>
                            <mat-button-toggle value="PM">
                                PM
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-end underline font-secondary-dark">
                <button type="button" mat-button class="button-width-auto" (click)="addDestinationAddress(i)">
                    <i class="fas fa-plus-circle"></i>
                    <span>Agregar otra dirección</span>
                </button>
            </div>
        </div>
        <div class="col-12">
            <button mat-raised-button class="primary-secondary button-width-auto" color="primary" type="button"
                (click)="addDestination()">
                Añadir destino
            </button>
        </div>

        <p class="col-12 my-3 text-bold">
            Tipo de viaje
        </p>

        <mat-form-field appearance="outline" class="col-md-6 mb-1">
            <mat-label>Tipo de viaje<span class="asterisk">*</span></mat-label>
            <mat-select [formControl]="form.get('cargoModel.tripType.name')">
                <mat-option *ngFor="let type of tripTypesEnabled" [value]="type.name">
                    {{ type.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('cargoModel.tripType.name').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-6 mb-1">
            <mat-label>Perfil de riesgo<span class="optional">(opcional)</span></mat-label>
            <mat-select [formControl]="formAux.get('riskProfile')">
                <mat-option *ngFor="let profile of riskProfiles" [value]="profile">
                    {{ profile.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <p class="col-12 my-3 text-bold">
            Vehículo y conductor a escoltar
        </p>
        <mat-form-field appearance="outline" class="col-12">
            <mat-label>Placa del vehículo<span class="asterisk">*</span></mat-label>
            <input matInput appLicensePlate type="text" [formControl]="form.get('escortedVehicle.licensePlate')">
            <mat-error *ngIf="form.get('escortedVehicle.licensePlate').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
        <app-type-vehicle [inputFormControl]="formAux.get('vehicleType')" [options]="optionsTypeVehicle"
            class="col-md-6" [validate]="formValidate"></app-type-vehicle>
        <mat-form-field appearance="outline" class="col-md-6 mb-3">
            <mat-label>N° de Contenedor<span class="optional">(solo si aplica)</span></mat-label>
            <input matInput type="text" [formControl]="form.get('escortedVehicle.container')"
                angularFormsMask="CCCCDDDDDDD" containerNumber>
            <mat-error *ngIf="form.get('escortedVehicle.container').errors?.pattern">
                {{utils.giveMessageError('INVALID_CONTAINER_GENERAL') }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Marca del vehículo<span class="optional">(opcional)</span></mat-label>
            <input matInput type="text" [formControl]="form.get('escortedVehicle.brand')">
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Color del vehículo<span class="optional">(opcional)</span></mat-label>
            <input matInput type="text" [formControl]="form.get('escortedVehicle.color')">
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Tipo de identificación del conductor<span class="optional">(opcional)</span></mat-label>
            <mat-select [formControl]="formAux.get('documentType')" name="documentType">
                <mat-option *ngFor="let documentType of documentTypes" [value]="documentType">
                    {{documentType.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Documento del conductor a escoltar<span class="optional">(opcional)</span></mat-label>
            <input matInput onlyNumbersInt type="text" [formControl]="form.get('escortedVehicle.driver.document')">
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Nombre del conductor a escoltar<span class="asterisk">*</span></mat-label>
            <input matInput type="text" [formControl]="form.get('escortedVehicle.driver.name')">
            <mat-error *ngIf="form.get('escortedVehicle.driver.name').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Celular del conductor a escoltar<span class="asterisk">*</span></mat-label>
            <input matInput onlyNumbersInt type="text" [formControl]="form.get('escortedVehicle.driver.phoneNumber')">
            <mat-error *ngIf="form.get('escortedVehicle.driver.phoneNumber').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>


        <p class="col-12 my-3 text-bold">
            Vehículo del escolta
        </p>
        <app-type-vehicle [inputFormControl]="formAux.get('escortVehicleType')" [options]="optionsTypeVehicle"
            class="col-md-6" [validate]="formValidate"></app-type-vehicle>
        <app-vehicle-body-work-type [inputFormControl]="formAux.get('escortBodyWorkType')" class="col-md-6"
            [options]="{ title: 'Tipo de carrocería', 'initialVehicleTypeId': formAux.get('escortVehicleType')?.value?.id}">
        </app-vehicle-body-work-type>

        <div class="d-flex flex-wrap col-12 p-0">
            <div class="col-md-6">
                <p class="my-3 text-bold">
                    Tarifa
                </p>
                <mat-form-field appearance="outline">
                    <mat-label>Valor de la tarifa<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" currencyMask min="1" placeholder="$000.000"
                        [formControl]="form.get('shippingCost.rate')">
                    <mat-error class="mb-3" *ngIf="form.get('shippingCost.rate').errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="form.get('shippingCost.rate').errors?.min">
                        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'tarifa', 1) }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <p class="my-3 text-bold">
                    Flete
                </p>
                <mat-form-field appearance="outline">
                    <mat-label>
                        Valor del flete
                        <span class="optional">(Valor a pagar al conductor)</span>
                        <span class="asterisk">*</span>
                    </mat-label>
                    <input matInput type="text" currencyMask min="0" placeholder="$000.000"
                        [formControl]="formAux.get('cost')">
                    <mat-error class="mb-3" *ngIf="formAux.get('cost').errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="formAux.get('cost').errors?.min">
                        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'flete', 0) }}
                    </mat-error>
                </mat-form-field>
            </div>

        </div>

        <p class="col-12 my-3 text-bold">
            Anticipo
        </p>
        <div class="col-md-6 d-flex align-items-baseline">
            <p>Porcentaje de anticipo<span class="asterisk">*</span>:</p>
            <mat-form-field appearance="standard" class="col-6 col-md-4 col-lg-3">
                <input matInput onlyNumbersInt min="0" type="number" placeholder="0%"
                    [formControl]="form.get('shippingCost.advancePercentage')">
            </mat-form-field>
        </div>
        <div class="col-md-6 d-flex align-items-baseline">
            <p>Tiempo de pago del saldo<span class="asterisk">*</span>:</p>
            <mat-form-field appearance="standard" class="col-6 col-md-4 col-lg-3">
                <input matInput onlyNumbersInt min="0" type="number" placeholder="0 días"
                    [formControl]="form.get('shippingCost.paymentTime')">
            </mat-form-field>
        </div>

        <div class="col-12 mt-3">
            <p class="text-bold mb-3">Observaciones internas</p>
            <mat-form-field appearance="outline">
                <mat-label>Notas adicionales<span class="optional">(opcional)</span></mat-label>
                <input matInput type="text" formControlName="observation">
            </mat-form-field>
        </div>

        <div class="col-12 mt-3">
            <p class="text-bold mb-3">Requisitos adicionales para el escolta</p>
            <mat-form-field appearance="outline">
                <mat-label>Notas adicionales<span class="optional">(opcional)</span></mat-label>
                <input matInput type="text" formControlName="observationDriver">
            </mat-form-field>
        </div>

        <div class="col-md-4 offset-md-8">
            <button mat-raised-button color="accent" class="w-100" type="submit"
                [ngClass]="{'bg-color-primary':form.valid && formAux.valid, 'disabled-btn':form.invalid || formAux.invalid}">
                Crear
            </button>
        </div>
    </div>
</form>
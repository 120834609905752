<div class="administrative-configuration__container">
    <div class="d-flex justify-content-between align-items-baseline">
        <div class="text-strong mb-2">
            Configuración de facturación
        </div>
        <button
        mat-raised-button
        color="primary"
        class="bg-color-primary button-width-auto text-bold administrative-configuration__products__categories__info--button mt-2"
        (click)="startTour()">
            <i class="fas fa-road mr-2"></i>
            <span>Iniciar tour</span>
        </button>
    </div>
    <!-- 1. Tipo de documento de factura -->
    <div id="typeBillStep" class="administrative-configuration__type-bill">
        <div class="text-strong administrative-configuration__type-bill__title">1. Tipo de documento de factura</div>
        <div class="administrative-configuration__type-bill__input">
            <mat-form-field class="mt-2">
                <mat-label>Seleccione el tipo de documento</mat-label>
                <mat-select 
                (selectionChange)="onSelectTypeBill($event)" 
                [value]="typeBill"
                [compareWith]="compareTypeBill"
                >
                <mat-option *ngFor="let typeBill of listTypeBill"
                    [value]="typeBill">{{typeBill.description}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div 
            *ngIf="messageListTypeBill" 
            class="alert alert-danger" 
            role="alert">
                {{messageListTypeBill}}
            </div>
        </div>
    </div>
    <!-- 2. Productos -->
    <div id="productsStep" class="administrative-configuration__products">
        <div class="text-strong administrative-configuration__products__title">2. Productos</div>
        <div class="administrative-configuration__products__categories">
            <!-- Cities section -->
            <div id="citiesStep">
                <div class="text-strong administrative-configuration__products__categories__title mt-3">2.1 Ciudades</div>
                <div class="administrative-configuration__products__categories__info d-flex flex-column">
                    <p class="my-2">Para que las facturas se generen correctamente, es importante configurar el <b><u>codigo retefuente</u></b> y el <b><u>producto Siigo</u></b> en cada ciudad.</p>
                    <app-autocomplete-city [inputFormControl]="cityControl" [options]="optionsCity">
                    </app-autocomplete-city>
                </div>
            </div>
            <!-- Additional services section -->
           <div id="additionalServicesStep">
            <div class="text-strong administrative-configuration__products__categories__title mt-3">2.2 Servicios adicionales</div>
            <div class="administrative-configuration__products__categories__info">
                <p class="my-2">Cada servicio adicional debe estar vinculado a un producto Siigo.</p>
                <div class="administrative-configuration__products__categories__info--cards" *ngIf="listAdditionalServices.length; else emptyAdditionalServices">
                    <ng-container *ngFor="let additionalService of listAdditionalServices; let i = index">
                        <!-- Additional services card -->
                        <div class="card my-2">
                            <div class="card-header">
                                {{additionalService.name}}
                            </div>
                            <div class="card-body">
                                <div class="text-strong administrative-configuration__products__categories__info--text">Producto siigo asociado</div>
                                <mat-form-field appearance="standard">
                                    <mat-label>Producto Siigo</mat-label>
                                    <mat-select 
                                    #selectList 
                                    (selectionChange)="onProductSiigoChange($event, i)"
                                    [value]="additionalService?.productSiigoId"
                                    [compareWith]="compareProductSiigo"
                                    [disabled]="isLoadingProducts"
                                    >
                                        <mat-option *ngFor="let option of listProductSiigo" [value]="option.code">
                                            {{option.name}}
                                        </mat-option>
                                    </mat-select>
                                    <i *ngIf="isLoadingProducts" class="fas fa-spinner fa-spin"></i>
                                </mat-form-field>
                                <div 
                                *ngIf="messageListProductSiigo" 
                                class="alert alert-danger" 
                                role="alert">
                                    {{messageListProductSiigo}}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <ng-template #emptyAdditionalServices>
                    <div class="text-strong administrative-configuration__products__categories__info--text">{{ messageListAdditionalServices}} configurados para la empresa</div>
                </ng-template>
            </div>
           </div>
        </div>  
    </div>
    <!-- 3. Cost center section -->
    <div id="costCenterStep" class="administrative-configuration__cost-center mt-2">
        <div class="text-strong administrative-configuration__cost-center__title">3. Centros de costo</div>
        <div class="administrative-configuration__cost-center__info">
            <p class="my-2">Para que las facturas se generen correctamente, es necesario configurar el <b><u>centro de costo</u></b> por cada empresa registrada.</p>
            <p>
                <u class="cursor-pointer text-bold color-secondary-dark administrative-configuration__cost-center__info--button" [routerLink]="['administration/companies/list']">
                    <i class="fas fa-sign-in-alt"></i>
                    Ir a la lista de empresas
                </u>
            </p>
        </div>
    </div>
    <!-- 4. Enable type 12 invoice in siigo -->
    <div id="enableType12InvoiceStep" class="administrative-configuration__enable-type-12-invoice mt-3">
        <div class="text-strong administrative-configuration__enable-type-12-invoice__title">4. Habilitar factura tipo 12 en Siigo</div>
        <div class="administrative-configuration__enable-type-12-invoice__info">
            <p class="my-2">Para que las facturas se generen correctamente, es necesario <b><u>habilitar la factura tipo 12 en Siigo</u></b> . A continuacion encontraras una breve explicacion de como hacerlo en la plataforma de Siigo.</p>
            <p (click)="openModalCargoPDF('assets/pdfs/Configuracion-requerida-para-siigo.pdf')" class="cursor-pointer text-bold color-secondary-dark administrative-configuration__enable-type-12-invoice__info--button"><u><i class="fas fa-eye"></i> Ver guía explicativa</u></p>
        </div>
    </div>
</div>

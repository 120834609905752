import { Pipe, PipeTransform } from '@angular/core';
import { Global } from '../resources/global';

@Pipe({
    name: 'guideState'
})
export class GuideStatePipe implements PipeTransform {

    constructor(
        private global: Global
    ) { }

    transform(type: string) {
        if (this.global.statusGuide[type])
            return this.global.statusGuide[type];
        return type;
    }
}


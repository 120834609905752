import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './../layout/main/main.component';
import { PrivacyPolicyComponent } from 'src/app/public/privacyPolicy/privacyPolicy.component';
import { PersonaDataPoliciesComponent } from '../public/personal-data/personal-data-policies.component';
import { TermsConditionsComponent } from 'src/app/public/terms-conditions/terms-conditions.component';
import { AboutUsComponent } from 'src/app/public/aboutUs/aboutUs.component';
import { ContactComponent } from '../public/contact/contact.component'
import { DheaderComponent } from '../public/dheader/dheader.component';
import { DshippersComponent } from '../public/dshippers/dshippers.component';
import { DcarriersComponent } from '../public/dcarriers/dcarriers.component';
import { QuestionsComponent } from '../public/questions/questions.component';
import { TrackGuideComponent } from '../public/track-guide/track-guide.component';
import { CompaniesResolver } from './resolves/companies.resolve';
import { SuggestionsComponent } from '../public/suggestions/suggestions.component';
import { HomeLandingComponent } from '../public/home/home-landing.component';
import { TestimonialsComponent } from '../public/testimonials/testimonials.component';
import { GpsTypeResolver } from './resolves/gpsType.resolve';
import { LegalFrameworkManifestsComponent } from '../public/legal-framework-manifests/legal-framework-manifests.component';
import { BascPolicyComponent } from '../public/bascPolicy/bascPolicy.component';
import { QuickPayComponent } from '../public/quick-pay/quick-pay.component';
import { HabeasDataComponent } from '../public/habeasData/habeasData.component';
import { EthicalLineComponent } from '../public/ethical-line/ethical-line.component';
import { PublicGuard } from './guards/public.guard';
import { PublicCargoEvidencesComponent } from '../public/public-cargo-evidences/public-cargo-evidences.component';
import { SecurityRulesResolver } from './resolves/security-rules.resolve';
import { NotificationResolver } from './resolves/notification.resolve';
import { TokenHandlerComponent } from '../public/token-handler/token-handler.component';
import { AuthRedirectGuard } from './guards/auth-redirect.guard';
import { UserResolver } from './resolves/user.resolve';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    // canActivate: [UserGuard],
    resolve: {
      UserResolver,
      SecurityRulesResolver,
      NotificationResolver
    },
    children: [
      {
        path: 'home',
        component: HomeLandingComponent,
        canActivate: [PublicGuard]
      },
      {
        path: 'contact',
        component: ContactComponent,
        canActivate: [PublicGuard]
      },
      {
        path: 'ethical-line',
        component: EthicalLineComponent,
        canActivate: [PublicGuard]
      },
      {
        path: 'dheader',
        component: DheaderComponent,
        canActivate: [PublicGuard]
      },
      {
        path: 'shippers',
        component: DshippersComponent,
        canActivate: [PublicGuard]
      },
      {
        path: 'carriers',
        component: DcarriersComponent,
        canActivate: [PublicGuard]
      },
      {
        path: 'questions',
        component: QuestionsComponent,
        canActivate: [PublicGuard]
      },
      {
        path: 'suggestions',
        component: SuggestionsComponent,
        canActivate: [PublicGuard]
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'habeas-data',
        component: HabeasDataComponent
      },
      {
        path: 'personal-data-policies',
        component: PersonaDataPoliciesComponent
      },
      {
        path: 'basc-policies',
        component: BascPolicyComponent
      },
      {
        path: 'terms-manifest',
        component: LegalFrameworkManifestsComponent
      },
      {
        path: 'terms',
        component: TermsConditionsComponent
      },
      {
        path: 'about-us',
        component: AboutUsComponent
      },
      {
        path: 'testimonials',
        component: TestimonialsComponent
      },
      {
        path: 'quick-pay',
        component: QuickPayComponent
      },
      {
        path: 'cargo-evidences',
        component: PublicCargoEvidencesComponent
      },
      {
        path: 'auth-redirect',
        component: TokenHandlerComponent,
        canActivate: [AuthRedirectGuard]
      },
      {
        path: 'administration',
        resolve: {
          CompaniesResolver,
          GpsTypeResolver
        },
        loadChildren: () => import('./../modules/administration/administration.module').then(m => m.AdministrationModule)
      },
      {
        path: 'collection-point',
        resolve: {
          CompaniesResolver
        },
        loadChildren: () => import('./../modules/collection-point/collection-point.module').then(m => m.CollectionPointModule)
      },
      {
        path: 'cargo',
        resolve: {
          CompaniesResolver,
          GpsTypeResolver
        },
        loadChildren: () => import('./../modules/cargo/cargo.module').then(m => m.CargoModule)
      },
      {
        path: 'guide',
        resolve: {
          CompaniesResolver
        },
        loadChildren: () => import('./../modules/guides/guide.module').then(m => m.GuideModule)
      },
      {
        path: 'routes',
        resolve: {
          CompaniesResolver
        },
        loadChildren: () => import('./../modules/routes/routes.module').then(m => m.RoutesModule)
      },
      {
        path: 'reports',
        resolve: {
          CompaniesResolver
        },
        loadChildren: () => import('./../modules/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'settings',
        resolve: {
          CompaniesResolver
        },
        loadChildren: () => import('./../modules/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'help-desk',
        loadChildren: () => import('./../modules/help-desk/help-desk.module').then(m => m.HelpDeskModule)
      },
      {
        path: 'monitoring',
        loadChildren: () => import('./../modules/monitoring/monitoring.module').then(m => m.MonitoringModule)
      },
      {
        path: 'analytics',
        loadChildren: () => import('./../modules/analytics/analytics.module').then(m => m.AnalyticsModule)
      },
      {
        path: 'sig',
        loadChildren: () => import('./../modules/sig/sig.module').then(m => m.SigModule)
      },
      {
        path: 'routing',
        resolve: {
          CompaniesResolver
        },
        loadChildren: () => import('./../modules/routing/routing.module').then(m => m.RoutingModule)
      },
      {
        path: 'service-request-form',
        loadChildren: () => import('./../modules/service-request-form/service-request-form.module')
          .then(m => m.ServiceRequestFormModule)
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' }
    ],
  },
  {
    path: 'account',
    loadChildren: () => import('./../modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'track-guide',
    component: TrackGuideComponent,
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class MainRoutingModule {

}

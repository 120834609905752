<div class="modal-body wrapper-modal position-relative w-100 d-flex flex-column">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <section class="px-2 px-md-5" *ngIf="cargosWithoutErrors?.length">
        <h2 class="text-bold text-center my-5 text-balance">{{title}}</h2>
        <div class="cargo-created-card" *ngFor="let cargo of cargosWithoutErrors">
            <h5 class="text-bold mb-0">ID.{{cargo.consecutive}}</h5>
            <div class="color-secondary-dark underline" (click)="goToCargo(cargo)">
                <i class="fas fa-eye"></i>
                <span class="text-bold">Ir al detalle</span>
            </div>
        </div>
    </section>
    <section class="pax-2 px-md-5" *ngIf="cargosWithErrors?.length">
        <h2 class="text-bold text-center my-5">Ocurrió un error al crear
            {{cargosWithErrors.length === 1
            ? 'el siguiente servicio'
            : 'los siguientes servicios'}}
        </h2>
        <div class="cargo-created-card" *ngFor="let cargo of cargosWithErrors">
            <h5 class="text-bold mb-0">ID.{{cargo?.consecutive}}</h5>
        </div>
    </section>
    <div class="button-container mt-4">
        <button mat-raised-button color="accent" type="button" (click)="goToListCargos()" *ngIf="hasCommonCargoList">
            <span class="text-bold">Ir a {{hasCommonCargoList.state}}</span>
        </button>
    </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { User } from 'src/app/core/interfaces/user';
import { AuthService } from 'src/app/core/services/authentication.service';
import { AdminUsersService } from '../../administration/admin-users/admin-users.service';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/core/resources/utils';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { ChangeDriverComponent } from '../change-driver/change-driver.component';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { DriverCargoService } from '../driver-cargo/driver-cargo.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-person-info',
  templateUrl: './person-info.component.html',
  styleUrls: ['./person-info.component.scss']
})
export class PersonInfoComponent implements OnInit {
  // Inputs
  @Input() driver: User;
  @Input() cargo: Cargo;
  @Input() vehicle: Vehicle;
  @Input() isOnlyDriver: boolean;
  @Input() showActionsButtons: boolean;
  @Input() showAssignButton: boolean;
  // Outputs
  @Output() emitCheckValidation: EventEmitter<string> = new EventEmitter();
  @Output() emitOpenDetailDriver: EventEmitter<boolean> = new EventEmitter();
  @Output() emitRefreshDriver: EventEmitter<string> = new EventEmitter();
  public environment = environment;
  public permission = Permission;
  userStateByValidationRules: { active: boolean, description: string } = { active: false, description: '' };
  constructor(
    public authService: AuthService,
    public adminUserService: AdminUsersService,
    public utils: Utils,
    public permissionRole: PermissionRole,
    public dialog: MatDialog,
    private snackbarService: SnackBarService,
    private driverCargoService: DriverCargoService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.driver) {
      this.adminUserService.getUserStateByValidationRules(this.driver, 'Driver')
      .then((userStateByValidationRules) => {
        this.userStateByValidationRules = userStateByValidationRules;
      })
      .catch((error) => {
        console.error('error', error);
      });
    }
  }
  confirmAssignChangeDriver(type: string) {
    const formControl = new FormControl();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: type && type === 'change' ? 'Modificación de conductor' : 'Asignación de conductor',
      label: 'Ingresa el número de documento',
      idVehicle: this.vehicle.id,
      options: {
        typeUser: 'driver',
      },
      inputFormControl: formControl,
      linkDriverWithoutVehicle: true
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const modalDialog = this.dialog.open(ChangeDriverComponent, dialogConfig);
    modalDialog.afterClosed().subscribe((result: { state: boolean, data: User }) => {
      if (result && result.state && result.data && result.data.information && result.data.information.document) this.assignChangeDriver(result.data.information.document, this.cargo.id)
    });

  }

  assignChangeDriver(document: string, cargoId: string) {
    const assignChangeDriverObserver = {
      next: () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(Fmt.string(ServiceMessages.ASSIGN_DRIVER_SUCCESS, 'asignado'));
        this.emitRefreshDriver.emit(this.cargo.consecutive.toString());
      },
      error: () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    };
    this.spinner.show();
    this.driverCargoService.changeAssignSecondDriver(`&driverDocument=${document}`, cargoId).subscribe(assignChangeDriverObserver);
  }

  confirmDeleteSecondDriver(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: '¿Estás seguro que deseas eliminar el segundo conductor asignado?',
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) this.deleteSecondDriver(this.cargo.id);
    });
  }

  deleteSecondDriver(cargoId: string) {
    const assignChangeDriverObserver = {
      next: () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(Fmt.string(ServiceMessages.ASSIGN_DRIVER_SUCCESS, 'eliminado'));
        this.emitRefreshDriver.emit(this.cargo.consecutive.toString());
      },
      error: () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    };
    this.spinner.show();
    this.driverCargoService.deleteSecondDriver(cargoId).subscribe(assignChangeDriverObserver);
  }
  // GETTERS
  get scoreDriver() {
    try {
      return Array(Math.round(this.driver.teclogiScore.globalScore)).fill(0).map((x, i) => i);
    } catch (e) {
      return 0;
    }
  }

  get showDriverDetail(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.readDriverDetails);
  }

  get showPhoneDriver(): boolean {
    return this.permissionRole.hasPermission(Permission.cargo.module, Permission.cargo.readDriverDetails);
  }

}

// Angular
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MainRoutingModule } from './main-routing.module';
import { SharedModule } from './shared.module';
import { RootComponent } from './../layout/root/root.component';
import { MainComponent } from './../layout/main/main.component';
import { HomeComponent } from './../modules/home/home.component';
import { ContactComponent } from './../public/contact/contact.component';
import { DheaderComponent } from './../public/dheader/dheader.component';
import { DshippersComponent } from './../public/dshippers/dshippers.component';
import { DcarriersComponent } from './../public/dcarriers/dcarriers.component';
import { QuestionsComponent } from './../public/questions/questions.component';
import { PrivacyPolicyComponent } from 'src/app/public/privacyPolicy/privacyPolicy.component';
import { TermsConditionsComponent } from 'src/app/public/terms-conditions/terms-conditions.component';
import { AboutUsComponent } from 'src/app/public/aboutUs/aboutUs.component';
import { PersonaDataPoliciesComponent } from '../public/personal-data/personal-data-policies.component';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(es);
import { BrowserModule } from '@angular/platform-browser';
import { CargoModule } from '../modules/cargo/cargo.module';
import { ReportsModule } from '../modules/reports/reports.module';
import { AdministrationModule } from '../modules/administration/administration.module';
import { GuideModule } from '../modules/guides/guide.module';
import { CollectionPointModule } from '../modules/collection-point/collection-point.module';
import { SettingsModule } from '../modules/settings/settings.module';
import { AccountModule } from '../modules/account/account.module';
import { HelpDeskModule } from '../modules/help-desk/help-desk.module';
import { MonitoringModule } from '../modules/monitoring/monitoring.module';
import { AnalyticsModule } from '../modules/analytics/analytics.module';
import { SuggestionsComponent } from '../public/suggestions/suggestions.component';
import { DtrasnporterComponent } from '../public/dtrasnporter/dtransporter.component';
import { DgeneratorComponent } from '../public/dgenerator/dgenerator.component';
import { HomeLandingComponent } from '../public/home/home-landing.component';
import { CarouselComponent } from '../public/home/carrousel/carousel.component';
import { TestimonialsComponent } from '../public/testimonials/testimonials.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LegalFrameworkManifestsComponent } from '../public/legal-framework-manifests/legal-framework-manifests.component';
import { BascPolicyComponent } from '../public/bascPolicy/bascPolicy.component';
import { RoutesModule } from '../modules/routes/routes.module';
import { HabeasDataComponent } from '../public/habeasData/habeasData.component';
import { EthicalLineComponent } from '../public/ethical-line/ethical-line.component';
import { RoutingModule } from '../modules/routing/routing.module';
import { PublicCargoEvidencesComponent } from '../public/public-cargo-evidences/public-cargo-evidences.component';
import { PublicCargoEvidencesDetailComponent } from '../public/public-cargo-evidences-detail/public-cargo-evidences-detail.component';
import { ServiceRequestFormModule } from '../modules/service-request-form/service-request-form.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    MainRoutingModule,
    AdministrationModule,
    ReportsModule,
    CargoModule,
    ServiceRequestFormModule,
    GuideModule,
    RoutesModule,
    CollectionPointModule,
    SettingsModule,
    AccountModule,
    HelpDeskModule,
    MonitoringModule,
    AnalyticsModule,
    RoutingModule,
  ],
  declarations: [
    RootComponent,
    MainComponent,
    HomeComponent,
    ContactComponent,
    EthicalLineComponent,
    DheaderComponent,
    DshippersComponent,
    DcarriersComponent,
    QuestionsComponent,
    SuggestionsComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    AboutUsComponent,
    TestimonialsComponent,
    PersonaDataPoliciesComponent,
    LegalFrameworkManifestsComponent,
    DtrasnporterComponent,
    DgeneratorComponent,
    HomeLandingComponent,
    CarouselComponent,
    BascPolicyComponent,
    HabeasDataComponent,
    PublicCargoEvidencesComponent,
    PublicCargoEvidencesDetailComponent
  ],
  entryComponents: [],
  providers: [],
  bootstrap: [RootComponent],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class MainModule {

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Permission } from 'src/app/core/resources/permission';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { CargoAdvancePercentageComponent } from '../cargo-advance-percentage/cargo-advance-percentage.component';
import { CargoDetailService } from '../cargo-detail/cargo-detail.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '../../account/account.service';
import { User } from 'src/app/core/interfaces/user';
import { VehiclesService } from '../../administration/vehicles/list-vehicles.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';
import { AdminUsersService } from '../../administration/admin-users/admin-users.service';
@Component({
  selector: 'app-generate-manifest-action',
  templateUrl: './generate-manifest-action.component.html',
  styleUrls: ['./generate-manifest-action.component.scss']
})
export class GenerateManifestActionComponent implements OnInit {
  @Input() cargo: Cargo;
  @Input() type: 'cargo-option' | 'button-detail' | 'button-tracking';
  // Output
  @Output() refreshLoad = new EventEmitter();
  permission = Permission;
  automaticManifestGeneration: boolean = true;
  userStateByValidationRules: { active: boolean, description: string } = { active: false, description: '' };
  constructor(
    public dialog: MatDialog,
    private cargoDetailService: CargoDetailService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private accountService: AccountService,
    private vehiclesService: VehiclesService,
    private authService: AuthService,
    private utils: Utils,
    private adminUsersService: AdminUsersService
  ) { }

  async ngOnInit() {
    let settings = this.authService.getSettingsSaasCompany();
    if (settings && settings.length) {
      const foundSetting = settings.find(setting => setting.id === 'automaticManifestGeneration');
      this.automaticManifestGeneration = foundSetting && this.utils.isDefined(foundSetting.active) ? foundSetting.active : false;
    }
  }

  public async openDialogNewAdvancePercentage() {
    let areValid: boolean = await this.validationsToGenerateManifest();
    if (!areValid) return;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
      viewType: "viewAdvance"
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(
      CargoAdvancePercentageComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) this.generateManifest();
    });
  }

  private async validationsToGenerateManifest() {
    if (this.cargo) {
      // Second driver validation
      if (this.cargo.secondDriver) {
        let isSecondDriverActive = await this.isSecondDriverActive();
        if (!isSecondDriverActive) {
          this.snackBarService.openSnackBar(ServiceMessages.DRIVER_NOT_ACTIVE, undefined, 'error');
          return false;
        }
      }
      // Rate and cost 0 validation
      if (!this.isShippingCostZero()) return true;
      if (!this.isValidSaasDocument()) {
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_RETRY_INDICATION, undefined, 'error');
        return false;
      }
      const vehicle: Vehicle = await this.getVehicle();
      if (!this.isValidVehicle(vehicle)) {
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_RETRY_INDICATION, undefined, 'error');
        return false;
      }
      if (vehicle.administrator && vehicle.administrator.document && vehicle.administrator.document === this.authService.getCompanySaaS().companyId.slice(0, 9)) return true;
      if (vehicle.owner && vehicle.owner.document && vehicle.owner.document === this.authService.getCompanySaaS().companyId.slice(0, 9)) return true;
      this.snackBarService.openSnackBar(ServiceMessages.SAME_OWNER_VEHICLE_LOAD_TO_RATE_COST_ZERO, undefined, 'error');
      return false;
    } else {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_RETRY_INDICATION, undefined, 'error');
      return false;
    }
  }

  private isShippingCostZero(): boolean {
    const { shippingCost } = this.cargo;
    return !!shippingCost &&
      (shippingCost.rate === 0 || shippingCost.freightCost === 0 || shippingCost.totalCost === 0);
  }

  private isValidSaasDocument(): boolean {
    return !!(this.authService && this.authService.getCompanySaaS() && this.authService.getCompanySaaS().companyId);
  }

  private isValidVehicle(vehicle: Vehicle): boolean {
    return !!vehicle && ((!!vehicle.owner && !!vehicle.owner.document) || (!!vehicle.administrator && !!vehicle.administrator.document));
  }

  private generateManifest() {
    const observer = {
      next: (cargo: Cargo) => {
        this.spinner.hide();
        if (this.type === 'button-detail' || this.type === 'cargo-option') this.refreshLoad.emit(cargo.consecutive.toString());
        if (cargo && cargo.manifestError && cargo.manifestError.error) this.snackBarService.openSnackBar(cargo.manifestError.error, undefined, 'error');
        else if (cargo && cargo.manifest) this.snackBarService.openSnackBar(ServiceMessages.CARGO_GENERATE_MANIFEST_SUCCESS, undefined, 'success');
        else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_RETRY_INDICATION, undefined, 'error');
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    };
    this.spinner.show();
    this.cargoDetailService.generateManifest(this.cargo.id).subscribe(observer);
  }

  async isSecondDriverActive() {
    try {
      this.spinner.show();
      const driver: User = await this.accountService.validateEntity(1, this.cargo.secondDriver).toPromise();
      if (driver && driver.state && this.utils.isDefined(driver.state.active)) {
        return await this.checkStateDriver(driver);
      }
      return false;
    } catch (error) {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      return false;
    } finally {
      this.spinner.hide();
    }
  }

  async checkStateDriver(driver: User) {
    const { active } = await this.adminUsersService.getUserStateByValidationRules(driver, 'Driver');
    return active;
  }

  public async getVehicle() {
    try {
      this.spinner.show();
      const vehicle: Vehicle = await this.vehiclesService.getVehicle(this.cargo.licensePlate).toPromise();
      return vehicle;
    } catch (error) {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      return null;
    } finally {
      this.spinner.hide();
    }
  }

  // GETTERS
  get cargoStateEnum(): typeof CargoStateEnum {
    return CargoStateEnum;
  }

}

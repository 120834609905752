<div class="row m-0 p-0 border-bottom pt-3 pb-4">
    <!-- Image -->
    <div class="col-lg-2 mb-3 mb-lg-0 driver__picture__column" *ngIf="driver.information.name ">
      <figure class="driver__picture driver__picture--loaded" *ngIf="driver.profilePicture">
        <img [src]="driver.profilePictureUrl" alt="">
      </figure>

      <figure class="driver__picture" *ngIf="!driver.profilePicture || !driver.information.name">
        <img src="/assets/svg/icons/icon-profile.png" alt="">
      </figure>

      <div class="driver__score__column">
        <div class="text-center driver__score" *ngIf="driver.teclogiScore && driver.state && utils.isDefined(driver.state.active) && userStateByValidationRules?.active">
          <img *ngFor="let star of scoreDriver" src="/assets/svg/icons/icon-star-purple.svg"
            [ngStyle]="{'filter':driver?.teclogiScore?.globalScore<=2&&'invert(32%) sepia(80%) saturate(7072%) hue-rotate(353deg) brightness(95%) contrast(133%)'}">
        </div>
      </div>
    </div>
    <!-- Detail -->
    <div class="col-lg-9">
      <div class="row mx-0 px-2 driver-info">
        <!-- Driver name -->
        <div class="col-md-4 col-sm-6">
          <div class="field__card">
            <label class="field__card--label m-0">
              Nombre
            </label>
            <span class="field__card--value" [ngClass]="{'underline': showDriverDetail}" (click)="emitOpenDetailDriver.emit(true)">
              {{driver.information && driver.information.name ? (driver.information.name | capitalizeText):
              cargo.driver}}
            </span>
          </div>
        </div>

        <!-- City -->
        <div class="col-md-4 col-sm-6" *ngIf="driver.city">
          <div class="field__card">
            <label class="field__card--label m-0">Ciudad</label>
            <span class="field__card--value">
              {{driver.city}}
            </span>
          </div>
        </div>

        <!-- State Driver -->
        <div class="col-md-4 col-sm-6" *ngIf="driver && authService.getCompany().companyId === authService.getCompanySaaS().companyId">
          <div class="field__card">
            <label class="field__card--label m-0">Estado Conductor</label>
            <span class="field__card--value text__color"
              [ngClass]="{'color--error': !driver.state || (utils.isDefined(driver.state.active) && !userStateByValidationRules?.active && (userStateByValidationRules?.description !== 'Pending' || userStateByValidationRules?.description !== 'Estudio de seguridad en proceso')),'color--warning': (utils.isDefined(driver.state.active) && !userStateByValidationRules?.active && (userStateByValidationRules?.description === 'Pending' || userStateByValidationRules?.description === 'Estudio de seguridad en proceso')), 'color--success': driver.state && userStateByValidationRules?.active}">
              {{userStateByValidationRules?.description}}
            </span>
          </div>
        </div>

        <!-- Phone -->
        <div class="col-md-4 col-sm-6" *ngIf="driver.phone && showPhoneDriver">
          <div class="field__card">
            <label class="field__card--label m-0">Celular</label>
            <span class="field__card--value">
              {{driver && driver.phone ? (driver.phone | phoneNumber): '-'}}
            </span>
          </div>
        </div>

        <!-- Address -->
        <div class="col-md-4 col-sm-6" *ngIf="driver.address">
          <div class="field__card"> 
            <label class="field__card--label m-0">Dirección</label>
            <span class="field__card--value">
              {{driver.address}}
            </span>
          </div>
        </div>

        <!-- Type id  -->
        <div class="col-md-4 col-sm-6" *ngIf="isOnlyDriver && driver.information && driver.information.documentTypeName">
          <div class="field__card">
            <label class="field__card--label">Tipo de identificación</label>
            <span class="field__card--value">
              {{driver.information.documentTypeName}}
            </span>
          </div>
        </div>

        <!-- Id number -->
        <div class="col-md-4 col-sm-6" *ngIf="isOnlyDriver && driver.information && driver.information.document">
          <div class="field__card">
            <label class="field__card--label">N° identificación</label>
            <span class="field__card--value">
              {{driver.information.document}}
            </span>
          </div>
        </div>

        <!-- Operation -->
        <div class="col-md-4 col-sm-6" *ngIf="isOnlyDriver && driver.information && driver.operationId">
          <div class="field__card">
            <label class="field__card--label">Operación</label>
            <span class="field__card--value">
              {{driver.operationId | companyName}}
            </span>
          </div>
        </div>

        <!-- Score Teclogi -->
        <div class="col-md-4 col-sm-6" *ngIf="driver.teclogiScore && driver.state && utils.isDefined(driver.state.active) && userStateByValidationRules?.active">
          <div class="field__card">
            <label class="field__card--label m-0">Calificación</label>
            <span class="field__card--value " [ngStyle]="{'color':driver.teclogiScore.globalScore<=2?'red':'black'}">
              {{driver.teclogiScore.globalScore | number}}
            </span>
          </div>
        </div>

      </div>
    </div>
     <!-- Actions -->
    <ng-container *ngIf="!cargo?.manifestAuthorization">
      <div *ngIf="showActionsButtons" class="col-lg-1 d-flex flex-row flex-lg-column mt-3 mt-lg-0 second-driver__actions">
        <i 
        permission-display 
        [module]="permission.cargo.module"
        [functionality]="permission.cargo.changeAssignSecondDriver"
        class="fas fa-exchange-alt color-secondary-dark cursor-pointer" 
        (click)="confirmAssignChangeDriver('change')"
        ></i>
        <i 
        permission-display 
        [module]="permission.cargo.module"
        [functionality]="permission.cargo.deleteSecondDriver"
        class="far fa-trash-alt second-driver__actions--delete-color cursor-pointer" 
        (click)="confirmDeleteSecondDriver()"
        ></i>
      </div>
      <div class="col-12 d-flex justify-content-center justify-content-md-end mt-3 mt-md-1">
        <button
          *ngIf="showAssignButton"
          permission-display 
          [module]="permission.cargo.module"
          [functionality]="permission.cargo.changeAssignSecondDriver"
          mat-raised-button
          class="m-0 secondary-sharp person-info__actions"
          color="primary"
          (click)="confirmAssignChangeDriver('assign')"
          >
            <div><i class="fas fa-plus-circle mr-2"></i><span>Añadir conductor</span></div>
          </button>
      </div>
    </ng-container>
    <!-- User and vehicle validation -->
    <div class="col-12 button-container"
      >
        <button 
        *ngIf="
          (!utils.isDefined(driver?.state) ||
          !utils.isDefined(driver?.state?.active) ||
          (!driver?.state?.active && driver?.state?.description === 'Pending')) &&
          (vehicle?.state?.description !== 'Pending' || 
          !vehicle?.state || 
          !vehicle?.state?.active)" 
        mat-raised-button 
        class="bg-color-primary" 
        color="primary" 
        (click)="emitCheckValidation.emit('user')">
          Validar estudio de seguridad
        </button>
    </div>
</div>
<form class="modal-body wrapper-modal py-5 px-2 px-sm-5" [formGroup]="form" (ngSubmit)="onSubmit()">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="confirmClose()"></i>
    <h2 class="text-bold text-center mx-2">
        Requisitos de cumplido
    </h2>
    <div class="scrollable-div">
        <div class="d-flex flex-wrap mt-3">
            <div class="d-flex flex-column col-md-6">
                <mat-form-field appearance="outline" *ngIf="hasFullEditPermission">
                    <mat-label class="text-bold">Estado del cumplido <span class="asterisk">*</span></mat-label>
                    <mat-select formControlName="generalState">
                        <mat-option *ngFor="let complimentState of complimentStates" [value]="complimentState">
                            {{complimentState}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form?.get('generalState')?.errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                </mat-form-field>
                <div *ngIf="!hasFullEditPermission">
                    <p class="text-bold mb-1">Estado del cumplido:</p>
                    <p>{{form?.get('generalState')?.value ? form.get('generalState').value : 'Sin validar'}}</p>
                </div>

                <mat-form-field appearance="outline" *ngIf="hasFullEditPermission">
                    <mat-label class="text-bold">Observaciones del cumplido</mat-label>
                    <input matInput type="text" formControlName="observations">
                </mat-form-field>
                <div *ngIf="!hasFullEditPermission">
                    <p class="text-bold mb-1">Observaciones del cumplido:</p>
                    <p>{{form?.get('observations')?.value ? form.get('observations').value : '-'}}</p>
                </div>
            </div>
            <table *ngIf="fulfillmentRequirements?.length" class="d-none d-sm-table col-md-6">
                <tr>
                    <th>Requisito</th>
                    <th>Validado</th>
                    <th>Novedad</th>
                </tr>
                <tr *ngFor="let document of fulfillmentRequirements?.controls">
                    <td data-cell="Requisito">{{document?.get('title')?.value}}</td>
                    <td data-cell="Validado">{{document?.get('fulfillmentState')?.value?.includes('Validado') ? 'Si' :
                        'No'}}</td>
                    <td data-cell="Novedad">{{document?.get('observations')?.value ? 'Si' : 'No'}}</td>
                </tr>
            </table>
            <table *ngIf="fulfillmentRequirements?.length" class="d-sm-none col-12">
                <tr *ngFor="let document of fulfillmentRequirements?.controls">
                    <td data-cell="Requisito">
                        <p class="m-1">
                            <span class="text-bold">Requisito:</span> {{document?.get('title')?.value}}
                        </p>
                        <p class="m-1">
                            <span class="text-bold">Validado:</span>
                            {{document?.get('fulfillmentState')?.value?.includes('Validado') ? 'Si' :
                            'No'}}
                        </p>
                        <p class="m-1">
                            <span class="text-bold">Novedad:</span> {{document?.get('observations')?.value ? 'Si' :
                            'No'}}
                        </p>
                    </td>
                </tr>
            </table>
        </div>
        <div *ngFor="let document of fulfillmentRequirements?.controls; let i = index" class="d-flex flex-wrap mt-3">
            <div class="col-md-12 wrapper-label mb-3">
                <label class="mb-0">
                    <span class="text-bold fs-20">{{document?.get('title')?.value}}</span>
                    <span *ngIf="document?.get('physicalDelivery')?.value" class="fs-16"> (Se requiere físico)</span>
                </label>
                <button type="button"
                    *ngIf="document?.get('example')?.value && (hasFullEditPermission || hasPartialEditPermission)"
                    class="btn float-right color-secondary-dark text-bold"
                    (click)="getComplimentFile(document.get('example').value)">
                    Ver ejemplo
                    <i class="fas fa-eye"></i>
                </button>
            </div>
            <div class="col-12 mb-4" [ngClass]="{'col-md-6':hasActiveCompliments('Driver',i)}"
                *ngIf="hasActiveCompliments('Fulfillment Team',i) || hasFullEditPermission || hasPartialEditPermission">
                <label class="text-bold">Subidos por equipo de cumplidos</label>
                <p class="static-p" *ngIf="hasActiveCompliments('Driver',i)"></p>
                <div class="popup-photos">
                    <div class="photo" *ngFor="let image of getFulfillmentDocuments(i)?.controls; let j = index;"
                        [ngClass]="{'d-none':(!hasDeletedPermission && !image?.get('active')?.value)||!isFulfillmentTeamDocument(i,j)}">
                        <i class="fa fa-times-circle icon-close icon-action" (click)="confirmDelete( i, j )"
                            *ngIf="(hasFullEditPermission || hasPartialEditPermission) && image?.get('active')?.value"
                            matTooltip="Eliminar"></i>
                        <i class="fas fa-info-circle icon-action" *ngIf="hasFingerPrintPermission"
                            [matTooltip]="getFingerprintToolTip(image.value)" matTooltipClass="custom-tooltip"></i>
                        <p class="deleted-text" *ngIf="hasDeletedPermission && !image?.get('active')?.value">
                            Eliminado
                        </p>
                        <img [src]="(image?.get('url')?.value | safe)" alt="" draggable="false"
                            (click)="openImageNewTab(image?.get('url')?.value, getExtension(image?.get('documentPath')?.value))"
                            (error)="utils.onErrorRenderImage($event)"
                            [ngClass]="{'deleted':!image?.get('active')?.value}">
                    </div>
                    <label class="photo photo-add" matTooltip="Agregar documento"
                        *ngIf="hasFullEditPermission || hasPartialEditPermission">
                        <input type="file" #documentFileInput class="display-none" (change)="handleFileInput($event, i)"
                            accept="application/pdf,image/png,image/jpeg" multiple>
                        <div class="icon-plus">
                            <i class="fas fa-plus "></i>
                        </div>
                    </label>
                </div>
            </div>
            <div class="col-md-6" *ngIf="hasActiveCompliments('Driver',i)">
                <label class="text-bold">Subidos por conductor</label>
                <p class="static-p">Seleccione los documentos que hayan sido validados:</p>
                <div class="popup-photos">
                    <div class="photo" *ngFor="let image of getFulfillmentDocuments(i)?.controls; let j = index;"
                        [ngClass]="{'d-none':(!hasDeletedPermission && image?.get('active')?.value)||!isDriverDocument(i,j)}">
                        <i class="fas fa-info-circle icon-action" *ngIf="hasFingerPrintPermission"
                            [matTooltip]="getFingerprintToolTip(image.value)" matTooltipClass="custom-tooltip"></i>
                        <mat-checkbox class="document-check" [formControl]="image.get('check')"
                            *ngIf="(hasFullEditPermission || hasPartialEditPermission) && image?.get('check') && image?.get('active')?.value"
                            matTooltip="Validar"></mat-checkbox>
                        <p class="deleted-text" *ngIf="hasDeletedPermission && !image?.get('active')?.value">
                            Eliminado
                        </p>
                        <img [src]="(image?.get('url')?.value | safe)" alt="" draggable="false"
                            (click)="openImageNewTab(image?.get('url')?.value, getExtension(image?.get('documentPath')?.value))"
                            (error)="utils.onErrorRenderImage($event)">
                    </div>
                </div>
            </div>


            <mat-form-field appearance="outline" class="col-md-6"
                *ngIf="hasFullEditPermission || hasPartialEditPermission">
                <mat-label class="text-bold">Estado del requisito <span class="asterisk">*</span></mat-label>
                <mat-select [formControl]="document?.get('fulfillmentState')"
                    (selectionChange)="onChangeDocumentState($event, i)">
                    <mat-option *ngFor="let state of documentStates" [value]="state">
                        {{state}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="document?.get('fulfillmentState').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
            <div *ngIf="!hasFullEditPermission && !hasPartialEditPermission" class="col-md-6">
                <p class="text-bold mb-1">Estado del requisito:</p>
                <p>{{document?.get('fulfillmentState')?.value ? document.get('fulfillmentState').value : 'Sin validar'}}
                </p>
            </div>

            <mat-form-field appearance="outline" class="col-md-6"
                *ngIf="hasFullEditPermission || hasPartialEditPermission">
                <mat-label class="text-bold">Comentarios y novedades</mat-label>
                <input matInput type="text" [formControl]="document?.get('observations')">
                <mat-error *ngIf="document?.get('observations').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
            <div *ngIf="!hasFullEditPermission && !hasPartialEditPermission" class="col-md-6">
                <p class="text-bold mb-1">Comentarios y novedades:</p>
                <p>{{document?.get('observations')?.value ? document.get('observations').value : '-'}}</p>
            </div>

            <div class="col-md-6"
                *ngIf="(hasFullEditPermission || hasPartialEditPermission) && document?.get('physicalDelivery')">
                <label class="text-bold mr-2">Los documentos se entregaron en físico</label>
                <mat-button-toggle-group #group="matButtonToggleGroup" [formControl]="document.get('isPhysical')">
                    <mat-button-toggle [value]="true">
                        Si
                    </mat-button-toggle>
                    <mat-button-toggle [value]="false">
                        No
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div *ngIf="!hasFullEditPermission && !hasPartialEditPermission && document?.get('physicalDelivery')"
                class="col-md-6">
                <p class="text-bold mb-1">Los documentos se entregaron en físico:</p>
                <p>{{document?.get('isPhysical')?.value ? 'Si' : 'No'}}</p>
            </div>
        </div>
        <p class="alert alert-info text-bold text-center" role="alert"
            *ngIf="!fulfillmentRequirements?.length && documentsFlag">
            {{companyDocuments?.length
            ? 'No hay requisitos de cumplido activos para este tipo de viaje (' + cargoTripType + ')'
            : 'No hay requisitos de cumplido configurados para la compañía propietaria de este servicio'
            }}
        </p>
    </div>
    <div class="button-container mt-3">
        <button mat-raised-button class="primary-secondary button-width-auto" color="primary" (click)="confirmClose()"
            type="button">
            {{fulfillmentRequirements?.controls?.length && (hasFullEditPermission || hasPartialEditPermission) ?
            'Cancelar' : 'Cerrar'}}
        </button>
        <button mat-raised-button color="primary" class="button-width-auto bg-color-primary" type="submit"
            *ngIf="fulfillmentRequirements?.controls?.length && (hasFullEditPermission || hasPartialEditPermission)">
            Guardar
        </button>
    </div>
</form>
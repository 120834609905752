import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { VehicleCargo } from 'src/app/core/interfaces/vehicle-cargo';
import { Utils } from 'src/app/core/resources/utils';
import { Router } from '@angular/router';
import { MenuServiceService } from '../../menu-service/menu-service.service';
import { MenuServiceComponent } from '../../menu-service/menu-service.component';
import { Subscription } from 'rxjs';
import { Fmt } from 'src/app/core/messages/fmt';
import { ServiceResources } from '../../resources/services';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
@Component({
  selector: 'app-vehicle-service-form',
  templateUrl: './vehicle-service-form.component.html',
  styleUrls: ['./vehicle-service-form.component.scss']
})
export class VehicleServiceFormComponent implements OnInit {
  permission = Permission;
  // Vehicle Controls
  vehicleTypeControl: FormControl = new FormControl('', Validators.required);
  vehicleTypeSub: Subscription;
  optionsTypeVehicle: OptionsAutocomplete = {
    showAutocomplete: true,
    title: 'Tipo de vehiculo',
    appearance: 'outline',
  };
  // Bodywork Controls
  bodyworkTypeControl: FormControl = new FormControl('', Validators.required);
  optionsBodyworkType: OptionsAutocomplete = {
    title: 'Tipo de carrocería',
    appearance: 'outline',
  }

  // Environment Controls
  environmentControl: FormControl = new FormControl('', Validators.required);
  environments = [
    { label: 'Seco', value: 'Seco' },
    { label: 'Refrigerado', value: 'Refrigerado' },
    { label: 'Congelado', value: 'Congelado' }
  ];
  formValidate = '';
  stepsRoutes: string[] = Object.keys(this.serviceResources.steps);
  formSubscription: Subscription;

  constructor(
    public utils: Utils,
    public menuService: MenuServiceService,
    private router: Router,
    private serviceResources: ServiceResources,
    private snackBarService: SnackBarService,
    private permissionRole: PermissionRole,
    private cargoManager: CargoManager
  ) { }

  ngOnInit() {
    this.setLocalValues();
    this.setSubscription();
    this.checkPermissionsToEdit();
  }

  private setLocalValues() {
    if (this.menuService.form.get('cargoFeature.vehicleType.quality').value)
      this.environmentControl.setValue(this.menuService.form.get('cargoFeature.vehicleType.quality').value);
    const vehicleType = this.menuService.form.get('cargoFeature.vehicleType.name').value;
    const bodyWorkType = this.menuService.form.get('cargoFeature.vehicleType.bodyWorkType.name').value;
    if (vehicleType) {
      this.optionsTypeVehicle = { ...this.optionsTypeVehicle, initialValue: vehicleType };
      this.vehicleTypeControl.setValue({ name: vehicleType });
    }
    if (bodyWorkType || vehicleType)
      this.optionsBodyworkType = { ...this.optionsBodyworkType, initialValue: bodyWorkType, initialVehicleTypeName: vehicleType };
    if (bodyWorkType)
      this.bodyworkTypeControl.setValue({ name: bodyWorkType });
  }

  private setSubscription() {
    this.vehicleTypeSub = this.vehicleTypeControl.valueChanges.subscribe(value => {
      if (value) {
        this.optionsBodyworkType = {
          ...this.optionsBodyworkType,
          initialVehicleTypeId: value.id,
          initialVehicleTypeName: value.name,
          initialValue: ""
        };
        this.bodyworkTypeControl.setValue("");
      }
    });
    this.formSubscription = this.menuService.formObservable.subscribe(() => this.setLocalValues());
  }

  private checkPermissionsToEdit() {
    if (!this.canEditVehicleService) {
      this.formValidate = 'disable';
      this.vehicleTypeControl.disable();
      this.bodyworkTypeControl.disable();
      this.environmentControl.disable();
    }
  }

  get canEditVehicleService(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.editTypeVehicle
    );
  }

  get showEnvironmentControl(): boolean {
    const vehicleType = this.utils.getNestedValue(this.vehicleTypeControl.value, 'name');
    const bodyWorkType = this.utils.getNestedValue(this.bodyworkTypeControl.value, 'name');
    return this.cargoManager.requestEnvironmentType(vehicleType, bodyWorkType);
  }

  /**
   * @description Navigates to the previous step (destination)
   */
  backToPreviousStep() {
    this.router.navigate([Fmt.string(this.serviceResources.steps[this.stepsRoutes[3]].url, this.menuService.cargo.consecutive)]);
  }

  /**
   * @description Saves form data and continues to next step if form is valid
   */
  saveAndContinue() {
    this.menuService.form.get('cargoFeature.vehicleType.quality').setValue(this.showEnvironmentControl ? this.environmentControl.value : '');
    const vehicleType = this.vehicleTypeControl.value && this.vehicleTypeControl.value.name ? this.vehicleTypeControl.value.name : '';
    this.menuService.form.get('cargoFeature.vehicleType.name').setValue(vehicleType);
    const bodyWorkType = this.bodyworkTypeControl.value && this.bodyworkTypeControl.value.name ? this.bodyworkTypeControl.value.name : '';
    this.menuService.form.get('cargoFeature.vehicleType.bodyWorkType.name').setValue(vehicleType ? bodyWorkType : '');
    this.menuService.updateService().then(() => {
      this.snackBarService.openSnackBar('Servicio actualizado exitosamente');
      this.continueToNextStep();
    }).catch(() => this.snackBarService.openSnackBar('Ocurrió un error al actualizar el servicio', undefined, 'error'));
  }

  continueToNextStep() {
    const isRequiredLoadTypeService = this.menuService.isRequiredLoadTypeService();
    this.router.navigate([Fmt.string(isRequiredLoadTypeService
      ? this.menuService.steps.cargoType.url
      : this.menuService.steps.loadCharacteristics.url, this.menuService.cargo.consecutive)]);
  }

  ngOnDestroy() {
    if (this.vehicleTypeSub) this.vehicleTypeSub.unsubscribe();
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }
}

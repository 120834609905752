import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MenuServiceService } from '../../menu-service/menu-service.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Router } from '@angular/router';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/resources/utils';
import { Subscription } from 'rxjs';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
@Component({
  selector: 'app-load-type-service-form',
  templateUrl: './load-type-service-form.component.html',
  styleUrls: ['./load-type-service-form.component.scss']
})
export class LoadTypeServiceFormComponent implements OnInit, OnDestroy {
  permission = Permission;
  //Letter Retirement
  letterRetirement = new FormControl(null);
  //Regime Type
  regimeTypeSelected: string = null;
  regimeTypes = this.menuService.regimeTypes;
  //Date Vessel Arrival
  dateVesselArrival = new FormControl(null, Validators.required);
  //Load Type
  loadTypeControl = new FormControl(null, Validators.required);
  //Service Type
  serviceTypeControl = new FormControl(null, Validators.required);
  //Container Size
  containerSizeControl = new FormControl(null, Validators.required);
  //Service Types
  serviceTypes = this.menuService.serviceTypes;
  tripSelectedName: string = null;
  docUrl: string = '';
  selectedRegimeType: string[] = [];
  selectedServiceTypes: string[] = [];
  formSubscription: Subscription;

  constructor(
    public menuService: MenuServiceService,
    private snackBarService: SnackBarService,
    public utils: Utils,
    private router: Router,
    private spinner: NgxSpinnerService,
    private angularFireStorage: AngularFireStorage,
    private permissionRole: PermissionRole
  ) { }

  ngOnInit() {
    this.setLocalValues();
    this.setSubscription();
    this.checkPermissionsToEdit();
  }

  private setLocalValues() {
    if (!this.menuService.isRequiredLoadTypeService()) return this.continueToNextStep();
    this.letterRetirement.setValue(
      this.menuService.isRequiredLetterRetirement() ? this.menuService.form.get('cargoModel.tripType.letterRetirement').value : null
    );
    const regimeType = this.menuService.form.get('cargoModel.tripType.regimeType').value;
    this.regimeTypeSelected = this.filteredRegimeTypes.includes(regimeType) ? regimeType : null;
    if (this.menuService.form.get('cargoModel.tripType.date').value)
      this.dateVesselArrival.setValue(DateManager.stringToDate(this.menuService.form.get('cargoModel.tripType.date').value));

    this.loadTypeControl.setValue(
      this.currentTripType !== 'Última milla'
        ? this.menuService.form.get('cargoModel.cargoType.name').value
        : "Carga suelta"
    );
    if (this.loadTypeControl.value) this.selectTypeLoad();
    this.serviceTypeControl.setValue(this.menuService.form.get('cargoModel.serviceType.name').value);
    this.containerSizeControl.setValue(this.menuService.form.get('cargoModel.cargoType.containerMeasure.size').value);
  }

  private setSubscription() {
    this.formSubscription = this.menuService.formObservable.subscribe(() => this.setLocalValues());
  }

  private checkPermissionsToEdit(): void {
    if (!this.canEditLoadType) {
      this.loadTypeControl.disable();
      this.serviceTypeControl.disable();
      this.containerSizeControl.disable();
      this.dateVesselArrival.disable();
    }
  }

  /**
   * @description Handles regime type selection
   * @param regimeType Selected regime type
   */
  selectRegimeType(regimeType: string) {
    if (this.canEditLoadType) this.regimeTypeSelected = regimeType;
  }

  /**
   * @description Handles load type selection
   * @param data Selected load type data
   */
  public selectTypeLoad(data?: { name: string }) {
    if (data && data.name) this.loadTypeControl.setValue(data.name);
    const selectedService = this.serviceTypes.find(type => type.name === this.loadTypeControl.value);
    if (selectedService) this.selectedServiceTypes = selectedService.types;
  }

  public handleFileInput(data) {
    this.spinner.show();
    const ref = this.angularFireStorage.ref(this.letterRetirement.value);
    const uploadTask = this.angularFireStorage.upload(this.letterRetirement.value, data);
    uploadTask.then(
      (result) => {
        ref.getDownloadURL().subscribe(
          (urlFile) => {
            this.spinner.hide();
            this.docUrl = urlFile;
          }
        );
        this.letterRetirement.setValue(`Cargo/${this.menuService.cargo.id}/letterRetirement/${data.name}`);
      },
      (error) => {
        this.snackBarService.openSnackBar(FormMessages.ERROR_LOAD_RESOURCE, undefined, 'error');
        this.spinner.hide();
      }
    );
  }

  get firebasePath(): boolean {
    const url = decodeURIComponent(this.letterRetirement.value);
    const match = url.match(/\/([^\/]+)\?alt=media/);
    const fileNameDecode = match ? match[1] : null;
    return !!fileNameDecode;
  }

  get currentTripType(): string {
    return this.menuService && this.menuService.form && this.menuService.form.get('cargoModel.tripType.name')
      && this.menuService.form.get('cargoModel.tripType.name').value ? this.menuService.form.get('cargoModel.tripType.name').value : '';
  }

  /**
   * @description Gets filtered regime types based on trip type
   */
  get filteredRegimeTypes() {
    if (this.regimeTypes[this.currentTripType])
      return this.regimeTypes[this.currentTripType];
    return [];
  }

  /**
   * @description Checks if current trip type is import or export
   */
  get isImportOrExport(): boolean {
    return this.menuService.isImportOrExport(this.menuService.cargo);
  }

  get showVesselArrivalDate(): boolean {
    return this.isImportOrExport && this.menuService.regimeTypesWithDateVesselArrival.includes(this.regimeTypeSelected);
  }

  get canSeeLoadType(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.showServiceTypeModule
    );
  }
  get canEditLoadType(): boolean {
    return this.canSeeLoadType && this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.editServiceTypeModule
    );
  }

  /**
   * @description Navigates to previous step
   */
  backToPreviousStep() {
    this.router.navigate([Fmt.string(this.menuService.steps.vehicle.url, this.menuService.cargo.consecutive)]);
  }

  /**
   * @description Saves form data and continues if valid
   * @param event Form submission event
   */
  saveAndContinue() {
    this.menuService.form.get('cargoModel.tripType.letterRetirement').setValue(
      this.menuService.isRequiredLetterRetirement() ? this.letterRetirement.value : null
    );
    this.menuService.form.get('cargoModel.tripType.regimeType').setValue(
      this.isImportOrExport && this.filteredRegimeTypes.includes(this.regimeTypeSelected) ? this.regimeTypeSelected : ""
    );
    this.menuService.form.get('cargoModel.tripType.date').setValue(
      this.isImportOrExport && this.menuService.regimeTypesWithDateVesselArrival.includes(this.regimeTypeSelected) && this.dateVesselArrival.value
        ? DateManager.dateToString(this.dateVesselArrival.value) : ""
    );
    this.menuService.form.get('cargoModel.cargoType.name').setValue(
      this.currentTripType !== 'Última milla' ? this.loadTypeControl.value : "Carga suelta"
    );
    this.menuService.form.get('cargoModel.serviceType.name').setValue(
      this.selectedServiceTypes.includes(this.serviceTypeControl.value) ? this.serviceTypeControl.value : ""
    );
    this.menuService.form.get('cargoModel.cargoType.containerMeasure.size').setValue(
      this.currentTripType !== 'Última milla' && this.loadTypeControl.value === 'Contenedor' ? this.containerSizeControl.value : null
    );
    this.menuService.updateService().then(() => {
      this.snackBarService.openSnackBar('Servicio actualizado exitosamente');
      this.continueToNextStep();
    }).catch(() => this.snackBarService.openSnackBar('Ocurrió un error al actualizar el servicio', undefined, 'error'));
  }

  continueToNextStep() {
    this.router.navigate([Fmt.string(this.menuService.steps.loadCharacteristics.url, this.menuService.cargo.consecutive)]);
  }

  ngOnDestroy() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatAutocompleteSelectedEvent } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';
import { CompanyCompliment, CompanyComplimentDTO, ComplimentTitle } from 'src/app/core/interfaces/companyCompliment';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FileComponent } from 'src/app/shared/files/file.component';
import { FileService } from 'src/app/shared/files/file.service';
import { CompaniesService } from '../list-companies.service';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { StorageEndpoints } from 'src/app/core/resources/storage-endpoints';
import { Fmt } from 'src/app/core/messages/fmt';

@Component({
  selector: 'app-compliment-form',
  templateUrl: './compliment-form.component.html',
  styleUrls: ['./compliment-form.component.scss']
})
export class ComplimentFormComponent implements OnInit {
  @ViewChild('appNotification', { static: false }) fileComponent: FileComponent;
  complimentTypes: ComplimentTitle[] = [];
  name = new FormControl('', Validators.required);
  form: FormGroup;
  permission = Permission;
  public tripTypesAllowed: { label: string, value: TRIP_TYPES }[] = [
    { label: "Exportación", value: TRIP_TYPES.EXPORT },
    { label: "Importación", value: TRIP_TYPES.IMPORT },
    { label: "Internacional", value: TRIP_TYPES.INTERNATIONAL },
    { label: "Última milla/ Paqueteo urbano", value: TRIP_TYPES.LAST_MILE },
    { label: "Nacional", value: TRIP_TYPES.NATIONAL },
    { label: "Urbana", value: TRIP_TYPES.URBAN }
  ];

  constructor(
    public dialogRef: MatDialogRef<ComplimentFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title;
      compliment?: CompanyCompliment;
      list: CompanyCompliment[];
      confirmBtn: string;
      companyNit: string;
    },
    private snackBarService: SnackBarService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    private companiesService: CompaniesService,
    private dialog: MatDialog,
    private permissionRole: PermissionRole,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getComplimentTitles();
    if (this.data && this.data.compliment) {
      this.form.patchValue(this.data.compliment);
      this.name.setValue(this.data.compliment.title);
      if (this.data.compliment.example && !this.data.compliment.example.length)
        this.form.get('example').setValue('');
    }
  }

  private initForm() {
    this.form = new FormGroup({
      tripTypes: new FormControl('', Validators.required),
      state: new FormControl(true),
      title: new FormControl('', Validators.required),
      example: new FormControl(''),
      requirePhysicalDocument: new FormControl(true),
    });
  }

  private getComplimentTitles() {
    this.companiesService.getComplimentTitles().subscribe(
      (success) => {
        if (success && success.length)
          this.complimentTypes = success;
        else this.complimentTypes = [];
      },
      (error) => {
        if (error && error.error && error.error.message)
          this.snackBarService.openSnackBar(error.error.message, undefined, 'error');
        else
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    )
  }

  get repeatedName(): string {
    if (this.form.get('title') && this.form.get('title').valid && this.data && this.data.list) {
      const normalizedInputValue = this.form.get('title').value.replace(/\s+/g, '').toLowerCase();
      const exists = this.data.list.some(compl => {
        const normalizedName = compl.title.replace(/\s+/g, '').toLowerCase();

        return (!this.data.compliment || (this.data.compliment && this.data.compliment.title.replace(/\s+/g, '').toLowerCase() !== normalizedName)) &&
          normalizedName === normalizedInputValue;
      });
      if (exists)
        return "Ya existe un requisito con este nombre";
    }
    return "";
  }

  handleFileInput(file: File) {
    this.spinner.show();
    this.fileService.loadFileToStorage(this.dataPath, file.name, file)
      .then(() => {
        this.form.get('example').setValue([file.name]);
        this.spinner.hide();
      })
      .catch(() => {
        this.form.get('example').setValue('');
        this.spinner.hide();
      });
  }

  displayName(title?: ComplimentTitle | string): string | undefined {
    if (typeof title === 'string') {
      return title;
    } else if (title && title.name) {
      return title.name;
    }
    return '';
  }

  changeValue() {
    this.form.get('title').setValue("");
  }

  onSelectName($event: MatAutocompleteSelectedEvent) {
    if ($event && $event.option && $event.option.value && $event.option.value.name)
      this.form.get('title').setValue($event.option.value.name);
  }

  createTitle() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `Agregar requisito`,
      inputText: true,
      maxLength: 60,
      placeholder: 'Nombre',
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state && result.inputText)
        this.createComplimentTitle(result.inputText.trim());
    });
  }

  private createComplimentTitle(title: string) {
    this.spinner.show();
    this.companiesService.createComplimentTitle(title).subscribe(
      (success) => {
        this.spinner.hide();
        this.getComplimentTitles();
        this.snackBarService.openSnackBar(success && success.message ? success.message : CargoMessages.COMPLIMENT_TITLE_CREATED);
      },
      (error) => {
        this.spinner.hide();
        if (error && error.error && error.error.message)
          this.snackBarService.openSnackBar(error.error.message, undefined, 'error');
        else
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    );
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('tripTypes'), 'tipo de viaje')) return;
      if (this.utils.errorMessagesCustomized(this.form.get('title'), 'nombre')) return;
      this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    if (this.repeatedName) {
      this.snackBarService.openSnackBar(this.repeatedName, undefined, 'error');
      return;
    }
    let newCompliment: CompanyComplimentDTO = this.form.value;
    if (this.data && this.data.compliment) {
      newCompliment['documentId'] = this.data.compliment.documentId;
      newCompliment['state'] = this.data.compliment.state;
    }
    if (!newCompliment.example)
      newCompliment['example'] = [];
    this.dialogRef.close({ compliment: newCompliment });
  }

  get canAddComplimentTypes(): boolean {
    return this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.createCompanyComplimentType);
  }

  get filteredComplimentTypes(): Array<ComplimentTitle> {
    const name: ComplimentTitle | string = this.name.value;
    if (!name || !this.complimentTypes.length) return this.complimentTypes;
    return this.complimentTypes.filter(type => {
      if (typeof name === 'string') return type.name.toLowerCase().includes(name.toLowerCase());
      return type.name.toLowerCase().includes(name.name.toLowerCase());
    })
  }

  get dataPath(): string {
    return Fmt.string(StorageEndpoints.companyFulfillment, this.data.companyNit);
  }

}


import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from '../../../../../../environments/environment';
import { Utils } from 'src/app/core/resources/utils';
import { ThirdParty, ThirdPartyAddress } from 'src/app/core/interfaces/thirdParty';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyConsignmentService {


  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints,
    public utils: Utils
  ) { }

  getThirdPartiesByParent(): Observable<Array<ThirdParty>> {
    return this.http.get<Array<ThirdParty>>(
      environment.urlServerTeclogi + this.endpointResources.thirdParties
    );
  }

  getThirdPartiesAddressByCompany(companyId: string, pageKey?: number, pageSize?: number, city?: string): Observable<ThirdPartyAddress[]> {
    let params = new HttpParams();
    params = params.append('companyId', companyId);
    if (this.utils.isDefined(pageKey))
      params = params.append('pageKey', pageKey.toString());
    if (pageSize)
      params = params.append('pageSize', pageSize.toString());
    if (this.utils.isDefined(city))
      params = params.append('city', city);

    return this.http.get<ThirdPartyAddress[]>(
      environment.urlServerTeclogi + this.endpointResources.thirdPartiesAddress,
      { params }
    );
  }

  createThirdParty(data: any): Observable<ThirdPartyAddress> {
    return this.http.post<ThirdPartyAddress>(
      environment.urlServerTeclogi + this.endpointResources.thirdParties,
      data
    );
  }

  setLatLngAddresses(data) {
    return this.http.put(
      environment.urlServerTeclogi + this.endpointResources.thirdPartiesUpdate,
      data
    )
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { MediaService } from 'src/app/core/services/media.service';
import { BellAlertService } from './bell-alert.service';
import { DateManager } from 'src/app/core/managers/date.manager';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
@Component({
  selector: 'app-bell-alert',
  templateUrl: './bell-alert.component.html',
  styleUrls: ['./bell-alert.component.scss'],
  providers: [CargoManager]
})

export class BellAlertComponent implements OnInit {

  @Input() cargo;
  public timeLastLocation: string;
  private subscriptionIntervalBell: Subscription;
  private hasOpenEval = false;
  private notificated = [];
  permission = Permission;

  constructor(
    private mediaService: MediaService,
    private cargoManager: CargoManager,
    public bellAlertService: BellAlertService,
    private permissionRole: PermissionRole,
    private utils: Utils
  ) {

  }
  /**
  * This method executes the method that set the interval bell.
  */
  ngOnInit() {
    this.intervalBell();
  }
  /**
  * This method unsuscribe the interval bell.
  */
  ngOnDestroy() {
    this.subscriptionIntervalBell.unsubscribe();
  }
  /**
  * This method set the color of the alarm according to the difference between the last report and now and the maximum time without report.
  * @param {number} diff (number) is the difference between the last report and now.
  * @param {number} max (number) is the max time without report.
  */
  alarm(diff: number, max: number) {
    let color: string;

    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.registerLocation))
      this.mediaService.audioAlert();

    const late = diff - max;
    if (late < AmountsCargoEnum.ALARM_YELLOW_MINS_STILL)
      color = '#FFDC1C';
    else if (late < AmountsCargoEnum.ALARM_ORANGE_MINS_STILL)
      color = '#FF3203';
    else
      color = '#495356';
    // Si no se ha notificado con esa prioridad
    if (
      this.notificated.indexOf(`${this.cargo.consecutive}${color}`) < 0
    ) {
      this.notificated.push(`${this.cargo.consecutive}${color}`);
      if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.registerLocation))
        this.mediaService.webPushNotification(
          'Servicio sin reportar',
          `El servicio ${this.cargo.consecutive} lleva ${Math.ceil(diff)} minutos sin reportar novedades, haga click en esta notificación para abrir el detalle`
        ).then(
          notification =>
            notification.onclick = (e) => {
              // this.router.navigate([`/cargo/tracking/${this.cargo.id}`]);
              location.href = `${location.origin}/#/cargo/tracking/${this.cargo.consecutive}`;
            }
        );
    }

    if (this.notificated[`${this.cargo.consecutive}`] === undefined) {
      this.notificated[`${this.cargo.consecutive}`] = (new Date()).getTime();
    }

    const currentTime = (new Date()).getTime();
    if ((currentTime - this.notificated[`${this.cargo.consecutive}`]) > (AmountsCargoEnum.UNCARED_ALARM_MINS * 60 * 1000)) {
      // Send email, non answered alarm
    }
    return { color };
  }

  // private openEvalDialog() {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.data = {
  //     cargo: this.cargo
  //   };
  //   dialogConfig.width = '100vw';
  //   dialogConfig.height = '100vh';
  //   const dialogRef = this.dialog.open(CargoLocationReportComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe(result => {
  //     try {
  //       if (result && result.state) {
  //         localStorage.removeItem('hasOpenEval');
  //         window.location.reload();
  //       }
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   });
  // }
  /**
* This method consumes the service that calculates the difference between now and the last report.
* @param {any} date (any) is the date of the last location report.
* @returns {any} (any) returns none or executes the method that set a color according to the time.
*/
  private validateTimeCargo(date: any) {
    this.timeLastLocation = DateManager.dateDiffFromNow(date, 'YYYY-MM-DD HH:mm ZZ');
    const isEmpty = this.cargoManager.getProductNameCargo(this.cargo) === 'CONTENEDOR VACIO';
    const none = { display: 'none' };
    let validationTime = this.bellAlertService.validateTimeCargo(date, this.cargo, isEmpty);
    return validationTime ? this.alarm(validationTime.diff, validationTime.amount) : none;
  }

  get semaphoreCargoTime() {
    const amount = this.utils.getNestedValue(this.cargo, 'cargoFeature.cargoMeasure.amount');
    if (amount) {
      if (this.cargo.lastPointLocation && this.cargo.lastPointLocation.fingerprint) {
        return this.validateTimeCargo(this.cargo.lastPointLocation.fingerprint.date);
      } else if (this.cargo.stateTracking === "Start trip" || this.cargo.stateTracking === "Destination arrived") {
        if (this.cargo.cargoFeature.uploadDownload.origin && this.cargo.cargoFeature.uploadDownload.origin.addresses.length > 0 && this.cargo.cargoFeature.uploadDownload.origin.addresses[0].durationTime && this.cargo.cargoFeature.uploadDownload.origin.addresses[0].durationTime.endDate) {
          return this.validateTimeCargo(this.cargo.cargoFeature.uploadDownload.origin.addresses[0].durationTime.endDate);
        }
      } else {
        return {
          "display": "none",
        };
      }
    } else {
      return {
        "display": "none",
      };
    }
  }
  /**
  * This method set the interval to the bell alert.
  */
  private intervalBell() {
    // 900000
    const intervalBell = interval(5000);
    this.subscriptionIntervalBell = intervalBell.subscribe(val => this.semaphoreCargoTime);
  }

}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { StandardMapComponent } from 'src/app/shared/standard-map/standard-map.component';

@Component({
  selector: 'app-routing-map',
  templateUrl: './routing-map.component.html',
  styleUrls: ['./routing-map.component.scss'],
})
export class RoutingMapComponent implements OnInit {
  @ViewChild(StandardMapComponent, { static: false }) standardMapComponent: StandardMapComponent;
  form: FormGroup;
  public mapOptions: OptionsAutocomplete = {
    zoom: 12,
    showMarkers: true,
  };

  /**
  * @description Executes the "initForm" method
  */
  ngOnInit(): void {
    this.initForm();
  }

  /**
  * @description Initializes the routing form
  */
  private initForm(): void {
    this.form = new FormGroup({
      description: new FormControl('', [Validators.required, Validators.minLength(3)]),
      date: new FormControl('', Validators.required),
      schemaName: new FormControl('', Validators.required),
      schemaCode: new FormControl('', Validators.required),
      fleetName: new FormControl('', Validators.required),
      fleetFromSchema: new FormControl(false),
      file: new FormControl('', Validators.required),
      showConfirmAddresses: new FormControl(false)
    })
  }

  /**
  * @param {[{ route: Object }, boolean]} $event are the params sent from confirm-addresses component to refresh the map
  * @description Updates the mapOptions from the params received
  */
  refreshMap($event: [{ route: Object }, boolean]) {
    if (!$event[1]) {
      this.standardMapComponent.removeAllMarkers();
    }
    this.mapOptions['route'] = $event[0];
    this.mapOptions = { ...this.mapOptions };
  }

  /**
  * @returns {boolean} returns true if the basic form is filled and valid.
  * @description Verifies if the basic form is filled and valid.
  */
  private get isValidBasicForm(): boolean {
    const basicFields = ['description', 'date', 'schemaName', 'schemaCode', 'fleetName', 'file'];
    return this.form && basicFields.every(field => this.form.get(field) && this.form.get(field).valid);
  }

  /**
  * @returns {boolean} returns true if the basicForm is valid and the user wants to pass to confirm addresses step
  * @description Verifies if the confirm addresses step must to be visible
  */
  get showConfirmAddresses(): boolean {
    return this.form && this.isValidBasicForm && this.form.get('showConfirmAddresses') && !!this.form.get('showConfirmAddresses').value;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuServiceService } from '../../menu-service/menu-service.service';
import { Utils } from 'src/app/core/resources/utils';
import { Router } from '@angular/router';
import { Fmt } from 'src/app/core/messages/fmt';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { VehicleCargo } from 'src/app/core/interfaces/vehicle-cargo';
import { VehiclesService } from 'src/app/modules/administration/vehicles/list-vehicles.service';
import { Permission } from 'src/app/core/resources/permission';
import { CargoItemService } from 'src/app/modules/cargo/cargo-item/cargo-item.service';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { environment } from 'src/environments/environment';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CurrencyPipe } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { DateManager } from 'src/app/core/managers/date.manager';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { CompanyNamePipe } from 'src/app/core/pipe/companyName.pipe';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { CargoService } from 'src/app/core/services/cargo.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { CargoDetailService } from 'src/app/modules/cargo/cargo-detail/cargo-detail.service';
import { MToKmPipe } from 'src/app/core/pipe/mToKm.pipe';

//TODO: Publicacion de servicio
@Component({
  selector: 'app-service-publication-form',
  templateUrl: './service-publication-form.component.html',
  styleUrls: ['./service-publication-form.component.scss'],
  providers: [CompanyNamePipe, MToKmPipe]
})

export class ServicePublicationFormComponent implements OnInit, OnDestroy {
  formSubscription: Subscription;
  permission = Permission;
  publicationZone = new FormControl('', [Validators.required]);
  publicationZones = [
    {
      value: 'private',
      name: 'Privada',
      description: 'Visible solo para los conductores directamente asociados a la compañía.'
    },
    {
      value: 'public',
      name: 'Pública',
      description: 'Visible para todos los conductores de la comunidad LoggiApp.'
    }
  ]
  rate = 0;
  totalCost = 0;
  freeTaxes = false;
  reteica = 0;
  reteicaValue = 0;
  retefteValue = 0;
  additionalServicesCost = 0;
  additionalServicesRate = 0;
  vehicleInsuranceDiscountCargo = 0;

  constructor(
    public utils: Utils,
    public menuService: MenuServiceService,
    private router: Router,
    private authService: AuthService,
    private vehiclesService: VehiclesService,
    private cargoItemService: CargoItemService,
    private permissionRole: PermissionRole,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private cargoManager: CargoManager,
    private dialog: MatDialog,
    private companyNamePipe: CompanyNamePipe,
    private mToKmPipe: MToKmPipe,
    private dateFormatPipe: DateFormatPipe,
    private currencyPipe: CurrencyPipe,
    private service: CargoDetailService,
  ) { }

  ngOnInit() {
    this.setLocalValues();
    this.setSubscription();
  }

  private async setLocalValues() {
    this.publicationZone.setValue(this.menuService.form.get('zone').value);
    const totalCost = this.utils.getNestedValue(this.menuService.form.value, 'shippingCost.totalCost');
    if (totalCost) this.totalCost = totalCost;
    const rate = this.utils.getNestedValue(this.menuService.form.value, 'shippingCost.rate');
    if (rate) this.rate = rate;
    const reteica = this.utils.getNestedValue(this.menuService.form.value, 'shippingCost.reteicaPercentage');
    if (reteica) this.reteica = reteica;
    const freeTaxes = await this.checkFreeTaxes().toPromise();
    if (freeTaxes) this.freeTaxes = freeTaxes;
    this.reteicaValue = Math.floor(totalCost * reteica) / 100;
    this.retefteValue = Math.floor(totalCost * 0.01);
    this.additionalServicesCost = this.cargoItemService.getTotalAdditionalCosts(this.menuService.form.getRawValue());
    this.additionalServicesRate = this.cargoItemService.getTotalAdditionalRate(this.menuService.form.getRawValue());
    this.vehicleInsuranceDiscountCargo = this.cargoItemService.getVehicleInsuranceDiscountCargo(this.menuService.form.getRawValue());
  }

  private setSubscription() {
    this.formSubscription = this.menuService.formObservable.subscribe(() => this.setLocalValues());
  }

  private checkFreeTaxes(): Observable<boolean> {
    const chargeOwnFleet = this.authService.getCompany().chargeOwnFleet;
    return new Observable<boolean>(observer => {
      const vehicle: string = this.utils.getNestedValue(this.menuService.form.value, 'licensePlate');
      if (chargeOwnFleet || !vehicle) {
        observer.next(false);
        observer.complete();
        return;
      }

      this.vehiclesService.getVehicle(vehicle).subscribe(
        (vehicle: Vehicle) => {
          observer.next(vehicle && vehicle.ownFleet);
          observer.complete();
        },
        () => {
          observer.next(false);
          observer.complete();
        }
      );
    });
  }

  get hasPermissionsCosts(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.showCostCargo
    ) && this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.additionalCosts
    );
  }

  get hasPermissionsRate(): boolean {
    return this.hasPermissionShowRate && this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.additionalCosts
    );
  }

  get hasPermissionShowRate(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.showRateCargo
    );
  }

  get isTeclogiUser(): boolean {
    return this.authService.getCompany().companyId === environment.rootNit;
  }

  get canSeeAndEditPublicationZone(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.editPublicationZone
    );
  }

  get canSeeFinancialInformationSection(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.showFinancialInformationModule
    );
  }

  get canCreateCargoFromRequest(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.createCargoFromRequest
    ) || this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.createService
    )
  }

  /**
   * @description Navigates to the previous step
   */
  backToPreviousStep() {
    const isRequiredConsignmentsService = this.menuService.isRequiredConsignmentsService();
    this.router.navigate([Fmt.string(isRequiredConsignmentsService
      ? this.menuService.steps.consignmentsService.url
      : this.menuService.steps.observationsService.url
      , this.menuService.cargo.consecutive)]);
  }

  private async updateCargo(): Promise<boolean> {
    this.menuService.form.get('zone').setValue(this.publicationZone.value);
    if (this.freeTaxes) {
      this.menuService.form.get('shippingCost.reteicaPercentage').setValue(0);
      this.menuService.form.get('shippingCost.reteftePercentage').setValue(0);
    }
    const { distancy, estimatedTime } = await this.menuService.getRouteGoogleData(this.menuService.cargo);
    this.menuService.form.get('distancy').setValue(distancy);
    this.menuService.form.get('estimatedTime').setValue(estimatedTime);
    try {
      await this.menuService.updateService();
      return true;
    } catch (error) {
      return false;
    }
  }

  async saveAndContinue() {
    const isUpdated = await this.updateCargo();
    if (!isUpdated) return this.snackBarService.openSnackBar("Ocurrió un error al actualizar el servicio", undefined, 'error');
    this.snackBarService.openSnackBar('Servicio actualizado exitosamente');
  }

  async createCargo() {
    if (!this.menuService.isValidEntireCargo().isValid)
      return this.snackBarService.openSnackBar(this.menuService.isValidEntireCargo().error, undefined, 'error');
    const checkTripTimes = await this.menuService.checkTripTimes(this.menuService.cargo);
    if (!checkTripTimes.valid)
      return this.snackBarService.openSnackBar(checkTripTimes.error, undefined, 'error');
    const isUpdated = await this.updateCargo();
    if (!isUpdated) return this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
    let alertMessage = '';
    let isRateOrCostZero = !!(this.menuService.cargo && this.menuService.cargo.shippingCost && (this.menuService.cargo.shippingCost.rate === 0 || this.menuService.cargo.shippingCost.freightCost === 0));
    let permissionToCreateWithRateOrCostZero = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.createCargoWithRateOrCostZero);
    if (isRateOrCostZero && permissionToCreateWithRateOrCostZero) {
      alertMessage += 'Has ingresado un valor de 0 '
      alertMessage += `${this.menuService.cargo.shippingCost.rate === 0 && this.menuService.cargo.shippingCost.freightCost === 0 ? 'para la tarifa y el flete' : this.menuService.cargo.shippingCost.rate === 0 ? 'para la tarifa' : 'para el flete'}`;
      alertMessage += '. Si esto es correcto, continúa; de lo contrario, revisa el valor ingresado';
    }
    const dialogConfig = new MatDialogConfig();
    const value = this.utils.getNestedValue(this.menuService.cargo, 'cargoFeature.cargoMeasure.amount') | 0;
    dialogConfig.data = {
      title: `¿Estás seguro que deseas crear el servicio ${this.menuService.cargo.consecutive}?`,
      messageList: [
        `Operación: ${this.companyNamePipe.transform(this.menuService.cargo.idCompany)}`,
        `Origen: ${this.menuService.cargo.cargoFeature.uploadDownload.origin.name}`,
        `Fecha de cargue: ${this.dateFormatPipe.transform(this.menuService.cargo.dateLoad, "only-date")}`,
        `Destino: ${this.menuService.cargo.cargoFeature.uploadDownload.destination[this.menuService.cargo.cargoFeature.uploadDownload.destination.length - 1].name}`,
        `Fecha de descargue: ${this.dateFormatPipe.transform(this.menuService.cargo.cargoFeature.uploadDownload.destination[this.menuService.cargo.cargoFeature.uploadDownload.destination.length - 1].downloadDate, "only-date")}`,
        `¿Requiere manifiesto?: ${this.menuService.cargo.ministry ? 'Si' : 'No'}`,
        `Valor declarado: ${this.currencyPipe.transform(value, "COP", "code")}`,
        `Distancia estimada: ${this.mToKmPipe.transform(this.menuService.cargo.distancy)}`,
      ],
      showYesBtn: true,
      showNoBtn: true,
      hideBtnCancel: true,
    };
    if (alertMessage) dialogConfig.data.descriptionHTML = `
      <div class="alert alert-warning m-3 d-flex align-items-center justify-content-center" role="alert">
        <i class="fas fa-exclamation-triangle mr-2"></i><span>${alertMessage}</span>
      </div>
    `;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    const result = await dialogRef.afterClosed().toPromise();
    if (!result || !result['state']) return;
    this.spinner.show();
    this.service.requestCargo(this.menuService.cargo.id).subscribe(
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(`El servicio ${this.menuService.cargo.consecutive} ha sido programado exitosamente`);
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          error && error.error && error.error.message ? error.error.message :
            ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error"
        );
        this.menuService.setCargoConsignments();
      }
    );
  }

  ngOnDestroy() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }
}

<form [formGroup]="menuService?.form" (ngSubmit)="saveAndContinue()" class="px-0 px-sm-3">
    <div class="container-fluid px-0 px-sm-3">
        <div class="d-flex flex-wrap align-items-center" *ngIf="canSeeLoadType">
            <!-- Show letterRetirement only for Exportación -->
            <div class="col-md-6" *ngIf="menuService?.isRequiredLetterRetirement()">
                <p class="text-bold">Carta de retiro</p>
                <app-file [ngClass]="{'disable-actions': !canEditLoadType}" [options]="{
                        title: 'Subir archivo', 
                        path: 'Cargo/' + menuService?.cargo?.id + '/letterRetirement', 
                        storage: true, 
                        storageName: 'tripType_letterRetirement', 
                        firebasePath: firebasePath ? letterRetirement.value : null, 
                        docUrl: docUrl, 
                        hideAlert: true
                    }" [inputFormControl]="letterRetirement" (emitToParent)="handleFileInput($event)">
                </app-file>
            </div>

            <!-- Regime Type -->
            <p class="text-bold col-12 mt-3" *ngIf="isImportOrExport">Tipo de régimen</p>
            <div class="pb-4 col-12" *ngIf="isImportOrExport">
                <div class="d-flex flex-wrap mt-3">
                    <div class="tag-container" *ngFor="let filteredRegimeType of filteredRegimeTypes">
                        <div class="tag-card col-md-12" [class.selected]="regimeTypeSelected === filteredRegimeType"
                            [ngClass]="{'cursor-pointer': canEditLoadType, 'disable-actions': !canEditLoadType}"
                            (click)="selectRegimeType(filteredRegimeType)">
                            <p class="text-bold mb-0">{{filteredRegimeType}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <mat-form-field appearance="outline" class="col-md-6" *ngIf="showVesselArrivalDate">
                <mat-label>Fecha de descargue del buque<span class="asterisk">*</span></mat-label>
                <input matInput [matDatepicker]="picker" [formControl]="dateVesselArrival"
                    placeholder="Seleccione una fecha">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="dateVesselArrival.errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
            <!-- Load Type -->
            <p class="text-bold col-md-12 mt-3" *ngIf="currentTripType !== 'Última milla'">Tipo de carga</p>
            <app-box-type-carga class="col-md-12 mb-4" [options]="serviceTypes"
                *ngIf="currentTripType !== 'Última milla'" [ngClass]="{'disable-actions': !canEditLoadType}"
                [TypeCargaSelectedName]="loadTypeControl.value" (emitToParent)="selectTypeLoad($event)">
            </app-box-type-carga>
            <!-- Service Type -->
            <mat-form-field class="col-md-6" *ngIf="loadTypeControl.value" appearance="outline">
                <mat-label>Tipo de servicio<span class="asterisk">*</span></mat-label>
                <mat-select [formControl]="serviceTypeControl" placeholder="Tipo de servicio">
                    <mat-option *ngFor="let type of selectedServiceTypes" [value]="type">
                        {{type}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="serviceTypeControl.errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
            <!-- Container Size -->
            <mat-form-field class="col-md-6" *ngIf="loadTypeControl?.value === 'Contenedor'" appearance="outline">
                <mat-label>Medidas del contenedor<span class="asterisk">*</span></mat-label>
                <mat-select [formControl]="containerSizeControl" placeholder="Medidas del contenedor">
                    <mat-option *ngFor="let size of serviceTypes[1].containerSizes" [value]="size">
                        {{size}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="containerSizeControl.errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
        </div>
        <div *ngIf="!canSeeLoadType" class="no-permission-container">
            <i class="fas fa-exclamation-circle fa-lg m-2"></i>
            <h4 class="text-bold">No tienes permiso para acceder a esta información. Contacta a tu administrador para
                solicitar acceso.</h4>
        </div>
        <!-- Action Buttons Section -->
        <div class="button-container mt-4 col-12">
            <button mat-raised-button color="primary" class="button-width-auto primary-secondary button-small"
                [ngClass]="{'warn': true}" type="button" (click)="backToPreviousStep()">
                <span class="mat-button-wrapper">Atras</span>
            </button>
            <button class="mx-2" type="submit" mat-raised-button color="accent" *ngIf="canEditLoadType">
                Guardar y Continuar
            </button>
            <button class="mx-2" type="button" mat-raised-button color="accent" *ngIf="!canEditLoadType"
                (click)="continueToNextStep()">
                Continuar
            </button>
        </div>
    </div>
</form>
<form [formGroup]="menuService?.form" (ngSubmit)="saveAndContinue()" class="px-0 px-sm-3">
    <div class="container-fluid px-0 px-md-3">
        <div class="row" *ngIf="canShowConsignmentServiceModule">
            <p class="col-md-12 text-bold">Información de manifiesto</p>
            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Tipo de manifiesto<span class="asterisk">*</span></mat-label>
                <mat-select [formControl]="manifestType" [compareWith]="compareByCode">
                    <mat-option *ngFor="let option of manifestTypeOptions" [value]="option">
                        <i matPrefix class="fas fa-info-circle color-secondary-dark cursor-pointer"
                            [matTooltip]="option.tooltip" matTooltipClass="tooltip__alert"
                            matTooltipPosition="left"></i>
                        {{ option.description }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="manifestType?.errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Tipo de operación <span class="asterisk">*</span></mat-label>
                <mat-select [formControl]="operationType" [compareWith]="compareByCode">
                    <mat-option *ngFor="let option of operationTypeOptions" [value]="option">
                        <i matPrefix class="fas fa-info-circle color-secondary-dark cursor-pointer"
                            [matTooltip]="option.tooltip" matTooltipClass="tooltip__alert"
                            matTooltipPosition="left"></i>
                        {{ option.description }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="operationType?.errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
            <p class="alert alert-danger col-12 text-bold text-center" role="alert"
                *ngIf="canEditConsignmentServiceModule && !canEditConsignments">
                <i class="fas fa-exclamation-circle"></i>
                Debes completar la información del contratante del servicio para poder agregar y editar remesas
            </p>
            <app-consignment-card *ngFor="let consignment of filteredConsignmentsFormArray;let i=index"
                class="col-12 consignment-container" [ngClass]="{'border-top-gray': !i}" [form]="consignment"
                [title]="'Remesa ' + (i + 1)" [formValidate]="formValidate" [senderCityOptions]="senderCityOptions"
                [recipientCityOptions]="recipientCityOptions" (removeConsignmentForm)="removeConsignmentForm(i)"
                [canDeleteConsignment]="canEditConsignments">
            </app-consignment-card>
            <p class="text-bold font-secondary-dark col-12 pl-2 mb-2 text-center cursor-pointer"
                (click)="watchMoreConsignments()" *ngIf="numberOfConsignmentsShowed < consignmentsFormArray.length">
                <span>Ver más remesas</span>
            </p>
            <p class="text-bold font-secondary-dark pl-2 cursor-pointer" (click)="addConsignmentForm()"
                *ngIf="canEditConsignments && numberOfConsignmentsShowed >= consignmentsFormArray.length">
                <i class="fas fa-plus-circle"></i>
                <span>Agregar {{consignmentsFormArray?.length ? 'otra ': ''}}remesa</span>
            </p>
        </div>
        <div *ngIf="!canShowConsignmentServiceModule" class="no-permission-container">
            <i class="fas fa-exclamation-circle fa-lg m-2"></i>
            <h4 class="text-bold">No tienes permiso para acceder a esta información. Contacta a tu administrador para
                solicitar acceso.</h4>
        </div>
        <!-- Action buttons -->
        <div class="button-container mt-4">
            <button mat-raised-button color="primary" class="button-width-auto primary-secondary button-small"
                [ngClass]="{'warn': true}" type="button" (click)="backToPreviousStep()">
                <span class="mat-button-wrapper">Atras</span>
            </button>
            <button class="mx-2" type="submit" mat-raised-button color="accent" *ngIf="canEditConsignmentServiceModule">
                Guardar y Continuar
            </button>
            <button class="mx-2" type="button" mat-raised-button color="accent" (click)="continueToNextStep()"
                *ngIf="!canEditConsignmentServiceModule">
                Continuar
            </button>
        </div>
    </div>
</form>
<div class="container-content">
  <div *ngIf="!user" class="text-center ">
    <span class="field__card--title">
      Administrador
    </span>
    <p class="field__card--label">
      El vehículo no cuenta actualmente con administrador
    </p>
    <p class="user-add font-secondary-dark cursor-pointer field__card--label" (click)="openEditAdministrator()">
      <i class="fas fa-user-plus"></i>
      <span class="ml-2"><strong>Asignar un Administrador</strong></span>
    </p>
  </div>

  <div *ngIf="user">
    <!--Icons-->
    <div class="icons-container">
      <!--Error-->
      <i class="fa fa-exclamation-circle font-secondary-dark i-view-error color--error" aria-hidden="true"
        *ngIf="user && utils.isDefined(user.state) && userStateByValidationRules?.description === 'Inactive'"></i>
      <!--Detail-->
      <i *ngIf="showDriverDetail" class="fas fa-eye font-secondary-dark i-view-info" (click)="openDetailUser()"></i>
      <!--Change Driver-->
      <i class="fas fa-exchange-alt font-secondary-dark i-view-info" (click)="changeDriver()"
        *ngIf="isDriver && user && utils.isDefined(user.state) && user.state.description !== 'Pending'"></i>
      <!--Edit-->
      <i class="fas fa-edit font-secondary-dark i-view-info" *ngIf="disabledEdit && user"
        (click)="openEditVehicle()"></i>
    </div>
    <div class="container-photo">
      <figure class="driver__picture driver__picture--loaded"
        *ngIf="user?.profilePictureUrl && user?.information?.name">
        <img [src]="user?.profilePictureUrl" alt="">
      </figure>
      <figure class="driver__picture" *ngIf="!user?.profilePictureUrl || !user?.information?.name">
        <img src="/assets/svg/icons/icon-profile.png" alt="">
      </figure>
      <div class="driver__score__column">
        <div class="text-center driver__score" *ngIf="user?.teclogiScore && user?.state && utils.isDefined(user?.state?.active) && userStateByValidationRules?.active">
          <img *ngFor="let star of scoreDriver" src="/assets/svg/icons/icon-star-purple.svg"
            [ngStyle]="{'filter':user?.teclogiScore?.globalScore<=2&&'invert(32%) sepia(80%) saturate(7072%) hue-rotate(353deg) brightness(95%) contrast(133%)'}">
        </div>
      </div>
      <div>
        <div class="text-center" *ngIf="showCheckValidation">
          <span class="field__card--value text__color" style="font-size: 17px;"
            [ngClass]="{'color--warning':( utils.isDefined(user?.state?.description) && (userStateByValidationRules?.description === 'Pending' || userStateByValidationRules?.description === 'Estudio de seguridad en proceso')) ,'color--error': !user?.state || (utils.isDefined(user?.state?.active) && !userStateByValidationRules?.active && utils.isDefined(user?.state?.description) && userStateByValidationRules?.description !== 'Pending' && userStateByValidationRules?.description !== 'Estudio de seguridad en proceso'), 'color--success': userStateByValidationRules?.active}">
            {{userStateByValidationRules?.description}}
          </span>
        </div>
        <div *ngIf="showCheckValidationUser">
          <div class="button-container">
            <button class="button-width-auto bg-color-primary" mat-raised-button color="primary"
              (click)="checkCompanyConditions()">
              Validar estudio de seguridad <span *ngIf="showCheckValidationIndividual">(opcional)</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-info">
      <div class="info-first-column">
        <div>
          <span class="field__card--title">
            {{user && user.information && user.information.name ? (user.information.name | capitalizeText) :
            relatedInfo.cargo &&
            relatedInfo.cargo.driver ? relatedInfo.cargo.driver : '-'}}
          </span>
          <h6 class="role__name">
            {{role | roleTranslate}}
          </h6>
        </div>
      </div>
      <div class="separator_center"></div>
      <div class="container-text">
        <label class="field__card--label">
          Tipo de identificación
        </label>
        <label class="field__card--value">
          {{user && user.information && user.information.documentTypeName ? user.information.documentTypeName :
          '-'}}
        </label>
      </div>
      <div class="container-text">
        <label class="field__card--label">
          N° identificación
        </label>
        <label class="field__card--value">
          {{user && user.information && user.information.document ? user.information.document : '-'}}
        </label>
      </div>
      <div class="container-text">
        <label class="field__card--label">
          Celular
        </label>
        <label class="field__card--value">
          {{user && user.phone ? (user.phone | phoneNumber): '-'}}
        </label>
      </div>
      <div class="container-text" *ngIf="user && user.city">
        <label class="field__card--label">
          Ciudad
        </label>
        <label class="field__card--value">
          {{user.city}}
        </label>
      </div>
      <div class="container-text" *ngIf="user && user.address">
        <label class="field__card--label">
          Dirección
        </label>
        <label class="field__card--value">
          {{user.address}}
        </label>
      </div>
      <div class="container-text" *ngIf="user && user.manifestQuantity && isDriver">
        <label class="field__card--label">
          N° de manifiestos
        </label>
        <label class="field__card--value">
          {{ user.manifestQuantity }}
        </label>
        <div class="d-flex flex-column">
          <p class="show-more" (click)="openManifests()">Ver manifiestos</p>
        </div>
      </div>
      <div class="container-text" *ngIf="user && !user.manifestQuantity && isDriver">
        <label class="field__card--label">
          Ver manifiestos:
        </label>
        <label class="field__card--value">
          {{ user && user.manifests && user.manifests[0] ? user.manifests[0].ministryId : '-' }}
        </label>
      </div>
    </div>
  </div>

</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoSearchService } from './cargo-search.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';

@Component({
  selector: 'app-cargo-search',
  templateUrl: './cargo-search.component.html',
  styleUrls: ['./cargo-search.component.scss']
})
export class CargoSearchComponent {
  public cargoId = new FormControl('', [Validators.required, Validators.maxLength(6)]);

  constructor(
    private cargoSearchService: CargoSearchService,
    private snackBarService: SnackBarService,
    public utils: Utils,
    private router: Router,
  ) { }

  onSubmit() {
    if (this.utils.errorMessagesCustomized(this.cargoId, 'ID del servicio')) return;
    this.validateLoad(this.cargoId.value)
  }

  validateLoad(consecutive: string) {
    this.cargoSearchService.getCargoByConsecutive(consecutive).subscribe(
      (success: Cargo[]) => {
        if (!success || !success.length) this.snackBarService.openSnackBar("El servicio no existe", undefined, 'error');
        else {
          success[0].state == CargoStateEnum.DELETED
            ? this.snackBarService.openSnackBar("El servicio fue eliminado", undefined, 'error')
            : this.router.navigate([(success[0].state == CargoStateEnum.START_SERVICE ? '/cargo/tracking/' : success[0].state === CargoStateEnum.REQUEST ? '/service-request-form/menu-service/' : '/cargo/detail/') + success[0].consecutive]);
        }
      },
      () => this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'alert')
    )
  }
}

import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import * as _ from 'lodash';
import { Utils } from 'src/app/core/resources/utils';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoItemService } from '../../../cargo-item/cargo-item.service';
import { AdditionalCostCargo } from 'src/app/core/interfaces/additionalCostCargo';
import { ShippingCost } from 'src/app/core/enums/shipping-cost.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { delay } from 'rxjs/operators';
import { Company } from 'src/app/core/interfaces/company';
import { Subscription } from 'rxjs';
import { CurrencyMaskDirective } from 'src/app/core/directives/currency-mask.directive';

@Component({
  selector: 'app-cargo-value',
  templateUrl: './cargo-value.component.html',
  styleUrls: ['./cargo-value.component.scss'],
  providers: [AuthService, Global]
})
export class CargoValueComponent implements OnInit {
  permission = Permission;
  detailAdditionalServices = {
    auxiliar: '',
    guard: ''
  };
  porcentageList: Array<object> = [];
  minRateCost: number = 1;
  otherFreightCost: FormControl = new FormControl(1, { validators: [Validators.required, Validators.min(1)], updateOn: 'blur' });
  otherFreightCostSub: Subscription;
  showOtherFreightCost: boolean = false;
  paymentTimeOptions = {
    '8 días': 8,
    '15 días': 15,
    '30 días': 30,
    '60 días': 60,
    '90 días': 90
  }
  paymentTimeVisibleOptions = Object.keys(this.paymentTimeOptions);
  companyUser: Company;
  companyName: string;
  permissionToCreateWithRateOrCostZero: boolean = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.createCargoWithRateOrCostZero);
  valueAdvanceControl: FormControl = new FormControl(0, {
    validators: [
      Validators.required,
      Validators.min(0),
      (control) => this.validateMaxAdvanceValue(control)
    ],
  });
  @ViewChildren(CurrencyMaskDirective) currencyMasks: QueryList<CurrencyMaskDirective>;

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    public utils: Utils,
    private router: Router,
    private snackBarService: SnackBarService,
    public cargoItemService: CargoItemService,
    private permissionRole: PermissionRole,
    private authService: AuthService,
  ) {
    this.setTypetrip();
  }

  ngOnInit() {
    this.permissionToCreateWithRateOrCostZero ? this.minRateCost = 0 : this.hasPermissionNoMinimumUtility ? this.minRateCost = 0 : this.minRateCost = 1;
    this.manualCreationCargoService.formShippingCost.controls.rate.setValidators([Validators.required, Validators.min(this.minRateCost)]);
    const validatorsFreightCost = [Validators.required];
    if (!this.isAutomaticTotalCostCalculation)
      validatorsFreightCost.push(Validators.min(this.minRateCost));
    this.manualCreationCargoService.formShippingCost.controls.freightCost.setValidators(validatorsFreightCost);
    this.manualCreationCargoService.formShippingCost.controls.advancePercentage.setValidators([Validators.required, Validators.min(0), Validators.max(this.maxAdvancePercentage)]);
    const paymentTime = this.manualCreationCargoService.cargoForm.get('shippingCost.paymentTime').value;
    this.companyUser = this.authService.getCompany()
    this.companyName = this.companyUser.name;
    if (this.validateValue(paymentTime)) {
      this.otherFreightCost.setValue(paymentTime)
      this.showOtherFreightCost = true;
    }
    this.otherFreightCostSub = this.otherFreightCost.valueChanges
      .pipe(delay(10))
      .subscribe(value => {
        this.manualCreationCargoService.cargoForm.get('shippingCost.paymentTime').setValue(value);
        this.showOtherFreightCost = this.validateValue(value);
      });
    if (this.isAutomaticTotalCostCalculation) {
      this.manualCreationCargoService.formShippingCost.controls.rate.valueChanges
        .pipe(delay(10))
        .subscribe(value => {
          const totalCost = (parseInt(value.replace(/\D/g, '')) * 10) / 100;
          this.manualCreationCargoService.formShippingCost.controls.freightCost.setValue(totalCost);
          this.manualCreationCargoService.formShippingCost.controls.totalCost.setValue(totalCost);
        });
    }

    this.manualCreationCargoService.formShippingCost.controls.freightCost.valueChanges
      .pipe(delay(10))
      .subscribe(value => {
        this.valueAdvanceControl.updateValueAndValidity();
        if (this.manualCreationCargoService.formShippingCost.controls.advancePercentage.value) {
          this.valueAdvanceControl.setValue(
            0,
            { emitEvent: false }
          );
          this.manualCreationCargoService.formShippingCost.controls.valueAdvance.setValue(0);
          this.manualCreationCargoService.formShippingCost.controls.advancePercentage.setValue(
            0,
            { emitEvent: false }
          );
        }
      });

    this.manualCreationCargoService.formShippingCost.controls.advancePercentage.valueChanges
      .pipe(delay(10))
      .subscribe(percentage => {
        this.updateValueAdvance();
      });

    const existingValue = this.manualCreationCargoService.formShippingCost.controls.valueAdvance.value;
    if (existingValue) {
      this.valueAdvanceControl.setValue(
        this.utils.formatStorageValues(existingValue.toFixed(1)),
        { emitEvent: false }
      );
    }

    this.valueAdvanceControl.valueChanges
      .pipe(delay(10))
      .subscribe(amount => {
        this.updateAdvancePercentage(amount);
      });
  }

  setTypetrip() {
    this.porcentageList = this.utils.getPorcentageAdvance(71);
  }

  onSelectPorcentage($event) {
    this.manualCreationCargoService.formShippingCost.controls.advancePercentage.setValue(
      $event.value
    );
  }

  validateValue(value): boolean {
    return !!(value && !Object.values(this.paymentTimeOptions).includes(value))
  }

  othersSelected(value: boolean) {
    this.showOtherFreightCost = value;
  }

  displayPaymentTime(data) {
    return !this.showOtherFreightCost && data && data.key ? data.key : 'Otro';
  }

  returnPaymentTime(data) {
    return data && data.value ? data.value : data;
  }

  nextStep() {
    this.otherFreightCost.markAsTouched();
    this.manualCreationCargoService.travelExpensesForm.markAllAsTouched()

    if (this.showOtherFreightCost) {
      if (this.otherFreightCost.invalid) this.otherFreightCost.setValue(1);
      this.manualCreationCargoService.cargoForm.controls.shippingCost['controls'].paymentTime.setValue(
        this.otherFreightCost.value
      );
    }
    this.manualCreationCargoService.formShippingCost.controls.advancePercentage.setValue(parseFloat(this.manualCreationCargoService.formShippingCost.controls.advancePercentage.value));
    this.manualCreationCargoService.getCargoForm().get('shippingCost.totalCost').setValue(
      this.manualCreationCargoService.getCargoForm().get('shippingCost.freightCost').value
    );
    this.manualCreationCargoService.formShippingCost.controls.advancePercentage.markAsTouched();
    this.manualCreationCargoService.formShippingCost.controls.rate.markAsTouched();
    this.manualCreationCargoService.formShippingCost.controls.freightCost.markAsTouched();
    if (!this.manualCreationCargoService.isValidCargoValue() || !this.manualCreationCargoService.isValidMethodPayment()) {
      if (this.utils.errorMessagesCustomized(this.manualCreationCargoService.formShippingCost.controls.rate, 'tarifa', null, null, this.minRateCost)) return;
      else if (this.utils.errorMessagesCustomized(this.manualCreationCargoService.formShippingCost.controls.freightCost, 'flete', null, null, this.minRateCost)) return;
      else if (this.utils.errorMessagesCustomized(this.manualCreationCargoService.formShippingCost.controls.advancePercentage, 'porcentaje de anticipo', null, null, 1, this.maxAdvancePercentage)) return;
      else if (!this.authService.getCompany().validateFreight && !_.isNumber(this.manualCreationCargoService.cargoForm.get('shippingCost.paymentTime').value))
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'tiempo de pago'), undefined, 'alert');
      else if (this.manualCreationCargoService.travelExpensesForm.invalid) {
        const travelExpenses = (this.manualCreationCargoService.travelExpensesForm.get('travelExpenses') as FormArray);
        let errorFound = false;
        for (let i = 0; i < travelExpenses.controls.length; i++) {
          const travel = travelExpenses.controls[i];
          if (this.utils.errorMessagesCustomized(travel.get('travelExpensesType'), `concepto del viático ${i + 1}`))
            errorFound = true;
          else if (this.utils.errorMessagesCustomized(travel.get('totalPaid'), `valor del viático ${i + 1}`, null, null, 100))
            errorFound = true;
          if (errorFound) break;
        }
        if (!errorFound) this.snackBarService.openSnackBar(FormMessages.INVALID_TRAVEL_EXPENSES_FORM, undefined, 'error');
      }
      else
        this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
    } else if (this.utilityCargo < this.manualCreationCargoService.getUtilityOwner() && !this.permissionToCreateWithRateOrCostZero) {
      if (!this.hasPermissionNoMinimumUtility)
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.MINIMUN_UTILITY_NOT_REACHED, this.manualCreationCargoService.getUtilityOwner()), undefined, 'alert');
      else {
        this.manualCreationCargoService.getComponentActive().setId(4);
        this.manualCreationCargoService.getComponentActive().getStep().setId(2);
        this.router.navigate([this.manualCreationCargoService.steps.methodPayment.url]);
      }
    }
    else {
      this.manualCreationCargoService.getComponentActive().setId(4);
      this.manualCreationCargoService.getComponentActive().getStep().setId(2);
      this.router.navigate([this.manualCreationCargoService.steps.methodPayment.url]);
    }
  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(3);
    if (this.hasAdditionalCostsPermission) {
      this.manualCreationCargoService.getComponentActive().getStep().setId(3);
      this.router.navigate([this.manualCreationCargoService.steps.additionalService.url]);
    } else {
      this.manualCreationCargoService.getComponentActive().getStep().setId(2);
      this.router.navigate([this.manualCreationCargoService.steps.download.url]);
    }

  }

  validateMaxAdvanceValue(control: AbstractControl): ValidationErrors | null {
    const freightCost = this.manualCreationCargoService.formShippingCost.controls.freightCost.value || 0;
    const maxValue = this.utils.formatDecimal((freightCost * this.maxAdvancePercentage) / 100);

    if (!freightCost) {
      return { noFreightCost: true };
    }

    const value = control.value || 0;

    if (value > maxValue) {
      return {
        maxValueExceeded: {
          maxValue: maxValue,
          currentValue: value
        }
      };
    }

    return null;
  }

  updateValueAdvance() {
    const freightCost = this.manualCreationCargoService.formShippingCost.controls.freightCost.value || 0;
    const percentage = parseFloat(Number(this.manualCreationCargoService.formShippingCost.controls.advancePercentage.value).toFixed(1)) || 0;
    const valueAdvance = parseFloat(((freightCost * percentage) / 100).toFixed(1));

    this.valueAdvanceControl.setValue(
      valueAdvance,
      { emitEvent: false }
    );
    const valueAdvanceMask = this.currencyMasks.find(mask => mask.maskId === 'valueAdvance');
    if (valueAdvanceMask) {
      valueAdvanceMask.formatValue(valueAdvance);
    }
    this.manualCreationCargoService.formShippingCost.controls.valueAdvance.setValue(valueAdvance);
  }

  updateAdvancePercentage(amount: string | number) {
    const freightCost = this.manualCreationCargoService.formShippingCost.controls.freightCost.value || 0;
    const normalizedAmount = this.utils.normalizeValueCurrencyMask(amount);

    if (freightCost > 0) {
      const percentage = Number(((normalizedAmount * 100) / freightCost).toFixed(1));

      this.manualCreationCargoService.formShippingCost.controls.advancePercentage.setValue(
        parseFloat(percentage.toFixed(1)),
        { emitEvent: false }
      );
      const advancePercentageMask = this.currencyMasks.find(mask => mask.maskId === 'advancePercentage');
      if (advancePercentageMask) {
        advancePercentageMask.formatValue(parseFloat(percentage.toFixed(1)));
      }
    }
  }

  ngOnDestroy() {
    if (this.otherFreightCostSub) this.otherFreightCostSub.unsubscribe();
  }

  get hasPermissionNoMinimumUtility() {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.createCargosWithoutMinimumUtility
    )
      && this.manualCreationCargoService.getCargoForm().get('cargoModel.tripType.name').value === "Urbana"
      && this.manualCreationCargoService.getCargoForm().get('cargoFeature.productType.name')
      && this.manualCreationCargoService.getCargoForm().get('cargoFeature.productType.name').value
      && this.manualCreationCargoService.getCargoForm().get('cargoFeature.productType.name').value === "CONTENEDOR VACIO";
  }
  get hasAdditionalCostsPermission(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.additionalCosts
    )
  }

  get utilityCargo() {
    this.manualCreationCargoService.getCargoForm().get('shippingCost.totalCost').setValue(
      this.manualCreationCargoService.getCargoForm().get('shippingCost.freightCost').value
    );
    return this.cargoItemService.getUtilityCargo(
      this.manualCreationCargoService.cargoForm.value.shippingCost.rate,
      this.manualCreationCargoService.cargoForm.value.shippingCost.totalCost
    );
  }

  get ValueAdditionalCostRate() {
    const additionalCosts: AdditionalCostCargo[] = this.manualCreationCargoService.cargoForm.value.additionalCosts;
    let totalAdditionalCost = 0;
    for (let additionalCost of additionalCosts) {
      totalAdditionalCost = additionalCost.financialInformation.rate + totalAdditionalCost;
    }
    return totalAdditionalCost;
  }

  get ValueAdditionalCost() {
    const additionalCosts: AdditionalCostCargo[] = this.manualCreationCargoService.cargoForm.value.additionalCosts;
    let totalAdditionalCost = 0;
    for (let additionalCost of additionalCosts) {
      if (additionalCost.type.id == '13' || additionalCost.type.id == '14') {
        totalAdditionalCost = additionalCost.financialInformation.cost + totalAdditionalCost;
      }
    }
    return totalAdditionalCost;
  }

  get maxAdvancePercentage(): number {
    return this.manualCreationCargoService.getAdvancePercentageOwner() || (this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit) ? AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI : AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES);
  }

  get messageAdvancePercentageAllowed(): string {
    return `El porcentaje máximo permitido por ${this.manualCreationCargoService.getOwnerSelected().name} es ${this.maxAdvancePercentage}%`;
  }

  get isAutomaticTotalCostCalculation(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.automaticTotalCostCalculation
    )
  }
}

import { Component, OnInit } from '@angular/core';
import { TrackGuide } from 'src/app/core/interfaces/trackGuide';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';

@Component({
  selector: 'app-track-guide',
  templateUrl: './track-guide.component.html',
  styleUrls: ['./track-guide.component.scss']
})
export class TrackGuideComponent implements OnInit {

  detailActive: boolean = false;
  guide: TrackGuide;
  urlDefaultLogo = this.utils.getDefaultUrlLogo('white');

  constructor(
    private authService: AuthService,
    private utils: Utils
  ) { }

  ngOnInit() {

  }

  changeView($event) {
    this.detailActive = $event.detailActive;
    this.guide = $event.guide;
    this.urlDefaultLogo = this.utils.getDefaultUrlLogo('mainLogo');
  }

  get hasSessionActive(): boolean {
    return this.authService && this.authService.sessionActive();
  }

}

import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { Router } from '@angular/router';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { DatePipe } from '@angular/common';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-date-cargo',
  templateUrl: './date-cargo.component.html',
  styleUrls: ['./date-cargo.component.scss'],
  providers: [AuthService, Global, DatePipe]
})

export class DateCargoComponent {

  displayMonths = 2;
  navigation = 'select';
  showWeekNumbers = false;
  outsideDays = 'visible';
  minDate: Object;
  dateToday = new Date();
  minDateDownload: Object = {
    year: this.dateToday.getFullYear(),
    month: this.dateToday.getMonth() + 1,
    day: this.dateToday.getDate()
  };
  viewActive: number = 1;
  indexDownloadActive: number = 0;
  dateLoad;
  dateSelected;
  constructor(
    private router: Router,
    public manualCreationCargoService: ManualCreationCargoService,
    public snackBarService: SnackBarService,
    private ngZone: NgZone,
    public utils: Utils,
    private datePipe: DatePipe,
    private cdRef: ChangeDetectorRef
  ) {
    if (!this.utils.isDefined(this.manualCreationCargoService.cargoForm) || !this.manualCreationCargoService.cargoForm.value.idCompany.length) {
      this.router.navigate([this.manualCreationCargoService.steps.main.url]);
    } else {
      this.minDate = {
        year: this.dateToday.getFullYear(),
        month: this.dateToday.getMonth() + 1,
        day: this.dateToday.getDate()
      };
    }
  }

  ngOnInit(): void {
    this.setDateLoad();
    if (history && history.state && history.state.type) this.viewActive = history.state.type;
    if (this.manualCreationCargoService.getOwnerSelected().integration) this.manualCreationCargoService.formNumberDocumentSender.setValidators(Validators.required);
    for (let control of this.manualCreationCargoService.formDestinations.controls) control.get('downloadDate').setValidators(Validators.required)
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  setDateLoad(type?) {
    if (this.manualCreationCargoService.cargoForm.controls.dateLoad.value) {
      const dateSelectedSplitted = this.manualCreationCargoService.cargoForm.controls.dateLoad.value.split('-');
      type ? this.minDateDownload = {
        year: parseInt(dateSelectedSplitted[0]),
        month: parseInt(dateSelectedSplitted[1]),
        day: parseInt(dateSelectedSplitted[2])
      } :
        this.dateSelected = {
          year: parseInt(dateSelectedSplitted[0]),
          month: parseInt(dateSelectedSplitted[1]),
          day: parseInt(dateSelectedSplitted[2])
        }
    }
  }
  nextStep() {
    this.manualCreationCargoService.formNumberDocumentSender.markAsTouched();
    for (let control of this.manualCreationCargoService.formDestinations.controls) {
      control.get('downloadDate').markAsTouched();
    }
    if (this.viewActive === 1) {
      if (!this.manualCreationCargoService.isValidNumberDocumentSender()) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'código identificador'), undefined, 'alert');
      } else if (!this.manualCreationCargoService.isValidDateLoad()) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'la fecha de cargue'), undefined, 'alert');
      } else {
        this.viewActive = 2;
        this.focusDownloadInput(0);
      }
    } else if (
      this.manualCreationCargoService.isValidDatesDownload() &&
      this.viewActive === 2
    ) {
      this.manualCreationCargoService.getComponentActive().setItineraryComplete(true);
      if (this.manualCreationCargoService.getCargoForm().get('cargoModel.tripType.name').value === "Internacional") {
        this.manualCreationCargoService.getComponentActive().setId(1);
        this.manualCreationCargoService.getComponentActive().getStep().setId(3);
        this.router.navigate([this.manualCreationCargoService.steps.typeCargo.url])
      } else {
        this.manualCreationCargoService.getComponentActive().setId(1);
        this.manualCreationCargoService.getComponentActive().getStep().setId(1);
        this.router.navigate([this.manualCreationCargoService.steps.typeTrip.url]);
      }
    } else {
      if (!this.manualCreationCargoService.isValidDatesDownload()) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'la fecha de descargue'), undefined, 'alert');
      } else {
        this.manualCreationCargoService.getComponentActive().setItineraryComplete(true);
        this.manualCreationCargoService.getComponentActive().setId(1);
        this.manualCreationCargoService.getComponentActive().getStep().setId(1);
        this.router.navigate([this.manualCreationCargoService.steps.typeTrip.url]);
      }
    }
  }

  focusDownloadInput(indexDownload: number) {
    this.indexDownloadActive = indexDownload;
    if (this.manualCreationCargoService.formDestinations.controls[this.indexDownloadActive].get('downloadDate').value) {
      const dateSelectedSplitted = this.manualCreationCargoService.formDestinations.controls[this.indexDownloadActive].get('downloadDate').value.split('-')
      this.dateSelected = {
        year: parseInt(dateSelectedSplitted[0]),
        month: parseInt(dateSelectedSplitted[1]),
        day: parseInt(dateSelectedSplitted[2])
      }
    } else {
      this.dateSelected = {};
    }
    this.snackBarService.openSnackBar(`Está seleccionando la fecha de descargue del destino ${this.indexDownloadActive + 1}`, undefined, 'info');
    if (indexDownload === 0) {
      this.setDateLoad(true);
    } else {
      this.minDateDownload = this.getMinDateDownload(indexDownload);
    }
  }

  getMinDateDownload(index: number) {
    const lastDate = this.manualCreationCargoService.formDestinations.controls[this.indexDownloadActive - 1].get('downloadDate').value;
    if (lastDate) {
      let dateSplit = lastDate.split("-");
      return {
        year: parseInt(dateSplit[0]),
        month: parseInt(dateSplit[1]),
        day: parseInt(dateSplit[2])
      }
    } else {
      return {
        year: this.dateLoad.year,
        month: this.dateLoad.month,
        day: this.dateLoad.day
      };
    }
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, "d MMMM yyyy");
  }

  onDateSelect(event: any, type: string) {
    const date = event.month + '/' + event.day + '/' + event.year;
    if (type === 'upload') {
      this.dateLoad = event;
      this.manualCreationCargoService.cargoForm.controls.dateLoad.setValue(
        DateManager.formatDate(date, 'MM/DD/YYYY', 'YYYY-MM-DD HH:mm ZZ')
      );
      this.setDateLoad(true);
    } else {
      ((this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.get('destination') as FormArray)
        .at(this.indexDownloadActive) as FormGroup).get('downloadDate').setValue(
          DateManager.formatDate(date, 'MM/DD/YYYY', 'YYYY-MM-DD HH:mm ZZ')
        );
      if ((this.indexDownloadActive + 1) < this.manualCreationCargoService.formDestinations.length) {
        if (this.manualCreationCargoService.formDestinations.controls[this.indexDownloadActive + 1].get('downloadDate').value && this.manualCreationCargoService.formDestinations.controls[this.indexDownloadActive].get('downloadDate').value) {
          const destination: Date = DateManager.stringToDate(this.manualCreationCargoService.formDestinations.controls[this.indexDownloadActive].get('downloadDate').value, 'YYYY-MM-DD HH:mm ZZ');
          const nextDestination: Date = DateManager.stringToDate(this.manualCreationCargoService.formDestinations.controls[this.indexDownloadActive + 1].get('downloadDate').value, 'YYYY-MM-DD HH:mm ZZ');
          if (DateManager.isBefore(nextDestination, destination)) {
            this.manualCreationCargoService.formDestinations.controls[this.indexDownloadActive + 1].get('downloadDate').setValue('');
          }
        }
        this.focusDownloadInput(this.indexDownloadActive + 1);
      }
    }
  }

  get destinations() {
    return this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.controls.destination.controls;
  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(0);
    this.manualCreationCargoService.getComponentActive().getStep().setId(1);
    this.router.navigate([this.manualCreationCargoService.steps.main.url], { state: { type: 'destination' } });
  }

  stepBackSameView() {
    this.viewActive = 1;
    this.setDateLoad();
  }

  ngOnDestroy() {
    this.cdRef.detach();
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { Company } from 'src/app/core/interfaces/company';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { AuthService } from 'src/app/core/services/authentication.service';
import { FreightListService } from '../cargo-list/cargo-list.service';
import * as _ from 'lodash';
import { UploadRouteService } from './upload-route.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { BasicAddress } from 'src/app/core/interfaces/basicAddress';
import { Subscription } from 'rxjs';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-upload-route',
  templateUrl: './upload-route.component.html',
  styleUrls: ['./upload-route.component.scss'],
  providers: [UploadRouteService]
})
export class UploadRouteComponent implements OnInit {
  listCompanies: Company[] = [];
  companyControl: FormControl = new FormControl({
    nit: "",
    exclusive: ""
  }, Validators.required);
  companySub: Subscription;
  cityControl: FormControl = new FormControl('', Validators.required);
  dateControl: FormControl = new FormControl(new Date(), Validators.required);
  readonly today: Date = new Date();
  citySub: Subscription;
  minFreight: number = 1;
  minRate: number = 1;
  validateCompany: string = '';
  validateCity: string = '';
  optionsCompany = {
    title: 'Compañía',
    appearance: 'outline'
  };
  optionsCity = {
    title: 'Ciudad de orígen',
    appearance: 'outline',
    pinIcon: true
  };
  form: FormGroup;
  formatError: boolean = false;
  constructor(
    public formBuilder: FormBuilder,
    private authService: AuthService,
    private freightListService: FreightListService,
    private uploadRouteService: UploadRouteService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    public utils: Utils
  ) {
    this.form = this.formBuilder.group({
      file: ['', Validators.required],
      companyId: ['', Validators.required],
      isExclusive: [false],
      freight: [0, [Validators.required, Validators.min(this.minFreight)]],
      rate: [0, [Validators.required, Validators.min(this.minRate)]],
      origin: this.formBuilder.group({
        name: ['', Validators.required],
        municipalityCode: ['', Validators.required],
        country: this.formBuilder.group({
          name: ['Colombia'],
          code: ['CO']
        })
      }),
      date: [''],
      addresses: [[]]
    });
  }

  ngOnInit() {
    this.setSubscriptions();
  }

  setSubscriptions() {
    this.companySub = this.companyControl.valueChanges
      .subscribe(value => {
        if (value && value.companyId) {
          this.form.get('companyId').setValue(value.companyId);
          this.form.get('isExclusive').setValue(value.exclusive);
        } else {
          this.form.get('companyId').setValue('');
          this.form.get('isExclusive').setValue('');
        }
      })

    this.citySub = this.cityControl.valueChanges
      .subscribe(value => {
        if (value && value.id) {
          this.form.get('origin.name').setValue(value.name);
          this.form.get('origin.municipalityCode').setValue(value.id);
        } else {
          this.form.get('origin.name').setValue('');
          this.form.get('origin.municipalityCode').setValue('');
        }
      })
  }

  fileEvents($event) {
    if ($event) {
      if ($event.error && $event.error === 'formatError') this.formatError = true;
      else {
        this.formatError = false;
      }
    }

  }

  onSubmit() {
    this.validateCompany = 'touched';
    this.validateCity = 'touched';
    if (this.form.valid && this.companyControl.valid && this.dateControl.valid) {
      this.spinner.show();
      this.form.get('freight').setValue(Number(this.form.get('freight').value));
      this.form.get('rate').setValue(Number(this.form.get('rate').value));
      this.form.get('date').setValue(DateManager.dateToString(this.dateControl.value));
      this.uploadRouteService.createCargoRouteUpload(this.form.value).subscribe(
        (success) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Los servicios se estan creando, los podrás visualizar en el listado de solicitudes');
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error al crear el servicio', undefined, 'error');
        }
      );
    } else {
      if (this.utils.errorMessagesCustomized(this.form.get('companyId'), 'compañía')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('freight'), 'flete', null, null, this.minFreight)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('rate'), 'tarifa', null, null, this.minRate)) return;
      else if (!this.form.value.file.fileData || !this.form.value.file.fileData.file || !this.form.value.file.fileData.name) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.FILE_NOT_UPLOADED, 'la ruta'), undefined, 'alert');
      }
      else if (this.formatError) {
        this.snackBarService.openSnackBar(FormMessages.INVALID_FORMAT_FILE, undefined, 'alert');
        return;
      }
      else if (this.utils.errorMessagesCustomized(this.form.get('municipalityCode'), 'ciudad de origen')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('dateControl'), 'fecha de cargue')) return;
      else if (this.form && this.form.get('addresses') && this.form.get('addresses').value && this.form.get('addresses').value.length) {
        this.form.get('addresses').value.forEach((address, i) => {
          if (!address.address) this.snackBarService.openSnackBar(Fmt.string(FormMessages.ADDRESS_NOT_DEFINED, i + 1), undefined, 'alert');
          return;
        }
        )
        return;
      }
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
    }
  }

  processAddresses(addresses: BasicAddress[]) {
    if (addresses && addresses.length && this.form && this.form.get('addresses')) this.form.get('addresses').patchValue(addresses);
  }

  //GETTERS
  get emptyAddress() {
    return this.form && this.form.get('addresses') && this.form.get('addresses').value && this.form.get('addresses').value.length && this.form.get('addresses').value.some(address => address.address === '');
  }

  ngOnDestroy() {
    if (this.companySub) this.companySub.unsubscribe();
    if (this.citySub) this.citySub.unsubscribe();
  }

}

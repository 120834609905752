<form [formGroup]="menuService?.form" (ngSubmit)="saveAndContinue()" class="px-0 px-sm-3">
    <div class="container-fluid">
        <div class="row" *ngIf="canSeeFinancialInformation">
            <p class="col-12 text-bold">Valor del Servicio</p>

            <!-- Rate -->
            <div class="col-md-6 mb-3">
                <mat-form-field appearance="outline">
                    <mat-label>
                        Valor de la tarifa
                        <span class="optional">(Valor a pagar por el cliente)</span>
                        <span class="asterisk">*</span>
                    </mat-label>
                    <input matInput type="text" currencyMask placeholder="$000.000" [formControl]="rateControl">
                    <mat-error *ngIf="rateControl.hasError('required')">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="rateControl.hasError('min')">
                        {{utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'tarifa', hasExceptionRateOrCost ? 0 :
                        1)}}
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- Freight Cost -->
            <div class="col-md-6 mb-3">
                <mat-form-field appearance="outline">
                    <mat-label>
                        Valor del flete
                        <span class="optional">(Valor a pagar al {{menuService?.isEscortService() ? 'escolta' :
                            'conductor' }})</span>
                        <span class="asterisk">*</span>
                    </mat-label>
                    <input matInput type="text" currencyMask placeholder="$000.000" [formControl]="freightCostControl">
                    <mat-error *ngIf="freightCostControl.hasError('required')">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="freightCostControl.hasError('min')">
                        {{
                        utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'flete', hasExceptionRateOrCost ? 0 : 1)
                        }}
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- Reminder message -->
            <div class="col-12 alert alert-danger p-2 mb-3 text-center text-bold" role="alert"
                *ngIf="utilityCargo < minUtility && !hasExceptionRateOrCost">
                Recuerde: La utilidad no debe ser menor a {{minUtility}}%
            </div>

            <!-- Payment info -->
            <div class="col-md-6 mb-3">
                <mat-form-field appearance="outline">
                    <mat-label>Porcentaje de anticipo<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" onlyNumbersInt [formControl]="advancePercentageControl">
                    <i matSuffix class="fas fa-info-circle color-secondary-dark cursor-pointer"
                        [matTooltip]="messageAdvancePercentageAllowed" matTooltipClass="tooltip__alert"></i>
                    <mat-error *ngIf="advancePercentageControl.errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="advancePercentageControl.hasError('min')">
                        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'porcentaje', 0) }}
                    </mat-error>
                    <mat-error *ngIf="advancePercentageControl.hasError('max')">
                        {{ utils.giveMessageError('MAX_VALUE_EXCEED', 'porcentaje', maxPercentage) }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Valor del anticipo<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" currencyMask maskId="valueAdvance" [formControl]="valueAdvanceControl">
                    <mat-error *ngIf="valueAdvanceControl.hasError('min')">
                        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'valor del anticipo', 0) }}
                    </mat-error>
                    <mat-error *ngIf="valueAdvanceControl.hasError('max')">
                        {{ utils.giveMessageError('MAX_VALUE_EXCEED', 'valor del anticipo', maxAdvanceValue) }}
                    </mat-error>
                    <mat-error *ngIf="valueAdvanceControl.errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- Payment Time -->
            <div class="col-md-6 mb-3">
                <mat-form-field appearance="outline">
                    <mat-label>Tiempo de pago del saldo<span class="asterisk">*</span></mat-label>
                    <mat-select [formControl]="paymentTimeControl">
                        <mat-option *ngFor="let paymentTime of paymentTimeValues" [value]="paymentTime">
                            {{ paymentTime }} días
                        </mat-option>
                        <mat-option [value]="-1">Otro</mat-option>
                    </mat-select>
                    <mat-error *ngIf="paymentTimeControl.errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 mb-3" *ngIf="paymentTimeControl?.value === -1">
                <mat-form-field appearance="outline">
                    <mat-label>Otro tiempo de pago<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" onlyNumbersInt placeholder="2" [formControl]="otherPaymentTimeControl">
                    <mat-error *ngIf="otherPaymentTimeControl.errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <app-additional-service-form *ngIf="showAdditionalCosts"></app-additional-service-form>
        <div class="d-flex flex-wrap" *ngIf="canEditAndSeeTravelExpenses">
            <!-- Viaticos Section -->
            <p class="col-12 px-0 mt-3 text-bold">Viáticos</p>
            <div class="col-12 px-0">
                <div *ngFor="let travelExpense of travelExpensesSaved?.controls; let i = index"
                    class="d-flex flex-wrap mb-2 pt-4 position-relative">
                    <!-- Viaticos Concept -->
                    <mat-form-field appearance="outline" class="col-md-6 px-0 pl-sm-2">
                        <mat-label>Concepto del viático<span class="optional">(opcional)</span></mat-label>
                        <mat-select [formControl]="travelExpense.get('travelExpensesType')"
                            [compareWith]="compareTravelExpensesType">
                            <mat-option *ngFor="let concept of concepts" [value]="concept">
                                {{ concept.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- Viaticos Value -->
                    <mat-form-field appearance="outline" class="col-md-6 px-0 pl-sm-2">
                        <mat-label>Valor del viático<span class="optional">(opcional)</span></mat-label>
                        <input matInput type="text" currencyMask placeholder="$000.000"
                            [formControl]="travelExpense.get('totalPaid')">
                    </mat-form-field>

                    <!-- Remove Button -->
                    <i class="fas fa-trash fa-lg trash-icon" matTooltip="Eliminar viático"
                        (click)="removeTravelExpense(i)" *ngIf="canDeleteTravelExpenses"></i>
                </div>
            </div>

            <!-- Add viaticos -->
            <div class="col-12 color-secondary-dark cursor-pointer" (click)="addTravelExpense()">
                <i class="fas fa-plus-circle"></i>
                <span>Agregar monto de viáticos (opcional)</span>
            </div>
        </div>

        <div *ngIf="!canSeeFinancialInformation && !canEditAndSeeTravelExpenses && !showAdditionalCosts"
            class="no-permission-container">
            <i class="fas fa-exclamation-circle fa-lg m-2"></i>
            <h4 class="text-bold">No tienes permiso para acceder a esta información. Contacta a tu administrador para
                solicitar acceso.</h4>
        </div>

        <!-- Action buttons -->
        <div class="button-container mt-4">
            <button mat-raised-button color="primary" class="button-width-auto primary-secondary button-small"
                [ngClass]="{'warn': true}" type="button" (click)="backToPreviousStep()">
                <span class="mat-button-wrapper">Atras</span>
            </button>
            <button class="mx-2" type="submit" mat-raised-button color="accent"
                *ngIf="canEditFinancialInformation || canEditAndSeeTravelExpenses || showAdditionalCosts">
                Guardar y Continuar
            </button>
            <button class="mx-2" type="button" mat-raised-button color="accent" (click)="continueToNextStep()"
                *ngIf="!canEditFinancialInformation && !canEditAndSeeTravelExpenses && !showAdditionalCosts">
                Continuar
            </button>
        </div>
    </div>
</form>
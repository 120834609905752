import { Component, OnInit } from '@angular/core';
import { ManualCreationCargoService } from '../../manual-creation-cargo.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { timeout } from 'rxjs/operators';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoManager } from 'src/app/core/managers/cargo.manager';

@Component({
  selector: 'app-request-button',
  templateUrl: './request-button.component.html',
  styleUrls: ['./request-button.component.scss']
})
export class RequestButtonComponent {
  permission = Permission;

  constructor(
    private manualCreationCargoService: ManualCreationCargoService,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private permissionRole: PermissionRole,
    private snackBarService: SnackBarService,
    private cargoManager: CargoManager
  ) { }

  get canCreateCargoRequest() {
    let tripType = this.manualCreationCargoService.isValidTripType();
    let serviceType = this.manualCreationCargoService.isValidServiceType();
    let vehicleType = this.manualCreationCargoService.isValidVehicleType();
    let vehicleQuantity = this.manualCreationCargoService.isValidVehicleQuantity();

    return tripType && serviceType && vehicleType && vehicleQuantity &&
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.createRequestFromManualCreationCargo
      );;
  }

  createCargoRequest() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    if (!this.manualCreationCargoService.isValidCargoValue(true)) {
      if (this.router.url === "/cargo/manual-creation/cargo-value") {
        dialogConfig.data = {
          title: 'Aún no se puede crear la solicitud de servicio',
          description: 'Debes diligenciar primero el valor del flete y la tarífa',
          iconError: true,
          hideBtnConfirm: true,
        };
        this.dialog.open(DialogComponent, dialogConfig);
      } else {
        dialogConfig.data = {
          title: 'Aún no se puede crear la solicitud de servicio',
          description: 'Se requiere diligenciar el valor del flete, ¿quieres ir a diligenciarlo?',
          showYesBtn: true,
          iconError: true,
        };
        const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.state) {
            this.manualCreationCargoService.getComponentActive().setId(4);
            this.manualCreationCargoService.getComponentActive().getStep().setId(1);
            this.router.navigate([this.manualCreationCargoService.steps.cargoValue.url]);
          }
        })
      }

    } else {
      let missingSteps = [];
      if (this.manualCreationCargoService.cargoForm.value.cargoModel.serviceType.name !== 'Contenedor vacío con carga de compensación' &&
        this.manualCreationCargoService.cargoForm.value.cargoModel.serviceType.name !== 'Contenedor vacío express' &&
        !this.manualCreationCargoService.isValidProductType()) missingSteps.push('El tipo de mercancía');
      if (!this.manualCreationCargoService.isValidDownloadAddressesCargoMeasure()) missingSteps.push('Las medidas del servicio');
      if (!(this.manualCreationCargoService.isValidUploadAddressesAdditionalData() &&
        this.manualCreationCargoService.isValidAllDateTimeLoad())) missingSteps.push('Los datos de cargue');
      if (!(this.manualCreationCargoService.isValidDownloadAddressesAdditionalData() &&
        this.manualCreationCargoService.isValidAllDateTimeDowndload())) missingSteps.push('Los datos de descargue');
      if (!this.manualCreationCargoService.isValidCargoValue()) missingSteps.push('El valor del flete');

      if (missingSteps.length) {
        dialogConfig.data = {
          title: '¿Deseas crear la solicitud de servicio?',
          description: 'Al crear una solicitud, podrás editar la información y no se crearán las remesas. Los siguientes campos se deberán llenar en la solicitud posteriormente:',
          icon: true
        };
        dialogConfig.data.messageList = missingSteps;
        const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.state)
            this.createRequest();
        })
      } else {
        dialogConfig.data = {
          title: '¿Deseas crear la solicitud de servicio?',
          description: 'Al crear una solicitud, podrás editar la información y no se crearán las remesas.',
          icon: true
        };
        const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.state) {
            this.createRequest();
          }
        })
      }
    }

  }

  async createRequest() {
    let path = '/cargo/list/request';
    this.spinner.show();
    const $createReq = this.manualCreationCargoService.createRequest().subscribe(
      {
        next: (success: Cargo[]) => {
          this.spinner.hide();
          this.belowSicetac(success, path);
          if (success && success.length)
            this.manualCreationCargoService.applyTravelExpenses(success);
        },
        error: (error) => {
          this.spinner.hide();
          this.manualCreationCargoService.showResponseCreateCargo(
            'Ocurrió un error al crear la solicitud de servicio',
            error
          );
        },
        complete: () => { $createReq.unsubscribe() }
      }
    )
  }

  public belowSicetac(cargo: Cargo[], path: string) {
    const formatter = new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    });
    const min = formatter.format(cargo[0].minimumApprovedValueSicetac);
    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.approvedBelowSicetac)) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      dialogConfig.disableClose = true;
      if (cargo[0].belowSicetac && !cargo[0].freightValueApprovedBelowSicetac) {
        dialogConfig.data = {
          title: '¿Desea aprobar el flete por debajo del SICETAC?',
          descriptionHTML: `El flete mínimo permitido por SICETAC es de <strong>${min}</strong>, si desea ajustarlo por favor realice una bonificación para ajustarse al valor mínimo permitido.`,
          btnDetailCargo: true,
          path: `service-request-form/menu-service/${cargo[0].consecutive}`,
          closeModal: true,
        };
        const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.state) {
            this.manualCreationCargoService.approvedBelowSiceTac(cargo[0].id).subscribe(
              () => {
                this.openConfirmBelowSicetac(path);
              },
              (error) => {
                this.snackBarService.openSnackBar("No se pudo aprobar", undefined, 'error');
              }
            );
            timeout(50000)
          }
        })
      }
    } else {
      if (cargo[0].belowSicetac && !cargo[0].freightValueApprovedBelowSicetac) {
        this.snackBarService.openSnackBar(`El flete mínimo es ${min}, por favor realice una bonificación para ajustarse al valor mínimo permitido`, undefined, 'alert');
      }
    }
    this.spinner.hide();
    this.cargoManager.modalCargoCreation(cargo, `Tu solicitud de servicio ha sido creada exitosamente con los siguientes consecutivos`);
  }

  openConfirmBelowSicetac(path) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      descriptionHTML: `Se ha aprobado el servicio por un flete que está <strong>por debajo del valor mínimo permitido</strong> por el SICETAC`,
      showBtn: true,
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate([path]);
    })
  }

}

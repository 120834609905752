import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuServiceService } from '../../menu-service/menu-service.service';
import { Utils } from 'src/app/core/resources/utils';
import { Router } from '@angular/router';
import { Fmt } from 'src/app/core/messages/fmt';
import { Subscription } from 'rxjs';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';

//TODO: Documentar el codigo
@Component({
  selector: 'app-observations-service-form',
  templateUrl: './observations-service-form.component.html',
  styleUrls: ['./observations-service-form.component.scss']
})
export class ObservationsServiceFormComponent implements OnInit, OnDestroy {
  permission = Permission;
  formSubscription: Subscription;
  observations = new FormControl('');
  observationsToDriver = new FormControl('');

  constructor(
    public menuService: MenuServiceService,
    private router: Router,
    private snackBarService: SnackBarService,
    private permissionRole: PermissionRole
  ) { }

  ngOnInit() {
    this.setLocalValues();
    this.setSubscription();
    this.checkPermissionsToEdit();
  }

  private setLocalValues() {
    this.observations.setValue(this.menuService.form.get('observation').value);
    this.observationsToDriver.setValue(this.menuService.form.get('observationDriver').value);
  }

  private setSubscription() {
    this.formSubscription = this.menuService.formObservable.subscribe(() => this.setLocalValues());
  }

  private checkPermissionsToEdit() {
    if (!this.canEditObservations) {
      this.observations.disable();
      this.observationsToDriver.disable();
    }
  }

  get canEditObservations(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.addObservation
    );
  }

  /**
   * @description Navigates to the previous step
   */
  backToPreviousStep() {
    this.router.navigate([Fmt.string(this.menuService.steps.additionalValuesService.url, this.menuService.cargo.consecutive)]);
  }

  /**
   * @description Saves form data and continues if valid
   */
  saveAndContinue() {
    this.menuService.form.get('observation').setValue(this.observations.value);
    this.menuService.form.get('observationDriver').setValue(this.observationsToDriver.value);
    this.menuService.updateService().then(() => {
      this.snackBarService.openSnackBar("Servicio actualizado exitosamente");
      this.continueToNextStep();
    }).catch(() => this.snackBarService.openSnackBar('Ocurrió un error al actualizar el servicio', undefined, 'error'));
  }

  continueToNextStep() {
    const isRequiredConsignmentsService = this.menuService.isRequiredConsignmentsService();
    this.router.navigate([Fmt.string(isRequiredConsignmentsService
      ? this.menuService.steps.consignmentsService.url
      : this.menuService.steps.servicePublication.url
      , this.menuService.cargo.consecutive)]);
  }

  ngOnDestroy() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }
}

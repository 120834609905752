import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoResources } from '../../../manual-creation-cargo/resources/cargo';
import { CargoDetailService } from '../../cargo-detail.service';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { Catalog } from 'src/app/core/interfaces/catalog';
import { VehicleCargo } from 'src/app/core/interfaces/vehicle-cargo';
import { ManualCreationCargoService } from '../../../manual-creation-cargo/manual-creation-cargo.service';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { Patterns } from 'src/app/core/resources/patterns';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { GoogleService } from 'src/app/core/services/google.service';
import { DateManager } from 'src/app/core/managers/date.manager';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { ThirdPartyAddress } from 'src/app/core/interfaces/thirdParty';
import { CargoFeature } from 'src/app/core/interfaces/cargoFeature';
import { ShippingCost } from 'src/app/core/interfaces/shippingCost';
import { ServiceType } from 'src/app/core/interfaces/serviceType';

export interface HiddenFieldsLoadDownloadCargo {
  cellphone?: boolean;
  email?: boolean;
  dateLoad?: boolean;
}

export interface BodyLoadDownloadCargo {
  id: string;
  state: string;
  cargoFeature: CargoFeature;
  dateLoad: string;
  shippingCost: ShippingCost;
}

@Component({
  selector: 'app-load-download-cargo',
  templateUrl: './load-download-cargo.component.html',
  styleUrls: ['./load-download-cargo.component.scss']
})
export class LoadDownloadCargoComponent extends ReactiveForm implements OnInit {
  @Input() cargo: Cargo;
  @Input() cleanCargo;
  @Input() hiddenFields: HiddenFieldsLoadDownloadCargo = {};
  @Input() hideOptionsAddCity: boolean;
  @Input() hideOptionsAddAddress: boolean;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  timeList: Array<{ value: string, title: string }> = [];
  showAlertChanges = "";
  showErrorReteica = false;
  cityOriginControl = new FormControl('', Validators.required);
  validate = '';
  cityOriginOptions: OptionsAutocomplete = {
    title: "Ciudad de Origen",
    requireFullCity: true,
  };
  cityDestinationControls = new FormArray([new FormControl('', Validators.required)]);
  cityDestinationOptions = new FormArray([new FormControl({
    title: "Ciudad de Destino"
  })]);
  hourList: Array<{ value: number, title: string }> = [];
  minuteList: Array<{ value: number, title: string }> = [];
  companyLoadControls = new FormArray([
    new FormControl('', Validators.required)
  ]);
  thirdPartyLoadAddressControls = new FormArray([
    new FormControl('', Validators.required)
  ]);
  companyLoadOptions = new FormArray([
    new FormControl({ title: "Remitente" })
  ]);
  companyDownloadControls = new FormArray([
    new FormArray([new FormControl('', Validators.required)])
  ]);
  thirdPartyAddressControls = new FormArray([
    new FormArray([new FormControl('', Validators.required)])
  ]);
  companyDownloadOptions = new FormArray([
    new FormArray([new FormControl({ title: "Destinatario" })])
  ]);

  dateLoad = new FormControl('', Validators.required);
  reteicaPercentage = new FormControl('', (control: AbstractControl): ValidationErrors | null => {
    if (!this.manualCreationCargoService.permissionToSkipReteica) {
      return Validators.required(control);
    }
    return null;
  });
  currentDateLoad = "";
  dateDownload = new FormArray([
    new FormControl('', Validators.required)
  ])
  dataVehicle;
  fromToday = (d: Date | null): boolean => {
    if (!d) return false;
    const currentDate: Date = new Date();
    currentDate.setHours(0, 0, 0, 0);
    d.setHours(0, 0, 0, 0);
    return d >= currentDate;
  };
  onUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
  subscriptions: Subscription = new Subscription();

  constructor(
    public cargoResources: CargoResources,
    public formBuilder: FormBuilder,
    public utils: Utils,
    private manualCreationCargoService: ManualCreationCargoService,
    private cargoDetail: CargoDetailService,
    private spinner: NgxSpinnerService,
    private patterns: Patterns,
    private snackBarService: SnackBarService,
    private googleService: GoogleService,
    private cargoManager: CargoManager,
    private cdRef: ChangeDetectorRef,
  ) {
    super(
      formBuilder,
      utils.clone(cargoResources.cargoMock.cargoFeature.uploadDownload),
      utils.clone(cargoResources.cargoMock.cargoFeature.uploadDownload)
    );
    this.hourList = this.utils.getListHour();
    this.timeList = this.utils.getListTime();
    this.minuteList = this.utils.getListMinute();
  }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {

    if (this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.origin.addresses')) {
      this.cargo.cargoFeature.uploadDownload.origin.addresses.forEach((address, index) => {
        if (index !== 0) {
          this.addAddressForm(index);
        }
      })
    }
    if (this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.destination')) {
      this.cargo.cargoFeature.uploadDownload.destination.forEach((city, index) => {
        if (index !== 0)
          this.addCityForm(index);
        if (city.addresses) {
          city.addresses.forEach((address, indexadd) => {
            if (indexadd !== 0)
              this.addAddressFormDestination(index, indexadd);
            if (address.guidesRecipients && address.guidesRecipients.length)
              address.guidesRecipients.forEach(() => this.addGuidesRecipients(index, indexadd));
          })
        }

      })
    }
    this.setValues();
  }

  setValues() {
    const companyLoadSub = this.companyLoadControls.at(0).valueChanges.subscribe(value => {
      const currentAddress = (this.form.get('origin.addresses') as FormArray).at(0);
      currentAddress.get('thirdPartyConsignment.documentNumber').setValue(value && value.document ? value.document : null);
      currentAddress.get('thirdPartyConsignment.name').setValue(value && value.name ? value.name : null);
    });
    this.subscriptions.add(companyLoadSub);
    const addressLoadSub = this.thirdPartyLoadAddressControls.at(0).valueChanges.subscribe((value: ThirdPartyAddress) => {
      const currentAddress = (this.form.get('origin.addresses') as FormArray).at(0);
      currentAddress.get('address').setValue(value && value.address && value.address.address ? value.address.address : '');
      currentAddress.get('location').patchValue(value && value.address && value.address.location ? value.address.location : { lat: null, lng: null });
      currentAddress.get('thirdPartyConsignment.branchOffice').setValue(value && value.address && value.address.id ? value.address.id : null);
      currentAddress.get('thirdPartyConsignment.documentType').setValue(
        value && value.information && value.information.documentTypeId ? value.information.documentTypeId : null
      );
    });
    this.subscriptions.add(addressLoadSub);
    const companyDownloadSub = (this.companyDownloadControls.at(0) as FormArray).at(0).valueChanges.subscribe(value => {
      const currentAddress = (this.formDestination.at(0).get('addresses') as FormArray).at(0);
      currentAddress.get('thirdPartyConsignment.documentNumber').setValue(value && value.document ? value.document : null);
      currentAddress.get('thirdPartyConsignment.name').setValue(value && value.name ? value.name : null);
    });
    this.subscriptions.add(companyDownloadSub);
    const addressDownloadSub = (this.thirdPartyAddressControls.at(0) as FormArray).at(0).valueChanges.subscribe((value: ThirdPartyAddress) => {
      const currentAddress = this.formDestination.controls[0].get('addresses')['controls'][0];
      currentAddress.get('address').setValue(value && value.address && value.address.address ? value.address.address : '');
      currentAddress.get('location').patchValue(value && value.address && value.address.location ? value.address.location : { lat: null, lng: null });
      currentAddress.get('thirdPartyConsignment.branchOffice').setValue(value && value.address && value.address.id ? value.address.id : null);
      currentAddress.get('thirdPartyConsignment.documentType').setValue(
        value && value.information && value.information.documentTypeId ? value.information.documentTypeId : null
      );
    });
    this.subscriptions.add(addressDownloadSub);
    const citySub = this.cityOriginControl.valueChanges.subscribe(city => {
      if (city) {
        this.form.get('origin.name').setValue(city.name ? city.name : '');
        this.form.get('origin.municipalityCode').setValue(city.id ? city.id : '');
        this.form.get('origin.country.name').setValue(city.country ? city.country : 'Colombia');
        this.form.get('origin.country.code').setValue(city.code ? city.code : 'CO');
        const reteica = city.reteica ? city.reteica : city.mainCity && city.mainCity.reteica ? city.mainCity.reteica : 0;
        this.reteicaPercentage.setValue(reteica ? reteica : '');
        this.showErrorReteica = !this.manualCreationCargoService.permissionToSkipReteica && !reteica && this.form.get('origin.country.code').value === 'CO';
      } else {
        this.formOriginAddresses.controls.forEach(address => {
          address.get('address').setValue('');
        });
        this.form.get('origin.name').setValue('');
        this.form.get('origin.municipalityCode').setValue('');
        this.form.get('origin.country.name').setValue('Colombia');
        this.form.get('origin.country.code').setValue('CO');
        this.reteicaPercentage.setValue('');
        this.showErrorReteica = false;
      }

    });
    this.subscriptions.add(citySub);
    const cityDestinationSub = this.cityDestinationControls.at(0).valueChanges.subscribe(city => {
      if (city) {
        this.formDestination.controls[0].get('name').setValue(city.name ? city.name : "");
        this.formDestination.controls[0].get('municipalityCode').setValue(city.id ? city.id : "");
        this.formDestination.controls[0].get('country.name').setValue(city.country ? city.country : 'Colombia');
        this.formDestination.controls[0].get('country.code').setValue(city.code ? city.code : 'CO');
      } else {
        (this.formDestination.controls[0].get('addresses') as FormArray).controls.forEach(address => {
          address.get('address').setValue('');
        })
        this.formDestination.controls[0].get('name').setValue("");
        this.formDestination.controls[0].get('municipalityCode').setValue("");
        this.formDestination.controls[0].get('country.name').setValue('Colombia');
        this.formDestination.controls[0].get('country.code').setValue('CO');
      }
    });
    this.subscriptions.add(cityDestinationSub);
    this.form.patchValue(this.cargo.cargoFeature.uploadDownload);
    this.currentDateLoad = this.cargo.dateLoad;
    this.reteicaPercentage.setValue(this.cargo && this.cargo.shippingCost && this.cargo.shippingCost.reteicaPercentage ? this.cargo.shippingCost.reteicaPercentage : '');
    this.dateLoad.setValue(DateManager.stringToDate(this.currentDateLoad, 'YYYY-MM-DD HH:mm'));
    this.setTime();
    this.setInternationalValues();
    this.setThirdParties();
    this.getVehicleType();
    this.form.updateValueAndValidity();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  validateByForm(changes) {
    if (changes && (changes.ministry || changes.ministry === false)) {
      this.showAlertChanges = "Se cambió el requerimiento de manifiesto, por favor diligencie nuevamente los destinos";
    } else if (!['Importación', 'Exportación'].includes(changes.tripType)) {
      this.showAlertChanges = `El tipo de viaje se ha modificado a ${changes.tripType}. Te recordamos que para este tipo de viaje
      es necesario que rellenes nuevamente los campos de destino, asegurándote de que tanto el punto de origen como el destino sean
       ${['Urbana', 'Última milla'].includes(changes.tripType) ? 'los mismos' : 'distintos'}.`;
    } else {
      this.showAlertChanges = '';
    }
    const ministry = changes && (changes.ministry || changes.ministry === false) ? changes.ministry : this.cargo.ministry
    const tripType = changes && changes.tripType ? changes.tripType : this.cargo.cargoModel.tripType.name;
    //Vacía la información de ThirdParties si es ministry es false
    if (changes && (changes.ministry || changes.ministry === false)) {
      if (!ministry) {
        this.form.get('origin').get('addresses').controls.forEach(address => {
          address.get('address').setValue('');
          address.get('thirdPartyConsignment.documentType').setValue('');
          address.get('thirdPartyConsignment.documentNumber').setValue('');
          address.get('thirdPartyConsignment.branchOffice').setValue('');
          address.get('thirdPartyConsignment.name').setValue('');
        })
        this.form.get('destination').controls.forEach(cityDestination => {
          cityDestination.get('addresses').controls.forEach(address => {
            address.get('address').setValue('');
            address.get('thirdPartyConsignment.documentType').setValue('');
            address.get('thirdPartyConsignment.documentNumber').setValue('');
            address.get('thirdPartyConsignment.branchOffice').setValue('');
            address.get('thirdPartyConsignment.name').setValue('');
          })
        })
      } else {
        this.form.get('origin').get('addresses').controls.forEach(address => {
          address.get('address').setValue('');
        })
        this.form.get('destination').controls.forEach(cityDestination => {
          cityDestination.get('addresses').controls.forEach(address => {
            address.get('address').setValue('');
          })
        })
      }
    }

    //Si no es internacional quita vacía todas las ciudades internacionales y las deja como nacionales.
    if (tripType !== "Internacional") {
      if (this.form.get('origin.country').value.code !== "CO") {
        this.form.get('origin.name').setValue("");
        this.form.get('origin.country').patchValue({ name: "Colombia", code: "CO" });
      }
      this.form.get('origin.isInternational').setValue(false);
      this.cityOriginOptions = { title: "Ciudad de Origen", isInternational: false, requireFullCity: true };
      this.form.get('destination').controls.forEach((destination, index) => {
        if (destination.get('country').value.code !== "CO") {
          destination.get('name').setValue("");
          destination.get('country').patchValue({ name: "Colombia", code: "CO" });
        }
        destination.get('isInternational').setValue(false);
        this.cityDestinationOptions.at(index).setValue({ title: "Ciudad de Destino", isInternational: false });
      });
    }
    //Si es internacional setea el campo de "isInternational" dependiendo si el countrycode es "CO" o no
    this.setInternationalValues(tripType);
    //Si es nacional, se vacían todas las ciudades y direcciones de destino cuya ciudad sea igual a la de origen
    if (["Nacional", "Exportación", "Importación", "Internacional"].includes(tripType)) {
      if (this.form.get('destination').controls.every(destination => destination.get('name').value === this.form.get('origin.name').value)) {
        this.form.get('destination').controls.forEach(destination => {
          destination.get('name').setValue("");
          destination.get('municipalityCode').setValue("");
          destination.get('addresses').controls.forEach(address => { address.get('address').setValue(""); })
        });
      }
    }
    //Si es urbana o última milla, se vacían todas las ciudades y direcciones con ciudad distinta a la de origen
    else if (["Urbana", "Última milla"].includes(tripType)) {
      this.form.get('destination').controls.forEach(destination => {
        if (destination.get('name').value !== this.form.get('origin.name').value) {
          destination.get('name').setValue("");
          destination.get('municipalityCode').setValue("");
          destination.get('addresses').controls.forEach(address => {
            address.get('address').setValue("");
          })
        }
      });
    }
    this.form.updateValueAndValidity();
    this.sendRequest(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.cargo) {
      this.setValues();
      this.setValidatorsOriginDestination();
    }
    changes && changes.cleanCargo && changes.cleanCargo.currentValue !== undefined && this.validateByForm(changes.cleanCargo.currentValue);
  }

  setTime() {
    this.form.get('origin').get('addresses').controls.map(address => {
      address.patchValue(this.timeFormat(address.get('time').value));
    })
    this.form.get('destination').controls.map((cityDestination, index) => {
      if (cityDestination.get('downloadDate').value)
        this.dateDownload.at(index).setValue(DateManager.stringToDate(cityDestination.get('downloadDate').value, 'YYYY-MM-DD HH:mm ZZ'));

      cityDestination.get('addresses').controls.map(address => {
        address.patchValue(this.timeFormat(address.get('time').value));
      })
    })
  }
  setInternationalValues(actualTripType?: string) {
    const tripType = actualTripType ? actualTripType : this.cargo.cargoModel.tripType.name;
    if (tripType === "Internacional") {
      let isInternationalOrigin = (this.form.get('origin').value.country && this.form.get('origin').value.country.code !== "CO");
      this.form.get('origin.isInternational').setValue(isInternationalOrigin);
      this.cityOriginOptions = { title: "Ciudad de Origen", isInternational: isInternationalOrigin, requireFullCity: true };

      this.form.get('destination').controls.forEach((destination, index) => {
        let isInternationalDestination = !!(destination.value.country && destination.value.country.code !== "CO");
        destination.get('isInternational').setValue(isInternationalDestination);
        this.cityDestinationOptions.at(index).setValue({ title: "Ciudad de Destino", isInternational: isInternationalDestination });
      });
    }
  }
  setThirdParties() {
    if (this.form.get('origin.name').value) {
      this.cityOriginOptions['initialValue'] = this.form.get('origin.name').value;
      this.cityOriginOptions = { ... this.cityOriginOptions };
    }
    this.form.get('origin').get('addresses').controls.map((address, indexAddress) => {
      if (address.get('thirdPartyConsignment').value && address.get('thirdPartyConsignment').value.documentNumber) {
        this.companyLoadOptions.at(indexAddress).setValue({ title: "Remitente", initialValue: address.get('thirdPartyConsignment').value.documentNumber });
      }
    })
    this.form.get('destination').controls.map((cityDestination, indexDestination) => {
      if (cityDestination.get('name').value) {
        let initialOptions = this.cityDestinationOptions.at(indexDestination).value;
        initialOptions['initialValue'] = cityDestination.get('name').value;
        this.cityDestinationOptions.at(indexDestination).setValue(initialOptions);
      }
      cityDestination.get('addresses').controls.map((address, indexAddress) => {
        if (address.get('thirdPartyConsignment').value && address.get('thirdPartyConsignment').value.documentNumber) {
          (this.companyDownloadOptions.at(indexDestination) as FormArray).at(indexAddress).setValue(
            { title: "Destinatario", initialValue: address.get('thirdPartyConsignment').value.documentNumber }
          );
        }
      })
    })
  }
  timeFormat(time: string) {
    if (time) {
      if (time[0] === '0' && time[1] !== '0')
        return { time: time.slice(1), timeType: 'AM' };
      else if (time[0] === '1' && time[1] === '2')
        return { time: `00:${time.slice(3, 5)}`, timeType: 'PM' };
      else if ((time[0] === '1' && parseInt(time[1]) > 2) || (time[0] === '2' && parseInt(time[1]) < 2))
        return { time: DateFormatPipe.prototype.transform('01-01-1990 ' + time, 'time').slice(1).slice(0, 4), timeType: 'PM' };
      else if (time[0] === '2')
        return { time: DateFormatPipe.prototype.transform('01-01-1990 ' + time, 'time').slice(0, 5), timeType: 'PM' };
      return { time: time, timeType: 'AM' };
    }
    return { time: '1:00', timeType: 'AM' };
  }
  public getVehicleType() {
    this.spinner.show();
    this.manualCreationCargoService.getVehicleType().subscribe(
      (data: Catalog) => {
        this.spinner.hide();
        let vehicleCargo = (data.catalog as VehicleCargo[]).filter((item: VehicleCargo) => {
          if (this.cargo && this.cargo.cargoFeature && this.cargo.cargoFeature.vehicleType
            && this.cargo.cargoFeature.vehicleType.name && this.cargo.cargoFeature.vehicleType.name === item.name) {
            return item;
          }
        });
        if (vehicleCargo.length) {
          this.getWeightVehicule(vehicleCargo[0].id);
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  public getWeightVehicule(idvehicle) {
    this.manualCreationCargoService.getVehicleWeightById(idvehicle).subscribe(response => {
      this.dataVehicle = response;
    })
  }

  public isInvalidForm(): boolean {
    this.setValidatorsOriginDestination();
    this.form.markAllAsTouched();
    this.validate = "touched";
    this.form.get('origin').get('addresses').controls.forEach((address, indexAddress) => {
      address.get('id').setValue(`${indexAddress}`);
    })
    this.form.get('destination').controls.forEach((cityDestination, index) => {
      cityDestination.get('id').setValue(`${index}`);
      cityDestination.get('addresses').controls.forEach((address, indexAddress) => {
        address.get('id').setValue(`${indexAddress}`);
      })
    })
    this.refreshDestinations();
    return !this.isValidOriginDestination()
  }

  onSubmit() {
    if (this.isInvalidForm()) return;
    this.spinner.show();
    this.sendRequest();
  }

  public createBodyToSubmit(): BodyLoadDownloadCargo {
    let newUpdateDownload = this.utils.clone(this.form.value);
    delete newUpdateDownload.origin['reteica'];
    delete newUpdateDownload.origin['isInternational'];
    newUpdateDownload.destination.map(city => {
      delete city.isInternational;
      delete city.reteica;
      city.addresses.map(item => {
        item.time = item.time + ' ' + item.timeType;
        item.time = DateFormatPipe.prototype.transform('01-01-1990 ' + item.time, 'time-24');
        delete item.timeType;
        delete item.guidesId;
        if (item.guidesRecipients && item.guidesRecipients.length) {
          item.numberOfGuides = item.guidesRecipients.length;
          item.createGuide = true;
          item.guidesRecipients.map(guide => {
            if (!guide.name && item.thirdPartyConsignment && item.thirdPartyConsignment.name)
              guide.name = item.thirdPartyConsignment.name;
            if (!guide.phone && item.cellphone)
              guide.phone = item.cellphone;
            if (!guide.email && item.email)
              guide.email = item.email;
          })
        } else {
          item.numberOfGuides = 0;
          item.createGuide = false;
        }
        if (this.cargo.ministry && item.thirdPartyConsignment) {
          delete item.thirdPartyConsignment.documentTypeName;
        } else {
          delete item.thirdPartyConsignment;
        }
      });
    });
    newUpdateDownload.origin.addresses.map(item => {
      item.time = item.time + ' ' + item.timeType;
      item.time = DateFormatPipe.prototype.transform('01-01-1990 ' + item.time, 'time-24');
      delete item.timeType;
      delete item.guidesId;
      if (this.cargo.ministry && item.thirdPartyConsignment) {
        delete item.thirdPartyConsignment.documentTypeName;
      } else {
        delete item.thirdPartyConsignment;
      }
    });

    const data: BodyLoadDownloadCargo = {
      id: this.cargo.id,
      state: this.cargo.state,
      cargoFeature: {
        uploadDownload: {
          origin: newUpdateDownload.origin,
          destination: newUpdateDownload.destination,
          destinationName: newUpdateDownload.destination.map(city => city.name)
        }
      },
      dateLoad: this.currentDateLoad,
      shippingCost: {
        reteicaPercentage: this.reteicaPercentage.value
      }
    }
    return data;
  }

  public async isValidArrivalDatetime(body: BodyLoadDownloadCargo): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await this.googleService
        .getRouteDataCargo(body)
        .then((success: { cargoDistancy; cargoEstimatedTime }) => {
          body['estimatedTime'] = success.cargoEstimatedTime;
          body['distancy'] = success.cargoDistancy;
        })
        .catch((error) => {
          console.error(error);
        });

      try {
        const cargo: Cargo = this.utils.clone(this.cargo);
        cargo.dateLoad = body.dateLoad;
        cargo.cargoFeature.uploadDownload.origin = body.cargoFeature.uploadDownload.origin;
        cargo.cargoFeature.uploadDownload.destination = body.cargoFeature.uploadDownload.destination;
        if (cargo.errorFieldsExcelCargo && cargo.errorFieldsExcelCargo.origin)
          cargo.errorFieldsExcelCargo.origin = null;
        if (cargo.errorFieldsExcelCargo && cargo.errorFieldsExcelCargo.destination)
          cargo.errorFieldsExcelCargo.destination = null;

        this.cargoManager.checkTripTimes(cargo).subscribe(
          (valid) => {
            if (valid && valid.valid) {
              resolve(true);
            } else {
              let message = 'Los tiempos estimados entre las direcciones son imposibles de cumplir.';
              this.snackBarService.openSnackBar(message, undefined, 'alert');
              this.spinner.hide();
              reject(false);
            }
          },
          () => {
            reject(false);
            this.spinner.hide()
          },
          () => this.spinner.hide()
        )
      } catch (err) {
        console.error(err);
        reject(false);
      }
    });
  }

  async sendRequest(cleanAlert = true) {
    this.spinner.show();
    const body = await this.createBodyToSubmit();
    const isValidArrival = await this.isValidArrivalDatetime(body)
      .catch(() => false);
    if (!isValidArrival) {
      this.spinner.hide();
      return;
    }
    this.cargoDetail.completeUpdateRequest(body, this.cargo).subscribe(
      (success) => {
        this.spinner.hide();
        if (cleanAlert) this.showAlertChanges = "";
        this.snackBarService.openSnackBar(CargoMessages.CARGO_UPDATED);
        this.emitToParent.emit(this.cargo.consecutive);
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(CargoMessages.CARGO_UPDATE_ERROR_DEFAULT, undefined, 'error');
      });
  }

  setValidatorsOriginDestination() {
    this.form.get('origin.name').setValidators(Validators.required);
    this.form.get('origin.addresses').controls.forEach(addressOrigin => {
      addressOrigin.get('address').setValidators(Validators.required);
      addressOrigin.get('time').setValidators(Validators.required);
      addressOrigin.get('cellphone').setValidators([Validators.minLength(10), Validators.pattern(this.patterns.ONLY_NUMBERS.source)]);
    })
    this.form.get('destination').controls.forEach(destination => {
      destination.get('name').setValidators(Validators.required);
      destination.get('downloadDate').setValidators(Validators.required);
      destination.get('addresses').controls.forEach(address => {
        address.get('address').setValidators(Validators.required);
        address.get('email').setValidators(Validators.pattern(this.patterns.EMAIL.source));
        address.get('time').setValidators(Validators.required);
        address.get('cellphone').setValidators([Validators.minLength(10), Validators.pattern(this.patterns.ONLY_NUMBERS.source)]);
        address.get('cargoMeasure') && address.get('cargoMeasure.totalWeight') && address.get('cargoMeasure.totalWeight').setValidators([Validators.required, Validators.max(this.dataVehicle.weight)]);
        address.updateValueAndValidity();
      })
      destination.updateValueAndValidity();
    });
    this.form.updateValueAndValidity();
  }
  isValidOriginDestination(): boolean {

    if (this.utils.errorMessagesCustomized(this.form.get('origin.name'), 'ciudad de origen')) return false;
    if (this.reteicaPercentage.invalid && this.form.get('origin.country.code').value === 'CO') {
      this.snackBarService.openSnackBar("La ciudad de origen no tiene asignado un ReteIca por favor asocielo o cambie de ciudad", undefined, 'alert');
      return false;
    }
    for (let i = 0; i < this.form.get('origin.addresses').controls.length; i++) {
      let addressOrigin = this.form.get('origin.addresses').controls[i];
      if (this.utils.errorMessagesCustomized(addressOrigin.get('address'), `dirección ${i + 1} de origen`)) return false;
      if (!addressOrigin.get('address').value) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, `dirección ${i + 1} de origen`), undefined, 'alert');
        return false;
      }
      if (this.utils.errorMessagesCustomized(addressOrigin.get('time'), `hora de la dirección ${i + 1} de origen`)) return false;
      if (!addressOrigin.get('time').value) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, `hora de la dirección ${i + 1} de origen`), undefined, 'alert');
        return false;
      }
      if (this.utils.errorMessagesCustomized(addressOrigin.get('cellphone'), `celular de la dirección ${i + 1} de origen`, 10)) return false;
      if (this.cargo.ministry && !this.form.get('origin.isInternational').value) {
        if (!addressOrigin.get('thirdPartyConsignment.documentType').value || !addressOrigin.get('thirdPartyConsignment.documentNumber').value
          || !addressOrigin.get('thirdPartyConsignment.branchOffice').value || !addressOrigin.get('thirdPartyConsignment.name').value) {
          this.snackBarService.openSnackBar(`No se ha diligenciado el remitente de la dirección ${i + 1} de origen`, undefined, 'alert');
          return false;
        }
        if (!addressOrigin.get('location.lat').value || !addressOrigin.get('location.lng').value) {
          this.snackBarService.openSnackBar(`No se ha diligenciado la posición geográfica de la dirección ${i + 1} de origen`, undefined, 'alert');
          return false;
        }
      }
    }
    let totalWeight = 0;
    for (let indexDestination = 0; indexDestination < this.form.get('destination').controls.length; indexDestination++) {
      let destination = this.form.get('destination').controls[indexDestination];
      if (this.utils.errorMessagesCustomized(destination.get('name'), `ciudad ${indexDestination + 1} de destino`)) return false;
      if (!destination.get('name').value) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, `ciudad ${indexDestination + 1} de destino`), undefined, 'alert');
        return false;
      }
      for (let indexAddress = 0; indexAddress < destination.get('addresses').controls.length; indexAddress++) {
        let address = destination.get('addresses').controls[indexAddress];
        if (this.utils.errorMessagesCustomized(address.get('email'), `correo de la dirección ${indexAddress + 1} de la ciudad ${indexDestination + 1} de destino`)) return false;
        if (this.utils.errorMessagesCustomized(address.get('address'), `dirección ${indexAddress + 1} de la ciudad ${indexDestination + 1} de destino`)) return false;
        if (!address.get('address').value) {
          this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, `dirección ${indexAddress + 1} de la ciudad ${indexDestination + 1} de destino`), undefined, 'alert');
          return false;
        }
        if (address.get('cargoMeasure') && address.get('cargoMeasure.totalWeight') && this.cargo.cargoFeature.productType.name !== "CONTENEDOR VACIO") {
          if (this.utils.errorMessagesCustomized(address.get('cargoMeasure.totalWeight'), `peso de la dirección ${indexAddress + 1} de la ciudad ${indexDestination + 1} de destino`)) return false;
          totalWeight += address.get('cargoMeasure.totalWeight').value;
        }
        if (this.utils.errorMessagesCustomized(address.get('time'), `hora de la dirección ${indexAddress + 1} de la ciudad ${indexDestination + 1} de destino`)) return false;
        if (!address.get('time').value) {
          this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, `hora de la dirección ${indexAddress + 1} de la ciudad ${indexDestination + 1} de destino`), undefined, 'alert');
          return false;
        }
        if (this.utils.errorMessagesCustomized(address.get('cellphone'), `celular de la dirección ${indexAddress + 1} de la ciudad ${indexDestination + 1} de destino`, 10)) return false;
        if (this.cargo.ministry && !destination.get('isInternational').value) {
          if (!address.get('thirdPartyConsignment.documentType').value || !address.get('thirdPartyConsignment.documentNumber').value
            || !address.get('thirdPartyConsignment.branchOffice').value || !address.get('thirdPartyConsignment.name').value) {
            this.snackBarService.openSnackBar(`No se ha diligenciado el remitente de la dirección ${indexAddress + 1} de la ciudad ${indexDestination + 1} de destino`, undefined, 'alert');
            return false;
          }

          if (!address.get('location.lat').value || !address.get('location.lng').value) {
            this.snackBarService.openSnackBar(`No se ha diligenciado la posición geográfica de la dirección ${indexAddress + 1} de la ciudad ${indexDestination + 1} de destino`, undefined, 'alert');
            return false;
          }
        }
      }
    }
    if (this.dataVehicle && this.dataVehicle.weight && totalWeight >= this.dataVehicle.weight) {
      this.snackBarService.openSnackBar('La suma de los pesos de las remesas excede la capacidad del vehículo (' + this.dataVehicle.weight + ')', undefined, 'alert');
      return false;
    }
    //Validaciones por viajes internacionales
    let tripType = this.cargo.cargoModel.tripType.name
    let destinations = this.form.value.destination;
    let foreignOrigin = this.form.value.origin.country.code !== "CO";
    let someForeignDestinations = this.form.value.destination.some(destination => destination.country.code !== "CO");
    let everyForeignDestinations = this.form.value.destination.every(destination => destination.country.code !== "CO");
    if (tripType === "Internacional" && !foreignOrigin && !someForeignDestinations) {
      this.snackBarService.openSnackBar('Un viaje de tipo internacional debe tener al menos una dirección internacional', undefined, 'alert');
      return false;
    }
    if (this.cargo.ministry && !foreignOrigin && destinations[0].country.code !== "CO") {
      this.snackBarService.openSnackBar('El primer destino debe ser la frontera en Colombia con el país de destino', undefined, 'alert');
      return false;
    }
    if (this.cargo.ministry && foreignOrigin && !everyForeignDestinations) {
      if (destinations.length == 1 || (destinations[destinations.length - 1].country.code === "CO" && destinations[destinations.length - 2].country.code !== "CO")) {
        this.snackBarService.openSnackBar('La penúltima ciudad de destino debe ser la frontera en Colombia', undefined, 'alert');
        return false;
      }
    }
    if (this.cargo.ministry && !everyForeignDestinations) {
      let timePasses = 0;
      let noPasses = 0;
      let index = 0;
      while (index !== destinations.length) {
        if (destinations[index].country.code === "CO") {
          if (noPasses === 0) timePasses += 1;
          index == 0 && !foreignOrigin ? noPasses += 2 : noPasses += 1;
        } else {
          if (noPasses === 1) {
            this.snackBarService.openSnackBar('Deben haber por lo menos dos destinos en el paso por Colombia (fronteras en Colombia)', undefined, 'alert');
            return false;
          } else noPasses = 0;
        }
        index += 1;
      }
      if (timePasses > 1) {
        this.snackBarService.openSnackBar('No es posible cruzar por Colombia más de una vez por viaje', undefined, 'alert');
        return false;
      }
    }
    //Validaciones por tipo de viaje
    if (["Nacional", "Internacional"].includes(tripType) && this.form.get('destination').controls.every(destination =>
      destination.get('name').value === this.form.get('origin.name').value
    )) {
      this.snackBarService.openSnackBar(`El tipo de viaje "${tripType}" debe tener como origen y destino ciudades distintas`, undefined, 'alert');
      return false;
    }
    if (["Urbana", "Última milla"].includes(tripType) && this.form.get('destination').controls.some(destination =>
      destination.get('name').value !== this.form.get('origin.name').value
    )) {
      this.snackBarService.openSnackBar(`El tipo de viaje "${tripType}" debe tener como origen y destino la misma ciudad`, undefined, 'alert');
      return false;
    }
    //Validaciones por fecha
    let previousDate = this.currentDateLoad;
    if (DateManager.isBefore(DateManager.stringToDate(previousDate, 'YYYY-MM-DD HH:mm ZZ'), new Date())) {
      this.snackBarService.openSnackBar(`La fecha de cargue es anterior a hoy`, undefined, 'alert');
      return false;
    }
    let address = this.form.get('origin.addresses').controls[0];
    let date = this.currentDateLoad;
    let time = DateFormatPipe.prototype.transform('01-01-1990 ' + address.get('time').value + ' ' + address.get('timeType').value, 'time-24');
    let currentDate = `${date.substring(0, 10)} ${time} +0000`;
    previousDate = currentDate;
    for (let indexAddress = 1; indexAddress < this.form.get('origin.addresses').controls.length; indexAddress++) {
      let address = this.form.get('origin.addresses').controls[indexAddress];
      let date = this.currentDateLoad;
      let time = DateFormatPipe.prototype.transform('01-01-1990 ' + address.get('time').value + ' ' + address.get('timeType').value, 'time-24');
      let currentDate = `${date.substring(0, 10)} ${time} +0000`;
      if (DateManager.isSameOrBefore(DateManager.stringToDate(currentDate, 'YYYY-MM-DD HH:mm ZZ'), DateManager.stringToDate(previousDate, 'YYYY-MM-DD HH:mm ZZ'), 'minutes')) {
        this.snackBarService.openSnackBar(
          `La fecha de cargue de la dirección ${indexAddress + 1} de origen es anterior o igual a la fecha de cargue de la dirección anterior`,
          undefined, 'alert');
        return false;
      }
      previousDate = currentDate;
    }
    for (let indexDestination = 0; indexDestination < this.form.get('destination').controls.length; indexDestination++) {
      let destination = this.form.get('destination').controls[indexDestination];
      if (!destination.get('downloadDate').value) {
        this.snackBarService.openSnackBar(`No se ha diligenciado la fecha de descargue de la ciudad ${indexDestination + 1} de destino`, undefined, 'alert');
        return false;
      }
      else if (DateManager.isBefore(DateManager.stringToDate(destination.get('downloadDate').value, 'YYYY-MM-DD HH:mm ZZ'), new Date())) {
        this.snackBarService.openSnackBar(`La fecha de descargue de la ciudad ${indexDestination + 1} de destino es anterior a hoy`, undefined, 'alert');
        return false;
      }
      else if (DateManager.isBefore(DateManager.stringToDate(destination.get('downloadDate').value, 'YYYY-MM-DD HH:mm ZZ'), DateManager.stringToDate(previousDate, 'YYYY-MM-DD HH:mm ZZ'), 'days')) {
        this.snackBarService.openSnackBar(
          `La fecha de descargue de la ciudad ${indexDestination + 1} de destino es anterior a ${indexDestination === 0 ? 'la fecha de cargue' : 'la fecha del destino anterior'}`,
          undefined, 'alert');
        return false;
      }
      for (let indexAddress = 0; indexAddress < destination.get('addresses').controls.length; indexAddress++) {
        let address = destination.get('addresses').controls[indexAddress];
        let date = destination.get('downloadDate').value;
        let time = DateFormatPipe.prototype.transform('01-01-1990 ' + address.get('time').value + ' ' + address.get('timeType').value, 'time-24');
        let currentDate = `${date.substring(0, 10)} ${time} +0000`;
        if (DateManager.isBefore(DateManager.stringToDate(currentDate, 'YYYY-MM-DD HH:mm ZZ'), DateManager.stringToDate(previousDate, 'YYYY-MM-DD HH:mm ZZ'), 'days')) {
          this.snackBarService.openSnackBar(
            `La fecha de descargue de la dirección ${indexAddress + 1} de la ciudad ${indexDestination + 1} de destino es anterior o igual a la fecha de la dirección anterior`,
            undefined, 'alert');
          return false;
        }
        previousDate = currentDate;
      }
    }
    return true;
  }

  refreshDestinations() {
    this.formDestination.controls[0].get('addresses')['controls'][0].setValue(
      this.formDestination.controls[0].get('addresses')['controls'][0].value
    )
  }

  onSelectCityOrigin() {
    this.formOriginAddresses.controls.forEach(address => {
      address.get('address').setValue('');
    });
  }

  onSelectCityDestination($event, index: number) {
    (this.formDestination.controls[index].get('addresses') as FormArray).controls.forEach(address => {
      address.get('address').setValue('');
    })
    this.formDestination.controls[index].patchValue($event.data);
    this.formDestination.controls[index].get('municipalityCode').setValue($event.data.id ? $event.data.id : "");
    this.formDestination.controls[index].get('id').setValue(index.toString());
  }
  onSelectAddressDestination($event, indexDestination, indexAddress) {
    if ($event === "") (this.formDestination.controls[indexDestination].get('addresses') as FormArray).controls[indexAddress].get('address').setValue('');
    else (this.formDestination.controls[indexDestination].get('addresses') as FormArray).controls[indexAddress].patchValue($event);
  }
  onSelectAddress($event, indexAddress) {
    if ($event === "") this.formOriginAddresses.controls[indexAddress].get('address').setValue('');
    else this.formOriginAddresses.controls[indexAddress].patchValue($event);
  }
  onChangeInternational(type: string, indexDestination?: number) {
    if (type === "origin") {
      this.formOrigin.get('name').setValue('');
      this.form.get('origin.addresses').controls.forEach(addressOrigin => {
        addressOrigin.get('address').setValue('');
      });
      this.cityOriginOptions = { title: "Ciudad de Origen", isInternational: this.formOrigin.get('isInternational').value, requireFullCity: true };
    } else {
      this.formDestination['controls'][indexDestination].get('name').setValue('');
      this.formDestination['controls'][indexDestination].get('addresses')['controls'].forEach(address => {
        address.get('address').setValue('');
      });
      this.cityDestinationOptions.at(indexDestination).setValue({
        title: "Ciudad de Destino", isInternational: this.formDestination['controls'][indexDestination].get('isInternational').value
      })
    }
  }
  onChangeDate($event, type: string, indexDestination?: number) {
    if (type === "load") {
      this.currentDateLoad = DateManager.dateToString($event.value, 'YYYY-MM-DD HH:mm ZZ');
    } else {
      this.formDestination['controls'][indexDestination].get('downloadDate').setValue(DateManager.dateToString($event.value, 'YYYY-MM-DD HH:mm ZZ'));
    }
  }

  addAddressForm(index?: number) {
    this.companyLoadControls.push(new FormControl('', Validators.required));
    this.thirdPartyLoadAddressControls.push(new FormControl('', Validators.required));
    this.companyLoadOptions.push(new FormControl({ title: "Remitente" }));
    this.formOriginAddresses.push(this.createOriginAddress());

    const currentIndex = index ? index : this.companyLoadControls.length - 1;
    const companySub = this.companyLoadControls.at(currentIndex).valueChanges.subscribe(value => {
      const currentAddress = (this.form.get('origin.addresses') as FormArray).at(currentIndex);
      currentAddress.get('thirdPartyConsignment.documentNumber').setValue(value && value.document ? value.document : null);
      currentAddress.get('thirdPartyConsignment.name').setValue(value && value.name ? value.name : null);
    });
    this.subscriptions.add(companySub);
    const addressLoadSub = this.thirdPartyLoadAddressControls.at(currentIndex).valueChanges.subscribe((value: ThirdPartyAddress) => {
      const currentAddress = (this.form.get('origin.addresses') as FormArray).at(currentIndex);
      currentAddress.get('address').setValue(value && value.address && value.address.address ? value.address.address : '');
      currentAddress.get('location').patchValue(value && value.address && value.address.location ? value.address.location : { lat: null, lng: null });
      currentAddress.get('thirdPartyConsignment.branchOffice').setValue(value && value.address && value.address.id ? value.address.id : null);
      currentAddress.get('thirdPartyConsignment.documentType').setValue(
        value && value.information && value.information.documentTypeId ? value.information.documentTypeId : null
      );
    });
    this.subscriptions.add(addressLoadSub);
  }

  addCityForm(index?: number) {
    this.companyDownloadControls.push(new FormArray([new FormControl('', Validators.required)]));
    this.thirdPartyAddressControls.push(new FormArray([new FormControl('', Validators.required)]));
    this.companyDownloadOptions.push(new FormArray([new FormControl({ title: "Destinatario" })]));

    this.cityDestinationControls.push(new FormControl('', Validators.required));
    this.cityDestinationOptions.push(new FormControl({ title: "Ciudad de Destino" }));

    this.dateDownload.push(new FormControl('', Validators.required));
    this.formDestination.push(this.createDestinationCity());

    const currentIndex = index ? index : this.companyDownloadControls.length - 1;
    const companyDownloadSub = (this.companyDownloadControls.at(currentIndex) as FormArray).at(0).valueChanges.subscribe(value => {
      const currentAddress = (this.formDestination.at(currentIndex).get('addresses') as FormArray).at(0);
      currentAddress.get('thirdPartyConsignment.documentNumber').setValue(value && value.document ? value.document : null);
      currentAddress.get('thirdPartyConsignment.name').setValue(value && value.name ? value.name : null);
    });
    this.subscriptions.add(companyDownloadSub);
    const addressDownloadSub = (this.thirdPartyAddressControls.at(currentIndex) as FormArray).at(0).valueChanges.subscribe((value: ThirdPartyAddress) => {
      const currentAddress = this.formDestination.controls[currentIndex].get('addresses')['controls'][0];
      currentAddress.get('address').setValue(value && value.address && value.address.address ? value.address.address : '');
      currentAddress.get('location').patchValue(value && value.address && value.address.location ? value.address.location : { lat: null, lng: null });
      currentAddress.get('thirdPartyConsignment.branchOffice').setValue(value && value.address && value.address.id ? value.address.id : null);
      currentAddress.get('thirdPartyConsignment.documentType').setValue(
        value && value.information && value.information.documentTypeId ? value.information.documentTypeId : null
      );
    });
    this.subscriptions.add(addressDownloadSub);

    const cityDestinationSub = this.cityDestinationControls.at(currentIndex).valueChanges.subscribe(city => {
      if (city) {
        this.formDestination.controls[currentIndex].get('name').setValue(city.name ? city.name : "");
        this.formDestination.controls[currentIndex].get('municipalityCode').setValue(city.id ? city.id : "");
        if (this.formDestination.controls[currentIndex].get('country.name'))
          this.formDestination.controls[currentIndex].get('country.name').setValue(city.country ? city.country : 'Colombia');
        if (this.formDestination.controls[currentIndex].get('country.code'))
          this.formDestination.controls[currentIndex].get('country.code').setValue(city.code ? city.code : 'CO');
      } else {
        (this.formDestination.controls[currentIndex].get('addresses') as FormArray).controls.forEach(address => {
          address.get('address').setValue('');
        })
        this.formDestination.controls[currentIndex].get('name').setValue("");
        this.formDestination.controls[currentIndex].get('municipalityCode').setValue("");
        try {
          this.formDestination.controls[currentIndex].get('country.name').setValue('Colombia');
          this.formDestination.controls[currentIndex].get('country.code').setValue('CO');
        } catch { /* NO EXISTEN LOS CAMPOS */ }
      }
    });
    this.subscriptions.add(cityDestinationSub);
  }

  addAddressFormDestination(indexDestination: number, index?: number) {
    (this.companyDownloadControls.at(indexDestination) as FormArray).push(new FormControl('', Validators.required));
    (this.thirdPartyAddressControls.at(indexDestination) as FormArray).push(new FormControl('', Validators.required));
    (this.companyDownloadOptions.at(indexDestination) as FormArray).push(new FormControl({ title: "Destinatario" }));
    this.formDestination['controls'][indexDestination].get('addresses')['controls'].push(this.createDestinationAdress());
    const currentIndex = index ? index : (this.companyDownloadControls.at(indexDestination) as FormArray).controls.length - 1;
    const companyDownloadSub = (this.companyDownloadControls.at(indexDestination) as FormArray).at(currentIndex).valueChanges.subscribe(value => {
      const currentAddress = (this.formDestination.at(indexDestination).get('addresses') as FormArray).at(currentIndex);
      currentAddress.get('thirdPartyConsignment.documentNumber').setValue(value && value.document ? value.document : null);
      currentAddress.get('thirdPartyConsignment.name').setValue(value && value.name ? value.name : null);
    });
    this.subscriptions.add(companyDownloadSub);
    const addressDownloadIndex = index ? index : (this.thirdPartyAddressControls.at(indexDestination) as FormArray).controls.length - 1;
    const addressDownloadSub = (this.thirdPartyAddressControls.at(indexDestination) as FormArray).at(addressDownloadIndex).valueChanges.subscribe((value: ThirdPartyAddress) => {
      const currentAddress = this.formDestination.controls[indexDestination].get('addresses')['controls'][addressDownloadIndex];
      currentAddress.get('address').setValue(value && value.address && value.address.address ? value.address.address : '');
      currentAddress.get('location').patchValue(value && value.address && value.address.location ? value.address.location : { lat: null, lng: null });
      currentAddress.get('thirdPartyConsignment.branchOffice').setValue(value && value.address && value.address.id ? value.address.id : null);
      currentAddress.get('thirdPartyConsignment.documentType').setValue(
        value && value.information && value.information.documentTypeId ? value.information.documentTypeId : null
      );
    });
    this.subscriptions.add(addressDownloadSub);
  }

  addGuidesRecipients(indexDestination: number, indexAddress: number) {
    try {
      this.getDestinationAddressGuidesRecipients(indexDestination, indexAddress).push(
        this.formBuilder.group({
          name: '',
          phone: '',
          email: '',
        }));
    } catch (error) {
      this.snackBarService.openSnackBar('No fue posible agregar la guía', undefined, 'alert');
    }
  }

  removeGuidesRecipients(indexDestination: number, indexAddress: number, indexRecipient: number) {
    try {
      this.getDestinationAddressGuidesRecipients(indexDestination, indexAddress).removeAt(indexRecipient);
    } catch (error) {
      this.snackBarService.openSnackBar('No fue posible eliminar la guía', undefined, 'alert');
    }
  }

  getFormDestination(indexDestination): FormGroup {
    return this.formDestination.controls[indexDestination] as FormGroup;
  }


  public get formOrigin(): FormGroup {
    return this.form.get('origin') as FormGroup;
  }

  public get formOriginAddresses(): FormArray {
    return this.formOrigin.get('addresses') as FormArray;
  }

  public get formDestination(): FormArray {
    return this.form.get('destination') as FormArray;
  }


  createOriginAddress(): FormGroup {
    return this.formBuilder.group({
      id: '',
      address: '',
      location: this.formBuilder.group({
        lat: 0,
        lng: 0
      }),
      time: '',
      cellphone: '',
      timePact: 0,
      minutePact: 0,
      quantity: 0,
      thirdPartyConsignment: this.formBuilder.group({
        documentType: '',
        documentNumber: '',
        branchOffice: '',
        name: '',
        documentTypeName: ''
      }),
      timeType: 'AM',
    });
  }

  createDestinationCity(): FormGroup {
    return this.formBuilder.group(
      {
        id: "",
        name: "",
        isInternational: false,
        country: {
          name: "Colombia",
          code: "CO",
        },
        municipalityCode: "",
        downloadDate: "",
        reteica: 0,
        addresses: this.formBuilder.array([
          this.formBuilder.group({
            id: '',
            guidesId: [],
            numberOfGuides: 0,
            createGuide: false,
            guidesRecipients: this.formBuilder.array([]),
            address: '',
            location: this.formBuilder.group({
              lat: 0,
              lng: 0
            }),
            time: '',
            timePact: 0,
            minutePact: 0,
            cellphone: '',
            email: '',
            quantity: 0,
            cargoMeasure: this.formBuilder.group({
              quantity: 1,
              totalMeasurement: 0,
              unit: '1',
              totalWeigth: 0,
              amount: 0,
              type: 'Apilable',
            }),
            timeType: 'AM',
            thirdPartyConsignment: this.formBuilder.group({
              documentType: '',
              documentNumber: '',
              branchOffice: '',
              name: '',
              documentTypeName: ''
            }),
          })
        ])
      }
    );
  }
  createDestinationAdress(): FormGroup {
    return this.formBuilder.group(
      {
        id: '',
        guidesId: [],
        numberOfGuides: 0,
        createGuide: false,
        guidesRecipients: this.formBuilder.array([]),
        address: '',
        location: this.formBuilder.group({
          lat: 0,
          lng: 0
        }),
        time: '',
        timePact: 0,
        minutePact: 0,
        cellphone: '',
        email: '',
        quantity: 0,
        cargoMeasure: this.formBuilder.group({
          quantity: 1,
          totalMeasurement: 0,
          unit: '1',
          totalWeigth: 0,
          amount: 0,
          type: 'Apilable',
        }),
        timeType: 'AM',
        thirdPartyConsignment: this.formBuilder.group({
          documentType: '',
          documentNumber: '',
          branchOffice: '',
          name: '',
          documentTypeName: ''
        }),
      }

    )
  }

  getCityDestinationAddresses(indexDestination: number): FormArray {
    return this.formDestination.controls[indexDestination].get('addresses') as FormArray;
  }

  getDestinationAddress(indexDestination: number, indexAddress: number): FormGroup {
    return this.getCityDestinationAddresses(indexDestination).controls[indexAddress] as FormGroup;
  }

  getDestinationAddressGuidesRecipients(indexDestination: number, indexAddress: number): FormArray {
    return this.getDestinationAddress(indexDestination, indexAddress).get('guidesRecipients') as FormArray;
  }

  getItemCompanyDownloadOptions(iDestination: number, idAddress: number) {
    return (this.companyDownloadOptions.at(iDestination) as FormArray).at(idAddress).value;
  }

  getItemCompanyDownloadControls(iDestination: number, idAddress: number): FormControl {
    return (this.companyDownloadControls.at(iDestination) as FormArray).at(idAddress) as FormControl;
  }

  getItemThirdPartyAddress(iDestination: number, idAddress: number): FormControl {
    return (this.thirdPartyAddressControls.at(iDestination) as FormArray).at(idAddress) as FormControl;
  }

  get isEscortedService(): boolean {
    const serviceType: ServiceType = this.utils.getNestedValue(this.cargo, 'cargoModel.serviceType');
    return serviceType && serviceType.id === 'escortServices';
  }

  ngOnDestroy() {
    if (this.subscriptions) this.subscriptions.unsubscribe();
    this.cdRef.detach();
  }
}

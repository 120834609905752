<div id="container" class="col-md-12S md-md-0 md-10 h-100-margin">
    <section class="container-list h-100-margin">
        <header class="container-list-header">
            <div class="row">
                <div class="col-md-12">
                    <div class="opcion-volver" (click)="goToBack()">
                        <i class="fas fa-angle-left"></i>
                        Volver
                    </div>
                    <div class="d-flex flex-wrap justify-content-between">
                        <label class="title-component" *ngIf="cityForm">
                            <i class="fas fa-city"></i>
                            {{isCreate ? 'Creación de Ciudad': 'Detalle de Ciudad'}}
                        </label>
                        <div permission-display [module]="permission.administration.module"
                            [functionality]="permission.administration.linkCities">
                            <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
                                (click)="editRteica()" *ngIf="!isMainCity">
                                {{mainCityName.value?'Asociar otra ciudad principal':'Asociar ciudad principal'}}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </header>
        <article id="content" class="h-100 mt-2">
            <div permission-display [module]="permission.administration.module"
                [functionality]="permission.administration.updateCity">
                <div class="d-flex justify-content-end" *ngIf="!isSubCity">
                    <div class="button-ica save-edit" (click)="editForm()" *ngIf="cityForm.disabled">
                        <b> Editar </b>
                    </div>
                    <div class="button-ica save-edit" (click)="cancelEditForm()" *ngIf="cityForm.enabled">
                        <b> Cancelar </b>
                    </div>
                    <div class="button-ica save-edit" (click)="saveCity()" *ngIf="cityForm.enabled">
                        <b> Guardar </b>
                    </div>
                </div>
            </div>
            <form class="form_container row" [formGroup]="cityForm">
                <div class="col-md-6">
                    <mat-form-field appearance="standard">
                        <mat-label>Ciudad<span class="asterisk" *ngIf="ifFieldMandatory('name')">*</span></mat-label>
                        <input matInput type="text" formControlName="name">
                        <mat-error *ngIf="cityForm.get('name').errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="standard">
                        <mat-label>Reteica<span class="asterisk"
                                *ngIf="ifFieldMandatory('reteica')">*</span></mat-label>
                        <input matInput type="text" onlyNumbersDouble formControlName="reteica">
                        <mat-error *ngIf="cityForm.get('reteica').errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--Fields for Siigo-->
                <div class="col-md-4" *ngIf="hasSiigoIntegration" id="taxSiigoStep">
                    <mat-form-field appearance="standard">
                        <mat-label>Código retefuente<span class="asterisk"
                                *ngIf="ifFieldMandatory('taxSiigo')">*</span></mat-label>
                        <mat-select [formControl]="taxSiigoControl" [disabled]="cityForm.disabled"
                            (selectionChange)="onTaxSiigoChange($event)">
                            <mat-option *ngFor="let option of taxOptions" [value]="option" [disabled]="!option.active">
                                {{option.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="taxSiigoControl.errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                        <mat-hint>*Requerido para contabilidad y facturación</mat-hint>
                    </mat-form-field>
                </div>
                <!--Fields for WorldOffice-->
                <div class="col-md-6" *ngIf="hasWorldOfficeIntegration">
                    <mat-form-field appearance="standard">
                        <mat-label>Código zonal<span class="asterisk"
                                *ngIf="ifFieldMandatory('zoneCode')">*</span></mat-label>
                        <input matInput type="text" formControlName="zoneCode">
                        <mat-error *ngIf="cityForm.get('zoneCode').errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Accounting section -->
                <div class="text-strong col-12 mt-0 mt-md-3">
                    <div class="text-strong">Contabilidad</div>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field appearance="standard">
                                <mat-label>Cuenta débito<span class="asterisk"
                                        *ngIf="ifFieldMandatory('debitAccount')">*</span></mat-label>
                                <input matInput type="text" onlyNumbersInt formControlName="debitAccount">
                                <mat-error *ngIf="cityForm.get('debitAccount').errors?.required">
                                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="standard">
                                <mat-label>Cuenta ica (opcional)<span class="asterisk"
                                        *ngIf="ifFieldMandatory('icaAccount')">*</span></mat-label>
                                <input matInput type="text" onlyNumbersDouble formControlName="icaAccount">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6" *ngIf="hasSiigoIntegration">
                            <mat-form-field appearance="standard">
                                <mat-label>Código ica<span class="asterisk"
                                        *ngIf="ifFieldMandatory('icaSiigo')">*</span></mat-label>
                                <mat-select formControlName="icaSiigo" [disabled]="cityForm.disabled">
                                    <mat-option *ngFor="let option of taxOptions" [value]="option.id">
                                        {{option.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="cityForm.get('icaSiigo').errors?.required">
                                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                                </mat-error>
                                <mat-error *ngIf="cityForm.get('icaSiigo').errors?.min">
                                    {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'código ica', minIcaSiigo) }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <!-- Billing section -->
                <div class="text-strong col-12 mt-0 mt-md-3" *ngIf="hasSiigoIntegration" id="productSiigoStep">
                    <div class="text-strong">Facturación</div>
                    <div>
                        <mat-form-field appearance="standard">
                            <mat-label>Producto Siigo<span class="asterisk"
                                    *ngIf="ifFieldMandatory('productSiigo')">*</span></mat-label>
                            <mat-select #mySelect formControlName="productSiigo" [disabled]="cityForm.disabled">
                                <mat-option *ngFor="let option of siigoOptions" [value]="option.code"
                                    (click)="onProductSiigoChange(option.code)">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="cityForm.get('productSiigo').errors?.required">
                                {{ utils.giveMessageError('MANDATORY_FIELD') }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

            </form>
            <mat-divider *ngIf="mainCityName.value && canLinkCities"></mat-divider>
            <form class="form_container row" *ngIf="mainCityName.value && canLinkCities">
                <div class="col-md-6 p-0 mt-2">
                    <mat-form-field appearance="standard">
                        <mat-label>Ciudad principal<span class="asterisk"
                                *ngIf="ifFieldMandatory('mainCityName')">*</span></mat-label>
                        <input matInput type="text" [formControl]="mainCityName">
                    </mat-form-field>
                </div>
            </form>
            <label class="subtitle-component" *ngIf="isMainCity && subcities?.length && canLinkCities">
                <i class="fas fa-tree-city"></i>
                Subciudades asociadas
            </label>
            <div *ngIf="isMainCity && canLinkCities" permission-display [module]="permission.administration.module"
                [functionality]="permission.administration.addSubCities">
                <app-autocomplete-city [inputFormControl]="subCityControl" [options]="optionsSubcity">
                </app-autocomplete-city>
            </div>

            <div class="container-list-subcity" *ngIf="canLinkCities && subcities.length && isMainCity">
                <div class="col-md-12 mb-md-0 mb-12" *ngFor="let subcity of subcities">
                    <app-subcity-item *ngIf="!isCreate" [subcity]="subcity" (delete)="deleteSubcity($event)">
                    </app-subcity-item>
                </div>
            </div>
        </article>
        <footer *ngIf="canLinkCities" id="footer" permission-display [module]="permission.administration.module"
            [functionality]="permission.administration.addSubCities">
            <span class="separator-horizontal"></span>
            <div class="col-md-12">
                <mat-card-actions class="mat-card-actions-vehcle text-center  button-center">
                    <button mat-raised-button color="primary" (click)="onSubmit()" class="button-width-auto"
                        *ngIf="isMainCity">
                        Añadir Subciudades
                    </button>
                </mat-card-actions>
            </div>
            <span class="separator-horizontal"></span>
        </footer>
    </section>
</div>
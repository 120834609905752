import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Catalog } from 'src/app/core/interfaces/catalog';
import { environment } from '../../../../environments/environment';
import { Cargo, RequestUpdateBody } from 'src/app/core/interfaces/cargo';
import { DiscountsAndBonuses } from 'src/app/core/interfaces/discountsAndBonuses';
import { Payments } from 'src/app/core/interfaces/payments';
import { BasicResponse } from 'src/app/core/interfaces/basicResponse';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { CompanyManager } from 'src/app/core/managers/company.manager';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Utils } from 'src/app/core/resources/utils';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { Destination } from 'src/app/core/interfaces/destination';
import { CargoConsignment } from 'src/app/core/interfaces/cargoEditConsignment';
import { ShippingCost } from 'src/app/core/interfaces/shippingCost';
import { AdditionalCostDeleted } from 'src/app/core/interfaces/additionalCostDeleted';
import { ConsignmentCargo } from 'src/app/core/interfaces/consignmentCargo';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CargoDetailService {

  permission = Permission;

  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
    private companyManager: CompanyManager,
    public snackBarService: SnackBarService,
    private permissionRole: PermissionRole,
    private utils: Utils,
  ) { }

  public acceptCargo(params) {

    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.urlAcceptCargo,
      params
    );
  }

  public refuseCargo(params) {

    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.urlRefuseCargo,
      params
    );
  }

  public negotiationCargo(params) {

    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.urlNegotiateCargo,
      params
    );
  }

  public requestCargo(idCargo: string) {
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.urlValidateRequestCargo + idCargo, null
    )
  }

  public addObservation(observation: any, idCargo: string) {

    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.addObservation + idCargo,
      observation
    );
  }

  public negotiationRequest(action: string, params) {

    return this._http.post(
      `${environment.urlServerTeclogi}${this._endpointResources.urlNegotiateRequestSubCompany}${action}`,
      params
    );
  }

  public cancelCargo(params) {

    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.urlCancelCargo,
      params
    );
  }

  public removeCargo(idCargo, data, hasPaymentsAndPermission = false) {
    let url = environment.urlServerTeclogi + this._endpointResources.urlRemoveCargo + idCargo;
    if (hasPaymentsAndPermission) url += '&deleteWithPayment=true'
    return this._http.delete(
      url,
      data
    );
  }


  public disassociate(idsCargo, type) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateCargo + "/" + type,
      {
        'cargoId': idsCargo,
        'type': "Delete"
      }
    );
  }

  public editInvoice(idsCargo, type, value) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateCargo + "/" + type,
      {
        'cargoId': idsCargo,
        'type': "Update",
        "value": value
      }
    );
  }
  public detailCargo(idCargo: string): Observable<Cargo> {
    return this._http.get<Cargo>(
      environment.urlServerTeclogi + this._endpointResources.urlDetailCargo + idCargo
    );
  }

  public detailCargoByConsecutive(consecutive: string) {
    return this._http.get<Cargo>(
      environment.urlServerTeclogi + this._endpointResources.urlDetailCargoByConsecutive + consecutive
    );
  }

  public detailServiceRequestByConsecutive(consecutive: string) {
    //Pendiente definir como manejar params y headers
    const headers = new HttpHeaders().set('companyId', '9002585606');
    let params = new HttpParams();
    params = params.append('cargoHolderId', '9002585606');
    return this._http.get(
      `${environment.urlServerTeclogi}${this._endpointResources.urlCargoServiceRequestsDetail}/${consecutive}`, { headers, params }
    );
  }



  public getGuideStates() {
    return this._http.get<Catalog>(
      environment.urlServerTeclogi + this._endpointResources.urlCatalog + '/GuideState'
    );
  }

  public setGuideStates(guideId: string, state: string, nit: string, date: string) {
    let params = new HttpParams();
    params = params.append('companyId', nit);
    params = params.append('state', state);
    params = params.append('guideId', guideId);
    params = params.append('date', date);
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.setGuideStateFalabella,
      {},
      { params }
    );
  }

  public updateWeightConsignment(data) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateWeightConsignment,
      data
    );
  }

  public updateFreightForwarder(data) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateCargo,
      data
    );
  }

  public updateCargo(data) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateCargo,
      data
    );
  }

  public completeUpdateRequest(data, realCargo: Cargo) {
    const keys = [
      'driver', 'licensePlate', 'observation', 'observationDriver', 'dateLoad', 'shippingCost', 'simpleRegimen', 'manifestError',
      'errorFieldsExcelCargo', 'cargoModel', 'cargoFeature', 'container', 'containerExpirationDate',
      'seal', 'booking', 'numberDocumentSender', 'ministry', 'cargoOwner', 'idCompany', 'estimatedTime', 'distancy'
    ];
    const newCargo: RequestUpdateBody = {
      id: realCargo.id,
      state: realCargo.state,
      dateLoad: realCargo.dateLoad,
      shippingCost: realCargo.shippingCost,
    };

    keys.forEach((key) => {
      if (key in data)
        newCargo[key] = data[key];
      else
        newCargo[key] = this.utils.getNestedValue(realCargo, key);
    });

    if (this.utils.getNestedValue(newCargo, 'cargoFeature.uploadDownload.origin.addresses'))
      newCargo.cargoFeature.uploadDownload.origin.addresses.forEach((address) => {
        if (address['idTracking'])
          delete address['idTracking'];
      });

    if (this.utils.getNestedValue(newCargo, 'cargoFeature.uploadDownload.destination'))
      newCargo.cargoFeature.uploadDownload.destination.forEach((destination) => {
        if (destination && destination.addresses)
          destination.addresses.forEach((address) => {
            if (address['idTracking'])
              delete address['idTracking'];
          });
      });

    return this.updateRequest(newCargo);
  }

  private updateRequest(data: RequestUpdateBody) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateRequest,
      data
    );
  }
  public updateLoadDownload(data, idCargo) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateLoadDownload + idCargo, data
    );
  }

  public getRouteGoogleCargo(idCargo: string) {
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.getRouteGoogleCargo + idCargo
    );
  }

  public saveRouteGoogleCargo(routeGoogle) {
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.saveRouteGoogleCargo,
      routeGoogle
    );
  }

  public getCargoPDF(pdfType: string, cargoId: string) {
    let params = new HttpParams();
    params = params.append('pdfType', pdfType);
    params = params.append('cargoId', cargoId);
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.cargoPDF,
      { params, responseType: 'blob' }
    );
  }

  public getHistoryDiscountsAndBonuses(cargo: Cargo, type: number, category?: 'amount' | 'amountRate'): DiscountsAndBonuses[] {
    if (cargo.shippingCost && cargo.shippingCost.modifications) {
      const history = cargo.shippingCost.modifications.filter((item) => {
        return item.type === type;
      });
      if (category) return history.filter((item) => category === 'amount' ? !!item.amount : !!item.amountRate);
      return history;
    }
    return [];
  }

  public getHistoryDiscountsAndBonusesAdditionalServices(additionalServiceGroupCtrl, type: number): DiscountsAndBonuses[] {
    if (additionalServiceGroupCtrl && additionalServiceGroupCtrl.financialInformation && additionalServiceGroupCtrl.financialInformation.modifications) {
      return additionalServiceGroupCtrl.financialInformation.modifications.filter((item) => {
        return item.type === type;
      });
    }
    return [];
  }

  public getHistoryPaid(cargo: Cargo): Payments[] {
    if (cargo.shippingCost && cargo.shippingCost.payments) {
      return cargo.shippingCost.payments.filter((item) => {
        return item;
      });
    }
    return [];
  }

  public addAdditionalcost(cargoId: string, body): Observable<BasicResponse> {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    return this._http.post<BasicResponse>(
      environment.urlServerTeclogi + this._endpointResources.addAdditionalcost,
      body,
      { params }
    );
  }

  public updateAdditionalcost(cargoId: string, body): Observable<BasicResponse> {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    return this._http.post<BasicResponse>(
      environment.urlServerTeclogi + this._endpointResources.updateAdditionalcost,
      body,
      { params }
    );
  }

  public updatecargoFeature(idCargo, newData) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updatecargoFeature + idCargo,
      newData
    );
  }

  public deletedAdditionalCosts(idCargo) {
    return this._http.get<{ cargoId: string, additionalCostsDeleted: AdditionalCostDeleted[] }>(
      `${environment.urlServerTeclogi}${this._endpointResources.deletedAdditionalCosts}?cargoId=${idCargo}`
    )
  }

  getUtilityCargo(cargo: Cargo) {
    const rate = this.utils.getNestedValue(cargo, 'shippingCost.rate') ? cargo.shippingCost.rate : 1;
    const totalCost = this.utils.getNestedValue(cargo, 'shippingCost.totalCost') ? cargo.shippingCost.totalCost : 1;
    return ((rate - totalCost) / rate) * 100;
  }

  public isValidShippingCost(cargo: Cargo, showMessages: boolean): boolean {
    const minUtility = this.companyManager.getUtilityByCompanyId(cargo.idCompany);
    const maxAdvancePercentage = this.companyManager.getAdvancePercentageByCompanyId(cargo.idCompany);
    const permissionToCreateWithRateOrCostZero = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.createCargoWithRateOrCostZero);
    if (!cargo.shippingCost || (!cargo.shippingCost.rate && !permissionToCreateWithRateOrCostZero))
      !this.hasPermissionNoMinimumUtility(cargo) && showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_RATE, undefined, 'alert');
    else if ((!cargo.shippingCost.freightCost || !cargo.shippingCost.totalCost) && !permissionToCreateWithRateOrCostZero)
      !this.hasPermissionNoMinimumUtility(cargo) && showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_TOTAL_COST, undefined, 'alert');
    else if (this.getUtilityCargo(cargo) < minUtility && !permissionToCreateWithRateOrCostZero)
      !this.hasPermissionNoMinimumUtility(cargo) && showMessages && this.snackBarService.openSnackBar(Fmt.string(FormMessages.MINIMUN_UTILITY_NOT_REACHED, minUtility), undefined, 'alert');
    else if (!this.utils.isDefined(cargo.shippingCost.advancePercentage))
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_ADVANCE_PERCENTAGE, undefined, 'alert');
    else if (cargo.shippingCost.advancePercentage > maxAdvancePercentage)
      showMessages && this.snackBarService.openSnackBar(CargoMessages.MAX_ADVANCE_PERCENTAGE_EXCEED, undefined, 'alert');
    else if (!this.utils.isDefined(cargo.shippingCost.paymentTime))
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_PAYMENT_TYPE, undefined, 'alert');
    else return true;

    return false;
  }
  hasPermissionNoMinimumUtility(cargo) {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.createCargosWithoutMinimumUtility
    )
      && cargo.cargoModel.tripType.name === "Urbana"
      && cargo.cargoFeature.productType.name === "CONTENEDOR VACIO";
  }

  public updateCargoLabels(idCargo, newData) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateCargoLabels + idCargo,
      newData
    );
  }

  public updateCargoZone(cargoId: string, zone: 'private' | 'public'): Observable<Cargo> {
    return this._http.put<Cargo>(
      `${environment.urlServerTeclogi}${this._endpointResources.cargoPublishZone}?cargoId=${cargoId}&zone=${zone}`, {}
    );
  }

  public updateCargoConsignments(cargo: CargoConsignment): Observable<Cargo> {
    return this._http.put<Cargo>(
      environment.urlServerTeclogi + this._endpointResources.editCargoConsignment,
      cargo
    )
  }

  public generateManifest(cargoId: string) {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    return this._http.get(
      `${environment.urlServerTeclogi}${this._endpointResources.rebuildManifest}`,
      { params }
    );
  }

  public updateShippingCost(shippingCost: ShippingCost, cargoId: string) {
    return this._http.put(
      `${environment.urlServerTeclogi}${Fmt.string(this._endpointResources.updateShippingCost, cargoId)}`,
      shippingCost
    );
  }

  public deleteAssignedVehicle(cargoId: string) {
    return this._http.delete(
      `${environment.urlServerTeclogi}${Fmt.string(this._endpointResources.deleteAssignedVehicle, cargoId)}`
    );
  }

  public getCargoConsignments(cargoId: string, state?: string): Observable<ConsignmentCargo[]> {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    if (state) params = params.append('state', state);
    return this._http.get<ConsignmentCargo[]>(
      `${environment.urlServerTeclogi}${this._endpointResources.getAllCargoConsignments}`,
      { params }
    );
  }

  public createConsignment(consignments: ConsignmentCargo[]) {
    return this._http.post(
      `${environment.urlServerTeclogi}${this._endpointResources.cargoConsignments}`,
      consignments
    );
  }

  public updateConsignments(consignments: ConsignmentCargo[]) {
    return this._http.put(
      `${environment.urlServerTeclogi}${this._endpointResources.cargoConsignments}`,
      consignments
    );
  }

  public deleteConsignment(documentId: string, isNational: boolean) {
    let params = new HttpParams();
    params = params.append('documentId', documentId);
    params = params.append('isNational', isNational.toString());
    return this._http.put(
      `${environment.urlServerTeclogi}${this._endpointResources.consignmentSoftDelete}`,
      {},
      { params }
    );
  }

  public updateConsignmentRNDC(consignment: ConsignmentCargo, documentId: string) {
    return this._http.put(
      `${environment.urlServerTeclogi}${this._endpointResources.consignmentUpdateRNDC}${documentId}`,
      consignment,
    );
  }
}

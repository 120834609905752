<div class="modal-body wrapper-modal main-container px-0 px-md-4 pt-4">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="title pt-3 pt-sm-0">Inspección preoperacional de vehículos</div>
  <form>
    <mat-card-content>
      <div class="md-form">
        <label class="label-field" *ngIf="data?.inspect?.id && data.inspect.approval">Aprobado por:</label>
        <label class="label-field" *ngIf="!data?.inspect?.id  || ( data?.inspect?.id && !data.inspect.approval)">Creado
          por:</label>
        <input type="text" class="form-control" value="{{userInformation}}" readonly>
      </div>
    </mat-card-content>
  </form>
  <div class="container-checklist" *ngIf="getQuestionFlag">
    <mat-expansion-panel [expanded]="true" *ngFor="let section of inspectionQuestions | keyvalue: originalOrder"
      class="dialog-inspection-section my-3">
      <mat-expansion-panel-header>
        <mat-panel-title class="text-strong">
          {{sections[section.key]}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card-content class="d-flex flex-wrap align-items-end">
        <p *ngIf="isVehicleInspectionv3 && section.key === 'elementos de proteccion personal'" class="col-12">
          ¿El vehículo cuenta con los siguientes elementos?
        </p>
        <div class="d-flex flex-wrap col-12 p-0 px-md-2"
          [ngClass]="{'col-md-6':!showQuestionObservation(visualQuestion)}"
          *ngFor="let visualQuestion of section.value">

          <label class="label-field font p-0 col-12" *ngIf="showQuestionLabel(visualQuestion)">
            {{visualQuestion.name}}
            <i class="fas fa-question-circle fa-lg cursor-pointer" *ngIf="visualQuestion.description"
              [matTooltip]="visualQuestion.description"></i>
          </label>

          <div *ngIf="showQuestionAvailable(visualQuestion)"
            class="col-12 p-0 d-flex flex-wrap justify-content-between align-items-center"
            [ngClass]="{'col-md-6':showQuestionObservation(visualQuestion), 'mb-2':!showQuestionStatus(visualQuestion)}">
            <p class="m-0 p-0">
              {{isVehicleInspectionv3 ? visualQuestion.name : 'Presente:'}}
              <i class="fas fa-question-circle fa-lg cursor-pointer"
                *ngIf="visualQuestion.description && isVehicleInspectionv3"
                [matTooltip]="visualQuestion.description"></i>
            </p>
            <mat-button-toggle-group
              [(ngModel)]="getArrayByName(visualQuestion.inspectionAttribute)[getQuestionIndex(visualQuestion.code, visualQuestion.inspectionAttribute)].available">
              <mat-button-toggle [value]="true" [disabled]="data.disabled">
                Si
              </mat-button-toggle>
              <mat-button-toggle [value]="false" [disabled]="data.disabled">
                No
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <mat-form-field appearance="standard" *ngIf="showQuestionStatus(visualQuestion)" class="col-12 p-0"
            [ngClass]="{'col-md-6':showQuestionObservation(visualQuestion)}">
            <mat-select
              [(ngModel)]="getArrayByName(visualQuestion.inspectionAttribute)[getQuestionIndex(visualQuestion.code, visualQuestion.inspectionAttribute)].status"
              [ngModelOptions]="{standalone: true}" placeholder="Seleccione una opción" [disabled]="data.disabled">
              <mat-option *ngFor="let option of getRealOptions" [value]="option" [disabled]="data.disabled">
                {{isVehicleInspectionv3 && useQuestionAlias ? getAliasOption(motorcycleOptions[option]):
                getAliasOption(option)}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="standard" *ngIf="showQuestionResponse(visualQuestion)" class="col-12 p-0"
            [ngClass]="{'col-md-6':showQuestionObservation(visualQuestion)}">
            <mat-select
              [(ngModel)]="getArrayByName(visualQuestion.inspectionAttribute)[getQuestionIndex(visualQuestion.code, visualQuestion.inspectionAttribute)].response"
              [ngModelOptions]="{standalone: true}" placeholder="Seleccione una opción" [disabled]="data.disabled">
              <mat-option *ngFor="let option of getRealOptions" [value]="option" [disabled]="data.disabled">
                {{isVehicleInspectionv3 && useQuestionAlias ? getAliasOption(motorcycleOptions[option]):
                getAliasOption(option)}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="col-12 p-0" *ngIf="showQuestionObservation(visualQuestion)">
            Observaciones:
            <mat-form-field>
              <input matInput [disabled]="data.disabled"
                [(ngModel)]="getArrayByName(visualQuestion.inspectionAttribute)[getQuestionIndex(visualQuestion.code, visualQuestion.inspectionAttribute)].observation">
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-expansion-panel>



    <mat-expansion-panel [expanded]="true" class="dialog-inspection-section my-3">
      <mat-expansion-panel-header>
        <mat-panel-title class="text-strong">
          Mantenimientos del vehículo
        </mat-panel-title>

      </mat-expansion-panel-header>
      <p>
        Ingresa las fechas de los últimos mantenimientos y carga las facturas o comprobantes correspondientes.
      </p>
      <div class="d-flex flex-wrap">
        <mat-form-field class="col-md-6 cursor-pointer" appearance="standard">
          <mat-label>Último mantenimiento preventivo<span class="asterisk">*</span></mat-label>
          <input readonly class="cursor-pointer" type="text" matInput [matDatepicker]="datePickerPreventive"
            [formControl]="form.get('dateMaintenancePreventive')" (click)="datePickerPreventive.open()">
          <mat-datepicker-toggle matSuffix [for]="datePickerPreventive"></mat-datepicker-toggle>
          <mat-datepicker #datePickerPreventive></mat-datepicker>
          <mat-error *ngIf="form.get('dateMaintenancePreventive').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-md-6 cursor-pointer" appearance="standard">
          <mat-label>Último mantenimiento correctivo<span class="asterisk">*</span></mat-label>
          <input readonly class="cursor-pointer" type="text" matInput [matDatepicker]="datePicker"
            [formControl]="form.get('dateMaintenanceCorrective')" (click)="datePicker.open()">
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
          <mat-error *ngIf="form.get('dateMaintenanceCorrective').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
        <div class="col-md-6">
          <input #inputPreventativeMaintenance class="display-none" type="file"
            (change)="setInstanceFileStorage($event,'preventativeMaintenance')">
          <mat-form-field appearance="standard" (click)="openWindowFile('preventativeMaintenance')">
            <mat-label>Mantenimiento preventivo</mat-label>
            <input matInput type="text" class="field__form--input" readonly
              [formControl]="formPicture.get('preventativeMaintenance')">
            <i class="fas fa-camera i-picture field__form--icon" for="upload-photo"></i>
            <mat-error *ngIf="formPicture.get('preventativeMaintenance').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <input #inputCorrectiveMaintenance class="display-none" type="file"
            (change)="setInstanceFileStorage($event,'correctiveMaintenance')">
          <mat-form-field appearance="standard" (click)="openWindowFile('correctiveMaintenance')">
            <mat-label>Mantenimiento correctivo</mat-label>
            <input matInput type="text" class="field__form--input" readonly
              [formControl]="formPicture.get('correctiveMaintenance')">
            <i class="fas fa-camera i-picture field__form--icon" for="upload-photo"></i>
            <mat-error *ngIf="formPicture.get('correctiveMaintenance').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>
        <mat-form-field class="col-md-12" appearance="outline" *ngIf="!this.isVehicleInspectionv3">
          <mat-label>Observación<span class="asterisk">*</span></mat-label>
          <textarea matInput [formControl]="form.get('observation')"></textarea>
          <mat-error *ngIf="form.get('observation').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
          <mat-error *ngIf="form.get('observation').errors?.minlength">
            {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'observación', minObservation) }}
          </mat-error>
        </mat-form-field>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true" class="dialog-inspection-section my-3">
      <mat-expansion-panel-header>
        <mat-panel-title class="text-strong">
          Fotografías del vehículo
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Carga fotografías actualizadas del vehículo.
      </p>
      <form class="d-flex flex-wrap" [formGroup]="formPicture">
        <!-- Left lateral Picture-->
        <div class="col-md-4">
          <input #inputLeftLateralPicture class="display-none" type="file"
            (change)="setInstanceFileStorage($event,'leftLateralPicture')">
          <mat-form-field appearance="standard" (click)="openWindowFile('leftLateralPicture')">
            <mat-label>Foto lateral Izquierda del Vehículo<span class="asterisk">*</span></mat-label>
            <input matInput type="text" class="field__form--input" readonly formControlName="leftLateralPicture">
            <i class="fas fa-camera i-picture field__form--icon" for="upload-photo"></i>
            <mat-error *ngIf="formPicture.get('leftLateralPicture').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>
        <!--Right lateral Picture-->
        <div class="col-md-4">
          <input #inputRightLateralPicture class="display-none" type="file"
            (change)="setInstanceFileStorage($event,'rightLateralPicture')">
          <mat-form-field appearance="standard" (click)="openWindowFile('rightLateralPicture')">
            <mat-label>Foto lateral Derecha del Vehículo<span class="asterisk">*</span></mat-label>
            <input matInput type="text" class="field__form--input" readonly formControlName="rightLateralPicture">
            <i class="fas fa-camera i-picture field__form--icon" for="upload-photo"></i>
            <mat-error *ngIf="formPicture.get('rightLateralPicture').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>
        <!--Frontal Picture-->
        <div class="col-md-4">
          <input #inputFrontalImage class="display-none" type="file"
            (change)="setInstanceFileStorage($event,'frontPicture')">
          <mat-form-field appearance="standard" (click)="openWindowFile('frontPicture')">
            <mat-label>Foto frontal del Vehículo<span class="asterisk">*</span></mat-label>
            <input matInput type="text" class="field__form--input" readonly formControlName="frontPicture">
            <i class="fas fa-camera i-picture field__form--icon" for="upload-photo"></i>
            <mat-error *ngIf="formPicture.get('frontPicture').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-expansion-panel>

    <div class="d-flex flex-wrap mt-5 mb-3" *ngIf="this.isVehicleInspectionv3">
      <h5 class="text-center col-12 text-bold">Garantía de responsabilidad</h5>
      <label class="mr-3 text-center col-12">
        Yo, <b>{{userName}}</b>, garantizo que el vehículo con placa <b>{{vehiclePlate}} ha recibido mantenimiento
          preventivo y correctivo y se encuentra en óptimas condiciones</b> de funcionamiento para prestar
        el servicio de transporte terrestre de carga por carretera.
      </label>
      <mat-checkbox class="my-3 col-12" [formControl]="form.get('driverWarranty')">
        <span class="text-bold text-break">
          Confirmo que la información proporcionada es correcta y asumo total responsabilidad.
        </span>
      </mat-checkbox>
    </div>

    <mat-card-content class="d-flex flex-column my-3 px-3"
      *ngIf="!this.isVehicleInspectionv3 && inspection !== 'motorcycle'">
      <div class="content-form d-flex flex-wrap justify-content-between align-items-center">
        <label class="label-field font">¿Se encuentra la unidad en estado optimo?</label>
        <mat-button-toggle-group [(ngModel)]="optimal">
          <mat-button-toggle [value]="true" [disabled]="data.disabled">
            Si
          </mat-button-toggle>
          <mat-button-toggle [value]="false" [disabled]="data.disabled">
            No
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="content-form d-flex flex-wrap justify-content-between align-items-center">
        <label class="label-field font">¿Hay olores presentes dentro de la unidad?</label>
        <mat-button-toggle-group [(ngModel)]="odors">
          <mat-button-toggle [value]="true" [disabled]="data.disabled">
            Si
          </mat-button-toggle>
          <mat-button-toggle [value]="false" [disabled]="data.disabled">
            No
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="content-form d-flex flex-wrap justify-content-between align-items-center">
        <label class="label-field font">¿Hay riesgo de contaminación cruzada?</label>
        <mat-button-toggle-group [(ngModel)]="crossedContamination">
          <mat-button-toggle [value]="true" [disabled]="data.disabled">
            Si
          </mat-button-toggle>
          <mat-button-toggle [value]="false" [disabled]="data.disabled">
            No
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card-content>

  </div>
  <!-- Btn-->
  <mat-card-actions>
    <button mat-raised-button color="primary" *ngIf="data?.inspect?.id" class="button-width-auto"
      (click)="downloadInspection()">
      Descargar
    </button>
    <button mat-raised-button color="primary" *ngIf="!data?.inspect?.id" class="button-width-auto"
      (click)="saveInspection()">
      Guardar
    </button>
    <button mat-raised-button color="primary" *ngIf="data?.inspect?.id && !data.inspect.approval"
      class="primary-secondary button-width-auto" (click)="confirmRejection()">
      Rechazar
    </button>
    <button mat-raised-button color="primary" *ngIf="data?.inspect?.id && !data.inspect.approval"
      class="button-width-auto" (click)="approve(true)">
      Aprobar
    </button>
  </mat-card-actions>
</div>
<mat-form-field [appearance]="options?.appearance? options.appearance : 'standard'">
  <mat-label>{{options && options.title ? options.title : 'Ciudad'}}<span class="asterisk"
      *ngIf="utils.isRequiredField(formControlCity)">*</span></mat-label>
  <input (keyup)="changeValue()" type="text" #inputAutocomplete matInput autocomplete="off" matGoogleMapsAutocomplete
    *ngIf="options?.isInternational" [formControl]="formControlCity" [readonly]="formControlCity.disabled">
  <input (keyup)="changeValue()" matInput [matAutocomplete]="autocompleteCity" [formControl]="formControlCity"
    *ngIf="!options?.isInternational" [readonly]="formControlCity.disabled">
  <i *ngIf="options?.pinIcon" matPrefix class="fas fa-map-marker-alt mr-2"></i>
  <i *ngIf="options?.showXMark && formControlCity?.value?.id" matSuffix class="fas fa-times-circle clean-field"
    matTooltip="Borrar campo" (click)="cleanField()"></i>
  <mat-autocomplete #autocompleteCity="matAutocomplete" [displayWith]="displayCityName"
    (optionSelected)="selectCity($event)">
    <mat-option *ngFor="let city of listCities | async" [value]="city" [disabled]="disableCity(city)" [attr.data-tooltip]="city.tooltip">
      <span class="d-flex align-items-center">
        <ng-container *ngIf="options?.filterByMainCities">
          <ng-container *ngTemplateOutlet="reteicaInfo; context: { city: city }"></ng-container>
        </ng-container>
        <span>{{city.name}}</span> 
        <ng-container *ngIf="options?.showBillingInfo">
          <ng-container *ngTemplateOutlet="billingInfo; context: { city: city }"></ng-container>
        </ng-container>
      </span>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="formControlCity?.errors?.required || false">
    {{ utils.giveMessageError('MANDATORY_FIELD') }}
  </mat-error>
</mat-form-field>

<!-- Billing configuration Info -->
<ng-template #billingInfo let-city="city">
  <span *ngIf="(city?.taxSiigo && city?.productSiigo) || (city?.mainCity?.taxSiigo && city?.mainCity?.productSiigo); else notConfigured" class="text-success">
    <i class="fas fa-check-circle"></i> Ciudad configurada (haz click para ir a la configuración)
  </span>
  <ng-template #notConfigured>
    <span class="danger-text">
      <i class="fas fa-times-circle not-configured-icon"></i> Ciudad no configurada (haz click para agregar configuración)
    </span>
  </ng-template>
</ng-template>

<!-- Reteica Info -->
<ng-template #reteicaInfo let-city="city">
  <i *ngIf="disableCity(city)" class="fas fa-info-circle cursor-pointer color-secondary-dark" matTooltip="La ciudad no tiene un reteica configurado"></i>
</ng-template>

<aside id="mainCollectionPointList" class="col-md-12 mb-0 h-100-margin">
  <div class="container-list h-100-margin">
    <div class="container-list-header">
      <div class="row m-0 p-0 pb-3" permission-display [module]="permission.administration.module"
        [functionality]="permission.administration.createVehicle">
        <div class="col-12 text-center text-md-right create-vehicle-button m-0 p-0">
          <button matTooltip="Crear Vehículo" mat-raised-button color="primary"
            class="bg-color-primary button-width-auto text-bold" (click)="goToCreate()">
            <i class="fas fa-plus-circle mr-2 d-none d-sm-inline"></i>
            <span>Crear vehículo</span>
          </button>
        </div>
      </div>
    </div>
    <div class="container-list-filter pt-3 pb-3 pb-lg-0">
      <div class="row m-0 p-0">
        <div class="col-12 col-sm-6 col-md-4 col-lg-2 filter--company m-0 p-0 px-2">
          <app-select-company [options]="optionsCompany" [inputFormControl]="companyNitCtrl">
          </app-select-company>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-2 m-0 p-0 px-2">
          <mat-form-field appearance="outline">
            <mat-label>Placa</mat-label>
            <input type="text" appLicensePlate matInput [formControl]="filterlicensePlate">
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-2 m-0 p-0 px-2" permission-display
          [module]="permission.administration.module"
          [functionality]="permission.administration.downloadFileRegistreBank">
          <mat-form-field appearance="outline">
            <mat-label>Cuentas Bancarias</mat-label>
            <mat-select [formControl]="stateAccount">
              <mat-option *ngFor="let state of listStateAccountBank" value="{{state.value}}">
                {{state.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-2 m-0 p-0 px-2">
          <mat-form-field appearance="outline">
            <mat-label>Estado</mat-label>
            <mat-select [formControl]="vehicleState">
              <mat-option [value]="true">
                Activo
              </mat-option>
              <mat-option [value]="false">
                Inactivo
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Owner document filter -->
        <div class="col-12 col-sm-6 col-md-4 col-lg-2 m-0 p-0 px-2">
          <mat-form-field appearance="outline">
            <mat-label>N° documento propietario</mat-label>
            <input type="text" onlyNumbersInt matInput [formControl]="owner">
          </mat-form-field>
        </div>

        <!-- Type vehicle filter -->
        <mat-form-field class="col-12 col-sm-6 col-md-4 col-lg-2 m-0 p-0 px-2" appearance="outline">
          <mat-label>Tipo de vehículo</mat-label>
          <mat-select [formControl]="type">
            <mat-option *ngFor="let vehicleType of vehicleTypes" value="{{vehicleType.name}}">
              {{vehicleType.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="col-12">
          <div class="button-container">
            <button mat-raised-button class="primary-secondary" color="primary" (click)="cleanFilter()"
              title="Eliminar Filtros">
              <i class="far fa-trash-alt"></i>
            </button>
            <button mat-raised-button class="bg-color-primary" color="primary" (click)="applyFilters()"
              title="Aplicar Filtros">
              <i class="fas fa-search"></i>
            </button>
            <button mat-raised-button permission-display [module]="permission.administration.module"
              [functionality]="permission.administration.downloadFileRegistreBank" class="primary-secondary"
              color="primary" title="Inscribir cuentas" (click)="registerBankAccounts()" *ngIf="itemsSelecteds?.length">
              <i class="fas fa-plus"></i> Inscribir cuentas
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="row pb-3">

      </div> -->
    </div>
    <div class="container-list-body mt-4 mt-lg-0" infinite-scroll
      [infiniteScrollDistance]="service.paginationList.getScrollDistance()"
      [infiniteScrollThrottle]="service.paginationList.getThrottle()" [scrollWindow]="false"
      (scrolled)="onScrollDown()">
      <div class="col-md-12 mb-md-0 mb-12 p-0 pl-2"
        *ngFor="let vehicle of service.paginationList.getList(); let i = index;">
        <app-item-vehicle [vehicle]="vehicle" [stateAccount]="stateAccount" select-item-list
          [active]="service.getActiveItem(vehicle)" [item]="vehicle" [list]="service.paginationList.getList()"
          (onSelectItem)="onSelectItem($event)">
        </app-item-vehicle>
      </div>
    </div>
    <div class="my-1 d-flex justify-content-start">
      <div class="conv-btn" (click)="openConventions()">
        <i class="fas fa-circle"></i> Convenciones
      </div>
    </div>
  </div>
</aside>
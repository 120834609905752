import { Inject, Input } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TrackGuide } from 'src/app/core/interfaces/trackGuide';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoEvidenceComponent } from 'src/app/modules/cargo/cargo-evidence/cargo-evidence.component';
import { TrackGuideService } from '../track-guide-services';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { BasicGuide } from 'src/app/core/interfaces/basicGuide';
import { NgxSpinnerService } from 'ngx-spinner';
import { Global } from 'src/app/core/resources/global';
import { EvidenceViewerComponent } from 'src/app/shared/evidence-viewer/evidence-viewer.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { StorageEndpoints } from 'src/app/core/resources/storage-endpoints';
import { Fmt } from 'src/app/core/messages/fmt';
import { AuthService } from 'src/app/core/services/authentication.service';
import { HistoryGuide } from 'src/app/core/interfaces/historyGuide';

@Component({
  selector: 'app-track-guide-detail',
  templateUrl: './track-guide-detail.component.html',
  styleUrls: ['./track-guide-detail.component.scss']
})
export class TrackGuideDetailComponent {

  @Input() guide: BasicGuide;
  @Input() isDialog: boolean;
  historyImages: { [key: string]: { url: string, type: 'image' | 'pdf' }[] } = {};


  constructor(
    public dialog: MatDialog,
    public global: Global,
    @Inject(MAT_DIALOG_DATA) public dialogParams: {
      guide?: BasicGuide
    },
    public dialogRef: MatDialogRef<TrackGuideDetailComponent>,
    public utils: Utils,
    private _angularFireStorage: AngularFireStorage,
    private authService: AuthService
  ) {
    if (this.dialogParams && this.dialogParams.guide)
      this.guide = this.dialogParams.guide;
  }


  ngOnInit() {
    if (this.guide.stateHistoryGuide && this.guide.stateHistoryGuide.length) {
      this.guide.stateHistoryGuide.forEach(async (history, index) => {
        const images = [];
        if (history.images && history.images.length) {
          for (const image of history.images) {
            images.push({ url: await this.getDownloadURL(image), type: 'image' });
          }
        }
        this.historyImages[index] = images;
      });
    }
  }



  isNovelty(state: HistoryGuide): boolean {
    return !Object.keys(this.global.statusGuide).includes(state.name) || this.utils.isDefined(state.images) || !!state.type;
  }

  hideAnomaly(history: HistoryGuide): boolean {
    return !this.hasSessionActive && history.type === 'Normal' && !history.visibleForRecipient;
  }


  showReturnToWarehouse(history: HistoryGuide): boolean {
    return !history.name || (history.type === 'Anomaly' && !history.visibleForRecipient && !this.hasSessionActive);
  }



  async getDownloadURL(fileName: string): Promise<string> {
    if (!fileName) return null;

    try {
      let pathReference = this._angularFireStorage.ref(fileName);
      const url = await pathReference.getDownloadURL().toPromise();
      return url;
    } catch (error) {
      return null;
    }
  }

  openImageNewTab(historyId: string, index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { evidences: this.historyImages[historyId], index };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;

    this.dialog.open(EvidenceViewerComponent, dialogConfig);
  }

  get hasSessionActive(): boolean {
    return this.authService && this.authService.sessionActive();
  }
}



<div class="d-flex align-items-center justify-content-center status-docs-container">
  <span *ngIf="isOptionalDocument && showOptional" class="status-docs-container--optional">
    (Opcional)
  </span>
  <i 
    *ngIf="(expireDocs || statusDocs === 'pending'); else currentDoc" 
    class="fas fa-info-circle cursor-pointer"
    [ngClass]="{'color-primary': statusDocs === 'optional','warning-icon': statusDocs === 'pending', 'error-icon': statusDocs === 'required'}"
    [matTooltip]="getDocumentState()" 
    [matTooltipClass]="getTooltipClass(statusDocs)" 
    matTooltipPosition="above">
  </i>

  <ng-template #currentDoc>
    <i 
      *ngIf="getDocumentSupport(type, documents)" 
      class="fas fa-check-circle cursor-pointer"
      [ngClass]="{'success-icon': statusDocs === 'current', 'warning-icon':statusDocs!=='current'}"
      [matTooltip]="getDocumentState()" 
      [matTooltipClass]="getTooltipClass(statusDocs)" 
      matTooltipPosition="above">
    </i>
  </ng-template>
</div>
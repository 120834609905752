<form [formGroup]="menuService?.form" (ngSubmit)="saveAndContinue()" class="px-0 px-sm-3">
    <div class="container-fluid">
        <div class="row">
            <p class="col-md-12 text-bold">Observaciones Internas</p>
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Notas Adicionales <span class="optional">(opcional)</span></mat-label>
                    <input matInput type="text" [formControl]="observations" placeholder="Observaciones">
                </mat-form-field>
            </div>

            <p class="col-md-12 text-bold">
                Requisitos para el {{menuService?.isEscortService() ? 'escolta' : 'conductor'}}
            </p>
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Notas adicionales <span class="optional">(opcional)</span></mat-label>
                    <input matInput type="text" [formControl]="observationsToDriver"
                        [placeholder]="'Observaciones para el' + (menuService?.isEscortService() ? 'escolta' : 'conductor')">
                </mat-form-field>
            </div>
        </div>

        <!-- Action buttons -->
        <div class="button-container mt-4">
            <button mat-raised-button color="primary" class="button-width-auto primary-secondary button-small"
                [ngClass]="{'warn': true}" type="button" (click)="backToPreviousStep()">
                <span class="mat-button-wrapper">Atras</span>
            </button>
            <button class="mx-2" type="submit" mat-raised-button color="accent" *ngIf="canEditObservations">
                Guardar y Continuar
            </button>
            <button class="mx-2" type="button" mat-raised-button color="accent" (click)="continueToNextStep()"
                *ngIf="!canEditObservations">
                Continuar
            </button>
        </div>
    </div>
</form>
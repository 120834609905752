import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserActiveGuard } from 'src/app/core/guards/user-active.guard';
import { OriginLoadServiceFormComponent } from './components/origin-load-service-form/origin-load-service-form.component'
import { ContractorTripInfoComponent } from './components/contractor-trip-info/contractor-trip-info.component';
import { DestinationDownloadServiceFormComponent } from './components/destination-download-service-form/destination-download-service-form.component';
import { VehicleServiceFormComponent } from './components/vehicle-service-form/vehicle-service-form.component';
import { MenuServiceComponent } from './menu-service/menu-service.component';
import { LoadTypeServiceFormComponent } from './components/load-type-service-form/load-type-service-form.component';
import { LoadCharacteristicsFormComponent } from './components/load-characteristics-form/load-characteristics-form.component';
import { AdditionalServiceFormComponent } from './components/additional-service-form/additional-service-form.component';
import { AditionalServicesResolver } from 'src/app/core/resolves/additionalServices.resolve';
import { AdditionalValuesServiceFormComponent } from './components/additional-values-service-form/additional-values-service-form.component';
import { ObservationsServiceFormComponent } from './components/observations-service-form/observations-service-form.component';
import { ServicePublicationFormComponent } from './components/service-publication-form/service-publication-form.component';
import { CompaniesResolver } from 'src/app/core/resolves/companies.resolve';
import { ConsignmentsServiceFormComponent } from './components/consignments-service-form/consignments-service-form.component';
const routes: Routes = [
    {
        path: 'menu-service/:consecutive',
        component: MenuServiceComponent,
        data: { breadcrumb: 'Completar solicitud de servicio' },
        canActivate: [UserActiveGuard],
        children: [
            {
                path: 'contractor-trip-info',
                component: ContractorTripInfoComponent,
            },
            {
                path: 'origin-load',
                component: OriginLoadServiceFormComponent,
            },
            {
                path: 'destination-download',
                component: DestinationDownloadServiceFormComponent,
            },
            {
                path: 'vehicle-service',
                component: VehicleServiceFormComponent,
            },
            {
                path: 'load-type',
                component: LoadTypeServiceFormComponent,
            },
            {
                path: 'load-characteristics',
                component: LoadCharacteristicsFormComponent,
            },
            {
                path: 'additional-values-service',
                component: AdditionalValuesServiceFormComponent,
                resolve: {
                    aditionalServicesResolver: AditionalServicesResolver,
                    companiesResolver: CompaniesResolver
                }
            },
            {
                path: 'observations-service',
                component: ObservationsServiceFormComponent,
            },
            {
                path: 'consignments-service',
                component: ConsignmentsServiceFormComponent,
                resolve: {
                    companiesResolver: CompaniesResolver
                }
            },
            {
                path: 'service-publication',
                component: ServicePublicationFormComponent,
                resolve: {
                    companiesResolver: CompaniesResolver
                }
            },
            {
                path: '**',
                redirectTo: 'contractor-trip-info'
            }
        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ServiceRequestFormRoutingModule { }

<aside id="upload__route" class="h-100-margin">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="col-md-12 mb-md-0 mb-12" *ngIf="form">
    <div class="row data-container">
      <div class="col-12">
        <p class="upload-route-title col-12 p-0 pb-3">Datos del servicio</p>
      </div>
      <!-- Company -->
      <div class="col-12 col-lg-3">
        <app-select-company [options]="optionsCompany" [inputFormControl]="companyControl" [validate]="validateCompany">
        </app-select-company>
      </div>

      <!-- freight -->
      <div class="col-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>
            Flete
            <span class="optional">(Valor a pagar al conductor)</span>
            <span class="asterisk">*</span>
          </mat-label>
          <input matInput type="text" currencyMask min="0" placeholder="$100.000" formControlName="freight">
          <mat-error *ngIf="form.get('freight').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
          <mat-error *ngIf="form.get('freight').errors?.min">
            {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'flete', minFreight) }}
          </mat-error>
        </mat-form-field>
      </div>

      <!-- rate -->
      <div class="col-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>
            Tarifa
            <span class="optional">(Valor a pagar por el cliente)</span>
            <span class="asterisk">*</span>
          </mat-label>
          <input matInput type="text" currencyMask min="0" placeholder="$100.000" formControlName="rate">
          <mat-error *ngIf="form.get('rate').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
          <mat-error *ngIf="form.get('rate').errors?.min">
            {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'tarifa', minRate) }}
          </mat-error>
        </mat-form-field>
      </div>

      <!-- File -->
      <div class="col-12 col-lg-3">

        <div class="d-flex flex-column">
          <p class="upload-route-title col-12 d-block d-lg-none p-0">Adjunta el archivo de ruta</p>
          <div class="file-container">
            <p class="upload-route-title route-file-title d-none d-lg-block">Adjunta el archivo de ruta</p>
          </div>
          <app-standard-file [inputFormControl]="form.get('file')"
            [options]="{storageName: 'uploadRoute', format:'xlsx' }"
            (fileState)="fileEvents($event)"></app-standard-file>
        </div>
      </div>

      <p class="upload-route-title col-12 mt-4 pb-3">Orígen de Cargue</p>



      <!-- City -->
      <div class="col-12 col-md-6 col-lg-3 pr-md-0">
        <app-autocomplete-city [inputFormControl]="cityControl" [options]="optionsCity" [validate]="validateCity">
        </app-autocomplete-city>
      </div>
      <!--Date-->
      <div class="col-12 col-md-6 col-lg-3 pr-md-0">
        <mat-form-field appearance="outline">
          <mat-label>Fecha de cargue<span class="asterisk">*</span></mat-label>
          <input type="text" matInput [matDatepicker]="datePicker" readonly (click)="datePicker.open()"
            [formControl]="dateControl" [min]="today">
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
          <mat-error *ngIf="dateControl.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- addresses -->
      <div class="col-12 align-items-baseline">
        <app-multiple-addresses (emitToParent)="processAddresses($event)"></app-multiple-addresses>
      </div>

      <div class="col-12 submit-button mt-5 mb-3">
        <button type="submit" mat-raised-button
          [disabled]="form?.invalid || form?.get('addresses')?.value.length === 0 || emptyAddress || formatError">Crear
          servicio</button>
      </div>
    </div>
  </form>
</aside>
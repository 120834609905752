<!-- Resources found -->
<div *ngIf="resources?.length; else notResourcesLoaded" class="upload-resources-carrousel">
  <div *ngFor="let resource of resources; let i = index" class="upload-resources-carrousel__content">
    <div *ngIf="!resource?.loaded && resource?.type === 'image'" class="resource upload-resources-carrousel__spinner">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <i class="fa fa-times-circle icon-close upload-resources-carrousel__content--close"
      (click)="confirmDelete($event, resource, i)"></i>
    <i class="fas fa-info-circle upload-resources-carrousel__content--info" *ngIf="resource?.fingerprint"
      [matTooltip]="getFingerprintToolTip(resource?.fingerprint)" matTooltipClass="custom-tooltip"></i>
    <a *ngIf="resource?.type === 'image'; else isPdf" [href]="resource.src" target="_blank" rel="noopener noreferrer"
      class="upload-resources-carrousel__ref">
      <img [src]="resource?.src" alt="resource" class="resource upload-resources-carrousel__resource"
        (load)="onImageLoad(resource, i)" (error)="utils.onErrorRenderImage($event)" [hidden]="!resource?.loaded">
    </a>
    <ng-template #isPdf>
      <a class="resource resource upload-resources-carrousel__icon" [href]="resource.src" target="_blank"
        rel="noopener noreferrer">
        <i class="fas fa-file-pdf"></i>
      </a>
    </ng-template>
  </div>
  <!-- Add new resource -->
  <label class="upload-resources-carrousel__photo-add m-0" matTooltip="Agregar {{ textName }}">
    <input #resourceUpload type="file" class="display-none" (change)="handleFileInput($event)"
      accept="application/pdf,image/png,image/jpeg,image/jpg" multiple>
    <div class="icon-plus resource upload-resources-carrousel__resource-add--icon">
      <i class="fas fa-plus"></i>
    </div>
  </label>
</div>
<div class="upload-resources-carrousel__actions button-container" *ngIf="areEvidencesPreview && showButtonActions">
  <button mat-raised-button class="m-0 primary upload-resources-carrousel__actions--save" color="primary"
    (click)="addEvidenciesToStorage()">
    Guardar
  </button>
  <button mat-raised-button class="m-0 primary-secondary upload-resources-carrousel__actions--close" color="primary"
    (click)="closeDialog.emit(true)">
    Cancelar
  </button>
</div>
<!-- Not resources found -->
<ng-template #notResourcesLoaded>
  <div class="d-flex flex-column justify-content-center non-resources" *ngIf="allRender">
    <label class="color-primary non-resources__add">
      <input #resourceUpload type="file" class="display-none" (change)="handleFileInput($event)"
        accept="application/pdf,image/png,image/jpeg,image/jpg" multiple>
      <div class="d-flex non-resources__add--container">
        <div class="non-resources__add--icon">
          <img src="assets/svg/icons/icon-upload.svg" alt="upload-icon">
        </div>
        <u class="non-resources__add--text">Subir {{ textName }}</u>
      </div>
    </label>
    <div class="non-resources__hint" *ngIf="showAlertResources">
      Aún no se han subido {{ textName }}
    </div>
  </div>
</ng-template>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Company } from 'src/app/core/interfaces/company';
import { Permission } from 'src/app/core/resources/permission';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CompaniesService } from '../list-companies.service';
import { CompanyCompliment, CompanyComplimentDTO } from 'src/app/core/interfaces/companyCompliment';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { ComplimentFormComponent } from '../compliment-form/compliment-form.component';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { FileService } from 'src/app/shared/files/file.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { FormControl } from '@angular/forms';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { StorageEndpoints } from 'src/app/core/resources/storage-endpoints';
import { Fmt } from 'src/app/core/messages/fmt';

@Component({
  selector: 'app-compliments',
  templateUrl: './compliments.component.html',
  styleUrls: ['./compliments.component.scss']
})
export class ComplimentsComponent implements OnInit {
  permission = Permission;
  @Input() company: Company;
  @Output() public refreshList: EventEmitter<any> = new EventEmitter();
  complimentsList: CompanyCompliment[] = [];
  complimentsFilter: FormControl = new FormControl([]);
  public tripTypes: { label: string, value: TRIP_TYPES }[] = [
    { label: "Exportación", value: TRIP_TYPES.EXPORT },
    { label: "Importación", value: TRIP_TYPES.IMPORT },
    { label: "Internacional", value: TRIP_TYPES.INTERNATIONAL },
    { label: "Última milla/ Paqueteo urbano", value: TRIP_TYPES.LAST_MILE },
    { label: "Nacional", value: TRIP_TYPES.NATIONAL },
    { label: "Urbana", value: TRIP_TYPES.URBAN }
  ];

  constructor(
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private listCompaniesService: CompaniesService,
    private dialog: MatDialog,
    private fileService: FileService,
    private permissionRole: PermissionRole,
    private utils: Utils,
  ) { }

  ngOnInit() {
    this.getCompanyCompliments();
  }

  private getCompanyCompliments() {
    this.spinner.show();
    this.listCompaniesService.getCompanyCompliments(this.company.companyId).subscribe(
      (success) => {
        this.spinner.hide();
        if (success && success.length)
          this.complimentsList = success;
        else this.complimentsList = [];
      },
      (error) => {
        this.spinner.hide();
        if (error && error.error && error.error.message)
          this.snackBarService.openSnackBar(error.error.message, undefined, 'error');
        else
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    )
  }


  get filteredCompliments(): CompanyCompliment[] {
    const tripTypes: TRIP_TYPES[] = this.complimentsFilter.value;
    if (!tripTypes || !tripTypes.length) return this.complimentsList;
    return this.complimentsList.filter(compliment => tripTypes.some((tripType) => compliment.tripTypes.includes(tripType)));
  }

  getJoinedTripTypes(compliment: CompanyCompliment): string {
    return compliment.tripTypes.join(', ');
  }


  createCompliment() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Crear requisito de cumplido',
      list: this.complimentsList,
      confirmBtn: 'Crear',
      companyNit: this.company.companyId
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ComplimentFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.compliment)
        this.createCompanyCompliment(result.compliment);
    });
  }

  private createCompanyCompliment(compliment: CompanyComplimentDTO) {
    this.spinner.show();
    this.listCompaniesService.createCompanyCompliment(this.company.companyId, compliment).subscribe(
      () => {
        this.spinner.hide();
        this.getCompanyCompliments();
        this.snackBarService.openSnackBar(CargoMessages.COMPLIMENT_CREATED);
      },
      (error) => {
        this.spinner.hide();
        if (error && error.error && error.error.message)
          this.snackBarService.openSnackBar(error.error.message, undefined, 'error');
        else
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    );
  }

  editCompliment(compliment: CompanyCompliment) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Editar requisito de cumplido',
      list: this.complimentsList,
      compliment,
      confirmBtn: 'Guardar',
      companyNit: this.company.companyId
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ComplimentFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.compliment)
        this.updateCompliment(result.compliment);
    });
  }

  onChangeState($event: MatCheckboxChange, compliment: CompanyCompliment) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Deseas ${$event.checked ? 'activar' : 'desactivar'} el requisito de cumplido ${compliment.title}?`,
      labelButton1: 'Cancelar',
      labelButton2: $event.checked ? 'Activar' : 'Desactivar',
      hideBtnCancel: true,
      hideBtnConfirm: true,
    };
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state && result.refuse === ($event.checked ? 'Activar' : 'Desactivar')) {
        let body: CompanyCompliment = this.utils.clone(compliment);
        body.state = !!$event.checked;
        this.updateCompliment(body);
      }
      else this.getCompanyCompliments();
    });
  }

  private updateCompliment(compliment: CompanyCompliment) {
    this.spinner.show();
    this.listCompaniesService.updateCompanyCompliment(this.company.companyId, compliment).subscribe(
      () => {
        this.spinner.hide();
        this.getCompanyCompliments();
        this.snackBarService.openSnackBar(CargoMessages.COMPLIMENT_UPDATED);
      },
      (error) => {
        this.spinner.hide();
        if (error && error.error && error.error.message)
          this.snackBarService.openSnackBar(error.error.message, undefined, 'error');
        else
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    );
  }

  getComplimentFile(compliment: CompanyCompliment) {
    if (!compliment.example || !compliment.example[0]) return;
    let extension: string;
    if (compliment.example[0] && compliment.example[0].includes('.'))
      extension = compliment.example[0].split('.').reverse()[0].toUpperCase() === 'PDF' ? 'PDF' : 'IMG';
    this.spinner.show();
    const url = Fmt.string(StorageEndpoints.companyFulfillment, this.company.companyId);
    this.fileService.getURLDocument(`${url}/${compliment.example[0]}`).subscribe(
      (response) => {
        this.spinner.hide();
        if (response && response.code_ === "storage/object-not-found") {
          this.snackBarService.openSnackBar(FormMessages.ERROR_LOAD_RESOURCE, undefined, 'error');
          return;
        }
        const realURL = extension === 'PDF'
          ? `<embed src="${response}" type="application/pdf" width="100%" height="100%">`
          : `<img src="${response}" alt="Image Preview">`;
        const newWindow = window.open();
        if (newWindow) {
          newWindow.document.write(realURL);
          newWindow.document.close();
        }
      }, (error) => {
        this.spinner.hide()
        this.snackBarService.openSnackBar('Ocurrió un error al obtener el ejemplo del requisito', undefined, 'error');
      })
  }

  get canEditCompliments(): boolean {
    return this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.editCompliments);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';

@Component({
  selector: 'app-cargo-creation-modal',
  templateUrl: './cargo-creation-modal.component.html',
  styleUrls: ['./cargo-creation-modal.component.scss']
})
export class CargoCreationModalComponent implements OnInit {
  private cargos: Cargo[];
  public cargosWithErrors: Cargo[] = [];
  public cargosWithoutErrors: Cargo[] = [];
  public title: string;

  constructor(
    public dialogRef: MatDialogRef<CargoCreationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cargos: Cargo[],
      title: string
    },
  ) {
    this.cargos = this.data.cargos;
    this.title = this.data.title;
  }

  ngOnInit() {
    this.cargosWithoutErrors = this.cargos.filter(cargo => !!(cargo && cargo.id));
    this.cargosWithErrors = this.cargos.filter(cargo => !cargo || !cargo.id);
  }

  goToCargo(cargo: Cargo) {
    if (cargo.state === CargoStateEnum.REQUEST)
      this.dialogRef.close({ path: `/service-request-form/menu-service/${cargo.consecutive}` });
    else if (cargo.state === CargoStateEnum.START_SERVICE)
      this.dialogRef.close({ path: `/cargo/tracking/${cargo.consecutive}` });
    else
      this.dialogRef.close({ path: `/cargo/detail/${cargo.consecutive}` });
  }

  get hasCommonCargoList(): { list: string, state: string } {
    if (this.cargosWithoutErrors.length === 0) return null;
    if (this.cargosWithoutErrors.every(cargo => cargo && cargo.state === CargoStateEnum.REQUEST))
      return { list: 'request', state: 'solicitudes' };
    if (this.cargosWithoutErrors.every(cargo => cargo && cargo.state === CargoStateEnum.START_SERVICE))
      return { list: 'loadingRoutes', state: 'servicios en ruta' };
    if (this.cargosWithoutErrors.every(cargo => cargo && [CargoStateEnum.CREATED, CargoStateEnum.ACCEPTED].includes(cargo.state)))
      return { list: 'scheduledLoads', state: 'servicios programados' };
    return null;
  }

  goToListCargos() {
    const state = this.hasCommonCargoList;
    this.dialogRef.close({ path: `/cargo/list/${state.list}` });
  }
}

<div class="novelty-list px-1 px-sm-4 py-2">
    <div class="d-flex flex-wrap flex-column-reverse flex-md-row mt-4">
        <mat-form-field appearance="outline" class="col-md-6 col-lg-4">
            <mat-label class="text-bold">Tipo de novedad</mat-label>
            <mat-select [formControl]="noveltyFilter">
                <mat-option *ngFor="let type of noveltyTypes" [value]="type">
                    {{type}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="col-md-6 col-lg-8 d-flex justify-content-end mb-2" *ngIf="canEditNovelty">
            <button mat-raised-button type="button" color="primary" class="bg-color-primary" (click)="createNovelty()">
                <i class="fas fa-plus-circle cursor-pointer"></i>
                <span>Crear novedad</span>
            </button>
        </div>
    </div>

    <div class="d-flex flex-column mt-2">
        <div *ngFor="let novelty of filteredNovelties; let i = index;"
            class="novelty-card d-flex flex-wrap align-items-center mt-2 py-4 px-2 px-md-4">
            <div class="col-12 d-flex flex-wrap align-items-center px-0" [ngClass]="{'col-sm-10': canEditNovelty}">
                <div class="col-sm-6 col-xl-3" [ngClass]="{'col-xl-4': isPrincipalCompany}">
                    <p class="m-0">Nombre</p>
                    <p class="text-bold mb-0 text-break">{{novelty?.name}}</p>
                </div>
                <div class="col-sm-6 col-xl-3" [ngClass]="{'col-xl-4': isPrincipalCompany}">
                    <p class="m-0">Descripción</p>
                    <p class="text-bold mb-0 text-break">{{novelty?.description ? novelty?.description : '-'}}</p>
                </div>
                <div class="col-sm-6 col-xl-3" [ngClass]="{'col-xl-4': isPrincipalCompany}">
                    <p class="m-0">Tipo de novedad</p>
                    <p class="text-bold mb-0 text-break">{{novelty?.type === 'Anomaly' ? 'Bloqueante': 'Normal'}}</p>
                </div>
                <div class="col-sm-6 col-xl-3" [ngClass]="{'col-xl-4': isPrincipalCompany}">
                    <p class="m-0">
                        Visible al cliente
                        <i class="fas fa-info-circle cursor-pointer font-secondary-dark"
                            matTooltip="Cuando la novedad no es visible para los clientes, se mostrará 'Retorno a bodega' en la guía"></i>
                    </p>
                    <p class="text-bold mb-0 text-break">{{novelty?.visibleForRecipient ? 'Si' : 'No'}}</p>
                </div>
                <div class=" col-sm-6 col-xl-4" *ngIf="isPrincipalCompany">
                    <p class="m-0">Aplicable a</p>
                    <p class="text-bold mb-0 text-break">
                        {{novelty?.global ? "Todas las empresas" : "Solo a esta empresa"}}
                    </p>
                </div>
            </div>
            <div class="d-flex justify-content-end col-sm-2"
                *ngIf="canEditNovelty && novelty?.companyId === company?.companyId">
                <i (click)="editNovelty(novelty)" matTooltip="Editar novedad"
                    class="m-0 mr-sm-4 fas fa-pencil-alt color-primary cursor-pointer"></i>
            </div>
            <p class="type-novelty" *ngIf="novelty?.companyId !== company?.companyId">
                Predeterminada
            </p>
        </div>
        <div *ngIf="!filteredNovelties?.length" class="container_no_results">
            <div class="icon"><i class="fas fa-calendar-times"></i></div>
            <h4 class="text-bold text-center">
                {{noveltyList?.length
                ? 'No hay novedades que coincidan con la búsqueda'
                : 'No se han registrado novedades'}}
            </h4>
        </div>
    </div>

</div>
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssignVehicleCargoService } from './assign-vehicle-cargo.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { AngularFireStorage } from '@angular/fire/storage';
import { Utils } from 'src/app/core/resources/utils';
import * as _ from 'lodash';
import { CargoPayment } from 'src/app/core/interfaces/cargoPayment';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Observable, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { CargoDetailService } from '../cargo-detail/cargo-detail.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { FormVehicleComponent } from '../../administration/vehicles/form-vehicle/form-vehicle.component';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { DialogSurveyComponent } from 'src/app/shared/dialog-survey/dialog-survey.component';
import { CargoItemService } from '../cargo-item/cargo-item.service';
import { Dialog } from 'src/app/core/resources/dialog';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { TripsToCargo } from 'src/app/core/enums/tripsPerVehicle.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { User } from 'src/app/core/interfaces/user';
import { AccountService } from '../../account/account.service';
import { VehicleMessages } from 'src/app/core/messages/vehicle-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { VehiclesService } from '../../administration/vehicles/list-vehicles.service';
import { Router } from '@angular/router';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';
import { AdminUsersService } from '../../administration/admin-users/admin-users.service';
import { environment } from 'src/environments/environment';
import { Roles } from 'src/app/core/enums/roles.enum';
import { UserManager } from 'src/app/core/managers/user.manager';
import { Company } from 'src/app/core/interfaces/company';
import { AssignVehicle } from 'src/app/core/interfaces/assignVehicle';
import { VehicleCreateResponse } from 'src/app/core/interfaces/vehicleCreateResponse';
import { CargoService } from 'src/app/core/services/cargo.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { TurnMatch } from 'src/app/core/interfaces/turnMatch';
import { DateManager } from 'src/app/core/managers/date.manager';
import { ServiceType } from 'src/app/core/interfaces/serviceType';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { ChangeDriverComponent } from '../change-driver/change-driver.component';
import { InputLicensePlateTrailerComponent } from 'src/app/shared/input-license-plate-trailer/input-license-plate-trailer';
import { TrailerVehicleComponent } from '../../administration/vehicles/trailer-vehicle/trailer-vehicle.component';
import { Trailer } from 'src/app/core/interfaces/trailer';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';

@Component({
  selector: 'app-assign-vehicle-cargo',
  templateUrl: './assign-vehicle-cargo.component.html',
  styleUrls: ['./assign-vehicle-cargo.component.scss'],
  providers: [AuthService, Global, AssignVehicleCargoService, CargoManager, UserManager]
})

export class AssignVehicleCargoComponent implements OnInit {

  assignVehicleForm: FormGroup;
  @Input() cargo: Cargo;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onAssignVehicle: EventEmitter<any> = new EventEmitter();
  listVehicles: Observable<Vehicle[]>;
  filteredOptions: Observable<string[]>;
  vehicleSelected: Vehicle;
  trailerSelected: Trailer;
  stateDriverSelected = false;
  stateAdminSelected = false;
  stateOwnerSelected = false;
  haveAdmin: boolean = false;
  haveOwner: boolean = false;
  isNewDriver: boolean;
  userActive = this.authService.getUserSession().information;
  permission = Permission;
  mask: string = '';
  vehicleInfo: Vehicle;
  type: string = '';
  assignVehicleCargoComponent: AssignVehicleCargoComponent;
  licensePLate: string;
  vehicle: Vehicle;
  invalidVehicle: boolean = true;
  holderCompany: string;
  filterMatchDate = new FormControl(null);
  dateOptions = {
    lastWeek: 'dddd [pasado]',
    sameElse: 'L'
  };
  matchDateOptions = [
    {
      alias: 'Hoy',
      value: new Date()
    },
    {
      alias: 'Ayer',
      value: DateManager.substract(new Date(), 1, 'days')
    },
    {
      alias: DateManager.getCalendar(DateManager.substract(new Date(), 2, 'days'), null, this.dateOptions),
      value: DateManager.substract(new Date(), 2, 'days')
    },
  ];
  filterNumberOfTrips = new FormControl(null);
  numberOfTripsOptions = [
    { alias: 'Ningún viaje', value: 'zero' },
    { alias: '1 a 5 viajes', value: 'to five' },
    { alias: 'Más de 5 viajes', value: 'from six' },
  ];
  allowCreatingWithoutTruora: boolean = false;
  userStateByValidationRules: { active: boolean, description: string } = { active: false, description: '' };
  constructor(
    public dialogRef: MatDialogRef<AssignVehicleCargoComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: {
      cargo?: Cargo,
      filteredCargosTurns?: {
        cargoConsecutive?: number,
        matches?: TurnMatch[]
      },
      title?: string,
      type?: string,
      licensePlate?: string,
    },
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private assignVehicleCargoService: AssignVehicleCargoService,
    private userManager: UserManager,
    private freightForwarderDetailService: CargoDetailService,
    public utils: Utils,
    public matDialog: MatDialog,
    public dialog: Dialog,
    private cargoManager: CargoManager,
    private permissionRole: PermissionRole,
    private accountService: AccountService,
    private vehiclesService: VehiclesService,
    private router: Router,
    private adminUsersService: AdminUsersService,
    private cargoService: CargoService,
    public service: CargoDetailService,
    private companyService: CompanyService
  ) {
    if (this.dialogParams) {
      if (this.dialogParams.cargo) this.cargo = this.dialogParams.cargo;
      if (this.dialogParams.type) this.type = this.dialogParams.type;
    }
  }
  /**
  * This method evaluates if type is assign or not.
  */
  ngOnInit() {
    this.infoTruora();
    if (this.type === 'assign' || this.cargo)
      this.createAssignVehicleForm();
    this.holderCompany = this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId ? this.authService.getUserSession().clientCompanyId : null;
  }

  private infoTruora() {
    const holderCompany = this.companyService.holderCompany;
    if (holderCompany && holderCompany.companyId)
      this.allowCreatingWithoutTruora = !!(holderCompany && holderCompany.allowCreatingWithoutTruora);
  }
  /**
  * This method allows to create the required form to assign the vehicle to a load.
  */
  createAssignVehicleForm() {

    this.assignVehicleForm = new FormGroup({
      idCargo: new FormControl(
        this.cargo.id,
        Validators.compose(
          [Validators.required]
        )
      ),
      idUser: new FormControl(
        null,
        Validators.compose(
          [Validators.required]
        )
      ),
      idVehicle: new FormControl(
        '',
        Validators.compose(
          [Validators.required]
        )
      )
    });

    this.listVehicles = this.assignVehicleForm.controls.idVehicle.valueChanges
      .pipe(
        startWith(''),
        // debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => {
          try {
            if (val.trim().length > 2) {
              return this.filter(val || '');
            } else {
              this.vehicleSelected = null;
              this.trailerSelected = null;
              return [];
            }
          } catch (e) {
            return [];
          }
        })
      );

    if (this.dialogParams && this.dialogParams.licensePlate)
      this.getDetailLicensePlate(this.dialogParams.licensePlate);
  }

  private getDetailLicensePlate(licensePlate: string): void {
    this.spinner.show();
    this.assignVehicleCargoService.getVehicleById(licensePlate, this.holderCompany).subscribe(
      (response) => {
        this.spinner.hide();
        if (response.length && response[0] && response[0].id) {
          const vehicle: Vehicle = response[0];
          this.vehicleSelected = {
            id: vehicle.id,
            trailerId: vehicle.trailerId,
            owner: {
              document: this.utils.getNestedValue(vehicle, 'owner.document'),
              name: this.utils.getNestedValue(vehicle, 'owner.name'),
              documentTypeId: this.utils.getNestedValue(vehicle, 'owner.documentTypeId'),
            },
            driver: {
              document: this.utils.getNestedValue(vehicle, 'driver.document'),
              name: this.utils.getNestedValue(vehicle, 'driver.name')
            }
          };
          let adminDocument = this.utils.getNestedValue(vehicle, 'administrator.document');
          if (adminDocument) this.vehicleSelected.administrator = {
            document: adminDocument,
            name: this.utils.getNestedValue(vehicle, 'administrator.name')
          };
          this.vehicleSelected.vehicleType = {
            name: this.utils.getNestedValue(vehicle, 'vehicleType.name')
          };

          this.vehicleSelected.state = {
            active: this.utils.getNestedValue(vehicle, 'state.active')
          };

          this.vehicleSelected.stateCompany = {
            active: this.utils.getNestedValue(vehicle, 'stateCompany.active'),
            description: this.utils.getNestedValue(vehicle, 'stateCompany.description')
          }
          if (adminDocument && this.authService.getCompany().companyId !== environment.rootNit)
            this.getStateUser(adminDocument, 'Admin');
          else if (this.utils.getNestedValue(vehicle, 'owner.document') && this.authService.getCompany().companyId !== environment.rootNit)
            this.getStateUser(this.utils.getNestedValue(vehicle, 'owner.document'), 'Owner');
          if (this.utils.getNestedValue(vehicle, 'driver.document'))
            this.getStateUser(this.utils.getNestedValue(vehicle, 'driver.document'), 'Driver');
          if (this.utils.getNestedValue(vehicle, 'trailerId'))
            this.getDetailTrailer(this.utils.getNestedValue(vehicle, 'trailerId')).then(trailer => this.trailerSelected = trailer);
        } else {
          this.snackBarService.openSnackBar('No se encontró el vehículo', undefined, 'error');
          this.vehicleSelected = null;
          this.trailerSelected = null;
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('No se encontró el vehículo', undefined, 'error');
        this.vehicleSelected = null;
        this.trailerSelected = null;
      },
    );
  }
  /**
  * This method consumes a service that search matches of a vehicle in the BD with the license plata typed.
  * @param {string} value (string) is the license plate write by the user.
  * @returns {Observable<Vehicle[]>} (Observable<Vehicle[]>) returns a vehicle object if exists.
  */
  filter(value: string): Observable<Vehicle[]> {
    const filterValue = value.toUpperCase();
    if (this.holderCompany !== null) {
      return this.assignVehicleCargoService.getVehicleById(filterValue, this.holderCompany)
        .pipe(
          map(response => {
            if (response && response.length) {
              return response.filter(option => {
                return option.id.toLowerCase().indexOf(filterValue.toLowerCase()) === 0;
              })
            } else {
              return [];
            }
          }
          )
        );
    } else return of([]);
  }
  /**
  * This method set some information according to the selection of the user.
  * @param {any} $event (any) is the information of the selected vehicle.
  */
  optionSelected($event) {
    this.haveAdmin = false;
    this.haveOwner = false;
    const optionSelected = $event.option._element.nativeElement;
    if (optionSelected.idowner) {
      this.isNewDriver = false;
      this.vehicleSelected = {
        id: $event.option.value,
        trailerId: optionSelected.trailerid,
        owner: {
          document: optionSelected.idowner,
          name: optionSelected.nameowner,
          documentTypeId: optionSelected.typedocumentowner,
        },
        driver: {
          document: optionSelected.iddriver,
          name: optionSelected.namedriver
        },
        administrator: {
          document: optionSelected.idadmin
        }
      };

      this.vehicleSelected.vehicleType = {
        name: optionSelected.vehicletype
      };

      this.vehicleSelected.state = {
        active: optionSelected.vehiclestate
      };

      this.vehicleSelected.stateCompany = {
        active: optionSelected.vehiclestatecompany,
        description: optionSelected.vehiclestatecompanydescription
      }
      if (optionSelected.idadmin && this.authService.getCompany().companyId !== environment.rootNit) this.getStateUser(optionSelected.idadmin, 'Admin');
      else if (optionSelected.idowner && this.authService.getCompany().companyId !== environment.rootNit) this.getStateUser(optionSelected.idowner, 'Owner');
      if (optionSelected.iddriver) this.getStateUser(optionSelected.iddriver, 'Driver');
      if (optionSelected.trailerid) this.getDetailTrailer(optionSelected.trailerid).then(trailer => this.trailerSelected = trailer);

    } else {
      this.isNewDriver = true;
      this.vehicleSelected = null;
      this.trailerSelected = null;
      this.stateDriverSelected = false;
      this.stateAdminSelected = false;
      this.stateOwnerSelected = false;
      this.openDialogCreateVehicle();
    }
  }
  /**
  * This method open a dialog window to fill the fields necessaries to create a vehicle.
  */
  openDialogCreateVehicle() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      company: {
        exclusive: this.cargo.exclusive,
        nit: this.cargo.idCompany
      },
      cargoId: this.cargo.id
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(FormVehicleComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        this.onSubmit(result.value);
      } else if (result && !result.state) {
        this.snackBarService.openSnackBar('Ocurrió un error al asignar el vehículo', undefined, 'error');
      }
    });
  }

  get amountsCargoEnum() {
    return AmountsCargoEnum;
  }

  get PermissionAssignVehicle() {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.assignVehicleFullAccess);
  }

  get TripsToCargo() {
    return TripsToCargo;
  }

  getMatchIndex(match: TurnMatch): number {
    if (!this.dialogParams || !this.dialogParams.filteredCargosTurns || !this.dialogParams.filteredCargosTurns.matches)
      return 0;
    const index = this.dialogParams.filteredCargosTurns.matches.findIndex(mat => mat.id === match.id);
    if (index !== -1) return index + 1;
    return 0;
  }

  get cargoMatchesFiltered(): TurnMatch[] {
    if (!this.dialogParams || !this.dialogParams.filteredCargosTurns || !this.dialogParams.filteredCargosTurns.matches)
      return [];
    let matches = this.dialogParams.filteredCargosTurns.matches;
    if (this.filterMatchDate.value) {
      matches = matches.filter(match => DateManager.isSame(this.filterMatchDate.value, DateManager.stringToDate(match.date)));
    }
    if (this.filterNumberOfTrips.value) {
      const trips = this.filterNumberOfTrips.value;
      matches = matches.filter(match => {
        return match && this.utils.isDefined(match.numberOfTrips) &&
          ((trips === 'zero' && match.numberOfTrips === 0) ||
            (trips === 'to five' && match.numberOfTrips && match.numberOfTrips <= 5) ||
            (trips === 'from six' && match.numberOfTrips && match.numberOfTrips > 5));
      });
    }
    return matches;
  }
  /**
  * This method evaluates the conditions of the vehicle necessaries to carry the load.
  * @param {Vehicle} vehicle (Vehicle - optional) is the infomation of the vehicle.
  * @param {string} type (string - optional) is the parameter to determined if is manual assign or autoassign.
  * @param {Cargo} cargo (Cargo - optional) is the information of the load.
  * @returns {boolean} (boolean) returns if the vehicle could carry the load or not.
  */
  secureTravelProtocol(vehicle?: Vehicle, type?: string, cargo?: Cargo): boolean {
    if (vehicle && type && cargo) {
      this.cargo = cargo;
    } else if (vehicle && type) {
      vehicle = vehicle[0];
    }
    const amount = this.utils.getNestedValue(cargo, 'cargoFeature.cargoMeasure.amount');
    if (amount && !this.PermissionAssignVehicle) {
      if (amount <= AmountsCargoEnum.LOW_END) {
        return false;
      } else if (amount <= AmountsCargoEnum.MEDIUM_END) {
        return !(vehicle && vehicle.numberOfTrips && vehicle.numberOfTrips >= TripsToCargo.TRIPS_TO_MEDIUM_END);
      } else {
        return !(vehicle && vehicle.numberOfTrips && vehicle.numberOfTrips >= TripsToCargo.TRIPS_TO_HIGH_END);
      }
    }
    else {
      return false;
    }
  }
  /**
  * This method verify if the vehicle has driver.
  * @param {Vehicle} vehicle (Vehicle) is the information of the vehicle.
  * @returns {boolean} (boolean) returns if has or not driver.
  */
  hasDriver(vehicle: Vehicle): boolean {
    return !!(vehicle && vehicle.driver && vehicle.driver.document && vehicle.driver.name);
  }
  /**
  * This method evaluates the conditions to assign a vehicle.
  * @param {AssignVehicle} params (AssignVehicle) is the information neccesary to bring the vehicle and driver information.
  * @param {string} type (string - optional) if is turn o common assign vehicle.
  */
  public async assignVehicle(params: AssignVehicle, type?: string) {
    const vehicle: Vehicle = await this.vehiclesService.getVehicle(params.licensePlate).toPromise();
    if (!vehicle || !vehicle.driver || !vehicle.driver.document) {
      this.spinner.hide();
      this.snackBarService.openSnackBar('Ocurrió un error al traer el detalle del vehículo', undefined, 'error');
      return;
    }

    const driver = await this.adminUsersService.getUsersDetailByDocument(vehicle.driver.document).toPromise();
    this.spinner.hide();
    if (!driver || !driver.information) {
      this.snackBarService.openSnackBar('Ocurrió un error al traer el detalle del conductor', undefined, 'error');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { title: "Error en la Información obligatoria del Vehículo", hideBtnConfirm: true };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    let missingMessage = '';

    // Documentación obligatoria faltante
    const missingDocuments = await this.vehiclesService.getMissingDocuments(vehicle, 'missing');
    // Documentación obligatoria pendiente por validar
    const unacceptedDocuments = await this.vehiclesService.getMissingDocuments(vehicle, 'unaccepted');
    // Referencias pendientes por validar
    const invalidReferences = this.vehiclesService.invalidReferences(driver);

    if (missingDocuments && missingDocuments.length) {
      missingDocuments.forEach(document => missingMessage += `<li>${document}</li>`);
      dialogConfig.data.descriptionHTML = `<p>${VehicleMessages.VEHICLE_DOCUMENTATION_INCOMPLETE}</p><ol>${missingMessage}</ol>`;
      this.matDialog.open(DialogComponent, dialogConfig);
    }
    else if (unacceptedDocuments && unacceptedDocuments.length) {
      unacceptedDocuments.forEach(document => missingMessage += `<li>${document}</li>`);
      dialogConfig.data.descriptionHTML = `<p>${VehicleMessages.VEHICLE_DOCUMENTATION_UNACCEPTED}</p><ol>${missingMessage}</ol>`;
      this.matDialog.open(DialogComponent, dialogConfig);
    }
    else if (invalidReferences && invalidReferences.length) {
      invalidReferences.forEach(document => missingMessage += `<li>${document}</li>`);
      dialogConfig.data.descriptionHTML = `<p>${VehicleMessages.VEHICLE_REFERENCES_UNACCEPTED}</p><ol>${missingMessage}</ol>`;
      this.matDialog.open(DialogComponent, dialogConfig);
    }
    else this.assignVehicleToCargo(params, type);
  }

  /**
  * This method consumes the service to assign a vehicle to a load.
  * @param {AssignVehicle} params (AssignVehicle) is the information neccesary to bring the vehicle and driver information.
  * @param {string} type (string - optional) if is turn o common assign vehicle.
  */
  private assignVehicleToCargo(params: AssignVehicle, type?: string) {
    this.spinner.show();
    this.freightForwarderDetailService.acceptCargo(params).subscribe(
      (data) => {
        // if (data) {
        //   this.dialogRef.close({ state: true })
        //   this.spinner.hide();
        //   this.snackBarService.openSnackBar('Se ha asignado el vehículo ' + params.licensePlate + ' correctamente');
        //   // this.onAssignVehicle.emit();
        // } else {
        //   this.spinner.hide();
        //   this.snackBarService.openSnackBar('Ocurrió un error al asignar el vehículo ' + params.licensePlate, undefined, 'error');
        // }
        this.cleanErrorFromExcel();
        this.belowSicetac(this.cargo, type, params);
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al asignar el vehículo ' + params.licensePlate, undefined, 'error');
      }
    );
  }
  /**
  * This method close the assign vehicle modal.
  */
  closeModal() {
    this.assignVehicleForm.reset();
    this.dialogRef.close({ state: false })
  }
  /**
  * This method set the license plate pattern to assign a vehicle.
  */
  getMask() {
    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.specialLicensePlate) && this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.assignLicensePlate
    )) {
      return 'WWWWWWW';
    } else if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.specialLicensePlate)) {
      return 'CDCDDD';
    } else {
      return 'WWWWWWW';
    }
  }
  /**
  * This method brings the information of the user.
  * @param {string} document (string) is the identification of the user.
  * @param {string} type (string) is the type of user could be: admin, owner or driver.
  * @param {Cargo} cargo (Cargo) is the information of the load.
  */
  getStateUser(document: string, type: 'Admin' | 'Owner' | 'Driver', cargo?: Cargo): void {
    if (document) {
      this.accountService.validateEntity(1, document).subscribe(
        (data: User) => {
          this.adminUsersService.getUserStateByValidationRules(data, type)
          .then((userStateByValidationRules) => {
            this.setStatesUser(type, data.information.documentTypeId == '3' || (data.state && this.utils.isDefined(data.state.active) && userStateByValidationRules.active));
          })
          .catch(() => {
            this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
            return;
          });
          if (cargo) {
            this.onAssignVehicle.emit(cargo);
          }
        }, () => {
          this.setStatesUser(type, false);
          if (cargo) {
            this.onAssignVehicle.emit(cargo);
          }
        })
    } else {
      this.setStatesUser(type, false);
      if (cargo) {
        this.onAssignVehicle.emit(cargo);
      }
    }

  }
  /**
  * This method evaluates the type of user and its state.
  * @param {string} type (string) is the type of user could be: admin, owner, driver.
  * @param {boolean} state (boolean) is the state of the user.
  */
  setStatesUser(type: 'Admin' | 'Owner' | 'Driver', state: boolean) {
    switch (type) {
      case 'Admin':
        this.haveAdmin = true;
        this.stateAdminSelected = state;
        break;
      case 'Owner':
        this.haveOwner = true;
        this.stateOwnerSelected = state;
        break;
      case 'Driver':
        this.stateDriverSelected = state;
        break;
    }
  }

  loadValidations() {
    if (this.cargo) {
      // Rate and cost 0 validation
      if (!this.isShippingCostZero()) return true;
      if (!this.isValidSaasDocument()) {
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_RETRY_INDICATION, undefined, 'error');
        return false;
      }
      if (!this.isValidVehicle(this.vehicleSelected)) {
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_RETRY_INDICATION, undefined, 'error');
        return false;
      }
      if (this.vehicleSelected.administrator && this.vehicleSelected.administrator.document && this.vehicleSelected.administrator.document === this.authService.getCompanySaaS().companyId.slice(0, 9)) return true;
      if (this.vehicleSelected.owner && this.vehicleSelected.owner.document && this.vehicleSelected.owner.document === this.authService.getCompanySaaS().companyId.slice(0, 9)) return true;
      this.snackBarService.openSnackBar(ServiceMessages.SAME_OWNER_VEHICLE_LOAD_TO_RATE_COST_ZERO_ASSING_VEHICLE, undefined, 'error');
      return false;
    } else {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_RETRY_INDICATION, undefined, 'error');
      return false;
    }
  }

  private isShippingCostZero(): boolean {
    const { shippingCost } = this.cargo;
    return !!shippingCost &&
      (shippingCost.rate === 0 || shippingCost.freightCost === 0 || shippingCost.totalCost === 0);
  }

  private isValidSaasDocument(): boolean {
    return !!(this.authService && this.authService.getCompanySaaS() && this.authService.getCompanySaaS().companyId);
  }

  private isValidVehicle(vehicle: Vehicle): boolean {
    return !!vehicle && ((!!vehicle.owner && !!vehicle.owner.document) || (!!vehicle.administrator && !!vehicle.administrator.document));
  }
  /**
  * This method validates all the conditions necessary to assign a vehicle.
  * @param {VehicleCreateResponse} vehicleNew (VehicleCreateResponse - optional) is the information of the vehicle if is a new vehicle.
  * @param {Cargo} cargo (Cargo - optional) is the information of the load.
  * @param {string} type (string - optional) if is turn o common assign vehicle.
  */
  async onSubmit(vehicleNew?: VehicleCreateResponse, cargo?: Cargo, type?: string) {
    if (cargo) this.cargo = cargo;
    let areValid: boolean = this.loadValidations();
    if (!areValid) return;
    if (this.vehicleSelected && (!this.vehicleSelected.driver || !this.vehicleSelected.driver.document)) return this.snackBarService.openSnackBar("Debes registrar un conductor para continuar", undefined, 'alert');
    if (this.vehicleSelected && this.vehicleSelected.vehicleType && this.vehicleSelected.vehicleType.name && this.vehicleSelected.vehicleType.name === "TRACTOCAMION" && !this.vehicleSelected.trailerId) return this.snackBarService.openSnackBar("Debes registrar un trailer para continuar", undefined, 'alert');
    let isRootNit = this.authService.getCompany().companyId === environment.rootNit;
    let title: string = '';
    if (!this.vehicleSelected) title = VehicleMessages.NOT_VEHICLE_SELECTED;
    else if (this.vehicleSelected && (!this.vehicleSelected.state || !this.vehicleSelected.state.active))
      title = !!this.utils.getNestedValue(this.vehicleSelected, 'state.description')
        ? this.vehicleSelected.state.description === 'Inactive' ? 'El vehículo se encuentra inactivo' : this.vehicleSelected.state.description
        : 'El vehículo se encuentra inactivo';
    else if (!this.utils.getNestedValue(this.vehicleSelected, 'vehicleType.name')) title = VehicleMessages.NO_VEHICLE_TYPE_NAME;
    else if (!this.utils.getNestedValue(this.cargo, 'cargoFeature.vehicleType.name')) title = VehicleMessages.NO_CARGO_VEHICLE_NAME;
    else if ((this.cargo.cargoFeature.vehicleType.name.trim().toLowerCase() !== this.vehicleSelected.vehicleType.name.trim().toLowerCase()))
      title = Fmt.string(VehicleMessages.VEHICLE_TYPE_DIFF, this.vehicleSelected.vehicleType.name, this.cargo.cargoFeature.vehicleType.name);
    else if (!(this.vehicleSelected.state && this.vehicleSelected.state.active.toString() === 'true')) title = VehicleMessages.STATE_INACTIVE;
    else if (!this.stateDriverSelected && !this.allowCreatingWithoutTruora) title = VehicleMessages.DRIVER_STATE_INACTIVE;
    else if (this.haveOwner && !this.stateOwnerSelected && !isRootNit && !this.allowCreatingWithoutTruora)
      title = VehicleMessages.OWNER_STATE_INACTIVE;
    else if (this.haveAdmin && !this.stateAdminSelected && !isRootNit && !this.allowCreatingWithoutTruora)
      title = VehicleMessages.ADMIN_STATE_INACTIVE;
    else if (this.vehicleSelected && this.vehicleSelected.vehicleType && this.vehicleSelected.vehicleType.name && this.vehicleSelected.driver && this.vehicleSelected.driver.document) {
      const driver = await this.getDetailUserByDocument(this.vehicleSelected.driver.document);
      if (driver && driver.driverLicenseCategory && driver.driverLicenseCategory.length) {
        const licenseCategory = this.assignVehicleCargoService.checkLicenseCategory(driver.driverLicenseCategory, this.vehicleSelected.vehicleType.name);
        if (!licenseCategory.valid) title = licenseCategory.message;
      } else title = "El conductor no tiene licencia(s) de conducción configurada(s)";
    }
    if (title) {
      this.spinner.hide();
      return this.snackBarService.openSnackBar(title, undefined, 'alert');
    }
    const params: AssignVehicle = {
      cargoId: this.cargo.id,
      applicantId: null,
      licensePlate: null
    };
    if (this.vehicleSelected) {
      params.applicantId = this.utils.getNestedValue(this.vehicleSelected, 'driver.document');
      params.licensePlate = this.utils.getNestedValue(this.vehicleSelected, 'id');
    }
    if (this.isNewDriver) {
      params.applicantId = vehicleNew.driver.information.document;
      params.licensePlate = vehicleNew.vehicle.id;
    }
    if (params && params.cargoId && params.cargoId && params.licensePlate) {
      //Inspeccionar vehiculo
      const result = await this.cargoManager.modalPreoperationalInspection(this.cargo, params.licensePlate);
      if (!result) return;
      this.spinner.show();
      if (type)
        this.assignVehicle(params, type);
      else
        this.assignVehicle(params);
    } else {
      this.spinner.hide();
      return this.snackBarService.openSnackBar('No ha seleccionado un Vehículo', undefined, 'alert');
    }


  }

  get isEscortedService(): boolean {
    const serviceType: ServiceType = this.utils.getNestedValue(this.cargo, 'cargoModel.serviceType');
    return serviceType && serviceType.id === 'escortServices';
  }

  /**
  * This method consumes the service that brings the information of the vehicle.
  * @param {string} licensePLate (string) is the license plate of the vehicle on request.
  * @param {Cargo} needResponse (Cargo - optional) is the information of the load.
  * @param {number} numberOfTrips (number - optional) is the number of trips necessary for the trip.
  */
  getVehicleInfo(licensePLate: string, needResponse?: Cargo, numberOfTrips?: number) {
    const amount = this.utils.getNestedValue(this.cargo, "cargoFeature.cargoMeasure.amount");
    if (amount) {
      if ((amount >= AmountsCargoEnum.HIGH_START) && (numberOfTrips < TripsToCargo.TRIPS_TO_HIGH_END) && (!this.PermissionAssignVehicle))
        return this.snackBarService.openSnackBar(`No es posible asignar este vehículo. El número mínimo de viajes requerido es de ${TripsToCargo.TRIPS_TO_HIGH_END}`, undefined, 'error')
      else if ((amount >= AmountsCargoEnum.MEDIUM_START) && (numberOfTrips < TripsToCargo.TRIPS_TO_MEDIUM_END) && (!this.PermissionAssignVehicle))
        return this.snackBarService.openSnackBar(`No es posible asignar este vehículo. El número mínimo de viajes requerido es de ${TripsToCargo.TRIPS_TO_MEDIUM_END}`, undefined, 'error')
    }

    const vehicleInfoObserver = {
      next: (data: Vehicle[]) => {
        this.spinner.hide();
        this.vehicleInfo = data[0];
        this.assignTovehicleSelected(this.vehicleInfo, needResponse);
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar("Hubo un error procesando la información vuelva a intentarlo", undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
        // nada por ahora
      }
    };
    this.spinner.show();
    this.assignVehicleCargoService.getVehicleById(licensePLate, this.holderCompany).subscribe(vehicleInfoObserver)

  }
  /**
  * This method assign the information of the vehicle selected and the people associate.
  * @param {Vehicle} vehicleInfo (Vehicle) is the information of the vehicle selected.
  * @param {Cargo} cargo (Cargo - optional) is the information of the load.
  */
  assignTovehicleSelected(vehicleInfo: Vehicle, cargo?: Cargo) {
    this.vehicleSelected = {
      id: vehicleInfo.id,
      trailerId: vehicleInfo.trailerId,
      owner: {
        document: this.utils.getNestedValue(vehicleInfo, 'owner.document'),
        name: this.utils.getNestedValue(vehicleInfo, 'owner.name'),
        documentTypeId: this.utils.getNestedValue(vehicleInfo, 'owner.documentTypeId'),
      },
      driver: {
        document: this.utils.getNestedValue(vehicleInfo, 'driver.document'),
        name: this.utils.getNestedValue(vehicleInfo, 'driver.name'),
      },
    };
    if (vehicleInfo.administrator && vehicleInfo.administrator.document)
      this.vehicleSelected.administrator = {
        document: this.utils.getNestedValue(vehicleInfo, 'administrator.document')
      }

    this.vehicleSelected.vehicleType = {
      name: this.utils.getNestedValue(vehicleInfo, 'vehicleType.name')
    };

    this.vehicleSelected.state = {
      active: this.utils.getNestedValue(vehicleInfo, 'state.active')
    };
    if (this.utils.getNestedValue(vehicleInfo, 'driver.document'))
      this.getStateUser(this.utils.getNestedValue(vehicleInfo, 'driver.document'), 'Driver', cargo);
    if (this.utils.getNestedValue(vehicleInfo, 'trailerId'))
      this.getDetailTrailer(this.utils.getNestedValue(vehicleInfo, 'trailerId')).then(trailer => this.trailerSelected = trailer);
  }

  public changeDriver(type?: 'change' | 'assign'): void {

    const formControl = new FormControl();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `${type === 'change' ? 'Modificación' : 'Asignación'} de conductor`,
      label: 'Ingresa el número de documento',
      idVehicle: this.vehicleSelected.id,
      options: {
        typeUser: 'driver',
      },
      inputFormControl: formControl
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const modalDialog = this.matDialog.open(ChangeDriverComponent, dialogConfig);
    modalDialog.afterClosed().subscribe(result => {
      if (result && result.state && result.data) {
        this.vehicleChangeDriver(result.data, result.securityStudyValidation);
      }
    });
  }

  private vehicleChangeDriver(user: User, securityStudyValidation: boolean): void {
    this.spinner.show();
    this.vehiclesService.vehicleChangeDriver(this.vehicleSelected.id, user, securityStudyValidation).toPromise()
      .then((success: User) => {
        const data = this.userManager.sanitizeUser(success, Roles.DRIVER);
        if (data) {
          if (data.errorRNDC && data.errorRNDC && data.errorRNDC.error === 'Debe ingresar un conductor diferente al actual')
            this.snackBarService.openSnackBar(data.errorRNDC.error, undefined, 'alert');
          else {
            this.snackBarService.openSnackBar('Conductor asignado correctamente');
            this.getDetailLicensePlate(this.vehicleSelected.id);
          }
        } else {
          this.snackBarService.openSnackBar('Ocurrió un error al cambiar de conductor', undefined, 'error');
        }
      })
      .catch((error) => {
        this.snackBarService.openSnackBar('Ocurrió un error al cambiar de conductor', undefined, 'error');
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  /**
  * @description Opens a modal to change the trailer, depending of result executes "openDialogCreateTrailer" or "vehicleChangeTrailer" methods
  */
  changeTrailer() {
    const formControl = new FormControl();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Ingresa la placa del trailer",
      dialog: true,
      options: { autocomplete: true },
      inputFormControl: formControl,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    this.matDialog.open(InputLicensePlateTrailerComponent, dialogConfig).afterClosed().subscribe((result) => {
      if (result && result.state) {
        if (result.data === null)
          this.openDialogCreateTrailer();
        else if (this.utils.isDefined(result.data))
          this.vehicleChangeTrailer(this.vehicleSelected.id, result.data);
      }
    });
  }

  /**
  * @description Opens a modal to create new trailer and updates the vehicle trailer with modal result
  */
  private openDialogCreateTrailer() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      updateTrailer: true,
      vehicle: this.vehicle,
    };
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    this.matDialog.open(TrailerVehicleComponent, dialogConfig).afterClosed().subscribe(async (result) => {
      if (result && result.state && result.trailerId) {
        const trailer = await this.getDetailTrailer(result.trailerId);
        if (trailer) this.vehicleChangeTrailer(this.vehicleSelected.id, trailer);
      }
    });
  }

  /**
  * @description Gets the trailer's detail from backend service
  */
  private async getDetailTrailer(trailerId: string): Promise<Trailer> {
    try {
      const trailers: Trailer[] = await this.vehiclesService.getTrailerList(`?id=${trailerId}`).toPromise();
      return trailers && trailers.length ? trailers[0] : null;
    } catch (error) {
      return null;
    }
  }

  /**
  * @description Changes the vehicle's trailer with backend service
  */
  private vehicleChangeTrailer(licensePlate: string, trailer: Trailer) {
    this.spinner.show();
    this.vehiclesService.updateTrailer(trailer, licensePlate).toPromise()
      .then(() => this.getDetailLicensePlate(licensePlate))
      .catch((error) => {
        if (error && error.errorRNDC && error.errorRNDC.error)
          this.snackBarService.openSnackBar(error.errorRNDC.error, undefined, "error");
        else
          this.snackBarService.openSnackBar("Ocurrió un error al cambiar de trailer", undefined, "error");
      })
      .finally(() => this.spinner.hide())
  }

  get assignTurnVehicles(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.assignTurnVehicles);
  }

  private cleanErrorFromExcel() {
    if (!this.cargo || !this.cargo.errorFieldsExcelCargo ||
      !this.cargo.errorFieldsExcelCargo.licensePlate ||
      this.cargo.state !== 'Request')
      return;

    let data = { id: this.cargo.id, state: this.cargo.state };
    if (this.cargo.errorFieldsExcelCargo) {
      data['errorFieldsExcelCargo'] = this.cargo.errorFieldsExcelCargo;
      data['errorFieldsExcelCargo']['licensePlate'] = null;
    }
    const $updateCargoFeature = this.service.completeUpdateRequest(data, this.cargo).subscribe({
      next: (success) => { },
      error: (error) => console.error(error),
      complete: () => $updateCargoFeature.unsubscribe()
    });
  }

  private belowSicetac(cargo: Cargo, type?: string, params?: AssignVehicle) {
    const formatter = new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    });
    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.approvedBelowSicetac)) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      dialogConfig.disableClose = true;
      this.spinner.show();
      this.service.detailCargoByConsecutive(cargo.consecutive.toString()).subscribe(
        (data: any) => {
          this.spinner.hide();
          cargo = data;
          if (cargo.belowSicetac && !cargo.freightValueApprovedBelowSicetac) {
            const min = formatter.format(cargo.minimumApprovedValueSicetac);
            dialogConfig.data = {
              title: 'Desea aprobar el flete por debajo del SICETAC',
              description: `El flete mínimo es ${min}, por favor realice una bonificación para ajustarse al valor mínimo permitido`,
              btnDetailCargo: true,
              path: this.cargo.state === CargoStateEnum.REQUEST
                ? `service-request-form/menu-service/${this.cargo.consecutive}`
                : `cargo/detail/${this.cargo.consecutive}`,
              closeModal: true,
            };
            const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(result => {
              if (result && result.state) {
                this.cargoService.approvedBelowSiceTac(cargo.id).subscribe(
                  () => {
                    this.snackBarService.openSnackBar("Se aprobó con éxito", undefined, 'success');
                  },
                  (error) => {
                    this.snackBarService.openSnackBar("No se pudo aprobar", undefined, 'error');
                  }
                );
              } else {
                this.snackBarService.openSnackBar("No se pudo aprobar", undefined, 'error');
              }
            })
          }
        }
      );
    } else {
      if (cargo.belowSicetac && !cargo.freightValueApprovedBelowSicetac) {
        const min = formatter.format(cargo.minimumApprovedValueSicetac);
        this.snackBarService.openSnackBar(`El flete mínimo es ${min}, por favor realice una bonificación para ajustarse al valor mínimo permitido`, undefined, 'alert');
      }
    }
    if (type && type === 'turns') {
      this.onAssignVehicle.emit('asignado');
      this.spinner.hide();
      this.snackBarService.openSnackBar('Se ha asignado el vehículo ' + params.licensePlate + ' correctamente');
      this.router.navigate(['/cargo/list/scheduledLoads']);
    }
    else {
      this.dialogRef.close({ state: true })
      this.spinner.hide();
      this.snackBarService.openSnackBar('Se ha asignado el vehículo ' + params.licensePlate + ' correctamente');
    }
  }

  async getDetailUserByDocument(userDocument: string): Promise<User> {
    this.spinner.show();
    const user = await this.adminUsersService.getUsersDetailByDocument(userDocument).toPromise();
    this.spinner.hide();
    return user;
  }
}

import PlaceResult = google.maps.places.PlaceResult;
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { LastPointLocation, LastPointLocationModel } from 'src/app/core/interfaces/lastPointLocation';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { MatAutocompleteSelectedEvent, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { CargoTripAnomalyService } from '../cargo-trip-anomaly/cargo-trip-anomaly.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Utils } from 'src/app/core/resources/utils';
import { DatabaseReference } from '@angular/fire/database/interfaces';
import { CargoTrackingService } from '../cargo-tracking/cargo-tracking.service';
import { AuthService } from 'src/app/core/services/authentication.service';
import { GoogleService } from 'src/app/core/services/google.service';
import { Global } from 'src/app/core/resources/global';
import { Patterns } from 'src/app/core/resources/patterns';
import { MapsAPILoader } from '@agm/core';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import 'firebase/database';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { StandardMapComponent } from 'src/app/shared/standard-map/standard-map.component';
import { FirebaseDatabase } from "src/app/core/services/firebaseDatabase.service";
import { CargoTracking } from 'src/app/core/interfaces/cargoTracking.interface';
import { Subscription } from 'rxjs';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { EvidenceViewerComponent } from 'src/app/shared/evidence-viewer/evidence-viewer.component';
import { FileService } from 'src/app/shared/files/file.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { StorageEndpoints } from 'src/app/core/resources/storage-endpoints';
import { Fmt } from 'src/app/core/messages/fmt';
import { Anomaly } from 'src/app/core/interfaces/anomaly';
import { CargoManager } from 'src/app/core/managers/cargo.manager';

@Component({
  selector: 'app-cargo-location-report',
  templateUrl: './cargo-location-report.component.html',
  styleUrls: ['./cargo-location-report.component.scss'],
  providers: [LastPointLocationModel, FirebaseDatabase]
})
export class CargoLocationReportComponent implements OnInit {

  public cargo: Cargo;
  public reactiveForm: ReactiveForm;
  public listAnomalies: Anomaly[] = [];
  private refFirebaseActiveTracking: DatabaseReference;
  private refFirebaseActiveAnomalies: DatabaseReference;
  public polyline: Array<Object>;
  @ViewChild('inputLastPoint', { static: false }) inputLastPoint: ElementRef;
  @ViewChild(StandardMapComponent, { static: false }) standardMap: StandardMapComponent;
  public coordinates: FormControl = new FormControl('', Validators.required);
  coordinatesSub: Subscription;
  valid: boolean = false;
  clickedMap: boolean = false;
  coordinatesAddress: string = '';
  coordinatesCity: string = '';
  autocomplete: boolean = false;
  active: boolean = true;
  editReport: boolean = false;
  allAnomalies = [];
  indexReport = -1;
  isLastPointChanged: boolean = false;
  temperatureUnits: Array<any> = [];
  keyMarker: string = 'cargoReport';
  mapOptions: OptionsAutocomplete = {
    keyMarkerListener: this.keyMarker,
    clickMapIcon: this.global.pathMarkerDestination,
    initialClickMap: true,
  };
  refFirebaseActive: any;
  locationsCargos: Object = {};
  tempListLocations: Object = {};
  @ViewChild('documentFileInput', { static: false }) documentFileInput: ElementRef<HTMLInputElement>;
  filesToUpload: { file: File, url: string, type: 'image' | 'pdf' }[] = [];
  constructor(
    private lastPointLocationModel: LastPointLocationModel,
    private formBuilder: FormBuilder,
    private cargoTripAnomalyService: CargoTripAnomalyService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public dialogParams: { cargo: Cargo, report },
    public dialogRef: MatDialogRef<CargoLocationReportComponent>,
    private dialog: MatDialog,
    public utils: Utils,
    private cargoTrackingService: CargoTrackingService,
    private authService: AuthService,
    private googleService: GoogleService,
    private global: Global,
    public patterns: Patterns,
    private mapsAPILoader: MapsAPILoader,
    private snackBarService: SnackBarService,
    private firebaseDatabase: FirebaseDatabase,
    private fileService: FileService,
    private _angularFireStorage: AngularFireStorage,
    private cargoManager: CargoManager,
  ) { }

  ngOnInit() {

    this.temperatureUnits = [{
      name: 'Centigrados',
      unit: 'C',
    },
    {
      name: 'Fahrenheit',
      unit: 'F',
    }
    ]

    this.coordinatesSub = this.coordinates.valueChanges.subscribe(value => {
      this.clickedMap = false;
      this.coordinatesAddress = '';
      this.onAutocompleteSelected(value, null);
    }
    )
    this.validateDialogParams();
    this.initForm();
    this.getTripAnomaly();
    this.coordinatesSub = this.coordinates.valueChanges.subscribe(value => {
      this.clickedMap = false;
      this.coordinatesAddress = '';
      this.coordinatesCity = '';
      this.onAutocompleteSelected(value, null);
    })
    if (!this.utils.isEmpty(this.cargo)) {
      this.getDataTracking();
      this.refFirebaseActiveTracking = AuthService.fDatabase.ref(`cargo/${this.cargo.id}/tracking`);

      this.refFirebaseActiveTracking.limitToLast(1).on('value', trackingSnapshot => {
        const tracking = trackingSnapshot.val();
        if (tracking !== undefined) {
          const key = Object.keys(tracking)[0];
          const fingerprint = tracking[key].fingerprint;

          // Si la última trazabilidad fue realizada por GPS cargar la última dirección de trazabilidad.
          if (fingerprint && (fingerprint.userId.includes('SATRACK') || fingerprint.userId.includes('MONITORHUB'))) {
            const position = tracking[key].latLng;
            this.reactiveForm.form.get('location.lat').setValue(parseFloat(position.lat));
            this.reactiveForm.form.get('location.lng').setValue(parseFloat(position.lng));
            this.getCityAndAddress(position.lat, position.lng);
          }
        }
      });

      this.refFirebaseActiveAnomalies = AuthService.fDatabase.ref(`cargo/${this.cargo.id}/anomalies`);
      this.refFirebaseActiveAnomalies.on('value', resp => {
        let arrayRoute: any = null;
        if (resp) arrayRoute = this.utils.snapshotToArrayAnomalies(resp);
        if (arrayRoute && arrayRoute.length) {
          this.allAnomalies = arrayRoute;
          this.editReport && this.setReport();
        }
      }).apply(this);
    }
  }


  private getDataTracking(): void {
    this.firebaseDatabase.getOldDataRealTime(this.cargo.id)
      .then((data: CargoTracking) => {
        return data.listLocations;
      })
      .then((listOldLocations) => {
        this.refFirebaseActive = this.firebaseDatabase.getRefDatabase(`cargo/${this.cargo.id}/tracking`);
        this.firebaseDatabase.lisenerDataReference(this.refFirebaseActive)
          .then((data) => {
            const dataTracking = this.firebaseDatabase.getDataTrackingSnapshot(data);
            const listLocations = [...listOldLocations, ...dataTracking.listLocations];
            let path = listLocations;
            let end = listLocations[listLocations.length - 1];
            if (end) {
              end['showMarker'] = true;
              end['setCenter'] = true;
              end['zoom'] = 16;
            }
            if (listLocations.length && listLocations.length < 25) {
              this.tempListLocations[this.cargo.id] = { path, showPolyline: true };
              this.locationsCargos = this.tempListLocations;
              this.mapOptionsConfig({
                initialRealRoute: true,
                initialMarkers: true,
                polyline: this.locationsCargos,
                route: this.locationsCargos
              });
              this.standardMap.processAnyGoogleRoute(listLocations, 'listLocationsSimulated');
            } else {
              if (listLocations.length) {
                this.tempListLocations[this.cargo.id] = { path, showPolyline: true };
                this.locationsCargos = this.tempListLocations;
                this.mapOptionsConfig({
                  initialRealRoute: true,
                  initialMarkers: true,
                  polyline: this.locationsCargos,
                  route: this.locationsCargos
                });
              }
            }
          });
      });
  }

  mapOptionsConfig(options: OptionsAutocomplete) {
    this.mapOptions = { ...this.mapOptions, ...options };
  }

  ngOnDestroy() {
    this.refFirebaseActiveTracking.off();
    this.refFirebaseActiveAnomalies.off();
    if (this.coordinatesSub) this.coordinatesSub.unsubscribe();
  }

  private validateDialogParams(): void {
    if (!this.utils.isEmpty(this.dialogParams) && !this.utils.isEmpty(this.dialogParams.cargo)) {
      this.cargo = this.dialogParams.cargo;
    }
    if (!this.utils.isEmpty(this.dialogParams) && !this.utils.isEmpty(this.dialogParams.report)) {
      this.editReport = true;
    }
  }

  private initForm(): void {
    this.reactiveForm = new ReactiveForm(
      this.formBuilder,
      this.lastPointLocationModel.modelLastPointLocation
    );
    this.reactiveForm.setValidatorsForm(
      this.lastPointLocationModel.validatorsModelLastPointLocation,
      this.reactiveForm.form
    );
    if (!this.editReport) {
      this.reactiveForm.form.get('fingerprint.userId').setValue(this.authService.getUserSession().information.document);
      this.reactiveForm.form.get('fingerprint.userName').setValue(this.authService.getUserSession().information.name);
      this.reactiveForm.form.get('fingerprint.date').setValue(DateManager.dateToString(new Date(), 'YYYY-MM-DD HH:mm ZZ'));
    }
    if (this.cargo.cargoFeature.vehicleType.quality) this.reactiveForm.form.get('temperature.value').setValidators(Validators.required);
  }

  public setReport() {
    let index;
    switch (this.dialogParams.report.parent) {
      case "lastPointLocation":
        if (this.allAnomalies[this.allAnomalies.length - 1]) {
          this.indexReport = this.allAnomalies.length - 1
        }
        break;
      case "tripAnomalies":
        index = this.allAnomalies.findIndex((anom) => {
          if (this.dialogParams.report.observation) {
            return ((anom.anomaly && anom.anomaly === this.dialogParams.report.name) || (anom.name && anom.name === this.dialogParams.report.name))
              && anom.observation === this.dialogParams.report.observation && anom.fingerprint && anom.fingerprint.date
              && DateManager.stringToDate(anom.fingerprint.date).getTime() === DateManager.stringToDate(this.dialogParams.report.date).getTime()
          }
          else {
            return ((anom.anomaly && anom.anomaly === this.dialogParams.report.name) || (anom.name && anom.name === this.dialogParams.report.name))
              && anom.fingerprint && anom.fingerprint.date && DateManager.stringToDate(anom.fingerprint.date).getTime() === DateManager.stringToDate(this.dialogParams.report.date).getTime()
          }
        })
        if (index !== -1) this.indexReport = index;
        break;
      default:
        index = this.dialogParams.report.index;
        if (index) this.indexReport = index;
        break;
    }
    if (this.indexReport !== -1 && this.allAnomalies[this.indexReport]) {
      this.reactiveForm.form.get('location.lat').setValue(this.allAnomalies[this.indexReport].lat);
      this.reactiveForm.form.get('location.lng').setValue(this.allAnomalies[this.indexReport].lng);
      this.reactiveForm.form.get('fingerprint.userId').setValue(this.allAnomalies[this.indexReport].fingerprint.userId);
      this.reactiveForm.form.get('fingerprint.userName').setValue(this.allAnomalies[this.indexReport].fingerprint.userName);
      this.reactiveForm.form.get('fingerprint.date').setValue(this.allAnomalies[this.indexReport].fingerprint.date);
      if (this.dialogParams.report.address) {
        this.reactiveForm.form.get('address').setValue(this.dialogParams.report.address);
        this.coordinates.setValue(this.dialogParams.report.address);
        this.reactiveForm.form.get('city').setValue(this.dialogParams.report.city);
      } else {
        this.getCityAndAddress(parseFloat(this.reactiveForm.form.get('location.lat').value), parseFloat(this.reactiveForm.form.get('location.lng').value));
        this.coordinates.setValue(this.coordinatesAddress);
      }
      if (this.dialogParams.report.temperature) {
        this.reactiveForm.form.get('temperature').patchValue(this.dialogParams.report.temperature);
      }
      if (this.dialogParams.report.evidences)
        this.filesToUpload = this.dialogParams.report.evidences.map(async evidence => ({ file: null, url: await this.getDownloadURL(evidence), type: evidence.split('.').reverse()[0] === 'pdf' ? 'pdf' : 'image' }));

      this.reactiveForm.form.get('name').setValue(this.dialogParams.report.name);
      this.dialogParams.report.observation && this.reactiveForm.form.get('observation').setValue(this.dialogParams.report.observation);
      this.allAnomalies[this.indexReport].observation && this.reactiveForm.form.get('observation').setValue(this.allAnomalies[this.indexReport].observation);
    }
    else {
      this.dialogRef.close({
        state: false
      });
    }
  }

  getDownloadURL(fileName: string): Promise<string> {
    if (fileName) {
      let pathReference = this._angularFireStorage.ref(`cargos/${this.cargo.id}/location-report-evidences/${fileName}`);
      return pathReference.getDownloadURL().toPromise();
    }
    return null;
  }

  public onAutocompleteSelected(coordinates?: any, result?: PlaceResult): void {
    if (this.patterns.COORDINATES_FORMAT.test(coordinates)) {
      const [lat, lng] = coordinates.split(',');
      this.reactiveForm.form.get('location.lat').setValue(parseFloat(lat));
      this.reactiveForm.form.get('location.lng').setValue(parseFloat(lng));
      this.getCityAndAddress(parseFloat(lat), parseFloat(lng));
      this.createMarker(parseFloat(lat), parseFloat(lng))
    }

    else {
      if (result && result.geometry) {
        this.reactiveForm.form.get('location.lat').setValue(result.geometry.location.lat());
        this.reactiveForm.form.get('location.lng').setValue(result.geometry.location.lng());
      }
      if (result && result.formatted_address) {
        this.reactiveForm.form.get('address').setValue(result.formatted_address);
        this.coordinates.setValue(result.formatted_address)
      }

      if (result && result.address_components.length > 0) {
        this.reactiveForm.form.get('city').setValue(result.address_components[result.address_components.length - 3].long_name);
      }

      if (result) this.createMarker(result.geometry.location.lat(), result.geometry.location.lng())

    }
  }

  createMarker(lat: number, lng: number) {
    this.standardMap.removeMarker(this.keyMarker);
    this.standardMap.createMarker(this.keyMarker, lat, lng, null, this.global.pathMarkerDestination);
    this.standardMap.setCenterMap(lat, lng, 16);
  }

  private getTripAnomaly(): void {
    this.spinner.show();
    this.cargoTripAnomalyService.getTripAnomaly().toPromise()
      .then((success: Anomaly[]) => {
        this.listAnomalies = success;
      })
      .catch((error) => {
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  public onSelectAnomaly($event: MatAutocompleteSelectedEvent): void {
    //this.observation.setValue(null);
  }

  onSelectValue($event: MatAutocompleteSelectedEvent) {
  }

  // onSelectValue($event: MatAutocompleteSelectedEvent) {
  //   this.vehicleType.emit(JSON.stringify($event.option.value));
  // }

  private getAnomalyName(): { name: string, observation: string } {
    const data = {
      observation: '',
      name: ''
    };
    data.name = this.reactiveForm.form.get('name').value;
    data.observation = this.reactiveForm.form.get('observation').value;
    return data;
  }

  handleFileInput(e: Event & { target: { files: FileList } }) {
    if (!e || !e.target || !e.target.files || !e.target.files.length) return;
    const formatsAllowed = ['pdf', 'jpeg', 'jpg', 'png'];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const extension = file.name.split('.').reverse()[0];
      if (!formatsAllowed.includes(extension.toLocaleLowerCase()) || !file.type) {
        this.snackBarService.openSnackBar(`El archivo ${i + 1} no es compatible con formatos ${formatsAllowed.join(', ')}`, 'x', 'error');
        continue;
      }
      this.addFile(file, extension);
    }
    if (this.documentFileInput && this.documentFileInput.nativeElement)
      this.documentFileInput.nativeElement.value = '';
  }

  private addFile(file: File, extension: string) {
    const fileToUpload: { file: File, url: string, type: 'image' | 'pdf' } = {
      file,
      url: URL.createObjectURL(file),
      type: extension === 'pdf' ? 'pdf' : 'image',
    };
    this.filesToUpload.push(fileToUpload);
  }

  deleteFile(index: number) {
    this.filesToUpload.splice(index, 1);
  }

  openImageNewTab(index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { evidences: this.filesToUpload.map(file => ({ url: file.url, type: file.type })), index };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialog.open(EvidenceViewerComponent, dialogConfig);
  }

  public onSubmit(coordinatesSetted = false): void {
    if ((this.coordinates.value === "" || this.coordinates.value === null) && !this.clickedMap && !this.autocomplete) {
      this.reactiveForm.form.get('address').setValue(null)
    }
    if (this.reactiveForm.form.valid) {

      if (this.cargo.requiresPreoperational && this.reactiveForm.form.get('name').value === 'Reiniciando ruta') {
        this.cargoManager.modalPreoperationalInspection(this.cargo).then((result) => {
          if (['Inspected'].includes(result)) !this.editReport ? this.registerLocation() : this.updateLocation();
          else this.snackBarService.openSnackBar('Se debe realizar la inspeccionar del vehículo para poder Reiniciar el servicio', undefined, 'alert');
        });
      } else {
        !this.editReport ? this.registerLocation() : this.updateLocation();
      }

    } else if (this.coordinatesAddress && this.coordinatesCity && !coordinatesSetted) {
      this.reactiveForm.form.get('address').setValue(this.coordinatesAddress);
      this.reactiveForm.form.get('city').setValue(this.coordinatesCity);
      this.coordinates.setValue(this.coordinatesAddress);
      this.onSubmit(true);
    } else {
      if (this.utils.errorMessagesCustomized(this.reactiveForm.form.controls.name, 'reporte')) { }
      else if (this.utils.errorMessagesCustomized(this.reactiveForm.form.controls.address, 'dirección')) { }
      else if (this.utils.errorMessagesCustomized(this.reactiveForm.form.controls.temperature.controls.value, 'temperatura')) { }
      else this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
    }
  }

  public lastPointChanged(changed: boolean) {
    if (changed) this.isLastPointChanged = true;
  }

  public onCancel(): void {
    this.isLastPointChanged
      ? this.dialogRef.close({
        state: true,
      })
      : this.dialogRef.close();
  }

  private async registerLocation(): Promise<void> {
    const data: LastPointLocation = this.reactiveForm.form.value;
    delete data.name;

    if (!this.cargo.cargoFeature.vehicleType.quality) delete data.temperature;
    this.spinner.show();
    data.name = this.getAnomalyName().name;
    data.observation = this.getAnomalyName().observation;
    const evidences = await this.uploadFiles();
    if (evidences.length !== this.filesToUpload.length) {
      this.spinner.hide();
      return;
    }
    data['images'] = evidences;
    this.cargoTrackingService.registerLocation(this.cargo.id, data).subscribe(
      (success) => {
        this.refFirebaseActiveTracking.push({
          latLng: {
            lat: this.reactiveForm.form.get('location.lat').value,
            lng: this.reactiveForm.form.get('location.lng').value,
          },
          fingerprint: {
            userId: this.authService.getUserSession().information.document,
            userName: this.authService.getUserSession().information.name,
            date: DateManager.dateToString(new Date(), 'YYYY-MM-DD HH:mm ZZ')
          },
        });
        this.refFirebaseActiveAnomalies.push({
          anomaly: this.getAnomalyName().name,
          fingerprint: {
            userId: this.authService.getUserSession().information.document,
            userName: this.authService.getUserSession().information.name,
            date: DateManager.dateToString(new Date(), 'YYYY-MM-DD HH:mm ZZ')
          },
          latLng: {
            lat: this.reactiveForm.form.get('location.lat').value,
            lng: this.reactiveForm.form.get('location.lng').value,
          },
          observation: this.reactiveForm.form.get('observation').value,
          fromWeb: true,
        })
        if (this.cargo.cargoFeature.vehicleType.quality) {
          this.refFirebaseActiveTracking.push({
            temperature: {
              value: this.reactiveForm.form.get('temperature.value').value,
              unit: this.reactiveForm.form.get('temperature.unit').value
            }
          })
          this.refFirebaseActiveAnomalies.push({
            temperature: {
              value: this.reactiveForm.form.get('temperature.value').value,
              unit: this.reactiveForm.form.get('temperature.unit').value
            }
          })
        }
        this.spinner.hide();
        this.dialogRef.close({
          state: true
        });
      },
      (error) => {
        if (error.error && error.error.message) {
          this.snackBarService.openSnackBar(error.error.message, undefined, 'error');
        } else {
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        }

        this.spinner.hide();
      });
  }

  private async updateLocation() {
    this.spinner.show();
    const data = this.reactiveForm.form.value;
    const evidences = await this.uploadFiles();
    if (evidences.length !== this.filesToUpload.length)
      return this.spinner.hide();

    data['images'] = evidences;
    this.cargoTripAnomalyService.updateTripAnomaly(data, this.cargo.id, this.indexReport).subscribe(
      (success: Cargo) => {
        if (success) {
          this.spinner.hide();
          this.dialogRef.close({
            state: true,
            cargo: success
          });
        }

      },
      (error) => {
        if (error && error.error && error.error.message) {
          this.snackBarService.openSnackBar(error.error.meesage, undefined, 'error');
        }
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        this.spinner.hide();
        this.dialogRef.close();
      }
    )
  }

  private async uploadFiles(): Promise<string[]> {
    const path = Fmt.string(StorageEndpoints.cargoLocationReportEvidences, this.cargo.id);
    try {
      let filesWithError = [];
      for (let i = 0; i < this.filesToUpload.length; i++) {
        const file = this.filesToUpload[i].file;
        if (!file) continue;
        const success = await this.fileService.loadFileToStorage(path, file.name, file)
        if (!success) filesWithError.push(file.name);
      }
      if (!filesWithError.length) return this.filesToUpload.map(file => file.file.name);
      this.snackBarService.openSnackBar(
        `Ocurrió un error al cargar ${filesWithError.length > 1 ? 'los archivos' : 'el archivo'} ${filesWithError.join(', ')}`,
        undefined,
        'error'
      );
      return [];
    } catch (error) {
      this.snackBarService.openSnackBar('Ocurrió un error al cargar los archivos', undefined, 'error');
      return [];
    }
  }

  getCityAndAddress(lat, lng) {
    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(lat, lng);
    geocoder.geocode({ 'location': latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          // results[0] contiene la dirección completa
          this.coordinatesAddress = results[0].formatted_address;
          let city = []
          city = this.coordinatesAddress.split(',');
          this.reactiveForm.form.get('address').setValue(this.coordinatesAddress);
          this.coordinates.setValue(this.coordinatesAddress);
          if (city.length > 0) {
            if (city.length > 1) {
              this.reactiveForm.form.get('city').setValue(city[1].trim());
              this.coordinatesCity = city[1].trim();
            } else {
              this.reactiveForm.form.get('city').setValue(city[0].trim());
              this.coordinatesCity = city[0].trim();
            }
          }
        }
      } else {
        this.snackBarService.openSnackBar('Error al geolocalizar: ' + status, undefined, 'error')
      }
    });
  }

  async onLoadMap($event) {
    let address = await this.transformCoordinatesToAddress($event.latLng.lat(), $event.latLng.lng());
    if (address) {
      this.clickedMap = true;
      this.coordinates.setValue(address);
      this.reactiveForm.form.get('location.lat').setValue($event.latLng.lat());
      this.reactiveForm.form.get('location.lng').setValue($event.latLng.lng());
      this.reactiveForm.form.get('address').setValue(address);
      this.inputLastPoint.nativeElement.value = address;
      const cityList = address.split(',');
      if (cityList.length > 0) {
        if (cityList.length > 1) {
          this.reactiveForm.form.get('city').setValue(cityList[1]);
        } else {
          this.reactiveForm.form.get('city').setValue(cityList[0]);
        }
      }
    }

  }

  async transformCoordinatesToAddress(lat: number, lng: number): Promise<string> {
    let geocoderService = new google.maps.Geocoder;
    let latlng = { lat: lat, lng: lng };
    let address = '';
    this.spinner.show();
    await geocoderService.geocode({ 'location': latlng }, (results, status) => {
      if (results.length) {
        this.spinner.hide();
        address = results[0].formatted_address;
      } else this.spinner.hide();
    });
    return address;
  }
}

<form class="consignment-form py-4 px-0 px-sm-3 px-md-5 w-100 row position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <h4 class="col-12 mt-3 mb-5 text-bold text-center">
        Edición Remesa
        <span class="color-secondary-dark">{{consignmentId}}</span>
    </h4>
    <app-consignment-card class="col-12" [form]="consignmentForm" [title]="'Remesa ' + (consignmentId)"
        [formValidate]="formValidate" [senderCityOptions]="senderCityOptions" *ngIf="consignmentForm"
        [recipientCityOptions]="recipientCityOptions" [canDeleteConsignment]="false">
    </app-consignment-card>
    <!-- Submit button -->
    <div class="button-container my-3  col-12">
        <button type="button" mat-raised-button color="primary" class="primary-secondary bg-color-primary"
            (click)="dialogRef.close()">Cancelar</button>
        <button mat-raised-button color="primary" type="button" (click)="onSubmit()"
            class="bg-color-primary">Guardar</button>
    </div>
</form>
import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Company } from 'src/app/core/interfaces/company';
import { GuideNovelty } from 'src/app/core/interfaces/guideNovelty';
import { Permission } from 'src/app/core/resources/permission';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CompaniesService } from '../list-companies.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AccountService } from 'src/app/modules/account/account.service';
import { AuthService } from 'src/app/core/services/authentication.service';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { GuideNoveltyFormComponent } from '../guide-novelty-form/guide-novelty-form.component';
import { FormControl } from '@angular/forms';
import { PermissionRole } from 'src/app/core/resources/permission-role';

@Component({
  selector: 'app-guide-novelties',
  templateUrl: './guide-novelties.component.html',
  styleUrls: ['./guide-novelties.component.scss']
})
export class GuideNoveltiesComponent implements OnInit {

  permission = Permission;
  @Input() company: Company;
  noveltyList: GuideNovelty[] = [];
  noveltyTypes: string[] = ["Bloqueante", "No Bloqueante", "Todas"];
  noveltyFilter: FormControl = new FormControl("Todas");

  constructor(
    private snackBarService: SnackBarService,
    private permissionRole: PermissionRole,
    private spinner: NgxSpinnerService,
    private accountService: AccountService,
    private authService: AuthService,
    private listCompaniesService: CompaniesService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getNoveltyList();
  }

  private getNoveltyList() {
    this.spinner.show();
    this.listCompaniesService.getGuideNovelties(this.company.companyId).subscribe(
      (novelties: GuideNovelty[]) => {
        this.noveltyList = novelties && novelties.length ? novelties : [];
        this.spinner.hide();
      }, (error) => {
        this.noveltyList = [];
        this.spinner.hide();
      });
  }


  get filteredNovelties(): GuideNovelty[] {
    if (this.noveltyFilter.value === "Todas") return this.noveltyList;
    return this.noveltyList.filter(novelty => {
      const isBlocked = this.noveltyFilter.value === "Bloqueante";
      const isNotBlocked = this.noveltyFilter.value === "No Bloqueante";
      return novelty && (isBlocked && novelty.type === 'Anomaly') || (isNotBlocked && novelty.type === 'Normal');
    });
  }

  createNovelty() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Crear novedad',
      isPrincipalCompany: this.isPrincipalCompany,
      noveltyList: this.noveltyList,
      confirmBtn: 'Crear',
      companyId: this.company.companyId,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(GuideNoveltyFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state)
        this.getNoveltyList();
    });
  }

  editNovelty(novelty: GuideNovelty) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      novelty,
      isPrincipalCompany: this.isPrincipalCompany,
      companyId: this.company.companyId,
      noveltyList: this.noveltyList,
      title: 'Editar novedad',
      confirmBtn: 'Guardar'
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(GuideNoveltyFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state)
        this.getNoveltyList();
    });
  }

  get canEditNovelty(): boolean {
    return this.permissionRole.hasPermission(this.permission.guide.module, this.permission.guide.editGuideNovelties);
  }

  get isPrincipalCompany(): boolean {
    const listCompanies = this.accountService.listRegisterCompanies();
    return !!(listCompanies.length && this.company && this.company.companyId &&
      listCompanies.some(company => company.nit === this.company.companyId) &&
      this.authService.getCompany() && this.authService.getCompany().companyId === this.company.companyId);
  }

}

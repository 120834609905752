import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, Form, FormArray, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RouteScenario } from 'src/app/core/interfaces/routeScenario';
import { Order } from 'src/app/core/interfaces/scenario';
import { VehicleCapacity } from 'src/app/core/interfaces/storedFleet';
import { Fmt } from 'src/app/core/messages/fmt';
import { RoutingMessages } from 'src/app/core/messages/routing-messages.enum';
import { Global } from 'src/app/core/resources/global';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-routing-change-orders',
  templateUrl: './routing-change-orders.component.html',
  styleUrls: ['./routing-change-orders.component.scss']
})
export class RoutingChangeOrdersComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() refreshMap: EventEmitter<any> = new EventEmitter();
  @Output() emitNextStep: EventEmitter<number> = new EventEmitter();

  orderSelected: number = 0;
  $ordersSub: Subscription;
  $selectableVehicles: Subscription;
  initialOrders: RouteScenario[] = [];
  selectableVehicles: VehicleCapacity[] = [];
  changeVehiclesMap: { id: number, vehicles: string[] }[] = [];
  constructor(
    public utils: Utils,
    public snackBarService: SnackBarService,
    private global: Global,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (this.form && this.orders && this.orders.value && this.orders.value.length) {
      this.showLocations(0);
      this.initialOrders = [...this.orders.value];
    }
    if (this.form && this.form.get('selectableVehicles') && this.form.get('selectableVehicles').value)
      this.selectableVehicles = Object.values(this.form.get('selectableVehicles').value)

    this.setSubscriptions();
  }

  /**
  * @description Sets a subscriptions to form's orders and selectableVehicles to update local variables and showLocations
  */
  private setSubscriptions() {
    this.$ordersSub = this.form.get('orders').valueChanges.subscribe((orders: RouteScenario[]) => {
      if (orders && orders.length && orders.length !== this.initialOrders.length) {
        this.showLocations(0);
        this.initialOrders = [...orders];
      }
    });
    this.$selectableVehicles = this.form.get('selectableVehicles').valueChanges.subscribe((vehicles) => {
      this.selectableVehicles = Object.values(vehicles);
      this.changeVehicleProtocol();
      this.$selectableVehicles.unsubscribe();
    });
  }

  /**
  * @description Verifies if every order can be assigned to every order
  */
  private changeVehicleProtocol() {
    const orders: RouteScenario[] = this.orders.value;
    orders.forEach(order => {
      let orderVehicles = { id: order['id'], vehicles: [] };
      let vehicles = [];

      const orderCapacities: number[] = order['capacities'] || [0, 0, 0];
      const vehicleCapacities = ['capacity1', 'capacity2', 'capacity3'];
      const validateCapacities = [false, false, false];

      let orderVehicle: VehicleCapacity = null;
      //The order has a vehicle assigned
      if (order.vehicle) {
        orderVehicle = this.selectableVehicles.find(veh => veh.vehiclePlate === order.vehicle) || null;
        vehicles.push(order.vehicle);
        vehicleCapacities.forEach((vehicleCapacity, index) => {
          validateCapacities[index] = !!(orderCapacities[index] && orderVehicle && orderVehicle[vehicleCapacity]
            && orderVehicle[vehicleCapacity] >= orderCapacities[index]);
        });
      }
      //No vehicle assigned
      else {
        vehicleCapacities.forEach((vehicleCapacity, index) => {
          validateCapacities[index] = !!(orderCapacities[index] && this.selectableVehicles.some(veh =>
            veh[vehicleCapacity] && veh[vehicleCapacity] >= orderCapacities[index])
          );
        });
      }

      this.selectableVehicles.forEach(vehicle => {
        let canBeAssigned = false;
        if (orderVehicle) {
          //If is a vehicle swap
          if (orders && orders.some(order => order.vehicle && order.vehicle === vehicle.vehiclePlate)) {
            const otherOrder = orders.find(order => order.vehicle && order.vehicle === vehicle.vehiclePlate);
            canBeAssigned = validateCapacities.some(validate => !!validate) && validateCapacities.every((validate, index) => {
              return !validate || (validate && vehicle[vehicleCapacities[index]] && vehicle[vehicleCapacities[index]] >= orderCapacities[index]
                && orderVehicle[vehicleCapacities[index]] && orderVehicle[vehicleCapacities[index]] >= otherOrder['capacities'][index]
              )
            });
          }
          //Assign a non assigned vehicle
          else canBeAssigned = validateCapacities.some(validate => !!validate) && validateCapacities.every((validate, index) => {
            return !validate || (validate && vehicle[vehicleCapacities[index]] && vehicle[vehicleCapacities[index]] >= orderCapacities[index])
          });
        }
        //No vehicle assigned
        else if (this.selectableVehicles.length && this.selectableVehicles.some(vehi => !!vehi.capacity1 || !!vehi.capacity2 || !!vehi.capacity3)) {
          canBeAssigned = validateCapacities.some(validate => !!validate) && validateCapacities.every((validate, index) => {
            return !validate || (validate && vehicle[vehicleCapacities[index]] && vehicle[vehicleCapacities[index]] <= orderCapacities[index])
          });
        }

        if (canBeAssigned) vehicles.push(vehicle.vehiclePlate);
      });
      orderVehicles.vehicles = [...new Set(vehicles)];
      this.changeVehiclesMap.push(orderVehicles);
    });
  }

  /**
  * @description Verifies if the form is valid to continue to next step
  */
  nextStep(): void {
    let hasErrors = false;
    if (!this.orders || !this.orders.controls) {
      this.snackBarService.openSnackBar(RoutingMessages.NO_ORDERS, undefined, 'alert');
      return;
    }
    this.orders.controls.forEach((order, i) => {
      if (!hasErrors && order.invalid) {
        this.snackBarService.openSnackBar(Fmt.string(RoutingMessages.NO_VEHICLE_ASSIGNED, i + 1), undefined, 'alert');
        hasErrors = true;
      }
    })
    if (hasErrors) return;
    this.hideLocations();
    this.emitNextStep.emit(2);
  }

  /**
  * @description Navigates to last step
  */
  goBack(): void {
    this.router.navigate(['routing/route-list']);
  }

  /**
  * @returns {FormArray} returns the form's orders as formarray if exists, otherwise null
  * @description Gets the form's orders as formarray
  */
  get orders(): FormArray {
    return this.form && this.form.get('orders') ? this.form.get('orders') as FormArray : null;
  }

  /**
  * @param {number} index is the order's index to get its addresses
  * @returns {{name: string, lat:number, lng: number}[]} returns an array of addresses with name, lat and lng
  * @description Gets the order addresses as an array of name, lat and lng
  */
  getAddresses(index: number): { name: string, lat: number, lng: number }[] {
    try {
      const addressesArr = [];
      if (this.orders && this.orders.at(index) && this.orders.at(index).get('trips') &&
        this.orders.at(index).get('trips').value && this.orders.at(index).get('trips').value.length)
        this.orders.at(index).value.trips.forEach(trip => {
          if (trip && trip.results && trip.results.length)
            trip.results.forEach(result => {
              if (result.stop && result.stop.address && result.stop.lat && result.stop.lng)
                addressesArr.push({ name: result.stop.address, lat: result.stop.lat, lng: result.stop.lng })
            })
        })
      return addressesArr;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  /**
  * @param {RouteScenario} order is the order to check
  * @returns {string[]} returns an array of addresses names
  * @description Gets the order addresses as an array with name
  */
  getOrderAddresses(order: RouteScenario): string[] {
    let addresses: string[] = [];
    if (order.trips && order.trips.length)
      order.trips.forEach(trip => {
        if (trip.results && trip.results.length)
          trip.results.forEach(result => {
            if (result && result.stop && result.stop.address)
              addresses.push(result.stop.address);
          })
      })
    return addresses;
  }

  /**
  * @param {number} index is the order's index selected
  * @description If the index is different to orderSelected, updates it and shows the locations of the selected order
  */
  onOpen(index: number) {
    if (index !== this.orderSelected) {
      this.showLocations(index);
      this.orderSelected = index;
    }
  }

  /**
  * @param {number} orderIndex is the order's index
  * @description Creates an structure to show the markers and polyline and emits it to the parent
  */
  private showLocations(orderIndex: number): void {
    let path = [];
    let addresses = this.getAddresses(orderIndex);
    addresses.forEach((address, index) => {
      if (address && address.lat && address.lng) {
        const isLast = index === addresses.length - 1;
        let point = {
          key: address.name,
          lat: address.lat,
          lng: address.lng,
          icon: '/assets/svg/icons/tl_ico__pin_google_maps_trip_stop.svg',
          contentInfoWindow: `<b>Dirección:</b> ${address.name}<br/>`,
          showMarker: !isLast,
          labelIcon: `${index + 1}`
        };
        path.push(point);
      }
    })
    this.refreshMap.emit([{ 'route': { path, showPolyline: true } }, true]);
  }

  /**
  * @description Emits an empty array to parent to hide the markers and polyline
  */
  private hideLocations(): void {
    this.refreshMap.emit([[], false]);
  }

  /**
  * @param {number} index is the order's index to update its vehicle
  * @param {MatSelectChange} $event is the vehicle selected 
  * @description Exchanges the vehicle selected with the order who used to have that vehicle
  */
  onChangeVehicle(index: number, $event: MatSelectChange) {
    if (!this.orders || !this.orders.value) return;
    const currentOrders = this.utils.clone(this.orders.value);
    const indexTarget = this.initialOrders.findIndex(order => order.vehicle === $event.value);
    if (indexTarget !== -1) {
      this.orders.at(indexTarget).get('vehicle').setValue(this.initialOrders[index]['vehicle']);
      this.initialOrders[indexTarget]['vehicle'] = currentOrders[indexTarget]['vehicle'];
    }
    this.initialOrders[index]['vehicle'] = $event.value;
  }

  /**
  * @param {VehicleCapacity} vehicle is the vehicle to check its available
  * @param {RouteScenario} order is the order to check 
  * @returns {boolean} returns true if the vehicle can be assigned to the order, otherwise false
  * @description Verifies if the vehicle has the capacities enough to be assigned to the order
  */
  canAssignVehicle(vehicle: VehicleCapacity, order: RouteScenario): boolean {
    if (!this.utils.isDefined(order['id'])) return false;
    return this.changeVehiclesMap.some(orderMap => orderMap.id === order['id'] && orderMap.vehicles.includes(vehicle.vehiclePlate));
  }

  /**
  * @description Unsubscribes of every open subscriptions
  */
  ngOnDestroy() {
    if (this.$ordersSub) this.$ordersSub.unsubscribe();
    if (this.$selectableVehicles) this.$selectableVehicles.unsubscribe();
  }
}
<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" class="row w-100 pb-3">
  <!-- Valor de la tarifa-->
  <div class="col-md-6">
    <mat-form-field appearance="standard">
      <mat-label>
        Valor de la tarifa
        <span class="optional">(Valor a pagar por el cliente)</span>
        <span class="asterisk">*</span>
      </mat-label>
      <input matInput type="text" currencyMask min="0" placeholder="$000.000" formControlName="rate">
      <mat-error *ngIf="form.get('rate').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="form.get('rate').errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'tarifa', hasPermissionNoMinimumUtility?0:1) }}
      </mat-error>
    </mat-form-field>
  </div>
  <!-- Valor del flete -->
  <div class="col-md-6">
    <mat-form-field appearance="standard">
      <mat-label>
        Valor del flete
        <span class="optional">(Valor a pagar al conductor)</span>
        <span class="asterisk">*</span>
      </mat-label>
      <input matInput type="text" currencyMask min="0" placeholder="$000.000" formControlName="freightCost">
      <mat-error *ngIf="form.get('freightCost').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="form.get('freightCost').errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'flete', hasPermissionNoMinimumUtility?0:1) }}
      </mat-error>
    </mat-form-field>
  </div>
  <!-- Porcentaje del anticipo -->
  <div class="col-md-6 col-lg-3">
    <mat-form-field appearance="standard">
      <mat-label>Porcentaje de anticipo<span class="asterisk">*</span></mat-label>
      <input matInput type="text" currencyMask maskId="advancePercentage" formControlName="advancePercentage">
      <i class="fas fa-info-circle fa-lg color-secondary-dark" matSuffix
        [matTooltip]="messageAdvancePercentageAllowed"></i>
      <mat-error *ngIf="form.get('advancePercentage').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="form.get('advancePercentage').errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'porcentaje', 0) }}
      </mat-error>
      <mat-error *ngIf="form.get('advancePercentage').errors?.max">
        {{ utils.giveMessageError('MAX_VALUE_EXCEED', 'porcentaje', MaxPercentage) }}
      </mat-error>
    </mat-form-field>
  </div>
  <!-- Valor del anticipo -->
  <div class="col-md-6 col-lg-3">
    <mat-form-field appearance="standard">
      <mat-label>
        Valor del anticipo
        <span class="asterisk">*</span>
      </mat-label>
      <input matInput type="text" currencyMask maskId="valueAdvance" formControlName="valueAdvance">
      <i class="fas fa-info-circle fa-lg color-secondary-dark" matSuffix
        [matTooltip]="messageAdvancePercentageAllowed"></i>
      <mat-error *ngIf="form.get('valueAdvance').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="form.get('valueAdvance').errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'porcentaje', 0) }}
      </mat-error>
      <mat-error *ngIf="form.get('valueAdvance').hasError('maxValueExceeded')">
        No puedes ingresar un valor de anticipo mayor a: {{ form.get('valueAdvance').errors?.maxValueExceeded?.maxValue }}
      </mat-error>
    </mat-form-field>
  </div>
  <!-- Tiempo de pago del saldo (en días) -->
  <div class="col-md-6 col-lg-3">
    <mat-form-field appearance="standard">
      <mat-label>Tiempo de pago del saldo<span class="asterisk">*</span></mat-label>
      <mat-select formControlName="paymentTime">
        <mat-option [value]="8">
          8 días
        </mat-option>
        <mat-option [value]="15">
          15 días
        </mat-option>
        <mat-option [value]="30">
          30 días
        </mat-option>
        <mat-option [value]="60">
          60 días
        </mat-option>
        <mat-option [value]="90">
          90 días
        </mat-option>
        <mat-option [value]="-1">
          Otro
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-6 col-lg-3" *ngIf="!paymentTimeValues.includes(form.get('paymentTime').value)">
    <mat-form-field appearance="standard">
      <mat-label>Otro valor (en días)<span class="asterisk">*</span></mat-label>
      <input matInput type="number" min="1" step="1" [formControl]="otherFreightCost">
      <mat-error *ngIf="otherFreightCost.errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="otherFreightCost.errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'valor', 1) }}
      </mat-error>
    </mat-form-field>
  </div>
</form>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Utils } from 'src/app/core/resources/utils';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { MenuServiceService } from '../../menu-service/menu-service.service';
import { MatSelectChange } from '@angular/material/select';
import { ModelCargo } from 'src/app/modules/cargo/manual-creation-cargo/resources/modelCargo';
import dataTypesMerchandiseJson from 'src/app/core/resources/JsonFile1572315390613.json';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Router } from '@angular/router';
import { Console } from 'console';
import { Global } from 'src/app/core/resources/global';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Patterns } from 'src/app/core/resources/patterns';
import { Fmt } from 'src/app/core/messages/fmt';
import { RiskProfile } from 'src/app/core/interfaces/riskProfile';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { CalculatorMeasurementsComponent } from 'src/app/modules/cargo/manual-creation-cargo/components/calculator-measurements/calculatorMeasurements.component';
import { Subscription } from 'rxjs';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { Catalog } from 'src/app/core/interfaces/catalog';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { VehicleCargo } from 'src/app/core/interfaces/vehicle-cargo';

//TODO: Dialogo de agregar caracteristicas de carga
@Component({
  selector: 'app-load-characteristics-form',
  templateUrl: './load-characteristics-form.component.html',
  styleUrls: ['./load-characteristics-form.component.scss'],
  providers: [ModelCargo]
})
export class LoadCharacteristicsFormComponent implements OnInit, OnDestroy {
  permission = Permission;
  //Risk profile
  riskProfileControl = new FormControl(null, [Validators.required]);
  riskProfiles: RiskProfile[] = this.global.riskProfiles as RiskProfile[];
  //Container
  container = new FormControl(null, [Validators.pattern(this.patterns.CONTAINER_FORMAT_FULL.source)]);
  seal = new FormControl(null);
  containerExpirationDate = new FormControl(null);
  booking = new FormControl(null);
  //Load characteristics
  readonly minAmount = 0;
  minQuantity = 1;
  readonly minTotalWeigth = 0;
  maxTotalWeigth = AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG;
  amountControl = new FormControl(0, [Validators.required, Validators.min(this.minAmount)]);
  quantityControl = new FormControl(1, [Validators.required, Validators.min(this.minQuantity)]);
  totalWeigthControl = new FormControl(0, [Validators.required, Validators.min(this.minTotalWeigth), Validators.max(this.maxTotalWeigth)]);
  totalMeasurementControl = new FormControl(0, [Validators.required, Validators.min(this.minTotalWeigth)]);
  unitControl = new FormControl(null);
  typeControl = new FormControl(null);
  vehicleCargo: VehicleCargo[] = [];
  formSubscription: Subscription;

  constructor(
    public menuService: MenuServiceService,
    public utils: Utils,
    public modelCargo: ModelCargo,
    private global: Global,
    private router: Router,
    private patterns: Patterns,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private permissionRole: PermissionRole,
    private manualCreationCargoService: ManualCreationCargoService,
  ) { }

  ngOnInit() {
    this.setLocalValues();
    this.setSubscription();
    this.checkPermissionsToEdit();
  }

  private async setLocalValues() {
    const riskProfile: RiskProfile = this.utils.getNestedValue(this.menuService.form.value, 'riskProfile');
    this.riskProfileControl.setValue(riskProfile && riskProfile.id && riskProfile.name ? { id: riskProfile.id, name: riskProfile.name } : null);

    this.container.setValue(this.menuService.isContainerService() ? this.menuService.form.get('container').value : null);
    this.seal.setValue(this.menuService.isContainerService() ? this.menuService.form.get('seal').value : null);
    if (this.menuService.isContainerService() && this.menuService.form.get('containerExpirationDate').value)
      this.containerExpirationDate.setValue(DateManager.stringToDate(this.menuService.form.get('containerExpirationDate').value, 'DD/MM/YYYY'));
    this.booking.setValue(this.menuService.isContainerService() ? this.menuService.form.get('booking').value : null);

    this.minQuantity = this.menuService.isEmptyContainer ? 0 : 1;
    this.quantityControl.setValidators([Validators.required, Validators.min(this.minQuantity)]);
    this.amountControl.setValue(this.menuService.isEscortService() ? null : this.menuService.form.get('cargoFeature.cargoMeasure.amount').value);
    this.quantityControl.setValue(this.menuService.isEscortService() ? null : this.menuService.form.get('cargoFeature.cargoMeasure.quantity').value);
    this.totalWeigthControl.setValue(this.menuService.isEscortService() ? null : this.menuService.form.get('cargoFeature.cargoMeasure.totalWeigth').value);
    this.totalMeasurementControl.setValue(this.menuService.isEscortService() ? null : this.menuService.form.get('cargoFeature.cargoMeasure.totalMeasurement').value);
    this.unitControl.setValue(this.menuService.isEscortService() ? null : this.menuService.form.get('cargoFeature.cargoMeasure.unit').value);
    this.typeControl.setValue(this.menuService.isEscortService() ? null : this.menuService.form.get('cargoFeature.cargoMeasure.type').value);
    if (!this.vehicleCargo.length)
      await this.getDataVehicle();
    this.setMaxTotalWeigth();
  }

  private async getDataVehicle() {
    let catalog: Catalog;
    try { catalog = await this.manualCreationCargoService.getVehicleType().toPromise() } catch (error) { }
    if (!catalog || !catalog.catalog || !catalog.catalog.length) return this.vehicleCargo = [];
    this.vehicleCargo = (catalog.catalog as VehicleCargo[]);

  }

  private async setMaxTotalWeigth() {
    const vehicleTypeName = this.utils.getNestedValue(this.menuService.cargo, 'cargoFeature.vehicleType.name');
    const vehicleData = this.vehicleCargo.find((item: VehicleCargo) => vehicleTypeName && vehicleTypeName === item.name);
    if (!vehicleData) {
      this.maxTotalWeigth = AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG;
      this.totalWeigthControl.setValidators([Validators.required, Validators.min(this.minTotalWeigth), Validators.max(this.maxTotalWeigth)]);
      return;
    }
    let infoVehicle: { id: string, name: string, weight: number };
    try { infoVehicle = await this.manualCreationCargoService.getVehicleWeightById(vehicleData.id).toPromise(); } catch (error) { }
    this.maxTotalWeigth = infoVehicle && infoVehicle.weight ? infoVehicle.weight : AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG;
    this.totalWeigthControl.setValidators([Validators.required, Validators.min(this.minTotalWeigth), Validators.max(this.maxTotalWeigth)]);
  }

  private setSubscription() {
    this.formSubscription = this.menuService.formObservable.subscribe(() => this.setLocalValues());
  }

  private checkPermissionsToEdit() {
    if (!this.canEditLoadCharacteristics) {
      this.riskProfileControl.disable();
      this.container.disable();
      this.seal.disable();
      this.containerExpirationDate.disable();
      this.booking.disable();
      this.amountControl.disable();
      this.quantityControl.disable();
      this.totalWeigthControl.disable();
      this.totalMeasurementControl.disable();
      this.unitControl.disable();
      this.typeControl.disable();
    }
  }

  compareById(option1: RiskProfile, option2: RiskProfile): boolean {
    return option1 && option2 && option1.id === option2.id;
  }

  openDialogCalculatorMeasurements() {
    if (!this.canEditLoadCharacteristics) return;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(CalculatorMeasurementsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(totalSize => totalSize && this.totalMeasurementControl.setValue(totalSize));
  }

  get canEditLoadCharacteristics(): boolean {
    return this.canSeeLoadCharacteristics &&
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.editServiceCharacteristicsModule
      );
  }

  get canSeeLoadCharacteristics(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.showServiceCharacteristicsModule
    );
  }

  /**
   * @description Navigates to the previous step
   */
  backToPreviousStep() {
    const isRequiredLoadTypeService = this.menuService.isRequiredLoadTypeService();
    this.router.navigate([Fmt.string(isRequiredLoadTypeService
      ? this.menuService.steps.cargoType.url
      : this.menuService.steps.vehicle.url, this.menuService.cargo.consecutive)]);
  }

  /**
   * @description Saves form data and continues if valid
   */
  saveAndContinue() {
    if (this.menuService.isContainerService() && this.container.value && this.container.invalid)
      return this.snackBarService.openSnackBar(this.utils.giveMessageError('INVALID_CONTAINER_GENERAL'), undefined, 'alert');
    this.menuService.form.get('riskProfile.name').setValue(
      this.riskProfileControl.value && this.riskProfileControl.value.name
        ? this.riskProfileControl.value.name : null
    );
    this.menuService.form.get('riskProfile.id').setValue(
      this.riskProfileControl.value && this.riskProfileControl.value.id
        ? this.riskProfileControl.value.id : null
    );
    this.menuService.form.get('container').setValue(
      this.menuService.isContainerService() ? this.container.value : null
    );
    this.menuService.form.get('seal').setValue(
      this.menuService.isContainerService() ? this.seal.value : null
    );
    this.menuService.form.get('containerExpirationDate').setValue(
      this.menuService.isContainerService() && this.containerExpirationDate.value
        ? DateManager.dateToString(this.containerExpirationDate.value, 'DD/MM/YYYY') : null
    );
    this.menuService.form.get('booking').setValue(
      this.menuService.isContainerService() ? this.booking.value : null
    );

    this.menuService.form.get('cargoFeature.cargoMeasure.amount').setValue(
      this.menuService.isEscortService() ? null : Number(this.amountControl.value)
    );
    this.menuService.form.get('cargoFeature.cargoMeasure.quantity').setValue(
      this.menuService.isEscortService() ? null : Number(this.quantityControl.value)
    );
    this.menuService.form.get('cargoFeature.cargoMeasure.totalWeigth').setValue(
      this.menuService.isEscortService() ? null : Number(this.totalWeigthControl.value) <= this.maxTotalWeigth
        ? Number(this.totalWeigthControl.value) : this.maxTotalWeigth
    );
    this.menuService.form.get('cargoFeature.cargoMeasure.totalMeasurement').setValue(
      this.menuService.isEscortService() ? null : Number(this.totalMeasurementControl.value)
    );
    this.menuService.form.get('cargoFeature.cargoMeasure.unit').setValue(
      this.menuService.isEscortService() ? null : this.unitControl.value
    );
    this.menuService.form.get('cargoFeature.cargoMeasure.type').setValue(
      this.menuService.isEscortService() ? null : this.typeControl.value
    );

    this.menuService.updateService().then(() => {
      this.snackBarService.openSnackBar('Servicio actualizado exitosamente');
      this.continueToNextStep();
    }).catch(() => this.snackBarService.openSnackBar('Ocurrió un error al actualizar el servicio', undefined, 'error'));
  }

  continueToNextStep() {
    this.router.navigate([Fmt.string(this.menuService.steps.additionalValuesService.url, this.menuService.cargo.consecutive)]);
  }

  ngOnDestroy() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }
}

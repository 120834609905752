<div class="wrapper-modal">
  <!-- vehicle assign manual -->
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div *ngIf="dialogParams?.type === 'assign' && !vehicleInfo">
    <form (ngSubmit)="onSubmit()" [formGroup]="assignVehicleForm" *ngIf="assignVehicleForm">
      <p class="title mx-4">
        {{dialogParams && dialogParams.licensePlate ? '¿Desea asignar la placa ' + this.dialogParams.licensePlate + '?'
        : isEscortedService? 'Asignación de escolta':'Asignación manual de vehículo'}}
      </p>
      <hr>
      <div class="body-content overflow-x-hidden">
        <div class="row">
          <!-- Input -->
          <div class="col-12" *ngIf="dialogParams && !dialogParams.licensePlate">
            <mat-form-field>
              <mat-label>Placa<span class="asterisk">*</span></mat-label>
              <input type="text" [angularFormsMask]="getMask()" [validateMaskInput]="false" matInput
                [matAutocomplete]="auto" formControlName="idVehicle">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
                <mat-option *ngFor="let vehicle of listVehicles | async" [value]="vehicle?.id"
                  [disabled]="secureTravelProtocol(vehicle)"
                  data-idowner="{{vehicle?.owner?.document}}" data-idadmin="{{vehicle?.administrator?.document}}"
                  data-nameowner="{{vehicle?.owner?.name}}" data-typedocumentowner="{{vehicle?.owner?.documentTypeId}}"
                  data-namedriver="{{vehicle?.driver?.name}}" data-iddriver="{{vehicle?.driver?.document}}"
                  data-vehicletype="{{vehicle?.vehicleType?.name}}" data-trailerid="{{vehicle?.trailerId}}"
                  data-vehiclestate="{{vehicle?.state ? vehicle.state.active : false}}"
                  data-vehiclestatecompany="{{vehicle?.stateCompany ? vehicle?.stateCompany?.active : false}}"
                  data-vehiclestatecompanydescription="{{vehicle?.stateCompany?.description ? vehicle?.stateCompany?.description : ''}}">
                  <span>
                    {{vehicle?.id}}
                    {{
                    vehicle?.numberOfTrips ?
                    '('+vehicle.numberOfTrips+' viajes)':
                    '(0 viajes)'
                    }}
                  </span> |
                  <small>{{hasDriver(vehicle)? vehicle.driver.name : 'Sin conductor' }}</small>
                </mat-option>
                <mat-option [disabled]="secureTravelProtocol()" [value]="assignVehicleForm.controls.idVehicle.value">
                  <span class="font-italic">Crear {{isEscortedService? 'escolta':'vehículo'}}</span>
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="assignVehicleForm.get('idVehicle').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row m-0">
          <!-- Selected -->
          <div class="col-12 col-md-6 mb-3" [ngClass]="{'col-md-4': trailerSelected || vehicleSelected?.trailerId || vehicleSelected?.vehicleType?.name === 'TRACTOCAMION', 'col-md-6': !trailerSelected && !vehicleSelected?.trailerId && vehicleSelected?.vehicleType?.name !== 'TRACTOCAMION'}"
            *ngIf="vehicleSelected">
            <div class="container-vehicle">
              <div class="vehicle-data">
                <div class="color-secondary-dark text-strong mb-2">
                  Vehículo
                </div>
                <div>
                  <label>Placa: </label>
                  <span class="label-field">&nbsp;{{vehicleSelected.id}}</span>
                </div>
                <div *ngIf="vehicleSelected.owner">
                  <label>Propietario: </label>
                  <span class="label-field">&nbsp;{{vehicleSelected?.owner?.name}}</span>
                </div>
                <div *ngIf="vehicleSelected?.vehicleType?.name">
                  <label>Tipo de Vehículo: </label>
                  <span class="label-field">&nbsp;{{vehicleSelected?.vehicleType?.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="vehicleSelected?.id">
            <!-- Driver Selected -->
            <div class="col-12 mb-3" [ngClass]="{'col-md-4': trailerSelected || vehicleSelected?.trailerId || vehicleSelected?.vehicleType?.name === 'TRACTOCAMION', 'col-md-6': !trailerSelected && !vehicleSelected?.trailerId && vehicleSelected?.vehicleType?.name !== 'TRACTOCAMION'}"
              *ngIf="vehicleSelected?.driver?.document; else noDriver">
              <div class="container-vehicle">
                <div class="vehicle-data">
                  <div class="color-secondary-dark text-strong mb-2">
                    Conductor principal
                  </div>
                  <div>
                    <label>Nombre: </label>
                    <span class="label-field">&nbsp;{{vehicleSelected?.driver?.name}}</span>
                  </div>
                  <div *ngIf="vehicleSelected.owner">
                    <label>Documento Conductor: </label>
                    <span class="label-field">&nbsp;{{vehicleSelected?.driver?.document}}</span>
                  </div>
                  <div class="color-primary text-strong underline mb-2" (click)="changeDriver('change')">
                    Cambiar conductor principal
                  </div>
                </div>
              </div>
            </div>
            <ng-template #noDriver>
              <div class="col-12 mb-3"
              [ngClass]="{'col-md-4': trailerSelected || vehicleSelected?.trailerId || vehicleSelected?.vehicleType?.name === 'TRACTOCAMION', 'col-md-6': !trailerSelected && !vehicleSelected?.trailerId && vehicleSelected?.vehicleType?.name !== 'TRACTOCAMION'}">
                <div 
                class="no-data d-flex flex-column align-items-center justify-content-center">
                  <span>No hay conductor asignado.</span>
                  <span>Debes asignar un conductor para continuar.</span>
                  <span class="cursor-pointer" (click)="changeDriver('assign')"><u>Asignar conductor principal</u></span>
                </div>
              </div>
            </ng-template>
            <!-- Trailer Selected -->
            <div class="col-12 col-md-4 mb-3" *ngIf="vehicleSelected?.trailerId; else noTrailer">
              <div class="container-vehicle">
                <div class="vehicle-data">
                  <div class="color-secondary-dark text-strong mb-2">
                    Último trailer asociado
                  </div>
                  <div>
                    <label>Placa del trailer: </label>
                    <span class="label-field">&nbsp;{{vehicleSelected?.trailerId}}</span>
                  </div>
                  <div *ngIf="trailerSelected?.axles">
                    <label>Ejes: </label>
                    <span class="label-field">&nbsp;{{trailerSelected?.axles}}</span>
                  </div>
                  <div *ngIf="trailerSelected?.emptyWeight">
                    <label>Peso vacío: </label>
                    <span class="label-field">&nbsp;{{trailerSelected?.emptyWeight}}</span>
                  </div>
                  <div *ngIf="trailerSelected?.bodywork?.description">
                    <label>Carrocería: </label>
                    <span class="label-field">&nbsp;{{trailerSelected?.bodywork?.description | titlecase}}</span>
                  </div>
                  <div *ngIf="trailerSelected?.configuration?.description">
                    <label>Clase: </label>
                    <span class="label-field">&nbsp;{{trailerSelected?.configuration?.description | titlecase}}</span>
                  </div>
                  <div class="color-primary text-strong underline mb-2" *ngIf="trailerSelected?.id"
                    (click)="changeTrailer()">
                    Cambiar tráiler asociado
                  </div>
                </div>
              </div>
            </div>
            <ng-template #noTrailer>
              <div class="col-12 col-md-4 mb-3">
                <div *ngIf="vehicleSelected && vehicleSelected?.vehicleType?.name === 'TRACTOCAMION'" class="no-data d-flex flex-column align-items-center justify-content-center">
                  <span>No hay tráiler asociado.</span>
                  <span>Debes asignar un tráiler para continuar.</span>
                  <span class="cursor-pointer" (click)="changeTrailer()"><u>Asignar tráiler</u></span>
                </div>
              </div>
            </ng-template>
          </ng-container>
          
        </div>
        <div class="row" *ngIf="!vehicleSelected && !PermissionAssignVehicle">
          <!-- Selected -->
          <div class="col-12">
            <div class="container-vehicle">
              <div class="vehicle-data text-center"
                *ngIf="cargo?.cargoFeature?.cargoMeasure?.amount>amountsCargoEnum.LOW_END && cargo?.cargoFeature?.cargoMeasure?.amount<=amountsCargoEnum.MEDIUM_END">
                Solo es posible asignar {{isEscortedService? 'escoltas':'vehículos'}} con
                <b>{{TripsToCargo.TRIPS_TO_MEDIUM_END}} o más viajes</b>
              </div>
              <div class="vehicle-data text-center"
                *ngIf="cargo?.cargoFeature?.cargoMeasure?.amount>amountsCargoEnum.MEDIUM_END">
                Solo es posible asignar {{isEscortedService? 'escoltas':'vehículos'}} con
                <b>{{TripsToCargo.TRIPS_TO_HIGH_END}} o más viajes</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-container mb-3">
        <button type="button" mat-raised-button class="primary-secondary" color="primary"
          (click)="closeModal()">Cancelar</button>
        <button class="bg-color-primary" mat-raised-button color="primary" type="submit">Asignar</button>
      </div>
    </form>
  </div>

  <!-- vehicle assign in turn -->
  <div *ngIf="vehicleInfo && vehicleSelected" class="row">
    <p class="title col-12 px-5">
      Información del vehículo por asignar
    </p>
    <div class="info-vehicle col-12 mt-3">
      <div class="vehicle-icon px-3">
        <i class="fas fa-car"></i>
      </div>
      <div class="container-vehicle mx-2">
        <div class="vehicle-data">
          <div>
            <label>Placa: </label>
            <span class="label-field">&nbsp;{{vehicleSelected.id}}</span>
          </div>
          <div *ngIf="vehicleSelected.owner">
            <label>Propietario: </label>
            <span class="label-field">&nbsp;{{vehicleSelected.owner.name}}</span>
          </div>
          <div *ngIf="vehicleSelected.driver">
            <label>Conductor: </label>
            <span class="label-field">&nbsp;{{vehicleSelected.driver.name}}</span>
          </div>
          <div *ngIf="vehicleSelected.driver">
            <label>Documento Conductor: </label>
            <span class="label-field">&nbsp;{{vehicleSelected.driver.document}}</span>
          </div>
          <div *ngIf="vehicleSelected.vehicleType && vehicleSelected.vehicleType.name">
            <label>Tipo de Vehículo: </label>
            <span class="label-field">&nbsp;{{vehicleSelected.vehicleType.name}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="button-container w-100 mb-3">
      <button mat-raised-button class="primary-secondary" color="primary" (click)="dialogRef.close()">Cancelar</button>
      <button mat-raised-button color="primary" class="bg-color-primary"
        (click)="onSubmit(null, null, 'cargo')">Asignar</button>
    </div>
  </div>

  <!-- avaliable turn list -->
  <div class="avaliable-turn-list-container" *ngIf="dialogParams?.type === 'turn' && !vehicleInfo && !vehicleSelected">
    <h2 class="title-component mt-2 mb-4">{{isEscortedService? 'Escoltas':'Vehículos'}} disponibles para el servicio {{
      dialogParams.filteredCargosTurns.cargoConsecutive}}</h2>
    <div class="col-12 mt-3 p-0 mb-4" *ngIf="!PermissionAssignVehicle && assignTurnVehicles">
      <div class="container-vehicle row">
        <div class="vehicle-data text-center col-12"
          *ngIf="dialogParams.cargo?.cargoFeature?.cargoMeasure?.amount>amountsCargoEnum.LOW_END && dialogParams.cargo?.cargoFeature?.cargoMeasure?.amount<=amountsCargoEnum.MEDIUM_END">
          <div class="alert alert-warning" role="alert">
            Solo es posible asignar {{isEscortedService? 'escoltas':'vehículos'}} con
            <b>{{TripsToCargo.TRIPS_TO_MEDIUM_END}} o más viajes</b>
          </div>
        </div>
        <div class="vehicle-data text-center col-12"
          *ngIf="dialogParams.cargo?.cargoFeature?.cargoMeasure?.amount>amountsCargoEnum.MEDIUM_END">
          <div class="alert alert-warning" role="alert">
            Solo es posible asignar {{isEscortedService? 'escoltas':'vehículos'}} con
            <b>{{TripsToCargo.TRIPS_TO_HIGH_END}} o más viajes</b>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="dialogParams?.filteredCargosTurns?.matches" class="d-flex flex-wrap">
      <mat-form-field class="col-md-6 px-0 pr-md-2" appearance="outline">
        <mat-label>Fecha de enturnamiento</mat-label>
        <mat-select [formControl]="filterMatchDate">
          <mat-option [value]="null">
            Todos
          </mat-option>
          <mat-option *ngFor="let dateOption of matchDateOptions" [value]="dateOption.value">
            {{dateOption.alias | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-6 px-0 pl-md-2" appearance="outline">
        <mat-label>Número de viajes</mat-label>
        <mat-select [formControl]="filterNumberOfTrips">
          <mat-option [value]="null">
            Todos
          </mat-option>
          <mat-option *ngFor="let tripOption of numberOfTripsOptions" [value]="tripOption.value">
            {{tripOption.alias}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="dialogParams?.filteredCargosTurns?.matches?.length && !cargoMatchesFiltered?.length"
      class="container_no_results w-100 text-center">
      <div class="icon"><i class="fas fa-calendar-times"></i></div>
      <h4 class="text-bold text-center">No hay resultados que coincidan con la búsqueda</h4>
    </div>
    <div class="cards-container">

      <div *ngFor="let match of cargoMatchesFiltered">
        <mat-card class="card card-overlay">
          <mat-card-content>
            <div class="license-plate">
              {{match.licensePlate}}
            </div>
            <p class="p-0 text-bold" *ngIf="getMatchIndex(match)">
              Turno {{getMatchIndex(match)}}
            </p>
            <div>
              <i class="fas fa-thumbtack mr-1 mt-1 fa-lg"></i> Enturnado el día:<span class="text-bold ml-1">{{
                match.date | dateFormat:'only-date' }}</span>
            </div>
            <div>
              <i class="fas fa-thumbtack mr-1 mt-1 fa-lg"></i> Conductor:<span class="text-bold ml-1">{{
                match.driverName | titlecase}}</span>
            </div>
            <div>
              <i class="fas fa-thumbtack mr-1 mt-1 fa-lg"></i> Celular:<span class="text-bold ml-1">{{
                match.cellphone}}</span>
            </div>
            <div>
              <i class="fas fa-thumbtack mr-1 mt-1 fa-lg"></i> Destino:<span class="text-bold ml-1">{{
                match.destination?.name ? match.destination?.name : 'Sin especificar'}}</span>
            </div>
            <div>
              <i class="fas fa-thumbtack mr-1 mt-1 fa-lg"></i> Número de viajes:<span class="text-bold ml-1">{{
                match.numberOfTrips}}</span>
            </div>
            <div *ngIf="assignTurnVehicles" class="col-12 mt-4">
              <div class="actions-card d-flex justify-content-center align-items-center">
                <button mat-raised-button color="primary"
                  (click)="getVehicleInfo(match.licensePlate, null, match.numberOfTrips)">
                  <span>Asignar</span>
                  <i class="fas fa-truck"></i>
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
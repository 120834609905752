import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { ThirdPartyAddress } from 'src/app/core/interfaces/thirdParty';
import { Global } from 'src/app/core/resources/global';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FormThirdPartyConsignmentComponent } from 'src/app/modules/cargo/manual-creation-cargo/components/form-third-party-consignment/form-third-party-consignment.component';
import { ThirdPartyConsignmentService } from 'src/app/modules/cargo/manual-creation-cargo/components/third-party-consignment/third-party-consignment.service';
import { DialogComponent } from '../dialog/dialog.component';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { ModalSelectionMapComponent } from '../modal-selection-map/modal-selection-map.component';
import { LocationAddress } from 'src/app/core/interfaces/locationAddress';

@Component({
  selector: 'app-autocomplete-thirdparty-address',
  templateUrl: './autocomplete-thirdparty-address.component.html',
  styleUrls: ['./autocomplete-thirdparty-address.component.scss']
})
export class AutocompleteThirdpartyAddressComponent implements OnInit {

  @Input() options: OptionsAutocomplete = {};
  @Input() inputFormControl: FormControl;
  @Input() validate: string = '';
  loadingThirdParties: boolean = false;

  formControlThirdPartyAddress: FormControl = new FormControl();
  listThirdPartiesAddresses: ThirdPartyAddress[] = [];

  constructor(
    private thirdPartyConsignmentService: ThirdPartyConsignmentService,
    public utils: Utils,
    private dialog: MatDialog,
    private global: Global,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    const validator = this.inputFormControl && this.inputFormControl.validator ? this.inputFormControl.validator({} as AbstractControl) : '';
    if (validator && validator.required) this.formControlThirdPartyAddress.setValidators(Validators.required);
    this.getThirdPartiesAddresses();
  }

  async getThirdPartiesAddresses() {
    if (!this.options || !this.options.companyId) {
      this.listThirdPartiesAddresses = [];
      if (this.options.cleanOnChangeOptions) {
        this.formControlThirdPartyAddress.setValue("");
        this.inputFormControl.setValue("");
      }
      return;
    }
    this.loadingThirdParties = true;
    const currentThirdPartyList: ThirdPartyAddress[] = await this.thirdPartyConsignmentService.getThirdPartiesAddressByCompany(this.options.companyId, null, null, this.options.municipalityCode).toPromise();
    this.loadingThirdParties = false;
    if (currentThirdPartyList && currentThirdPartyList.length)
      this.listThirdPartiesAddresses = currentThirdPartyList;
    else
      this.listThirdPartiesAddresses = [];
    if (this.options.cleanOnChangeOptions) {
      this.formControlThirdPartyAddress.setValue("");
      this.inputFormControl.setValue("");
    }
  }

  displayThirdPartyName(thirdParty?: ThirdPartyAddress): string | ThirdPartyAddress {
    return thirdParty && thirdParty.address ? thirdParty.address.address : undefined;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.options && changes.options.currentValue) {
        if (changes.options.currentValue.initialValue || changes.options.currentValue.initialValue === "") {
          changes.options.currentValue.initialValue
            ? this.setInitialThirdParty(changes.options.currentValue.initialValue, this.options.companyId, this.options.municipalityCode)
            : this.formControlThirdPartyAddress.setValue("");
        }
        if (changes.options.currentValue.initialBranchOffice || changes.options.currentValue.initialBranchOffice === "") {
          changes.options.currentValue.initialBranchOffice
            ? this.setInitialThirdParty(null, this.options.companyId, this.options.municipalityCode, this.options.initialBranchOffice)
            : this.formControlThirdPartyAddress.setValue("");
        }
        if ("municipalityCode" in changes.options.currentValue) {
          const currentMunicipalityCode = changes.options.currentValue.municipalityCode;
          if (currentMunicipalityCode) this.getThirdPartiesAddresses();
          else if (currentMunicipalityCode === "") this.formControlThirdPartyAddress.setValue("");
        }
        if ("companyId" in changes.options.currentValue) {
          const currentCompanyId = changes.options.currentValue.companyId;
          if (currentCompanyId) this.getThirdPartiesAddresses();
          else if (currentCompanyId === "") this.formControlThirdPartyAddress.setValue("");
        }
      }

      if (changes.validate) {
        switch (this.validate) {
          case 'touched':
            this.formControlThirdPartyAddress.markAsTouched();
            break;
          case 'untouched':
            this.formControlThirdPartyAddress.markAsUntouched();
            break;
          case 'enable':
            this.formControlThirdPartyAddress.enable();
            break;
          case 'disable':
            this.formControlThirdPartyAddress.disable();
            break;
          case 'disable&untouched':
            this.formControlThirdPartyAddress.markAsUntouched();
            this.formControlThirdPartyAddress.disable();
            break;
          case 'setValidators':
            this.formControlThirdPartyAddress.setValidators(Validators.required);
            this.formControlThirdPartyAddress.updateValueAndValidity();
            break;
          case 'clearValidators':
            this.formControlThirdPartyAddress.clearValidators();
            this.formControlThirdPartyAddress.updateValueAndValidity();
            break;
          default:
            break;
        }
      }
    }
  }

  async setInitialThirdParty(address: string, companyId: string, municipalityCode: string, branchOffice?: string) {
    if (!this.listThirdPartiesAddresses.length) await this.getThirdPartiesAddresses();
    const thirdPartyAddress = this.listThirdPartiesAddresses.find((thirdParty: ThirdPartyAddress) => {
      const hasSameCompany = thirdParty && thirdParty.information && companyId && thirdParty.information.document === companyId;
      const hasSameAddress = !address || (address && thirdParty && thirdParty.address && thirdParty.address.address === address);
      const hasSameMunicipality = !municipalityCode || (municipalityCode && thirdParty.municipalityCode === municipalityCode);
      const hasSameBranchOffice = !branchOffice || (branchOffice && thirdParty && thirdParty.address && thirdParty.address.id === branchOffice);
      return hasSameCompany && hasSameAddress && hasSameMunicipality && hasSameBranchOffice;
    });
    if (thirdPartyAddress) {
      this.formControlThirdPartyAddress.setValue(thirdPartyAddress);
      this.inputFormControl.setValue(thirdPartyAddress)
    }
  }

  onSelectAddress($event: MatAutocompleteSelectedEvent) {
    if ($event && $event.option && $event.option.value && this.inputFormControl) this.inputFormControl.setValue($event.option.value);
  }

  get showCreateAddress(): boolean {
    return !!(this.options.thirdParty && this.options.thirdParty.document && this.options.thirdParty.documentType
      && this.options.thirdParty.name);
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    const document = this.global.documenTypes.find(doc => doc.id === this.options.thirdParty.documentType);
    dialogConfig.data = {
      newThirdParty: true,
      title: this.options.title,
      options: {
        municipalityCode: this.options.municipalityCode,
        thirdParty: { ...this.options.thirdParty, documentTypeName: document.name, documentTypeId: this.options.thirdParty.documentType }
      }
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(FormThirdPartyConsignmentComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      result && result.state && this.getThirdPartiesAddresses();
    });
  }

  changeValue() {
    if (this.inputFormControl) this.inputFormControl.setValue("");
  }

  get thirdPartiesAddressesFiltered(): ThirdPartyAddress[] {
    const value: ThirdPartyAddress | string = this.formControlThirdPartyAddress.value
    if (!value) return this.listThirdPartiesAddresses;
    const term = typeof value === 'string' ? value : value.address.address;
    return this.listThirdPartiesAddresses.filter(address => address && address.address && address.address.address
      && address.address.address.toLowerCase().includes(term.toLowerCase())
    );
  }

  openAddLatLng() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      clickMap: true
    };
    dialogConfig.height = ModalEnum.FORMS_HEIGHT;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ModalSelectionMapComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state && result.data && result.data.location && result.data.location.lat && result.data.location.lng) {
        this.setLatLngAddress(result.data.location);
      }
    });
  }

  setLatLngAddress(location: LocationAddress) {
    const addressObserver = {
      next: (success) => {
        this.spinner.hide();
        const event = {
          option: { value: success },
          source: null
        }
        this.onSelectAddress(event as MatAutocompleteSelectedEvent);
        this.snackBarService.openSnackBar(FormMessages.ADDRESS_LOCATION_SETTED);
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.ADDRESS_LOCATION_NOT_SETTED, undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    let addressToSend = this.utils.clone(this.inputFormControl.value);
    addressToSend.address['location'] = {
      lat: Number(location.lat),
      lng: Number(location.lng)
    }
    this.thirdPartyConsignmentService.setLatLngAddresses(addressToSend).subscribe(addressObserver);
  }

}

import { Component, Inject, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdditionalCost } from 'src/app/core/interfaces/additionalCost';
import { AdditionalCostCargo } from 'src/app/core/interfaces/additionalCostCargo';
import { BasicResponse } from 'src/app/core/interfaces/basicResponse';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { DataDialogCargoAdditionalService } from 'src/app/core/interfaces/dataDialogCargoAdditionalService';
import { Permission } from 'src/app/core/resources/permission';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AdditionalServiceComponent } from '../../../additional-service/additional-service.component';
import { ManualCreationCargoService } from '../../../manual-creation-cargo/manual-creation-cargo.service';
import { CargoResources } from '../../../manual-creation-cargo/resources/cargo';
import { CargoDetailService } from '../../cargo-detail.service';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
@Component({
  selector: 'app-update-additional-cost-cargo',
  templateUrl: './update-additional-cost-cargo.component.html',
  styleUrls: ['./update-additional-cost-cargo.component.scss']
})
export class UpdateAdditionalCostCargoComponent implements OnInit {

  permission = Permission;
  @ViewChild(AdditionalServiceComponent, { static: false }) additionalServiceComponent: AdditionalServiceComponent;
  @Input() public listAdditionalService: AdditionalCost[] = [];
  @Input() public cargo: Cargo;
  modelFormAdditionalCost: AdditionalCostCargo[];
  form: FormGroup;
  readonly: boolean;
  isAdditionalService: boolean = false;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    public authService: AuthService,
    private route: ActivatedRoute,
    private cargoResources: CargoResources,
    public utils: Utils,
    private formBuilder: FormBuilder,
    private cargoDetailService: CargoDetailService,
    public dialogRef: MatDialogRef<UpdateAdditionalCostCargoComponent>,
    @Inject(MAT_DIALOG_DATA) public paramsDialog: DataDialogCargoAdditionalService,
    private manualCreationCargoService: ManualCreationCargoService,
    private permissionRole: PermissionRole,
  ) {
    this.updateService();
  }

  ngOnInit() { }

  updateService() {
    this.createForm();
    this.route.data.subscribe((data: { aditionalServicesResolver: AdditionalCost[] }) => {
      if (data && data.aditionalServicesResolver) {
        this.listAdditionalService = this.utils.clone(data.aditionalServicesResolver);
        this.disabledAdditionalCostsSelected();
      } else {
        if (this.manualCreationCargoService.getListAdditionalCost() && this.manualCreationCargoService.getListAdditionalCost().length) {
          this.listAdditionalService = this.utils.clone(this.manualCreationCargoService.getListAdditionalCost());
          this.disabledAdditionalCostsSelected();
        } else {
          this.manualCreationCargoService.getValueAdditionalCost().subscribe((data) => {
            this.listAdditionalService = this.utils.clone(data);
            this.disabledAdditionalCostsSelected();
          });
        }
      }

    });
  }

  createForm() {
    let reactiveForm: ReactiveForm;
    reactiveForm = new ReactiveForm(
      this.formBuilder,
      { additionalCosts: [] }
    );
    this.form = reactiveForm.form;
    this.validateParamsDialog();
  }

  validateParamsDialog() {
    if (!this.utils.isEmpty(this.paramsDialog) && !this.utils.isEmpty(this.paramsDialog.cargo)) {
      this.cargo = this.utils.clone(this.paramsDialog.cargo);
      this.setAdditionalCostCargo();
    }
    if (!this.utils.isEmpty(this.paramsDialog) && !this.utils.isEmpty(this.paramsDialog.listAdditionalService)) {
      this.listAdditionalService = this.utils.clone(this.paramsDialog.listAdditionalService);
      this.disabledAdditionalCostsSelected();
    }
  }

  setAdditionalCostCargo() {
    if (this.cargo.additionalCosts && this.cargo.additionalCosts.length) {
      this.readonly = true;
    } else {
      this.readonly = false;
    }
    if (this.cargo.approval && ((this.cargo.approval !== 'Approved' && this.cargo.approval !== 'Rejected'))) {
      this.readonly = true;
    }
  }

  disabledAdditionalCostsSelected() {
    if (this.cargo && this.cargo.additionalCosts && this.cargo.additionalCosts.length) {
      (this.cargo.additionalCosts).forEach((additionalCost) => {
        const index = this.listAdditionalService.findIndex((item) => {
          return item.name === additionalCost.type.name;
        });
        if (index >= 0 && index != 0 && index != 3 && index != 5 && additionalCost.type.id === this.listAdditionalService[index].id) {
          this.listAdditionalService[index].disabled = true;
        }
      });
      this.modelFormAdditionalCost = this.cargo.additionalCosts;
      this.modelFormAdditionalCost && this.modelFormAdditionalCost.length && this.modelFormAdditionalCost.forEach((additionalCost) => {
          if (additionalCost && !additionalCost.consignment) additionalCost.consignment = '';
      })
    }
  }

  save() {
    if (this.additionalServiceComponent.form.valid) {
      const formValue: AdditionalCostCargo[] = this.additionalServiceComponent.form.value;
      let reactiveForm: ReactiveForm;
      reactiveForm = new ReactiveForm(
        this.formBuilder,
        {
          additionalCosts: formValue
        }
      );
      this.form = reactiveForm.form;
      const body: AdditionalCostCargo[] = this.utils.clone(this.form.value.additionalCosts).map((additionalCost) => {
        delete additionalCost.type.disabled;
        delete additionalCost.type.whoToPaysUtilities;
        delete additionalCost.type.utility;
        return additionalCost;
      });
      this.spinner.show();
      this.cargoDetailService.addAdditionalcost(this.cargo.id, body).toPromise()
        .then((success: BasicResponse) => {
          if (success && success.message === 'Update') {
            this.snackBarService.openSnackBar('Servicios Adicionales registrados correctamente');
            this.dialogRef.close({ state: true });
          } else {
            this.snackBarService.openSnackBar('Ocurrió un error al agregar los Servicios Adicionales', undefined, 'error');
          }
        })
        .catch((error) => {
          console.error(error);
          this.snackBarService.openSnackBar('Ocurrió un error al agregar los Servicios Adicionales', undefined, 'error');
        })
        .finally(() => this.spinner.hide());
    } else {
      let index = this.additionalServiceComponent.form.controls.findIndex((service) => service.invalid);
      if (index !== -1) {
        if (this.additionalServiceComponent.form.controls[index].errors)
          this.snackBarService.openSnackBar(FormMessages.MINIMUN_UTILITY_NOT_REACHED_DEFAULT + ` en el servicio ${index + 1}`, undefined, 'alert');
        else
          this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, `tarifa del servicio ${index + 1}`), undefined, 'alert');
      } else {
        this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
      }
    }
  }

  update() {
    if (this.additionalServiceComponent.form.valid) {
      const formValue: AdditionalCostCargo[] = this.additionalServiceComponent.form.value;
      let reactiveForm: ReactiveForm;
      formValue.forEach((additionalCost) => {
        switch (additionalCost.type.name) {
          case 'Servicios de escolta':
          case 'Servicio descargue':
          case 'Servicio cargue':

            break;
        }
      });
      reactiveForm = new ReactiveForm(
        this.formBuilder,
        {
          additionalCosts: formValue
        }
      );
      this.form = reactiveForm.form;
      const body: AdditionalCostCargo[] = this.utils.clone(this.form.value.additionalCosts).map((additionalCost) => {
        delete additionalCost.type.disabled;
        return additionalCost;
      });
      this.spinner.show();
      this.cargoDetailService.updateAdditionalcost(this.cargo.id, body).toPromise()
        .then((success: BasicResponse) => {
          if (success && success.message === 'Update') {
            this.snackBarService.openSnackBar('Servicios Adicionales Actualizados Correctamente');
            this.dialogRef.close({ state: true });
          } else {
            this.snackBarService.openSnackBar('Ocurrió un error al actualizar los Servicios Adicionales', undefined, 'error');
          }
        })
        .catch((error) => {
          console.error(error);
          this.snackBarService.openSnackBar('Ocurrió un error al actualizar los Servicios Adicionales', undefined, 'error');
        })
        .finally(() => this.spinner.hide());
    } else {
      let index = this.additionalServiceComponent.form.controls.findIndex((service) => service.invalid);
      if (index !== -1) {
        if (this.additionalServiceComponent.form.controls[index].errors)
          this.snackBarService.openSnackBar(FormMessages.MINIMUN_UTILITY_NOT_REACHED_DEFAULT + ` en el servicio ${index + 1}`, undefined, 'alert');
        else
          this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, `tarifa del servicio ${index + 1}`), undefined, 'alert');
      } else {
        this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
      }
    }
  }

  close() {
    this.dialogRef.close({ state: true })
  }

  additionalCostSelected($event: boolean) {
    this.isAdditionalService = $event;
  }

  emitToParent($event: Event) {
    this.spinner.show();
    this.cargoDetailService.detailCargo(this.cargo.id).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data && data.id) {
          this.cargo = data;
          this.modelFormAdditionalCost = this.cargo.additionalCosts;
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error refrescando los valores totales de los bonos y descuentos. Para poder visualizar los cambios por favor cierra y vuelve a abrir la vista de los servicios adicionales', undefined, 'alert');
      })
  }

  get showBtnCreateAdditionalService() {
    if (this.paramsDialog && this.paramsDialog.listActive && this.paramsDialog.listActive === 'serviceRequests') return true;
    let hasShippingCost = this.paramsDialog && this.paramsDialog.cargo && this.paramsDialog.cargo.shippingCost;
    if (this.cargo.approval && this.cargo.approval !== 'Approved') {
      return this.permissionRole.hasPermission(Permission.cargo.module, Permission.cargo.additionalCostCreate);
    } else if (hasShippingCost && this.paramsDialog.cargo.shippingCost.paid) {
      return this.permissionRole.hasPermission(Permission.payments.module, Permission.payments.addAdditionalServiceCargoPaid);
    } else if (hasShippingCost && this.paramsDialog.cargo.shippingCost.cashed) {
      return this.permissionRole.hasPermission(Permission.payments.module, Permission.payments.addAdditionalServiceCargoCashed);
    } else if (this.paramsDialog && this.paramsDialog.cargo && this.paramsDialog.cargo.approval && this.paramsDialog.cargo.approval === "Approved") {
      return this.permissionRole.hasPermission(Permission.payments.module, Permission.payments.addAdditionalServiceCargoApproved);
    } else {
      return false;
    }
  }
}

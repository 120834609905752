import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Endpoints } from '../resources/endpoints';
import { environment } from './../../../environments/environment'
import { Observable } from 'rxjs';
import { TrackGuide } from '../interfaces/trackGuide';

@Injectable({ providedIn: 'root' })
export class GuideService {

    constructor(
        private http: HttpClient,
        private endpoints: Endpoints
    ) { }

    public getMultipleGuides(guides: string[]): Observable<TrackGuide[]> {
        let params = new HttpParams();
        params = params.append('guides', JSON.stringify(guides));
        return this.http.get<TrackGuide[]>(`${environment.urlServerTeclogi}/${this.endpoints.getMultipleGuides}`, { params });
    }

    public getDetailTrackGuide(guideId: string): Observable<TrackGuide> {
        return this.http.get<TrackGuide>(
            environment.urlServerTeclogi + this.endpoints.trackguide + guideId
        );

    }
}
import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { User } from 'src/app/core/interfaces/user';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Titles } from 'src/app/core/resources/titles';
import { RolesService } from '../roles.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Company } from 'src/app/core/interfaces/company';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { Role } from 'src/app/core/interfaces/role';
import { ModuleRole } from 'src/app/core/interfaces/moduleRole';

@Component({
  selector: 'app-item-role',
  templateUrl: './item-role.component.html',
  styleUrls: ['./item-role.component.scss'],
  providers: [AuthService]
})
export class ItemRoleComponent implements OnInit {

  @Input() role: Role;
  instanceModalDeleteCompany: NgbModalRef;
  @ViewChild('modalConfirmDeleteCompany', { static: false }) modalConfirmDeleteCompany: ModalComponent;

  constructor(
    private authService: AuthService,
    public titles: Titles,
    private rolesService: RolesService,
    public utils: Utils,
    private global: Global,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private router: Router,
    private modalService: NgbModal
  ) {

  }

  ngOnInit() {

  }

  getModulePermissions(): number {
    try {
      return this.role.modules.reduce((acc, role) =>
        acc + (role.functionalities.reduce((sum, functionality) =>
          sum + (functionality && functionality.visible ? 1 : 0)
          , 0) > 0 ? 1 : 0)
        , 0);
    } catch (e) {
      return 0
    }

  }
  getTotalPermissions(): number {
    try {
      return this.role.modules.reduce((acc, role) =>
        acc + role.functionalities.reduce((sum, functionality) =>
          sum + (functionality && functionality.visible ? 1 : 0)
          , 0)
        , 0);
    }
    catch (e) {
      return 0;
    }
  }

  openDetail() {
    this.rolesService.roleSelected = this.role;
    this.router.navigate(['administration/roles/detail', this.role.id]);
  }

}

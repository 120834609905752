import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { CompaniesService } from '../list-companies.service';
import { TypeBill } from 'src/app/core/interfaces/typeBill';
import { MatDialog, MatDialogConfig, MatSelect, MatSelectChange } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/resources/utils';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { BasicBillType } from 'src/app/core/interfaces/billingConfiguration';
import { AdditionalCost } from 'src/app/core/interfaces/additionalCost';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { CityService } from '../../city/city.service';
import { ApiResponseSiigo, ProductSiigo } from 'src/app/core/interfaces/productSiigo';
import { OnboardingTourService } from 'src/app/core/services/onboarding-tour.service';
import { DriveStep } from 'driver.js';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { OpenImgComponent } from 'src/app/shared/open-img/open-img.component';
import { FormControl } from '@angular/forms';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Router } from '@angular/router';
interface ApiResponse<T> {
  data: T[];
  error: string;
}
@Component({
  selector: 'app-administrative-configuration',
  templateUrl: './administrative-configuration.component.html',
  styleUrls: ['./administrative-configuration.component.scss']
})
export class AdministrativeConfigurationComponent implements OnInit {

  @ViewChildren('selectList') selectList!: QueryList<MatSelect>;

  // Inputs
  @Input() typeBill: BasicBillType = {code: '', description: ''};
  @Input() nitCompany: string = '';
  // Outputs
  @Output() clickedTab: EventEmitter<string> = new EventEmitter();
  @Output() refreshList: EventEmitter<boolean> = new EventEmitter();
  // Properties
  listTypeBill: BasicBillType[] = [];
  listAdditionalServices: AdditionalCost[] = [];
  listProductSiigo: ProductSiigo[] = [];
  pageKey: number = 1;
  pageSize: number = 50;
  messageListTypeBill: string = '';
  messageListAdditionalServices: string = '';
  messageListProductSiigo: string = '';
  selectedTypeBill: BasicBillType;
  selectedProductSiigo: string = '';
  listAdditionalServicesControls: string[] = [];
  isLoadingProducts: boolean = false;
  currentIndexLoading: number | null = null;
  cityControl: FormControl = new FormControl();
  optionsCity: OptionsAutocomplete = {
    title: 'Buscar ciudad',
    requireFullCity: true,
    showBillingInfo: true
  };
  citySub: Subscription;
  attemps: number = 0;
  constructor(
    private companiesService: CompaniesService,
    private spinner: NgxSpinnerService,
    public utils: Utils,
    private snackBarService: SnackBarService,
    private cityService: CityService,
    private onboardingTourService: OnboardingTourService,
    private matDialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {    
    this.clickedTab.emit("Configuración administrativa");
    this.getServices();
    this.setSubscriptionCity();
  }

  startTour(): void {
    const steps: DriveStep[] = [
      {
        element: '#typeBillStep',
        popover: {
          title: 'Tipo de documento de factura',
          description: 'En esta sección debes seleccionar el tipo de factura (tipo 12) correspondiente a la operación de transporte.',
          side: 'bottom'
        },
        disableActiveInteraction: true,
      },
      {
        element: '#citiesStep',
        popover: {
          title: 'Ciudades',
          description: 'En esta sección puedes configurar las ciudades que se utilizarán en la empresa.',
          side: 'bottom'
        },
        disableActiveInteraction: true,
      },
      {
        element: '#additionalServicesStep', 
        popover: {
          title: 'Servicios adicionales',
          description: 'En esta sección, selecciona el producto Siigo configurado para el servicio adicional correspondiente. Al hacerlo, el sistema asignará automáticamente las cuentas contables para la respectiva contabilidad.',
          side: 'bottom'
        },
        disableActiveInteraction: true,
      },
      {
        element: '#costCenterStep',
        popover: {
          title: 'Centro de costos',
          description: 'En esta sección puedes configurar el centro de costos que se utilizará en la empresa.',
          side: 'bottom'
        },
        disableActiveInteraction: true,
      },
      {
        element: '#enableType12InvoiceStep',
        popover: {
          title: 'Habilitar factura tipo 12 en Siigo',
          description: 'En esta sección encontraras una guia detallada para que puedas habilitar la factura tipo 12 en Siigo (recuerda que debes tener configurado con anterioridad la facturación en Siigo).',
          side: 'bottom'
        },
        disableActiveInteraction: true,
      }
    ];
    if (!this.onboardingTourService.driverInstance) this.onboardingTourService.setDriverInstanceConfig();
    this.onboardingTourService.setSteps(steps);
    this.onboardingTourService.startTour();
  }

  getServices(){
    this.spinner.show();
    this.messageListTypeBill = '';
    this.messageListAdditionalServices = '';
    this.messageListProductSiigo = '';
    const services$: [Observable<ApiResponse<TypeBill>>, Observable<ApiResponse<AdditionalCost>>] = [
      this.getListTypeBill(),
      this.getListAdditionalServices()
    ]
    forkJoin(services$).pipe(finalize(() => {
      this.spinner.hide();
      this.checkIfInfoEmpty();
    })).subscribe({
      next: ([typeBillResponse, additionalServicesResponse]) => {
        this.processTypeBillResponse(typeBillResponse);
        this.processAdditionalServicesResponse(additionalServicesResponse);
      }
    });
  }

  checkIfInfoEmpty(){
    const typeBillEmpty = this.typeBill && ( !this.typeBill.code || !this.typeBill.description );
    const additionalServicesEmpty = !this.listAdditionalServices.length || (this.listAdditionalServices.length && this.listProductSiigo.length && !this.listAdditionalServices.every(additionalService => !!additionalService.productSiigoId));
    if (typeBillEmpty && additionalServicesEmpty) this.startTour();
    if (this.listAdditionalServices.length) this.getProductSiigo();
  }

  processTypeBillResponse(typeBillResponse: ApiResponse<TypeBill>): void {
    if (!typeBillResponse || typeBillResponse.error) this.messageListTypeBill = Fmt.string(ServiceMessages.ERROR_GETTING_DATA, 'los tipos de documentos');
    else if (!typeBillResponse.data || typeBillResponse.data.length === 0) this.messageListTypeBill = Fmt.string(ServiceMessages.NO_DATA_FOUND, 'tipos de documento de factura');
    else this.listTypeBill = typeBillResponse.data;
  }

  processAdditionalServicesResponse(additionalServicesResponse: ApiResponse<AdditionalCost>): void {
    if (!additionalServicesResponse || additionalServicesResponse.error) this.messageListAdditionalServices = Fmt.string(ServiceMessages.ERROR_GETTING_DATA, 'los servicios adicionales');
    else if (!additionalServicesResponse.data || additionalServicesResponse.data.length === 0) this.messageListAdditionalServices = Fmt.string(ServiceMessages.NO_DATA_FOUND, 'servicios adicionales');
    else this.listAdditionalServices = additionalServicesResponse.data;
  }

  processProductSiigoResponse(productSiigoResponse: ApiResponse<ProductSiigo>, totalResults?: number): void {
      if (!productSiigoResponse || productSiigoResponse.error) this.messageListProductSiigo = Fmt.string(ServiceMessages.ERROR_GETTING_DATA, 'los productos siigo');
      else if (!productSiigoResponse.data || productSiigoResponse.data.length === 0) this.messageListProductSiigo = Fmt.string(ServiceMessages.NO_DATA_FOUND, 'productos siigo');
      else {
        if (this.pageKey && this.pageKey == 1) this.listProductSiigo = productSiigoResponse.data;
        else this.listProductSiigo = [...this.listProductSiigo, ...productSiigoResponse.data];
        if (totalResults && this.listProductSiigo.length < totalResults && this.attemps < 10) {
          this.pageKey++;
          this.attemps++;
          this.getProductSiigo();
        } else this.attemps = 0;
      };
  }

  getListTypeBill(): Observable<ApiResponse<TypeBill>> {
    return this.companiesService.listTypeBill().pipe(
      map((data: TypeBill[]) => ({ data, error: '' })),
      catchError((error): Observable<ApiResponse<TypeBill>> => {
        return of({ data: [], error: error });
      })
    );
  }
  
  getListAdditionalServices(): Observable<ApiResponse<AdditionalCost>> {
    return this.companiesService.getAdditionalServices().pipe(
      map((data: AdditionalCost[]) => ({ data, error: '' })),
      catchError((error): Observable<ApiResponse<AdditionalCost>> => {
        return of({ data: [], error: error });
      })
    );
  }

  onProductSiigoChange(event: MatSelectChange, index: number) {
    const productSiigoId = event.value;
    this.updateProductSiigoAdditionalService(productSiigoId, this.listAdditionalServices[index]);
  }
  
  public getProductSiigo() {
    this.isLoadingProducts = true;
    const listProductSiigoObserver = {
      next: (data: ApiResponseSiigo) => {
        const totalResults = this.utils.getNestedValue(data, 'pagination.totalResults');
        this.processProductSiigoResponse({data: data.results, error: ''}, totalResults);
      },
      error: (error) => {
        this.processProductSiigoResponse({data: [], error: error});
      }
    }
    this.cityService.getProductSiigo(this.pageKey, this.pageSize).pipe(finalize(() => {
      this.isLoadingProducts = false;
    })).subscribe(listProductSiigoObserver);
  }

  onSelectTypeBill(event: MatSelectChange) {
    let code = this.utils.getNestedValue(event.value, 'id');
    let description = this.utils.getNestedValue(event.value, 'description');
    if (code && description) this.updateTypeBillCompany({ code, description });
    else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
  }

  updateTypeBillCompany(typeBill: BasicBillType) {
    const updateTypeBillCompanyObserver = {
      next: (response: {message: string}) => {
        if (response && response.message) {
          this.snackBarService.openSnackBar(Fmt.string(ServiceMessages.FIELD_UPDATED_SUCCESSFULLY, 'el tipo de documento de factura'), undefined, 'success');
          this.refreshList.emit(true);
        } else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      error: () => {
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    }
    this.spinner.show();
    this.companiesService.updateTypeBillCompany(typeBill).pipe(finalize(() => this.spinner.hide())).subscribe(updateTypeBillCompanyObserver);
  }

  
  updateProductSiigoAdditionalService(productSiigoId: string, additionalService: AdditionalCost) {
    const updateProductSiigoAdditionalServiceObserver = {
      next: (response: {message: string}) => {
        if (response && response.message) {
          this.snackBarService.openSnackBar(`${Fmt.string(ServiceMessages.FIELD_UPDATED_SUCCESSFULLY, 'el producto siigo')} del servicio adicional ${additionalService.name}`, undefined, 'success');
        }
      },
      error: () => {
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    }
    this.spinner.show();
    this.companiesService.updateProductSiigoAdditionalService(productSiigoId, additionalService.id).pipe(finalize(() => this.spinner.hide())).subscribe(updateProductSiigoAdditionalServiceObserver);
  }

// Compara cada uno de los elementos de la lista con el valor seteado en el select donde option1 va a ser cada uno de los elementos de la lista y option2 va a ser el valor seteado en el select
  compareTypeBill = (option1: TypeBill, option2: BasicBillType): boolean => {
    if (!option1 || !option2 || !this.utils.isDefined(option1.id) || !option2.code ) return false;
    return option1.id.toString() === option2.code && option1.description === option2.description;
  }

  compareProductSiigo = (option1: string, option2: string): boolean => {
    if (!option1 || !option2) return false;
    return option1 === option2;
  }

  openModalCargoPDF(url: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Habilitar factura tipo 12 en Siigo',
      src: url,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.matDialog.open(OpenImgComponent, dialogConfig);
  }

  setSubscriptionCity() {
    this.citySub = this.cityControl.valueChanges
      .subscribe(value => {
        if (value && value.id) {
          this.router.navigate(['administration/cities/detail', value.id], {state: {byRedirect: true, nitCompany: this.nitCompany}});
        }
      })
  }

  ngOnDestroy(): void {
    if(this.onboardingTourService.driverInstance) this.onboardingTourService.stopTour();
    if (this.citySub) this.citySub.unsubscribe();
  }

}




import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/core/shared.module';
import { ServiceRequestFormRoutingModule } from './service-request-form-routing.module';
import { OriginLoadServiceFormComponent } from './components/origin-load-service-form/origin-load-service-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ContractorTripInfoComponent } from './components/contractor-trip-info/contractor-trip-info.component';
import { DestinationDownloadServiceFormComponent } from './components/destination-download-service-form/destination-download-service-form.component';
import { VehicleServiceFormComponent } from './components/vehicle-service-form/vehicle-service-form.component';
import { MenuServiceComponent } from './menu-service/menu-service.component';
import { LoadTypeServiceFormComponent } from './components/load-type-service-form/load-type-service-form.component';
import { ServiceResources } from './resources/services';
import { MatCardModule } from '@angular/material/card';
import { LoadCharacteristicsFormComponent } from './components/load-characteristics-form/load-characteristics-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AdditionalServiceFormComponent } from './components/additional-service-form/additional-service-form.component';
import { AdditionalValuesServiceFormComponent } from './components/additional-values-service-form/additional-values-service-form.component';
import { ObservationsServiceFormComponent } from './components/observations-service-form/observations-service-form.component';
import { ServicePublicationFormComponent } from './components/service-publication-form/service-publication-form.component';
import { CargoModule } from '../cargo/cargo.module';
import { MenuServiceService } from './menu-service/menu-service.service';
import { ConsignmentsServiceFormComponent } from './components/consignments-service-form/consignments-service-form.component';

@NgModule({
    imports: [
        SharedModule.forRoot(),
        ServiceRequestFormRoutingModule,
        ReactiveFormsModule,
        CommonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatButtonToggleModule,
        CargoModule,
    ],
    declarations: [
        OriginLoadServiceFormComponent,
        ContractorTripInfoComponent,
        DestinationDownloadServiceFormComponent,
        VehicleServiceFormComponent,
        MenuServiceComponent,
        LoadTypeServiceFormComponent,
        LoadCharacteristicsFormComponent,
        AdditionalServiceFormComponent,
        AdditionalValuesServiceFormComponent,
        ObservationsServiceFormComponent,
        ServicePublicationFormComponent,
        ConsignmentsServiceFormComponent
    ],
    providers: [
        ServiceResources,
        MenuServiceService
    ],
    exports: [
        LoadTypeServiceFormComponent,
        LoadCharacteristicsFormComponent,
        AdditionalServiceFormComponent
    ]
})
export class ServiceRequestFormModule { }

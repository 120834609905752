import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { BodyWorkType } from 'src/app/core/interfaces/bodyWorkType';
import { CargoType } from 'src/app/core/interfaces/cargoType';
import { MethodPayment } from 'src/app/core/interfaces/methodPayment';
import { ContainerMeasure } from 'src/app/core/interfaces/containerMeasure';
import { RegimeType } from 'src/app/core/interfaces/regimeType';
import { ServiceType } from 'src/app/core/interfaces/serviceType';
import { TripType } from 'src/app/core/interfaces/tripType';
import { VehicleType } from 'src/app/core/interfaces/vehicleType';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Guide } from 'src/app/core/interfaces/guide';
import { environment } from 'src/environments/environment';
import { Origin } from 'src/app/core/interfaces/origin';

@Injectable({
  providedIn: 'root'
})
export class UploadRouteService {

  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints,
    private globalResources: Global,
    private authService: AuthService
  ) {

  }

  public createCargoRouteUpload(data) {
    const formData = new FormData();
    if (data) {
      const origin: Origin = data.origin;
      origin.addresses = data.addresses;
      formData.append('file', data.file.fileData.file);
      formData.append('companyId', data.companyId);
      formData.append('isExclusive', data.isExclusive);
      formData.append('freight', data.freight);
      formData.append('rate', data.rate);
      formData.append('date', data.date);
      formData.append('origin', new Blob([JSON.stringify(origin)], { type: "application/json" }));
    }

    return this.http.post(
      environment.urlServerTeclogi + this.endpointResources.uploadRoute,
      formData
    );
  }

  public processObject(data, formData: FormData) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];

        if (typeof value === 'object') {
          // Si el valor es un objeto, recorre sus propiedades
          for (const nestedKey in value) {
            if (value.hasOwnProperty(nestedKey)) {
              formData.append(`${key}.${nestedKey}`, value[nestedKey]);
            }
          }
        } else {
          // Si el valor no es un objeto, simplemente agrégalo
          formData.append(key, value);
        }
      }
    }
  }

  public processArray(data, formData: FormData) {
    for (let i = 0; i < data.addresses; i++) {
      formData.append(`data.addresses[${i}]`, data.addresses[i]);
    }
  }

}

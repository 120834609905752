import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { BasicGuide } from 'src/app/core/interfaces/basicGuide';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { TrackGuideService } from 'src/app/public/track-guide/track-guide-services';
import { GuideListDetailsComponent } from 'src/app/public/track-guide/guide-list-details/guide-list-details.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { AddGuideNoveltyFormComponent } from '../add-guide-novelty-form/add-guide-novelty-form.component';
import { FormControl } from '@angular/forms';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';

@Component({
  selector: 'app-cargo-guides-list',
  templateUrl: './cargo-guides-list.component.html',
  styleUrls: ['./cargo-guides-list.component.scss']
})
export class CargoGuidesListComponent implements OnInit {
  permission = Permission;
  guideList: { address: AddressCargo, guides: BasicGuide[] }[] = [];
  addressFilter = new FormControl('');

  constructor(
    public dialogRef: MatDialogRef<CargoGuidesListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      cargo: Cargo;
      address?: AddressCargo;
    },
    private snackBarService: SnackBarService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private trackGuideService: TrackGuideService,
    private permissionRole: PermissionRole
  ) { }


  ngOnInit() {
    if (this.data.address)
      this.getAddressGuideList();
    else if (this.data.cargo)
      this.getCargoGuideList();
    else {
      this.snackBarService.openSnackBar('No se pudo obtener la lista de guías', undefined, 'error');
      this.dialogRef.close();
    }
  }
  private async getAddressGuideList() {
    try {
      this.guideList = [];
      this.guideList.push({ address: this.data.address, guides: [] });
      const guides = await this.trackGuideService.getGuidesByCargoId(this.data.cargo.id, null, this.data.address.address).toPromise();
      guides.forEach(guide => {
        const index = this.guideList.findIndex(element =>
          guide && guide.recipient && element.address &&
          element.address.address === guide.recipient.address
        );
        if (index !== -1) this.guideList[index].guides.push(guide);
      });
    } catch (error) {
      this.snackBarService.openSnackBar('No se pudo obtener la lista de guías', undefined, 'error');
      this.dialogRef.close();
    }
  }

  private async getCargoGuideList() {
    try {
      this.guideList = [];
      this.data.cargo.cargoFeature.uploadDownload.destination.forEach(download => {
        download.addresses.forEach(address => {
          this.guideList.push({ address, guides: [] });
        });
      });
      const guides = await this.trackGuideService.getGuidesByCargoId(this.data.cargo.id).toPromise();
      guides.forEach(guide => {
        const index = this.guideList.findIndex(element =>
          guide && guide.recipient && element.address &&
          element.address.address === guide.recipient.address
        );
        if (index !== -1) this.guideList[index].guides.push(guide);
      });
    } catch (error) {
      this.snackBarService.openSnackBar('No se pudo obtener la lista de guías', undefined, 'error');
      this.dialogRef.close();
    }

  }

  get filteredGuideList() {
    if (!this.addressFilter.value) return this.guideList;
    return this.guideList.filter(guide => guide.address.address.includes(this.addressFilter.value));
  }

  private refreshGuide(addressIndex: number, guideId: string) {
    this.trackGuideService.getDetailGuide(guideId).toPromise().then(guide => {
      if (!guide) return;
      const index = this.guideList[addressIndex].guides.findIndex(guide => guide.guideId === guideId);
      if (index !== -1) this.guideList[addressIndex].guides[index] = guide;
    }).catch(error => console.error(error));
  }

  openDialogDetailGuide(guide: BasicGuide) {
    this.trackGuideService.getDetailGuide(guide.guideId).subscribe(guide => {
      if (!guide) {
        this.snackBarService.openSnackBar('No fue posible acceder al detalle de la guía', undefined, 'error');
        return;
      }
      const dialogConfig = new MatDialogConfig();
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.data = { guides: [guide] };
      this.dialog.open(GuideListDetailsComponent, dialogConfig);
    }, error => this.snackBarService.openSnackBar('No fue posible acceder al detalle de la guía', undefined, 'error'));
  }

  public canAddNovelty(guide: BasicGuide): boolean {
    return this.permissionRole.hasPermission(this.permission.guide.module, this.permission.guide.addNoveltyToGuide) &&
      guide.generalState !== 'Locked' &&
      ![CargoStateEnum.DELETED, CargoStateEnum.EXPIRED].includes(this.data.cargo.state);
  }



  openDialogAddNovelty(id: string, guideId: string, address: AddressCargo, addressIndex: number) {
    const dialogConfig = new MatDialogConfig();
    const companyId = address.thirdPartyConsignment && address.thirdPartyConsignment.document
      ? address.thirdPartyConsignment.document
      : this.data.cargo.cargoOwner && this.data.cargo.cargoOwner.document
        ? this.data.cargo.cargoOwner.document
        : this.data.cargo.idCompany;
    dialogConfig.data = { id, guideId, companyId };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;

    this.dialog.open(AddGuideNoveltyFormComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result && result.state) this.refreshGuide(addressIndex, guideId);
    });
  }

}

<form class="modal-body wrapper-modal py-5 px-3 px-sm-5" [formGroup]="form" (submit)="onSubmit()">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <h3 class="text-center text-bold mx-3 mb-5">
        {{data.title}}
    </h3>
    <div class="row mt-4">
        <mat-form-field appearance="outline" class="col-12">
            <mat-label class="text-bold">Nombre de la novedad <span class="asterisk">*</span></mat-label>
            <input matInput formControlName="name">
            <mat-error *ngIf="form.get('name').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="form.get('name').errors?.repeatedName">
                Ya existe una novedad con este nombre
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-12">
            <mat-label class="text-bold">Descripción de la novedad<span class="optional">(Opcional)</span></mat-label>
            <textarea matInput formControlName="description" rows="3"></textarea>
        </mat-form-field>
        <div class="col-12 d-block d-sm-flex justify-content-start">
            <p class="text-bold text-wrap-balanced">¿Esta novedad bloquea la entrega de la guía?</p>
            <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="type" class="mw-85">
                <mat-button-toggle value="Anomaly">
                    Si
                </mat-button-toggle>
                <mat-button-toggle value="Normal">
                    No
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="col-12 d-block d-sm-flex justify-content-start" *ngIf="data.isPrincipalCompany">
            <p class="text-bold text-wrap-balanced">¿Esta novedad aplica para todas las compañías?</p>
            <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="global" class="mw-85">
                <mat-button-toggle [value]="true">
                    Si
                </mat-button-toggle>
                <mat-button-toggle [value]="false">
                    No
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="col-12 d-block d-sm-flex justify-content-start">
            <p class="text-bold text-wrap-balanced">
                ¿Esta novedad es visible para los clientes?
                <i class="fas fa-info-circle cursor-pointer font-secondary-dark"
                    matTooltip="Cuando la novedad no es visible para los clientes, se mostrará 'Retorno a bodega' en la guía"></i>
            </p>
            <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="visibleForRecipient" class="mw-85">
                <mat-button-toggle [value]="true">
                    Si
                </mat-button-toggle>
                <mat-button-toggle [value]="false">
                    No
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <div class="button-container mt-4">
        <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
            (click)="dialogRef.close()" type="button">
            Cancelar
        </button>
        <button mat-raised-button color="primary" class="button-width-auto bg-color-primary" type="submit">
            {{data.confirmBtn}}
        </button>
    </div>
</form>
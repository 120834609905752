import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from 'src/app/core/resources/utils';
import { OpenImgService } from './open-img.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';

@Component({
  selector: 'app-open-img',
  templateUrl: './open-img.component.html',
  styleUrls: ['./open-img.component.scss'],
  providers: [OpenImgService]
})
export class OpenImgComponent implements OnInit {

  @Input() src: string;
  @Input() confirmBtn: string;
  @Input() title: string;
  @Input() storage: boolean;
  fileURLReference: SafeResourceUrl = null;
  fileURLPublic: string = null;
  alertMessage: string = '';
  constructor(
    public dialogRef: MatDialogRef<OpenImgComponent>,
    @Inject(MAT_DIALOG_DATA) public paramsDialog: {
      title, src, confirmBtn, storage, alertMessage: string
    },
    public utils: Utils,
    private service: OpenImgService,
    private domSanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private global: Global,
  ) {
    if (paramsDialog.title) {
      this.title = paramsDialog.title;
    }
    if (paramsDialog.src) {
      this.src = paramsDialog.src;
    }
    if (paramsDialog.confirmBtn) {
      this.confirmBtn = paramsDialog.confirmBtn;
    }
    if (paramsDialog.storage) {
      this.storage = paramsDialog.storage;
    }
    if (paramsDialog.alertMessage) this.alertMessage = paramsDialog.alertMessage;

  }

  ngOnInit() {
    if (this.storage) {
      this.getURLStorage();
    } else {
      this.fileURLReference = this.domSanitizer.bypassSecurityTrustResourceUrl(this.src);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  downloadFile() {
    if (!this.confirmBtn) {
      // this.utils.downloadFromUrl(this.src);
      const element = document.createElement('a');
      if (this.storage) {
        element.setAttribute('href', this.fileURLPublic);
      } else {
        element.setAttribute('href', this.src);
      }
      element.setAttribute('target', '_blank');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else {
      this.dialogRef.close({
        state: true
      });
    }
  }

  getURLStorage() {
    const storage = AuthService.fStorage;
    const pathReference = storage.ref(this.src);
    this.spinner.show();
    pathReference.getDownloadURL().then(
      (url) => {
        this.spinner.hide();
        this.fileURLReference = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
        this.fileURLPublic = url;
      },
      (error) => {
        this.spinner.hide();
        this.fileURLReference = null;
      }
    ).catch(() => {
      this.spinner.hide();
    }).finally(() => {
      this.spinner.hide();
    });
  }

}

<div class="main-container m-0 px-3 position-relative">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <label class="title-component mt-4 mr-5">{{data.title}}</label>
  <app-filter-tracking-reports [lastPointLocations]="tracepoints"
    (emitFilteredHistoryNoveltyPoints)="filterTracepoints($event)"></app-filter-tracking-reports>
  <div>
    <div class="card-item" *ngFor="let novelty of filteredHistoryNoveltyPoints">
      <div class="d-flex flex-column w-100">
        <div class="row align-items-end justify-content-between">
          <div class="pl-3 text-bold">
            {{novelty.name?novelty.name:novelty.anomaly}}
            <mat-icon class="cursor-pointer icon-edit" (click)="updateNovelty(novelty)" *ngIf="canEditAnomaly"
              matTooltip="Editar novedad">
              edit
            </mat-icon>
          </div>
          <div class="col-6 date text-right" *ngIf="novelty?.temperature?.value && novelty?.temperature?.unit">
            {{ novelty.temperature.value }}
            °{{novelty.temperature.unit}}
          </div>
          <div class="col-12 observation">
            {{novelty.observation}}
          </div>
        </div>
        <div class="w-100 d-flex flex-wrap justify-content-between mt-2" *ngIf="novelty.anomalyDuration">
          <div class="col-12 col-md-4 d-block date pl-0 mr-2" *ngIf="novelty.anomalyDuration.startDate">
            Inicio: <span class="text-bold">{{novelty.anomalyDuration.startDate | dateFormat:
              'col-date'}}</span>
          </div>
          <div class="col-12 col-md-4 d-block date pl-0 mr-2" *ngIf="novelty.anomalyDuration.endDate">
            Finalización: <span class="text-bold">{{novelty.anomalyDuration.endDate | dateFormat:
              'col-date'}}</span>
          </div>
          <div class="col-12 col-md-2 d-block date pl-0" *ngIf="novelty.anomalyDuration.duration">
            Duración: <span class="text-bold">{{novelty.anomalyDuration.duration | millisecondsDigitalClock}}</span>
          </div>
        </div>
        <div class="w-100 mt-2" *ngIf="novelty.images?.length">
          <small class="mb-0 text-bold">
            Evidencias
          </small>
          <div class="popup-photos mt-0" *ngIf="getNoveltyImages(novelty.id)?.length">
            <div class="photo" *ngFor="let image of getNoveltyImages(novelty.id); let i = index;">
              <img [src]="image?.url" alt="" (click)="openImageNewTab(i, novelty.id)"
                (error)="utils.onErrorRenderImage($event)">
            </div>
          </div>
          <div class="alert alert-warning" role="alert" *ngIf="!getNoveltyImages(novelty.id)?.length">
            No fue posible cargar las evidencias de esta novedad
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
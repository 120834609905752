export const TypeTrips: TypeTrip[] = [
    {
        type: "1",
        name: "Importación",
        label: "Importación",
        image: "/assets/svg/icons/icon-import.svg"
    },
    {
        type: "2",
        name: "Exportación",
        label: "Exportación",
        image: "/assets/svg/icons/icon-export.svg"
    },
    {
        type: "3",
        name: "Nacional",
        label: "Nacional",
        image: "/assets/svg/icons/icon-national.svg"
    },
    {
        type: "4",
        name: "Urbana",
        label: "Urbana",
        image: "/assets/svg/icons/icon-urban.svg",
        tip: "Viajes dentro de la misma ciudad que no corresponden a paqueteo"
    },
    {
        type: "5",
        name: "Última milla",
        label: "Última milla/ Paqueteo urbano",
        image: "/assets/svg/icons/lastmile_icon.svg",
        tip: "Aquellas que corresponden a viajes de paqueteo"
    }
];

export interface TypeTrip {
    type: string;
    name: string;
    image: string;
    tip?: string;
    label?: string;
}
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger, MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, finalize, map, startWith } from 'rxjs/operators';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Company } from 'src/app/core/interfaces/company';
import { CostCenter } from 'src/app/core/interfaces/costCenter';
import { DataDialogCargoBilling } from 'src/app/core/interfaces/dataDialogCargoBilling';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { CompaniesService } from '../../administration/companies/list-companies.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FreightListService } from '../cargo-list/cargo-list.service';
import { CargoBilling } from 'src/app/core/interfaces/cargoBilling';
import { BasicResponse } from 'src/app/core/interfaces/basicResponse';
import { CargoItemService } from '../cargo-item/cargo-item.service';
import ContentTypes from 'src/app/core/resources/content-type-ext.json';
import { OpenImgComponent } from 'src/app/shared/open-img/open-img.component';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { PaymentType } from 'src/app/core/interfaces/paymentType';
import { IntegrationEnum } from 'src/app/core/enums/integration.enum';
import { Router } from '@angular/router';
import { BillingUser } from 'src/app/core/interfaces/billingUser';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { AdminUsersService } from '../../administration/admin-users/admin-users.service';
import { UserClient } from 'src/app/core/interfaces/userClient';

@Component({
  selector: 'app-cargo-billing',
  templateUrl: './cargo-billing.component.html',
  styleUrls: ['./cargo-billing.component.scss']
})
export class CargoBillingComponent implements OnInit {

  listCostCenter: Observable<CostCenter[]>;
  companyCostCenters: CostCenter[] = [];
  costCenters: CostCenter[] = [];
  paymentTypes: PaymentType[] = [];
  form: FormGroup;
  cargos: Cargo[] = [];
  nitCompany: string;
  typeBilling: string;
  showAllCostCenters = new FormControl(false);
  billType: string = 'integration';
  costCenterControl: FormControl = new FormControl('');
  costCenterSub: Subscription;
  listBillingUsers: BillingUser[] = [];
  messageListBillingUsers: string = '';
  sellerControl: FormControl = new FormControl(null);
  defaultSeller: BillingUser;
  @ViewChild(MatAutocompleteTrigger, { static: false, read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;


  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogParams: DataDialogCargoBilling,
    public dialogRef: MatDialogRef<CargoBillingComponent>,
    public utils: Utils,
    public authService: AuthService,
    private companiesService: CompaniesService,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    public cargoListService: FreightListService,
    private cargoItemService: CargoItemService,
    public dialog: MatDialog,
    private router: Router,
    private adminUsersService: AdminUsersService
  ) {
    this.validateDataDialog();
    this.createForm();
  }

  ngOnInit() {
    if (this.hasSiigoIntegration) {
      this.getCompanyCostCenters();
      this.getPaymentMethods();
    } else {
      this.billType = 'exported';
      this.form.get('billingId').setValue('Exportado');
    }
    if (this.isBillIntegration) this.getBillingUsers();
    this.setValidators();
    this.costCenterSub = this.costCenterControl.valueChanges
      .subscribe(value => {
        if (value && value.name && this.utils.isDefined(value.id)) {
          this.form.get('costCenter').setValue(value)
        } else {
          this.form.get('costCenter').setValue('');
        }
      })
  }

  async getLoggedUser(){
    try {
      const userLoggedDocument = this.authService.getUserSession() && this.authService.getUserSession().information && this.authService.getUserSession().information.document;
      const users: UserClient[] = await this.adminUsersService.getUserClientByDocument(userLoggedDocument).toPromise();
      if (users && users.length){
        const user = users[0];
        const billingUser = this.listBillingUsers.find((billingUser: BillingUser) => (billingUser.id).toString() === user.siigoId);
        if (billingUser) {
          this.defaultSeller = billingUser;
          this.sellerControl.setValue(billingUser); 
        }
      }
    } catch (error) {
      console.error("error:", error);
    } 
  }

  createInstanceAutocomplete() {
    if (this.isBillIntegration) {
      this.listCostCenter = this.form.get('costCenter').valueChanges.pipe(
        startWith(''),
        distinctUntilChanged(),
        map(value => {
          const list = this.showAllCostCenters.value ? this.costCenters : this.companyCostCenters;
          let valueName = this.utils.getNestedValue(value, 'name') ? this.utils.getNestedValue(value, 'name') : '';
          return list.filter((option) => {
            return option.name.indexOf(valueName) !== -1;
          });
        })
      );
    }
  }

  validateDataDialog() {
    if (!this.utils.isEmpty(this.dialogParams) && !this.utils.isEmpty(this.dialogParams.cargos)) {
      this.cargos = this.dialogParams.cargos;
    }
    if (!this.utils.isEmpty(this.dialogParams) && !this.utils.isEmpty(this.dialogParams.nitCompany)) {
      this.nitCompany = this.dialogParams.nitCompany;
    }
    if (!this.utils.isEmpty(this.dialogParams) && !this.utils.isEmpty(this.dialogParams.typeBilling)) {
      this.typeBilling = this.dialogParams.typeBilling;
    }
  }

  createForm() {
    this.form = new FormGroup({
      costCenter: new FormControl(''
      ),
      cargos: new FormControl(
        this.utils.getItemsCheked(this.cargos, 'id'),
        [Validators.required]
      ),
      company: new FormControl(
        this.nitCompany,
        [Validators.required]
      ),
      billingId: new FormControl(
        ''
      ),
      observations: new FormControl(
        ''
      ),
      dueDate: new FormControl(
        null
      ),
      paymentType: new FormControl(
        ''
      ),
      sendDIAN: new FormControl(
        false,
      ),
      sendEmail: new FormControl(
        false
      ),
      seller: new FormControl(
        null
      )
    });
  }

  setValidators() {
    if (!this.isBill || (this.isBill && !this.isBillIntegration)) {
      this.form.get('billingId').setValidators([Validators.required]);
      this.form.get('billingId').updateValueAndValidity();
    }
    if (this.isBill && this.isBillIntegration) {
      this.form.get('dueDate').setValidators([Validators.required]);
      this.form.get('dueDate').updateValueAndValidity();

      this.form.get('costCenter').setValidators([Validators.required]);
      this.form.get('costCenter').updateValueAndValidity();

      this.costCenterControl.setValidators(Validators.required);
      this.costCenterControl.updateValueAndValidity();

      this.form.get('billingId').setValidators([]);
      this.form.get('billingId').updateValueAndValidity();

      if (this.hasSiigoIntegration) {
        this.form.get('paymentType').setValidators(Validators.required);
        this.form.get('paymentType').updateValueAndValidity();
      }
    }
    if (this.isBill && !this.isBillIntegration) {
      this.form.get('dueDate').clearValidators();
      this.form.get('dueDate').updateValueAndValidity();

      this.form.get('costCenter').clearValidators();
      this.form.get('costCenter').updateValueAndValidity();

      this.costCenterControl.clearValidators();
      this.costCenterControl.updateValueAndValidity();
    }
  }

  getCompanyCostCenters(): void {
    const company: Company[] = (this.authService.getCompanies() || []).filter((companyObj: Company) => companyObj.companyId === this.nitCompany);
    if (company.length) {
      this.showAllCostCenters.setValue(false);
      this.companyCostCenters = company[0].costCenter || [];
    }
    if (!this.companyCostCenters.length) {
      this.showAllCostCenters.setValue(true);
      this.getCostCenters();
    } else {
      this.createInstanceAutocomplete();
    }
  }

  getCostCenters() {
    if (!this.costCenters.length) {
      this.spinner.show();
      this.companiesService.getCostCenters().toPromise()
        .then((success) => {
          this.costCenters = success;
          this.createInstanceAutocomplete();
        })
        .catch((error) => {
          this.costCenters = [];
        })
        .finally(() => this.spinner.hide());
    }
  }

  getPaymentMethods() {
    this.spinner.show();
    this.companiesService.getPaymentTypes().subscribe(
      (success) => {
        this.spinner.hide();
        if (success && success.length) this.paymentTypes = success;
      },
      (error) => {
        this.spinner.hide();
      }
    )
  }

  displayCostCenterSelected(costCenter: CostCenter) {
    return costCenter ? costCenter.name || '' : '';
  }

  toggleListCostCenter(): void {
    if (this.showAllCostCenters.value) {
      this.getCostCenters();
    }
    this.createInstanceAutocomplete();
  }

  onChangeSeller() {
    const userLoggedDocument = this.authService.getUserSession() && this.authService.getUserSession().information && this.authService.getUserSession().information.document;
    const sellerId = this.sellerControl && this.sellerControl.value && this.sellerControl.value.id;
    if (userLoggedDocument && sellerId){
      const setInfoUserClientObserver = {
        next: (success: { message: string }) => {
          if (success && success.message === "Actualizado") {
            this.defaultSeller = this.sellerControl.value;
            this.snackBarService.openSnackBar(`Se ha establecido a ${this.sellerControl.value.firstName} ${this.sellerControl.value.lastName} como vendedor predeterminado correctamente`);
          }
        },
        error: () => {
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error")
        }
      }
      this.spinner.show();
      this.companiesService.setInfoUserClient(userLoggedDocument, sellerId.toString()).pipe(finalize(() => this.spinner.hide())).subscribe(setInfoUserClientObserver);
    } else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error")
  }

  onSubmit() {
    if (this.sellerControl && this.sellerControl.value && this.sellerControl.value.id) this.form.get('seller').setValue(this.sellerControl.value.id);
    this.form.markAllAsTouched();
    this.costCenterControl.markAsTouched();
    if (this.form.valid && (
      (this.isBill && (this.billType === 'integration' && this.utils.isDefined(this.form.get('costCenter').value.id)) || (this.billType === 'exported')) ||
      !this.isBill)
    ){
      if (this.sellerControl && this.sellerControl.value && this.sellerControl.value.id) this.form.get('seller').setValue(this.sellerControl.value.id);
      this.cashedCargo();
    }
    else {
      this.form.updateValueAndValidity();
      if (this.utils.errorMessagesCustomized(this.form.get('cargos'), 'servicios')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('company'), 'compañía')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('dueDate'), 'fecha de vencimiento')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('costCenter'), 'centro de costo')) return;
      else if (!this.utils.isDefined(this.form.get('costCenter').value.id))
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'centro de costo'), undefined, 'alert');
      else if (this.utils.errorMessagesCustomized(this.form.get('billingId'), this.isBill && !this.isBillIntegration ? 'nº de Factura' : 'nº de Comprobante')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('paymentType'), 'método de pago')) return;
      else {
        this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
      }
    }
    /*if (this.form.valid && (this.isBill && !this.utils.isEmpty(this.billType) && !this.utils.isEmpty(this.form.get('cargos').value) && !this.utils.isEmpty(this.form.get('dueDate').value) && !this.utils.isEmpty(this.form.get('costCenter').value) && this.form.get('costCenter').value.id > 0) || !this.isBill) {
      this.cashedCargo();
    }*/
  }

  cashedCargo() {
    this.spinner.show();
    let body: CargoBilling = this.utils.clone(this.form.value);
    body.costCenter = this.form.value.costCenter.id;
    if (!this.isBillIntegration) {
      delete body.costCenter;
      delete body.observations;
      delete body.dueDate;
      delete body.sendDIAN;
      delete body.sendEmail;
    } else {
      delete body.billingId;
      if (!this.hasSiigoIntegration) delete body.paymentType;
    }
    body.cargos = this.cargos.map((cargo) => {
      return cargo.id;
    });
    this.cargoListService.cashedCargo(body, this.typeBilling).toPromise()
      .then((success: BasicResponse) => {
        if (success.message === "Update") {
          this.snackBarService.openSnackBar('Se ha enviado la información correctamente');
          if (this.isBillIntegration) {
            if (this.showAllCostCenters.value) {
              this.associateCostCenter(this.form.value.costCenter);
            }
            this.getCargoInvoicePDF(this.form.value.cargos[0]);
          } else {
            this.dialogRef.close({ state: true });
          }
        } else if (success.message) {
          this.spinner.hide();
          this.snackBarService.openSnackBar(success.message, undefined, 'error');
        } else {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error al realizar la facturación, contacte al Administrador', undefined, 'error');
        }
      })
      .catch((error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al enviar la información', undefined, 'error');
      });
  }

  associateCostCenter(costCenter: CostCenter) {
    this.spinner.show();
    this.companiesService.associateCostCenter(costCenter, this.nitCompany).toPromise()
      .then((success: BasicResponse) => {
        if (success.message === "Update" || success.message === "Actualizado") {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Centro de costo asociado correctamente');
        } else if (success.message) {
          this.spinner.hide();
          this.snackBarService.openSnackBar(success.message, undefined, 'error');
        } else {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error asociando el Centro de costo', undefined, 'error');
        }
      })
      .catch((error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al enviar la información', undefined, 'error');
      });
  }

  getCargoInvoicePDF(cargoId: string) {
    this.cargoItemService.getCargoInvoicePDF(cargoId).toPromise()
      .then((response) => {
        var file = new Blob([response], { type: ContentTypes.pdf });
        var fileURL = URL.createObjectURL(file);
        this.openDocumentExtra(response, fileURL);
        this.dialogRef.close({ state: true });
      })
      .catch(() => this.snackBarService.openSnackBar('Ocurrió un error al cargar la factura', undefined, 'error'))
      .finally(() => this.spinner.hide())
  }

  openDocumentExtra(blob: Blob, url: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `Factura`,
      src: url,
      confirmBtn: 'Descargar'
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(OpenImgComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        this.utils.downloadFile(blob, 'Factura', ContentTypes.pdf);
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  onChangeDatePicker($event) {
    this.form.patchValue({ dueDate: DateManager.dateToString($event.value, 'YYYY-MM-DD') });
  }

  goToCostCenter() {
    this.router.navigate(['/administration/companies/detail', this.nitCompany], {
      state: { tab: 'Centros de Costo' }
    });
    this.dialogRef.close();
  }

  getBillingUsers() {
    const listBillingUsers = {
      next: async (billingUsers: BillingUser[]) => {
        if (billingUsers && billingUsers.length) {
          this.listBillingUsers = billingUsers;
          await this.getLoggedUser();
        }
        else this.messageListBillingUsers = Fmt.string(ServiceMessages.NO_DATA_FOUND, 'vendedores');
        this.spinner.hide();
      },
      error: () => {
        this.messageListBillingUsers = Fmt.string(ServiceMessages.ERROR_GETTING_DATA, 'vendedores')
        this.spinner.hide();
      }
    }
    this.spinner.show();
    this.companiesService.listBillingUsers().subscribe(listBillingUsers);
  }

  ngOnDestroy() {
    if (this.costCenterSub) this.costCenterSub.unsubscribe();
  }

  get isBill() {
    return this.typeBilling === 'bill';
  }

  get isBillIntegration() {
    return this.isBill && this.billType === 'integration';
  }

  get hasSiigoIntegration(): boolean {
    return this.authService.getCompany().integrationCredentials.some(integration => integration && integration.integrationChannel && integration.integrationType &&
      integration.integrationChannel.toLowerCase() === IntegrationEnum.SIIGO.toLowerCase() && integration.state && integration.integrationType.toLowerCase() === "billing"
    );
  }

}

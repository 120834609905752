import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { AuthService } from "src/app/core/services/authentication.service";
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { Global } from "src/app/core/resources/global";
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  FormGroup,
  Validators,
  FormControl,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { AdminUsersService } from "../admin-users.service";
import { Utils } from "src/app/core/resources/utils";
declare var google: any;
import { User } from "src/app/core/interfaces/user";
import { Role } from "src/app/core/interfaces/role";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { Patterns } from "src/app/core/resources/patterns";
import { CompanyNamePipe } from "src/app/core/pipe/companyName.pipe";
import { OpenImgComponent } from "src/app/shared/open-img/open-img.component";
import { DateFormatPipe } from "src/app/core/pipe/dateFormat.pipe";
import { Permission } from "src/app/core/resources/permission";
import { ReactiveForm } from "src/app/core/resources/reactive-form";
import { Model } from "src/app/core/interfaces/vehicle";
import { PermissionRole } from "src/app/core/resources/permission-role";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { ServiceMessages } from "src/app/core/messages/service-messages.enum";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { UserClientModel } from "src/app/core/models/userClient.model";
import { UserClient } from "src/app/core/interfaces/userClient";
import { FirebaseApi } from "src/app/core/classes/firebase-api";
import { Subscription } from "rxjs";
import { DateManager } from "src/app/core/managers/date.manager";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-user-client-form",
  templateUrl: "./user-client-form.component.html",
  styleUrls: ["./user-client-form.component.scss"],
  providers: [AuthService, Global, CompanyNamePipe, DateFormatPipe, Model, DatePipe],
})
export class UserClientFormComponent implements OnInit {
  @ViewChild("modalPDFTruora", { static: false }) modalPDFTruora;
  @ViewChild("modalConfirmActivateOrDesactivate", { static: false })
  modalConfirmActivateOrDesactivate;

  permission = Permission;
  formUser: FormGroup;
  disabledForm: boolean;
  urlPDFTruora: string = "";
  documenTypes: Array<Object> = [
    {
      id: "1",
      name: "Cédula de ciudadanía",
    },
    {
      id: "2",
      name: "Pasaporte",
    },
  ];
  roles: Role[] = [];
  user = new UserClientModel();
  originalUser: User;
  reactiveFormDriver: ReactiveForm;
  companySelected: FormControl = new FormControl();
  currentValueName: string = "";
  currentValueAddress: string = "";
  currentValueCity: string = "";
  currentValueNitCompany: string = "";
  currentValueSimpleRegimen: boolean = false;
  company: FormControl = new FormControl({
    nit: "",
    exclusive: ""
  }, Validators.required);
  validateCompany: string = 'disable';
  optionsCompany = {
    title: 'Operación'
  };
  companySub: Subscription;
  firebaseUserUnsubscribe;
  userInfoSupport = [];
  constructor(
    public adminUsersService: AdminUsersService,
    private router: Router,
    public utils: Utils,
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    public adminUserService: AdminUsersService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserClientFormComponent>,
    public patterns: Patterns,
    private authService: AuthService,
    private companyNamePipe: CompanyNamePipe,
    private dateFormatPipe: DateFormatPipe,
    public modelVehicle: Model,
    private permissionRole: PermissionRole,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA)
    public paramsDialog: {
      driver;
      expireArl?;
      expireEps?;
    }
  ) {
    this.initDriverForm();
  }

  /**
  * @description Verifies the dialogs params and route params to initialize the user
  */
  ngOnInit(): void {
    this.setNit();
    if (this.paramsDialog && this.paramsDialog.driver) {
      this.validateDialogParams();
    } else {
      let userDocument = null;
      const params = this.route.snapshot.params;
      if (!this.utils.objIsEmpty(params)) {
        if (this.utils.isDefined(params.id)) {
          userDocument = params.id;
        }
      }
      this.disabledForm = true;
      this.getDetailUserByDocument(userDocument);
    }
  }

  /**
  * @description Makes a subscription to company value changes to update the form's nitCompany
  */
  private setNit(): void {
    this.companySub = this.company.valueChanges
      .subscribe(value => {
        if (value && value.companyId) this.getForm().nitCompany.setValue(this.companyNamePipe.transform(value.companyId));
        else if (this.optionsCompany['initialNit']) this.getForm().nitCompany.setValue(this.optionsCompany['initialNit']);
        else this.getForm().nitCompany.setValue(null);
      })
  }

  /**
  * @description Initializes the user's form group
  */
  private initDriverForm(): void {
    this.reactiveFormDriver = new ReactiveForm(
      this.formBuilder,
      this.modelVehicle.modelCreate.driver
    );
  }

  /**
  * @description Validates the paramDialog "driver" to use "setUserInfo" and "setSubscriptionAndUpdateUser" methods
  */
  private validateDialogParams(): void {
    if (this.utils.isDefined(this.paramsDialog.driver)) {
      this.setUserInfo(this.paramsDialog.driver);
      this.setSubscriptionAndUpdateUser(this.paramsDialog.driver.information.document);
    }
  }

  /**
  * @param {User} user is the user to fill the form
  * @description Fills the form with the param user, also uses "setModificableValues" and "getRolesCompany" methods
  */
  private setUserInfo(user: User): void {
    this.originalUser = this.utils.clone(user);
    this.reactiveFormDriver.form.patchValue(user);
    this.setModificableValues();
    this.user.deserialize(user);
    this.getRolesCompany(this.user.clientCompanyId);
    this.disabledForm = true;
  }

  /**
  * @param {string} document is the document of the user to subscribe
  * @description Subscribes to user collection to get the changes and use the "setUserInfo" method
  */
  private async setSubscriptionAndUpdateUser(document: string) {
    try {
      const doc = await FirebaseApi.firestore.collection('UserClient').doc(document).get();
      if (doc.exists) {
        this.firebaseUserUnsubscribe = FirebaseApi.firestore.collection('UserClient').doc(document).onSnapshot((snapshot) => {
          const updatedUser = snapshot.data() as User;
          this.setUserInfo(updatedUser);
        });
      } else {
        console.error("No es posible acceder a la referencia de Firestore del usuario");
      }
    } catch (error) {
      console.error("No es posible acceder a la referencia de Firestore del usuario");
    }
  }

  /**
  * @description Assigns to currentValue variables and optionsCompany's initialNit the info of the user form
  */
  private setModificableValues(): void {
    this.currentValueName = this.reactiveFormDriver.form.value.information.name;
    this.currentValueAddress = this.reactiveFormDriver.form.value.address;
    this.currentValueCity = this.reactiveFormDriver.form.value.city;
    this.currentValueNitCompany = this.reactiveFormDriver.form.value.nitCompany;
    this.optionsCompany['initialNit'] = this.reactiveFormDriver.form.value.nitCompany;
    this.currentValueSimpleRegimen = this.reactiveFormDriver.form.value.simpleRegimen;
  }

  /**
  * @param {string} nitCompany is the nit of the company to get its roles
  * @description Gets the list of roles and executes the "initFormUser" method
  */
  private getRolesCompany(nitCompany: string): void {
    this.adminUsersService.getRolesCompany(nitCompany).subscribe(
      (success: Role[]) => {
        this.roles = success;
        this.initFormUser();
      },
      (error) => {
        this.roles = [];
        this.initFormUser();
      }
    );
  }

  /**
  * @param {string} userDocument is the document of the user to get
  * @param type is the type of the user
  * @description Gets the detail of the user and assigns it to user variable and executes the "setUserInfo" and "setSubscriptionAndUpdateUser" methods
  */
  getDetailUserByDocument(userDocument: string, type?): void {
    this.spinner.show();
    this.adminUsersService.getUserClientByDocument(userDocument).subscribe(
      (success: UserClient[]) => {
        if (success && success.length) {
          this.user = new UserClientModel();
          this.setUserInfo(success[0]);
          this.setSubscriptionAndUpdateUser(success[0].information.document);
        } else {
          this.user = new UserClientModel();
          this.originalUser = null;
        }
        this.spinner.hide();
      },
      (error) => {
        if (type) this.userInfoSupport = [];
        else {
          this.user = new UserClientModel();
          this.originalUser = null;
          this.spinner.hide();
          this.backView();
        }
      }
    );
  }

  /**
  * @description Initializes the form group "formUser"
  */
  private initFormUser(): void {
    this.formUser = new FormGroup({
      document: new FormControl({
        value: this.user.information.document || "",
        disabled: this.disabledForm,
      }),
      email: new FormControl(
        {
          value: this.user.email || "",
          disabled: this.disabledForm,
        },
        [
          Validators.required,
          Validators.pattern(this.patterns.EMAIL.source),
          Validators.maxLength(100)
        ]
      ),
      idDocumentType: new FormControl({
        value: this.user.information.documentTypeId || "1",
        disabled: this.disabledForm,
      }),
      name: new FormControl(
        {
          value: this.user.information.name || "",
          disabled: this.disabledForm,
        },
        Validators.required
      ),
      phone: new FormControl(
        {
          value: this.user.phone || "",
          disabled: this.disabledForm,
        },
        Validators.required
      ),
      role: new FormControl({
        value: this.user.role || "",
        disabled: this.disabledForm,
      }),
      nitCompany: new FormControl({
        value: this.companyNamePipe.transform(this.user.clientCompanyId),
        disabled: this.disabledForm,
      }),
      companyNit: new FormControl(
        {
          value: this.user.clientCompanyId || "",
          disabled: this.disabledForm,
        },
        [Validators.required]
      ),
      roleUserToActive: new FormControl({
        value: "",
      }),
    });
    this.formUser.get("roleUserToActive").setValue(this.user.roleId);
  }

  /**
  * @returns {{[key:string]: AbstractControl}} returns the controls of the form group "formUser"
  * @description Gets the controls of the form group "formUser"
  */
  private getForm(): { [key: string]: AbstractControl } {
    return this.formUser.controls;
  }

  /**
  * @description Navigates to admin-users list
  */
  backView() {
    this.router.navigate(["/administration/admin-users/list"]);
  }

  /**
  * @param {'change'|'activate'} type is the operation type
  * @description Activates a userClient and can assigns him a role
  */
  private activateUserClient(type: 'change' | 'activate'): void {
    if (this.getForm().roleUserToActive.value.length) {
      let roleUserToActive = this.getForm().roleUserToActive.value;

      this.spinner.show();
      this.adminUsersService
        .activateUserClient(this.user.information.document, roleUserToActive)
        .subscribe(
          (success: UserClient) => {
            this.spinner.hide();
            if (type === 'activate') this.snackBarService.openSnackBar(ServiceMessages.USER_ACTIVATED);
            else if (type === 'change') this.snackBarService.openSnackBar(ServiceMessages.USER_CHANGE_SUCCESS);
            else this.snackBarService.openSnackBar(ServiceMessages.USER_DEACTIVE_SUCCESS_GENERAL);
            if (this.paramsDialog && this.paramsDialog.driver) this.dialogRef.close({ state: true });
            else this.getDetailUserByDocument(this.getForm().document.value);
          },
          (error) => {
            this.spinner.hide();
            this.snackBarService.openSnackBar(
              "Ocurrió un error al " +
              (type === 'change' ? 'cambiar' : "activar") +
              " a " +
              this.user.information.name,
              undefined,
              "error"
            );
          }
        );
    } else {
      this.hideModals();
      this.snackBarService.openSnackBar(
        "Debe seleccionar un rol para hacer la activación del usuario",
        undefined,
        "error"
      );
    }
  }

  /**
  * @description Opens a modal to confirm the user's activation
  */
  confirmActive(): void {
    this.formUser.get('roleUserToActive').setValidators(Validators.required);
    this.formUser.get('roleUserToActive').updateValueAndValidity();
    this.formUser.get('roleUserToActive').markAsTouched();
    if (this.utils.errorMessagesCustomized(this.formUser.get('roleUserToActive'), 'rol')) return;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Estás seguro que deseas activar a ${this.user
        .information.name}?`,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state)
        this.activateUserClient("activate");
    });
  }

  /**
  * @description Opens a modal to confirm the user's role changing
  */
  changeRole(): void {
    if (!this.user.state.active) {
      this.snackBarService.openSnackBar(FormMessages.INACTIVE_USER, undefined, 'alert');
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Estás seguro que deseas cambiarle el rol a ${this.user
        .information.name}?`,
      hideBtnConfirm: false,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state)
        this.activateUserClient('change');
    });
  }

  /**
  * @description Executes the modalService's "dismissAll" method
  */
  hideModals() {
    this.modalService.dismissAll();
  }

  /**
  * @param {'ARL'|'EPS'} type is the type of the user's document
  * @description Approves the user's document and executes the "getDetailUserByDocument" method
  */
  private userActivateAffiliation(type: 'ARL' | 'EPS'): void {
    this.adminUserService
      .userActivateAffiliation(
        type,
        this.authService.getUserSession().information.name,
        this.originalUser.information.document,
        'Driver'
      )
      .toPromise()
      .then((success: any) => {
        this.getDetailUserByDocument(this.originalUser.information.document);
        this.snackBarService.openSnackBar("Soporte aprobado correctamente");
      })
      .catch((error: any) => {
        this.snackBarService.openSnackBar(
          "Ocurrió un error al aprobar el soporte",
          undefined,
          "error"
        );
      })
      .finally(() => {
        if (this.paramsDialog && this.paramsDialog.driver) {
          this.dialogRef.close({ state: true });
        }
        this.spinner.hide();
      });
  }

  /**
  * @param {'ARL'|'EPS'} type is the type of the user's document
  * @param {string} url is the URL of the document
  * @param {string} dueDate is the due date of the document
  * @description Shows a modal to approve the document
  */
  private openDocumentExtra(type: 'ARL' | 'EPS', url: string, dueDate: string): void {
    const dialogConfig = new MatDialogConfig();
    let formatDueDate: Date = DateManager.stringToDate(dueDate);
    dialogConfig.data = {
      title: `Validar soporte - ${type}`,
      src: url,
      confirmBtn: "Aprobar",
      alertMessage: DateManager.isBefore(formatDueDate, new Date()) ? `No es posible aprobar el documento ya que este se encuentra vencido. Fecha de vencimiento: ${this.formatDate(formatDueDate)}` : ''
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(OpenImgComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.confirmApproveExtraDocument(type);
      }
    });
  }
  /**
* @param {Date} date is the date to transform
* @returns {string} returns the date on expected format
* @description Transforms a date format into specific string format
*/
  formatDate(date: Date): string {
    return this.datePipe.transform(date, "d MMMM yyyy");
  }

  /**
  * @param {'ARL'|'EPS'} type is the type of the user's document
  * @param mode defines if the document has to be opened in a window
  * @description Gets the user's document from storage and shows it
  */
  getURLDocument(type: 'ARL' | 'EPS', mode?) {
    const extraDocument = this.adminUserService.getExtraDocumentByType(
      type,
      this.originalUser
    );
    if (extraDocument && extraDocument.path) {
      const storage = AuthService.fStorage;
      const pathReference = storage.ref(extraDocument.path);
      this.spinner.show();
      pathReference.getDownloadURL().then(
        (url) => {
          this.spinner.hide();
          if (url) {
            if (mode) window.open(url)
            else this.openDocumentExtra(type, url, extraDocument.dueDate);
          } else this.snackBarService.openSnackBar(FormMessages.NOT_LOAD_IMAGE, undefined, 'alert');
        },
        (error) => {
          this.spinner.hide();
          if (error && error.code_ && error.code_.includes('object-not-found')) this.snackBarService.openSnackBar(FormMessages.NOT_EXIST_IMAGE, undefined, 'error')
          else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        }
      );
    }
  }

  /**
  * @param {'ARL'|'EPS'} type is the type of the user's document
  * @description Opens a modal to confirm the document approval
  */
  private confirmApproveExtraDocument(type: 'ARL' | 'EPS') {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Estás seguro que deseas aprobar la ${type}?`,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.userActivateAffiliation(type);
      }
    });
  }

  /**
  * @returns {boolean} returns if the activate/deactivate user's button must to be shown
  * @description Indicates if the button of activate/deactivate user must to be shown
  */
  get showButtonInactivateActivateUser(): boolean {
    if (!this.user || !this.user.state || !this.utils.isDefined(this.user.state.active)) return false;
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.disableEnableUsers
    );
  }

  /**
  * @description Opens a dialog to confirm the user's deactivate
  */
  openDialogDeactivateUser(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `Escriba la razón por la cual desea desactivar este usuario`,
      textArea: true,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state && result.message) {
        this.deactivateUserClient(result.message);
      }
    });
  }

  /**
  * @param {string} reason is the reason to deactivate a userClient
  * @description Deactivates a userClient and executes the "getDetailUserByDocument" method
  */
  private deactivateUserClient(reason: string) {
    const idUser: string = this.getForm().document.value;
    this.spinner.show();
    this.adminUserService.deactivateUserClient(idUser, reason).subscribe(
      (success) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.USER_DEACTIVATED);
        this.getDetailUserByDocument(this.originalUser.information.document);
      },
      (error) => {
        if (error.error && error.error.message) {
          this.snackBarService.openSnackBar(
            error.error.message,
            undefined,
            "error"
          );
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrio un error al desactivar el usuario",
            undefined,
            "error"
          );
        }
        this.spinner.hide();
      }
    );
  }

  /**
  * @description Unsubscribes to subscriptions if ii's required
  */
  ngOnDestroy() {
    if (this.firebaseUserUnsubscribe) this.firebaseUserUnsubscribe();
    if (this.companySub) this.companySub.unsubscribe();
  }
}

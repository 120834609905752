import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { CargoTypeComponent, HiddenFieldsCargoType } from '../cargo-detail/components/cargo-type/cargo-type.component';
import { CargoCharacteristicComponent, HiddenFieldsCargoCharacteristic } from '../cargo-detail/components/cargo-characteristic/cargo-characteristic.component';
import { HiddenFieldsLoadDownloadCargo, LoadDownloadCargoComponent } from '../cargo-detail/components/load-download-cargo/load-download-cargo.component';
import { HiddenFieldsGeneralDataCargo, GeneralDataCargoComponent } from '../cargo-detail/components/general-data-cargo/general-data-cargo.component';
import { CargoConsignment, CargoEditConsignmentAddress, formBasicModel, formModel } from 'src/app/core/interfaces/cargoEditConsignment';
import { CargoResources } from '../manual-creation-cargo/resources/cargo';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from 'src/app/core/resources/utils';
import { CargoDetailService } from '../cargo-detail/cargo-detail.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Permission } from 'src/app/core/resources/permission';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { ConsignmentCargo } from 'src/app/core/interfaces/consignmentCargo';
import { Origin } from 'src/app/core/interfaces/origin';
import { Destination } from 'src/app/core/interfaces/destination';
import { ConsignmentCityOption } from 'src/app/shared/consignment-card/consignment-card.component';
import { PackagingType } from 'src/app/core/interfaces/packagingType';
import { ProductType } from 'src/app/core/interfaces/productType';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { DateManager } from 'src/app/core/managers/date.manager';
import { ThirdParty, ThirdPartyAddress } from 'src/app/core/interfaces/thirdParty';
import { LocationAddress } from 'src/app/core/interfaces/locationAddress';
import { ThirdPartyConsignmentService } from '../manual-creation-cargo/components/third-party-consignment/third-party-consignment.service';
import { Global } from 'src/app/core/resources/global';

@Component({
  selector: 'app-consignment-form',
  templateUrl: './consignment-form.component.html',
  styleUrls: ['./consignment-form.component.scss'],
  providers: [CargoResources]
})
export class ConsignmentFormComponent implements OnInit {
  private destinationId: number;
  private addressId: number;
  public consignmentId: string;
  public consignmentForm: FormGroup;
  public consignment: ConsignmentCargo;
  private cargo: Cargo;
  senderCityOptions: { [key: string]: ConsignmentCityOption } = {};
  recipientCityOptions: { [key: string]: ConsignmentCityOption } = {};
  consignmentFieldsToCheck: { field: string, alias: string }[] = this.global.consignmentFieldsToCheck;
  formValidate = '';

  constructor(
    private formBuilder: FormBuilder,
    private cargoResources: CargoResources,
    private utils: Utils,
    private global: Global,
    private cargoDetailService: CargoDetailService,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogParams: {
      consignmentId: string,
      consignment: ConsignmentCargo,
      destinationId: number,
      addressId: number,
      cargo: Cargo
    },
    public dialogRef: MatDialogRef<ConsignmentFormComponent>,
    private thirdPartyConsignmentService: ThirdPartyConsignmentService
  ) {
  }

  ngOnInit() {
    this.checkData();
    this.setCityOptions();
    this.initForm();
  }

  private checkData() {
    if (this.dialogParams && this.utils.isDefined(this.dialogParams.destinationId) && this.utils.isDefined(this.dialogParams.addressId
      && this.utils.isDefined(this.dialogParams.consignmentId) && this.utils.isDefined(this.dialogParams.cargo) &&
      this.utils.isDefined(this.dialogParams.consignment)
    )) {
      this.destinationId = this.dialogParams.destinationId;
      this.addressId = this.dialogParams.addressId;
      this.consignmentId = this.dialogParams.consignmentId;
      this.cargo = this.dialogParams.cargo;
      this.consignment = this.dialogParams.consignment;
    } else {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      this.dialogRef.close();
    }
  }

  private setCityOptions() {
    const origin: Origin = this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.origin');
    const destination: Destination[] = this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.destination');
    this.senderCityOptions = origin && origin.name
      ? {
        [origin.name]: {
          municipalityCode: origin.municipalityCode,
          index: 0,
          countryCode: origin.country && origin.country.code ? origin.country.code : 'CO',
          date: this.utils.getNestedValue(this.cargo, 'dateLoad'),
          addresses: origin.addresses && origin.addresses.length ? origin.addresses.map((address, index) => {
            return {
              address: address && address.address ? address.address : '',
              location: address && address.location && address.location.lat && address.location.lng ? address.location : { lat: 0, lng: 0 },
              time: address && address.time ? address.time : null,
              index: index
            }
          }) : []
        }
      }
      : {};
    if (!destination || !destination.length) { this.recipientCityOptions = {}; return; }
    destination.forEach((city, index) => {
      if (!city || !city.name) return;
      this.recipientCityOptions[city.name] = {
        municipalityCode: city.municipalityCode ? city.municipalityCode : '',
        index: index,
        countryCode: city.country && city.country.code ? city.country.code : 'CO',
        date: city.downloadDate ? city.downloadDate : '',
        addresses: city.addresses && city.addresses.length ? city.addresses.map((address, indexCity) => {
          return {
            address: address && address.address ? address.address : '',
            location: address && address.location && address.location.lat && address.location.lng ? address.location : { lat: 0, lng: 0 },
            time: address && address.time ? address.time : null,
            index: indexCity
          }
        }) : []
      }
    });
  }

  private initForm() {
    this.consignmentForm = new ReactiveForm(this.formBuilder, this.utils.clone(this.dialogParams.consignment)).form;
  }

  private get documentId(): string {
    return this.consignmentId ? this.consignmentId.replace(/[^0-9]/g, '') : null;
  }

  public async onSubmit() {
    this.formValidate = 'touched';
    for (const field of this.consignmentFieldsToCheck) {
      if (!this.consignmentForm.get(field.field) || (this.consignmentForm.get(field.field).value !== 0 && !this.consignmentForm.get(field.field).value))
        return this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, field.alias), undefined, 'alert');
    }
    const consignmentToUpdate: ConsignmentCargo = this.consignmentForm.value;
    const { success, consignment, error } = await this.checkConsignmentsBranchOffice(consignmentToUpdate);
    if (!success) return { success: false, error: error ? error : 'Ocurrió un error al guardar las sucursales de las remesas' };
    this.spinner.show();
    this.cargoDetailService.updateConsignmentRNDC(consignment, this.documentId).subscribe(
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(`Remesa ${this.consignmentId} actualizada correctamente`);
        this.dialogRef.close({ state: true });
      },
      (error: any) => {
        this.spinner.hide();
        const errorMessage = error && error.error && error.error.message ? error.error.message : "Error al actualizar la remesa";
        this.snackBarService.openSnackBar(errorMessage, undefined, 'error');
      }
    );

  }

  private async checkConsignmentsBranchOffice(consignment: ConsignmentCargo): Promise<{ success: boolean, consignment: ConsignmentCargo, error?: string }> {
    const consignmentToCheck: ConsignmentCargo = this.utils.clone(consignment);
    const senderBranchOffice = await this.checkThirdPartyAddress(consignmentToCheck.sender, consignmentToCheck.sender.municipalityCode, consignmentToCheck.sender.location, consignmentToCheck.sender.address);
    if (senderBranchOffice.branchOffice) consignment.sender.branchOffice = senderBranchOffice.branchOffice;
    else return { success: false, consignment: null, error: senderBranchOffice.error };
    const recipientBranchOffice = await this.checkThirdPartyAddress(consignment.recipient, consignment.recipient.municipalityCode, consignment.recipient.location, consignment.recipient.address);
    if (recipientBranchOffice.branchOffice) consignment.recipient.branchOffice = recipientBranchOffice.branchOffice;
    else return { success: false, consignment: null, error: recipientBranchOffice.error };
    consignment.errorCreation = null;
    consignment.errorFinalFulfillment = null;
    consignment.errorInitialFulfillment = null;
    return { success: true, consignment: consignmentToCheck };
  }

  private async checkThirdPartyAddress(thirdParty: ThirdParty, municipalityCode: string, location: LocationAddress, address: string): Promise<{ branchOffice: string, error?: string }> {
    const thirdPartyAddresses = await this.getThirdPartyAddresses(thirdParty.documentNumber, municipalityCode);
    const thirdPartyAddress = thirdPartyAddresses.find((thirdPartyAddress) => thirdPartyAddress && thirdPartyAddress.address &&
      thirdPartyAddress.address.location && thirdPartyAddress.address.location.lat === location.lat &&
      thirdPartyAddress.address.location.lng === location.lng);
    if (thirdPartyAddress) return { branchOffice: thirdPartyAddress.address.id };
    const created = await this.createThirdPartyAddress(thirdParty, municipalityCode, { address: address, location: location });
    if (!created || !created.success) return { branchOffice: '', error: created && created.error ? created.error : `Ocurrió un error al guardar la dirección ${address} del tercero` };
    const newThirdPartyAddresses = await this.getThirdPartyAddresses(thirdParty.documentNumber, municipalityCode);
    const newThirdPartyAddress = newThirdPartyAddresses.find((thirdPartyAddress) => thirdPartyAddress && thirdPartyAddress.address &&
      thirdPartyAddress.address.location && thirdPartyAddress.address.location.lat === location.lat &&
      thirdPartyAddress.address.location.lng === location.lng);
    if (newThirdPartyAddress) return { branchOffice: newThirdPartyAddress.address.id };
    return { branchOffice: '' };
  }

  async getThirdPartyAddresses(thirdPartyDocument: string, municipalityCode: string): Promise<ThirdPartyAddress[]> {
    let thirdPartyAddresses: ThirdPartyAddress[] = [];
    try {
      thirdPartyAddresses = await this.thirdPartyConsignmentService.getThirdPartiesAddressByCompany(thirdPartyDocument, null, null, municipalityCode).toPromise();
    } catch (error) { console.error(error) }
    return thirdPartyAddresses && thirdPartyAddresses.length ? thirdPartyAddresses : [];
  }

  async createThirdPartyAddress(
    thirdParty: ThirdParty,
    municipalityCode: string,
    address: { address: string, location: LocationAddress }
  ): Promise<{ success: boolean, error?: string }> {
    const data = {
      information: {
        documentTypeId: thirdParty.documentType,
        documentTypeName: this.utils.toCapitalize(
          this.utils.getDocumentType(thirdParty.documentType).name
        ),
        document: thirdParty.documentNumber,
        name: thirdParty.name
      },
      parent: null,
      address: address,
      municipalityCode: municipalityCode
    };
    let response: ThirdPartyAddress;
    try { response = await this.thirdPartyConsignmentService.createThirdParty(data).toPromise(); } catch (error) { }
    return { success: !!(response && response.address && response.address.id), error: response && response.errorRNDC ? response.errorRNDC.error : '' };
  }
}

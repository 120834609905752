import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OptionsAutocomplete } from "src/app/core/interfaces/optionsAutocomplete";
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { StandardMapComponent } from 'src/app/shared/standard-map/standard-map.component';
import { MenuServiceService } from '../../menu-service/menu-service.service';
import { Router } from '@angular/router';
import { Fmt } from 'src/app/core/messages/fmt';
import { DateManager } from 'src/app/core/managers/date.manager';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { Subscription } from 'rxjs';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Patterns } from 'src/app/core/resources/patterns';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Cargo } from 'src/app/core/interfaces/cargo';

@Component({
  selector: 'app-origin-load-service-form',
  templateUrl: './origin-load-service-form.component.html',
  styleUrls: ['./origin-load-service-form.component.scss']
})

export class OriginLoadServiceFormComponent implements OnInit {
  permission = Permission;
  originControl = new FormControl('', Validators.required);
  initialAddresses: { initialValue: string }[] = [];

  readonly baseOriginCityOptions: OptionsAutocomplete = {
    title: 'Origen (Municipio o Ciudad)',
    appearance: 'outline',
    requireFullCity: true
  };
  originCityOptions: OptionsAutocomplete = {
    ...this.baseOriginCityOptions,
    isInternational: false,
    requireFullCity: true
  };

  readonly loadDateOptions = { min: new Date() };
  loadDateControl = new FormControl(null, Validators.required);
  isInternationalControl = new FormControl(false);

  originAddresses = new FormArray([]);
  originAddressOptions = {
    title: 'Dirección de origen',
    appearance: 'outline',
    required: true,
    showXMark: true
  };
  formValidate = '';

  readonly reteicaError = 'La ciudad de origen no tiene asignado un ReteIca por favor asocielo o cambie de ciudad';
  formSubscription: Subscription = new Subscription();

  constructor(
    public utils: Utils,
    public menuService: MenuServiceService,
    private fb: FormBuilder,
    private router: Router,
    private snackBarService: SnackBarService,
    private patterns: Patterns,
    private permissionRole: PermissionRole
  ) { }

  /**
* @description Executes the "initForm" method
*/
  ngOnInit() {
    this.setLocalValues();
    this.setSubscriptions();
  }

  private setLocalValues() {
    this.initialAddresses = [];
    const originCountryCode = this.utils.getNestedValue(this.menuService.form.value, 'cargoFeature.uploadDownload.origin.country.code');
    const originCountryName = this.utils.getNestedValue(this.menuService.form.value, 'cargoFeature.uploadDownload.origin.country.name');
    const reteica = this.utils.getNestedValue(this.menuService.form.value, 'shippingCost.reteicaPercentage');
    this.isInternationalControl.setValue(originCountryCode !== 'CO');
    const originCity = this.utils.getNestedValue(this.menuService.form.value, 'cargoFeature.uploadDownload.origin.name');
    const originCityId = this.utils.getNestedValue(this.menuService.form.value, 'cargoFeature.uploadDownload.origin.municipalityCode');
    this.originCityOptions = {
      ...this.baseOriginCityOptions,
      initialValue: originCity ? originCity : '',
      isInternational: !!this.isInternationalControl.value
    };
    this.originControl.setValue({
      name: originCity ? originCity : '',
      id: originCityId ? originCityId : '',
      country: originCountryName ? originCountryName : 'Colombia',
      code: originCountryCode ? originCountryCode : 'CO',
      reteica: reteica
    });
    const loadDate = this.utils.getNestedValue(this.menuService.form.value, 'dateLoad');
    this.loadDateControl.setValue(DateManager.stringToDate(loadDate));
    
    this.originAddresses.clear();
    this.addAddressForm();
    const originAddresses = this.utils.getNestedValue(this.menuService.form.value, 'cargoFeature.uploadDownload.origin.addresses');
    originAddresses.forEach((address: AddressCargo, index: number) => {
      if (index) this.addAddressForm();
      this.initialAddresses.push({ initialValue: address && address.address ? address.address : '' });
      this.originAddresses.at(index).get('address').setValue({
        address: address && address.address ? address.address : '',
        location: {
          lat: address && address.location && address.location.lat ? address.location.lat : 0,
          lng: address && address.location && address.location.lng ? address.location.lng : 0
        }
      });
      this.originAddresses.at(index).get('time').setValue(address && address.time ? address.time : '00:01');
      this.originAddresses.at(index).get('cellphone').setValue(address && address.cellphone ? address.cellphone : '');
    });
    this.checkPermissionsToEdit();
  }

  private setSubscriptions() {
    this.formSubscription = this.menuService.formObservable.subscribe(() => this.setLocalValues());
  }

  private checkPermissionsToEdit() {
    if (!this.canEditOriginLoadService) {
      this.formValidate = 'disable';
      this.originControl.disable();
      this.isInternationalControl.disable();
      this.loadDateControl.disable();
      this.originAddresses.controls.forEach(address => {
        address.get('address').disable();
        address.get('time').disable();
        address.get('cellphone').disable();
      });
    }
  }

  get canSeeOriginLoadService(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.showLoadDownloadModule
    );
  }

  get canEditOriginLoadService(): boolean {
    return this.canSeeOriginLoadService && this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.editLoadDownloadModule
    );
  }

  onChangeInternational() {
    this.originControl.setValue('');
    this.originAddresses.controls.forEach(addressOrigin => {
      addressOrigin.get('address').setValue('');
    });
    this.originCityOptions = {
      ...this.baseOriginCityOptions,
      isInternational: !!this.isInternationalControl.value,
      initialValue: ''
    };
  }

  addAddressForm() {
    this.originAddresses.push(this.getAddressForm());
  }

  private getAddressForm(): FormGroup {
    return this.fb.group({
      address: ['', Validators.required],
      time: ['00:01', Validators.required],
      cellphone: ['', [Validators.minLength(10), Validators.pattern(this.patterns.ONLY_NUMBERS.source)]]
    });
  }

  removeSenderAddress(index: number) {
    this.originAddresses.removeAt(index);
  }

  get showErrorReteica(): boolean {
    if (!this.menuService.isRequiredReteica()) return false;
    const originCity = this.originControl.value;
    const reteica = originCity && originCity.reteica
      ? originCity.reteica
      : originCity && originCity.mainCity && originCity.mainCity.reteica
        ? originCity.mainCity.reteica
        : 0;
    const countryCode = originCity ? originCity.code ? originCity.code : 'CO' : null;
    return countryCode === 'CO' && !reteica;
  }

  onSelectAddress($event: "" | { address: string, location: { lat: number, lng: number } }, indexAddress: number) {
    if ($event === "") this.originAddresses.at(indexAddress).get('address').setValue('');
    else this.originAddresses.at(indexAddress).get('address').setValue($event);
  }

  get isInternationalTrip(): boolean {
    return this.utils.getNestedValue(this.menuService.form.value, 'cargoModel.tripType.name') === 'Internacional';
  }

  backToPreviousStep() {
    this.router.navigate([Fmt.string(this.menuService.steps.contractor.url, this.menuService.cargo.consecutive)]);
  }

  async saveAndContinue() {
    if (this.showErrorReteica)
      return this.snackBarService.openSnackBar(this.reteicaError, undefined, 'error');
    const isValidEstimatedTime = this.isValidEstimatedTime();
    if (!isValidEstimatedTime.isValid)
      return this.snackBarService.openSnackBar(isValidEstimatedTime.error, undefined, 'alert');

    this.menuService.form.get('cargoFeature.uploadDownload.origin.name').setValue(
      this.originControl.value && this.originControl.value.name ? this.originControl.value.name : ''
    );
    this.menuService.form.get('cargoFeature.uploadDownload.origin.municipalityCode').setValue(
      this.originControl.value && this.originControl.value.id ? this.originControl.value.id : ''
    );
    this.menuService.form.get('cargoFeature.uploadDownload.origin.country.name').setValue(
      this.originControl.value && this.originControl.value.country ? this.originControl.value.country : 'Colombia'
    );
    this.menuService.form.get('cargoFeature.uploadDownload.origin.country.code').setValue(
      this.originControl.value && this.originControl.value.code ? this.originControl.value.code : 'CO'
    );

    this.menuService.form.get('cargoFeature.uploadDownload.origin.reteica').setValue(
      this.originControl.value && this.originControl.value.reteica ? this.originControl.value.reteica : 0
    );
    const originReteica = this.originControl.value && this.originControl.value.reteica
      ? this.originControl.value.reteica
      : this.originControl.value && this.originControl.value.mainCity && this.originControl.value.mainCity.reteica
        ? this.originControl.value.mainCity.reteica
        : 0;

    this.menuService.form.get('shippingCost.reteicaPercentage').setValue(
      originReteica && !this.isInternationalControl.value
        ? originReteica
        : 0
    );
    this.menuService.form.get('dateLoad').setValue(DateManager.utcTransform(DateManager.setStartOfDay(this.loadDateControl.value)));
    this.originAddresses.controls.forEach((address: FormGroup, index: number) => {
      if (!this.menuService.form.get('cargoFeature.uploadDownload.origin.addresses').at(index))
        this.menuService.form.get('cargoFeature.uploadDownload.origin.addresses').push(this.menuService.createOriginAddressGroup());
      const formAddress = this.menuService.form.get('cargoFeature.uploadDownload.origin.addresses').at(index);
      const localAddress = address.value;
      formAddress.get('address').setValue(localAddress && localAddress.address && localAddress.address.address ? localAddress.address.address : '');
      formAddress.get('location.lat').setValue(localAddress && localAddress.address && localAddress.address.location && localAddress.address.location.lat ? localAddress.address.location.lat : 0);
      formAddress.get('location.lng').setValue(localAddress && localAddress.address && localAddress.address.location && localAddress.address.location.lng ? localAddress.address.location.lng : 0);
      formAddress.get('cellphone').setValue(localAddress && localAddress.cellphone ? localAddress.cellphone : '');
      formAddress.get('time').setValue(localAddress && localAddress.time ? localAddress.time : '00:01');
      formAddress.get('timeType').setValue('');
    });
    const formOriginAddresses = this.menuService.form.get('cargoFeature.uploadDownload.origin.addresses') as FormArray;
    for (let i = formOriginAddresses.controls.length - 1; i >= 0; i--) {
      if (i >= this.originAddresses.controls.length)
        formOriginAddresses.removeAt(i);
    }
    const formValue: Cargo = this.menuService.form.value;
    if (formValue.dateLoad && formValue.cargoFeature.uploadDownload.origin.addresses.length > 1 &&
      formValue.cargoFeature.uploadDownload.origin.addresses.every(address => !!(address.address && address.location.lat && address.location.lng && address.time))) {
      const checkTripTimes = await this.menuService.checkTripTimes(formValue, true, false);
      if (!checkTripTimes.valid)
        return this.snackBarService.openSnackBar(checkTripTimes.error, undefined, 'error');
    }

    this.menuService.updateService().then(() => {
      this.snackBarService.openSnackBar("Servicio actualizado exitosamente");
      this.continueToNextStep();
    }).catch(() => this.snackBarService.openSnackBar('Ocurrió un error al actualizar el servicio', undefined, 'error'));
  }

  continueToNextStep() {
    this.router.navigate([Fmt.string(this.menuService.steps.destination.url, this.menuService.cargo.consecutive)]);
  }

  private isValidEstimatedTime(): { isValid: boolean, error: string } {
    let currentTime: Date;
    for (let i = 0; i < this.originAddresses.controls.length; i++) {
      const address = this.originAddresses.controls[i];
      const time = address.get('time').value;
      const timeDate = new Date(`1990-01-01T${time}`);
      if (currentTime && DateManager.isBefore(timeDate, currentTime, 'minutes'))
        return { isValid: false, error: `La hora de la dirección ${i + 1} es anterior a la dirección ${i}` };
      currentTime = timeDate;
    }
    return { isValid: true, error: '' };
  }

  ngOnDestroy() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }
}

<i class="fas fa-ellipsis-v color-secondary-dark text-strong" mat-icon-button [matMenuTriggerFor]="menu"
  *ngIf="(addressList && addressList[(typeAddress === 'Origin' ? 'o-' + index : parent + '-' + index)]) || address.state === 'Pickup load' || address.state === 'Cargo unloaded'"></i>

<mat-menu #menu="matMenu">
  <!-- Origen -->
  <button mat-menu-item (click)="nextStep('ARRIVED_TO_PICKUP_CARGO')" *ngIf="showDriverArrivedToPickupCargo">
    <mat-icon><i class="menu-icon fas fa-map-marker-alt"></i></mat-icon>
    <span>{{isEscortedService? 'El escolta llegó al origen': 'El conductor llegó a cargar'}}</span>
  </button>

  <button mat-menu-item (click)="nextStep('PICKUP_LOAD')" *ngIf="showDriverPickupLoadButton">
    <mat-icon><i class="menu-icon fas fa-box"></i></mat-icon>
    <span>{{isEscortedService? 'Vehículo listo para escoltar':'Se terminó de cargar'}}</span>
  </button>

  <button mat-menu-item (click)="nextStep('SHOW_EVIDENCE')" *ngIf="showPickupEvidenceButton">
    <mat-icon><i class="menu-icon fas fa-receipt"></i></mat-icon>
    <span>Ver evidencias</span>
  </button>

  <!-- Destino -->
  <button mat-menu-item (click)="nextStep('DESTINATION_ARRIVED')" *ngIf="showDriverArrivedToDownloadCargo">
    <mat-icon><i class="menu-icon fas fa-map-marker-alt"></i></mat-icon>
    <span>{{isEscortedService? 'El escolta llegó al destino':'El conductor llegó a descargar'}}</span>
  </button>

  <button mat-menu-item (click)="nextStep('CARGO_UNLOADED')" *ngIf="showDriverDownloadedButton && permissionTodownload">
    <mat-icon><i class="menu-icon fas fa-truck-loading"></i></mat-icon>
    <span>{{isEscortedService? 'Acompañamiento completado en destino':'Se terminó de descargar'}}</span>
  </button>

  <button mat-menu-item (click)="openDialogGuides()" *ngIf="showGuidesButton">
    <mat-icon><i class="menu-icon fas fa-file-alt"></i></mat-icon>
    <span>Ver guías</span>
  </button>
</mat-menu>
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { RoutingService } from '../../routing.service';
import { Scheme } from 'src/app/core/interfaces/scheme';

@Component({
  selector: 'app-routing-scheme-form',
  templateUrl: './routing-scheme-form.component.html',
  styleUrls: ['./routing-scheme-form.component.scss'],
})
export class RoutingSchemeFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() stepper: MatStepper;
  schemes: Scheme[] = [];
  optimizationMode = {
    1: "Menor cantidad de vehículos en el menor tiempo posible.",
    2: "Optimización a partir de vehículos pre asignados.",
    3: "Distribución equitativa de direcciones entre los vehículos disponibles.",
    4: "Optimización del tiempo utilizando todos los vehículos."
  }

  constructor(
    public utils: Utils,
    public snackBarService: SnackBarService,
    public spinner: NgxSpinnerService,
    private routingService: RoutingService,
  ) { }

  /**
  * @description Executes the "getSchemes" method.
  */
  ngOnInit(): void {
    this.getSchemes();
  }

  /**
  * @description Gets the schemes list from backend
  */
  private getSchemes(): void {
    this.spinner.show();
    const sub = this.routingService.getSchemes().subscribe(
      (success) => {
        this.spinner.hide();
        this.schemes = success && success.length ? success : [];
      }, (error) => {
        this.spinner.hide();
        this.schemes = [];
        console.error(error);
      }, () => {
        sub.unsubscribe();
      }
    )
  }

  /**
  * @param {Scheme} scheme is the scheme to compare
  * @returns {boolean} returns true if the scheme to compare is the same as saved from form's scheme
  * @description Verifies if the current scheme is the same as form's scheme
  */
  isSelectedScheme(scheme: Scheme): boolean {
    return this.form.get('schemaName').value && this.form.get('schemaName').value === scheme.name;
  }

  /**
  * @param {Scheme} scheme is the scheme to check its mode
  * @returns {boolean} returns the scheme optimization mode
  * @description Gets the scheme optimization mode
  */
  getOptimizationMode(scheme: Scheme): string {
    return scheme && scheme.optimizationType && this.optimizationMode[scheme.optimizationType]
      ? this.optimizationMode[scheme.optimizationType]
      : this.optimizationMode[1];
  }

  /**
  * @param {Scheme} scheme is the scheme selected
  * @description Assigns the current scheme's name to the form's control "schemaName"
  */
  onSelectScheme(scheme: Scheme): void {
    this.form.get('schemaName').setValue(scheme.name);
    this.form.get('schemaCode').setValue(scheme.code);
    this.form.get('fleetFromSchema').setValue(!!scheme.fleet);
    if (scheme.fleet)
      this.form.get('fleetName').setValue(scheme.fleet);
  }

  /**
  * @description Verifies if the form's control "schemaName" is valid to allow pass to next step
  */
  onSubmit() {
    if (this.form.get('schemaName').invalid) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'ningún esquema'), undefined, 'alert');
      return;
    }
    this.stepper.next();
  }

  /**
  * @returns {boolean} returns true if the form's control "schemaName" is valid.
  * @description Verifies if the current step is valid
  */
  get isValidStep(): boolean {
    return this.form && this.form.get('schemaName') && this.form.get('schemaName').valid
      && this.form.get('schemaCode') && this.form.get('schemaCode').valid;
  }

}

<form [formGroup]="form" *ngIf="form" class="col-md-12">

  <div *ngIf="existingUser && typeAction === 'createDriver'" class="alert alert-warning" role="alert">
    El usuario digitado ya existe si deseas ir al detalle haz click <a
      [routerLink]="['/administration/admin-users/detail', existingUser]"
      class="color-secondary-dark text-bold cursor-pointer"><u>aquí</u></a>
  </div>
  <ng-container *ngIf="typeAction === 'editDriver' || typeAction === 'createDriver' || typeAction === 'createVehicle'">
    <ng-container
      *ngTemplateOutlet="editSection; context: { section: 'basicInformation', titleSection: title, fieldsEnabled: basicInformationEnabled, permissionSection: canEditBasicFields}"></ng-container>
  </ng-container>

  <div class="row">
    <!-- documentType -->
    <div class="col-md-4">
      <mat-form-field appearance="standard" formGroupName="information">
        <mat-label>Tipo identificación<span class="asterisk"
            *ngIf="utils.isRequiredField(form.get('information.documentTypeId'))">*</span></mat-label>
        <mat-select (selectionChange)="onChangeDocumentType($event)" formControlName="documentTypeId"
          [displayWith]="displayFnDocumentType" [returnWith]="returnFnDocumentType">
          <mat-option *ngFor="let type of documenTypes" [value]="type.id">
            {{type.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('information.documentTypeId').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- document -->
    <div class="col-md-4">
      <app-input-document-user [inputFormControl]="documentControl" [options]="optionsDocumentUser"
        [validate]="validateDocument" [typeAction]="typeAction" (emitExistingUser)="emitExistingUser($event)">
      </app-input-document-user>
    </div>
    <!-- Name -->
    <div class="col-md-4">
      <mat-form-field appearance="standard" formGroupName="information">
        <mat-label>Nombre<span class="asterisk"
            *ngIf="utils.isRequiredField(form.get('information.name'))">*</span></mat-label>
        <input matInput type="text" name="tlInput" formControlName="name" [disabled]="true">
        <mat-error *ngIf="form.get('information.name').errors?.pattern">
          {{ utils.giveMessageError('INCORRECT_FORMAT', 'nombre') }}
        </mat-error>
        <mat-error *ngIf="form.get('information.name').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- phoneNumber -->
  <div class="row">
    <div class="col-md-4" *ngIf="form.get('phone')">
      <mat-form-field appearance="standard">
        <mat-label>Celular<span class="asterisk" *ngIf="utils.isRequiredField(form.get('phone'))">*</span></mat-label>
        <input matInput type="text" onlyNumbersInt name="tlInput" formControlName="phone">
        <mat-error *ngIf="form.get('phone').errors?.pattern">
          {{ utils.giveMessageError('INCORRECT_FORMAT', 'celular') }}
        </mat-error>
        <mat-error *ngIf="form.get('phone').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="form.get('phone').errors?.minlength">
          {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'celular', 7) }}
        </mat-error>
        <mat-error *ngIf="form.get('phone').errors?.maxlength">
          {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'celular', 12) }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- expeditionDate -->
    <div class="col-md-4 mt-3 mt-md-0"
      *ngIf="(title.includes('Conductor') || requiresDriversFields) && form.get('expeditionDateId')">
      <mat-form-field appearance="standard">
        <mat-label>Fecha de expedición<span class="asterisk"
            *ngIf="utils.isRequiredField(expeditionDateId)">*</span></mat-label>
        <input type="text" matInput [matDatepicker]="datePicker" readonly (click)="datePicker.open()"
          (dateChange)="onChangeDatePicker($event)" [formControl]="expeditionDateId"
          [disabled]="form.get('expeditionDateId').disabled" [max]="today">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="expeditionDateId.errors?.required && !form.get('expeditionDateId').disabled">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- Profile Picture-->
    <div class="col-md-4" *ngIf="(title.includes('Conductor') || requiresDriversFields) && form.get('email')">
      <mat-form-field appearance="standard">
        <mat-label>Correo electrónico<span class="asterisk"
            *ngIf="utils.isRequiredField(form.get('email'))">*</span></mat-label>
        <input type="text" matInput formControlName="email">
        <mat-icon matSuffix class="cursor-pointer" color="primary" *ngIf="form.get('email').errors?.pattern"
          matTooltip="No se permiten caracteres especiales al principio('.test@mail.com'), caracteres especiales seguidos ('te..st@mail.com'), más de un arroba ('te@st@mail.com'), espacios ('te st@mail.com'), caracteres especiales al terminar el dominio ('test.@mail.com'), ni correos de extensión mayor a 100 caracteres">help</mat-icon>
        <mat-error *ngIf="form.get('email').errors?.pattern">
          {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo') }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- City -->
    <div class="col-md-4">
      <app-autocomplete-city [options]="optionsCity" [inputFormControl]="cityControl" [validate]="validateCity">
      </app-autocomplete-city>
    </div>
    <!-- Address -->
    <div class="col-md-4" [style.height]="'84.83px'">
      <app-autocomplete-address [title]="'Dirección'" [required]="!onlyRequiredFieldsInForm" [simpleAddress]="true"
        (emitToParent)="onSelectAddress($event)" [value]="form.get('address').value"
        [disabled]="form.get('address').disabled">
      </app-autocomplete-address>
      <span class="pattern pattern--invalid pattern--document"
        *ngIf="form.get('address') && form.get('address').errors && form.get('address').errors.required">
        Campo obligatorio
      </span>
    </div>

    <ng-container *ngIf="typeAction === 'editDriver'">
      <ng-container *ngTemplateOutlet="extraInformationUser"></ng-container>
    </ng-container>

    <div class="col-md-4 simple-regimen">
      <mat-label>¿Es régimen simple?</mat-label>
      &nbsp;&nbsp;
      <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="simpleRegimen">
        <mat-button-toggle [value]="true">
          Si
        </mat-button-toggle>
        <mat-button-toggle [value]="false">
          No
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <ng-container
    *ngIf="((typeAction === 'createDriver' && authService?.getCompanySaaS()?.allowCreatingWithoutTruora) || (typeAction === 'editDriver'))">
    <!-- User docs and certifications -->
    <div class="row title-2 text-strong color-secondary-dark">
      <div class="col-md-12 p-2">
        Información de seguridad social
      </div>
    </div>
    <app-user-docs-and-certifications
      *ngIf="form && ((form?.get('information.document')?.value && typeAction === 'editDriver') || (typeAction === 'createDriver'))"
      class="row" [form]="form" [typeAction]="typeAction" [visualArl]="visualArl" [visualEps]="visualEps"
      [visualOperationalAccreditation]="visualOperationalAccreditation" [title]="title"
      (onUploadDocument)="onUploadDocument($event)" [loadingResources]="loadingResources"
      [getDocsStateValidation]="getDocsStateValidation()"></app-user-docs-and-certifications>
    <!-- Licenses -->
    <ng-container *ngIf="title.includes('Conductor') || typedDocRules?.includes('driverLicense')">
      <ng-container *ngIf="typeAction === 'editDriver' || typeAction === 'createDriver'">
        <ng-container
          *ngTemplateOutlet="editSection; context: { section: 'licenses', titleSection: 'Información de licencia de conducción', fieldsEnabled: licensesEnabled, permissionSection: canOverwriteVehicleInfo}"></ng-container>
      </ng-container>
      <div class="row"
        *ngIf="driverLicenseCategory && driverLicenseCategory.length && (authService?.getCompanySaaS()?.allowCreatingWithoutTruora || (!authService?.getCompanySaaS()?.allowCreatingWithoutTruora) && form?.get('state.description')?.value !== 'Pending')">
        <ng-container *ngFor="let licenseForm of driverLicenseCategory.controls; let i = index">
          <app-license-form [form]="licenseForm" [options]="{inputStyle: 'standard'}" [index]="i"
            [enableDeleteLicense]="(typeAction === 'createDriver' || (typeAction === 'editDriver' && licenseForm.get('preview').value) && licensesEnabled)"
            (emitIndexLicense)="deleteLicenseElement($event)"></app-license-form>
        </ng-container>
      </div>
      <div class="alert alert-warning mt-2" role="alert"
        *ngIf="!authService?.getCompanySaaS()?.allowCreatingWithoutTruora && form?.get('state.description')?.value === 'Pending'">
        Debes esperar a que termine el estudio de seguridad para poder visualizar los datos de la licencia de conducción
      </div>
      <div class="d-flex actions-container"
        *ngIf="form?.get('state.description')?.value !== 'Pending' || authService?.getCompanySaaS()?.allowCreatingWithoutTruora">
        <app-manual-data-vehicle-input type="manualButton" dataType="license" [typeAction]="typeAction"
          [checkId]="!!(form?.get('truora.checkId')?.value)" [haveData]="this.driverLicenseCategory?.length"
          class="actions-container--button" (emitServiceTrigger)="addLicenseForm($event)"
          [licensesEnabled]="licensesEnabled || typeAction === 'createDriver'"></app-manual-data-vehicle-input>
        <span *ngIf="licensesEnabled || typeAction === 'createDriver'" [style.color]="'#DC3545'"
          class="actions-container--alert">
          (Máximo 3 licencias)
        </span>
      </div>
    </ng-container>
    <!-- Contacts and references -->
    <ng-container *ngIf="title.includes('Conductor')">
      <ng-container *ngIf="typeAction === 'editDriver'">
        <ng-container
          *ngTemplateOutlet="editSection; context: { section: 'contactsAndReferences', titleSection: 'Referencias y contactos', fieldsEnabled: contactsAndReferencesEnabled, permissionSection: canEditContactsAndReferences }"></ng-container>
      </ng-container>
      <app-vehicle-contacts *ngIf="typeAction === 'editDriver'" [form]="form" [fromCreation]="true"
        [typeAction]="typeAction" [onlyDriver]="true">
      </app-vehicle-contacts>
    </ng-container>
  </ng-container>
  <!-- Extra information user -->
  <ng-template #extraInformationUser>
    <!-- Score Truora -->
    <div class="col-md-4"
      *ngIf="isDriver || (isOwner && paramsDialog && (paramsDialog.showDocuments || paramsDialog.driverIsSameOwner || paramsDialog.ownerIsSameAdmin)) || (isAdmin && paramsDialog.showDocuments)">
      <mat-form-field appearance="standard">
        <mat-label>Score de Seguridad<span class="asterisk">*</span></mat-label>
        <input matInput type="text" [formControl]="scoreTruoraControl">
      </mat-form-field>
    </div>
    <!-- Score loggiapp -->
    <div class="col-md-4" *ngIf="isDriver">
      <mat-form-field appearance="standard">
        <mat-label>Score de {{extraInformationUserOptions?.isTeclogiUserOrClient ? 'Teclogi' : 'LoggiApp'}}<span
            class="asterisk">*</span></mat-label>
        <input matInput type="text" [formControl]="scoreTeclogiControl">
      </mat-form-field>
    </div>

  </ng-template>
  <ng-template #editSection let-section="section" let-titleSection="titleSection" let-fieldsEnabled="fieldsEnabled"
    let-permissionSection="permissionSection">
    <div class="mt-3 edit-section-container">
      <div class="row title-2 text-strong color-secondary-dark">
        <div class="col-md-12 p-2">
          {{titleSection}}
        </div>
      </div>
      <ng-container
        *ngIf="(form?.get('state.description')?.value !== 'Pending' || section === 'contactsAndReferences' || authService?.getCompanySaaS()?.allowCreatingWithoutTruora) && permissionSection && typeAction === 'editDriver'">
        <span *ngIf="!fieldsEnabled; else cancelEditSection"
          class="cursor-pointer color-primary text-bold p-2 edit-section" (click)="onEditSection(section, 'edit')"><i
            class="fas fa-edit"></i> Editar sección</span>
      </ng-container>
      <ng-template #cancelEditSection>
        <div class="d-flex align-items-center">
          <span class="cursor-pointer text-bold p-2 edit-section edit-section--cancel"
            (click)="onEditSection(section, 'cancel')"><i class="fas fa-times"></i> Cancelar edición</span>
          <span class="cursor-pointer color-primary text-bold p-2 edit-section edit-section--save"
            (click)="onEditSection(section, 'save')"><i class="fas fa-save"></i> Guardar</span>
        </div>
      </ng-template>
    </div>
  </ng-template>

</form>
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Utils } from 'src/app/core/resources/utils';
import { CargoResources } from '../../../manual-creation-cargo/resources/cargo';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CargoDetailService } from '../../../cargo-detail/cargo-detail.service';
import { ModelCargo } from '../../../manual-creation-cargo/resources/modelCargo';
import { AngularFireStorage } from '@angular/fire/storage';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { DatePipe } from '@angular/common';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
import { CargoFeature } from 'src/app/core/interfaces/cargoFeature';
import { CargoModel } from 'src/app/core/interfaces/cargoModel';
import { ErrorsFieldsExcel } from 'src/app/core/interfaces/errorsFieldsExcel';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';

export interface HiddenFieldsCargoType {
  tripType?: boolean;
  regimeType?: boolean;
  tripTypeDate?: boolean;
  letterRetirement?: boolean
  cargoType?: boolean;
  containerMeasure?: boolean;
  serviceType?: boolean;
  observation?: boolean;
  observationDriver?: boolean;
}

export interface BodyCargoType {
  id: string;
  state: string;
  cargoFeature: CargoFeature;
  cargoModel: CargoModel;
  observation: string;
  observationDriver: string;
  errorFieldsExcelCargo: ErrorsFieldsExcel;
}

@Component({
  selector: 'app-cargo-type',
  templateUrl: './cargo-type.component.html',
  styleUrls: ['./cargo-type.component.scss'],
  providers: [AuthService, Global, ModelCargo, DatePipe]
})
export class CargoTypeComponent extends ReactiveForm implements OnInit {
  @Input() cargo: Cargo;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Output() cleanCargo: EventEmitter<any> = new EventEmitter();
  @Input() hiddenFields: HiddenFieldsCargoType = {};
  public progress: number = 0;
  public types: { label: string, value: TRIP_TYPES }[] = [
    { label: "Importación", value: TRIP_TYPES.IMPORT },
    { label: "Exportación", value: TRIP_TYPES.EXPORT },
    { label: "Nacional", value: TRIP_TYPES.NATIONAL },
    { label: "Internacional", value: TRIP_TYPES.INTERNATIONAL },
    { label: "Urbana", value: TRIP_TYPES.URBAN },
    { label: "Última milla/ Paqueteo urbano", value: TRIP_TYPES.LAST_MILE },
  ];
  public regimeTypes = [
    { name: "Exportación", types: ["DUTA", "DTAI", "Nacionalizado"] },
    { name: "Importación", types: ["OTM", "DUTA", "Nacionalizado"] }
  ]
  public containerTypes = [
    { size: 'Un contenedor de 45 pies', code: '10' },
    { size: 'Un contenedor de 40 pies', code: '9' },
    { size: 'Dos contenedores de 20 pies', code: '8' },
    { size: 'Un contenedor de 20 pies', code: '7' }
  ]
  public cargoTypes = [
    {
      name: "Carga suelta",
      types: ["Carga expresa", "Carga consolidada"]
    },
    {
      name: "Contenedor",
      types: ["Contenedor vacío express", "Contenedor lleno express", "Contenedor vacío con carga de compensación", "Contenedor lleno consolidado"]
    }
  ]
  public dateFieldDTA = new FormControl('');
  public storageRef: any;
  public uploadTask: any;
  public urlDocCtrl = new FormControl('');
  onUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
  docUrl: string = '';
  constructor(
    public dateFormatPipe: DateFormatPipe,
    public formBuilder: FormBuilder,
    public utils: Utils,
    private cargoResources: CargoResources,
    private snackBarService: SnackBarService,
    private cargoDetailService: CargoDetailService,
    private spinner: NgxSpinnerService,
    public modelCargo: ModelCargo,
    private angularFireStorage: AngularFireStorage,
    public datePipe: DatePipe
  ) {
    super(
      formBuilder,
      utils.clone(cargoResources.cargoMock),
    );
  }

  ngOnInit(): void {
    this.form.patchValue(this.cargo);
    if (this.cargo && ((this.cargo.state && this.cargo.state !== CargoStateEnum.REQUEST) || (this.cargo.requestState && this.cargo.requestState !== CargoStateEnum.REQUEST))) {
      // this.form.get('cargoModel').disable();
    }
    this.form.get('observation').setValidators(Validators.required);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cargo && changes.cargo.currentValue) this.form.patchValue(this.cargo);
  }

  get filteredRegimeTypes() {
    let tripType = this.form.get('cargoModel.tripType.name').value;
    if (tripType === "Exportación") return this.regimeTypes[0].types;
    else return this.regimeTypes[1].types;
  }

  public isSameCity(): boolean {
    const originCity = this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.origin.name');
    const destinations = this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.destination');
    return destinations.every((city) => city.name === originCity);
  }

  changeTripType($event) {
    this.form.get('cargoModel.tripType.regimeType').setValue('');
    this.form.get('cargoModel.tripType.letterRetirement').setValue('');
    this.form.get('cargoModel.tripType.date').setValue('');
    this.dateFieldDTA.setValue('');
    this.form.get('cargoModel.cargoType.name').setValue($event && $event.value === "Última milla" ? "Carga suelta" : '');
    this.form.get('cargoModel.cargoType.containerMeasure.size').setValue('');
    this.form.get('cargoModel.serviceType.name').setValue('');
  }

  changeRegimeType($event) {
    if ($event.value !== "DTA" && $event.value !== "DUTA" && $event.value !== "OTM") {
      this.form.get('cargoModel.tripType.date').setValue('');
      this.dateFieldDTA.setValue('');
    }
    this.form.get('cargoModel.cargoType.name').setValue('');
    this.form.get('cargoModel.cargoType.containerMeasure.size').setValue('');
    this.form.get('cargoModel.serviceType.name').setValue('');
  }

  onDateSelected(event: string) {
    const dateFormatted = this.dateFormatPipe.transform(
      this.dateFieldDTA.value,
      "date-time"
    );
    this.form.get('cargoModel.tripType.date').setValue(dateFormatted);
  }

  changeCargoType($event) {
    this.form.get('cargoModel.cargoType.containerMeasure.size').setValue('');
    this.form.get('cargoModel.serviceType.name').setValue('');
  }

  changeContainerMeasure($event) {
    this.form.get('cargoModel.serviceType.name').setValue('');
  }

  get filteredServiceTypes() {
    if (this.form.get('cargoModel.cargoType.name').value === 'Contenedor') {
      return this.cargoTypes[1].types;
    } else {
      return this.cargoTypes[0].types;
    }
  }

  public handleFileInput(data) {
    this.spinner.show();
    this.storageRef = this.angularFireStorage.ref(this.form.get('cargoModel.tripType.letterRetirement').value);
    this.uploadTask = this.angularFireStorage.upload(this.form.get('cargoModel.tripType.letterRetirement').value, data);
    this.uploadTask.then(
      (result) => {
        this.storageRef.getDownloadURL().subscribe(
          (urlFile) => {
            this.spinner.hide();
            this.docUrl = urlFile;
          }
        );
        this.form.get('cargoModel.tripType.letterRetirement').setValue(`Cargo/${this.cargo.id}/letterRetirement/${data.name}`);
      },
      (error) => {
        this.snackBarService.openSnackBar(FormMessages.ERROR_LOAD_RESOURCE, undefined, 'error');
        this.spinner.hide();
      }
    );
  }

  public createBodyToSubmit(): BodyCargoType {
    let data: BodyCargoType = {
      id: this.cargo.id,
      state: this.cargo.state,
      observation: this.form.getRawValue().observation,
      observationDriver: this.form.getRawValue().observationDriver,
      cargoFeature: {
        productType: {
          name: this.form.getRawValue().cargoFeature.productType.name,
          code: this.form.getRawValue().cargoFeature.productType.code,
          type: this.form.getRawValue().cargoFeature.productType.type,
        }
      },
      cargoModel: this.form.getRawValue().cargoModel,
      errorFieldsExcelCargo: this.cargo.errorFieldsExcelCargo,
    };

    if (!data.errorFieldsExcelCargo)
      data.errorFieldsExcelCargo = {}
    data.errorFieldsExcelCargo.cargoTypeName = null;
    data.errorFieldsExcelCargo.serviceTypeName = null;

    return data;
  }

  public isInvalidFormData(): boolean {
    this.form.markAllAsTouched();
    let formValid = this.formValid();
    if (!formValid) {
      this.form.updateValueAndValidity();
      this.dateFieldDTA.updateValueAndValidity();
      return true;
    }
    return false;
  }

  async onSubmit() {
    const isInvalid = await this.isInvalidFormData();
    if (isInvalid) return;
    this.setOtherFields();
    const data: BodyCargoType = await this.createBodyToSubmit();
    this.updateRequest(data);
  }

  setOtherFields() {
    const serviceTypeName = this.form.getRawValue().cargoModel.serviceType.name;

    if (serviceTypeName === 'Contenedor vacío con carga de compensación' || serviceTypeName === 'Contenedor vacío express') {
      this.form.get('cargoFeature.productType.name').setValue('CONTENEDOR VACIO');
      this.form.get('cargoFeature.productType.code').setValue('009990');
      this.form.get('cargoFeature.productType.type').setValue('No');
      this.form.get('cargoModel.cargoNature').patchValue({
        code: '1',
        description: 'Carga Normal'
      });
      this.form.get('cargoModel.packagingType.code').setValue('18');
      this.form.get('cargoModel.packagingType.description').setValue('Unidad sin empaque');
    } else if (serviceTypeName === 'Carga consolidada') {
      this.form.get('cargoFeature.productType.code').setValue('009880');
      this.form.get('cargoFeature.productType.type').setValue('No');
      this.form.get('cargoFeature.productType.name').setValue('MISCELANEOS CONTENIDOS EN PAQUETES ( PAQUETEO )');
      this.form.get('cargoModel.cargoNature').patchValue({
        code: '1',
        description: 'Carga Normal'
      });
      this.form.get('cargoModel.packagingType.code').setValue('0');
      this.form.get('cargoModel.packagingType.description').setValue('Paquetes. General Fraccionada en máximo 2 kgs por unidad de empaque');
    }
    const tripType = this.form.get('cargoModel.tripType.name').value;
    if (tripType === "Última milla") this.form.get('cargoModel.cargoType.name').setValue("Carga suelta");

    if (this.form.get('cargoFeature.productType.name').value === "CONTENEDOR VACIO") {
      this.form.get('cargoModel.operationType.code').setValue("V");
      this.form.get('cargoModel.operationType.description').setValue("Contenedor vacío");
    } else if (this.form.get('cargoModel.serviceType.name').value === "Carga consolidada") {
      this.form.get('cargoModel.operationType.code').setValue("P");
      this.form.get('cargoModel.operationType.description').setValue("Paqueteo");
    } else if (
      (this.form.get('cargoModel.tripType.name').value === "Exportación" || this.form.get('cargoModel.tripType.name').value === "Importación") &&
      this.form.get('cargoModel.cargoType.name').value === "Contenedor"
    ) {
      this.form.get('cargoModel.operationType.code').setValue("C");
      this.form.get('cargoModel.operationType.description').setValue("Contenedor cargado");
    } else {
      this.form.get('cargoModel.operationType.code').setValue("G");
      this.form.get('cargoModel.operationType.description').setValue("General");
    }
  }

  public updateRequest(data) {
    const originalTripType = this.cargo.cargoModel.tripType.name;
    const changedTripType = data.cargoModel.tripType.name;
    this.spinner.show();
    const $updateRequest = this.cargoDetailService.completeUpdateRequest(data, this.cargo).subscribe({
      next: (success) => {
        this.spinner.hide();
        this.emitToParent.emit(this.cargo.consecutive);
        if (['Nacional', 'Internacional', 'Exportación', 'Importación'].includes(originalTripType) && ['Urbana', 'Última milla'].includes(changedTripType)
          || ['Nacional', 'Internacional', 'Exportación', 'Importación'].includes(changedTripType) && ['Urbana', 'Última milla'].includes(originalTripType)) {
          this.cleanCargo.emit({ tripType: changedTripType });
        }
        this.snackBarService.openSnackBar(CargoMessages.CARGO_UPDATED);
        this.onUpdate.emit(true);
      },
      error: (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(CargoMessages.CARGO_UPDATE_ERROR_DEFAULT, undefined, 'error');
      },
      complete: () => {
        $updateRequest.unsubscribe();
      }
    });
  }
  formValid() {
    let tripType = this.form.get('cargoModel.tripType.name');
    let regimeType = this.form.get('cargoModel.tripType.regimeType');
    let letterRetirement = this.form.get('cargoModel.tripType.letterRetirement');
    let tripTypeDate = this.form.get('cargoModel.tripType.date');
    let tripDate = this.dateFieldDTA;
    let cargoType = this.form.get('cargoModel.cargoType.name');
    let containerMeasure = this.form.get('cargoModel.cargoType.containerMeasure.size');
    let serviceType = this.form.get('cargoModel.serviceType.name');
    let observation = this.form.get('observation');

    if (!tripType.value) {
      tripType.setErrors({ required: true });
      this.utils.errorMessagesCustomized(tripType, 'tipo de viaje');
      return false;
    } else {
      tripType.setErrors(null);
    }

    if (tripType.value === "Exportación" && !letterRetirement.value) {
      letterRetirement.setErrors({ required: true });
      this.utils.errorMessagesCustomized(letterRetirement, "carta de retiro de la exportación");
      return false;
    } else {
      letterRetirement.setErrors(null);
    }

    if ((tripType.value === "Exportación" || tripType.value === "Importación") && !regimeType.value) {
      regimeType.setErrors({ required: true });
      this.utils.errorMessagesCustomized(regimeType, 'tipo de régimen');
      return false;
    } else if (tripType.value === "Exportación" && regimeType.value === "OTM") {
      regimeType.setErrors({ regimeType: true });
      this.snackBarService.openSnackBar(Fmt.string(CargoMessages.UNABLE_TO_ASSIGN_ERROR, 'este tipo de régimen', 'este tipo de viaje'), undefined, 'alert');
      return false;
    } else if (tripType.value === "Importación" && regimeType.value === "DTAI") {
      regimeType.setErrors({ regimeType: true });
      this.snackBarService.openSnackBar(Fmt.string(CargoMessages.UNABLE_TO_ASSIGN_ERROR, 'este tipo de régimen', 'este tipo de viaje'), undefined, 'alert');
      return false;
    } else if ((tripType.value === "Urbana" || tripType.value === "Última milla" || tripType.value === "Nacional" || tripType.value === "Internacional") && regimeType.value) {
      regimeType.setErrors({ regimeType: true });
      this.snackBarService.openSnackBar(Fmt.string(CargoMessages.UNABLE_TO_ASSIGN_ERROR, 'este tipo de régimen', 'este tipo de viaje'), undefined, 'alert');
      return false;
    } else {
      regimeType.setErrors(null);
    }

    if ((regimeType.value === "DTA" || regimeType.value === "DUTA" || regimeType.value === "OTM") && !tripTypeDate.value) {
      tripDate.setErrors({ required: true });
      this.utils.errorMessagesCustomized(tripDate, 'fecha de descargue del buque');
      return false;
    } else {
      tripDate.setErrors(null);
    }

    if (["Exportación", "Importación", "Nacional", "Internacional"].includes(tripType.value) && !cargoType.value) {
      cargoType.setErrors({ required: true });
      this.utils.errorMessagesCustomized(cargoType, 'tipo de carga');
      return false;
    } else cargoType.setErrors(null);

    if (cargoType.value && cargoType.value === "Contenedor" && !containerMeasure.value) {
      containerMeasure.setErrors({ required: true });
      this.utils.errorMessagesCustomized(containerMeasure, 'medidas del contenedor');
      return false;
    } else if (((cargoType.value && cargoType.value === "Carga suelta") ||
      tripType.value === "Última milla") && containerMeasure.value) {
      containerMeasure.setErrors({ wrongContainer: true });
      this.snackBarService.openSnackBar(Fmt.string(CargoMessages.UNABLE_TO_ASSIGN_ERROR, 'este tipo de contenedor', 'este tipo de viaje'), undefined, 'alert');
      return false;
    } else {
      containerMeasure.setErrors(null);
    }

    if (!serviceType.value) {
      serviceType.setErrors({ required: true });
      this.utils.errorMessagesCustomized(serviceType, 'tipo de servicio');
      return false;
    } else if (serviceType.value.includes('Contenedor') && (!cargoType.value || cargoType.value !== "Contenedor")) {
      serviceType.setErrors({ serviceTypeError: true });
      this.snackBarService.openSnackBar(Fmt.string(CargoMessages.UNABLE_TO_ASSIGN_ERROR, 'este tipo de servicio', 'este tipo de viaje'), undefined, 'alert');
      return false;
    } else if ((serviceType.value === "Carga expresa" || serviceType.value === "Carga consolidada") && (cargoType.value && cargoType.value === "Contenedor")) {
      serviceType.setErrors({ serviceTypeError: true });
      this.snackBarService.openSnackBar(Fmt.string(CargoMessages.UNABLE_TO_ASSIGN_ERROR, 'este tipo de servicio', 'este tipo de viaje'), undefined, 'alert');
      return false;
    } else {
      serviceType.setErrors(null);
    }

    if (this.utils.errorMessagesCustomized(observation, 'observaciones')) return false;

    return true;
  }

  get firebasePath(): boolean {
    const url = decodeURIComponent(this.form.get('cargoModel.tripType.letterRetirement').value);
    const match = url.match(/\/([^\/]+)\?alt=media/);
    const fileNameDecode = match ? match[1] : null;
    return !!fileNameDecode;
  }


}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Fmt } from 'src/app/core/messages/fmt';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
import { MenuServiceService } from '../../menu-service/menu-service.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { ModelCargo } from 'src/app/modules/cargo/manual-creation-cargo/resources/modelCargo';
import { Destination } from 'src/app/core/interfaces/destination';
import { Origin } from 'src/app/core/interfaces/origin';
import { ThirdPartyConsignmentService } from 'src/app/modules/cargo/manual-creation-cargo/components/third-party-consignment/third-party-consignment.service';
import { ConsignmentCityOption } from 'src/app/shared/consignment-card/consignment-card.component';
import { ThirdParty, ThirdPartyAddress } from 'src/app/core/interfaces/thirdParty';
import { CargoDetailService } from 'src/app/modules/cargo/cargo-detail/cargo-detail.service';
import { BasicResponse } from 'src/app/core/interfaces/basicResponse';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocationAddress } from 'src/app/core/interfaces/locationAddress';
import { ConsignmentCargo } from 'src/app/core/interfaces/consignmentCargo';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { Global } from 'src/app/core/resources/global';
@Component({
  selector: 'app-consignments-service-form',
  templateUrl: './consignments-service-form.component.html',
  styleUrls: ['./consignments-service-form.component.scss'],
  providers: [ModelCargo]
})
export class ConsignmentsServiceFormComponent implements OnInit {
  permission = Permission;
  formSubscription: Subscription;
  manifestType = new FormControl('', Validators.required);
  operationType = new FormControl('', Validators.required);
  readonly operationTypeOptions: { code: string, description: string, tooltip: string }[] = this.utils.clone(this.menuService.operationTypes);
  readonly manifestTypeOptions: { code: string, description: string, tooltip: string }[] = this.utils.clone(this.menuService.manifestTypes);
  senderCityOptions: { [key: string]: ConsignmentCityOption } = {};
  recipientCityOptions: { [key: string]: ConsignmentCityOption } = {};
  originalConsignments: ConsignmentCargo[] = [];
  consignmentsFormArray: FormArray = new FormArray([]);
  consignmentFieldsToCheck: { field: string, alias: string }[] = this.global.consignmentFieldsToCheck;
  formValidate = '';
  numberOfConsignmentsShowed = 5;

  constructor(
    public menuService: MenuServiceService,
    private fb: FormBuilder,
    private global: Global,
    private spinner: NgxSpinnerService,
    private router: Router,
    private snackBarService: SnackBarService,
    private permissionRole: PermissionRole,
    public utils: Utils,
    private thirdPartyConsignmentService: ThirdPartyConsignmentService,
    private cargoDetailService: CargoDetailService
  ) { }

  ngOnInit() {
    this.setLocalValues();
    this.setSubscription();
  }

  private setLocalValues() {
    if (!this.menuService.isRequiredConsignmentsService()) return this.continueToNextStep();
    const manifestType = this.menuService.form.get('manifestType').value;
    const manifestTypeOption = this.manifestTypeOptions.find(option => manifestType && option.code === manifestType.code);
    this.manifestType.setValue(manifestTypeOption ? manifestTypeOption : '');
    const operationType = this.menuService.form.get('cargoModel.operationType').value;
    const operationTypeOption = this.operationTypeOptions.find(option => operationType && option.code === operationType.code);
    this.operationType.setValue(operationTypeOption ? operationTypeOption : '');
    this.numberOfConsignmentsShowed = 5;
    this.setCityOptions();
    this.checkPermissionsToEdit();
    this.setConsignments();
  }

  private setSubscription() {
    this.formSubscription = this.menuService.formObservable.subscribe(() => this.setLocalValues());
  }

  private setCityOptions() {
    const origin: Origin = this.utils.getNestedValue(this.menuService.form.value, 'cargoFeature.uploadDownload.origin');
    const destination: Destination[] = this.utils.getNestedValue(this.menuService.form.value, 'cargoFeature.uploadDownload.destination');
    this.senderCityOptions = origin && origin.name
      ? {
        [origin.name]: {
          municipalityCode: origin.municipalityCode,
          index: 0,
          countryCode: origin.country && origin.country.code ? origin.country.code : 'CO',
          date: this.utils.getNestedValue(this.menuService.form.value, 'dateLoad'),
          addresses: origin.addresses && origin.addresses.length ? origin.addresses.map((address, index) => {
            return {
              address: address && address.address ? address.address : '',
              location: address && address.location && address.location.lat && address.location.lng ? address.location : { lat: 0, lng: 0 },
              time: address && address.time ? address.time : null,
              index: index
            }
          }) : []
        }
      }
      : {};
    if (!destination || !destination.length) { this.recipientCityOptions = {}; return; }
    destination.forEach((city, index) => {
      if (!city || !city.name) return;
      this.recipientCityOptions[city.name] = {
        municipalityCode: city.municipalityCode ? city.municipalityCode : '',
        index: index,
        countryCode: city.country && city.country.code ? city.country.code : 'CO',
        date: city.downloadDate ? city.downloadDate : '',
        addresses: city.addresses && city.addresses.length ? city.addresses.map((address, indexCity) => {
          return {
            address: address && address.address ? address.address : '',
            location: address && address.location && address.location.lat && address.location.lng ? address.location : { lat: 0, lng: 0 },
            time: address && address.time ? address.time : null,
            index: indexCity
          }
        }) : []
      }
    });
  }

  private checkPermissionsToEdit() {
    if (!this.canEditConsignmentServiceModule) {
      this.manifestType.disable();
      this.operationType.disable();
      this.formValidate = 'disable';
    } else if (!this.canEditConsignments) {
      this.consignmentsFormArray.disable();
      this.formValidate = 'disable';
    }
  }

  private setConsignments() {
    this.cargoDetailService.getCargoConsignments(this.menuService.cargo.id, 'Created').subscribe(
      (consignments: ConsignmentCargo[]) => {
        if (consignments && JSON.stringify(consignments) === JSON.stringify(this.originalConsignments)) return;
        this.consignmentsFormArray.clear();
        this.originalConsignments = [];
        if (consignments && consignments.length) {
          this.originalConsignments = consignments;
          consignments.forEach(consignment => {
            const form = this.menuService.getConsignmentForm();
            form.patchValue(consignment);
            form.get('cargoOwner').patchValue(this.menuService.form.get('cargoOwner').value);
            this.consignmentsFormArray.push(form);
          });
        }
      }, () => {
        this.consignmentsFormArray.clear();
        this.originalConsignments = [];
      }
    );
  }

  addConsignmentForm() {
    this.consignmentsFormArray.push(this.menuService.getConsignmentForm());
    if (this.numberOfConsignmentsShowed < this.consignmentsFormArray.length)
      this.watchMoreConsignments();
  }

  removeConsignmentForm(index: number) {
    this.consignmentsFormArray.removeAt(index);
  }

  get filteredConsignmentsFormArray(): AbstractControl[] {
    if (!this.consignmentsFormArray.length) return [];
    if (this.consignmentsFormArray.length <= this.numberOfConsignmentsShowed)
      return this.consignmentsFormArray.controls;
    return this.consignmentsFormArray.controls.slice(0, this.numberOfConsignmentsShowed);
  }

  watchMoreConsignments() {
    this.numberOfConsignmentsShowed += 5;
  }

  compareByCode(a: { code: string }, b: { code: string }) {
    return a && b && a.code === b.code;
  }

  get canShowConsignmentServiceModule(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.showConsignmentServiceModule
    );
  }

  get canEditConsignmentServiceModule(): boolean {
    return this.canShowConsignmentServiceModule && this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.editConsignmentServiceModule
    );
  }

  get canEditConsignments(): boolean {
    return this.canEditConsignmentServiceModule && this.menuService.isValidContractorTripInfo().isValid;
  }

  backToPreviousStep() {
    this.router.navigate([Fmt.string(this.menuService.steps.observationsService.url, this.menuService.cargo.consecutive)]);
  }

  private areConsignmentsValid(): boolean {
    let isValid = true;
    for (const [index, consignmentForm] of this.consignmentsFormArray.controls.entries()) {
      for (const consignmentField of this.consignmentFieldsToCheck) {
        if (this.utils.errorMessagesCustomized(consignmentForm.get(consignmentField.field), `${consignmentField.alias} de la remesa ${index + 1}`))
          isValid = false;
        if (!isValid) break;
      }
      if (!isValid) break;
    }
    return isValid;
  }

  async saveAndContinue() {
    this.formValidate = 'touched';
    if (!this.areConsignmentsValid()) return;

    const manifestTypeCode = this.manifestType.value && this.manifestType.value.code ? this.manifestType.value.code : '';
    const manifestTypeDescription = this.manifestType.value && this.manifestType.value.description ? this.manifestType.value.description : '';
    const operationTypeCode = this.operationType.value && this.operationType.value.code ? this.operationType.value.code : '';
    const operationTypeDescription = this.operationType.value && this.operationType.value.description ? this.operationType.value.description : '';

    if (this.consignmentsFormArray.value && this.canEditConsignments) {
      this.spinner.show();
      const { success, error } = await this.processConsignments();
      if (success) await this.menuService.setCargoConsignments();
      this.spinner.hide();
      if (!success) return this.snackBarService.openSnackBar(error ? error : 'Ocurrió un error al guardar las remesas', undefined, 'error');
    }

    this.menuService.form.get('manifestType').patchValue({ code: manifestTypeCode, description: manifestTypeDescription });
    this.menuService.form.get('cargoModel.operationType').patchValue({ code: operationTypeCode, description: operationTypeDescription });
    if (this.consignmentsFormArray.value && this.consignmentsFormArray.value.length) {
      const totalWeigth = this.consignmentsFormArray.value.reduce((acc, consignment) => acc + Number(consignment.totalWeight), 0);
      this.menuService.form.get('cargoFeature.cargoMeasure.totalWeigth').setValue(
        this.menuService.isEmptyContainer ? 0 : totalWeigth <= AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG
          ? totalWeigth : AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG
      );
      this.menuService.form.get('cargoFeature.cargoMeasure.unit').setValue("1");
    }

    this.menuService.updateService().then(() => {
      this.snackBarService.openSnackBar("Servicio actualizado exitosamente");
      this.continueToNextStep();
    }).catch(() => this.snackBarService.openSnackBar('Ocurrió un error al actualizar el servicio', undefined, 'error'));
  }

  private async processConsignments(): Promise<{ success: boolean, error?: string }> {
    const consignments: ConsignmentCargo[] = this.consignmentsFormArray.value;

    const consignmentsToAdd = consignments.filter((consignment) => !consignment.id);
    const consignmentsToRemove = this.originalConsignments.filter((consignment) => !consignments.some((savedConsignment) => savedConsignment.id === consignment.id));
    const consignmentsToUpdate = consignments.filter((consignment) => consignment.id).filter((consignment) => {
      const originalConsignment = this.originalConsignments.find((originalConsignment) => originalConsignment.id === consignment.id);
      return originalConsignment && !this.consignmentFieldsToCheck.every((consignmentField) =>
        this.utils.getNestedValue(originalConsignment, consignmentField.field) === this.utils.getNestedValue(consignment, consignmentField.field));
    });

    const promises = [];
    if (consignmentsToAdd.length) {
      const { success, consignments: consignmentsToCreate, error } = await this.checkConsignments(consignmentsToAdd);
      if (!success) return { success: false, error: error ? error : 'Ocurrió un error al guardar las sucursales de las remesas' };
      promises.push(this.createConsignments(consignmentsToCreate));
    }
    if (consignmentsToUpdate.length) {
      const { success, consignments: consignmentsToChange, error } = await this.checkConsignments(consignmentsToUpdate);
      if (!success) return { success: false, error: error ? error : 'Ocurrió un error al guardar las sucursales de las remesas' };
      promises.push(this.updateConsignments(consignmentsToChange));
    }
    const tripType = this.utils.getNestedValue(this.menuService.cargo, 'cargoModel.tripType.name');
    if (consignmentsToRemove.length) promises.push(this.deleteConsignments(consignmentsToRemove, ![TRIP_TYPES.URBAN, TRIP_TYPES.LAST_MILE].includes(tripType)));
    if (!promises.length) return { success: true };
    this.spinner.show();
    try {
      await Promise.all(promises);
      this.spinner.hide();
      return { success: true };
    } catch (error) {
      this.spinner.hide();
      return { success: false };
    }
  }

  private async checkConsignments(consignments: ConsignmentCargo[]): Promise<{ success: boolean, consignments: ConsignmentCargo[], error?: string }> {
    const consignmentsToCheck: ConsignmentCargo[] = this.utils.clone(consignments);
    for (const consignment of consignmentsToCheck) {
      const senderBranchOffice = await this.checkThirdPartyAddress(consignment.sender, consignment.sender.municipalityCode, consignment.sender.location, consignment.sender.address);
      if (senderBranchOffice.branchOffice) consignment.sender.branchOffice = senderBranchOffice.branchOffice;
      else return { success: false, consignments: [], error: senderBranchOffice.error };
      const recipientBranchOffice = await this.checkThirdPartyAddress(consignment.recipient, consignment.recipient.municipalityCode, consignment.recipient.location, consignment.recipient.address);
      if (recipientBranchOffice.branchOffice) consignment.recipient.branchOffice = recipientBranchOffice.branchOffice;
      else return { success: false, consignments: [], error: recipientBranchOffice.error };
      const cargoOwner = this.utils.getNestedValue(this.menuService.cargo, 'cargoOwner');
      const consignmentCargoOwner = this.utils.getNestedValue(consignment, 'cargoOwner');
      if (cargoOwner && consignmentCargoOwner) {
        consignment.cargoOwner.branchOffice = consignmentCargoOwner.branchOffice === cargoOwner.branchOffice ? consignmentCargoOwner.branchOffice : cargoOwner.branchOffice;
        consignment.cargoOwner.documentNumber = consignmentCargoOwner.documentNumber === cargoOwner.documentNumber ? consignmentCargoOwner.documentNumber : cargoOwner.documentNumber;
        consignment.cargoOwner.documentType = consignmentCargoOwner.documentType === cargoOwner.documentType ? consignmentCargoOwner.documentType : cargoOwner.documentType;
        consignment.cargoOwner.name = consignmentCargoOwner.name === cargoOwner.name ? consignmentCargoOwner.name : cargoOwner.name;
      }
      consignment.errorCreation = null;
      consignment.errorFinalFulfillment = null;
      consignment.errorInitialFulfillment = null;
    }
    return { success: true, consignments: consignmentsToCheck };
  }

  private async createConsignments(consignmentsToCreate: ConsignmentCargo[]): Promise<any> {
    return this.cargoDetailService.createConsignment(consignmentsToCreate).toPromise();
  }

  private async updateConsignments(consignments: ConsignmentCargo[]): Promise<any> {
    return this.cargoDetailService.updateConsignments(consignments).toPromise();
  }

  private async deleteConsignments(consignments: ConsignmentCargo[], isNational: boolean): Promise<any> {
    return consignments.map((consignment) => this.cargoDetailService.deleteConsignment(consignment.documentId, isNational).toPromise());
  }

  private async checkThirdPartyAddress(thirdParty: ThirdParty, municipalityCode: string, location: LocationAddress, address: string): Promise<{ branchOffice: string, error?: string }> {
    const thirdPartyAddresses = await this.getThirdPartyAddresses(thirdParty.documentNumber, municipalityCode);
    const thirdPartyAddress = thirdPartyAddresses.find((thirdPartyAddress) => thirdPartyAddress && thirdPartyAddress.address &&
      thirdPartyAddress.address.location && thirdPartyAddress.address.location.lat === location.lat &&
      thirdPartyAddress.address.location.lng === location.lng);
    if (thirdPartyAddress) return { branchOffice: thirdPartyAddress.address.id };
    const created = await this.createThirdPartyAddress(thirdParty, municipalityCode, { address: address, location: location });
    if (!created || !created.success) return { branchOffice: '', error: created && created.error ? created.error : `Ocurrió un error al guardar la dirección ${address} del tercero` };
    const newThirdPartyAddresses = await this.getThirdPartyAddresses(thirdParty.documentNumber, municipalityCode);
    const newThirdPartyAddress = newThirdPartyAddresses.find((thirdPartyAddress) => thirdPartyAddress && thirdPartyAddress.address &&
      thirdPartyAddress.address.location && thirdPartyAddress.address.location.lat === location.lat &&
      thirdPartyAddress.address.location.lng === location.lng);
    if (newThirdPartyAddress) return { branchOffice: newThirdPartyAddress.address.id };
    return { branchOffice: '' };
  }

  async getThirdPartyAddresses(thirdPartyDocument: string, municipalityCode: string): Promise<ThirdPartyAddress[]> {
    let thirdPartyAddresses: ThirdPartyAddress[] = [];
    try {
      thirdPartyAddresses = await this.thirdPartyConsignmentService.getThirdPartiesAddressByCompany(thirdPartyDocument, null, null, municipalityCode).toPromise();
    } catch (error) { console.error(error) }
    return thirdPartyAddresses && thirdPartyAddresses.length ? thirdPartyAddresses : [];
  }

  async createThirdPartyAddress(
    thirdParty: ThirdParty,
    municipalityCode: string,
    address: { address: string, location: LocationAddress }
  ): Promise<{ success: boolean, error?: string }> {
    const data = {
      information: {
        documentTypeId: thirdParty.documentType,
        documentTypeName: this.utils.toCapitalize(
          this.utils.getDocumentType(thirdParty.documentType).name
        ),
        document: thirdParty.documentNumber,
        name: thirdParty.name
      },
      parent: null,
      address: address,
      municipalityCode: municipalityCode
    };
    let response: ThirdPartyAddress;
    try { response = await this.thirdPartyConsignmentService.createThirdParty(data).toPromise(); } catch (error) { }
    return { success: !!(response && response.address && response.address.id), error: response && response.errorRNDC ? response.errorRNDC.error : '' };
  }

  continueToNextStep() {
    this.router.navigate([Fmt.string(this.menuService.steps.servicePublication.url, this.menuService.cargo.consecutive)]);
  }

  ngOnDestroy() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from '../../../../environments/environment';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { licenseCategories } from 'src/app/core/resources/licenseCategories';
import { DriverLicenseCategory } from 'src/app/core/interfaces/driverLicenseCategory';

@Injectable({
  providedIn: 'root'
})
export class AssignVehicleCargoService {
  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints
  ) { }

  getVehicleById(idVehicle: string, holderCompany?: string) {
    let url = `${environment.urlServerTeclogi}${this.endpointResources.getVehiclesById}${idVehicle}`;
    if (holderCompany) url += '&holderCompany=' + holderCompany;
    return this.http.get<Vehicle[]>(url);
  }

  checkLicenseCategory(driverLicenses: DriverLicenseCategory[], vehicleType: string): {valid: boolean, message: string} {
    const requiredLicenses = licenseCategories[vehicleType];
    if (!requiredLicenses) return {valid: false, message: "Tipo de vehículo no válido."};
    const validLicenses = driverLicenses.filter(license => 
      license && license.category && requiredLicenses.includes(license.category)
    );
    if (validLicenses.length === 0) return {valid: false, message: `El conductor no cuenta con la licencia requerida para este tipo de vehículo. Licencia(s) requerida(s): ${requiredLicenses.join(', ')}`};
    const hasActiveLicense = validLicenses.some(license => license.active);
    return hasActiveLicense 
      ? {valid: true, message: ""} 
      : {valid: false, message: "El conductor cuenta con la licencia requerida, pero está vencida o inactiva."};
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { ServiceType } from 'src/app/core/interfaces/serviceType';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Utils } from 'src/app/core/resources/utils';

@Injectable({
  providedIn: 'root'
})
export class BellAlertService {
  private static uncares: Array<Cargo> = [];
  public static onUncareStacked: EventEmitter<any> = new EventEmitter();

  constructor(private utils: Utils) { }

  static get cargos(): Cargo[] {
    return BellAlertService.uncares;
  }

  public static stack(cargos: Cargo[]) {
    BellAlertService.uncares = cargos;
    if (cargos && cargos.length) BellAlertService.onUncareStacked.emit(cargos);
  }

  public validateTimeCargo(date, cargo: Cargo, isEmpty: boolean) {
    let diff = DateManager.dateDiff(new Date(), null, date, 'YYYY-MM-DD HH:mm ZZ', 'minutes');

    const serviceType: ServiceType = cargo && cargo.cargoModel && cargo.cargoModel.serviceType ? cargo.cargoModel.serviceType : null;
    // Escort service
    if (serviceType && serviceType.id === 'escortServices') {
      if (diff >= AmountsCargoEnum.ESCORT_SERVICE_ALERT_MINS)
        return ({ diff, amount: AmountsCargoEnum.ESCORT_SERVICE_ALERT_MINS })
      return null;
    }

    const amount = this.utils.getNestedValue(cargo, 'cargoFeature.cargoMeasure.amount');
    // Vacío
    if (isEmpty && diff >= AmountsCargoEnum.EMPTY_ALERT_MINS)
      return ({ diff, amount: AmountsCargoEnum.EMPTY_ALERT_MINS })
    // Amarillo 3H
    if (amount >= AmountsCargoEnum.LOW_START && amount <= AmountsCargoEnum.LOW_END && diff >= AmountsCargoEnum.LOW_ALERT_MINS)
      return ({ diff, amount: AmountsCargoEnum.LOW_ALERT_MINS })
    // Rojo 2H
    if (amount >= AmountsCargoEnum.MEDIUM_START && amount <= AmountsCargoEnum.MEDIUM_END && diff >= AmountsCargoEnum.MEDIUM_ALERT_MINS)
      return ({ diff, amount: AmountsCargoEnum.MEDIUM_ALERT_MINS })
    // Morado 1H
    if (amount >= AmountsCargoEnum.HIGH_START && amount <= AmountsCargoEnum.HIGH_END && diff >= AmountsCargoEnum.HIGH_ALERT_MINS)
      return ({ diff, amount: AmountsCargoEnum.HIGH_ALERT_MINS })
    return null;
  }

}

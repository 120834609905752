<aside class="wrapper-vehicle-form">
  <i *ngIf="paramsDialog && (paramsDialog.vehicle || paramsDialog.id)" matTooltip="Cerrar"
    class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="col-md-12">
    <div class="row px-0 px-sm-4">
      <div class="col-md-12 wrapper-detail">
        <!-- Title -->
        <!-- Form -->
        <form class="form-container" [formGroup]="reactiveForm.form"
          *ngIf="reactiveForm && vehicle && relatedInfoDriver">
          <span class="separator-horizontal"></span>
          <mat-tab-group (selectedIndexChange)="onSelectTab()">
            <!-- Información general -->
            <mat-tab>
              <ng-template mat-tab-label>
                {{vehicle && vehicle.id ? vehicle.id : ''}} ({{(this.vehicle && !this.vehicle.validateTruora &&
                !this.vehicle.checkId) ? "Activo"
                : adminUserService.getVehicleState(vehicle) === 'Activo' ?
                (missingOrUnacceptedDocs || !vehicle?.stateCompany?.active) ? 'Inactivo' :
                adminUserService.getVehicleState(vehicle) :
                adminUserService.getVehicleState(vehicle)}})
                <i *ngIf=" (((missingOrUnacceptedDocs || !vehicle?.stateCompany?.active) && adminUserService.getVehicleState(vehicle) !== 'Estudio de seguridad en proceso') || adminUserService.getVehicleState(vehicle) === 'Inactivo') && this.vehicle.validateTruora"
                  class="fa fa-exclamation-circle icon-vh color--error ml-2" style="font-size: 1.5rem;"></i>
                <i *ngIf="(adminUserService.getVehicleState(vehicle) === 'Estudio de seguridad en proceso') || (this.vehicle && !this.vehicle.validateTruora && !this.vehicle.checkId)"
                  class="fa fa-exclamation-triangle icon-vh color--warning ml-2" aria-hidden="true"></i>
                <i *ngIf="!missingOrUnacceptedDocs && vehicle?.stateCompany?.active && adminUserService.getVehicleState(vehicle) === 'Activo'"
                  class="fas fa-check-circle icon-vh color--success ml-2"></i>
              </ng-template>
              <mat-accordion [multi]="true">
                <div class="wrapper-vehicle-form">
                  <div class="wrapper-detail">
                    <div class="container-card-row">
                      <!--INFO VH CARD-->
                      <div class="card-info-vehicle-user">

                        <app-manual-data-vehicle-input
                          *ngIf="adminUserService.getVehicleState(vehicle) !== 'Estudio de seguridad en proceso' && correctVehicleData"
                          class="w-100" type="alert" [outServiceDbs]="vehicleDataBase" dataType="vehicle"
                          [checkId]="!!(vehicle?.checkId)" [haveData]="getValidateVehicle()"
                          [allowedToActions]="showEditVehicleMessage" (emitServiceTrigger)="editVehicleInfo($event)"
                          (newSecurityStudy)="checkValidateVehicle($event)"></app-manual-data-vehicle-input>
                        <div class="card-info-vehicle-user__content h-100">
                          <div class="info-card-vehicle">
                            <div class="center-horizontal txt_center field__card--label">
                              Placa
                            </div>
                            <div class="title-component ">
                              {{vehicle && vehicle.id ? vehicle.id : ''}}
                            </div>
                            <div class="separator_center width-separator"></div>
                            <div class="vehicle-info-container mt-2">
                              <div class="text-center" *ngIf="vehicle?.vehicleType?.name">
                                <div *ngFor="let vehicleType of vehicleTypesIcons">
                                  <i *ngIf="vehicle?.vehicleType?.name === vehicleType.name" class="fas fa-2xl"
                                    [ngClass]="vehicleType.icon"></i>
                                </div>
                                <div class="field__card--value">
                                  {{vehicle && vehicle.vehicleType && vehicle.vehicleType.name ?
                                  vehicle.vehicleType.name :
                                  ''}}
                                </div>
                              </div>
                              <div *ngIf="vehicle?.bodywork?.description" class="text-center">
                                <div class="center-horizontal txt_center field__card--label">
                                  Carrocería
                                </div>
                                <div class="field__card--value">
                                  {{vehicle && vehicle.bodywork && vehicle.bodywork.description ?
                                  vehicle.bodywork.description : '-'}}
                                </div>
                              </div>
                              <div *ngIf="vehicle?.brand?.description" class="text-center">
                                <div class="center-horizontal txt_center field__card--label">
                                  Marca
                                </div>
                                <div class="field__card--value">
                                  {{vehicle && vehicle.brand && vehicle.brand.description ? vehicle.brand.description:
                                  '-'}}
                                </div>
                              </div>
                              <div *ngIf="vehicle?.manufacturingYear" class="text-center">
                                <div class="center-horizontal txt_center field__card--label">
                                  Año de fabricación
                                </div>
                                <div class="field__card--value">
                                  {{vehicle && vehicle?.manufacturingYear ? vehicle.manufacturingYear : '-'}}
                                </div>
                              </div>
                              <div *ngIf="vehicle?.axles" class="text-center">
                                <div class="center-horizontal txt_center field__card--label">
                                  N° de ejes
                                </div>
                                <div class="field__card--value">
                                  {{vehicle && vehicle.axles ? vehicle.axles : '-'}}
                                </div>
                              </div>
                              <div *ngIf="vehicle?.color" class="text-center">
                                <div class="center-horizontal txt_center field__card--label">
                                  Color
                                </div>
                                <div class="field__card--value">
                                  {{vehicle && vehicle.color ? vehicle.color : '-'}}
                                </div>
                              </div>
                              <div *ngIf="vehicle?.fuelType?.description" class="text-center">
                                <div class="center-horizontal txt_center field__card--label">
                                  Tipo de combustible
                                </div>
                                <div class="field__card--value">
                                  {{vehicle && vehicle.fuelType && vehicle.fuelType.description ?
                                  vehicle.fuelType.description : '-'}}
                                </div>
                              </div>
                              <div *ngIf="vehicle?.line?.description" class="text-center">
                                <div class="center-horizontal txt_center field__card--label">
                                  Línea
                                </div>
                                <div class="field__card--value">
                                  {{vehicle && vehicle.line && vehicle.line.description ?
                                  vehicle.line.description : '-'}}
                                </div>
                              </div>
                              <div *ngIf="vehicle?.chassisId" class="text-center">
                                <div class="center-horizontal txt_center field__card--label">
                                  N° de chasis
                                </div>
                                <div class="field__card--value">
                                  {{vehicle && vehicle.chassisId ? vehicle.chassisId : '-'}}
                                </div>
                              </div>
                              <div *ngIf="vehicle?.motorId" class="text-center">
                                <div class="center-horizontal txt_center field__card--label">
                                  N° de motor
                                </div>
                                <div class="field__card--value">
                                  {{vehicle && vehicle.motorId ? vehicle.motorId : '-'}}
                                </div>
                              </div>
                              <div *ngIf="vehicle?.repowered !== undefined">
                                <div class="center-horizontal txt_center field__card--label">
                                  ¿Está repotenciado?
                                </div>
                                <div class="field__card--value">
                                  {{vehicle && vehicle.repowered !== undefined ? vehicle.repowered === true ? 'Sí' :
                                  'No' : ''}}
                                </div>
                              </div>
                              <div>
                                <div class="center-horizontal txt_center field__card--label">
                                  ¿Es flota propia?
                                </div>
                                <div class="field__card--value">
                                  {{vehicle?.ownFleet? 'Sí' : 'No'}}
                                </div>
                              </div>
                              <div *ngIf="registrationDate">
                                <div class="center-horizontal txt_center field__card--label">
                                  Fecha de matriculación
                                </div>
                                <div class="field__card--value">
                                  {{ registrationDate }}
                                </div>
                              </div>
                              <div *ngIf="vehicle?.emptyWeight">
                                <div class="center-horizontal txt_center field__card--label">
                                  Peso vacío
                                </div>
                                <div class="field__card--value">
                                  {{ vehicle?.emptyWeight }}
                                </div>
                              </div>
                              <div *ngIf="vehicle?.serviceType">
                                <div class="center-horizontal txt_center field__card--label">
                                  Tipo de servicio
                                </div>
                                <div class="field__card--value">
                                  {{ vehicle?.serviceType }}
                                </div>
                              </div>
                              <div colspan="3"
                                *ngIf="!vehicle?.state?.active && (errorsRNDC?.owner?.error || errorsRNDC?.driver?.error || errorsRNDC?.vehicle?.error)">
                                <div class="field__card--value text-center w-100">
                                  <i class="fa fa-exclamation-circle icon-vh color--error" aria-hidden="true"></i>
                                  <a (click)="openRNDCErrors()" role="button" style="text-decoration: underline;"
                                    class="color--error">
                                    Ver errores RNDC
                                  </a>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="d-flex justify-content-center align-items-end my-2"
                            *ngIf="showEditVehicleMessage">
                            <app-manual-data-vehicle-input
                              *ngIf="adminUserService.getVehicleState(vehicle) !== 'Estudio de seguridad en proceso'"
                              type="manualButton" dataType="vehicle" [checkId]="!!(vehicle?.checkId)"
                              [haveData]="getValidateVehicle()"
                              (emitServiceTrigger)="editVehicleInfo($event)"></app-manual-data-vehicle-input>
                          </div>
                          <div class="content-center d-flex flex-column my-2">
                            <div class="center-horizontal txt_center field__card--label">
                              Estado del vehículo
                            </div>
                            <span class="text__color text-center info-state"
                              [ngClass]="{'color--error': !vehicle?.state || (vehicle?.state && utils.isDefined(vehicle?.state?.active) || (missingOrUnacceptedDocs || !vehicle?.stateCompany?.active) && !vehicle?.state?.active), 'color--success': vehicle?.state && vehicle?.state?.active && !missingOrUnacceptedDocs && vehicle?.stateCompany?.active || (this.vehicle && !this.vehicle.validateTruora),'color--warning': vehicle?.state && vehicle?.state?.description=='Pending'}">
                              {{(this.vehicle && !this.vehicle.validateTruora && !this.vehicle.checkId) ? 'Activo sin estudio de seguridad' :
                              adminUserService.getVehicleState(vehicle) === 'Activo' ? missingOrUnacceptedDocs ||
                              !vehicle?.stateCompany?.active ?
                              'Inactivo' : adminUserService.getVehicleState(vehicle) :
                              adminUserService.getVehicleState(vehicle)}}
                            </span>
                          </div>
                          <div class="alert alert-warning mt-2 w-100 my-2" role="alert" *ngIf="missingOrUnacceptedDocs">
                            Falta validar o cargar <u class="cursor-pointer text-bold"
                              (click)="openDetailMissingDocuments()">información obligatoria</u>
                          </div>
                          <div class="alert alert-warning mt-2 w-100 my-2" role="alert" *ngIf="!vehicle?.rndcTransmited && vehicle?.errorRNDC?.error && !hasEscortServicesCompany">
                            {{ vehicle?.errorRNDC?.error }}
                          </div>
                          <div class="mt-3 mb-2">
                            <div class="button-container">
                              <button class="button-width-auto btn-validate bg-color-primary mx-2 flex-fill"
                                mat-raised-button color="primary" (click)="checkValidateVehicle(false)"
                                *ngIf="(!utils.isDefined(vehicle?.state) || (vehicle?.state && !vehicle?.state?.active && vehicle?.state?.description === 'Pending')) && (driver?.state?.description !== 'Pending')">
                                Validar vehículo
                              </button>
                              <button class="button-width-auto bg-color-primary mx-2 flex-fill" mat-raised-button
                                color="primary" (click)="confirmReactivateVehicleByInactivity(vehicle.id)"
                                *ngIf="!vehicle?.stateCompany?.active" permission-display
                                [module]="permission.administration.module"
                                [functionality]="permission.administration.reactivateVehicleByInactivity">
                                Reactivar vehículo
                              </button>
                              <button class="button-width-auto primary-secondary btn-validate mx-2 flex-fill"
                                mat-raised-button color="secondary-dark" *ngIf="!vehicle?.state?.active"
                                permission-display [module]="permission.administration.module"
                                [functionality]="permission.administration.forceVehicleActivation"
                                (click)="forzeActivation()">
                                Forzar Activación
                              </button>

                            </div>
                          </div>
                          <!-- Reactive vehicle warning -->
                          <div class="alert alert-warning mt-2" role="alert"
                            *ngIf="vehicle?.stateCompany?.description && !vehicle?.stateCompany?.active && vehicle.stateCompany?.description !== 'Inactive'">
                            <mat-label class="font-weight-bold text-alert inactivity-alert">
                              {{ vehicle?.stateCompany?.description }}
                            </mat-label>
                          </div>
                        </div>
                      </div>
                      <div class="rtm-soat-container">
                        <!--INFO RTM CARD-->
                        <div class="card-info-vehicle-user">
                          <app-manual-data-vehicle-input
                            *ngIf="adminUserService.getVehicleState(vehicle) !== 'Estudio de seguridad en proceso' && !currentVehicleByRtmExoneration && correctVehicleData"
                            class="w-100" type="alert" [outServiceDbs]="vehicleDataBase" dataType="RTM"
                            [checkId]="!!(vehicle?.checkId)" [haveData]="getValidateVehicleDocuments('RTM')"
                            [allowedToActions]="showEditVehicleMessage" (emitServiceTrigger)="editVehicleInfo($event)"
                            (newSecurityStudy)="checkValidateVehicle($event)"></app-manual-data-vehicle-input>
                          <div class="card-info-vehicle-user__content h-100">
                            <div class="icon-redo-validar font-secondary-dark" (click)="checkValidateVehicle(true)"
                              *ngIf="this.utils.isDefined(this.vehicle.validRTM) && this.remainingDaysRTM <7 && this.remainingDaysRTM != null && disabledOptions && !authService?.getCompanySaaS()?.allowCreatingWithoutTruora">
                              <i class="fas fa-redo font-secondary-dark"></i>
                              &nbsp;
                              <span class="text-strong font-secondary-dark"> Validar</span>
                            </div>
                            <div class="card-detail-column">
                              <div class="info-card-vehicle">
                                <div class="title-component ">
                                  RTM
                                </div>
                                <div class="separator_center"></div>
                                <div class="center-horizontal txt_center field__card--label">
                                  Fecha de expiración
                                </div>
                                <div class="center-horizontal txt_center field__card--value">
                                  {{vehicle && vehicle.validRTM ?
                                  this.rtmExpedirationDate:
                                  'No aplica'}}
                                </div>
                                <div *ngIf="currentVehicleByRtmExoneration && this.exonerationExpirationDate" class="center-horizontal txt_center field__card--value">
                                  Vehículo exonerado hasta el {{ this.exonerationExpirationDate | date: 'dd/MM/yyyy' }}
                                </div>
                                <div class="d-flex justify-content-center align-items-end my-2"
                                  *ngIf="showEditVehicleMessage">
                                  <app-manual-data-vehicle-input
                                    *ngIf="adminUserService.getVehicleState(vehicle) !== 'Estudio de seguridad en proceso' && !currentVehicleByRtmExoneration"
                                    type="manualButton" dataType="RTM" [checkId]="!!(vehicle?.checkId)"
                                    [haveData]="getValidateVehicleDocuments('RTM')"
                                    (emitServiceTrigger)="editVehicleInfo($event)"></app-manual-data-vehicle-input>
                                </div>
                              </div>
                              <div class="container-icon-vh ml-3">
                                <div
                                  *ngIf="(this.utils.isDefined(this.vehicle.validRTM) && this.vehicle.validRTM.active && this.remainingDaysRTM > 7 ) || (this.utils.isDefined(this.vehicle.validRTM) && this.remainingDaysRTM == null && this.vehicle.validRTM.active)">
                                  <i class="fas fa-check-circle icon-vh color--success"></i>
                                </div>
                                <div
                                  *ngIf="this.utils.isDefined(this.vehicle.validRTM)&& this.vehicle.validRTM.active  && this.remainingDaysRTM>=0 && this.remainingDaysRTM <= 7&& this.remainingDaysRTM != null ">
                                  <i class="fa fa-exclamation-triangle icon-vh color--warning" aria-hidden="true"></i>
                                </div>
                                <div
                                  *ngIf="this.utils.isDefined(this.vehicle.validRTM)&& this.vehicle.validRTM.active  && this.remainingDaysRTM <0 && this.remainingDaysRTM != null">
                                  <i class="fa fa-exclamation-circle icon-vh color--error" aria-hidden="true"></i>
                                </div>
                                <div
                                  *ngIf="(!this.vehicle.validRTM || !this.vehicle.validRTM.active) && this.vehicle.validateTruora">
                                  <i class="fa fa-exclamation-circle icon-vh color--error" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                            <div class="mt-3 w-100 text-center" *ngIf="getValidateVehicleDocuments('RTM')">
                              <p class="alert alert-info" role="alert" *ngIf="remainingDaysRTM >=0"
                                [ngClass]="{'alert-info':remainingDaysRTM >= 7, 'alert-warning':remainingDaysRTM<7 && remainingDaysRTM>=0}">
                                <mat-label class="font-weight-bold text-alert">
                                  Faltan {{this.remainingDaysRTM}} dias, para que se cumpla la fecha de vencimiento.
                                </mat-label>
                              </p>
                              <p class="alert alert-danger" role="alert"
                                *ngIf="this.remainingDaysRTM <0 && this.remainingDaysRTM != null">
                                <mat-label class="font-weight-bold text-alert">
                                  El documento tiene {{this.remainingDaysRTM * -1}} dias de vencido.
                                </mat-label>
                              </p>
                              <p class="alert alert-warning" role="alert"
                                *ngIf="this.utils.isEmpty(this.remainingDaysRTM)">
                                <mat-label class="font-weight-bold text-alert">
                                  El Vehiculo no tiene registro de vencimiento de RTM.
                                </mat-label>
                              </p>
                            </div>
                            <div class="alert alert-warning mt-3" role="alert" *ngIf="!vehicle?.serviceType && !vehicle?.validRTM?.expirationDate && vehicle?.state?.description !== 'Pending'">
                              Para verificar si el vehículo esta exonerado por RTM, debes diligenciar el tipo de servicio del vehiculo<span *ngIf="showEditVehicleMessage && adminUserService.getVehicleState(vehicle) !== 'Estudio de seguridad en proceso'"> dando click <u class="text-bold cursor-pointer color-secondary-dark" (click)="editVehicleInfo('vehicle', null, true)">aquí</u></span>
                            </div>
                          </div>
                        </div>
                        <!--INFO SOAT CARD-->
                        <div class="card-info-vehicle-user">
                          <app-manual-data-vehicle-input
                            *ngIf="adminUserService.getVehicleState(vehicle) !== 'Estudio de seguridad en proceso' && correctVehicleData"
                            class="w-100" type="alert" [outServiceDbs]="vehicleDataBase" dataType="SOAT"
                            [checkId]="!!(vehicle?.checkId)" [haveData]="getValidateVehicleDocuments('SOAT')"
                            [allowedToActions]="showEditVehicleMessage" (emitServiceTrigger)="editVehicleInfo($event)"
                            (newSecurityStudy)="checkValidateVehicle($event)"></app-manual-data-vehicle-input>
                          <div class="card-info-vehicle-user__content h-100">
                            <div class="icon-redo-validar" (click)="checkValidateVehicle(true)"
                              *ngIf="this.utils.isDefined(this.vehicle.validSOAT) && this.remainingDaysSoat <7 && this.remainingDaysSoat != null && disabledOptions && !authService?.getCompanySaaS()?.allowCreatingWithoutTruora">
                              <i class="fas fa-redo font-secondary-dark"></i>
                              &nbsp;
                              <span class="text-strong font-secondary-dark"> Validar</span>
                            </div>
                            <div class="card-detail-column">
                              <div class="info-card-vehicle">
                                <div class="title-component">
                                  SOAT
                                </div>
                                <div class="separator_center"></div>
                                <div class="center-horizontal txt_center field__card--label">
                                  Fecha de expiración
                                </div>
                                <div class="center-horizontal txt_center field__card--value">
                                  {{vehicle && vehicle.validSOAT ?
                                  this.soatExpedirationDate:
                                  '--'}}
                                </div>
                                <div class="d-flex justify-content-center align-items-end my-2"
                                  *ngIf="showEditVehicleMessage">
                                  <app-manual-data-vehicle-input
                                    *ngIf="adminUserService.getVehicleState(vehicle) !== 'Estudio de seguridad en proceso'"
                                    type="manualButton" dataType="SOAT" [checkId]="!!(vehicle?.checkId)"
                                    [haveData]="getValidateVehicleDocuments('SOAT')"
                                    (emitServiceTrigger)="editVehicleInfo($event)"></app-manual-data-vehicle-input>
                                </div>
                              </div>
                              <div class="container-icon-vh ml-3">
                                <div
                                  *ngIf="this.utils.isDefined(this.vehicle.validSOAT)&& this.vehicle.validSOAT.active  && this.remainingDaysSoat >= 7 ">
                                  <i class="fas fa-check-circle icon-vh color--success"></i>
                                </div>
                                <div
                                  *ngIf="this.utils.isDefined(this.vehicle.validSOAT) && this.vehicle.validSOAT.active  &&  this.remainingDaysSoat>=0 && this.remainingDaysSoat <= 7 ">
                                  <i class="fa fa-exclamation-triangle icon-vh color--warning" aria-hidden="true"></i>
                                </div>
                                <div
                                  *ngIf="this.utils.isDefined(this.vehicle.validSOAT) &&  this.vehicle.validSOAT.active  && this.remainingDaysSoat <0 && this.remainingDaysSoat != null">
                                  <i class="fa fa-exclamation-circle icon-vh color--error" aria-hidden="true"></i>
                                </div>
                                <div
                                  *ngIf="(!this.vehicle.validSOAT || !this.vehicle.validSOAT.active) && this.vehicle.validateTruora">
                                  <i class="fa fa-exclamation-circle icon-vh color--error" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                            <div class="mt-3 w-100 text-center"
                              *ngIf="utils.isDefined(vehicle) && utils.isDefined(vehicle.validSOAT) && utils.isDefined(vehicle.validSOAT.expirationDate)">
                              <p class="alert alert-info" role="alert" *ngIf="this.remainingDaysSoat >=0"
                                [ngClass]="{'alert-info':remainingDaysSoat > 7, 'alert-warning':remainingDaysSoat<=7 && remainingDaysSoat>=0}">
                                <mat-label class="font-weight-bold text-alert">
                                  Faltan {{this.remainingDaysSoat}} dias, para que se cumpla la fecha de vencimiento.
                                </mat-label>
                              </p>
                              <p class="alert alert-danger" role="alert"
                                *ngIf="this.remainingDaysSoat <0 && this.remainingDaysSoat != null">
                                <mat-label class="font-weight-bold text-alert">
                                  El documento tiene {{this.remainingDaysSoat * -1}} dias de vencido.
                                </mat-label>
                              </p>
                              <p class="alert alert-warning" role="alert"
                                *ngIf="this.utils.isEmpty(this.remainingDaysSoat)">
                                <mat-label class="font-weight-bold text-alert">
                                  El Vehiculo no tiene registro de vencimiento de RTM.
                                </mat-label>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!---------------------------------------------------------------->
                      <!-- INFO LICENSE CARD -->
                      <div class="card-info-vehicle-user" *ngIf="relatedInfoDriver?.driver?.information?.document">
                        <app-manual-data-vehicle-input
                          *ngIf="driver?.state?.description !== 'Pending' && correctVehicleData" class="w-100"
                          type="alert" [outServiceDbs]="vehicleDataBase" dataType="license"
                          [checkId]="!!(driver?.truora?.checkId)" [haveData]="getValidateLicense()"
                          additionalText=". Es por ello que no podras visualizar la información de las licencias"
                          [allowedToActions]="(canEditVehicleInfo || canOverwriteVehicleInfo) && (!driver?.driverLicenseCategory?.length || driver?.driverLicenseCategory?.length<3)"
                          (emitServiceTrigger)="editVehicleInfo($event)"
                          (newSecurityStudy)="checkValidateVehicle($event)"></app-manual-data-vehicle-input>
                        <div class="card-info-vehicle-user__content h-100">
                          <div class="icon-redo-validar font-secondary-dark" (click)="checkValidateVehicle(true)"
                            *ngIf="showAlertLicenseExpirationDate">
                            <i class="fas fa-redo font-secondary-dark"></i>
                            &nbsp;
                            <span class="text-strong font-secondary-dark"> Validar Licencia</span>
                          </div>
                          <div class="w-100">
                            <div class="d-flex justify-content-center title-component">
                              LICENCIAS
                              <i class="fas fa-eye font-secondary-dark i-view-info cursor-pointer ml-1"
                                (click)="showDialogLicense()"
                                *ngIf="utils.isDefined(driver?.driverLicenseCategory) && driver?.driverLicenseCategory.length"></i>
                            </div>
                            <div class="license-plate-container"
                              *ngFor="let license of driverCopy?.driverLicenseCategory">
                              <div class="text-center">
                                <div class="center-horizontal txt_center field__card--label">
                                  Categoría
                                </div>
                                <div class="field__card--value">
                                  {{license?.category ? license.category : '-'}}
                                </div>
                              </div>
                              <div class="text-center" matTooltip="{{license?.expirationDate | date:'fullDate'}}">
                                <div class="center-horizontal txt_center field__card--label">
                                  Estado
                                </div>
                                <div class="field__card--value" [ngClass]="{'color--error': license?.isLicenseExpired || !license.active, 
                              'color--success': license.active && !license?.isLicenseExpired}">
                                  {{license?.active && !license?.isLicenseExpired ? 'Activo' : 'Inactivo'}}
                                </div>
                              </div>
                              <u class="text-strong font-secondary-dark cursor-pointer"
                                (click)="showDialogLicense(license)">
                                Ver detalle
                              </u>
                              <i class="fas fa-pencil-alt cursor-pointer font-primary-light"
                                *ngIf="canOverwriteVehicleInfo" (click)="editVehicleInfo('license',license)"></i>
                            </div>
                            <p *ngIf="!driver?.driverLicenseCategory?.length"
                              class="alert alert-warning my-2 w-100">
                              <mat-label class="text-bold text-alert">
                                {{driver?.information?.document ? 'El conductor no tiene licencias de conducción registradas' : 'El vehículo no tiene conductor asignado'}}
                              </mat-label>
                            </p>
                          </div>
                          <div class="d-flex justify-content-center align-items-end mt-3"
                            *ngIf="(canEditVehicleInfo || canOverwriteVehicleInfo) && (!driver?.driverLicenseCategory?.length || driver?.driverLicenseCategory?.length<3)">
                            <app-manual-data-vehicle-input *ngIf="driver?.state?.description !== 'Pending'"
                              type="manualButton" dataType="license" [checkId]="!!(driver?.truora?.checkId)"
                              [haveData]="getValidateLicense()"
                              (emitServiceTrigger)="editVehicleInfo($event)"></app-manual-data-vehicle-input>
                          </div>
                        </div>
                      </div>
                      <!---------------------------------------------------------------->
                       <!--INFO-ARL -->
                       <div class="card-info-vehicle-user" *ngIf="relatedInfoDriver?.driver?.information?.document">
                        <app-manual-data-vehicle-input
                          *ngIf="driver?.state?.description !== 'Pending' && correctVehicleData" class="w-100"
                          type="alert" dataType="arl" [checkId]="!!(driver?.truora?.checkId)"
                          [haveData]="getValidateDriverDocuments('arl')" [allowedToActions]="showEditVehicleMessage"
                          (newSecurityStudy)="checkValidateVehicle($event)"
                          outServiceDbs="arl"></app-manual-data-vehicle-input>
                        <div class="card-info-vehicle-user__content h-100">
                          <div class="content-center title-component">
                            ARL
                          </div>
                          <div class="separator_center"></div>
                          <div class="content-center gap">
                            <span class="text__color text-center info-state"
                              [ngClass]="{'color--error': adminUserService.getarlState(driver) === 'Inactivo','color--warning':(adminUserService.getarlState(driver) === 'Pendiente' || adminUserService.getarlState(driver) === 'Estudio de seguridad en proceso') ,'color--success': adminUserService.getarlState(driver) === 'Activo'}">
                              {{adminUserService.getarlState(driver)}}
                            </span>
                            <app-docs-state
                              *ngIf="driver && userInfoSupportDriver?.length && getDocumentSupport('ARL') && !adminUserService.getTruoraInProgress(driver)"
                              matSuffix [expireDocs]="expireArl" [documents]="userInfoSupportDriver"
                              [userState]="driver?.arl?.active" [dueDateDoc]="driver?.arl?.dueDate" [typesRules]="typesRules"
                              type="ARL" [showOptional]="true"></app-docs-state>
                          </div>
                        </div>
                      </div>
                      <!--INFO-EPS -->
                      <div class="card-info-vehicle-user" *ngIf="relatedInfoDriver?.driver?.information?.document">
                        <app-manual-data-vehicle-input
                          *ngIf="driver?.state?.description !== 'Pending' && correctVehicleData" class="w-100"
                          type="alert" dataType="eps" [checkId]="!!(driver?.truora?.checkId)"
                          [haveData]="getValidateDriverDocuments('eps')" [allowedToActions]="showEditVehicleMessage"
                          (newSecurityStudy)="checkValidateVehicle($event)"
                          outServiceDbs="eps"></app-manual-data-vehicle-input>
                        <div class="card-info-vehicle-user__content h-100">
                          <div class="content-center title-component">
                            EPS
                          </div>
                          <div class="separator_center"></div>
                          <div class="content-center gap">
                            <span class="text__color text-center info-state"
                              [ngClass]="{'color--error': adminUserService.getepsState(driver) === 'Inactivo','color--warning':(adminUserService.getepsState(driver) === 'Pendiente' || adminUserService.getepsState(driver) === 'Estudio de seguridad en proceso') ,'color--success': adminUserService.getepsState(driver) === 'Activo'}">
                              {{adminUserService.getepsState(driver)}}
                            </span>
                            <app-docs-state
                              *ngIf="driver && userInfoSupportDriver?.length && getDocumentSupport('EPS') && !adminUserService.getTruoraInProgress(driver)"
                              matSuffix [expireDocs]="expireEps" [documents]="userInfoSupportDriver"
                              [userState]="driver?.eps?.active" [dueDateDoc]="driver?.eps?.dueDate" [typesRules]="typesRules"
                              type="EPS" [showOptional]="true"></app-docs-state>
                          </div>
                        </div>
                      </div>
                      <!---------------------------------------------------------------->

                      <!-- TRIPS AND ADDITIONAL CERTIFICATION INFO VEHICLE -->
                      <div class="card-detail-vehicule" *ngIf="hasEscortServicesCompany && relatedInfoDriver?.driver?.information?.document">
                        <div class="card-detail-column">
                          <div class="info-card-vehicle">
                            <div class="title-component text-center">
                              Acreditación de personal operativo
                            </div>
                            <div class="separator_center"></div>
                            <div class="center-horizontal txt_center field__card--label">
                              Fecha de expiración
                            </div>
                            <div class="center-horizontal txt_center field__card--value">
                              {{getAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)?.dueDate
                              ?
                              (getAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION).dueDate
                              | dateFormat:'only-date'):
                              '--'}}
                            </div>
                          </div>
                          <div class="container-icon-vh ml-3 cursor-pointer"
                            *ngIf="getAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)?.dueDate">
                            <div matTooltip="El certificado está activo y vigente"
                              *ngIf="getAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)?.active 
                                && getAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)?.path 
                                && getRemainingDaysAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION) > 7 ">
                              <i class="fas fa-check-circle icon-vh color--success"></i>
                            </div>
                            <div matTooltip="El certificado está activo pero cercano a vencerse"
                              *ngIf="getAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)?.active 
                                && getAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)?.path 
                                && getRemainingDaysAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION) >=0 
                                && getRemainingDaysAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION) <= 7">
                              <i class="fa fa-exclamation-triangle icon-vh color--warning" aria-hidden="true"></i>
                            </div>
                            <div matTooltip="El certificado está vencido"
                              *ngIf="getAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)?.active 
                                && getAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)?.path 
                                && getRemainingDaysAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION) <0">
                              <i class="fa fa-exclamation-circle icon-vh color--error" aria-hidden="true"></i>
                            </div>
                            <div matTooltip="El certificado no está activo o no cuenta con un documento válido"
                              *ngIf="!getAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)?.active
                                ||!getAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)?.path ">
                              <i class="fa fa-exclamation-circle icon-vh color--error" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                        <div class="mt-3"
                          *ngIf="getAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)?.dueDate">
                          <p class="alert alert-info" role="alert"
                            *ngIf="getRemainingDaysAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION) > 7 ">
                            <mat-label class="font-weight-bold text-alert">
                              Faltan
                              {{getRemainingDaysAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)}}
                              dias, para que se cumpla la fecha de vencimiento.
                            </mat-label>
                          </p>
                          <p class="alert alert-warning" role="alert"
                            *ngIf="getRemainingDaysAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION) >=0 
                              && getRemainingDaysAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION) <= 7 ">
                            <mat-label class="font-weight-bold text-alert">
                              Faltan
                              {{getRemainingDaysAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)}}
                              dias, para que se cumpla la fecha de vencimiento.
                            </mat-label>
                          </p>
                          <p class="alert alert-danger" role="alert"
                            *ngIf="getRemainingDaysAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION) <0">
                            <mat-label class="font-weight-bold text-alert">
                              El documento tiene
                              {{getRemainingDaysAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)
                              * -1}}
                              dias de vencido.
                            </mat-label>
                          </p>
                          <p class="alert alert-warning" role="alert"
                            *ngIf="utils.isEmpty(getRemainingDaysAdditionalCertification(additionalCertificationEnum.OPERATIONAL_ACCREDITATION))">
                            <mat-label class="font-weight-bold text-alert">
                              No hay registro de vencimiento de Acreditación de personal operativo
                            </mat-label>
                          </p>
                        </div>
                      </div>
                      <div class="card-detail-vehicule" *ngIf="relatedInfoDriver?.driver?.information?.document">
                        <app-vehicle-trips [driver]="driver"></app-vehicle-trips>
                      </div>
                      <div class="card-detail-vehicule">
                        <app-vehicle-trips [vehicle]="vehicle"></app-vehicle-trips>
                      </div>
                    </div>
                    <!-- ALERT MESAGGE-->
                    <div class="col-12 px-0 state-description-container"
                      *ngIf="utils.isDefined(vehicle) && utils.isDefined(vehicle.state) && utils.isDefined(vehicle.state?.active) && !vehicle.state?.active && utils.isDefined(vehicle.state?.description)">
                      <div class="alert alert-warning text-center text-bold" role="alert">
                        <span class="title-purple" title="{{vehicle.state?.description}}">
                          <i class="fas fa-exclamation-triangle"></i>
                          {{vehicle.state?.description | StateUser}}
                          <i class="far fa-copy cursor-pointer" title="Copiar" (click)="copyMessage()"></i>
                        </span>
                      </div>
                    </div>
                    <!-- ASSIGN OWNER AND DRIVER -->
                    <div class="col-12 mt-3 px-0 cursor-pointer" *ngIf="!vehicle.driver && !vehicle.owner"
                      (click)="openEditVehicle()">
                      <div class="alert alert-warning text-center text-strong" role="alert">
                        <span>
                          <i class="fas fa-user-plus fa-lg mr-1"></i>
                          Asignar conductor y propietario
                        </span>
                      </div>
                    </div>
                    <div class="container-vehicle-card-row">
                      <!--INFO DRIVER CARD-->
                      <!--<app-summary-user [user]="driver" [vehicle]="vehicle" *ngIf="driver"></app-summary-user>
                      <app-summary-user [user]="owner" [vehicle]="vehicle" *ngIf="owner"></app-summary-user>
                      <app-summary-user [user]="administrator" [vehicle]="vehicle" *ngIf="administrator">
                      </app-summary-user>-->

                      <div class="card-detail-vehicule">
                        <div class="text-center" *ngIf="!vehicle.driver">
                          <span class="field__card--title">
                            Conductor
                          </span>
                          <p class="field__card--label">
                            El vehículo no cuenta actualmente con conductor
                          </p>
                          <div class="user-add" (click)="assignDriver()" *ngIf="!vehicle.driver && vehicle.owner">
                            <i class="fas fa-user-plus fa-lg"></i>
                            <span class="ml-2"><strong>Asignar un conductor</strong></span>
                          </div>
                        </div>
                        <app-user-card class="width-100" [role]="'Driver'" [user]="driver"
                          [disabledEdit]="disabledOptions" [notAllowedEdit]="notAllowedEdit"
                          (onEdit)="getVehicle($event)" [relatedInfo]="relatedInfoDriver"
                          [validateTruora]="this.vehicle.validateTruora"
                          *ngIf="vehicle.driver && vehicle.driver.document && driver && vehicle && vehicle.id"></app-user-card>
                      </div>
                      <!--INFO OWNER CARD-->
                      <div class="card-detail-vehicule">
                        <div class=" icon-izq" permission-display [module]="permission.administration.module"
                          [functionality]="permission.administration.editOwnerVehicle">
                        </div>
                        <div class="text-center" *ngIf="!vehicle.owner">
                          <span class="field__card--title">
                            Propietario
                          </span>
                          <p class="field__card--label">
                            El vehículo no cuenta actualmente con propietario
                          </p>
                          <div class="user-add" (click)="openEditVehicle()" *ngIf="!vehicle.owner && vehicle.driver">
                            <i class="fas fa-user-plus fa-lg"></i>
                            <span class="ml-2"><strong>Asignar un propietario</strong></span>
                          </div>
                        </div>
                        <app-user-card class="width-100" [role]="'Owner'" [user]="owner"
                          [relatedInfo]="relatedInfoDriver" [disabledEdit]="disabledOptions"
                          [notAllowedEdit]="notAllowedEdit" (onEdit)="getVehicle($event)"
                          *ngIf=" vehicle.owner && vehicle.owner.document"></app-user-card>
                      </div>
                      <!--INFOR ADMINISTRATOR-->
                      <div class="card-detail-vehicule">
                        <app-user-card class="width-100" [role]="'Admin'" [user]="admin"
                          [relatedInfo]="relatedInfoDriver" [disabledEdit]="disabledOptions"
                          [notAllowedEdit]="notAllowedEdit" (onEdit)="getVehicle($event)"></app-user-card>
                        <!--<app-driver-cargo class="width-100" *ngIf="vehicle.administrator" [onlyDriver]="true"
                          [administrator]="vehicle.administrator" (emitDriver)="updateDriver($event)"
                          [expireArl]="expireArl" [expireEps]="expireEps">
                        </app-driver-cargo>-->
                      </div>
                    </div>

                    <div class="container-vehicle-card-row mt-3">
                      <!--BARRA DE PROGRESO-->
                      <div class="card-detail-vehicule">
                        <div class="w-100">
                          <div class="title-component mb-0">Estado Cuentas Bancarias</div>
                          <!--PROGRESS BAR-->
                          <div class="progress mb-3 rounded-3" style="height: 10px;"
                            *ngIf="stateProgressBar != 'Cuentas Bancarias sin Registrar'">
                            <div *ngIf="stateProgressBar == 'Cuenta de Saldos sin Registrar'"
                              class="progress-bar bg-progress-gradient border-end border-white border-2 dot_yellow"
                              role="progressbar" style="width: 75%;" aria-valuenow="20" aria-valuemin="0"
                              aria-valuemax="100"></div>
                            <div *ngIf="stateProgressBar == 'Cuentas Registradas sin Aprobar'"
                              class="progress-bar bg-progress-gradient border-end border-white border-2 dot_blue"
                              role="progressbar" style="width: 90%;" aria-valuenow="20" aria-valuemin="0"
                              aria-valuemax="100"></div>
                            <div *ngIf="stateProgressBar == 'Cuentas Registradas Aprobadas'"
                              class="progress-bar bg-progress-gradient border-end border-white border-2 dot_green"
                              role="progressbar" style="width: 100%;" aria-valuenow="10" aria-valuemin="0"
                              aria-valuemax="100"></div>
                            <div *ngIf="stateProgressBar == 'Cuenta de Anticipos sin Registrar'"
                              class="progress-bar bg-progress-gradient border-end border-white border-2 dot_orange"
                              role="progressbar" style="width: 75%;" aria-valuenow="10" aria-valuemin="0"
                              aria-valuemax="100"></div>
                          </div>
                          <div *ngIf="stateProgressBar == 'Cuentas Bancarias sin Registrar'"
                            class="progress mb-3 rounded-3" style="height: 10px;">
                            <div class="progress-bar dot_red bg-progress-gradient border-end border-white border-2"
                              role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0"
                              aria-valuemax="100"></div>
                          </div>
                          <!--CONVENTIONS-->
                          <div class="row fs--1 fw-semi-bold text-500 g-0">
                            <div class="col-auto d-flex align-items-center pe-3"><span
                                class="dot dot_red"></span><span>Cuentas Sin
                                Registrar</span><span
                                class="d-none d-md-inline-block d-lg-none d-xxl-inline-block"></span></div>
                            <div class="col-auto d-flex align-items-center pe-3"><span
                                class="dot dot_yellow"></span><span>Cuenta saldos sin registrar</span><span
                                class="d-none d-md-inline-block d-lg-none d-xxl-inline-block"></span></div>
                            <div class="col-auto d-flex align-items-center pe-3"><span
                                class="dot dot_orange"></span><span>Cuenta anticipos sin registrar</span><span
                                class="d-none d-md-inline-block d-lg-none d-xxl-inline-block"></span>
                            </div>
                            <div class="col-auto d-flex align-items-center"><span
                                class="dot dot_blue bg-200"></span><span>Cuentas registradas sin aprobar</span><span
                                class="d-none d-md-inline-block d-lg-none d-xxl-inline-block"></span>
                            </div>
                            <div class="col-auto d-flex align-items-center"><span
                                class="dot dot_green bg-200"></span><span>Cuentas registradas sin aprobar</span><span
                                class="d-none d-md-inline-block d-lg-none d-xxl-inline-block"></span>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!--GPS-->
                      <div class="card-detail-vehicule">
                        <div class="title-component gps-title d-flex justify-content-between">
                          GPS
                          <i permission-display [module]="permission.administration.module"
                            [functionality]="permission.administration.editGpsVehicle"
                            class="fas fa-edit font-secondary-dark i-gps float-right" *ngIf="disabledOptions"
                            (click)="editGps()"></i>
                        </div>
                        <div class="separator_center"></div>
                        <div class="container-text">
                          <label class="field__card--label">
                            Tipo
                          </label>
                          <label class="field__card--value">
                            {{vehicle && vehicle.gps && vehicle.gps?.gpsType != '' ?this.vehicle.gps.gpsType:'--'}}
                          </label>
                        </div>
                        <div class="container-text">
                          <label class="field__card--label">
                            Usuario
                          </label>
                          <label class="field__card--value">
                            {{vehicle?.gps?.userGps? vehicle.gps.userGps :'--'}}
                          </label>
                        </div>
                        <div class="container-text">
                          <label class="field__card--label">
                            Contraseña
                          </label>
                          <label class="field__card--value">
                            {{vehicle?.gps?.passwordGps? vehicle.gps.passwordGps:'--'}}
                          </label>
                        </div>
                        <div class="container-text" *ngIf="hasSatrackGps">
                          <label class="field__card--label">
                            Clase
                          </label>
                          <label class="field__card--value">
                            {{vehicle && vehicle.gps && vehicle.gps.isPortableUnit?'Portable':'Integrado'}}
                          </label>
                        </div>
                        <div class="container-text" *ngIf="hasSatrackGps && vehicle?.gps?.isPortableUnit">
                          <label class="field__card--label">
                            Código
                          </label>
                          <label class="field__card--value">
                            {{vehicle?.gps?.gpsId ? vehicle.gps.gpsId :'--'}}
                          </label>
                        </div>
                        <div class="container-text">
                          <label class="field__card--label">
                            Link
                          </label>
                          <div class="d-flex flex-wrap justify-content-center gps-value-container">
                            <label class="field__card--value text-break">
                              {{ vehicle && vehicle.gps && vehicle.gps.gpsType ? (vehicle.gps.gpsType | gpsType) : '--'
                              }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-md-12 offset-md-12 text-right">
                  <div class="button-container">
                    <button permission-display [module]="permission.administration.module"
                      [functionality]="permission.administration.deactivateVehicle" mat-raised-button color="primary"
                      type="submit" class="warn button-width-auto" (click)="openDialogDisableVehicle()"
                      *ngIf="vehicle.state?.active">
                      Desactivar Vehículo
                    </button>
                    <button mat-raised-button color="primary" type="submit" class="warn button-width-auto"
                      (click)="openDialogActiveVehicle()" *ngIf="canActivateVehicle">
                      Autorizar Vehículo
                    </button>
                  </div>
                </div>
                <span class="separator-horizontal"></span>
              </mat-accordion>
            </mat-tab>
            <!-- Bank Account Advance -->
            <mat-tab label="Anticipos">
              <span class="separator-horizontal"></span>
              <!-- Cuenta Bancaria -->
              <app-bank-account [form]='this.formBankAccountAdvance' [vehicle]='this.vehicle' type='advance'
                [oppositeForm]="this.formBankAccountBalance">
              </app-bank-account>
              <span class="separator-horizontal"></span>
              <!-- Documentos -->
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title class="text-strong">
                    {{documentsValidator ? 'Documentos': 'Documentos invalidos'}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-md-12">
                    <!-- Select -->
                    <div class="row">
                      <!-- Type Documents -->
                      <div class="col-md-4">
                        <mat-form-field appearance="standard">
                          <mat-select [(ngModel)]="selectDocumentView" [ngModelOptions]="{standalone: true}"
                            (selectionChange)="onChangeTypeDocument('bankAccountAdvance')">
                            <mat-option *ngFor="let document of listTypeDocumentBankView" [value]="document.value">
                              {{document.name}} </mat-option>
                          </mat-select>
                          <mat-label>
                            <i>Elija un Documento</i>
                          </mat-label>
                        </mat-form-field>
                      </div>
                      <!-- Approval -->
                      <div class="col-md-4" permission-display [module]="permission.payments.module"
                        [functionality]="permission.payments.approveBankAdvance" *ngIf="vehicle.bankAccountAdvance">
                        <mat-card-actions>
                          <button mat-raised-button color="primary" type="button"
                            (click)="approvalDocuments('advance', true)" class="button-width-auto">
                            Aprobar
                          </button>
                          <button mat-raised-button class="primary-secondary button-width-auto" type="button"
                            color="primary" (click)="approvalDocuments('advance', false)">
                            No aprobar
                          </button>
                        </mat-card-actions>
                      </div>
                      <!-- State Approval -->
                      <div class="col-md-4" *ngIf="vehicle.bankAccountAdvance">
                        <p class="alert alert-warning" role="alert"
                          *ngIf="vehicle.bankAccountAdvance.state && !vehicle.bankAccountAdvance.state.active">
                          <mat-label class="font-weight-bold">
                            Motivo de rechazo:
                          </mat-label>
                          <mat-divider></mat-divider>
                          <span class="font-italic">
                            {{vehicle.bankAccountAdvance.state.description}}
                          </span>
                        </p>
                        <p class="alert alert-success" role="alert"
                          *ngIf="vehicle.bankAccountAdvance.state && vehicle.bankAccountAdvance.state.active">
                          <mat-label class="font-weight-bold">
                            Datos bancarios aprobados
                          </mat-label>
                        </p>
                        <p class="alert alert-warning" role="alert"
                          *ngIf="!utils.isDefined(vehicle.bankAccountAdvance.state) || !utils.isDefined(vehicle.bankAccountAdvance.state.active)">
                          <mat-label class="font-weight-bold">
                            Datos bancarios pendientes de aprobar
                          </mat-label>
                        </p>
                      </div>
                    </div>
                    <!-- Preview Document -->
                    <div class="row" *ngIf="nameFileAdvanceSelected">
                      <app-files-storage class="width-100" [nameFile]="nameFileAdvanceSelected"></app-files-storage>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-tab>
            <!-- Bank Account Balance -->
            <mat-tab label="Saldos">
              <!-- Cuenta Bancaria -->
              <app-bank-account [form]='this.formBankAccountBalance' [vehicle]='this.vehicle' type='balance'
                [oppositeForm]="this.formBankAccountAdvance">
              </app-bank-account>
              <span class="separator-horizontal"></span>
              <!-- Documentos -->
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title class="text-strong">
                    Documentos
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-md-12">
                    <!-- Select -->
                    <div class="row">
                      <div class="col-md-4">
                        <mat-form-field appearance="standard">
                          <mat-select [(ngModel)]="selectDocumentView" [ngModelOptions]="{standalone: true}"
                            (selectionChange)="onChangeTypeDocument('bankAccountBalance')">
                            <mat-option *ngFor="let document of listTypeDocumentBankView" [value]="document.value">
                              {{document.name}} </mat-option>
                          </mat-select>
                          <mat-label>
                            <i>Elija un Documento</i>
                          </mat-label>
                        </mat-form-field>
                      </div>
                      <div class="col-md-4" permission-display [module]="permission.payments.module"
                        [functionality]="permission.payments.approveBankBalance" *ngIf="vehicle.bankAccountBalance">
                        <mat-card-actions>
                          <button mat-raised-button color="primary" type="button"
                            (click)="approvalDocuments('balance', true)" class="button-width-auto">
                            Aprobar
                          </button>
                          <button mat-raised-button class="primary-secondary button-width-auto" type="button"
                            color="primary" (click)="approvalDocuments('balance', false)">
                            No aprobar
                          </button>
                        </mat-card-actions>
                      </div>
                      <!-- State Approval -->
                      <div class="col-md-4" *ngIf="vehicle.bankAccountBalance">
                        <p class="alert alert-warning" role="alert"
                          *ngIf="vehicle.bankAccountBalance.state && !vehicle.bankAccountBalance.state.active">
                          <mat-label class="font-weight-bold">
                            Motivo de rechazo:
                          </mat-label>
                          <mat-divider></mat-divider>
                          <span class="font-italic">
                            {{vehicle.bankAccountBalance.state.description}}
                          </span>
                        </p>
                        <p class="alert alert-success" role="alert"
                          *ngIf="vehicle.bankAccountBalance.state && vehicle.bankAccountBalance.state.active">
                          <mat-label class="font-weight-bold">
                            Datos bancarios aprobados
                          </mat-label>
                        </p>
                        <p class="alert alert-warning" role="alert"
                          *ngIf="!utils.isDefined(vehicle.bankAccountBalance.state) || !utils.isDefined(vehicle.bankAccountBalance.state.active)">
                          <mat-label class="font-weight-bold">
                            Datos bancarios pendientes de aprobar
                          </mat-label>
                        </p>
                      </div>
                    </div>
                    <!-- Preview Document -->
                    <div class="row" *ngIf="nameFileBalanceSelected">
                      <app-files-storage class="width-100" [nameFile]="nameFileBalanceSelected"></app-files-storage>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-tab>
            <!-- Trailers -->
            <!-- Información general -->
            <mat-tab *ngIf="showTabTrailer">
              <ng-template mat-tab-label>
                <label #tooltipTrailer="matTooltip" matTooltipClass="tooltip__alert"
                  matTooltip="Recuerde, debe registrar un trailer.">Trailer</label>
                &nbsp;&nbsp;
                <mat-icon class="warning-icon"
                  *ngIf="this.vehicle.vehicleType && this.vehicle.vehicleType.name === 'TRACTOCAMION' && !this.vehicle.trailerId">
                  warning</mat-icon>
              </ng-template>
              <mat-accordion [multi]="true">
                <span class="separator-horizontal"></span>
                <div class="row">
                  <div class="col-md-12">
                    <app-trailer-vehicle [vehicle]="vehicle" *ngIf="vehicle"></app-trailer-vehicle>
                  </div>
                </div>
                <span class="separator-horizontal"></span>
              </mat-accordion>
            </mat-tab>
            <!-- Documentos -->
            <mat-tab>
              <ng-template mat-tab-label>
                Documentos
                <i class="fa fa-exclamation-circle icon-vh color--error ml-2" style="font-size: 1.5rem;"
                  *ngIf="!documentsValidator || unacceptedDocuments?.length"></i>
              </ng-template>
              <app-vehicle-documents
                *ngIf="reactiveFormCreate && reactiveFormCreate.form && !utils.isEmpty(reactiveFormCreate.form.get('vehicle.id').value)"
                [form]="reactiveFormCreate.form" (emitToParent)="refreshVehicleData()"></app-vehicle-documents>
            </mat-tab>
            <!-- Referencias y Contactos -->
            <mat-tab *ngIf="reactiveFormCreate && reactiveFormCreate.form && !utils.isEmpty(reactiveFormCreate.form.get('driver.information.document').value)">
              <ng-template mat-tab-label>
                Referencias & Contacto
                <i class="fa fa-exclamation-circle icon-vh color--error ml-2" *ngIf="invalidReferences.length"></i>
              </ng-template>
              <app-vehicle-contacts
                *ngIf="reactiveFormCreate && reactiveFormCreate.form && !utils.isEmpty(reactiveFormCreate.form.get('driver.information.document').value) && !utils.isEmpty(reactiveFormCreate.form.get('owner.information.document').value)"
                [form]="reactiveFormCreate.form" [driver]="driver" [fromCreation]="false" (emitDriver)="updateDriver()"></app-vehicle-contacts>
            </mat-tab>
            <!-- <mat-tab label="Viajes">
              <app-vehicle-trips [licensePlate]="vehicle?.id" [showList]="true"></app-vehicle-trips>
            </mat-tab> -->
          </mat-tab-group>
        </form>

        <!-- Button back -->
        <div class="row md-form" *ngIf="paramsDialog && !paramsDialog.vehicle">
          <label class="label-back-step col-md-2">
            <span class="cursor-pointer" (click)="goToList()">
              <figure>
                <img src="/assets/svg/icons/icon-back.png" alt="">
              </figure>
              Atrás
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</aside>
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from "@angular/core";
import 'firebase/database';
import 'firebase/storage';
import { CargoTrackingService } from "./cargo-tracking.service";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { AuthService } from "src/app/core/services/authentication.service";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AccountService } from "../../account/account.service";
import { Driver } from "src/app/core/models/driver";
import { Utils } from "src/app/core/resources/utils";
import { Global } from "src/app/core/resources/global";
import {
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ContactService } from "src/app/public/contact/contact.service";
import { Contact } from "src/app/core/interfaces/formContact";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { RegisterCargoPaymentComponent } from "../register-cargo-payment/register-cargo-payment.component";
import { ListCargoPaymentsComponent } from "../list-cargo-payments/list-cargo-payments.component";
import { Cargo } from "src/app/core/interfaces/cargo";
import { PermissionRole } from "src/app/core/resources/permission-role";
import { RoleService } from "src/app/core/services/role.service";
import { DatabaseReference } from "@angular/fire/database/interfaces";
declare var google: any;
import PlaceResult = google.maps.places.PlaceResult;
import { GoogleService } from "src/app/core/services/google.service";
import { Titles } from "src/app/core/resources/titles";
import { MatButtonToggleChange, MatDialog, MatDialogConfig } from "@angular/material";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { CargoDetailComponent } from "../cargo-detail/cargo-detail.component";
import { Permission } from "src/app/core/resources/permission";
import { CargoOptionsComponent } from "../cargo-options/cargo-options.component";
import { Vehicle } from "src/app/core/interfaces/vehicle";
import { CargoRatingComponent } from "../cargo-rating/cargo-rating.component";
import { VehiclesService } from "../../administration/vehicles/list-vehicles.service";
import { ConfirmBellAlertsComponent } from "../confirm-bell-alerts/confirm-bell-alerts.component";
import { ItemAdditionalService } from "../item-additional-service/item-additional-service.service";
import { BasicResponse } from "src/app/core/interfaces/basicResponse";
import { AdditionalServicesRequired } from "src/app/core/interfaces/additional-services-required";
import { concatMap } from "rxjs/operators";
import { Observable, Subscription, from } from "rxjs";
import { Traceability } from "src/app/core/interfaces/lastPointLocation";
import { PDFTypes } from "src/app/core/enums/pdf-types.enum";
import { Patterns } from "src/app/core/resources/patterns";
import { RndcErrorSolver } from "src/app/core/classes/rndc-error-solver";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { FirebaseDatabase } from "src/app/core/services/firebaseDatabase.service";
import { CargoTracking } from "src/app/core/interfaces/cargoTracking.interface";
import { OptionsAutocomplete } from "src/app/core/interfaces/optionsAutocomplete";
import { StandardMapService } from "src/app/shared/standard-map/standard-map.service";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { CargoService } from "src/app/core/services/cargo.service";
import { FreightForwarderEvidenceService } from "../cargo-evidence/cargo-evidence.service";
import { ServiceMessages } from "src/app/core/messages/service-messages.enum";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { ConfirmComponent } from "src/app/shared/confirm/confirm.component";
import { CargoAdvancePercentageComponent } from "../cargo-advance-percentage/cargo-advance-percentage.component";
import { Dialog } from "src/app/core/resources/dialog";
import { RequestDatesToMonitorComponent } from "../request-dates-to-monitor/request-dates-to-monitor.component";
import { BasicCompany } from "src/app/core/interfaces/basicCompany";
import { CargoOptionsService } from "../cargo-options/cargo-options.service";
import { DateManager } from "src/app/core/managers/date.manager";
import { DateEnum } from "src/app/core/enums/date.enum";
import { MapPoint } from "src/app/core/interfaces/mapPoint";
import { EmitToParent } from "src/app/core/interfaces/emitToParent";
import { CargoStateDict, CargoStateEnum } from "src/app/core/enums/cargoState.enum";
import { environment } from "src/environments/environment";
import { PlanningRoute } from "src/app/core/interfaces/planning-route";
import { PlanningRouteService } from "src/app/core/services/planning-route.service";
import { RouteItinerary } from "src/app/core/interfaces/route-itinerary";
import { LocationAddress } from "src/app/core/interfaces/locationAddress";
import { StandardMapComponent } from "src/app/shared/standard-map/standard-map.component";
import { AdditionalCostCargo } from "src/app/core/interfaces/additionalCostCargo";
import { CargoManager } from "src/app/core/managers/cargo.manager";



@Component({
  selector: "app-cargo-tracking",
  templateUrl: "./cargo-tracking.component.html",
  styleUrls: ["./cargo-tracking.component.scss"],
  providers: [CargoTrackingService, GoogleService, FirebaseDatabase, DatePipe, CurrencyPipe],
})
export class CargoTrackingComponent implements OnInit {
  @Output() loadFinished: EventEmitter<string> = new EventEmitter<string>();
  permission = Permission;
  cargoService = CargoService;
  formTrackContact: FormGroup;
  showRouteGoogle: boolean;
  showRoutePlan: boolean;
  routePlanItinerary: RouteItinerary;
  cargo: Cargo = null;
  cargoOriginal: Cargo = null;
  tracepoints: Traceability;
  @ViewChild(CargoOptionsComponent, { static: false })
  cargoOptionsComponent: CargoOptionsComponent;
  @ViewChild("modalConfirmLoadPositive", { static: true })
  modalConfirmLoadPositive: ElementRef;
  @ViewChild("modalConfirmLoad", { static: true }) modalConfirmLoad: ElementRef;
  @ViewChild("modalConfirmLoadNegative", { static: true })
  @ViewChild(StandardMapComponent, { static: false }) standardMap: StandardMapComponent;
  modalConfirmLoadNegative: ElementRef;
  @Input() entryLoad: Cargo;
  instanceModalConfirmLoadPositive: NgbModalRef;
  instanceModalConfirmLoad: NgbModalRef;
  instanceModalConfirmLoadNegative: NgbModalRef;
  instanceModalRegisterCargoPayment: NgbModalRef;
  instanceModalListCargoPayments: NgbModalRef;
  cargoPDF: any;
  currentRate = 0;
  commentRateCargo = "";
  refFirebaseTracking: DatabaseReference;
  refFirebaseAnomalies: DatabaseReference;
  onGetCargoDetail: any;
  driverAssigned: Driver;
  vehicleAssigned: Vehicle;
  polyline: Array<Object> = [];
  showBells: boolean = false;
  isCargoDefined: boolean = false;
  confirmAdittionalServices: boolean = false;
  cargoChangesSub: Subscription;
  public listReportedAnomalies = [];
  private oldListLocations = [];
  private oldListAnomalies = [];
  private isSending: boolean = false;
  private loadedRealtimes: boolean = false;
  mapOptions: OptionsAutocomplete = {
    zoom: 11,
    showGeofences: false,
    showMarkers: false,
    module: 'tracking'
  }
  showMarkers: FormControl = new FormControl(false);
  showGeofences: FormControl = new FormControl(false);
  originRoute;
  public listLocations: Object = {};
  public tempListLocations: Object = {};
  visibleAnomalies: boolean = false;
  flag = 1;
  fixer_attempts = 0;

  isMonitoreable: boolean = false;
  isPrincipalCompany: boolean = false;
  trackingState: boolean = true;
  hasLastMonitoringDate: boolean = true;
  lastMonitoringDate: string;
  remainingMonitoring: string;

  constructor(
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _snackBarService: SnackBarService,
    private _trackingService: CargoTrackingService,
    private planningRouteService: PlanningRouteService,
    public authService: AuthService,
    public roleService: RoleService,
    private accountService: AccountService,
    public utils: Utils,
    private patterns: Patterns,
    private contactService: ContactService,
    private route: ActivatedRoute,
    public titles: Titles,
    public dialog: MatDialog,
    private dialogManager: Dialog,
    private permissionRole: PermissionRole,
    private vehiclesService: VehiclesService,
    public itemAdditionalService: ItemAdditionalService,
    private rndcErrorSolver: RndcErrorSolver,
    private firebaseDatabase: FirebaseDatabase,
    public standardMapService: StandardMapService,
    private global: Global,
    private cargoEvidenceService: FreightForwarderEvidenceService,
    public datePipe: DatePipe,
    public _cargoService: CargoService,
    private currencyPipe: CurrencyPipe,
    private cargoOptionsService: CargoOptionsService,
    private cargoManager: CargoManager
  ) {
    this.onGetCargoDetail = this.getCargoDetail.bind(this);
    this.createFormContact();
  }
  /**
  * This method is an angular method that executes when the component is created in this case brings the information on the load to render.
  */
  ngOnInit() {
    this.fixer_attempts = 0;
    if (!this.entryLoad) {
      if (
        !this.authService.getUserSession().state ||
        !this.authService.getUserSession().state.active
      ) {
        this.goToList("/cargo/create");
      } else {
        const params = this.route.snapshot.params;
        if (!this.utils.objIsEmpty(params)) {
          if (this.utils.isDefined(params.consecutive)) {
            this.getCargoDetail(params.consecutive, false, (data: Cargo) => {
              if (data.state === CargoStateEnum.START_SERVICE) {
                this.checkAdditionalServices(data);
                this._cargoService.watch(data.id);
                this.cargoChangesSub = this._cargoService.onChange.subscribe((_: Cargo) => {
                  this.getCargoDetail(data.consecutive.toString());
                });
              } else this.refreshCargoData(data);
            });
          } else {
            this._snackBarService.openSnackBar(
              `Ocurrió un error intentando traer el detalle del servicio: ${this.cargo.consecutive}`,
              undefined,
              "alert"
            );
            this.goToList();
          }
        } else {
          this._snackBarService.openSnackBar(
            `Ocurrió un error intentando traer el detalle del servicio: ${this.cargo.consecutive}`,
            undefined,
            "alert"
          );
          this.goToList();
        }
      }
    }
    this.isPrincipalCompany = true;
    const company = this.authService.getCompany();
    const listCompanies = this.accountService.listRegisterCompanies();
    this.isPrincipalCompany = !!(
      listCompanies.length &&
      company &&
      company.companyId &&
      listCompanies.some(subcompany => subcompany.nit === company.companyId)
    );
  }

  private checkForMonitoring() {
    this.trackingState = false;
    this.hasLastMonitoringDate = false;

    if (this.cargo && this.cargo.monitorHubStartDate && this.cargo.monitorHubEndDate) {
      const now = new Date();
      const startDate = DateManager.stringToDate(this.cargo.monitorHubStartDate);
      const endDate = DateManager.stringToDate(this.cargo.monitorHubEndDate);
      this.hasLastMonitoringDate = true;
      this.lastMonitoringDate = DateManager.formatDate(endDate, 'DD/MM/YYYY LT');

      if (startDate < now && endDate > now) {
        this.trackingState = true;
        const difference = DateManager.dateDiff(endDate, DateEnum.YYYY_MM_DD_HH_mm_ZZ, new Date(), null, "seconds");
        const remaning = DateManager.durationFormat(difference, "seconds");
        this.remainingMonitoring = `${remaning.days} d ${remaning.hours} h ${remaning.minutes} min`;
      } else {
        this.trackingState = false;
      }
    } else {
      this.trackingState = false;
      this.hasLastMonitoringDate = false;
    }

    setTimeout(() => this.checkForMonitoring(), 1000);
  }

  private get pendingInitialFulfill(): boolean {
    try {
      const pendingInitialFulfill = this.canFinalizeCargo &&
        !this.cargo.totalConsignmentsInitialApproval &&
        this.cargo.ministry &&
        this.cargo.cargoFeature.uploadDownload.destination.some((destination) => {
          return destination.addresses.some((address) => {
            return !address.approvalInitialConsignment;
          });
        }) &&
        this.cargo.approval !== 'Approved';
      return pendingInitialFulfill;
    } catch (e) {
      return false;
    }
  }
  /**
  * Description: check which additional services should be contracted and which of this required additional services are already validated by the user.
  */
  checkAdditionalServices(cargo: Cargo) {
    if (cargo && cargo.additionalCosts) {
      const requiredAdditionalServices: AdditionalCostCargo[] = cargo.additionalCosts
        .filter(
          (additionalService) =>
            additionalService.type.productSiigoId === "16" ||
            additionalService.type.productSiigoId === "15"
        )
      if (
        requiredAdditionalServices.every(
          (additionalService) => additionalService.validated
        )
      ) {
        this.confirmAdittionalServices = true;
      }

      for (let i = 0; i < requiredAdditionalServices.length; i++) {
        if (requiredAdditionalServices[i].validated === true) {
          requiredAdditionalServices.splice(i, 1);
          i--;
        }
      }
      const canConfirmAdditionalServices =
        this.permissionRole.hasPermission(
          this.permission.cargo.module,
          this.permission.cargo.confirmationAdditionalMandatoryServices
        );
      if (
        requiredAdditionalServices.length > 0 &&
        !this.confirmAdittionalServices &&
        canConfirmAdditionalServices
      ) {
        this.showAlertAdditionalServices(requiredAdditionalServices);
      }
    }
  }
  /**
  * This method open a dialog that reports to the user that this page could have bell alerts.
  */
  showConfirmBellAlerts() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const dialoRef = this.dialog.open(ConfirmBellAlertsComponent, dialogConfig);
    dialoRef.afterClosed().subscribe(() => {
      this.showBells = true;
    });
  }
  /**
  * This method open a dialog that asks to the user if the required additional services are hired.
  * @param {requiredAdditionalService[]} additionalServices (requiredAdditionalService[]) is the array of required additional services hired to the load.
  */
  showAlertAdditionalServices(additionalServices: AdditionalCostCargo[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Desea confirmar si los servicios adicionales ya fueron contratados?`,
      hideBtnCancel: true,
      showNoBtn: true,
      showYesBtn: true,
      checkbox: additionalServices.map((additionalService) => ({
        name: additionalService.type.name,
        validated: additionalService.validated,
        id: additionalService.id,
      })),
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (
        result &&
        result.state === true &&
        result.checkbox &&
        result.checkbox.length > 0
      ) {
        let onlyOneSuccess = 0;
        let onlyOneError = 0;
        const additionalServiceMatches = additionalServices
          .filter((additionalService: AdditionalCostCargo) =>
            result.checkbox.some((additionalServiceSelected: AdditionalServicesRequired) =>
              additionalServiceSelected.name === additionalService.type.name
            )
          )
          .map((additionalService: AdditionalCostCargo) => ({
            ...additionalService,
            validated: true
          }));

        this.spinner.show();
        from(additionalServiceMatches)
          .pipe(
            concatMap((additionalService: AdditionalCostCargo) =>
              this.itemAdditionalService.editAdditionalService(
                additionalService,
                this.cargo.id
              )
            )
          )
          .subscribe(
            (success: BasicResponse) => {
              onlyOneSuccess++;
              this.spinner.hide();
              if (success.message === "Actualizado") {
                if (onlyOneSuccess < 2) {
                  this.snackBarService.openSnackBar(
                    "Confirmación realizada con éxito",
                    undefined,
                    "success"
                  );
                }
              } else if (success.message) {
                this.spinner.hide();
                this.snackBarService.openSnackBar(
                  success.message,
                  undefined,
                  "error"
                );
              } else {
                this.spinner.hide();
                this.snackBarService.openSnackBar(
                  "Ocurrió un error al realizar la confirmación. Vuelva a intentarlo",
                  undefined,
                  "error"
                );
              }
            },
            (error) => {
              onlyOneError++;
              if (onlyOneError < 2) {
                this.snackBarService.openSnackBar(
                  "Ocurrió un error al enviar la información",
                  undefined,
                  "error"
                );
              }
              this.spinner.hide();
            }
          );
        //!!EL ARRAY QUE SE ENVIARA CUANDO SE CORRIJA EL SERVICIO
        // const requiredAdditionalServiceId = result.checkbox.map(
        //   (additionalService) => ({
        //     id: additionalService.id,
        //     validated: true,
        //   })
        // );
        //!!BODY CUANDO SE CORRIJA EL SERVICIO
        // let body: AdditionalCostUpdate = requiredAdditionalServiceId;
        //!!SERVICIO QUE SE VA A LANZAR CUANDO SE CORRIJA SERVICIO
        //this.sendAdditionalServiceConfirmation(body)
      }
      //else {
      //   this.confirmAdittionalServices = false;
      // }
    });
  }

  // sendAdditionalServiceConfirmation(body) {
  //   this.itemAdditionalService
  //     .editAdditionalService(body, this.cargo.id)
  //     .subscribe(
  //       (success: BasicResponse) => {
  //         this.spinner.hide();
  //         if (success.message === "Actualizado") {
  //           this.snackBarService.openSnackBar(
  //             "Confirmación realizada con éxito",
  //             undefined,
  //             "success"
  //           );
  //         } else if (success.message) {
  //           this.spinner.hide();
  //           this.snackBarService.openSnackBar(
  //             success.message,
  //             undefined,
  //             "error"
  //           );
  //         } else {
  //           this.spinner.hide();
  //           this.snackBarService.openSnackBar(
  //             "Ocurrió un error al realizar la confirmación. Vuelva a intentarlo",
  //             undefined,
  //             "error"
  //           );
  //         }
  //       },
  //       (error) => {
  //         this.snackBarService.openSnackBar(
  //           "Ocurrió un error al enviar la información",
  //           undefined,
  //           "error"
  //         );
  //         this.spinner.hide();
  //       }
  //     );
  // }
  /**
* Create the form group related with the support contact information such as: name, email, phone and description.
*/
  createFormContact() {
    this.formTrackContact = new FormGroup({
      name: new FormControl(
        this.authService.getUserSession().information.name,
        Validators.required
      ),
      email: new FormControl(
        this.authService.getUserSession().email,
        [
          Validators.required,
          Validators.pattern(this.patterns.EMAIL.source),
          Validators.maxLength(100)
        ]
      ),
      phone: new FormControl(
        this.authService.getUserSession().phone,
        Validators.required
      ),
      description: new FormControl("", Validators.required),
    });
  }
  /**
* This method executes the service that send the information of the support contact.
* @param {string} description (string) is the information that the user types related with the support that needs.
*/
  sendContactInfo(description: string) {
    let dataContact: Contact = {
      name: this.formTrackContact.controls.name.value,
      email: this.formTrackContact.controls.email.value,
      phone: this.formTrackContact.controls.phone.value,
      description: description,
    };
    this.spinner.show();
    this.contactService.sendDataContact(dataContact).subscribe(
      (success) => {
        this.spinner.hide();
        this.formTrackContact.controls["description"].setValue("");
        this._snackBarService.openSnackBar(
          "Se ha enviado la informacion correctamente"
        );
      },
      (error) => {
        this.spinner.hide();
        this._snackBarService.openSnackBar(
          "No se ha podido enviar la informacion",
          undefined,
          "error"
        );
      }
    );
  }
  /**
 * This method is an angular method that executes when the component is destroy in this case close the listener of the real time firebase collections.
 */
  ngOnDestroy() {
    if (this.refFirebaseTracking)
      this.refFirebaseTracking.off();
    if (this.refFirebaseAnomalies)
      this.refFirebaseAnomalies.off();
    if (this.cargoChangesSub)
      this.cargoChangesSub.unsubscribe();
    this._cargoService.stopWatch();
    CargoService.secondsLeftToUploadEvidences = null;
    CargoService.timeLeftToUploadEvidences = null;
  }
  /**
* This method allows to go to an specific list of elements based on the path that pass it in the parameters.
* @param {string} routeUrl (string - optional) is the path needed to redirect
* @param {NavigationExtras} params (NavigationExtras - optional) extra parameters needed in the router navigate
*/
  goToList(routeUrl?: string, params?: NavigationExtras) {
    if (routeUrl) {
      if (params) {
        this.router.navigate([routeUrl, params]);
      } else {
        this.router.navigate([routeUrl]);
      }
    } else {
      this.router.navigate(["cargo/list/loadingRoutes"]);
    }
  }
  /**
 * This method brings the information on the load.
 * @param {string} consecutive (string) is the consecutive of the load.
 * @param {boolean} hideSpinner (boolean) control the visualization of the spinner loader.
 * @param {function} [callback] (function) callback that in this case go to check additional required services and also watch the changes into the specific document of the load saved in firestore (the parameter of the callback in this case is the information of the load).
 */
  getCargoDetail(consecutive: string, hideSpinner?: boolean, callback?: (data?: Cargo) => void): void {
    if (!hideSpinner) {
      this.spinner.show();
    }
    this._trackingService.detailCargoByConsecutive(consecutive).subscribe(
      (data: Cargo) => {
        if (!hideSpinner) {
          this.spinner.hide();
        }
        if (data && data.id) {

          !!callback ? callback(data) : this.refreshCargoData(data);;
        } else {
          this.cargo = null;
          this.cargoOriginal = null;
          this.showRouteGoogle = false;
          this.showRoutePlan = false;
          this.routePlanItinerary = null;
          this._snackBarService.openSnackBar(
            `Ocurrió un error intentando traer el detalle del servicio ${this.cargo && this.cargo.consecutive ? ':' + this.cargo.consecutive : ''}`,
            undefined,
            "error"
          );
          this.goToList();
        }
      },
      (error) => {
        if (!hideSpinner) {
          this.spinner.hide();
        }
        this.cargo = null;
        this.cargoOriginal = null;
        this.showRouteGoogle = false;
        this.showRoutePlan = false;
        this.routePlanItinerary = null;
        this._snackBarService.openSnackBar(
          `Ocurrió un error intentando traer el detalle del servicio: ${this.cargo && this.cargo.consecutive ? ':' + this.cargo.consecutive : ''}`,
          undefined,
          "error"
        );
        this.goToList();
      }
    );
  }

  private isNecessaryToRedirect(cargo: Cargo): boolean {
    const { redirect, path } = this.cargoManager.checkRedirectCargo(cargo, 'tracking');
    if (redirect) {
      this.showAlertChangeState(cargo);
      this.router.navigate([path]);
    }
    return redirect;
  }

  private showAlertChangeState(cargo: Cargo) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `El servicio ${cargo.consecutive} ha sido actualizado`,
      iconAlert: true,
      description: `El servicio ha pasado al estado de ${CargoStateDict[cargo.state] ? CargoStateDict[cargo.state].toLowerCase() : cargo.state}, se te redirigirá a la visual correpondiente`,
      hideBtnCancel: false,
      hideBtnConfirm: true,
    };
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.dialog.open(DialogComponent, dialogConfig);
  }

  /**
  * This method refresh the data of the load, the information of the map and process the manifest errors.
  * @param {Cargo} data (Cargo) is the current information of the load.
  */
  refreshCargoData(data: Cargo) {
    if (this.isNecessaryToRedirect(data)) return;
    this.cargo = data;
    this.mapOptions['cargo'] = this.cargo;
    this.mapOptions = { ...this.mapOptions };
    this.setInfoStartEnd();
    this.cargoOriginal = this.utils.clone(data);
    this.getDataRealTime().then(() => {
      this.processLocations();
    });
    this.getDetailDriver(this.cargo.driver);
    this.getDetailVehicle(this.cargo.licensePlate);
    if (
      this.cargo.manifestError &&
      this.cargo.manifestError.error
    ) {
      this.fixManifestError();
    }
    else if (this.cargo.manifestAlert) {
      this.showAlertManifest(this.cargo.manifestAlert);
    }
    if (this.cargo.state && this.cargo.state === CargoStateEnum.END_SERVICE) {
      if (
        this.permissionRole.hasPermission(
          this.permission.cargo.module,
          this.permission.cargo.rateCargo
        )
        && (!this.cargo.custumeRated || !(this.cargo.cargoScore && this.cargo.cargoScore.creatorScore))
      ) {
        this.openModalConfirmCargo();
      } else {
        this._snackBarService.openSnackBar("Servicio finalizado");
        sessionStorage.setItem("_lastList", "loadingRoutes");
        this.goToList("cargo/list/loadingRoutes");
      }
    }
  }

  /**
  * This method brings the information of the vehicle according to the license plate.
  * @param {string} licensePlate (string) license plate of the vehicle saved in the load.
  */
  private getDetailVehicle(licensePlate: string): void {
    this.vehiclesService.getVehicle(licensePlate).subscribe(
      (data: Vehicle) => {
        this.vehicleAssigned = data;

        if (this.isPrincipalCompany) {
          this.cargoOptionsService
            .getGPSProviders()
            .subscribe((gpsProviders: Array<{ monitor: boolean, name: string, companyId: string, id: string }>) => {
              const GPS = this.vehicleAssigned.gps && this.vehicleAssigned.gps.gpsType ? this.vehicleAssigned.gps.gpsType.toUpperCase() : null;
              const provider = gpsProviders.filter(gps => gps.name == GPS);
              if (provider.length > 0 && provider[0].monitor && !environment.ignoredGPSByMonitor.includes(provider[0].name)) {
                this.isMonitoreable = true;
                this.checkForMonitoring();
              } else {
                this.isMonitoreable = false;
              }
            });
        }
      },
      (error) => {
        this.vehicleAssigned = null;
      }
    );
  }

  /**
  * This method allows to see the transported value semaphore conventions.
  * @returns {boolean} (boolean) is the required permission to see this transported value semaphore.
  */
  public showTransportedValueSemaphore(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.transportedValueSemaphore
    );
  }
  /**
* This method brings the information of the driver according to his document.
* @param {string} idDriver (string) document of the driver.
*/
  getDetailDriver(idDriver: string) {
    if (
      !this.utils.isDefined(this.driverAssigned) ||
      !this.utils.isDefined(this.driverAssigned.profilePicture)
    ) {
      this.accountService.validateEntity(1, idDriver).subscribe(
        (data: Driver) => {
          this.driverAssigned = data;
          if (this.utils.isDefined(this.driverAssigned) && this.utils.isDefined(this.driverAssigned.profilePicture) && this.utils.isDefined(this.driverAssigned.urlProfilePicture)) {
            let storage = AuthService.fStorage;
            let pathReference = storage.ref(this.driverAssigned.profilePicture);
            pathReference.getDownloadURL().then(
              (data) => {
                this.driverAssigned.urlProfilePicture = data;
              },
              (error) => {
                this.driverAssigned.urlProfilePicture = "";
              }
            );
          }
        },
        (error) => {
          this.driverAssigned = {
            document: "",
          };
        }
      );
    }
  }
  /**
* This methods process the options of the map in this case allows to show the google route.
*/
  processLocations() {
    if (!this.hasDifferentCities() || !this.cargo.itineraryId || !this.cargo.routePlanId) {
      this.showRouteGoogle = true;
      this.showRoutePlan = false;
      this.routePlanItinerary = null;
      this.mapOptions = {
        ...this.mapOptions,
        routePlanItinerary: this.routePlanItinerary,
        showRouteGoogle: this.showRouteGoogle,
        showRoutePlan: this.showRoutePlan
      };
      this.refreshRoute('polyline');
      return;
    }

    this.getItinerary(this.cargo.itineraryId).then((itinerary) => {
      if (itinerary && !itinerary.active)
        this.snackBarService.openSnackBar("Este servicio está usando un itinerario deshabilitado", undefined, 'error');
      this.routePlanItinerary = itinerary;
      this.showRoutePlan = !!itinerary;
      this.showRouteGoogle = !itinerary;
      this.refreshRoute('polyline');
    });
  }

  private async getItinerary(id: string): Promise<RouteItinerary> {
    try {
      const itinerary = await this.planningRouteService.getItinerary(id).toPromise();
      return itinerary && itinerary.id ? itinerary : null;
    } catch (e) { return null; }
  }

  /**
 * This method contains all the logic related with the automatic fix of the most common manifest errors.
 */

  fixManifestError() {
    if (this.fixer_attempts++ > 2)
      return;

    if (this.cargo.manifestError.error === "Error no controlado RNDC, probar en unos minutos") {
      this.vehiclesService.getVehicle(this.cargo.licensePlate).subscribe(
        (vehicle: Vehicle) => {
          if (vehicle && vehicle.id && vehicle.driver) {
            this.rndcErrorSolver.noControlledError(this.cargo.driver, this.cargo, vehicle).then(() => {
              this.getDetailDriver(this.cargo.driver);
              this.rebuildManifest();
            }).catch((reason) => {
              if (reason) this.showErrorManifest(this.cargo.manifestError.error);
            });
          } else this.showErrorManifest(this.cargo.manifestError.error);
        }, (error) => {
          console.error(error);
          this.showErrorManifest(this.cargo.manifestError.error);
        }
      );
      return;
    }
    const errorCode = this.rndcErrorSolver.getErrorCode(this.cargo.manifestError.error).toUpperCase();
    if (!errorCode) {
      this.showErrorManifest(this.cargo.manifestError.error);
      return;
    }
    switch (errorCode) {
      case "MAN220":
        this.vehiclesService.getVehicle(this.cargo.licensePlate).subscribe(
          (vehicle: Vehicle) => {
            this.rndcErrorSolver.MAN220(vehicle, this.cargo).then(
              () => {
                this.getDetailVehicle(vehicle.id);
                this.rebuildManifest();
              }
            ).catch((reason) => {
              if (reason) this.showErrorManifest(this.cargo.manifestError.error);
            });
          }, (error) => {
            console.error(error);
            this.showErrorManifest(this.cargo.manifestError.error);
          }
        );
        break;
      case "MAN245":
        this.vehiclesService.getVehicle(this.cargo.licensePlate).subscribe(
          (vehicle) => {
            this.rndcErrorSolver.MAN245(this.cargo.driver, this.cargo, vehicle).then(() => {
              this.getDetailDriver(this.cargo.driver);
              this.rebuildManifest();
            }).catch((reason) => {
              if (reason) this.showErrorManifest(this.cargo.manifestError.error);
            });
          }, (error) => {
            console.error(error);
            this.showErrorManifest(this.cargo.manifestError.error);
          }
        );
        break;
      case "RTU140":
        if (this.cargo && this.cargo.licensePlate) {
          this.vehiclesService.getVehicle(this.cargo.licensePlate).subscribe(
            (vehicle: any) => {
              this.rndcErrorSolver.RTU140(this.cargo, vehicle).then(() => {
                this.getDetailVehicle(vehicle.id);
                this.rebuildManifest();
              }).catch((reason) => {
                if (reason) this.showErrorManifest(this.cargo.manifestError.error);
              })
            },
            (error) => {
              console.error(error);
              this.showErrorManifest(this.cargo.manifestError.error);
            }
          );
        } else {
          this.showErrorManifest(this.cargo.manifestError.error);
        }
        break;
      case "VEH280":
        this.vehiclesService.getVehicle(this.cargo.licensePlate).subscribe(
          (vehicle) => {
            this.rndcErrorSolver.VEH280(vehicle, this.cargo).then(
              (vehicle: Vehicle) => {
                this.getDetailVehicle(vehicle.id)
                this.rebuildManifest();
              }
            ).catch((reason) => {
              if (reason) this.showErrorManifest(this.cargo.manifestError.error);
            });
          }, (error) => {
            console.error(error);
            this.showErrorManifest(this.cargo.manifestError.error);
          }
        );
        break;

      case "MAN130":
        this.fixOwnerMinistry();
        break;

      case "MAN240":
        this.fixUserMinistry();
        break;

      case "MAN140":
        this.fixVehicleMinistry();
        break;

      case "MAN200":
        this.fixTrailerVehicleMinistry();
        break;
      case "RTU287":
      case "MAN287":
        this.fixLowAgreedValue();
        break;
      default:
        this.showErrorManifest(this.cargo.manifestError.error);
        break;
    }
  }

  /**
 * Method related with one of the automatic solutions when shows the following manifest error: MAN287.
 * @param {string} action (string - optional) parameter related to the specific action of "confirmDelete" load.
 */

  fixLowAgreedValue(action?: string) {
    const dialogConfig = new MatDialogConfig();
    const title = action && action === 'confirmDelete' ? '¿Estás seguro de que deseas eliminar este servicio?' : `Ocurrió un error al generar el manifiesto de la carga${this.cargo && this.cargo.consecutive ? `: ${this.cargo.consecutive}` : ''}`;
    const buttonIconState: boolean = !(action && (action === 'confirmDelete'));
    dialogConfig.data = {
      title: title,
      description: `${action && action === 'confirmDelete' ? '' : 'El valor pactado del flete es muy bajo, por favor elimine el servicio actual y cree un nuevo servicio con un valor mayor.'}`,
      hideBtnConfirm: buttonIconState,
      labelButton2: `${action && action === 'confirmDelete' ? '' : 'Eliminar servicio'}`,
      iconError: buttonIconState,
      snackbarMessage: `${action && action === 'confirmDelete' ? `Luego de dar click a confirmar seras redirigido a la creacion del servicio para crear un nuevo servicio correctamente. Recuerda que el valor debe ser mayor al previamente asignado: ${this.cargo && this.cargo.shippingCost && (this.cargo.shippingCost.totalCost || this.cargo.shippingCost.totalCost === 0) ? this.cargo.shippingCost.totalCost : 0}COP` : ''}`
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        if (action && action === 'confirmDelete') this.cargoOptionsComponent.confirmRemoveCargo('lowAgreedValue');
        else this.fixLowAgreedValue(`${!action ? 'confirmDelete' : ''}`);
      } else this.snackBarService.openSnackBar(FormMessages.RECOMMENDED_DELETE_LOAD, 'Eliminar servicio', 'alert', 6000, () => {
        this.fixLowAgreedValue('confirmDelete');
      });
    });
  }
  /**
 * Method related with one of the automatic solutions when shows the following manifest error: MAN130.
 */
  fixOwnerMinistry() {
    this.spinner.show();
    this._trackingService
      .fixOwnerMinistry(this.cargo.id)
      .toPromise()
      .then(() => {
        this._snackBarService.openSnackBar("Se aplicó una solución automática para generar el Manifiesto, por favor verifique nuevamente");
        this.rebuildManifest();
      })
      .catch(() => {
        this.showErrorManifest(this.cargo.manifestError.error);
      })
      .finally(() => this.spinner.hide());
  }

  /**
 * Method related with one of the automatic solutions when shows the following manifest error: MAN240.
 */
  fixUserMinistry() {
    this.spinner.show();
    this._trackingService
      .fixUserMinistry(this.cargo.id)
      .toPromise()
      .then(() => {
        this._snackBarService.openSnackBar("Se aplicó una solución automática para generar el Manifiesto, por favor verifique nuevamente");
        this.rebuildManifest();
      })
      .catch(() => {
        this.showErrorManifest(this.cargo.manifestError.error);
      })
      .finally(() => this.spinner.hide());
  }

  /**
 * Method related with one of the automatic solutions when shows the following manifest error: MAN140.
 */

  fixVehicleMinistry() {
    this.spinner.show();
    this._trackingService
      .fixVehicleMinistry(this.cargo.id)
      .toPromise()
      .then(() => {
        this._snackBarService.openSnackBar("Se aplicó una solución automática para generar el Manifiesto, por favor verifique nuevamente");
        this.rebuildManifest();
      })
      .catch(() => {
        this.showErrorManifest(this.cargo.manifestError.error);
      })
      .finally(() => this.spinner.hide());
  }

  /**
   * Method related with one of the automatic solutions when shows the following manifest error: MAN200.
   */
  fixTrailerVehicleMinistry() {
    this.spinner.show();
    this._trackingService
      .fixTrailerVehicleMinistry(this.cargo.id)
      .toPromise()
      .then(() => {
        this._snackBarService.openSnackBar("Se aplicó una solución automática para generar el Manifiesto, por favor verifique nuevamente");
        this.rebuildManifest();
      })
      .catch(() => {
        this.showErrorManifest(this.cargo.manifestError.error);
      })
      .finally(() => this.spinner.hide());
  }

  /**
  * This method allows to open a dialog with the detail of the load.
  */
  openDetailCargo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.utils.clone(this.cargo),
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    this.dialog.open(CargoDetailComponent, dialogConfig);
  }

  /**
  * This method close the positive or negative calification of the load dialog and also open the rate dialog.
  */
  openModalConfirmCargo(type?: string) {
    if (this.instanceModalConfirmLoadPositive) {
      this.instanceModalConfirmLoadPositive.close();
    }
    if (this.instanceModalConfirmLoadNegative) {
      this.instanceModalConfirmLoadNegative.close();
    }
    this.openConfirmReceiveCargo(type);
  }

  /**
  * This method invoques 3 methods responsible for brings all the information related with the anomalies and the tracking.
  */
  private async getDataRealTime() {
    await this.getOldAnomalies();
    await this.getDataAnomalies();
    await this.getDataTracking();
  }

  /**
 * This method brings the anomalies of the load saved in an old collection of firebase realtime structure.
 */
  async getOldAnomalies() {
    try {
      const data = await this.firebaseDatabase.getOldDataRealTime(this.cargo.id);
      this.oldListLocations = data && data.listLocations ? data.listLocations : [];
      this.oldListAnomalies = data && data.listAnomalies ? data.listAnomalies : [];
    } catch (error) { this.oldListLocations = []; this.oldListAnomalies = []; }
  }

  /**
 * This method brings the tracking of the load storage in a realtime collection of firebase according to the id of the load.
 */
  private async getDataTracking(): Promise<void> {
    this.mapOptions = { ...this.mapOptions };
    this.refFirebaseTracking = this.firebaseDatabase.getRefDatabase(`cargo/${this.cargo.id}/tracking`);
    try {
      const data = await this.firebaseDatabase.lisenerDataReference(this.refFirebaseTracking);
      const dataTracking = await this.firebaseDatabase.getDataTrackingSnapshot(data);
      const polylineToSend = dataTracking.listLocations.map(trackingLocation => {
        const index = this.listReportedAnomalies.findIndex(anomaly => {
          return !!(anomaly.lat === trackingLocation.lat && anomaly.lng === trackingLocation.lng &&
            anomaly.data && anomaly.data.fingerprint && trackingLocation.fingerprint
            && anomaly.data.fingerprint.date === trackingLocation.fingerprint.date)
        });
        if (index !== -1) {
          if (this.listReportedAnomalies[index].data.name) return { ...trackingLocation, name: this.listReportedAnomalies[index].data.name };
          if (this.listReportedAnomalies[index].data.anomaly) return { ...trackingLocation, anomaly: this.listReportedAnomalies[index].data.anomaly };
        }
        return trackingLocation;
      }).filter(location => location.lat && location.lng);
      this.polyline = polylineToSend;
      this.originRoute['key'] = 'originPolyline';
      this.originRoute['showMarker'] = true;
      this.polyline.unshift(this.originRoute);
      this.showLastPointLocation();
      if (this.visibleAnomalies)
        this.showAnomalies(this.visibleAnomalies);
      if (!this.cargo.itineraryId && !this.cargo.routePlanId && !this.hasDifferentCities())
        this.refreshRoute('polyline');
    } catch (error) { console.error(error); }
  }

  private hasDifferentCities(): boolean {
    try {
      const origin = this.cargo.cargoFeature.uploadDownload.origin.municipalityCode;
      const destinations = this.cargo.cargoFeature.uploadDownload.destination;
      return destinations.some(destination => destination.municipalityCode !== origin);
    } catch (error) {
      return false;
    }
  }
  /**
  * This method set all the properties necessaries to show the points of a route such as zoom, icon, infowindow etc.
  * @param {boolean} show (boolean) Indicate if the points are visibles or not.
  */

  showLastPointLocation() {
    if (this.polyline && this.polyline.length) {
      const lastPointLocation = this.polyline[this.polyline.length - 1];
      if (!lastPointLocation.hasOwnProperty('name') && !lastPointLocation.hasOwnProperty('anomaly')) {
        lastPointLocation['name'] = 'Última posición';
      }

      this.setMarkerProperties(lastPointLocation, true);
      lastPointLocation['showMarker'] = true;
      lastPointLocation['setCenter'] = true;
      lastPointLocation['zoom'] = 16;
    }
  }
  /**
  * This method set all the properties necessaries to show the anomalies.
  * @param {boolean} show (boolean) Indicate if the points are visibles or not.
  */
  showAnomalies(show: boolean) {
    if (this.polyline && this.polyline.length) {
      const polylineCopy = [...this.polyline];
      polylineCopy.pop();
      polylineCopy.forEach(async (polyline, index) => {
        if (polyline) {
          if ((polyline.hasOwnProperty('name') && polyline['name'] !== 'Reiniciando ruta') ||
            (polyline.hasOwnProperty('anomaly') && polyline['anomaly'] !== 'Reiniciando ruta')) {
            this.setMarkerProperties(polyline, false);
            polyline['showMarker'] = index === 0 ? true : show;
          } else polyline['showMarker'] = index === 0;
        }
      })
      this.refreshRoute('route');
    }
  }

  /**
  * This method process all the map options setted in other methods and refresh the map according to this options.
  * @param {string} key (string) is the unique identifier of the element that wanted to modified in the map
  */
  refreshRoute(key: string) {
    const path = this.polyline;
    this.tempListLocations[this.cargo.id] = {
      path,
      showPolyline: true,
      showRouteGoogle: this.showRouteGoogle,
      showRoutePlan: this.showRoutePlan,
      routePlanItinerary: this.routePlanItinerary
    };
    this.listLocations = this.tempListLocations;
    this.mapOptions[key] = this.listLocations;
    this.mapOptions = { ...this.mapOptions };
  }

  /**
  * Description: related with the google maps configuration; Creates an information window with relevant data such as: novedad, fecha, observacion, responsable y origen.
  * @param {MapPoint} point (required) is the format of a point in a map polyline configuration.
  * @returns {string} (string) returns the content of the information window.
  */
  private createContentInfoWindow(point: MapPoint): string {
    let content = '';
    const anomalyName = point && point.name ? point.name : point.anomaly;
    content =
      `
        <b>Novedad:</b> ${anomalyName}<br/>
        <b>Fecha:</b> ${point.fingerprint && point.fingerprint.date ? point.fingerprint.date.slice(0, 16) : '-'}<br/>
        <b>Observación:</b> ${point.observation ? point.observation : '-'}<br/>
        <b>Responsable:</b> (${point.fingerprint && point.fingerprint.userId ? point.fingerprint.userId : '-'}) ${point.fingerprint && point.fingerprint.userName ? point.fingerprint.userName : '-'}<br/>
        <b>Origen:</b> ${point.fromWeb ? 'Web' : 'Conductor'}<br/>
   `;

    return content;
  }
  /**
  * This method set the icon resource and the information of the infowindow displayed in the detail of each icon in the map.
  * @param {MapPoint} point (MapPoint) point of the map that wanted to modified.
  * @param {boolean} lastItem (boolean) is validation to know if is the last item to avoid the logic inside de condition.
  */
  async setMarkerProperties(point: MapPoint, lastItem: boolean) {
    if (!lastItem) {
      let iconName = point.hasOwnProperty('name') && point.name ? point['name'] : point.hasOwnProperty('anomaly') ? point['anomaly'] : null;
      point['icon'] = iconName ? this.standardMapService.getIcon(iconName) : this.global.pathMarkerVehicleNoLastLocations;
      point['icon'] = point['icon'] ? point['icon'] : this.global.pathMarkerVehicleNoLastLocations;
    }
    point['contentInfoWindow'] = await this.createContentInfoWindow(point);
  }
  /**
  * This method set the information of the start point (origin) and the end point (destination) of the load that wanted to show in the map.
  */
  setInfoStartEnd() {
    this.polyline = [];
    if (!this.cargo) return '';
    const addresses = [];
    this.cargo.cargoFeature.uploadDownload.origin.addresses.forEach((address) => {
      addresses.push(address);
    });
    for (const destination of this.cargo.cargoFeature.uploadDownload.destination) {
      destination.addresses.forEach((address) => {
        addresses.push(address);
      });
    }
    if (addresses.length > 1) {
      let start = addresses[0];
      let end = addresses[addresses.length - 1];
      if (start) {
        this.originRoute = {
          key: 'originMarker',
          icon: this.global.pathMarkerOrigin,
          lat: start.location && start.location.lat ? start.location.lat : null,
          lng: start.location && start.location.lng ? start.location.lng : null,
          contentInfoWindow: `
          <b>Dirección:</b> ${start.address ? start.address : '-'}<br/>
          <b>Inicio:</b> ${start.durationTime && start.durationTime.startDate ? start.durationTime.startDate.slice(0, 16) : '-'}
          `,
          showMarker: true
        };
        this.polyline.push(this.originRoute);
      }
      if (end) {
        this.polyline.push({
          key: 'destinationMarker',
          icon: this.global.pathMarkerDestination,
          lat: end.location && end.location.lat ? end.location.lat : null,
          lng: end.location && end.location.lng ? end.location.lng : null,
          contentInfoWindow: `
          <b>Dirección:</b> ${end.address ? end.address : '-'}<br/>
          <b>fin:</b> ${end.durationTime && end.durationTime.endDate ? end.durationTime.endDate.slice(0, 16) : '-'}
          `,
          showMarker: true
        });
      }
    }
    this.refreshRoute('route');
  }
  /**
 * This method brings the anomalies of the load storage in a realtime collection of firebase according to the id of the load.
 */
  private async getDataAnomalies(): Promise<void> {
    try {
      this.refFirebaseAnomalies = this.firebaseDatabase.getRefDatabase(`cargo/${this.cargo.id}/anomalies`);
      const data = await this.firebaseDatabase.lisenerDataReference(this.refFirebaseAnomalies);
      const dataTracking = await this.firebaseDatabase.getDataTrackingSnapshot(data);
      this.listReportedAnomalies = [...this.oldListAnomalies, ...dataTracking.listAnomalies];
    } catch (error) { this.listReportedAnomalies = [...this.oldListAnomalies]; }
  }

  /**
  * This method open a dialog that allows the user to rate the load and redirect to the detail of the load or to the list of loading loads.
  */
  openConfirmReceiveCargo(type?: string) {
    if (!this.dialog.getDialogById('cargoRatingComponent')) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'cargoRatingComponent';
      dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.autoFocus = false;
      dialogConfig.data = {
        cargo: this.cargo,
        redirect: true,
      };
      dialogConfig.disableClose = true;
      const dialog = this.dialog.open(CargoRatingComponent, dialogConfig);
      dialog.afterClosed().subscribe((response) => {
        if (type && type === 'fromAdvanceAlert') this.loadFinished.emit(this.cargo.licensePlate);
        else if (this.pendingInitialFulfill) {
          sessionStorage.setItem('_lastList', 'loadsFinished');
          this.goToList(`cargo/detail/${this.cargo.consecutive}`);
        } else {
          sessionStorage.setItem('_lastList', 'loadingRoutes');
          this.goToList('cargo/list/loadingRoutes');
        }
      });
    }
  }

  /**
  * This method open a dialog that shows a manifest SICETAC alert.
  * @param {string} alertMessage (string) is the alert message that would be show.
  */
  showAlertManifest(alertMessage: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `Se generó una alerta al generar el manifiesto de la carga: ${this.cargo.consecutive}`,
      iconAlert: true,
      description: `ALERTA: ${alertMessage}`,
      hideBtnCancel: false,
      hideBtnConfirm: true,
    };
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.dialog.open(DialogComponent, dialogConfig);
  }
  /**
* This method open a dialog that shows manifests errors.
* @param {string} messageError (string) is the manifest message error that needs to display.
*/
  showErrorManifest(messageError: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `Ocurrió un error al generar el manifiesto de la carga: ${this.cargo.consecutive}`,
      iconError: true,
      description: messageError,
      labelButton1: 'Cancelar',
      labelButton2: 'Volver a intentar',
      hideBtnCancel: true,
      hideBtnConfirm: true,
    };
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state && result.refuse === "Volver a intentar") {
        this.rebuildManifest();
      }
    });
  }

  /**
  * This method open a dialog for support to the user.
  */
  openModalContact() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Cómo podemos ayudarte?`,
      textArea: true,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state && result.message) {
        this.sendContactInfo(result.message);
      }
    });
  }

  // showErrorMinistry() {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.data = {
  //     title: 'Ocurrió un error al generar la remesa',
  //     iconError: true,
  //     description: this.cargo.ministryError.error,
  //     hideBtnConfirm: true
  //   };
  //   const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (!this.disabledRebuildMinistry) {
  //       this.rebuildMinistry();
  //     }
  //   });
  // }
  /**
  * This method allows to rebuild the manifest. this occurs when the original manifest have errors, then solved this errors and wanted to rebuild to generate the manifest correctly.
  */
  rebuildManifest() {
    this.spinner.show();
    this._trackingService.rebuildManifest(this.cargoOriginal.id).subscribe(
      (success: Cargo) => {
        this.spinner.hide();
        if (success.manifestError && success.manifestError.error) {
          this.snackBarService.openSnackBar(
            success.manifestError.error,
            undefined,
            "error"
          );
        } else {
          this.snackBarService.openSnackBar(
            "Manifiesto generado correctamente"
          );
        }
        this.getCargoDetail(this.cargo.consecutive.toString());
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          `Ocurrió un error al generar el manifiesto de la carga (${this.cargo.consecutive})`,
          undefined,
          "error"
        );
      }
    );
  }

  /**
  * This method executes the open action of a modal to contact support.
  * @param {string} $event (string) event related with the action of open the contact window.
  */
  onExecuteParentMethod($event: string) {
    if ($event) this.openModalContact();
  }

  /**
  * This method allows to execute an specific method inside the class pass it as parameter, also is posible to pass parameters of this method that wants to execute.
  * @param {EmitToParent} $event (EmitToParent) event related with the parameters that wanted to include in the internal method called.
  */
  public lisenerStatesHistory($event: EmitToParent): void {
    if (this[$event.name]) {
      this[$event.name]($event.data);
    }
  }
  /**
* This method allows to toggle between show or hide geofences and anomalies in the map.
* @param {MatButtonToggleChange} $event (MatButtonToggleChange) event that listen the change toggle action.
* @param {string} type (string) the type of the toggle could be geofence or anomaly
*/
  onToggleChange($event: Event, type: string) {
    if (type && type === 'showMarkers') {
      this.visibleAnomalies = ($event.target as HTMLInputElement).checked;
      this.showAnomalies(this.visibleAnomalies);
    }
    if (type && type === 'showGeofences') {
      this.mapOptions['showGeofences'] = ($event.target as HTMLInputElement).checked
      this.mapOptions = { ...this.mapOptions }
    }
  }
  /**
  * This method allows to start the real time of the trip.
  * @param {string} state (string) is the state that needs to setted in this case start trip.
  */
  async startRealRoute(state: string) {
    if ((!this.cargo.routePlanId || !this.cargo.itineraryId) &&
      this.permissionRole.hasPermission(this.permission.routes.module, this.permission.routes.accessCargoRoutePlanning))
      await this.showPlanningRouteAlert();
    const trackingObserver = {
      next: (success: Cargo) => {
        this.spinner.hide();
        if (success) {
          this.getCargoDetail(success.consecutive.toString());
          this.snackBarService.openSnackBar(FormMessages.START_TRIP_REAL, undefined, 'success');
        }
        else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error')
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    };
    this.spinner.show();
    let idObject = {
      "idCargo": this.cargo && this.cargo.id ? this.cargo.id : null
    }
    this.cargoEvidenceService.setStateAddressTracking(idObject, state).subscribe(trackingObserver);
  }

  async showPlanningRouteAlert() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `El servicio no cuenta con un plan de ruta asociado`,
      iconAlert: true,
      description: `Antes de iniciar, es recomendable contar con un plan de ruta. ¿Cómo deseas proceder? `,
      labelButton1: 'Continuar',
      hideBtnCancel: true,
      hideBtnConfirm: true,
    };
    if (this.permissionRole.hasPermission(this.permission.routes.module, this.permission.routes.editCargoRoutePlanning))
      dialogConfig.data['labelButton2'] = "Asignar";
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialog = this.dialog.open(DialogComponent, dialogConfig);
    const result = await dialog.afterClosed().toPromise();
    if (!result || !result.state || !result.refuse || result.refuse !== "Asignar")
      return;
    await this.checkPlanningRoute();
  }

  private async checkPlanningRoute() {
    this.spinner.show();
    let route: PlanningRoute;
    try { route = await this.planningRouteService.findRouteByCargo(this.cargo) } catch (e) { }
    if (!route || !route.id) {
      try {
        const body = await this.planningRouteService.createRouteItineraryFromCargo(this.cargo);
        if (typeof body === 'string') {
          this.snackBarService.openSnackBar(body, undefined, 'error');
          this.spinner.hide();
          return;
        }
        await this.planningRouteService.createRouteItinerary(body).toPromise();
        route = await this.planningRouteService.findRouteByCargo(this.cargo);
      } catch (e) { }
    }
    if (route && route.id) {
      let response: Cargo[];
      try {
        const body = {
          cargoId: this.cargo.id,
          routePlanId: route.id,
          itineraryId: route.itineraries[0].id
        };
        response = await this.planningRouteService.setCargoRouteAndItinerary([body]).toPromise();
      } catch (e) { };
      if (response && response.length && response[0].routePlanId && response[0].itineraryId)
        this.snackBarService.openSnackBar("Se ha asignado un plan de ruta al servicio");
      else
        this.snackBarService.openSnackBar("Ocurrió un error al asignar el plan de ruta", undefined, 'error');
    }
    this.spinner.hide();
  }

  /**
 * This method transforms the format of a date that pass it through a parameter.
 * @param {string | Date} date (string or Date) is the date that wants to format.
 * @param {string} type (type - optional) type allows to know if the date that arrives needs only the date not the time.
 * @returns {string | Date} (string | Date) returns a date in string format or in date format.
 */
  formatDate(date: string | Date, type?: string): string | Date {
    if (date) {
      if (typeof date === 'string' && type === 'only-date') {
        var partsDate = date.split("/");
        if (partsDate.length && partsDate.length === 3) {
          var day = parseInt(partsDate[0]);
          var month = parseInt(partsDate[1]) - 1;
          var year = parseInt(partsDate[2]);
          var dateFormatted = new Date(year, month, day);
          return this.datePipe.transform(dateFormatted, "d MMMM yyyy");
        }
      } else return this.datePipe.transform(date, "d MMMM yyyy HH:mm a");
    }
    return date;
  }

  get showBellAlert() {
    if (
      this.cargo &&
      this.cargo.state === CargoStateEnum.START_SERVICE &&
      this.showTransportedValueSemaphore() &&
      !this.isCargoDefined && !this.entryLoad
    ) {
      this.showConfirmBellAlerts();
      this.isCargoDefined = true;
    }
    return (
      this.cargo &&
      this.cargo.state === CargoStateEnum.START_SERVICE &&
      this.showTransportedValueSemaphore() &&
      this.showBells
    );
  }

  get PDFTypes() {
    return PDFTypes;
  }

  get hasManifestPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.documentManifest);
  }
  get hasConsignmentPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.documentConsignment);
  }
  get hasManifestUrbanPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.documentManifestUrban);
  }
  get hasConsignmentUrbanPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.documentConsignmentUrban);
  }

  public get permissionToStartService(): boolean {
    return (
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadEvidences
      ) ||
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadExtraDocumentsCargo
      )
    );
  }

  public get enableStateTrackingAddresses() {
    return this.cargo && this.cargo.cargoFeature && this.cargo.cargoFeature.uploadDownload && this.cargo.cargoFeature.uploadDownload.origin && this.cargo.cargoFeature.uploadDownload.origin.addresses && this.cargo.cargoFeature.uploadDownload.origin.addresses.length && this.cargo.cargoFeature.uploadDownload.origin.addresses.every(address => address.state && address.state === 'Pickup load');
  }
  /**
  * This method open a dialog that allows the user to modify the advance percentage of the load.
  */
  public openDialogNewAdvancePercentage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
      viewType: null,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(
      CargoAdvancePercentageComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result.cargo) {
        this.cargo.shippingCost = result.cargo.shippingCost;
        if (this.cargo.shippingCost.valueAdvance > 0) {
          this.dialogManager.openDialog({
            title:
              "El Anticipo se ha actualizado, debe esperar a que se realice el pago para poder finalizar el servicio",
            icon: true,
            hideBtnConfirm: true,
          }).then().catch(err => err);
        } else {
          this.confirmEndService();
        }
      }
    });
  }

  /**
  * Description: allows the user to confirm the end of the load.
  */
  private confirmEndService(type?: string) {
    const config = new MatDialogConfig();
    config.data = {
      title: `¿Desea finalizar el servicio ${this.cargo.consecutive}?`
    };
    config.maxHeight = ModalEnum.MEDIUM_HEIGHT;
    config.width = ModalEnum.SMALL_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    const _modal = this.dialog.open(DialogComponent, config);

    _modal.afterClosed().subscribe({
      next: (response) => {
        if (response) {
          if (response.state && response.refuse == 'true') {
            this.setTrackingActionEndService(type);
          }
        }
      },
      error: (_error) => { console.trace(_error) },
      complete: () => { this.spinner.hide() }
    });
  }

  /**
* Validates the conditions of the load to decide which flow continues next, the possibilites are: not allowed to finished the load because the user dont have the permission, the case in which the load have a pending advance or the confirmation of the end of the load window.
* @param {Event} _event (required) the event is directly related with the click button action of the user.
*/
  public endCargo(_event?: Event, load?: Cargo, type?: string) {
    if (load && !this.isNecessaryToRedirect(load)) this.cargo = load;
    if (_event) {
      _event.preventDefault();
      _event.stopPropagation();
    }

    const finishCargoPermission = this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.finishCargo
    );

    if (finishCargoPermission) {
      if (
        !this.cargo.shippingCost.advanceState
      ) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          iconError: true,
          title: `Anticipo sin pagar`,
          description: `El servicio ${this.cargo.consecutive} tiene un anticipo pendiente por pagar de ${this.currencyPipe.transform(
            this.cargo.shippingCost.valueAdvance,
            "COP",
            "code"
          )} ¿Deséa finalizarlo sin pagar el anticipo o deséa modificar el valor del anticipo?`,
          showYesBtn: true,
          showNoBtn: true,
          showCancelBtn: true,
          titleYes: "Modificar Anticipo",
          titleNo: "Finalizar con Anticipo en $0",
          titleCancel: "Cerrar",
        };
        dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
        dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
        dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
        dialogConfig.autoFocus = false;
        const dialogRef = this.dialog.open(ConfirmComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result) => {
          switch (result) {
            case true:
              this.openDialogNewAdvancePercentage();
              break;
            case false:
              this.confirmEndService(type);
              break;
          }
        });
      } else {
        this.confirmEndService(type);
      }
    } else {
      this.snackBarService.openSnackBar(
        "Debes contar con el permiso de Finalizar servicios",
        undefined,
        "error"
      );
    }
  }

  /**
  * This method allows the user to end the load.
  */

  private setTrackingActionEndService(type?: string): void {
    this.spinner.show();
    this.cargoEvidenceService.setStateAddressTracking({ idCargo: this.cargo.id }, 'End service').subscribe(
      (success: Cargo) => {
        if (this.utils.isEmpty(this.cargo) && !this.isNecessaryToRedirect(success))
          this.cargo = success;
        this.spinner.hide();
        this.openModalConfirmCargo(type);
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    );
  }

  public get canFinalizeCargo(): boolean {
    if (this.cargo) {
      const destinations = this.cargo.cargoFeature.uploadDownload.destination;
      const addresses = destinations[destinations.length - 1].addresses;
      return addresses[addresses.length - 1].state === 'Cargo unloaded';
    }
    return false;
  }

  get cantFinalizeCargoReason(): string {
    if (this.canFinalizeCargo) return '';
    let reason = '';
    if (this.cargo && this.cargo.cargoFeature && this.cargo.cargoFeature.uploadDownload) {
      const destinations = this.cargo.cargoFeature.uploadDownload.destination;
      const originAddresses = this.cargo.cargoFeature.uploadDownload.origin.addresses;
      for (const [index, address] of originAddresses.entries()) {
        if (address.state !== 'Pickup load') {
          reason = `No se ha completado el origen ${index + 1} (${address.address})`;
          break;
        }
      }
      if (reason) return reason;
      for (const [indexDestination, destination] of destinations.entries()) {
        for (const [indexAddress, address] of destination.addresses.entries()) {
          if (address.state !== 'Cargo unloaded') {
            reason = `No se ha completado el destino ${indexDestination + 1}, dirección ${indexAddress + 1} (${address.address})`;
            break;
          }
        }
        if (reason) break;
      }
      if (reason) return reason;
    }
    return 'No se han completado todos los destinos';
  }

  get hasPermissionToActivateMonitor() {
    return this.permissionRole.hasPermission(
      Permission.administration.module,
      Permission.administration.setupMonitorIntegration
    );
  }

  setUpMonitorToVehicle() {
    this.askForMonitorIfNecesary().subscribe(
      (result) => {
        if (result) {
          this.snackBarService.openSnackBar('Se ha agendado la activación del vehículo nuevamente.');
        }
      },
      (err) => {
        this.snackBarService.openSnackBar(err, 'x', 'error');
      }
    );
  }

  askForMonitorIfNecesary() {
    return new Observable<boolean>(
      observer => {
        const company = this.authService.getCompany();

        if (
          company &&
          company.gpsConfiguration &&
          company.gpsConfiguration.monitor &&
          company.gpsConfiguration.monitor.active
        ) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            title: `¿Deseas realizar la trazabilidad automática con GPS del vehículo ${this.cargo.licensePlate}?`,
            description: `Recuerde que este servicio tiene costo adicional.`,
            showYesBtn: true,
            showNoBtn: true,
            hideBtnCancel: true
          };
          dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
          dialogConfig.autoFocus = false;
          dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
          dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
          const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
          dialogRef.afterClosed().subscribe((result) => {
            if (result === undefined)
              return;
            const must_register_vehicle = result && result.state;
            if (must_register_vehicle) {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.data = { licensePlate: this.cargo.licensePlate, startDate: this.trackingState ? this.cargo.monitorHubEndDate : (new Date()).toISOString() };
              dialogConfig.width = ModalEnum.LARGE_WIDTH;
              dialogConfig.autoFocus = false;
              dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
              dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
              dialogConfig.disableClose = true;
              const dialogRef = this.dialog.open(RequestDatesToMonitorComponent, dialogConfig);
              dialogRef
                .afterClosed()
                .subscribe(
                  (result: { state: 'goBack' | 'confirm', dates: { start: string | null, end: string | null } }) => {
                    if (result.state === 'goBack') {
                      this.setUpMonitorToVehicle();
                      observer.complete();
                    } else {
                      this._cargoService
                        .registerVehicleInMonitor(this.cargo.id, result.dates.start, result.dates.end)
                        .subscribe({
                          next: (response) => {
                            this.snackBarService.openSnackBar("Vehículo registrado correctamente", "Aceptar", "success");
                            setTimeout(() => location.reload(), 3000);
                            observer.next(true);
                          },
                          error:
                            observer.error,
                          complete:
                            observer.complete
                        });
                    }
                  },
                  observer.error
                );
            } else {
              observer.next(false);
              observer.complete();
            }
          });
        } else {
          observer.error('La empresa no parece tener habilitada integración con múltiples GPS');
          observer.complete();
        }
      }
    );
  }
}

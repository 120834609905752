import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { VehiclesService } from '../list-vehicles.service';
import { Utils } from 'src/app/core/resources/utils';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-account-inscription-vehicle',
  templateUrl: './account-inscription-vehicle.component.html',
  styleUrls: ['./account-inscription-vehicle.component.scss']
})
export class AccountInscriptionVehicleComponent implements OnInit {
  form: FormArray = new FormArray([]);
  options: { [key: string]: { label: string, option: string }[] } = {};
  constructor(
    public dialogRef: MatDialogRef<AccountInscriptionVehicleComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { vehicles: Array<Vehicle> },
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private service: VehiclesService,
    private utils: Utils,
  ) { }

  ngOnInit() {
    if (!this.data || !this.data.vehicles || !this.data.vehicles.length) {
      this.snackBarService.openSnackBar('No se seleccionaron vehículos para inscripción de cuentas', undefined, 'alert');
      return this.dialogRef.close();
    }
    const accounts = [
      { value: 'bankAccountBalance', label: 'Cuenta de saldos', option: ['balance'] },
      { value: 'bankAccountAdvance', label: 'Cuenta de anticipos', option: ['advance'] }
    ];
    this.data.vehicles.forEach((vehicle: Vehicle) => {
      let vehicleGroup = new FormGroup({ licensePlate: new FormControl(vehicle.id), accountType: new FormControl([], Validators.required) });
      let options = [];
      accounts.forEach((account) => {
        if (vehicle[account.value] && vehicle[account.value].state &&
          vehicle[account.value].state.active && !vehicle[account.value].createdInBank)
          options.push(account);
      });
      if (options.length) {
        if (options.length === 2)
          options.push({ label: 'Cuentas de saldos y anticipos', option: ['balance', 'advance'] });
        this.options[vehicle.id] = options;
        vehicleGroup.get('accountType').setValue(options[options.length - 1].option);
      }
      this.form.push(vehicleGroup);
    });
  }

  get areVehiclesToInscribe() {
    return this.form.value && this.form.value.some(vehicle => vehicle && this.options[vehicle.licensePlate] && this.options[vehicle.licensePlate].length);
  }

  onSubmit() {
    const vehicles = this.form.value;
    const vehiclesToInscribe = vehicles.filter(vehicle => vehicle.accountType && vehicle.accountType.length);
    if (!vehiclesToInscribe.length)
      return this.snackBarService.openSnackBar('No hay vehículos con cuentas para inscribir', undefined, 'alert');
    const returnData = vehiclesToInscribe.map(vehicle => {
      return {
        licensePlate: vehicle.licensePlate,
        accountType: vehicle.accountType
      }
    });
    this.getFileForInscriptionAccount(returnData);
  }

  /**
  * @param {Array<{licensePlate: string, accountType: ('balance' | 'advance')[]}>} data is the list of vehicles with the bank account type
  * @description Gets the file for inscription account and downloads it
  */
  private getFileForInscriptionAccount(data?: { licensePlate: string, accountType: ('balance' | 'advance')[] }[]) {
    this.spinner.show();
    this.service.getFileForInscriptionAccount(data).subscribe(
      (success) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Las cuentas han sido inscritas con éxito');
        this.utils.downloadFile(success.body, 'inscripcion de cuentas-' + DateManager.dateToString(new Date(), 'YYYY-MM-DD'));
        this.dialogRef.close({ state: true });
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al enviar la informacion', undefined, 'error');
      }
    );
  }

}

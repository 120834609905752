import { Component, OnInit, HostListener, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from './manual-creation-cargo.service';
import { TypeTripComponent } from './components/type-trip/type-trip.component';
import { Router } from '@angular/router';
import { CargoResources } from './resources/cargo';
import { Utils } from 'src/app/core/resources/utils';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from "src/app/core/resources/permission";
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { map, timeout } from "rxjs/operators";
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
import { CargoManager } from 'src/app/core/managers/cargo.manager';

@Component({
  selector: 'app-manual-creation-cargo',
  templateUrl: './manual-creation-cargo.component.html',
  styleUrls: ['./manual-creation-cargo.component.scss'],
  providers: [AuthService, Global]
})
export class ManualCreationCargoComponent implements OnInit {

  permission = Permission;
  @ViewChild(TypeTripComponent, { static: false }) TypeTripComponent: TypeTripComponent;

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    public authService: AuthService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private router: Router,
    public utils: Utils,
    private cdRef: ChangeDetectorRef,
    private snackBarService: SnackBarService,
    public permissionRole: PermissionRole,
    private cargoManager: CargoManager
  ) {
    if (!this.utils.isDefined(this.manualCreationCargoService.cargoForm) || !this.manualCreationCargoService.cargoForm.value.idCompany.length) {
      this.router.navigate([this.manualCreationCargoService.steps.main.url]);
    }
  }

  ngOnInit() {

  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy(): void {
    this.cdRef.detach();
  }

  // @HostListener('window:beforeunload', ['$event'])
  // onWindowClose(event: any): void {
  //   event.preventDefault();
  //   event.returnValue = false;
  // }

  setRoute(typeStep: string, step: number) {
    this.manualCreationCargoService.getComponentActive().setId(step);
    this.manualCreationCargoService.getComponentActive().getStep().setId(1);
  }

  changeStep(stepCargo: any) {
    if (this.isValidStep(stepCargo.parentId, stepCargo.id)) {
      this.manualCreationCargoService.getComponentActive().setId(stepCargo.parentId);
      this.manualCreationCargoService.getComponentActive().getStep().setId(stepCargo.id);
      this.router.navigate([stepCargo.url]);
    } else {
      this.manualCreationCargoService.showMessageMissingFields();
    }
  }

  isValidStep(parentId: number, stepId: number): boolean {
    let state = false;
    if (parentId === 1 && stepId === 1) {
      state = this.manualCreationCargoService.isValidTripType(true) &&
        this.manualCreationCargoService.isValidUploadLocation(this.manualCreationCargoService.getCargoForm().get('ministry').value) &&
        this.manualCreationCargoService.isValidUploadAddressesLocation(this.manualCreationCargoService.getCargoForm().get('ministry').value) &&
        this.manualCreationCargoService.isValidDownloadLocation(this.manualCreationCargoService.getCargoForm().get('ministry').value) &&
        this.manualCreationCargoService.isValidCompany() &&
        this.manualCreationCargoService.isValidNumberDocumentSender() &&
        this.manualCreationCargoService.isValidDateLoad() &&
        this.manualCreationCargoService.isValidDatesDownload();
    }
    if (parentId === 1 && stepId === 2) {
      state = this.manualCreationCargoService.isValidTripType();
    }
    if (parentId === 1 && stepId === 3) {
      state = this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    if (parentId === 1 && stepId === 4) {
      state = this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    if (parentId === 1 && stepId === 5) {
      state = this.manualCreationCargoService.isValidContainerMeasure() &&
        this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    if (parentId === 1 && stepId === 6) {
      state = this.manualCreationCargoService.isValidServiceType() &&
        this.manualCreationCargoService.isValidContainerMeasure() &&
        this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    // 2
    if (parentId === 2 && stepId === 1) {
      state = this.manualCreationCargoService.isValidTripTypeDocuments() &&
        this.manualCreationCargoService.isValidServiceType() &&
        this.manualCreationCargoService.isValidContainerMeasure() &&
        this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    if (parentId === 2 && stepId === 2) {
      state = this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidTripTypeDocuments() &&
        this.manualCreationCargoService.isValidServiceType() &&
        this.manualCreationCargoService.isValidContainerMeasure() &&
        this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    if (parentId === 2 && stepId === 3) {
      state = this.manualCreationCargoService.isValidVehicleQuantity() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidTripTypeDocuments() &&
        this.manualCreationCargoService.isValidServiceType() &&
        this.manualCreationCargoService.isValidContainerMeasure() &&
        this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    if (parentId === 2 && (stepId === 5 || stepId === 6)) {
      state = this.manualCreationCargoService.isValidProductType() &&
        this.manualCreationCargoService.isValidVehicleQuantity() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidTripTypeDocuments() &&
        this.manualCreationCargoService.isValidServiceType() &&
        this.manualCreationCargoService.isValidContainerMeasure() &&
        this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    if (parentId === 2 && stepId === 6) {
      state = this.manualCreationCargoService.isValidContainerData() &&
        this.manualCreationCargoService.isValidVehicleQuantity() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidProductType() &&
        this.manualCreationCargoService.isValidTripTypeDocuments() &&
        this.manualCreationCargoService.isValidServiceType() &&
        this.manualCreationCargoService.isValidContainerMeasure() &&
        this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    // 3
    if (parentId === 3 && stepId === 1) {
      state = this.manualCreationCargoService.isValidDownloadAddressesCargoMeasure() &&
        this.manualCreationCargoService.isValidVehicleQuantity() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidProductType() &&
        this.manualCreationCargoService.isValidTripTypeDocuments() &&
        this.manualCreationCargoService.isValidServiceType() &&
        this.manualCreationCargoService.isValidContainerMeasure() &&
        this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    if (parentId === 3 && stepId === 2) {
      state = this.manualCreationCargoService.isValidUploadAddressesAdditionalData() &&
        this.manualCreationCargoService.isValidAllDateTimeLoad() &&
        this.manualCreationCargoService.isValidDownloadAddressesCargoMeasure() &&
        this.manualCreationCargoService.isValidVehicleQuantity() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidProductType() &&
        this.manualCreationCargoService.isValidTripTypeDocuments() &&
        this.manualCreationCargoService.isValidServiceType() &&
        this.manualCreationCargoService.isValidContainerMeasure() &&
        this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    if (parentId === 3 && stepId === 3) {
      state = this.manualCreationCargoService.isValidDownloadAddressesAdditionalData() &&
        this.manualCreationCargoService.isValidAllDateTimeDowndload() &&
        this.manualCreationCargoService.isValidUploadAddressesAdditionalData() &&
        this.manualCreationCargoService.isValidAllDateTimeLoad() &&
        this.manualCreationCargoService.isValidDownloadAddressesCargoMeasure() &&
        this.manualCreationCargoService.isValidVehicleQuantity() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidProductType() &&
        this.manualCreationCargoService.isValidTripTypeDocuments() &&
        this.manualCreationCargoService.isValidServiceType() &&
        this.manualCreationCargoService.isValidContainerMeasure() &&
        this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    if (parentId === 4 && stepId === 1) {
      state = this.manualCreationCargoService.isValidDownloadAddressesAdditionalData() &&
        this.manualCreationCargoService.isValidAllDateTimeDowndload() &&
        this.manualCreationCargoService.isValidUploadAddressesAdditionalData() &&
        this.manualCreationCargoService.isValidAllDateTimeLoad() &&
        this.manualCreationCargoService.isValidDownloadAddressesCargoMeasure() &&
        this.manualCreationCargoService.isValidVehicleQuantity() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidProductType() &&
        this.manualCreationCargoService.isValidTripTypeDocuments() &&
        this.manualCreationCargoService.isValidServiceType() &&
        this.manualCreationCargoService.isValidContainerMeasure() &&
        this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    if (parentId === 4 && stepId === 2) {
      state = this.manualCreationCargoService.isValidCargoValue() &&
        this.manualCreationCargoService.isValidDownloadAddressesAdditionalData() &&
        this.manualCreationCargoService.isValidAllDateTimeDowndload() &&
        this.manualCreationCargoService.isValidUploadAddressesAdditionalData() &&
        this.manualCreationCargoService.isValidAllDateTimeLoad() &&
        this.manualCreationCargoService.isValidDownloadAddressesCargoMeasure() &&
        this.manualCreationCargoService.isValidVehicleQuantity() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidProductType() &&
        this.manualCreationCargoService.isValidTripTypeDocuments() &&
        this.manualCreationCargoService.isValidServiceType() &&
        this.manualCreationCargoService.isValidContainerMeasure() &&
        this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    if (parentId === 4 && stepId === 3) {
      state = this.manualCreationCargoService.isValidMethodPayment() &&
        this.manualCreationCargoService.isValidCargoValue() &&
        this.manualCreationCargoService.isValidDownloadAddressesAdditionalData() &&
        this.manualCreationCargoService.isValidAllDateTimeDowndload() &&
        this.manualCreationCargoService.isValidUploadAddressesAdditionalData() &&
        this.manualCreationCargoService.isValidAllDateTimeLoad() &&
        this.manualCreationCargoService.isValidDownloadAddressesCargoMeasure() &&
        this.manualCreationCargoService.isValidVehicleQuantity() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidVehicleType() &&
        this.manualCreationCargoService.isValidProductType() &&
        this.manualCreationCargoService.isValidTripTypeDocuments() &&
        this.manualCreationCargoService.isValidServiceType() &&
        this.manualCreationCargoService.isValidContainerMeasure() &&
        this.manualCreationCargoService.isValidCargoType() &&
        this.manualCreationCargoService.isValidRegimeType() &&
        this.manualCreationCargoService.isValidTripType();
    }
    return state;
  }

  stepBack() {
    if (
      this.manualCreationCargoService.getComponentActive().getStep().getId() === 5 &&
      this.manualCreationCargoService.cargo.getCargoModel().getCargoType().getName() !== "Contenedor"
    ) {
      if (
        this.manualCreationCargoService.cargo.getCargoModel().getTripType().getName() === "Nacional" ||
        this.manualCreationCargoService.cargo.getCargoModel().getTripType().getName() === "Urbana" ||
        this.manualCreationCargoService.cargo.getCargoModel().getTripType().getName() === "Contenedor vacío"
      ) {
        this.manualCreationCargoService.getComponentActive().getStep().setId(1);
      } else {
        this.manualCreationCargoService.getComponentActive().getStep().setId(3);
      }
    } else {
      switch (this.manualCreationCargoService.getComponentActive().getId()) {
        case 1:
          if (this.manualCreationCargoService.getComponentActive().getStep().getId() > 1) {
            this.manualCreationCargoService.getComponentActive().getStep().setId(
              this.manualCreationCargoService.getComponentActive().getStep().getId() - 1
            );
          } else {
            if (this.manualCreationCargoService.cargo.getCargoModel().getTripType().getName() === "Exportación") {
              // this.TypeTripComponent.stepActive = 1;
            } else {
              this.manualCreationCargoService.getComponentActive().setItineraryComplete(false);
              this.manualCreationCargoService.getComponentActive().setId(1);
              this.manualCreationCargoService.getComponentActive().getStep().setId(2);
            }
          }
          break;
        case 2:
        case 3:
        case 4:
          if (this.manualCreationCargoService.getComponentActive().getStep().getId() > 1) {
            this.manualCreationCargoService.getComponentActive().getStep().setId(
              this.manualCreationCargoService.getComponentActive().getStep().getId() - 1
            );
          } else {
            this.manualCreationCargoService.getComponentActive().getStep().setId(3);
            this.manualCreationCargoService.getComponentActive().getStep().setId(
              this.manualCreationCargoService.getComponentActive().getStep().getId() - 1
            );
          }
          break;
      }
    }

  }

  editItinerary() {
    this.manualCreationCargoService.getComponentActive().setItineraryComplete(false);
    this.manualCreationCargoService.getComponentActive().setId(1);
    this.manualCreationCargoService.getComponentActive().getStep().setId(1);
    this.router.navigate([this.manualCreationCargoService.steps.main.url]);
  }

  async createCargo() {
    let path = '/cargo/list/scheduledLoads';
    const isValidDates = await this.manualCreationCargoService.isValidAllDateTimeDowndload();
    if (this.manualCreationCargoService.isValidAdditionalCosts() && isValidDates) {
      this.spinner.show();
      this.manualCreationCargoService.processCargo()
        .then((data: Cargo[]) => {
          this.belowSicetac(data, path);
          if (data.length === 1)
            this.manualCreationCargoService.applyTravelExpenses(data);
        })
        .catch((error) => {
          this.manualCreationCargoService.showResponseCreateCargo(
            'Ocurrió un error al crear el servicio',
            (Array.isArray(error) ? error : undefined)
          );
        })
        .finally(() => {
          this.spinner.hide();
        });
    }
  }

  public belowSicetac(cargo: Cargo[], path: string) {
    const formatter = new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    });
    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.approvedBelowSicetac)) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      dialogConfig.disableClose = true;
      if (cargo[0].belowSicetac && !cargo[0].freightValueApprovedBelowSicetac) {
        const min = formatter.format(cargo[0].minimumApprovedValueSicetac);
        dialogConfig.data = {
          title: '¿Desea aprobar el flete por debajo del SICETAC?',
          descriptionHTML: `El flete mínimo permitido por SICETAC es de <strong>${min}</strong>, si desea ajustarlo por favor realice una bonificación para ajustarse al valor mínimo permitido.`,
          btnDetailCargo: true,
          path: cargo[0].state === CargoStateEnum.REQUEST
            ? `service-request-form/menu-service/${cargo[0].consecutive}`
            : `cargo/detail/${cargo[0].consecutive}`,
          closeModal: true,
        };
        const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.state) {
            this.manualCreationCargoService.approvedBelowSiceTac(cargo[0].id).subscribe(
              () => {
                this.openConfirmBelowSicetac(path);
              },
              (error) => {
                this.snackBarService.openSnackBar("No se pudo aprobar", undefined, 'error');
              }
            );
            timeout(5000)
          }
        })
      }
    } else {
      if (cargo[0].belowSicetac && !cargo[0].freightValueApprovedBelowSicetac) {
        const min = formatter.format(cargo[0].minimumApprovedValueSicetac);
        this.snackBarService.openSnackBar(`El flete mínimo es ${min}, por favor realice una bonificación para ajustarse al valor mínimo permitido`, undefined, 'alert');
      }
    }
    this.cargoManager.modalCargoCreation(cargo, `Tu servicio ha sido creado exitosamente con los siguientes consecutivos`);
  }

  openConfirmBelowSicetac(path) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: `Aprobación exitosa`,
      icon: true,
      description: `Se ha aprobado el servicio por un flete que está por debajo del valor mínimo permitido por el SICETAC`,
      hideBtnCancel: false,
      hideBtnConfirm: true,
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate([path]);
    })
  }

  get hasAdditionalCostsPermission(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.additionalCosts
    )
  }
}

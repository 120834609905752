import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { LocationAddress } from 'src/app/core/interfaces/locationAddress';
import { ThirdParty } from 'src/app/core/interfaces/thirdParty';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Utils } from 'src/app/core/resources/utils';
import { ModelCargo } from 'src/app/modules/cargo/manual-creation-cargo/resources/modelCargo';
import { MenuServiceService } from 'src/app/modules/service-request-form/menu-service/menu-service.service';

export type ConsignmentAddressOption = Pick<AddressCargo, 'address' | 'location' | 'time'> & { index: number };
export interface ConsignmentCityOption {
  municipalityCode: string;
  countryCode: string;
  date: string;
  index: number;
  addresses: ConsignmentAddressOption[];
}

@Component({
  selector: 'app-consignment-card',
  templateUrl: './consignment-card.component.html',
  styleUrls: ['./consignment-card.component.scss'],
  providers: [ModelCargo]
})
export class ConsignmentCardComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() title: string;
  @Input() formValidate: string;
  @Input() senderCityOptions: { [key: string]: ConsignmentCityOption };
  @Input() recipientCityOptions: { [key: string]: ConsignmentCityOption };
  @Input() canDeleteConsignment: boolean = true;
  @Output() removeConsignmentForm = new EventEmitter<void>();

  permission = Permission;
  operationTypeControl = new FormControl('', Validators.required);
  cargoNatureControl = new FormControl('', Validators.required);
  packagingTypeControl = new FormControl('', Validators.required);
  merchandiseControl = new FormControl('', Validators.required);
  readonly consignmentTypeOptions: Array<{ code: string, description: string, tooltip: string }> = this.utils.clone(this.menuService.consignmentTypes);
  readonly cargoNatureTypeOptions: Array<{ code: string, description: string }> = this.utils.clone(this.menuService.cargoNatureTypes);
  readonly packagingTypeOptions: Array<{ code: string, description: string }> = this.utils.clone(this.modelCargo.packagingTypes).map(packagingType => ({ code: packagingType.code, description: packagingType.description }));
  readonly timePactOptions = this.utils.clone(this.menuService.timePactOptions);
  readonly timeList: Array<{ value: string, title: string }> = this.utils.getListTime();

  senderThirdParty = new FormControl('', Validators.required);
  senderCityControl = new FormControl('', Validators.required);
  senderAddressControl = new FormControl('', Validators.required);
  loadDateControl = new FormControl('', Validators.required);
  loadTimePactControl = new FormControl('', Validators.required);
  recipientThirdParty = new FormControl('', Validators.required);
  recipientCityControl = new FormControl('', Validators.required);
  recipientAddressControl = new FormControl('', Validators.required);
  unloadDateControl = new FormControl('', Validators.required);
  unloadTimePactControl = new FormControl('', Validators.required);

  constructor(
    public menuService: MenuServiceService,
    public utils: Utils,
    public modelCargo: ModelCargo,
    public permissionRole: PermissionRole
  ) { }

  ngOnInit() {
    this.setLocalValues();
    this.setSubscription();
  }

  private setLocalValues() {
    const operationType = this.utils.getNestedValue(this.form.value, 'operationType');
    const consignmentType = this.consignmentTypeOptions.find(option => operationType && option.code === operationType.code);
    if (consignmentType) this.operationTypeControl.setValue(consignmentType);
    const cargoNature = this.utils.getNestedValue(this.form.value, 'cargoNature');
    const valueCargoNature = this.cargoNatureTypeOptions.find(option => cargoNature && option.code === cargoNature.code);
    if (valueCargoNature) this.cargoNatureControl.setValue(valueCargoNature);
    const packagingType = this.utils.getNestedValue(this.form.value, 'packagingType');
    const valuePackagingType = this.packagingTypeOptions.find(option => packagingType && option.code === packagingType.code);
    if (valuePackagingType) this.packagingTypeControl.setValue(valuePackagingType);
    const senderCity = this.utils.getNestedValue(this.form.value, 'sender.city');
    const senderMunicipalityCode = this.utils.getNestedValue(this.form.value, 'sender.municipalityCode');
    const senderCityOption = this.senderCityOptions[senderCity];
    if (senderCityOption && senderMunicipalityCode && senderCityOption.municipalityCode === senderMunicipalityCode) {
      this.senderCityControl.setValue({ key: senderCity, value: senderCityOption });
      const senderAddress = this.utils.getNestedValue(this.form.value, 'sender.address');
      const senderAddressId = this.utils.getNestedValue(this.form.value, 'sender.addressId');
      const senderLocationLat = this.utils.getNestedValue(this.form.value, 'sender.location.lat');
      const senderLocationLng = this.utils.getNestedValue(this.form.value, 'sender.location.lng');
      const senderAddressOption = senderCityOption.addresses.find(address => {
        return address && address.address === senderAddress && address.index === senderAddressId && address.location &&
          address.location.lat === senderLocationLat && address.location.lng === senderLocationLng;
      });
      if (senderAddressOption) this.senderAddressControl.setValue(senderAddressOption);
    }
    const loadDate = this.utils.getNestedValue(this.form.value, 'load.date');
    this.loadDateControl.setValue(loadDate ? DateManager.stringToDate(loadDate, 'DD/MM/YYYY') : null);
    const loadTimePactHours = this.utils.getNestedValue(this.form.value, 'load.hours');
    const loadTimePactMinutes = this.utils.getNestedValue(this.form.value, 'load.minutes');
    this.loadTimePactControl.setValue(this.utils.isDefined(loadTimePactHours) && this.utils.isDefined(loadTimePactMinutes) ? { hours: loadTimePactHours, minutes: loadTimePactMinutes } : null);
    const recipientCity = this.utils.getNestedValue(this.form.value, 'recipient.city');
    const recipientMunicipalityCode = this.utils.getNestedValue(this.form.value, 'recipient.municipalityCode');
    const recipientCityId = this.utils.getNestedValue(this.form.value, 'recipient.cityId');
    const recipientCityOption = this.recipientCityOptions[recipientCity];
    if (recipientCityOption && recipientMunicipalityCode && recipientCityOption.municipalityCode === recipientMunicipalityCode
      && recipientCityId === recipientCityOption.index) {
      this.recipientCityControl.setValue({ key: recipientCity, value: recipientCityOption });
      const recipientAddress = this.utils.getNestedValue(this.form.value, 'recipient.address');
      const recipientAddressId = this.utils.getNestedValue(this.form.value, 'recipient.addressId');
      const recipientLocationLat = this.utils.getNestedValue(this.form.value, 'recipient.location.lat');
      const recipientLocationLng = this.utils.getNestedValue(this.form.value, 'recipient.location.lng');
      const recipientAddressOption = recipientCityOption.addresses.find(address => {
        return address && address.address === recipientAddress && address.index === recipientAddressId && address.location &&
          address.location.lat === recipientLocationLat && address.location.lng === recipientLocationLng;
      });
      if (recipientAddressOption) this.recipientAddressControl.setValue(recipientAddressOption);
    }
    const unloadDate = this.utils.getNestedValue(this.form.value, 'unload.date');
    this.unloadDateControl.setValue(unloadDate ? DateManager.stringToDate(unloadDate, 'DD/MM/YYYY') : null);
    const unloadTimePactHours = this.utils.getNestedValue(this.form.value, 'unload.hours');
    const unloadTimePactMinutes = this.utils.getNestedValue(this.form.value, 'unload.minutes');
    this.unloadTimePactControl.setValue(this.utils.isDefined(unloadTimePactHours) && this.utils.isDefined(unloadTimePactMinutes) ? { hours: unloadTimePactHours, minutes: unloadTimePactMinutes } : null);
  }

  private setSubscription() {
    this.merchandiseControl.valueChanges.subscribe(value => this.onChangeMerchandise(value));
    this.senderThirdParty.valueChanges.subscribe(value => this.onChangeThirdParty(value, 'sender'));
    this.recipientThirdParty.valueChanges.subscribe(value => this.onChangeThirdParty(value, 'recipient'));
    this.loadDateControl.valueChanges.subscribe(value => this.onChangeDate(value, 'load'));
    this.unloadDateControl.valueChanges.subscribe(value => this.onChangeDate(value, 'unload'));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formValidate']) {
      if (this.formValidate === 'touched') {
        this.operationTypeControl.markAsTouched();
        this.cargoNatureControl.markAsTouched();
        this.packagingTypeControl.markAsTouched();
        this.merchandiseControl.markAsTouched();
        this.senderThirdParty.markAsTouched();
        this.recipientThirdParty.markAsTouched();
        this.senderCityControl.markAsTouched();
        this.recipientCityControl.markAsTouched();
        this.senderAddressControl.markAsTouched();
        this.recipientAddressControl.markAsTouched();
        this.loadDateControl.markAsTouched();
        this.unloadDateControl.markAsTouched();
        this.loadTimePactControl.markAsTouched();
        this.unloadTimePactControl.markAsTouched();
        this.form.markAllAsTouched();
      }
      if (this.formValidate === 'disable') {
        this.operationTypeControl.disable();
        this.cargoNatureControl.disable();
        this.packagingTypeControl.disable();
        this.merchandiseControl.disable();
        this.senderThirdParty.disable();
        this.recipientThirdParty.disable();
        this.senderCityControl.disable();
        this.recipientCityControl.disable();
        this.senderAddressControl.disable();
        this.recipientAddressControl.disable();
        this.loadDateControl.disable();
        this.unloadDateControl.disable();
        this.loadTimePactControl.disable();
        this.unloadTimePactControl.disable();
        this.form.disable();
      }
      if (this.formValidate === 'enable') {
        this.operationTypeControl.enable();
        this.cargoNatureControl.enable();
        this.packagingTypeControl.enable();
        this.merchandiseControl.enable();
        this.senderThirdParty.enable();
        this.recipientThirdParty.enable();
        this.senderCityControl.enable();
        this.recipientCityControl.enable();
        this.senderAddressControl.enable();
        this.recipientAddressControl.enable();
        this.loadDateControl.enable();
        this.unloadDateControl.enable();
        this.loadTimePactControl.enable();
        this.unloadTimePactControl.enable();
        this.form.enable();
      }

    }
  }

  compareByCode(a: { code: string }, b: { code: string }) {
    return a && b && a.code === b.code;
  }

  compareTimePact(a: { hours: number, minutes: number }, b: { hours: number, minutes: number }) {
    return a && b && a.hours === b.hours && a.minutes === b.minutes;
  }

  compareByKey(a: { key: string }, b: { key: string }) {
    return a && b && a.key === b.key;
  }

  isDisabledCity(city: string, option: ConsignmentCityOption): string {
    if (!city || !option) return 'No se encontró la ciudad';
    if (option.countryCode === 'CO' && !option.municipalityCode) return 'Se requiere diligenciar la ciudad del servicio nuevamente';
    if (option.countryCode !== 'CO') return 'No se pueden generar remesas con ciudades de países diferentes a Colombia';
    if (!option.addresses || !option.addresses.length) return 'La ciudad no tiene direcciones asociadas';
    return '';
  }

  isDisabledAddress(address: string, option: { address: string; location: LocationAddress }): string {
    if (!address || !option || !option.address) return 'No se encontró la dirección';
    if (!option.location.lat || !option.location.lng) return 'Se requiere diligenciar la dirección nuevamente';
    return '';
  }

  onChangeOperationType(event: { code: string, description: string, tooltip: string }) {
    this.form.get('operationType').patchValue(event);
  }

  onChangeCargoNature(event: { code: string, description: string }) {
    this.form.get('cargoNature').patchValue(event);
  }

  onChangePackagingType(event: { code: string, description: string }) {
    this.form.get('packagingType').patchValue(event);
  }

  onChangeMerchandise(event: { id: string, description: string }) {
    this.form.get('merchandise.code').setValue(event && event.id ? event.id : '');
    this.form.get('merchandise.description').setValue(event && event.description ? event.description : '');
  }

  onChangeThirdParty(event: ThirdParty, type: 'sender' | 'recipient') {
    this.form.get(`${type}.documentType`).setValue(event && event.documentType ? event.documentType : '');
    this.form.get(`${type}.documentNumber`).setValue(event && event.document ? event.document : '');
    this.form.get(`${type}.name`).setValue(event && event.name ? event.name : '');
  }

  onChangeDate(event: Date, type: 'load' | 'unload') {
    this.form.get(`${type}.date`).setValue(DateManager.dateToString(event, 'DD/MM/YYYY'));
  }

  onChangeTimePact(event: { hours: number, minutes: number }, type: 'load' | 'unload') {
    this.form.get(`${type}.hours`).setValue(event.hours);
    this.form.get(`${type}.minutes`).setValue(event.minutes);
  }

  onChangeCity(city: { key: string, value: ConsignmentCityOption }, type: 'sender' | 'recipient') {
    this.form.get(type === 'sender' ? 'sender.city' : 'recipient.city').setValue(city.key);
    const cityOption = type === 'sender' ? this.senderCityOptions[city.key] : this.recipientCityOptions[city.key];
    this.form.get(type === 'sender' ? 'sender.municipalityCode' : 'recipient.municipalityCode').setValue(city.value.municipalityCode);
    this.form.get(type === 'sender' ? 'sender.cityId' : 'recipient.cityId').setValue(city.value.index);
    if (cityOption && cityOption.date) {
      const date = DateManager.stringToDate(cityOption.date);
      this.form.get(type === 'sender' ? 'load.date' : 'unload.date').setValue(DateManager.dateToString(date, 'DD/MM/YYYY'));
      type === 'sender' ? this.loadDateControl.setValue(date) : this.unloadDateControl.setValue(date);
    }
  }

  onChangeAddress(address: ConsignmentAddressOption, type: 'sender' | 'recipient') {
    this.form.get(`${type}.address`).setValue(address.address);
    this.form.get(`${type}.addressId`).setValue(address.index);
    this.form.get(`${type}.location`).patchValue(address.location);
    if (address && address.time)
      this.form.get(type === 'sender' ? 'load.appointmentTime' : 'unload.appointmentTime').setValue(address.time);
  }

  get canEditConsignmentServiceModule(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.editConsignmentServiceModule
    );
  }


}

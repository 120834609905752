import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "../services/authentication.service";
import { Utils } from "../resources/utils";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class AppInterceptorService implements HttpInterceptor {
  whiteList = ["cargo/request/update", "cargo/update/set"];
  constructor(private router: Router, private authService: AuthService, private utils: Utils) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem("_token") || "T3cl0g1";
    let companyId = '9013312320';
    let appType = '3bba218d9b555c1777e1c9cb45c1b90f';
    if (req && req.url.includes('email/formContact') && this.authService.getUserSession().clientCompanyId !== environment.rootNit) appType = 'KU62iVo2ytWCaedjWat1';
    if (sessionStorage.getItem('_companyId')) companyId = sessionStorage.getItem('_companyId');
    else if (req && req.headers && req.headers.get('companyId')) companyId = req.headers.get('companyId');
    else companyId = this.authService.getCompany() ? this.authService.getCompany().companyId : "9013312320";
    let request = req;
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.set("appType", appType);
      headers = headers.set("companyId", companyId);
      headers = headers.set(
        "userSession",
        sessionStorage.getItem("_email") || "Invited"
      );
      headers = headers.set("tokenUser", token);
      if (
        req &&
        req.url &&
        (req.url.includes("guide/uploadSimpleGuides") ||
          req.url.includes("guide/uploadGuides") ||
          req.url.includes("guide/generate?type=") ||
          req.url.includes("cargo/reports?&excel=true") ||
          req.url.includes("route/cargo/upload") ||
          req.url.includes("guide/excelToGuide") ||
          req.url.includes("cargo/cargoMultiple") ||
          req.url.includes("cargo/rndc/bill") ||
          req.url.includes("catalog/logs")) ||
        req.url.includes("drivin/order/excel")
      ) {
        headers = headers.set("Content-Type", "multipart/form-data");
        request = req.clone({
          headers,
          body: req.body ? req.body : null,
        });
      } else if (req && req.url && this.whiteList.some(url => req.url.includes(url))) {
        headers = headers.set("Content-Type", "application/json");
        request = req.clone({
          headers,
          body: req.body ? req.body : null,
        });
      } else {
        headers = headers.set("Content-Type", "application/json");
        request = req.clone({
          headers,
          body: req.body ? this.utils.removeEmpty(req.body) : null,
        });
      }
    }

    return next.handle(request).pipe(
      finalize(() => { }),
      catchError((err: HttpErrorResponse) => {
        if (err && err.status && req.params.get("token")) this.router.navigateByUrl("/home");
        else if (err.status === 401) this.router.navigateByUrl("/account/login");
        return throwError(err);
      })
    );
  }
}

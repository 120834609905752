import { Component, Input, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';
import { Company } from 'src/app/core/interfaces/company';
import { SelectCompanyService } from './select-company.service';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss'],
  providers: [AuthService, SelectCompanyService]
})
export class SelectCompanyComponent {

  @Input() options;
  @Input() inputFormControl: FormControl;
  @Input() validate: string = '';
  rootCompanies: any[] = [];
  listCompanies: Company[];
  listCompaniesAsync: Observable<any[]>;
  companyCtrl: FormControl = new FormControl('');
  isRootNit: boolean;
  constructor(
    private authService: AuthService,
    public utils: Utils,
    private selectCompanyService: SelectCompanyService,
  ) {
    this.isRootNit = this.authService.userIsFromRootNit();
    this.originListCompanies();

  }

  ngOnInit() {
    const validator = this.inputFormControl && this.inputFormControl.validator ? this.inputFormControl.validator({} as AbstractControl) : '';
    if (validator && validator.required) this.companyCtrl.setValidators(Validators.required);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.inputFormControl && changes.inputFormControl.currentValue) {
        if (this.utils.isDefined(changes.inputFormControl.currentValue.value) && this.utils.isDefined(changes.inputFormControl.currentValue.value.name))
          this.companyCtrl.setValue(changes.inputFormControl.currentValue.value);
      }
      if (changes.options && changes.options.currentValue) {
        if (changes.options.currentValue.initialNit || changes.options.currentValue.initialNit == "") {
          this.selectCompanyByNit(changes.options.currentValue.initialNit);
        }
        if (changes.options.currentValue.optionAll) {
          const index = this.listCompanies.findIndex((company) => company.companyId === null);
          if (index === -1 && this.isRootNit) {
            this.listCompanies.unshift({
              companyId: null,
              name: 'Todas'
            });
          }
        }
      }

      if (changes.validate) {
        switch (this.validate) {
          case 'touched':
            this.companyCtrl.markAsTouched();
            break;
          case 'untouched':
            this.companyCtrl.markAsUntouched();
            break;
          case 'enable':
            this.companyCtrl.enable();
            break;
          case 'disable':
            this.companyCtrl.disable();
            break;
          case 'disable&untouched':
            this.companyCtrl.markAsUntouched();
            this.companyCtrl.disable();
            break;
          default:
            break;
        }
      }
    }
  }

  changeValue() {
    if (this.inputFormControl) this.inputFormControl.setValue("");
  }

  async originListCompanies() {
    if (this.authService.getCompany() && this.authService.getCompany().subCompanies && this.authService.getCompany().subCompanies) {
      this.listCompanies = this.authService.getCompany().subCompanies.map((company) => {
        return {
          name: company.description,
          companyId: company.code
        };
      });
      this.listCompanies.unshift({ name: this.authService.getCompany().name, companyId: this.authService.getCompany().companyId });
      this.createFormControlCompany();
    } else if (this.authService.getUserSession().clientCompanyId && this.isRootNit) {
      await this.getCompanies();
    } else {
      this.listCompanies = this.utils.clone([this.authService.getCompany()]);
      this.onSelectCompany({
        option: {
          value: this.authService.getCompany()
        }
      } as MatAutocompleteSelectedEvent);
      this.companyCtrl.setValue(this.authService.getCompany());
      this.createFormControlCompany();
    }
  }

  createFormControlCompany() {
    this.listCompaniesAsync = this.companyCtrl.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      map(company => company ? this.filterCompanyCtrl(company) : this.listCompanies ? this.listCompanies.slice() : [])
    );
  }

  private filterCompanyCtrl(value: any): any[] {
    if (typeof value === 'string') {
      return this.listCompanies.filter(company => company.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);
    } else if (typeof value === 'object') {
      try {
        return this.listCompanies.filter(company => company.name.toLowerCase().indexOf(value.name.toLowerCase()) >= 0);
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }

  async getCompanies() {
    if (this.authService.getCompanies().length) {
      this.listCompanies = this.utils.clone(this.authService.getCompanies());
      this.createFormControlCompany();
    } else {
      let companies: Company[] = [];
      try { companies = await this.selectCompanyService.getAllCompanies().toPromise() } catch (error) { }
      this.listCompanies = this.utils.clone(companies);
      this.createFormControlCompany();
    }
  }

  cleanField() {
    if (this.inputFormControl) this.inputFormControl.setValue("clean");
    this.companyCtrl.setValue('');
  }

  displayCompanyName(company?: Company): string | undefined {
    return company ? company.name : undefined;
  }

  onSelectCompany($event: MatAutocompleteSelectedEvent) {
    if (this.inputFormControl) this.inputFormControl.setValue($event.option.value);
  }

  async selectCompanyByNit(nit: string) {
    if (nit === "") {
      if (this.inputFormControl) this.inputFormControl.setValue("");
      this.companyCtrl.setValue("");
      return;
    }
    if (!this.listCompanies || !this.listCompanies.length)
      await this.originListCompanies();
    const company: Company[] = this.listCompanies.filter((companyObj) => companyObj.companyId === nit);
    if (company.length) {
      this.companyCtrl.setValue(company[0]);
      this.onSelectCompany({
        option: {
          value: company[0]
        }
      } as MatAutocompleteSelectedEvent);
    }
  }
}

export enum ServiceMessages {
  GENERAL_SUCCESS = 'Operación exitosa',
  GENERAL_UPDATE_SUCCESS = "Cambios guardados correctamente",
  CANNOT_SEND_NOTIFICATIONS = "No se pueden enviar notificaciones al navegador.",
  GENERAL_HTTP_ERROR = "Ha ocurrido un error procesando la solicitud",
  GENERAL_RETRY_INDICATION = "Ha ocurrido un error, intentente nuevamente.",
  REFERENCES_SUCCESS_DELETE = "Datos eliminados exitosamente",
  REFERENCES_SUCCESS_EDIT = "Datos guardados exitosamente",
  CITY_UPDATED = "Se actualizó correctamente la ciudad",
  ERROR_UPDATING_CITY = "Ocurrió un error actualizando la ciudad",
  CITIES_ASSOCIATED = "Ciudades asociadas correctamente",
  ERROR_ASSOCIATING_CITIES = "Ocurrió un error asociando las ciudades",
  CITY_ALREADY_LINKED = "La ciudad seleccionada ya existe en la lista de subciudades",
  USER_DEACTIVE_SUCCESS_GENERAL = "Operacion realizada exitosamente",
  VEHICLE_CHANGED_SUCCESS = "El vehículo ha sido actualizado exitosamente",
  USER_CHANGE_SUCCESS = "Se cambió el rol del usuario exitosamente",
  USER_ACTIVATED = "Se activó el usuario correctamente",
  USER_DEACTIVATED = "Se desactivo el usuario correctamente",
  ADDRESS_LOCATION_NOT_SETTED = "No se ha podido guardar la longitud y latitud ingresada. Por favor vuelva a intentarlo",
  VEHICLE_HOLDERCOMPANY_ASSOCIATED = "El vehículo fue asociado correctamente a la compañia",
  LICENSE_PLATE_NOT_FOUND = 'La placa %s no existe',
  LICENSE_PLATE_FORMAT_INCORRECT = "La placa %s tiene un formato incorrecto",
  VEHICLE_WITHOUT_DRIVER = 'La placa %s no cuenta con conductor',
  NOT_CATEGORY_OR_LICENSE_PLATE = 'No hay informacion asociada a la licencia del conductor o a la categoria respectiva',
  SUCCESS_LOAD_RESOURCES = "Se cargaron %s exitosamente",
  ERROR_LOAD_RESOURCES = "Ocurrió un error al cargar %s",
  SUCCESS_DELETE_MAINTENANCE = "Mantenimiento eliminado exitosamente",
  SUCCESS_CREATE_EDIT_MAINTENANCE = "El mantenimiento se ha %s exitosamente",
  EVIDENCE_SUCCESS_DELETE = "Evidencia(s) %s exitosamente",
  CARGO_GENERATE_MANIFEST_SUCCESS = "Se generó el manifiesto correctamente",
  ASSIGN_DRIVER_SUCCESS = "El conductor se ha %s correctamente",
  SECURITY_STUDY = "El %s se encuentra en estudio de seguridad",
  INACTIVE_USER = "El %s se encuentra inactivo",
  DRIVER_NOT_ACTIVE = "No puedes generar el manifiesto porque el segundo conductor no se encuentra activo",
  SAME_OWNER_VEHICLE_LOAD_TO_RATE_COST_ZERO = "No puedes generar el manifiesto ya que para cargas con tarifa y/o flete 0 el tenedor del vehiculo debe ser el mismo generador del manifiesto (empresa de transporte).",
  SAME_OWNER_VEHICLE_LOAD_TO_RATE_COST_ZERO_ASSING_VEHICLE = "No puedes asignar el vehiculo ya que para cargas con tarifa y/o flete 0 el tenedor del vehiculo debe ser el mismo generador del manifiesto (empresa de transporte).",
  ENABLE_ACTIVE_DRIVER_WITH_MANIFEST_ERRORS = "No es posible activar el segundo conductor debido a los errores presentes en el manifiesto",
  GENERAL_ERROR_BY_USER = "Ocurrió un error guardando la informacion del %s",
  STATE_COST_CENTER = "Se ha %s correctamente el centro de costo",
  DRIVER_CREATED_SUCCESSFULLY = "Conductor creado correctamente",
  FIELD_UPDATED_SUCCESSFULLY = "Se actualizó correctamente %s",
  ERROR_GETTING_DATA = "Se ha producido un error al obtener %s",
  NO_DATA_FOUND = "No se encontraron %s"
}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { Utils } from 'src/app/core/resources/utils';
import { AdminUsersService } from '../admin-users.service';
import { AdditionalCertificationDict, AdditionalCertificationEnum } from 'src/app/core/enums/additionalCertification.enum';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { Patterns } from 'src/app/core/resources/patterns';
import { DocumentEvent } from 'src/app/core/interfaces/documentEvent';
import { AdditionalCertification } from 'src/app/core/interfaces/additionalCertification';
import { Permission } from 'src/app/core/resources/permission';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Companies } from 'src/app/core/resources/companies';
import { VehiclesService } from '../../vehicles/list-vehicles.service';
import { ValidationRule } from 'src/app/core/interfaces/validation-rule';

@Component({
  selector: 'app-user-docs-and-certifications',
  templateUrl: './user-docs-and-certifications.component.html',
  styleUrls: ['./user-docs-and-certifications.component.scss']
})
export class UserDocsAndCertificationsComponent implements OnInit {
  permission = Permission;
  additionalCertificationEnum = AdditionalCertificationEnum;
  // Inputs
  @Input() visualArl: FormControl;
  @Input() visualEps: FormControl;
  @Input() visualOperationalAccreditation: FormControl;
  @Input() title: string;
  @Input() form: FormGroup;
  @Input() typeAction: string;
  @Input() getDocsStateValidation: string = '';
  @Input() loadingResources: boolean;
  @Input() typedDocRules: string[];
  // @Input() docRules: ValidationRule[];
  // Outputs
  @Output() onUploadDocument: EventEmitter<DocumentEvent> = new EventEmitter();
  // Variables
  formAdditionalCertifications: FormGroup;
  additionalCertifications: AdditionalCertification[] = [];
  expireArl: boolean;
  expireEps: boolean;
  expireOperationalAccreditation: boolean;
  epsState: string;
  arlState: string;
  operationalAccreditationState: string;
  preloadedArl: boolean = false;
  preloadedEps: boolean = false;
  preloadedOperationalAccreditation: boolean = false;
  typesRules: string[] = [];
  constructor(
    public adminUsersService: AdminUsersService,
    public utils: Utils,
    private patterns: Patterns,
    public dialog: MatDialog,
    public authService: AuthService,
    public vehiclesService: VehiclesService
  ) { }

  ngOnInit() { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes && changes.form && changes.form.currentValue) if (this.typeAction === 'editDriver') await this.validateDocuments();
    if (changes && changes.getDocsStateValidation) await this.validateDocuments();
  }

  private async processControlValidators() {
    try {
      let docRules = await this.vehiclesService.getValidationRules().toPromise();
      this.typesRules = docRules.filter(rule => rule.roleName === this.role && rule.active).map(rule => rule.ruleName);
      this.typesRules.forEach(type => {
        let control = this[`visual${type.charAt(0).toUpperCase() + type.slice(1)}`];
        if (control) {
          control.setValidators([Validators.required]);
        }
      });
    }
    catch (error) {
      // this.docRules = [];
      console.error(error);
    }
  }

  private get role(): string {
    if (this.title && ['Propietario', 'Administrador', 'Conductor'].includes(this.title))
      return this.title === 'Propietario' ? 'Owner' : this.title === 'Administrador' ? 'Admin' : 'Driver';
    return this.form && this.form.get('role') && this.form.get('role').value &&
      ['Owner', 'Admin', 'Driver'].includes(this.form.get('role').value)
      ? this.form.get('role').value
      : 'Driver';
  }
  /**
   * @param {'ARL|EPS'| AdditionalCertificationEnum} type is the type of the document to upload
  * @description Makes the validations and Opens a modal to confirm the documents's upload
  */
  uploadDocument(type: 'ARL' | 'EPS' | AdditionalCertificationEnum): void {
    const path = `driver/${this.typeAction === 'editDriver' ? this.form.get('information.document').value : 'temp'}/${type}`;
    const title = type === 'ARL' || type === 'EPS' ? type : AdditionalCertificationDict[type];
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `Cargue de documento para ${title}`,
      file: true,
      fileOptions: {
        type: "input",
        title,
        path,
        storage: true,
        pattern: this.patterns.ALL_EXPRESSIONS,
        required: true,
        storageName: type,
        onlyProcessPaths: true,
      },
      inputFormControl: new FormControl(null, [Validators.required]),
      date: "ExpirationDateDocuments",
      fromDate: new Date(),
      subtitle: `Ingrese la fecha de vencimiento de ${title}:`,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.state) {
        this.onUploadDocument.emit({ ...result, type });
        if (this.typeAction === 'editDriver') await this.validateDocuments();
      }
    });
  }

  /**
* @param {'ARL'|'EPS'|AdditionalCertificationEnum} type is the type of the document
* @returns {boolean} returns true if the userInfoSupport has the document required
* @description Indicates if the userInfoSupport has the document required
*/
  getDocumentSupport(type: 'ARL' | 'EPS' | AdditionalCertificationEnum): boolean {
    if (this.form.get('extraDocuments') && this.form.get('extraDocuments').value && this.form.get('extraDocuments').value.length) return this.adminUsersService.getDocumentSupport(type, this.form.get('extraDocuments').value);
    return false;
  }
  /**
  * @description Validates the driver's arl and eps dueDate to check its expiration date
  */
  private async validateDocuments() {
    await this.processControlValidators();
    if (this.form.get('extraDocuments') && this.form.get('extraDocuments').value && this.form.get('extraDocuments').value.length) {
      this.expireArl = this.adminUsersService.isExpiredDoc(this.form.getRawValue(), 'ARL');
      this.expireEps = this.adminUsersService.isExpiredDoc(this.form.getRawValue(), 'EPS');
      this.expireOperationalAccreditation = this.adminUsersService.isExpiredDoc(this.form.getRawValue(), AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION);
      this.epsState = this.adminUsersService.getepsState(this.form.getRawValue(), this.typesRules);
      this.arlState = this.adminUsersService.getarlState(this.form.getRawValue(), this.typesRules);
      this.operationalAccreditationState = this.adminUsersService.getAdditionalCertificationState(this.form.getRawValue(), AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION, this.typesRules);
    }
  }

  getBadgeClass(status: string): string {
    switch (status) {
      case 'Activo': return 'success-badge';
      case 'Inactivo': return 'error-badge';
      case 'Pendiente':
      case 'Estudio de seguridad en proceso':
        return 'warning-badge';
      case 'Opcional':
        return 'info-badge';
      default: return '';
    }
  }
  // GETTERS
  /**
 * @param {AdditionalCertificationEnum} type is the type of the certification
 * @returns {AdditionalCertification} returns the certification required if exists
 * @description Indicates if the driver has the certification required and returns it.
 */
  getAdditionalCertification(type: AdditionalCertificationEnum): AdditionalCertification {
    if (!this.additionalCertifications || !this.additionalCertifications.length) return null;
    return this.additionalCertifications.find(cert => cert && cert.id === type);
  }

  getState(type: 'ARL' | 'EPS'): boolean {
    return this.form && this.form.get(type.toLowerCase()) && this.form.get(type.toLowerCase()).value && this.form.get(type.toLowerCase()).value.active;
  }

  getDueDate(type: 'ARL' | 'EPS'): string {
    return this.form && this.form.get(type.toLowerCase()) && this.form.get(type.toLowerCase()).value && this.form.get(type.toLowerCase()).value.dueDate;
  }

  getDueDateOperationalAccreditation(): string {
    return this.form && this.form.get('extraDocuments') && this.form.get('extraDocuments').value && this.form.get('extraDocuments').value.length && this.form.get('extraDocuments').value.find(doc => doc.type === AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION) ? this.form.get('extraDocuments').value.find(doc => doc.type === AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION).dueDate : '';
  }

  getStateOperationalAccreditation(): string {
    return this.form && this.form.get('extraDocuments') && this.form.get('extraDocuments').value && this.form.get('extraDocuments').value.length && this.form.get('extraDocuments').value.find(doc => doc.type === AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION) ? this.form.get('extraDocuments').value.find(doc => doc.type === AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION).active : '';
  }
  /**
* @returns {boolean} Returns true if the user's SaaS company has escort services
* @description Verifies if the user's SaaS company has escort services
*/
  get hasEscortServicesCompany(): boolean {
    return this.authService.getCompanySaaS() && this.authService.getCompanySaaS().companyId === Companies.companiesNIT.SEGURIDAD_EXTREMA;
  }


}

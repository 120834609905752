import { Validators } from "@angular/forms";
import { CargoStateEnum } from "src/app/core/enums/cargoState.enum";
import { TRIP_TYPES } from "src/app/core/enums/tripTypes.enum";
import { Cargo } from "src/app/core/interfaces/cargo";

export class ServiceResources {
    public serviceRequestUrls = {
        contractor: "/service-request-form/menu-service/%d/contractor-trip-info",
        origin: "/service-request-form/menu-service/%d/origin-load",
        destination: "/service-request-form/menu-service/%d/destination-download",
        vehicle: "/service-request-form/menu-service/%d/vehicle-service",
        cargoType: "/service-request-form/menu-service/%d/load-type",
        loadCharacteristics: "/service-request-form/menu-service/%d/load-characteristics",
        additionalValuesService: "/service-request-form/menu-service/%d/additional-values-service",
        observationsService: "/service-request-form/menu-service/%d/observations-service",
        consignmentsService: "/service-request-form/menu-service/%d/consignments-service",
        servicePublication: "/service-request-form/menu-service/%d/service-publication",
    }
    public steps = {
        main: {
            url: "/service-request-form/menu-service",
            id: 1,
            parentId: 0,
            subSteps: []
        },
        // Paso 1: Contratante
        contractor: {
            url: this.serviceRequestUrls.contractor,
            id: 1,
            parentId: 1,
            subSteps: [
                { id: 1, name: 'contractorName', completed: false },
                { id: 2, name: 'doc', completed: false },
                { id: 3, name: 'typeTrip', completed: false },
                { id: 4, name: 'ministry', completed: false },
                { id: 5, name: 'labels', completed: false }
            ]
        },
        // Paso 2: Origen y Carga
        origin: {
            url: this.serviceRequestUrls.origin,
            id: 1,
            parentId: 2,
            subSteps: [
                { id: 1, name: 'origin', completed: false },
                { id: 2, name: 'dateLoad', completed: false },
                { id: 3, name: 'senderName', completed: false },
                { id: 4, name: 'senderAddresses', completed: false },
                { id: 5, name: 'timePact', completed: false },
                { id: 6, name: 'loadingPointContact', completed: false },
                { id: 7, name: 'loadingPointPhone', completed: false }
            ]
        },
        // Paso 3: Destino
        destination: {
            url: this.serviceRequestUrls.destination,
            id: 1,
            parentId: 3,
            subSteps: [
                { id: 1, name: 'destination', completed: false },
                { id: 2, name: 'receiverName', completed: false },
                { id: 3, name: 'dateDownloadDestination', completed: false },
                { id: 4, name: 'receiverAddresses', completed: false },
                { id: 5, name: 'timePactDestination', completed: false },
                { id: 6, name: 'downloadingPointContact', completed: false },
                { id: 7, name: 'downloadingPointPhone', completed: false }
            ]
        },
        // Paso 4: Vehículo
        vehicle: {
            url: this.serviceRequestUrls.vehicle,
            id: 1,
            parentId: 4,
            subSteps: [
                { id: 1, name: 'vehicleType', completed: false },
                { id: 2, name: 'bodyworkType', completed: false },
                { id: 3, name: 'quantity', completed: false },
                { id: 4, name: 'environment', completed: false }
            ]
        },
        // Paso 5: Tipo de Carga
        cargoType: {
            url: this.serviceRequestUrls.cargoType,
            id: 1,
            parentId: 5,
            subSteps: [
                { id: 1, name: 'letterRetirement', completed: false, alwaysShow: false },
                { id: 2, name: 'regimeType', completed: false, alwaysShow: false },
                { id: 3, name: 'serviceType', completed: false, alwaysShow: true },
                { id: 4, name: 'specificType', completed: false, alwaysShow: true },
            ]
        },
        // Paso 6: Características de la Carga
        loadCharacteristics: {
            url: this.serviceRequestUrls.loadCharacteristics,
            id: 1,
            parentId: 6,
            subSteps: [
                { id: 1, name: 'riskProfile', completed: false },
                { id: 2, name: 'amountDeclared', completed: false },
                { id: 3, name: 'typePackaging', completed: false },
                { id: 4, name: 'quantityUnits', completed: false },
                { id: 5, name: 'merchandiseType', completed: false },
                { id: 6, name: 'weight', completed: false },
                { id: 7, name: 'unit', completed: false }
            ]
        },
        // Paso 7: Servicios Adicionales y Valores
        additionalValuesService: {
            url: this.serviceRequestUrls.additionalValuesService,
            id: 1,
            parentId: 7,
            subSteps: [
                { id: 1, name: 'rate', completed: false },
                { id: 2, name: 'freightCost', completed: false },
                { id: 3, name: 'advancePercentage', completed: false },
                { id: 4, name: 'paymentTime', completed: false }
            ]
        },
        // Paso 8: Observaciones
        observationsService: {
            url: this.serviceRequestUrls.observationsService,
            id: 1,
            parentId: 8,
            subSteps: [
                { id: 1, name: 'observations', completed: false },
                { id: 2, name: 'observationDriver', completed: false }
            ]
        },
        // Paso 9: Remesas
        consignmentsService: {
            url: this.serviceRequestUrls.consignmentsService,
            id: 1,
            parentId: 9,
        },
        // Paso 10: Publicación
        servicePublication: {
            url: this.serviceRequestUrls.servicePublication,
            id: 1,
            parentId: 10,
        }
    };
    public cargoMock: Cargo = {
        approval: "Pending approval",
        assignedDriver: false,
        assignedVehicleFingerPrint: {
            userId: '',
            userName: '',
            date: ''
        },
        confirmedDriver: false,
        confirmedDriverFingerprint: {
            userId: '',
            userName: '',
            date: ''
        },
        consecutive: 0,
        unlegalizedTravelExpense: false,
        requiresPreoperational: false,
        ministry: true,
        id: "",
        dateLoad: "",
        distancy: 0,
        quantity: 0,
        state: CargoStateEnum.REQUEST,
        numberDocumentSender: "",
        numberDocumentCreatorLoad: "",
        idCompany: "",
        businessName: "",
        licensePlate: "",
        driver: "",
        secondDriver: "",
        observation: "",
        observationDriver: "",
        riskProfile: {
            id: '',
            name: ''
        },
        labels: [],
        costCenter: {
            id: 0,
            code: '',
            name: '',
            active: false
        },
        cargoModel: {
            tripType: {
                name: TRIP_TYPES.EMPTY,
                regimeType: "",
                letterRetirement: "",
                date: "",
            },
            cargoType: {
                name: "",
                containerMeasure: {
                    size: "",
                },
            },
            serviceType: {
                name: "",
                cost: "",
            },
            operationType: {
                code: "",
                description: "",
            },
            packagingType: {
                code: "",
                description: "",
            },
            cargoNature: {
                code: "1",
                description: "Carga Normal",
            },
        },
        cargoFeature: {
            productType: {
                name: "",
                type: "No", // Pendiente por implementar
                description: "",
                code: "",
                cargoNature: "",
                cargoNatureId: "",
            },
            vehicleType: {
                name: "",
                quantity: 1,
                quality: "",
                bodyWorkType: {
                    name: "",
                },
            },
            cargoMeasure: {
                quantity: 0,
                totalMeasurement: 0,
                unit: "",
                totalWeigth: 0,
                amount: 0,
                type: "",
            },
            uploadDownload: {
                origin: {
                    name: "",
                    isInternational: false,
                    country: {
                        name: "Colombia",
                        code: "CO",
                    },
                    municipalityCode: "",
                    reteica: 0,
                    addresses: [
                        {
                            id: "",
                            address: "",
                            location: {
                                lat: 0,
                                lng: 0,
                            },
                            time: "",
                            cellphone: "",
                            timePact: 0,
                            minutePact: 0,
                            quantity: 0,
                            thirdPartyConsignment: {
                                documentType: "",
                                documentNumber: "",
                                branchOffice: "",
                                name: "",
                                documentTypeName: "",
                            },
                            timeType: "",
                        },
                    ],
                },
                destination: [
                    {
                        id: "",
                        name: "",
                        isInternational: false,
                        country: {
                            name: "Colombia",
                            code: "CO",
                        },
                        municipalityCode: "",
                        downloadDate: "",
                        reteica: 0,
                        addresses: [
                            {
                                id: "",
                                guidesId: [],
                                createGuide: false,
                                numberOfGuides: 0,
                                guidesRecipients: [],
                                address: "",
                                location: {
                                    lat: 0,
                                    lng: 0,
                                },
                                time: "",
                                timePact: 0,
                                minutePact: 0,
                                cellphone: "",
                                email: "",
                                quantity: 0,
                                cargoMeasure: {
                                    quantity: 1,
                                    totalMeasurement: 0,
                                    unit: "1",
                                    totalWeigth: 0,
                                    amount: 0,
                                    type: "Apilable",
                                },
                                timeType: '',
                                thirdPartyConsignment: {
                                    documentType: '',
                                    documentNumber: '',
                                    branchOffice: '',
                                    name: '',
                                    documentTypeName: ''
                                },
                                consignments: []
                            }
                        ]
                    }
                ]
            }
        },
        shippingCost: {
            freightCost: 0,
            totalCost: 0,
            advancePercentage: 0,
            valueAdvance: 0,
            advancePaymentMethod: 'Transferencia',
            balancePaymentMethod: 'Transferencia',
            reteicaPercentage: 0,
            reteftePercentage: 0,
            paymentTime: 30,
            rate: 0
        },
        additionalCosts: [],
        estimatedTime: 0,
        exclusive: false,
        cargoOwner: {
            documentType: '',
            documentNumber: '',
            branchOffice: '',
            name: '',
            //
            address: '',
            location: {
                lat: 0,
                lng: 0
            }
        },
        seal: '',
        container: '',
        containerExpirationDate: '',
        booking: '',
        realTime: 0,
        axles: 2,
        zone: "private",
        creationDate: '',
        manifestType: {
            code: '',
            description: ''
        },
        consecutiveAdditionalCosts: 0,
        minimumApprovedValueSicetac: 0,
        belowSicetac: false,
        fastPayment: false,
        manifestTerms: false,
        freightValueApprovedBelowSicetac: false,
        errorMassiveRequest: false,
        ministryError: false,
        durationToTakeCargo: {
            startDate: '',
        },
        numberOfOffers: 0,
        secondDriverActive: false,
        errorFieldsExcelCargo: {},
        gpsMonitoringIntegration: false,
        uploadedBillingXML: false,
        rate: 0,
        cost: 0,
        simpleRegimen: false,
        chargeTaxes: false,
        rndcTransmited: false,
        consecutiveTraceability: 0,
        holderCompany: '',
    };
}
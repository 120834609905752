import { Injectable } from '@angular/core';
import * as _ from "lodash";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Utils } from 'src/app/core/resources/utils';
import { CargoResources } from 'src/app/modules/cargo/manual-creation-cargo/resources/cargo';
import { CargoModel } from 'src/app/core/interfaces/cargoModel';
import { Cargo, Cargo as CargoInterface, CargoRequest } from "src/app/core/interfaces/cargo";
import { ComponentActive } from 'src/app/core/models/component-active.model';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { timeout, map } from 'rxjs/operators';
import { ServiceResources } from '../resources/services';
import { Tag } from 'src/app/core/interfaces/tag';
import { TripType } from 'src/app/core/interfaces/tripType';
import { RiskProfile } from 'src/app/core/interfaces/riskProfile';
import { Destination } from 'src/app/core/interfaces/destination';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { AdditionalService } from 'src/app/core/interfaces/additionalService';
import { ServiceType } from 'src/app/core/interfaces/serviceType';
import { DateManager } from 'src/app/core/managers/date.manager';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';
import { Patterns } from 'src/app/core/resources/patterns';
import { CargoDetailService } from '../../cargo/cargo-detail/cargo-detail.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { Address } from 'src/app/core/interfaces/address';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { CompanyManager } from 'src/app/core/managers/company.manager';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { ConsignmentCargo } from 'src/app/core/interfaces/consignmentCargo';
import { Origin } from 'src/app/core/interfaces/origin';
import { ThirdParty } from 'src/app/core/interfaces/thirdParty';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { GoogleService } from 'src/app/core/services/google.service';

@Injectable({
    providedIn: 'root'
})
export class MenuServiceService extends ReactiveForm {
    public steps = this.serviceResources.steps;
    public cargo: Cargo;
    public cargoConsignments: ConsignmentCargo[] = [];
    formObservable: BehaviorSubject<Cargo> = new BehaviorSubject<Cargo>(this.form.value);
    consignmentsObservable: BehaviorSubject<ConsignmentCargo[]> = new BehaviorSubject<ConsignmentCargo[]>([]);
    isListeningChangesFromFirebase: boolean = false;
    permission = Permission;
    readonly regimeTypes = {
        "Exportación": ["DUTA", "DTAI", "Nacionalizado"],
        "Importación": ["OTM", "DUTA", "Nacionalizado"]
    };
    readonly serviceTypes = [
        {
            name: "Carga suelta",
            types: ["Carga expresa", "Carga consolidada"],
            image: "/assets/svg/icons/icon-load-free.svg"
        },
        {
            name: "Contenedor",
            types: ["Contenedor vacío express", "Contenedor lleno express", "Contenedor vacío con carga de compensación", "Contenedor lleno consolidado"],
            containerSizes: ['Un contenedor de 45 pies', 'Un contenedor de 40 pies', 'Dos contenedores de 20 pies', 'Un contenedor de 20 pies'],
            image: "/assets/svg/icons/icon-container.svg"
        }
    ];
    readonly regimeTypesWithDateVesselArrival = ['OTM', 'DTA', 'DUTA'];
    readonly manifestTypes = [
        { code: 'G', description: "General", tooltip: "Viaje estandar de origen a destino. Requiere remesa para cada parada." },
        //{ code: '2', description: "Viaje vacío", tooltip: "No requiere remesa. Se usa para pagar el viaje vacío en cualquier vía nacional." },
        //{ code: '3', description: "Varios viajes en el día", tooltip: "Cubre varios viajes en un mismo día para un mismo vehículo entre un único origen y un único destino" },
        //{ code: '4', description: "Viaje de ida y regreso", tooltip: "Operación con dos trayectos ida y vuelta. Entre dos municipios distintos y con al menos dos remesas." },
        { code: 'M', description: "Viaje multiparada", tooltip: "Viaje nacional que tiene paradas en municipios intermedios distintos entre el origen y el destino con al menos dos remesas." },
    ];
    readonly consignmentTypes = [
        { code: 'G', description: "General", tooltip: "Viaje estandar de origen a destino." },
        { code: 'P', description: "Mercancía consolidada", tooltip: "En una misma remesa se agrupa mercancía de varios generadores de carga. En este caso el contratante en el campo generador de carga se puede colocar en la misma empresa de transporte." },
        { code: 'C', description: "Contenedor cargado", tooltip: "Se utiliza cuando se transporta para el mismo cliente el contenedor y la mercancía que va en él." },
        { code: 'V', description: "Contenedor vacío.", tooltip: "Se utiliza cuando se transporta un contenedor vacío o cuando el contenedor vacío se debe facturar en una factura diferente a la factura del transporte de la mercancía." },
    ];
    readonly cargoNatureTypes = [
        { code: '1', description: "Carga General (Carga Normal)" },
        { code: '2', description: "Mercancía Peligrosa" },
        { code: '3', description: "Carga Extradimensionada" },
        { code: '4', description: "Carga Extrapesada" },
        { code: '5', description: "Mercancía Peligrosa: Residuos Peligrosos" },
        { code: '6', description: "Semovientes" },
        { code: '7', description: "Refrigerada" },
    ];
    readonly operationTypes = [
        { code: '1', description: "Importación", tooltip: "Viaje desde un puerto hacia cualquier ciudad" },
        { code: '2', description: "Exportación", tooltip: "Viaje desde cualquier ciudad hacia puerto" },
        { code: '3', description: "Paqueteo urbano", tooltip: "Paqueteo dentro del mismo municipio." },
        { code: '4', description: "Paqueteo nacional", tooltip: "Paqueteo con municipio de origen distinto al municipio de destino" },
        { code: '5', description: "Masivo nacional", tooltip: "Viaje masivo con municipio de origen igual al municipio de destino" },
        { code: '6', description: "Viaje multiparada", tooltip: "Viaje nacional que tiene paradas en municipios intermedios distintos entre el origen y el destino con al menos dos remesas." },
        { code: '7', description: "Viaje ida y retorno", tooltip: "Operación con dos trayectos ida y vuelta. Entre dos municipios distintos y con al menos dos remesas." },
        { code: '8', description: "Masivo urbano", tooltip: "Viaje masivo con municipio de origen distinto al municipio de destino" },
        { code: '9', description: "Varios viajes en el día", tooltip: "Operación que cubre varios viajes en un mismo día para un mismo vehículo entre un único origen y un único destino" },
        { code: '10', description: "Viaje vacío", tooltip: "Viaje vacío" },
        { code: '11', description: "Viaje de contenedor vacío", tooltip: "Viaje de sólo contenedor vacío." }
    ];
    readonly timePactOptions = this.getTimePactList();
    readonly consignmentFieldsToCheck = this.global.consignmentFieldsToCheck;

    constructor(
        private http: HttpClient,
        private endpointResources: Endpoints,
        private utils: Utils,
        public formBuilder: FormBuilder,
        private dialog: MatDialog,
        private snackBarService: SnackBarService,
        private global: Global,
        private cargoDetailService: CargoDetailService,
        private authService: AuthService,
        private serviceResources: ServiceResources,
        private patterns: Patterns,
        private spinner: NgxSpinnerService,
        private companyManager: CompanyManager,
        private permissionRole: PermissionRole,
        private cargoManager: CargoManager,
        private googleService: GoogleService
    ) {
        super(
            formBuilder,
            utils.clone(serviceResources.cargoMock),
            utils.clone(serviceResources.cargoMock)
        );
    }

    public resetForm() {
        this.form = new ReactiveForm(this.formBuilder, this.utils.clone(this.serviceResources.cargoMock)).form;
    }

    public patchForm(newCargo: Cargo, isListeningChangesFromFirebase: boolean = false) {
        this.isListeningChangesFromFirebase = isListeningChangesFromFirebase;
        this.resetForm();
        const keys = Object.keys(newCargo);
        keys.forEach(key => {
            if (Array.isArray(newCargo[key])) {
                const value = this.utils.getNestedValue(newCargo, key);
                if (value && value.length) {
                    value.forEach((item: any) => this.form.get(key).push(typeof item === 'object'
                        ? this.formBuilder.group(item)
                        : this.formBuilder.control(item)));
                }
            }
        });
        this.setUnexistingElements(newCargo);

        const originAddresses: AddressCargo[] = this.utils.getNestedValue(newCargo, 'cargoFeature.uploadDownload.origin.addresses');
        if (originAddresses && originAddresses.length)
            originAddresses.forEach((address: AddressCargo, i: number) => i && this.form.get('cargoFeature.uploadDownload.origin.addresses').push(this.createOriginAddressGroup()));

        const destinationCities: Destination[] = this.utils.getNestedValue(newCargo, 'cargoFeature.uploadDownload.destination');
        if (destinationCities && destinationCities.length)
            destinationCities.forEach((destinationCity: Destination, i: number) => {
                if (i) this.form.get('cargoFeature.uploadDownload.destination').push(this.createDestinationGroup());
                const addresses: AddressCargo[] = this.utils.getNestedValue(destinationCity, 'addresses');
                if (addresses && addresses.length)
                    addresses.forEach((address: AddressCargo, j: number) => {
                        if (j) this.form.get('cargoFeature.uploadDownload.destination').at(i).get('addresses').push(this.createDestinationAddressGroup());
                        const guidesRecipients: Address[] = this.utils.getNestedValue(address, 'guidesRecipients');
                        if (guidesRecipients && guidesRecipients.length)
                            guidesRecipients.forEach(() => this.form.get('cargoFeature.uploadDownload.destination').at(i).get('addresses').at(j).get('guidesRecipients').push(this.createGuideRecipientGroup()));
                        const consignments: string[] = this.utils.getNestedValue(address, 'consignments');
                        if (consignments && consignments.length)
                            consignments.forEach(() => this.form.get('cargoFeature.uploadDownload.destination').at(i).get('addresses').at(j).get('consignments').push(new FormControl('')));
                    });
            });
        this.form.patchValue(newCargo);
        this.formObservable.next(this.form.value);
    }

    private setUnexistingElements(newCargo: Cargo) {
        const keys = Object.keys(newCargo);
        let elementsNotExisting = [];
        keys.forEach(key => {
            if (this.utils.isDefined(newCargo[key]) && !this.utils.isDefined(this.form.value[key])) {
                elementsNotExisting.push(key);
                if (Array.isArray(newCargo[key])) {
                    const arr = this.formBuilder.array(newCargo[key]);
                    this.form.addControl(key, arr);
                } else if (typeof newCargo[key] === 'object') {
                    const form = this.formBuilder.group(newCargo[key]);
                    this.form.addControl(key, form);
                } else {
                    this.form.addControl(key, this.formBuilder.control(newCargo[key]));
                }
            }
        });
        if (elementsNotExisting.length)
            console.warn("Por favor verificar los siguientes elementos, están presentes en el servicio pero no en el formulario (Se agregaron para evitar errores):", elementsNotExisting);
    }

    /**
     * @description Creates form groups for origin addresses
     * @returns FormGroup with origin address fields
     */
    public createOriginAddressGroup(): FormGroup {
        return new ReactiveForm(this.formBuilder, this.serviceResources.cargoMock.cargoFeature.uploadDownload.origin.addresses[0]).form;
    }

    /** 
     * @description Creates form groups for destination
     * @returns FormGroup with destination fields
     */
    public createDestinationGroup(): FormGroup {
        return new ReactiveForm(this.formBuilder, this.serviceResources.cargoMock.cargoFeature.uploadDownload.destination[0]).form;
    }

    /**
     * @description Creates form groups for destination addresses
     * @returns FormGroup with destination address fields
     */
    public createDestinationAddressGroup(): FormGroup {
        return new ReactiveForm(this.formBuilder, this.serviceResources.cargoMock.cargoFeature.uploadDownload.destination[0].addresses[0]).form;
    }

    /**
     * @description Creates form groups for travel expenses
     * @returns FormGroup with travel expense fields
     */
    public createTravelExpenseGroup(): FormGroup {
        return this.formBuilder.group({
            cargoConsecutive: [this.cargo.consecutive],
            cargoId: [this.cargo.id],
            totalPaid: [0, [Validators.min(0)]],
            travelExpensesType: [{ id: "2", name: "Peaje" }],
        });
    }

    /**
     * @description Creates form groups for guide IDs
     * @returns FormGroup with guide ID fields
     */
    public createGuideRecipientGroup(): FormGroup {
        return this.formBuilder.group({
            name: [''],
            email: [''],
            phone: ['']
        });
    }

    public getConsignmentForm(): FormGroup {
        return this.utils.createConsignmentForm(this.cargo);
    }

    /**
     * @description Validates form data for each step
     * All isValid* methods follow the same pattern:
     * Gets the form control and returns its validity state
     */
    public isValidEntireCargo(): { isValid: boolean, error: string } {
        const isValidContractorTripInfo = this.isValidContractorTripInfo();
        if (!isValidContractorTripInfo.isValid) return isValidContractorTripInfo;
        const isValidOrigin = this.isValidOrigin();
        if (!isValidOrigin.isValid) return isValidOrigin;
        const isValidDestination = this.isValidDestination();
        if (!isValidDestination.isValid) return isValidDestination;
        const isValidVehicleTypeInfo = this.isValidVehicleTypeInfo();
        if (!isValidVehicleTypeInfo.isValid) return isValidVehicleTypeInfo;
        const isValidLoadTypeService = this.isValidLoadTypeService();
        if (!isValidLoadTypeService.isValid && this.isRequiredLoadTypeService()) return isValidLoadTypeService;
        const isValidLoadCharacteristics = this.isValidLoadCharacteristics();
        if (!isValidLoadCharacteristics.isValid) return isValidLoadCharacteristics;
        const isValidServiceValue = this.isValidServiceValue();
        if (!isValidServiceValue.isValid) return isValidServiceValue;
        const isValidConsignmentsService = this.isValidConsignmentsService();
        if (!isValidConsignmentsService.isValid) return isValidConsignmentsService;
        const isValidServicePublication = this.isValidServicePublication();
        if (!isValidServicePublication.isValid) return isValidServicePublication;

        return { isValid: true, error: '' };
    }

    public isValidContractorTripInfo(): { isValid: boolean, error: string } {
        const isValidTripType = this.isValidTripType();
        if (!isValidTripType.isValid) return isValidTripType;
        const isValidMinistry = this.isValidMinistry();
        if (!isValidMinistry.isValid) return isValidMinistry;
        const isValidCargoOwnerName = this.isValidCargoOwnerName();
        if (!isValidCargoOwnerName.isValid) return isValidCargoOwnerName;
        const isValidCargoOwnerBranchOffice = this.isValidCargoOwnerBranchOffice();
        if (!isValidCargoOwnerBranchOffice.isValid) return isValidCargoOwnerBranchOffice;

        return { isValid: true, error: '' };
    }

    public isValidTripType(): { isValid: boolean, error: string } {
        const tripType = this.utils.getNestedValue(this.cargo, 'cargoModel.tripType.name');
        if (!tripType) return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el tipo de viaje') };
        return { isValid: true, error: '' };
    }
    public isRequiredMinistry(): boolean {
        return !this.isEscortService();
    }
    public isValidMinistry(): { isValid: boolean, error: string } {
        const ministry = this.utils.getNestedValue(this.cargo, 'ministry');
        if (!this.utils.isDefined(ministry) && this.isRequiredMinistry())
            return { isValid: false, error: "Se requiere guardar nuevamente el requerimiento de manifiesto de carga" };
        return { isValid: true, error: '' };
    }
    public isValidCargoOwnerName(): { isValid: boolean, error: string } {
        const ministry = this.utils.getNestedValue(this.cargo, 'ministry');
        const cargoOwner = this.utils.getNestedValue(this.cargo, 'cargoOwner');
        if (ministry) {
            if (!cargoOwner || !cargoOwner.name)
                return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el nombre del contratante') };
            if (!cargoOwner.documentNumber || !cargoOwner.documentType)
                return { isValid: false, error: "Se requiere volver a seleccionar al contratante del servicio" };
        }
        const idCompany = this.utils.getNestedValue(this.cargo, 'idCompany');
        if (!idCompany)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el nombre del contratante') };
        return { isValid: true, error: '' };
    }
    public isRequiredCargoOwnerBranchOffice(): boolean {
        const ministry = this.utils.getNestedValue(this.cargo, 'ministry');
        return !!ministry;
    }
    public isValidCargoOwnerBranchOffice(): { isValid: boolean, error: string } {
        const cargoOwner = this.utils.getNestedValue(this.cargo, 'cargoOwner');
        if ((!cargoOwner || !cargoOwner.branchOffice) && this.isRequiredCargoOwnerBranchOffice())
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'la dirección del contratante') };
        return { isValid: true, error: '' };
    }

    public isValidOrigin(): { isValid: boolean, error: string } {
        const isValidOriginCity = this.isValidOriginCity();
        if (!isValidOriginCity.isValid) return isValidOriginCity;
        const isValidDateLoad = this.isValidDateLoad();
        if (!isValidDateLoad.isValid) return isValidDateLoad;
        const isValidReteica = this.isValidReteica();
        if (!isValidReteica.isValid && this.isRequiredReteica()) return isValidReteica;
        const isValidOriginAddresses = this.isValidOriginAddresses();
        if (!isValidOriginAddresses.isValid) return isValidOriginAddresses;
        return { isValid: true, error: '' };
    }

    public isValidOriginCity(): { isValid: boolean, error: string } {
        const originCity = this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.origin.name');
        if (!originCity)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'la ciudad de origen') };
        const country = this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.origin.country');
        if (!country || !country.code || !country.name)
            return { isValid: false, error: "Se requiere volver a seleccionar la ciudad de origen" };
        const municipalityCode = this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.origin.municipalityCode');
        if (!municipalityCode && country.code === 'CO')
            return { isValid: false, error: "Se requiere volver a seleccionar la ciudad de origen" };
        return { isValid: true, error: '' };
    }

    public isValidDateLoad(): { isValid: boolean, error: string } {
        const dateLoad = this.utils.getNestedValue(this.cargo, 'dateLoad');
        if (!dateLoad)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'la fecha de origen') };
        if (DateManager.isBefore(DateManager.stringToDate(dateLoad), new Date()))
            return { isValid: false, error: "La fecha de origen no puede ser anterior a la fecha actual" };
        return { isValid: true, error: '' };
    }

    public isRequiredReteica(): boolean {
        const permissionToSkipReteica = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.skipReteicaValidation);
        const ministry = this.utils.getNestedValue(this.cargo, 'ministry');
        return !!ministry && !this.isEscortService() && !permissionToSkipReteica;
    }

    public isValidReteica(): { isValid: boolean, error: string } {
        const reteica = this.utils.getNestedValue(this.cargo, 'shippingCost.reteicaPercentage');
        if (this.isRequiredReteica() && !reteica)
            return { isValid: false, error: "La ciudad de origen no tiene asignado un ReteIca por favor asocielo o cambie de ciudad" };
        return { isValid: true, error: '' };
    }

    public isValidOriginAddresses(): { isValid: boolean, error: string } {
        const originAddresses: AddressCargo[] = this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.origin.addresses');
        if (!originAddresses || !originAddresses.length)
            return { isValid: false, error: "No hay direcciones de origen registradas" };
        for (let index = 0; index < originAddresses.length; index++) {
            const address = originAddresses[index];
            if (!address || !address.address)
                return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', `la dirección ${index + 1}`) };
            if (address && !address.time)
                return { isValid: false, error: `Se requiere volver a seleccionar la hora de la dirección ${index + 1}` };
            if (address && (!address.location || !address.location.lat || !address.location.lng))
                return { isValid: false, error: `Se requiere volver a seleccionar la dirección ${index + 1}` };
            if (address && address.cellphone && address.cellphone.length < 10)
                return { isValid: false, error: this.utils.giveMessageError('MIN_LENGTH_NOT_REACHED', `celular de la dirección ${index + 1}`, 10) };
        };
        return { isValid: true, error: '' };
    }

    public isValidDestination(): { isValid: boolean, error: string } {
        const isValidDestinationCities = this.isValidDestinationCities();
        if (!isValidDestinationCities.isValid) return isValidDestinationCities;
        const isValidDestinationAddresses = this.isValidDestinationAddresses();
        if (!isValidDestinationAddresses.isValid) return isValidDestinationAddresses;
        return { isValid: true, error: '' };
    }

    public isValidDestinationCities(): { isValid: boolean, error: string } {
        const destinationCities: Destination[] = this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.destination');
        if (!destinationCities || !destinationCities.length)
            return { isValid: false, error: "No hay ciudades de destino registradas" };

        const tripType = this.utils.getNestedValue(this.cargo, 'cargoModel.tripType.name');
        const originCity = this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.origin.name');
        const destinationCitiesNames = [];
        let lastDate = this.cargo.dateLoad ? DateManager.stringToDate(this.cargo.dateLoad) : new Date();
        for (let index = 0; index < destinationCities.length; index++) {
            const destinationCity = destinationCities[index];
            if (!destinationCity || !destinationCity.name)
                return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', `la ciudad de destino ${index + 1}`) };
            if (destinationCity && (!destinationCity.country || !destinationCity.country.code || !destinationCity.country.name))
                return { isValid: false, error: `Se requiere volver a seleccionar la ciudad ${index + 1} de destino` };
            if (destinationCity && destinationCity.country && destinationCity.country.code === 'CO' && !destinationCity.municipalityCode)
                return { isValid: false, error: `Se requiere volver a seleccionar la ciudad ${index + 1} de destino` };
            if (originCity && tripType && ["Urbana", "Última milla"].includes(tripType) && destinationCity.name !== originCity)
                return { isValid: false, error: `El tipo de viaje "${tripType === 'Última milla' ? 'Última milla / Paqueteo urbano' : tripType}" debe tener como origen y destino la misma ciudad` };
            destinationCitiesNames.push(destinationCity.name);
            if (destinationCity && !destinationCity.downloadDate)
                return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', `la fecha del destino ${index + 1}`) };
            if (destinationCity && DateManager.isBefore(DateManager.stringToDate(destinationCity.downloadDate), lastDate))
                return { isValid: false, error: `La fecha del destino ${index + 1} no puede ser anterior a la fecha ${index ? `del destino ${index}` : 'de origen'}` };
            lastDate = DateManager.stringToDate(destinationCity.downloadDate);
        };
        if (originCity && tripType && ["Nacional", "Internacional"].includes(tripType) && destinationCitiesNames.every(destination => destination && destination === originCity))
            return { isValid: false, error: `El tipo de viaje "${tripType}" debe tener como origen y destino ciudades distintas` };

        return { isValid: true, error: '' };
    }

    public isValidDestinationAddresses(): { isValid: boolean, error: string } {
        const destinations: Destination[] = this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.destination');
        const ministry = this.utils.getNestedValue(this.cargo, 'ministry');
        if (!destinations || !destinations.length)
            return { isValid: false, error: "No hay ciudades de destino registradas" };

        let lastDate = this.cargo.dateLoad ? DateManager.stringToDate(this.cargo.dateLoad) : new Date();
        for (let index = 0; index < destinations.length; index++) {
            const destination = destinations[index];
            if (!destination || !destination.addresses || !destination.addresses.length)
                return { isValid: false, error: `No hay direcciones registradas para el destino ${index + 1}` };
            for (let indexAddress = 0; indexAddress < destination.addresses.length; indexAddress++) {
                const address = destination.addresses[indexAddress];
                if (!address || !address.address)
                    return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', `la dirección ${indexAddress + 1} del destino ${index + 1}`) };
                if (address && !address.time)
                    return { isValid: false, error: `Se requiere volver a seleccionar la hora de la dirección ${indexAddress + 1} del destino ${index + 1}` };
                if (address && (!address.location || !address.location.lat || !address.location.lng))
                    return { isValid: false, error: `Se requiere volver a seleccionar la dirección ${indexAddress + 1} del destino ${index + 1}` };
                if (address && address.cellphone && address.cellphone.length < 10)
                    return { isValid: false, error: this.utils.giveMessageError('MIN_LENGTH_NOT_REACHED', `celular de la dirección ${indexAddress + 1} del destino ${index + 1}`, 10) };
                if (address.consignments && address.consignments.length && (!ministry || address.consignments.some(consignmentId => !this.cargoConsignments.find(consignment => consignment.id === consignmentId))))
                    return { isValid: false, error: `Se requiere volver a guardar las direcciones de destino` };
                if (destination && !destination.downloadDate)
                    return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', `la fecha del destino ${index + 1}`) };
                const destinationDate = DateManager.stringToDate(`${DateManager.setStartOfDay(DateManager.stringToDate(destination.downloadDate)).toISOString().split('T')[0]}T${address.time} -0500`, 'YYYY-MM-DDTHH:mm:ssZ');
                if (destination && DateManager.isBefore(destinationDate, lastDate))
                    return { isValid: false, error: `La fecha del destino ${index + 1} no puede ser anterior a la fecha ${index ? `del destino ${index}` : 'de origen'}` };
                lastDate = destinationDate;
            };
        };
        return { isValid: true, error: '' };
    }

    /**
     * @description Validates vehicle type information
     * @returns { isValid: boolean, error: string } indicating if vehicle type information is valid
     */
    public isValidVehicleTypeInfo(): { isValid: boolean, error: string } {
        const isValidVehicleType = this.isValidVehicleType();
        if (!isValidVehicleType.isValid) return isValidVehicleType;
        const isValidVehicleTypeBodyWorkType = this.isValidVehicleTypeBodyWorkType();
        if (!isValidVehicleTypeBodyWorkType.isValid) return isValidVehicleTypeBodyWorkType;
        const isValidEnvironmentType = this.isValidEnvironmentType();
        if (!isValidEnvironmentType.isValid) return isValidEnvironmentType;
        return { isValid: true, error: '' };
    }
    public isValidVehicleType(): { isValid: boolean, error: string } {
        const vehicleType = this.utils.getNestedValue(this.cargo, 'cargoFeature.vehicleType.name');
        if (!vehicleType)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el tipo de vehiculo') };
        return { isValid: true, error: '' };
    }
    public isValidVehicleTypeBodyWorkType(): { isValid: boolean, error: string } {
        const bodyWorkType = this.utils.getNestedValue(this.cargo, 'cargoFeature.vehicleType.bodyWorkType.name');
        if (!bodyWorkType)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el tipo de carroceria') };
        return { isValid: true, error: '' };
    }
    public isRequiredEnvironmentType(): boolean {
        const vehicleType = this.utils.getNestedValue(this.cargo, 'cargoFeature.vehicleType.name');
        const bodyWorkType = this.utils.getNestedValue(this.cargo, 'cargoFeature.vehicleType.bodyWorkType.name');
        return this.cargoManager.requestEnvironmentType(vehicleType, bodyWorkType);
    }
    public isValidEnvironmentType(): { isValid: boolean, error: string } {
        if (!this.isRequiredEnvironmentType()) return { isValid: true, error: '' };
        const environmentType = this.utils.getNestedValue(this.cargo, 'cargoFeature.vehicleType.quality');
        if (!environmentType)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el tipo de ambiente') };
        return { isValid: true, error: '' };
    }

    public isRequiredLoadTypeService(): boolean {
        return !this.isEscortService();
    }

    public isValidLoadTypeService(): { isValid: boolean, error: string } {
        if (!this.isRequiredLoadTypeService()) return { isValid: true, error: '' };

        const isValidLetterRetirement = this.isValidLetterRetirement();
        if (!isValidLetterRetirement.isValid) return isValidLetterRetirement;
        const isValidRegimeType = this.isValidRegimeType();
        if (!isValidRegimeType.isValid) return isValidRegimeType;
        const isValidDateVesselArrival = this.isValidDateVesselArrival();
        if (!isValidDateVesselArrival.isValid) return isValidDateVesselArrival;
        const isValidLoadType = this.isValidLoadType();
        if (!isValidLoadType.isValid) return isValidLoadType;
        const isValidServiceType = this.isValidServiceType();
        if (!isValidServiceType.isValid) return isValidServiceType;
        const isValidContainerSize = this.isValidContainerSize();
        if (!isValidContainerSize.isValid) return isValidContainerSize;

        return { isValid: true, error: '' };
    }

    public isRequiredLetterRetirement(cargo?: Cargo): boolean {
        const tripType = this.utils.getNestedValue(cargo ? cargo : this.cargo, 'cargoModel.tripType.name');
        return tripType === 'Exportación';
    }

    public isValidLetterRetirement(): { isValid: boolean, error: string } {
        const letterRetirement = this.utils.getNestedValue(this.cargo, 'cargoModel.tripType.letterRetirement');
        if (this.isRequiredLetterRetirement() && !letterRetirement)
            return { isValid: false, error: 'Falta subir la carta de retiro' };
        return { isValid: true, error: '' };
    }

    public isRequiredRegimeType(cargo?: Cargo): boolean {
        return this.isImportOrExport(cargo ? cargo : this.cargo);
    }

    public isValidRegimeType(): { isValid: boolean, error: string } {
        const tripType = this.utils.getNestedValue(this.cargo, 'cargoModel.tripType.name');
        const regimeType = this.utils.getNestedValue(this.cargo, 'cargoModel.tripType.regimeType');
        if (this.isRequiredRegimeType() && !regimeType)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el tipo de regimen') };
        if (this.regimeTypes[tripType] && !this.regimeTypes[tripType].includes(regimeType))
            return { isValid: false, error: "Es necesario volver a seleccionar el tipo de regimen" };
        return { isValid: true, error: '' };
    }

    public isRequiredDateVesselArrival(cargo?: Cargo): boolean {
        const regimeType = this.utils.getNestedValue(cargo ? cargo : this.cargo, 'cargoModel.tripType.regimeType');
        return this.isImportOrExport(cargo ? cargo : this.cargo) && this.regimeTypesWithDateVesselArrival.includes(regimeType);
    }

    public isValidDateVesselArrival(): { isValid: boolean, error: string } {
        const dateVesselArrival = this.utils.getNestedValue(this.cargo, 'cargoModel.tripType.date');
        if (this.isRequiredDateVesselArrival() && !dateVesselArrival)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'la fecha de descargue del buque') };
        return { isValid: true, error: '' };
    }

    public isRequiredLoadType(): boolean {
        const tripType = this.utils.getNestedValue(this.cargo, 'cargoModel.tripType.name');
        return tripType !== 'Última milla';
    }

    public isValidLoadType(): { isValid: boolean, error: string } {
        const loadType = this.utils.getNestedValue(this.cargo, 'cargoModel.cargoType.name');
        const tripType = this.utils.getNestedValue(this.cargo, 'cargoModel.tripType.name');
        if (!loadType)
            return {
                isValid: false, error: tripType === 'Última milla'
                    ? "Se requiere volver a guardar el tipo de servicio"
                    : this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el tipo de carga')
            };
        if (tripType === 'Última milla' && loadType !== 'Carga suelta')
            return { isValid: false, error: "Se requiere volver a guardar el tipo de servicio" };

        return { isValid: true, error: '' };
    }

    public isValidServiceType(): { isValid: boolean, error: string } {
        const tripType = this.utils.getNestedValue(this.cargo, 'cargoModel.tripType.name');
        const cargoType = this.utils.getNestedValue(this.cargo, 'cargoModel.cargoType.name');
        const serviceType = this.utils.getNestedValue(this.cargo, 'cargoModel.serviceType.name');
        if (!serviceType)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el tipo de servicio') };
        if (tripType === 'Última milla' && cargoType === 'Contenedor')
            return { isValid: false, error: "Se requiere volver a seleccionar el tipo de servicio" };
        const selectedService = this.serviceTypes.find(type => type.name === cargoType);
        if (selectedService && !selectedService.types.includes(serviceType))
            return { isValid: false, error: "Se requiere volver a seleccionar el tipo de servicio" };
        return { isValid: true, error: '' };
    }

    public isRequiredContainerSize(cargo?: Cargo): boolean {
        const tripType = this.utils.getNestedValue(cargo ? cargo : this.cargo, 'cargoModel.tripType.name');
        const cargoType = this.utils.getNestedValue(cargo ? cargo : this.cargo, 'cargoModel.cargoType.name');
        return tripType && tripType !== 'Última milla' && cargoType === 'Contenedor';
    }

    public isValidContainerSize(): { isValid: boolean, error: string } {
        const containerSize = this.utils.getNestedValue(this.cargo, 'cargoModel.cargoType.containerMeasure.size');
        if (this.isRequiredContainerSize() && !containerSize)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'las medidas del contenedor') };
        return { isValid: true, error: '' };
    }

    public isContainerService(): boolean {
        const cargoType = this.utils.getNestedValue(this.cargo, 'cargoModel.cargoType.name');
        return cargoType === 'Contenedor';
    }

    public isValidLoadCharacteristics(): { isValid: boolean, error: string } {
        if (this.isEscortService()) return { isValid: true, error: '' };

        const isValidRiskProfile = this.isValidRiskProfile();
        if (!isValidRiskProfile.isValid) return isValidRiskProfile;
        const isValidContainerInfo = this.isValidContainerInfo();
        if (!isValidContainerInfo.isValid) return isValidContainerInfo;
        const isValidAmount = this.isValidAmount();
        if (!isValidAmount.isValid) return isValidAmount;
        const isValidTotalMeasurement = this.isValidTotalMeasurement();
        if (!isValidTotalMeasurement.isValid) return isValidTotalMeasurement;
        const isValidQuantity = this.isValidQuantity();
        if (!isValidQuantity.isValid) return isValidQuantity;
        const isValidTotalWeight = this.isValidTotalWeight();
        if (!isValidTotalWeight.isValid) return isValidTotalWeight;
        const isValidUnit = this.isValidUnit();
        if (!isValidUnit.isValid) return isValidUnit;
        const isValidType = this.isValidType();
        if (!isValidType.isValid) return isValidType;

        return { isValid: true, error: '' };
    }

    public isValidRiskProfile(): { isValid: boolean, error: string } {
        const riskProfile = this.utils.getNestedValue(this.cargo, 'riskProfile');
        if (!this.isEscortService() && (!riskProfile || !riskProfile.id || !riskProfile.name))
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el perfil de riesgo') };
        return { isValid: true, error: '' };
    }

    public isRequiredContainerInfo(): boolean {
        return this.isContainerService();
    }

    public isValidContainerInfo(): { isValid: boolean, error: string } {
        const container = this.utils.getNestedValue(this.cargo, 'container');
        const patternContainer = new RegExp(this.patterns.CONTAINER_FORMAT_FULL.source);
        if (this.isRequiredContainerInfo() && container && !patternContainer.test(container))
            return { isValid: false, error: this.utils.giveMessageError('INVALID_CONTAINER_GENERAL') };
        return { isValid: true, error: '' };
    }

    public isRequiredMerchandiseCharacteristics(): boolean {
        return !this.isEscortService();
    }

    public isValidAmount(): { isValid: boolean, error: string } {
        if (!this.isRequiredMerchandiseCharacteristics()) return { isValid: true, error: '' };
        const amount = this.utils.getNestedValue(this.cargo, 'cargoFeature.cargoMeasure.amount');
        if (!this.utils.isDefined(amount) || typeof amount !== 'number')
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el valor declarado') };
        if (amount < 0)
            return { isValid: false, error: this.utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'valor declarado', 0) };
        else if (amount && this.isEmptyContainer)
            return { isValid: false, error: CargoMessages.AMOUNT_TO_EMPTY_CONTAINERS };
        return { isValid: true, error: '' };
    }

    public isValidTotalMeasurement(): { isValid: boolean, error: string } {
        if (!this.isRequiredMerchandiseCharacteristics()) return { isValid: true, error: '' };
        const totalMeasurement = this.utils.getNestedValue(this.cargo, 'cargoFeature.cargoMeasure.totalMeasurement');
        if (!this.utils.isDefined(totalMeasurement) || typeof totalMeasurement !== 'number')
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'la medida total') };
        return { isValid: true, error: '' };
    }

    public isValidQuantity(): { isValid: boolean, error: string } {
        if (!this.isRequiredMerchandiseCharacteristics()) return { isValid: true, error: '' };
        const quantity = this.utils.getNestedValue(this.cargo, 'cargoFeature.cargoMeasure.quantity');
        if (!this.utils.isDefined(quantity) || typeof quantity !== 'number')
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'la cantidad de unidades') };
        if (quantity < 1 && !this.isEmptyContainer)
            return { isValid: false, error: this.utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'cantidad de unidades', 1) };
        return { isValid: true, error: '' };
    }

    public isValidTotalWeight(): { isValid: boolean, error: string } {
        if (!this.isRequiredMerchandiseCharacteristics()) return { isValid: true, error: '' };
        const totalWeigth = this.utils.getNestedValue(this.cargo, 'cargoFeature.cargoMeasure.totalWeigth');
        if (!this.utils.isDefined(totalWeigth) || typeof totalWeigth !== 'number')
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el peso total') };
        if (totalWeigth < 0)
            return { isValid: false, error: this.utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'peso total', 0) };
        if (totalWeigth > AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG)
            return { isValid: false, error: this.utils.giveMessageError('MAX_VALUE_EXCEED', 'peso total', AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG) };
        if (!totalWeigth && !this.isEmptyContainer)
            return { isValid: false, error: CargoMessages.NO_TOTAL_WEIGHT };
        if (totalWeigth && this.isEmptyContainer)
            return { isValid: false, error: CargoMessages.TOTAL_WEIGHT_TO_EMPTY_CONTAINERS };
        const unit = this.utils.getNestedValue(this.cargo, 'cargoFeature.cargoMeasure.unit');
        if ((unit === "1" && totalWeigth > AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG) || (unit === "2" && totalWeigth > AmountsCargoEnum.MAX_WEIGHT_ALLOWED_GL))
            return { isValid: false, error: CargoMessages.OVERWEIGHT + (unit === "1" ? AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG : AmountsCargoEnum.MAX_WEIGHT_ALLOWED_GL) };
        return { isValid: true, error: '' };
    }

    public isValidUnit(): { isValid: boolean, error: string } {
        if (!this.isRequiredMerchandiseCharacteristics()) return { isValid: true, error: '' };
        const unit = this.utils.getNestedValue(this.cargo, 'cargoFeature.cargoMeasure.unit');
        if (!unit)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'la unidad de medida') };
        return { isValid: true, error: '' };
    }
    public isValidType(): { isValid: boolean, error: string } {
        if (!this.isRequiredMerchandiseCharacteristics()) return { isValid: true, error: '' };
        const type = this.utils.getNestedValue(this.cargo, 'cargoFeature.cargoMeasure.type');
        if (!type)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el tipo de almacenamiento') };
        return { isValid: true, error: '' };
    }

    get hasPermissionNoMinimumUtility(): boolean {
        const tripType = this.utils.getNestedValue(this.cargo, 'cargoFeature.tripType.name');
        const serviceType = this.utils.getNestedValue(this.cargo, 'cargoFeature.serviceType.name');
        const permission = this.permissionRole.hasPermission(
            this.permission.cargo.module,
            this.permission.cargo.createCargosWithoutMinimumUtility
        );
        const anotherPermission = this.permissionRole.hasPermission(
            this.permission.cargo.module,
            this.permission.cargo.createCargoWithRateOrCostZero
        );
        return tripType === "Urbana" && serviceType.includes("Contenedor vacío") && (permission || anotherPermission);
    }

    public isValidServiceValue(): { isValid: boolean, error: string } {
        const isValidServiceRate = this.isValidServiceRate();
        if (!isValidServiceRate.isValid) return isValidServiceRate;
        const isValidFreightCost = this.isValidFreightCost();
        if (!isValidFreightCost.isValid) return isValidFreightCost;
        const isValidAdvancePercentage = this.isValidAdvancePercentage();
        if (!isValidAdvancePercentage.isValid) return isValidAdvancePercentage;
        const isValidPaymentTime = this.isValidPaymentTime();
        if (!isValidPaymentTime.isValid) return isValidPaymentTime;
        return { isValid: true, error: '' };
    }

    public isValidServiceRate(): { isValid: boolean, error: string } {
        const rate = this.utils.getNestedValue(this.cargo, 'shippingCost.rate');
        if (!this.utils.isDefined(rate) || typeof rate !== 'number')
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'la tarifa') };
        const chargeOwnFleet = this.authService.getCompany().chargeOwnFleet;
        const minRate = this.hasPermissionNoMinimumUtility || chargeOwnFleet ? 0 : 1;
        if (rate < minRate) return { isValid: false, error: this.utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'tarifa', minRate) };
        return { isValid: true, error: '' };
    }

    public isValidFreightCost(): { isValid: boolean, error: string } {
        const freightCost = this.utils.getNestedValue(this.cargo, 'shippingCost.freightCost');
        if (!this.utils.isDefined(freightCost) || typeof freightCost !== 'number')
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el flete') };
        const chargeOwnFleet = this.authService.getCompany().chargeOwnFleet;
        const minFreightCost = this.hasPermissionNoMinimumUtility || chargeOwnFleet ? 0 : 1;
        if (freightCost < minFreightCost) return { isValid: false, error: this.utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'flete', minFreightCost) };
        const minUtility = this.companyManager.getUtilityByCompanyId(this.cargo.idCompany);
        if (this.utilityCargo < minUtility && !this.hasPermissionNoMinimumUtility)
            return { isValid: false, error: Fmt.string(FormMessages.MINIMUN_UTILITY_NOT_REACHED, minUtility) };

        return { isValid: true, error: '' };
    }

    public isValidAdvancePercentage(): { isValid: boolean, error: string } {
        const advancePercentage = this.utils.getNestedValue(this.cargo, 'shippingCost.advancePercentage');
        if (!this.utils.isDefined(advancePercentage) || typeof advancePercentage !== 'number')
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el porcentaje de anticipo') };
        if (advancePercentage < 0)
            return { isValid: false, error: this.utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'porcentaje de anticipo', 0) };
        const maxAdvancePercentage = Math.min(this.companyManager.getAdvancePercentageByCompanyId(this.cargo.idCompany), 100);
        if (advancePercentage > maxAdvancePercentage)
            return { isValid: false, error: this.utils.giveMessageError('MAX_VALUE_EXCEED', 'porcentaje de anticipo', maxAdvancePercentage) };
        return { isValid: true, error: '' };
    }

    public isValidPaymentTime(): { isValid: boolean, error: string } {
        const paymentTime = this.utils.getNestedValue(this.cargo, 'shippingCost.paymentTime');
        if (!this.utils.isDefined(paymentTime) || typeof paymentTime !== 'number')
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el tiempo de pago') };
        if (paymentTime <= 0)
            return { isValid: false, error: this.utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'tiempo de pago', 1) };
        return { isValid: true, error: '' };
    }

    public isRequiredConsignmentsService(): boolean {
        const ministry = this.utils.getNestedValue(this.cargo, 'ministry');
        return !this.isEscortService() && !!ministry;
    }

    public isValidConsignmentsService(): { isValid: boolean, error: string } {
        if (!this.isRequiredConsignmentsService()) return { isValid: true, error: '' };
        const isValidManifestType = this.isValidManifestType();
        if (!isValidManifestType.isValid) return isValidManifestType;
        const isValidOperationType = this.isValidOperationType();
        if (!isValidOperationType.isValid) return isValidOperationType;
        const isValidConsignments = this.isValidConsignments();
        if (!isValidConsignments.isValid) return isValidConsignments;
        return { isValid: true, error: '' };
    }

    public isValidManifestType(): { isValid: boolean, error: string } {
        const manifestType = this.utils.getNestedValue(this.cargo, 'manifestType');
        if (!manifestType || !manifestType.code || !manifestType.description)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el tipo de manifiesto') };
        if (!this.manifestTypes.find(type => type.code === manifestType.code))
            return { isValid: false, error: "El tipo de manifiesto no es valido" };
        return { isValid: true, error: '' };
    }

    public isValidOperationType(): { isValid: boolean, error: string } {
        const operationType = this.utils.getNestedValue(this.cargo, 'cargoModel.operationType');
        if (!operationType || !operationType.code || !operationType.description)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'el tipo de operacion') };
        if (!this.operationTypes.find(type => type.code === operationType.code))
            return { isValid: false, error: "El tipo de operacion no es valido" };
        return { isValid: true, error: '' };
    }

    public isValidConsignments(): { isValid: boolean, error: string } {
        if (!this.isValidManifestType().isValid) return { isValid: false, error: 'Se requiere diligenciar el tipo de manifiesto' };
        //Validaciones por tipo de manifiesto
        const manifestType = this.utils.getNestedValue(this.cargo, 'manifestType');
        const origin = this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.origin');
        const destinations: Destination[] = this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.destination');
        const destinationMunicipalityCodes = destinations.map(destination => destination && destination.municipalityCode ? destination.municipalityCode : null);

        if (manifestType && manifestType.code === 'M') { //Viaje multiparada
            const everyDestinationHasConsignment = destinationMunicipalityCodes.every(destinationMunicipalityCode => {
                return destinationMunicipalityCode && origin && origin.municipalityCode && destinationMunicipalityCode !== origin.municipalityCode &&
                    this.cargoConsignments.some(consignment => consignment && consignment.recipient && consignment.recipient.municipalityCode === destinationMunicipalityCode);
            });
            if (!everyDestinationHasConsignment) return { isValid: false, error: 'Debe diligenciar al menos una remesa por cada destino distinto al origen' };

            const someConsignmentHasOriginMunicipalityCode = this.cargoConsignments.some(consignment => consignment && consignment.recipient && origin && consignment.recipient.municipalityCode === origin.municipalityCode);
            if (someConsignmentHasOriginMunicipalityCode) return { isValid: false, error: 'No puede tener una remesa entre la misma ciudad de origen y destino para manifestos multiparada' };

            const firstMunicipalityCode = this.utils.getNestedValue(this.cargoConsignments[0], 'recipient.municipalityCode');
            const everyConsignmentHasSameMunicipalityCode = this.cargoConsignments.every((consignment, i) => i && consignment && consignment.recipient && consignment.recipient.municipalityCode === firstMunicipalityCode);
            if (everyConsignmentHasSameMunicipalityCode) return { isValid: false, error: 'Para manifestos multiparada, las remesas deben tener distinta ciudad de destino' };
        }
        if (manifestType && manifestType.code === 'G' && !this.cargoConsignments.length)  // Viaje general
            return { isValid: false, error: 'Debe diligenciar al menos una remesa' };

        //Validaciones por campos
        for (const [index, consignment] of this.cargoConsignments.entries()) {
            for (const field of this.consignmentFieldsToCheck) {
                const value = this.utils.getNestedValue(consignment, field.field);
                if (value !== 0 && !value)
                    return { isValid: false, error: `Se requiere diligenciar el campo ${field.alias} de la remesa ${index + 1}` };
            }
            if (consignment.errorCreation || consignment.errorFinalFulfillment || consignment.errorInitialFulfillment)
                return { isValid: false, error: `Ocurrió un error al generar la remesa ${index + 1}` };
        }

        //Validaciones por contenido de las remesas
        const cargoOwner: ThirdParty = this.utils.getNestedValue(this.cargo, 'cargoOwner');
        if (cargoOwner) {
            const withoutCargoOwnerIndex = this.cargoConsignments.findIndex(consignment => consignment && !consignment.cargoOwner ||
                !consignment.cargoOwner.documentType || !consignment.cargoOwner.documentNumber || !consignment.cargoOwner.name || !consignment.cargoOwner.branchOffice);
            if (withoutCargoOwnerIndex !== -1) return { isValid: false, error: `Se requiere completar la información del propietario del servicio y volver a guardar la información de las remesas` };
            const diffCargoOwnerIndex = this.cargoConsignments.findIndex(consignment =>
                consignment && consignment.cargoOwner && (consignment.cargoOwner.documentType !== cargoOwner.documentType ||
                    consignment.cargoOwner.documentNumber !== cargoOwner.documentNumber || consignment.cargoOwner.name !== cargoOwner.name ||
                    consignment.cargoOwner.branchOffice !== cargoOwner.branchOffice));
            if (diffCargoOwnerIndex !== -1) return { isValid: false, error: `Se requiere volver a guardar la información de las remesas` };
        }
        if (origin && origin.name && origin.municipalityCode) {
            const consignment = this.cargoConsignments.findIndex(consignment => consignment && consignment.sender && (consignment.sender.municipalityCode !== origin.municipalityCode || consignment.sender.city !== origin.name));
            if (consignment !== -1) return { isValid: false, error: `Se requiere volver a seleccionar la ciudad de origen de la remesa ${consignment + 1}` };
        }
        if (origin && origin.addresses && origin.addresses.length) {
            const diffOriginAddressIndex = this.cargoConsignments.findIndex(consignment => consignment && consignment.sender && origin.addresses.every((address, i) =>
                address && address.location && (consignment.sender.address !== address.address || consignment.sender.location.lat !== address.location.lat || consignment.sender.location.lng !== address.location.lng)));
            if (diffOriginAddressIndex !== -1) return { isValid: false, error: `Se requiere volver a seleccionar la dirección de origen de la remesa ${diffOriginAddressIndex + 1}` };

            const diffOriginAddressIdIndex = this.cargoConsignments.findIndex(consignment => consignment && consignment.sender && origin.addresses.some((address, i) =>
                address && address.location && consignment.sender.address === address.address && consignment.sender.location.lat === address.location.lat && consignment.sender.location.lng === address.location.lng && consignment.sender.addressId !== i));
            if (diffOriginAddressIdIndex !== -1) return { isValid: false, error: `Se requiere seleccionar nuevamente la dirección de origen de la remesa ${diffOriginAddressIdIndex + 1}` };
        }
        if (destinations && destinations.length) {
            const consignment = this.cargoConsignments.findIndex(consignment => consignment && consignment.recipient && destinations.every((destination, i) =>
                destination && (consignment.recipient.municipalityCode !== destination.municipalityCode || consignment.recipient.city !== destination.name)));
            if (consignment !== -1) return { isValid: false, error: `Se requiere volver a seleccionar la ciudad de destino de la remesa ${consignment + 1}` };

            const diffDestinationIndex = this.cargoConsignments.findIndex(consignment => consignment && consignment.recipient && destinations.some((destination, i) =>
                destination && consignment.recipient.municipalityCode === destination.municipalityCode && consignment.recipient.city === destination.name && consignment.recipient.cityId !== i));
            if (diffDestinationIndex !== -1) return { isValid: false, error: `Se requiere seleccionar nuevamente la ciudad de destino de la remesa ${diffDestinationIndex + 1}` };

            const diffDestinationAddressIndex = this.cargoConsignments.findIndex(consignment => consignment && consignment.recipient && destinations.every((destination, i) =>
                destination && destination.addresses && destination.addresses.every((address, j) =>
                    address && address.location && (consignment.recipient.address !== address.address || consignment.recipient.location.lat !== address.location.lat || consignment.recipient.location.lng !== address.location.lng))))// && consignment.recipient.addressId === j));
            if (diffDestinationAddressIndex !== -1) return { isValid: false, error: `Se requiere volver a seleccionar la dirección de destino de la remesa ${diffDestinationAddressIndex + 1}` };

            const diffDestinationAddressIdIndex = this.cargoConsignments.findIndex(consignment => consignment && consignment.recipient && destinations.some((destination, i) =>
                destination && destination.addresses && destination.addresses.some((address, j) =>
                    address && address.location && consignment.recipient.address === address.address && consignment.recipient.location.lat === address.location.lat && consignment.recipient.location.lng === address.location.lng && consignment.recipient.addressId !== j)));
            if (diffDestinationAddressIdIndex !== -1) return { isValid: false, error: `Se requiere volver a seleccionar la dirección de destino de la remesa ${diffDestinationAddressIdIndex + 1}` };
        }
        //Validaciones por igualdad de información de remesas
        const hasSameOriginAndDestination = this.cargoConsignments.some((consignment, i) => consignment && consignment.sender && consignment.recipient && this.cargoConsignments.some(
            (consignment2, j) => consignment2 && consignment2.sender && consignment2.recipient && consignment.sender.address === consignment2.sender.address &&
                consignment.sender.city === consignment2.sender.city && consignment.recipient.address === consignment2.recipient.address && consignment.recipient.city === consignment2.recipient.city && i !== j));
        if (hasSameOriginAndDestination) return { isValid: false, error: 'No puede tener dos remesas con la misma información de origen y destino' };

        const hasSameDateAndHour = this.cargoConsignments.findIndex(consignment =>
            consignment && consignment.load && consignment.unload && consignment.load.date === consignment.unload.date && consignment.load.appointmentTime === consignment.unload.appointmentTime);
        if (hasSameDateAndHour !== -1) return { isValid: false, error: `La remesa ${hasSameDateAndHour + 1} tiene la misma fecha y hora de cargue y descargue` };
        return { isValid: true, error: '' };
    }

    public isValidServicePublication(): { isValid: boolean, error: string } {
        const servicePublication = this.utils.getNestedValue(this.cargo, 'zone');
        if (!servicePublication)
            return { isValid: false, error: this.utils.giveMessageError('MISSING_FIELD_ALTERNATIVE', 'la zona de publicacion') };

        return { isValid: true, error: '' };
    }



    timeFormat(time: string): { time: string, timeType: string } {
        if (!time) return { time: '00:01', timeType: 'AM' };
        if (time[0] === '0' && time[1] !== '0')
            return { time: time.slice(1), timeType: 'AM' };
        else if (time[0] === '1' && time[1] === '2')
            return { time: `00:${time.slice(3, 5)}`, timeType: 'PM' };
        else if ((time[0] === '1' && parseInt(time[1]) > 2) || (time[0] === '2' && parseInt(time[1]) < 2))
            return { time: DateFormatPipe.prototype.transform('01-01-1990 ' + time, 'time').slice(1).slice(0, 4), timeType: 'PM' };
        else if (time[0] === '2')
            return { time: DateFormatPipe.prototype.transform('01-01-1990 ' + time, 'time').slice(0, 5), timeType: 'PM' };
        return { time: time, timeType: 'AM' };
    }

    public async checkTripTimes(cargo: Cargo, validateOrigin = true, validateDestination = true): Promise<{ valid: boolean, error: string }> {
        this.spinner.show();
        let validCargo;
        try { validCargo = await this.cargoManager.checkTripTimes(cargo, validateOrigin, validateDestination).toPromise() }
        catch (error) {
            this.spinner.hide();
            return { valid: false, error };
        }
        this.spinner.hide();
        if (!validCargo)
            return { valid: false, error: 'Ocurrió un error al validar el servicio' };
        if (!validCargo.valid) {
            let message = 'Los tiempos estimados entre las direcciones son imposibles de cumplir.';
            if (validCargo && validCargo.min && validCargo.address) {
                message = `Para llegar a la dirección ${validCargo.address} se requieren al menos `;
                const duration = DateManager.durationFormat(validCargo.min, 'seconds');
                if (duration.hours) message += `${duration.hours} horas `;
                if (duration.minutes) message += `${duration.minutes} minutos `;
                if (duration.seconds) message += `${duration.seconds} segundos `;
            }
            return { valid: false, error: message };
        }
        return { valid: true, error: '' };
    }

    public async getRouteGoogleData(cargo: Cargo): Promise<{ distancy: number, estimatedTime: number }> {
        let success: { cargoDistancy; cargoEstimatedTime } = null;
        try { success = await this.googleService.getRouteDataCargo(cargo) }
        catch (error) { return { distancy: 0, estimatedTime: 0 } }
        return { distancy: success.cargoDistancy, estimatedTime: success.cargoEstimatedTime };
    }

    async updateService(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            this.spinner.show();
            const body = this.getBodyUpdateOnlyRequest();
            try { await this.updateOnlyRequest(body).toPromise() } catch (error) {
                this.spinner.hide();
                this.refreshCargo().then(reject).catch(reject);
                return;
            }
            this.spinner.hide();
            if (!this.isListeningChangesFromFirebase && this.cargo && this.cargo.consecutive)
                this.refreshCargo().then(resolve).catch(reject);
            else
                resolve(true);
        });
    }

    private getBodyUpdateOnlyRequest() {
        const cargo = this.form.value;
        //Update by trip type
        if (cargo.cargoModel.tripType.name === 'Última milla') {
            cargo.cargoModel.cargoType.name = "Carga suelta";
            const cargoType = this.utils.getNestedValue(cargo, 'cargoModel.cargoType.name');
            const serviceType = this.utils.getNestedValue(cargo, 'cargoModel.serviceType.name');
            const selectedService = this.serviceTypes.find(type => type.name === cargoType);
            if (selectedService && !selectedService.types.includes(serviceType))
                cargo.cargoModel.serviceType.name = "";
        }
        if (!this.isRequiredLetterRetirement(cargo)) cargo.cargoModel.tripType.letterRetirement = null;
        if (!this.isRequiredRegimeType(cargo)) cargo.cargoModel.tripType.regimeType = "";
        if (!this.isRequiredDateVesselArrival(cargo)) cargo.cargoModel.tripType.date = "";
        if (!this.isRequiredContainerSize(cargo)) cargo.cargoModel.cargoType.containerMeasure.size = null;
        if (!this.isContainerService())
            ['container', 'seal', 'containerExpirationDate', 'booking'].forEach(valueToDelete => cargo[valueToDelete] = null);

        //Update by vehicle type
        const lastVehicleType = this.utils.getNestedValue(this.cargo, 'cargoFeature.vehicleType.name');
        const lastBodyWorkType = this.utils.getNestedValue(this.cargo, 'cargoFeature.vehicleType.bodyWorkType.name');
        const vehicleType = this.utils.getNestedValue(cargo, 'cargoFeature.vehicleType.name');
        const bodyWorkType = this.utils.getNestedValue(cargo, 'cargoFeature.vehicleType.bodyWorkType.name');
        if ((lastVehicleType && vehicleType && lastVehicleType !== vehicleType) || (lastBodyWorkType && bodyWorkType && lastBodyWorkType !== bodyWorkType)) {
            cargo.licensePlate = '';
            cargo.driver = '';
            cargo.secondDriver = '';
            cargo.assignedDriver = false;
            cargo.confirmedDriver = false;
            cargo.assignedVehicleFingerPrint = this.serviceResources.cargoMock.assignedVehicleFingerPrint;
            cargo.confirmedDriverFingerprint = this.serviceResources.cargoMock.confirmedDriverFingerprint;
        } else if (cargo.licensePlate && cargo.driver) cargo.assignedDriver = true;
        if (!this.cargoManager.requestEnvironmentType(vehicleType, bodyWorkType))
            cargo.cargoFeature.vehicleType.quality = '';

        //Update by upload download
        cargo.cargoFeature.uploadDownload.origin.isInternational = cargo.cargoFeature.uploadDownload.origin.country.code !== 'CO';
        cargo.cargoFeature.uploadDownload.origin.addresses.forEach((address: AddressCargo, i: number) => {
            address.id = i.toString();
            delete address.thirdPartyConsignment;
            delete address.timeType;
            delete address.timePact;
            delete address.quantity;
            delete address.createGuide;
        });
        cargo.cargoFeature.uploadDownload.destination.forEach((destination: Destination, i: number) => {
            destination.id = i.toString();
            destination.isInternational = destination.country.code !== 'CO';
            destination.addresses.forEach((address: AddressCargo, j: number) => {
                address.id = j.toString();
                delete address.thirdPartyConsignment;
                delete address.timeType;
                delete address.timePact;
                delete address.quantity;
                if (address.guidesRecipients && address.guidesRecipients.length) {
                    address.numberOfGuides = address.guidesRecipients.length;
                    address.createGuide = true;
                    address.guidesRecipients.map(guide => {
                        if (!guide.name && address.thirdPartyConsignment && address.thirdPartyConsignment.name)
                            guide.name = address.thirdPartyConsignment.name;
                        if (!guide.phone && address.cellphone)
                            guide.phone = address.cellphone;
                        if (!guide.email && address.email)
                            guide.email = address.email;
                    })
                } else {
                    address.numberOfGuides = 0;
                    address.createGuide = false;
                }
                if (address.consignments && address.consignments.length) {
                    if (!cargo.ministry) delete address.consignments;
                    else address.consignments = address.consignments.filter(consignmentId =>
                        this.cargoConsignments.find(consignment => consignment.id === consignmentId));
                }
                delete address.cargoMeasure;
            });
            delete destination.reteica;
        });
        cargo.cargoFeature.uploadDownload['destinationName'] = cargo.cargoFeature.uploadDownload.destination.map(destination => destination.name);

        if (!cargo.ministry) {
            delete cargo.cargoOwner;
            delete cargo.manifestType
        } else {
            delete cargo.cargoOwner.address;
            delete cargo.cargoOwner.location;
        }
        cargo.holderCompany = this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId
            ? this.authService.getUserSession().clientCompanyId
            : null;
        cargo.shippingCost.freightCost = Number(cargo.shippingCost.freightCost);
        cargo.shippingCost.totalCost = Number(cargo.shippingCost.totalCost);
        cargo.shippingCost.rate = Number(cargo.shippingCost.rate);
        delete cargo.cargoFeature.uploadDownload.origin.isInternational;
        cargo.cargoFeature.uploadDownload.destination.forEach(destination => {
            delete destination.isInternational;
        })
        delete cargo.quantity;
        delete cargo.businessName;
        delete cargo.cargoFeature.productType.cargoNature;
        delete cargo.cargoFeature.productType.cargoNatureId;
        delete cargo.cargoFeature.uploadDownload.origin.reteica;

        return cargo;
    }



    private updateOnlyRequest(body: Cargo) {
        return this.http.put(
            environment.urlServerTeclogi + this.endpointResources.urlUpdateOnlyRequest,
            [body]
        )
    }

    private async refreshCargo(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            this.spinner.show();
            let cargo: Cargo;
            try { cargo = await this.cargoDetailService.detailCargoByConsecutive(this.cargo.consecutive.toString()).toPromise() } catch (error) { }
            this.spinner.hide();
            if (!cargo) return reject(false);
            this.patchForm(cargo, false);
            await this.setCargoConsignments();
            resolve(true);
        });
    }

    async setCargoConsignments() {
        if (!this.cargo || !this.cargo.ministry) {
            this.cargoConsignments = [];
            this.consignmentsObservable.next(this.cargoConsignments);
            return;
        }
        let consignments: ConsignmentCargo[] = [];
        try { consignments = await this.cargoDetailService.getCargoConsignments(this.cargo.id, 'Created').toPromise() } catch (error) { }
        this.cargoConsignments = consignments && consignments.length ? consignments : [];
        this.consignmentsObservable.next(this.cargoConsignments);
    }
    async deleteAllCargoConsignments(isNational: boolean): Promise<{ success: boolean, error: string }> {
        if (!this.cargoConsignments || !this.cargoConsignments.length) return;
        const promises = [];
        this.cargoConsignments.forEach(consignment => {
            promises.push(this.cargoDetailService.deleteConsignment(consignment.documentId, isNational).toPromise());
        });
        try { await Promise.all(promises) } catch (error) { return { success: false, error: 'Ocurrió un error al eliminar las remesas' } }
        return { success: true, error: '' };
    }
    async createAllCargoConsignments(consignments: ConsignmentCargo[]): Promise<{ success: boolean, error: string }> {
        try { await this.cargoDetailService.createConsignment(consignments).toPromise() }
        catch (error) { return { success: false, error: 'Ocurrió un error al crear las remesas' } }
        return { success: true, error: '' };
    }

    /**
     * @description Gets available label tags
     * @returns Observable with tag list
     */
    public getLabelTags(): Observable<Tag[]> {
        return this.http.get<any>(
            `${environment.urlServerTeclogi}${this.endpointResources.labelTags}`
        ).pipe(
            map(response => response.catalog)
        );
    }

    public isEscortService(): boolean {
        const serviceType: ServiceType = this.utils.getNestedValue(this.cargo, 'cargoModel.serviceType');
        return serviceType && serviceType.id === 'escortServices';
    }

    public isImportOrExport(cargo?: Cargo): boolean {
        const tripType = this.utils.getNestedValue(cargo ? cargo : this.cargo, 'cargoModel.tripType.name');
        if (!tripType) return false;
        return [TRIP_TYPES.EXPORT, TRIP_TYPES.IMPORT].includes(tripType as TRIP_TYPES);
    }

    get isEmptyContainer(): boolean {
        const serviceType = this.utils.getNestedValue(this.cargo, 'cargoModel.serviceType.name');
        return serviceType && serviceType.includes("Contenedor vacío");
    }

    private get utilityCargo() {
        const rate = this.utils.getNestedValue(this.cargo, 'shippingCost.rate') ? this.cargo.shippingCost.rate : 0;
        const totalCost = this.utils.getNestedValue(this.cargo, 'shippingCost.totalCost') ? this.cargo.shippingCost.totalCost : 0;
        if (!rate) return 0;
        return ((rate - totalCost) / rate) * 100;
    }

    private getTimePactList(): { hours: number, minutes: number, description: string }[] {
        const list = [];
        for (let hours = 0; hours < 24; hours++) {
            for (let minutes = 0; minutes < 60; minutes += 5) {
                if (hours || minutes)
                    list.push({ hours: hours, minutes: minutes, description: `${hours ? hours + ' hr ' : ''}${minutes ? minutes + ' min' : ''}` });
            }
        }
        return list;

    }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';
import { Tag } from 'src/app/core/interfaces/tag';
import { MatSelectChange, MatButtonToggleChange } from '@angular/material';
import { Cargo, CargoRequest } from 'src/app/core/interfaces/cargo';
import { Permission } from 'src/app/core/resources/permission';
import { MenuServiceService } from '../../menu-service/menu-service.service';
import { Utils } from 'src/app/core/resources/utils';
import { Router } from '@angular/router';
import { Company } from 'src/app/core/interfaces/company';
import { Subscription } from 'rxjs';
import { ThirdParty, ThirdPartyAddress } from 'src/app/core/interfaces/thirdParty';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { ServiceType } from 'src/app/core/interfaces/serviceType';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ServiceResources } from '../../resources/services';
import { CargoResources } from 'src/app/modules/cargo/manual-creation-cargo/resources/cargo';
import { Fmt } from 'src/app/core/messages/fmt';
import { ConsignmentCargo } from 'src/app/core/interfaces/consignmentCargo';
import { NgxSpinnerService } from 'ngx-spinner';
//TODO: Documentar el codigo
@Component({
  selector: 'app-contractor-trip-info',
  templateUrl: './contractor-trip-info.component.html',
  styleUrls: ['./contractor-trip-info.component.scss']
})
export class ContractorTripInfoComponent implements OnInit {
  permission = Permission;
  //Company
  companyControl = new FormControl('', [Validators.required]);
  companyOptions: OptionsAutocomplete = {
    title: 'Nombre',
    appearance: 'outline'
  };
  companyValidate = '';
  //Cargo owner
  cargoOwnerControl = new FormControl('', [Validators.required]);
  cargoOwnerOptions: OptionsAutocomplete = {
    title: 'Contratante',
    appearance: 'outline'
  };
  cargoOwnerValidate = '';
  //Cargo owner address
  cargoOwnerAddressControl = new FormControl('', [Validators.required]);
  cargoOwnerAddressValidate = '';
  //Tags
  tags: Tag[] = [];
  tagsSaved: string[] = [];
  tagControl: FormControl = new FormControl('');
  //Trip types
  tripTypeControl: FormControl = new FormControl('', [Validators.required]);
  readonly tripTypes: { label: string, name: TRIP_TYPES }[] = [
    { label: 'Importación', name: TRIP_TYPES.IMPORT },
    { label: 'Exportación', name: TRIP_TYPES.EXPORT },
    { label: 'Internacional', name: TRIP_TYPES.INTERNATIONAL },
    { label: 'Nacional', name: TRIP_TYPES.NATIONAL },
    { label: 'Urbano', name: TRIP_TYPES.URBAN },
    { label: 'Último milla/ Paqueteo urbano', name: TRIP_TYPES.LAST_MILE }
  ];
  //Ministry
  ministryControl: FormControl = new FormControl(!this.menuService.isEscortService(), [Validators.required]);
  numberDocumentSenderControl: FormControl = new FormControl('');
  //Steps routes
  stepsRoutes: string[] = Object.keys(this.serviceResources.steps);
  formSubscription: Subscription;
  constructor(
    public utils: Utils,
    public menuService: MenuServiceService,
    private router: Router,
    private permissionRole: PermissionRole,
    private snackBarService: SnackBarService,
    private serviceResources: ServiceResources,
    private cargoResources: CargoResources,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.setLocalValues();
    this.setSubscription();
    this.checkPermissionsToEdit();
    this.getTags();
  }

  private setLocalValues() {
    const idCompany = this.menuService.form.get('idCompany').value;
    this.companyOptions = { ...this.companyOptions, initialNit: idCompany ? idCompany : '' };
    const documentNumber = this.utils.getNestedValue(this.menuService.form.value, 'cargoOwner.documentNumber');
    this.cargoOwnerOptions = { ...this.cargoOwnerOptions, initialValue: documentNumber ? documentNumber : '' };
    const tripType = this.utils.getNestedValue(this.menuService.form.value, 'cargoModel.tripType.name');
    this.tripTypeControl.setValue(tripType ? tripType : '');
    const ministry = this.utils.getNestedValue(this.menuService.form.value, 'ministry');
    this.ministryControl.setValue(this.utils.isDefined(ministry) ? ministry : !this.menuService.isEscortService());
    const labels = this.utils.getNestedValue(this.menuService.form.value, 'labels');
    this.tagsSaved = this.utils.isDefined(labels) ? labels : [];
    const numberDocumentSender = this.utils.getNestedValue(this.menuService.form.value, 'numberDocumentSender');
    this.numberDocumentSenderControl.setValue(this.utils.isDefined(numberDocumentSender) ? numberDocumentSender : '');
  }

  private setSubscription() {
    this.formSubscription = this.menuService.formObservable.subscribe((cargo) => {
      this.setLocalValues();
    });
  }

  private checkPermissionsToEdit(): void {
    if (!this.canEditGeneralData) {
      this.tripTypeControl.disable();
      this.ministryControl.disable();
      this.cargoOwnerControl.disable();
      this.cargoOwnerValidate = 'disable';
      this.cargoOwnerAddressControl.disable();
      this.cargoOwnerAddressValidate = 'disable';
      this.companyControl.disable();
      this.companyValidate = 'disable';
      this.numberDocumentSenderControl.disable();
    }
    if (!this.canEditTags)
      this.tagControl.disable();
  }

  private getTags(): void {
    this.menuService.getLabelTags().subscribe(
      (success) => this.tags = success && success.length ? success : [],
      () => this.tags = []
    );
  }



  selectTag(event: MatSelectChange): void {
    const selectedTag: string = event.value;
    this.tagsSaved.push(selectedTag);
    this.tagControl.setValue(null);
  }

  clearTag(index: number): void {
    this.tagsSaved.splice(index, 1);
  }

  get showMinistrySelector(): boolean {
    const hasPermission = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.changeManifestRequirement);
    const isEscortService = this.menuService.isEscortService();
    return hasPermission && !isEscortService;
  }

  get canSeeGeneralData(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.allowToEditGeneralDataModule)
  }

  get canEditGeneralData(): boolean {
    return this.canSeeGeneralData && this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editCargo)
  }

  get canSeeTags(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.showEditTagsModule)
  }

  get canEditTags(): boolean {
    return this.canSeeTags && this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editCargoTags)
  }

  saveAndContinue() {
    this.menuService.form.get('ministry').setValue(!this.menuService.isEscortService() ? this.ministryControl.value : false);
    this.menuService.form.get('numberDocumentSender').setValue(this.numberDocumentSenderControl.value);
    this.menuService.form.get('cargoModel.tripType.name').setValue(this.tripTypeControl.value);
    if (!this.menuService.form.get('ministry').value) {
      this.menuService.form.get('cargoOwner').patchValue(this.cargoResources.cargoMock.cargoOwner);
      this.menuService.form.get('idCompany').setValue(this.companyControl.value && this.companyControl.value.companyId ? this.companyControl.value.companyId : '');
    } else {
      this.menuService.form.get('cargoOwner.documentNumber').setValue(this.cargoOwnerControl.value && this.cargoOwnerControl.value.document ? this.cargoOwnerControl.value.document : '');
      this.menuService.form.get('cargoOwner.documentType').setValue(this.cargoOwnerControl.value && this.cargoOwnerControl.value.documentType ? this.cargoOwnerControl.value.documentType : '');
      this.menuService.form.get('cargoOwner.name').setValue(this.cargoOwnerControl.value && this.cargoOwnerControl.value.name ? this.cargoOwnerControl.value.name : '');
      this.menuService.form.get('idCompany').setValue(this.cargoOwnerControl.value && this.cargoOwnerControl.value.document ? this.cargoOwnerControl.value.document : '');
      this.menuService.form.get('cargoOwner.address').setValue(this.cargoOwnerAddressControl.value && this.cargoOwnerAddressControl.value.address ? this.cargoOwnerAddressControl.value.address.address : '');
      this.menuService.form.get('cargoOwner.branchOffice').setValue(this.cargoOwnerAddressControl.value && this.cargoOwnerAddressControl.value.address ? this.cargoOwnerAddressControl.value.address.id : '');
    }
    this.menuService.form.get('labels').clear();
    this.tagsSaved.forEach(tag => this.menuService.form.get('labels').value.push(tag));

    const oldConsignments: ConsignmentCargo[] = this.utils.clone(this.menuService.cargoConsignments);
    const oldCargo: Cargo = this.utils.clone(this.menuService.cargo);
    this.menuService.updateService().then(async () => {
      if (oldConsignments.length)
        await this.checkConsignmentsToChange(oldConsignments, oldCargo);
      this.snackBarService.openSnackBar("Servicio actualizado exitosamente");
      this.continueToNextStep();
    }).catch(() => this.snackBarService.openSnackBar('Ocurrió un error al actualizar el servicio', undefined, 'error'));
  }

  private async checkConsignmentsToChange(oldConsignments: ConsignmentCargo[], oldCargo: Cargo) {
    const oldCargoTripType = this.utils.getNestedValue(oldCargo, 'cargoModel.tripType.name');
    const newCargoTripType = this.utils.getNestedValue(this.menuService.form.value, 'cargoModel.tripType.name');
    const consignmentUrbanTripTypeNames = [TRIP_TYPES.URBAN, TRIP_TYPES.LAST_MILE];
    const hasToChangeConsignmentByTripType = oldCargoTripType && newCargoTripType && oldCargoTripType !== newCargoTripType &&
      ((consignmentUrbanTripTypeNames.includes(oldCargoTripType) && !consignmentUrbanTripTypeNames.includes(newCargoTripType)) ||
        (!consignmentUrbanTripTypeNames.includes(oldCargoTripType) && consignmentUrbanTripTypeNames.includes(newCargoTripType)));
    const hasToDeleteConsignmentByMinistry = oldCargo && oldCargo.ministry && !this.menuService.form.get('ministry').value;
    this.spinner.show();
    if (hasToChangeConsignmentByTripType || hasToDeleteConsignmentByMinistry) {
      const { success, error } = await this.menuService.deleteAllCargoConsignments(!consignmentUrbanTripTypeNames.includes(oldCargoTripType));
      if (success && hasToChangeConsignmentByTripType)
        await this.menuService.createAllCargoConsignments(oldConsignments);
      await this.menuService.setCargoConsignments();
    }
    this.spinner.hide();
  }

  continueToNextStep() {
    this.router.navigate([Fmt.string(this.serviceResources.steps[this.stepsRoutes[2]].url, this.menuService.cargo.consecutive)]);
  }

  ngOnDestroy() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }
}

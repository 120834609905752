<!-- Template -->
<div
  [ngClass]="{'cargo__options--ellipsis':type === 'ellipsis', 'cargo__options--button':type === 'button', 'cargo__options--text':type === 'text'}">
  <!-- ellipsis -->
  <i class="fas fa-ellipsis-v color-secondary-dark text-strong" mat-icon-button [matMenuTriggerFor]="menuOptionsCargo"
    *ngIf="type === 'ellipsis'"></i>
  <!-- button -->
  <button mat-raised-button [matMenuTriggerFor]="menuOptionsCargo" class="primary-secondary cargo__options--button"
    color="primary" *ngIf="type === 'button'">
    Opciones
  </button>
  <!-- Text -->
  <div mat-raised-button [matMenuTriggerFor]="menuOptionsCargo" class="primary-secondary" color="primary"
    *ngIf="type === 'text'">
    Opciones
  </div>
</div>
<!-- Options -->
<mat-menu #menuOptionsCargo="matMenu" class="cargo__options--menu">
  <button mat-menu-item (click)="openDetailCargo()"
    *ngIf="cargo && (cargo.state === cargoStateEnum.START_SERVICE||['ellipsis','text'].includes(type))">
    <i class="fas fa-eye"></i>
    Ver detalles del servicio
  </button>
  <button mat-menu-item (click)="openDialogNewAdvancePercentage()" permission-display [module]="permission.cargo.module"
    [functionality]="permission.cargo.editAdvance"
    *ngIf="![cargoStateEnum.DELETED,cargoStateEnum.EXPIRED].includes(cargo?.state) && (!cargo?.shippingCost?.advanceState || !haveAdvancePayments)">
    <i class="fas fa-edit"></i>
    Editar Anticipo
  </button>
  <button mat-menu-item (click)="openDialogChangeReteica()"
    *ngIf="![cargoStateEnum.DELETED,cargoStateEnum.EXPIRED].includes(cargo?.state)" permission-display
    [module]="permission.cargo.module" [functionality]="permission.cargo.changeRteica">
    <i class="fas fa-exchange-alt"></i>
    Modificar reteica
  </button>
  <button mat-menu-item (click)="getTracepoints()" permission-display [module]="permission.cargo.module"
    [functionality]="permission.cargo.showAnomaliesCargo"
    *ngIf="cargo?.state !== cargoStateEnum.EXPIRED && cargo?.lastPointLocation">
    <i class="fas fa-history"></i>
    Historial de novedades
  </button>
  <button mat-menu-item (click)="btnDeleteCarga()" permission-display [module]="permission.cargo.module"
    [functionality]="permission.cargo.removeCargo" *ngIf="cargo?.state !== cargoStateEnum.DELETED">
    <i class="fas fa-ban"></i>
    Anular Servicio
  </button>
  <!--
    Pending change to new approval process
    <button mat-menu-item (click)="openDialogApproveCargo(true)" *ngIf="showBtnApproveCargo" permission-display
    [module]="permission.payments.module" [functionality]="permission.payments.approvePayments">
    <i class="fas fa-thumbs-up"></i>
    Aprobar servicio
  </button>
  <button mat-menu-item (click)="openDialogApproveCargo(false)"
    *ngIf="(cargo?.approval === 'Pending approval'||cargo?.approval === 'Approved') && cargo.state == cargoStateEnum.END_SERVICE"
    permission-display [module]="permission.payments.module" [functionality]="permission.payments.approvePayments">
    <i class="fas fa-thumbs-down"></i>
    No aprobar servicio
  </button>
-->
  <button mat-menu-item (click)="openModalReturnApproveCargo()"
    *ngIf="(cargo?.approval === 'Approved' || cargo?.approval ==='Rejected') && cargo.state == cargoStateEnum.END_SERVICE"
    permission-display [module]="permission.payments.module" [functionality]="permission.payments.reverseApproval">
    <i class="fas fa-undo"></i>
    Reversar <span *ngIf="cargo?.approval ==='Rejected'">No</span> aprobación
  </button>
  <button mat-menu-item *ngIf="showOptionEditAndDisassociate" (click)="editBill()" permission-display
    [module]="permission.payments.module" [functionality]="permission.payments.charges">
    <i class="fas fa-file-invoice"></i>
    Modificar Factura
  </button>
  <button mat-menu-item *ngIf="showOptionEditAndDisassociate" (click)="disassociateBill()" permission-display
    [module]="permission.payments.module" [functionality]="permission.payments.charges">
    <i class="fas fa-file-invoice"></i>
    Desasociar Factura
  </button>
  <button mat-menu-item *ngIf="cargo?.shippingCost?.cashed && cargo?.shippingCost?.checked" permission-display
    [module]="permission.payments.module" [functionality]="permission.payments.charges" (click)="editIncome()">
    <i class="fas fa-file-invoice"></i>
    Modificar Comprobante
  </button>
  <button mat-menu-item *ngIf="cargo?.shippingCost?.cashed && cargo?.shippingCost?.checked" permission-display
    [module]="permission.payments.module" [functionality]="permission.payments.charges" (click)="disassociateIncome()">
    <i class="fas fa-file-invoice"></i>
    Desasociar Comprobante
  </button>
  <button mat-menu-item (click)="openModalAssignVehicle()"
    *ngIf="showAssignVehicleBtn && !isNegotiation && [cargoStateEnum.CREATED, cargoStateEnum.ACCEPTED].includes(cargo?.state)  && !cargo?.confirmedDriver && (diferenceInDays(true) >= 0)">
    <i class="fas fa-bus"></i>
    Asignar {{isEscortedService? 'escolta' : 'vehículo'}}
  </button>
  <button mat-menu-item (click)="showAssignedVehicleData()"
    *ngIf="authService.getCompany()?.companyId === environment.rootNit && !isNegotiation && ![cargoStateEnum.DELETED,cargoStateEnum.EXPIRED,cargoStateEnum.END_SERVICE].includes(cargo?.state) && cargo?.confirmedDriver && cargo?.licensePlate">
    <i class="fas fa-bus"></i>
    {{isEscortedService? 'Escolta' : 'Vehículo'}} Asignado {{cargo.licensePlate}}
  </button>
  <button mat-menu-item (click)="checkConfirmStartService()"
    *ngIf="showStartServiceBtn() && cargo?.driver && !isNegotiation && [cargoStateEnum.ACCEPTED].includes(cargo?.state) && cargo.confirmedDriver">
    <i class="fas fa-play-circle"></i>
    Iniciar servicio
  </button>
  <button mat-menu-item (click)="openDiscountsAndBonuses()"
    *ngIf="showBtnModificationsShippingCost && ![cargoStateEnum.DELETED,cargoStateEnum.EXPIRED].includes(cargo?.state)">
    <i class="fas fa-edit"></i>
    Bonificaciones y descuentos
  </button>
  <button mat-menu-item (click)="openLoadExternalManifest()" permission-display [module]="permission.cargo.module"
    [functionality]="permission.cargo.uploadExternalManifest"
    *ngIf="!isNegotiation && !cargo?.ministry && !isEscortedService && !cargo?.manifest && !['Urbana','Última milla'].includes(cargo?.cargoModel?.tripType?.name) && ![cargoStateEnum.DELETED,cargoStateEnum.EXPIRED,cargoStateEnum.REQUEST,cargoStateEnum.END_SERVICE].includes(cargo?.state)">
    <i class="fas fa-upload"></i>
    Cargar Manifiesto Externo
  </button>
  <button mat-menu-item (click)="openExternalManifest()" permission-display [module]="permission.cargo.module"
    [functionality]="permission.cargo.readManifest"
    *ngIf="!isNegotiation && cargo?.externalManifest && !isEscortedService">
    <i class="fas fa-file-alt"></i>
    Ver Manifiesto Externo
  </button>
  <button mat-menu-item (click)="openCargoPDF(PDFTypes.ORDER_VEHICLE)" *ngIf="![cargoStateEnum.DELETED,cargoStateEnum.EXPIRED,cargoStateEnum.REQUEST].includes(cargo?.state) &&
    !isNegotiation && cargo?.driver && hasOrderVehiclePermission">
    <i class="fas fa-file-alt"></i>
    Orden de {{isEscortedService? 'servicio' : 'cargue'}}
  </button>
  <app-generate-manifest-action *ngIf="cargo?.ministry && !cargo?.manifestAuthorization" [cargo]="cargo"
    type="cargo-option" (refreshLoad)="refreshCargo.emit($event)"></app-generate-manifest-action>
  <!-- <div matTooltip="{{ cannotShowManifestReason }}" matTooltipPosition="above" *ngIf="cargo && cargo.cargoModel && cargo.cargoModel.tripType && cargo.cargoModel.tripType.name && (cargo.cargoModel.tripType.name !== 'Urbana' || cargo.cargoModel.tripType.name !== 'Última milla')"> -->
  <div matTooltip="{{ cannotShowManifestReason }}" matTooltipPosition="above"
    *ngIf="cargo?.ministry && hasManifestPermission">
    <button mat-menu-item (click)="openCargoPDF(PDFTypes.MANIFEST)" permission-display
      [module]="permission.cargo.module" [functionality]="permission.cargo.readManifest"
      [disabled]="disableViewManifest" *ngIf="cargo && !isNegotiation">
      <i class="fas fa-file-alt"></i>
      Manifiesto
    </button>
  </div>

  <button mat-menu-item (click)="openCargoPDF(PDFTypes.CONSIGNMENT)" *ngIf="!isNegotiation && canSeeConsignments">
    <i class="fas fa-file-alt"></i>
    Remesa
  </button>
  <!-- Urban -->
  <button mat-menu-item (click)="openCargoPDF(PDFTypes.MANIFEST_URBAN)" permission-display
    [module]="permission.cargo.module" [functionality]="permission.cargo.readManifest"
    *ngIf="!cargo?.ministry && cargo?.licensePlate && cargo?.driver && hasManifestUrbanPermission">
    <i class="fas fa-file-alt"></i>
    Planilla urbana
  </button>
  <!-- Urban -->
  <button mat-menu-item (click)="openCargoPDF(PDFTypes.CONSIGNMENT_URBAN)"
    *ngIf="!cargo?.ministry && hasConsignmentUrbanPermission">
    <i class="fas fa-file-alt"></i>
    Guía terrestre
  </button>
  <div matTooltip="{{ !cargo?.routePlanId || !cargo?.itineraryId ? 'No se ha asociado un plan de ruta' : '' }}"
    matTooltipPosition="above" *ngIf="hasPlanningRoutePermission &&
    ![cargoStateEnum.DELETED,cargoStateEnum.EXPIRED,cargoStateEnum.REQUEST].includes(cargo?.state)">
    <button mat-menu-item (click)="openCargoPDF(PDFTypes.PLANNING_ROUTE)"
      [disabled]="!cargo?.routePlanId || !cargo?.itineraryId">
      <i class="fas fa-file-alt"></i>
      Plan de ruta
    </button>
  </div>
  <button mat-menu-item (click)="openHistoryPayments()" *ngIf="cargo?.shippingCost?.payments" permission-display
    [module]="permission.cargo.module" [functionality]="permission.cargo.showPaymentCargo">
    <i class="fas fa-dollar-sign"></i>
    Historial de pagos
  </button>
  <button mat-menu-item (click)="openModalConfirmDriver()"
    *ngIf="showConfirmLicensePlateBtn && !isNegotiation && cargo?.driver && !cargo?.confirmedDriver && [cargoStateEnum.ACCEPTED].includes(cargo?.state)">
    <i class="fas fa-check-circle"></i>
    Confirmar {{isEscortedService? 'escolta' : 'placa'}}
  </button>
  <div [matTooltip]="disabledAddTracking ? 'No es posible añadir seguimientos, el servicio ha finalizado' : (cargo.manifestError && cargo.manifestError.error && cargo.ministryError) ? 'No es posible añadir seguimientos, el servicio tiene errores en el manifiesto' : '' "
    matTooltipPosition="above">
    <button mat-menu-item (click)="openCargoLocationReport()" *ngIf="cargo?.state === cargoStateEnum.START_SERVICE"
      permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.registerLocation"
      [disabled]="disabledAddTracking || (cargo.manifestError && cargo.manifestError.error && cargo.ministryError)">
      <i class="fas fa-map-marker-alt"></i>
      Añadir Seguimiento
    </button>
  </div>
  <button mat-menu-item (click)="executeParentMethod.emit('openPopupContact')"
    *ngIf="cargo?.state === cargoStateEnum.START_SERVICE && !typeList">
    <i class="fas fa-paper-plane"></i>
    Contactar Soporte
  </button>
  <button mat-menu-item (click)="openRenewDate()" *ngIf="showRestoreCargo" permission-display
    [module]="permission.cargo.module" [functionality]="permission.cargo.restoreCargo">
    <i class="fas fa-plus-square"></i>
    Restaurar servicio
  </button>
  <button mat-menu-item (click)="openDialogRestartCargo(cargo.consecutive, cargo.id)"
    *ngIf="cargo?.state === cargoStateEnum.END_SERVICE" permission-display [module]="permission.cargo.module"
    [functionality]="permission.cargo.restartCargo">
    <i class="fas fa-undo"></i>
    Retornar a servicios en ruta
  </button>
  <!--button mat-menu-item *ngIf="showCreateRoundedCargo" (click)="openRoundedCargo()" permission-display
    [module]="permission.cargo.module" [functionality]="permission.cargo.createRoundedCargo">
    <i class="fas fa-truck"></i>
    Crear servicio redondo
  </button-->
  <button mat-menu-item (click)="openChangeCompany()" permission-display [module]="permission.cargo.module"
    [functionality]="permission.cargo.changeCompany"
    *ngIf="![cargoStateEnum.DELETED,cargoStateEnum.EXPIRED].includes(cargo?.state)">
    <i class="fas fa-building"></i>
    Cambiar compañia asociada
  </button>
  <button mat-menu-item (click)="openDialogApprovePayBalance()" permission-display [module]="permission.cargo.module"
    [functionality]="permission.cargo.approveBalanceDriver" *ngIf="cargo?.state === cargoStateEnum.END_SERVICE">
    <i class=" fas fa-thumbs-up"></i>
    Aprobar Pago Saldo
  </button>
  <button mat-menu-item
    (click)="openDialogFastPayment(cargo.id, cargo.consecutive,true, cargo.cargoModel.tripType.name)" permission-display
    [module]="permission.payments.module" [functionality]="permission.payments.fastPaymentRequest"
    *ngIf="cargo?.state === cargoStateEnum.END_SERVICE && !cargo?.fastPayment && cargo?.approval !== 'Rejected' && balanceState && utils.isTeclogiUserOrClient">
    <i class="fas fa-money-bill"></i>
    Solicitud de pronto pago
  </button>
  <!-- <button mat-menu-item (click)="openDialogFastPayment(cargo.id, cargo.consecutive, false, cargo.cargoModel.tripType.name)" permission-display [module]="permission.payments.module"
    [functionality]="permission.payments.fastPaymentRequest" *ngIf="cargo && cargo.state === cargoStateEnum.END_SERVICE && cargo.fastPayment">
    <i class="fas fa-money-bill"></i>
    Cancelar solicitud de pronto pago
  </button> -->
  <button mat-menu-item (click)="canOpenRequestAdvance('RequestAdvance')"
    *ngIf="!showCancelAdvance && !cargo?.shippingCost?.advanceState && !cargo?.shippingCost?.balanceState && cargo?.state === cargoStateEnum?.START_SERVICE"
    permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.advanceRequest">
    <i class="fas fa-file-invoice"></i>
    Solicitar pago de anticipo
  </button>

  <button mat-menu-item
    *ngIf="showCancelAdvance && !cargo?.shippingCost?.advanceState && ![cargoStateEnum.DELETED,cargoStateEnum.EXPIRED,cargoStateEnum.REQUEST].includes(cargo?.state)"
    permission-display (click)="canOpenRequestAdvance('CancelRequest')" [module]="permission.cargo.module"
    [functionality]="permission.cargo.advanceRequest">
    <i class="fas fa-file-invoice"></i>
    Cancelar solicitud de pago de anticipo
  </button>

  <button mat-menu-item (click)="downloadReport()" permission-display [module]="permission.cargo.module"
    [functionality]="permission.cargo.reportAnomalies"
    *ngIf="[cargoStateEnum.START_SERVICE,cargoStateEnum.END_SERVICE].includes(cargo?.state)">
    <i class="fas fa-file-download"></i>
    Reporte Novedades
  </button>

  <button mat-menu-item (click)="openMoveAdvance()" permission-display [module]="permission.cargo.module"
    [functionality]="permission.cargo.moveAdvance"
    *ngIf="![cargoStateEnum.DELETED,cargoStateEnum.EXPIRED,cargoStateEnum.REQUEST].includes(cargo?.state)">
    <i class="fas fa-arrow-right"></i>
    Trasladar anticipos
  </button>

  <button type="button" mat-menu-item (click)="openDuplicateTransportRequest()" permission-display
    [module]="permission.cargo.module" [functionality]="permission.cargo.duplicateTransportRequest">
    <i class="fas fa-clone"></i>
    Duplicar Servicio
  </button>
</mat-menu>
<app-assign-vehicle-cargo hidden [cargo]="cargo"></app-assign-vehicle-cargo>
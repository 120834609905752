import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl, FormsModule } from '@angular/forms';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { VehicleCargo } from 'src/app/core/interfaces/vehicle-cargo';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSelectChange } from '@angular/material';
import { Subscription } from 'rxjs';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { environmentTypes } from 'src/app/core/resources/typeEnvironment';
import { CargoManager } from 'src/app/core/managers/cargo.manager';

@Component({
  selector: 'app-quantity-vehicles',
  templateUrl: './quantity-vehicles.component.html',
  styleUrls: ['./quantity-vehicles.component.scss'],
  providers: [AuthService, Global]
})
export class QuantityVehiclesComponent implements OnInit {
  permission = Permission;
  formVehicles: FormGroup;
  validBodyWork: boolean = false;
  minQuantity: number = 1;
  minAxles: number = 2;
  isRefrigerated: boolean = false;
  quantityVehicles: FormGroup = new FormGroup({});
  subscriptions: Subscription = new Subscription();
  environmentTypes: string[] = environmentTypes;
  constructor(
    private formBuilder: FormBuilder,
    public manualCreationCargoService: ManualCreationCargoService,
    public snackBarService: SnackBarService,
    private router: Router,
    private spinner: NgxSpinnerService,
    public utils: Utils,
    private permissionRole: PermissionRole,
    private cargoManager: CargoManager
  ) {
    this.formVehicles = this.formBuilder.group({
      typeVehicles: new FormArray([], Validators.required)
    });
  }

  ngOnInit() {
    this.setFormControls();
    this.setSubscriptions();
    this.setOptions();
  }

  setSubscriptions() {
    this.manualCreationCargoService.bodyworkTypeControls.controls.forEach((bodyworkType, i) => {
      const bodyWorkSub = bodyworkType.valueChanges.subscribe(value => {
        this.manualCreationCargoService.vehiclesSelected[i].bodyWorkType.id = value && value.id ? value.id : '';
        this.manualCreationCargoService.vehiclesSelected[i].bodyWorkType.name = value && value.name ? value.name : '';
        if (this.manualCreationCargoService.vehiclesSelected[i].name !== 'TRACTOCAMION' && this.manualCreationCargoService.vehiclesSelected[i].bodyWorkType && this.manualCreationCargoService.vehiclesSelected[i].bodyWorkType.name !== 'FURGON') this.manualCreationCargoService.vehiclesSelected[i].quality = '';
      });
      this.subscriptions.add(bodyWorkSub);
    })
  }

  setOptions() {
    this.manualCreationCargoService.vehiclesSelected.forEach((vehicle, i) => {
      let bodywork = this.manualCreationCargoService.bodyworkTypeOptions.at(i).value;
      bodywork['initialValue'] = vehicle.bodyWorkType && vehicle.bodyWorkType.name ? vehicle.bodyWorkType.name : '';
      this.manualCreationCargoService.bodyworkTypeOptions.at(i).setValue(bodywork)
    })
  }

  setFormControls() {
    this.manualCreationCargoService.formAxles.setValidators(Validators.min(this.minAxles))
    if (this.manualCreationCargoService.vehiclesSelected) {
      this.manualCreationCargoService.vehiclesSelected.forEach((element, i) => {
        this.quantityVehicles.addControl(`quantity${element.name}`, new FormControl(element.quantity ? element.quantity : 1, [Validators.required, Validators.min(this.minQuantity)]))
      });
      this.manualCreationCargoService.vehiclesSelected.forEach((element, i) => {
        this.quantityVehicles.get(`quantity${element.name}`).valueChanges
          .subscribe(value => {
            this.manualCreationCargoService.vehiclesSelected[i].quantity = value;
          })
      })
    }
  }
  onChangeIsRefrigerated(event: MatSelectChange, index: number) {
    this.manualCreationCargoService.vehiclesSelected[index].quality = event && event.value ? event.value : '';
  }

  getValidWork(vehicle: VehicleCargo): boolean {
    const vehicleType = vehicle.name;
    const bodyworkType = vehicle.bodyWorkType.name;
    return this.cargoManager.requestEnvironmentType(vehicleType, bodyworkType);
  }

  getDataVehicle(vehicle: Object) {
    let indexVehicle = _.findIndex(this.manualCreationCargoService.listVehicles, function (vehicleObj) {
      return vehicleObj.id == vehicle['id'];
    });

    if (indexVehicle >= 0) {
      return this.manualCreationCargoService.listVehicles[indexVehicle];
    }
  }

  getIconVehicle(vehicle: Object) {
    return '/assets/svg/icons/icon-' + vehicle['name'].toLowerCase() + '.svg';
  }

  getNameVehicle(vehicle: Object) {
    return this.getDataVehicle(vehicle).name;
  }

  findErrorsVehicles(): boolean {
    let isValid = true;
    this.manualCreationCargoService.vehiclesSelected.forEach((element, i) => {
      if (isValid) {
        if (this.utils.errorMessagesCustomized(this.quantityVehicles.get(`quantity${element.name}`), `cantidad del vehículo ${i + 1}`, null, null, this.minQuantity)) {
          isValid = false;
        }
        else if (!element.bodyWorkType || !element.bodyWorkType.name) {
          this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, `tipo de carroceria del vehículo ${i + 1}`), undefined, 'alert');
          isValid = false;
        }
        else if (element.name === 'TRACTOCAMION') {
          if (this.utils.errorMessagesCustomized(this.manualCreationCargoService.formAxles, 'n° de ejes', null, null, this.minAxles)) {
            isValid = false;
          }
        }
      }
    })
    return isValid;
  }

  nextStep() {
    this.manualCreationCargoService.bodyworkTypeValidates.controls.forEach(bodyworkType => bodyworkType.setValue('touched'));
    this.manualCreationCargoService.formAxles.markAsTouched();
    this.quantityVehicles.markAllAsTouched();
    if (this.manualCreationCargoService.isValidVehicleQuantity() && this.quantityVehicles.valid && this.manualCreationCargoService.formAxles.valid) {
      if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.assignLicensePlate)) {
        // Assign vehicles
        this.manualCreationCargoService.getComponentActive().setId(2);
        this.manualCreationCargoService.getComponentActive().getStep().setId(3);
        this.router.navigate([this.manualCreationCargoService.steps.assignVehicles.url]);
      } else {
        const serviceTypeName = this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].serviceType.controls.name.value;
        if (serviceTypeName === 'Contenedor vacío con carga de compensación' || serviceTypeName === 'Contenedor vacío express' || serviceTypeName === 'Carga consolidada') {
          this.manualCreationCargoService.getComponentActive().setId(2);
          this.manualCreationCargoService.getComponentActive().getStep().setId(5);
          this.router.navigate([this.manualCreationCargoService.steps.optional.url]);
        } else {
          this.manualCreationCargoService.getComponentActive().setId(2);
          this.manualCreationCargoService.getComponentActive().getStep().setId(4);
          this.router.navigate([this.manualCreationCargoService.steps.typeMerchandise.url]);
        }
      }


    } else {
      if (this.findErrorsVehicles()) this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
    }
  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(2);
    this.manualCreationCargoService.getComponentActive().getStep().setId(1);
    this.router.navigate([this.manualCreationCargoService.steps.vehicleType.url]);
  }

  ngOnDestroy() {
    if (this.subscriptions) this.subscriptions.unsubscribe();
  }
}

import { AfterViewInit, Component, EventEmitter, Input, Output, OnInit, SimpleChanges, Inject, ChangeDetectorRef } from '@angular/core';
import { Global } from 'src/app/core/resources/global';
import { Utils } from 'src/app/core/resources/utils';
import { CargoDetailService } from 'src/app/modules/cargo/cargo-detail/cargo-detail.service';
import { v4 as uuidv4 } from 'uuid';
import { GoogleService } from 'src/app/core/services/google.service';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { MapsEnum } from 'src/app/core/enums/maps.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { MapLocation, MapLocations } from 'src/app/core/interfaces/mapLocations';
import { MapPoint } from 'src/app/core/interfaces/mapPoint';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { LocationAddress } from 'src/app/core/interfaces/locationAddress';

@Component({
  selector: 'app-standard-map',
  templateUrl: './standard-map.component.html',
  styleUrls: ['./standard-map.component.scss']
})
export class StandardMapComponent implements OnInit, AfterViewInit {
  // Properties
  @Input('route-google') routeGoogle: any[] = [];
  @Input() options: OptionsAutocomplete;
  // Events
  @Output() clickMap: EventEmitter<any> = new EventEmitter();
  // Maps config
  private uuid: string;
  private geofence_references = [];
  public get mapId(): string { return this.uuid; };
  public mapOptions: google.maps.MapOptions;
  public map: google.maps.Map;
  center: google.maps.LatLngLiteral = { lat: 4.687631140720726, lng: -74.12697035582234 };
  polylines = {};
  polylinesGoogle = {};
  markers = {};
  infoContentMarkers = {};
  localOptions: OptionsAutocomplete = {}
  listMarkersKey: Array<string> = [];
  listPolylineKeys: Array<string> = [];
  listPolylineGoogleKeys: Array<string> = [];
  clickMapMarker;
  clickMapInstance: google.maps.MapsEventListener | null;
  markersCluster: MarkerClusterer;
  constructor(
    public utils: Utils,
    private freightForwarderDetailService: CargoDetailService,
    private global: Global,
    private googleService: GoogleService,
    public spinner: NgxSpinnerService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.uuid = uuidv4();
  }
  /**
  * This method initiate variables according to the options such as: route, real route, click maps and markers.
  */
  ngOnInit() {
    // Podemos usar esto para inicialización temprana si es necesario
  }
  ngAfterViewInit() {
    // Usar Promise.resolve() para ejecutar después del ciclo de detección de cambios
    Promise.resolve().then(() => {
      this.initMap();

      if (this.options.initialClickMap && this.options.clickMapIcon && this.map)
        this.clickMapListener();
      if (this.options.initialRoute)
        this.processRouteGoogle(this.options.polyline, this.options.cargo);
      if (this.options.initialRealRoute)
        this.checkPolyline(this.options.polyline, 'real', '#02d7dc');
      if (this.options.initialMarkers)
        this.checkLocations(this.options.route);
    });
  }
  /**
  * This method check locations, markers, geofences and listeners.
  * @param {SimpleChanges} changes (SimpleChanges) is the element that listen all the changes of the inputs of the map.
  */
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.options && changes.options.currentValue) {
      if (changes.options.currentValue.polyline && JSON.stringify(this.localOptions['polyline']) !== JSON.stringify(changes.options.currentValue.polyline)) {
        this.localOptions['polyline'] = this.utils.clone(changes.options.currentValue.polyline);
        this.checkPolyline(changes.options.currentValue.polyline, 'real', '#02d7dc');
        if (changes.options.currentValue.cargo && JSON.stringify(this.localOptions['cargo']) !== JSON.stringify(changes.options.currentValue.cargo)) {
          this.localOptions['cargo'] = this.utils.clone(changes.options.currentValue.cargo);
          this.processRouteGoogle(changes.options.currentValue.polyline, changes.options.currentValue.cargo);
        }
      }
      if (changes.options.currentValue.route && JSON.stringify(this.localOptions['route']) !== JSON.stringify(changes.options.currentValue.route)) {
        this.localOptions['route'] = this.utils.clone(changes.options.currentValue.route);
        this.checkLocations(this.localOptions['route']);
      }
      if ((changes.options.currentValue.showGeofences || changes.options.currentValue.showGeofences === false) && this.localOptions['showGeofences'] !== changes.options.currentValue.showGeofences) {
        this.localOptions['showGeofences'] = changes.options.currentValue.showGeofences
        changes.options.currentValue.showGeofences ? this.renderGeofences() : this.hideGeofences();
      }
      if (changes.options.currentValue.removeClickMapListener && this.map) this.removeMapListener();
      if (changes.options.currentValue.clickMap && (changes.options.currentValue.clickMapIcon || changes.options.currentValue.onlyListener) && this.map) this.clickMapListener();
      if (changes.options.currentValue.removeMarker && this.map) this.removeMarker(this.options && this.options.keyMarkerListener ? this.options.keyMarkerListener : '');
    }
    this.cdRef.detectChanges();
  }
  /**
  * This method create a new instance of map.
  */
  initMap() {
    if (document.getElementById(this.mapId)) {
      this.map = new google.maps.Map(document.getElementById(this.mapId) as HTMLElement, {
        center: { lat: this.options && this.options.lat ? this.options.lat : 4.687631140720726, lng: this.options && this.options.lng ? this.options.lng : -74.12697035582234 },
        zoom: this.options && this.options.zoom ? this.options.zoom : 13
      });
    }
  }
  /**
  * This method add a listener of events to an specific marker.
  */
  clickMapListener() {
    if (!this.clickMapInstance) {
      this.clickMapInstance = this.map.addListener('click', (e) => {
        if (this.options && !this.options.onlyListener) {
          this.removeMarker(this.options && this.options.keyMarkerListener ? this.options.keyMarkerListener : 'clickMapMarker');
          this.createMarker(this.options && this.options.keyMarkerListener ? this.options.keyMarkerListener : 'clickMapMarker', e.latLng.lat(), e.latLng.lng(), null, this.options && this.options.clickMapIcon ? this.options.clickMapIcon : null, this.options && this.options.labelIcon ? this.options.labelIcon : null);
        }
        this.setCenterMap(e.latLng.lat(), e.latLng.lng(), 16)
        this.clickMap.emit(e)
      });
    }
  }
  /**
  * This method remove the active listener.
  */
  removeMapListener() {
    if (this.clickMapInstance) {
      this.clickMapInstance.remove();
      this.clickMapInstance = null;
    }
  }

  //GEOREFERENCE OPTIONS
  /**
  * This method create geofences according to the information of the load.
  */
  renderGeofences() {
    if (this.options && this.options.cargo) {
      var cargo = this.options.cargo;
    }
    if (!cargo) return;
    const addresses = [];
    cargo.cargoFeature.uploadDownload.origin.addresses.forEach((address) => {
      addresses.push(address);
    });
    for (const destination of cargo.cargoFeature.uploadDownload.destination) {
      destination.addresses.forEach((address) => {
        addresses.push(address);
      });
    }
    for (const stop of addresses) {
      const geofence = new google.maps.Circle({
        strokeColor: "#02d7dc",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#02d7dc",
        fillOpacity: 0.35,
        map: this.map,
        center: new google.maps.LatLng(stop.location.lat, stop.location.lng),
        radius: MapsEnum.GEOFENCE_STANDARD_SIZE,
      });
      this.geofence_references.push(geofence);
    }
  }
  /**
  * This method delete the references related with the geofences in the map.
  */
  hideGeofences() {
    this.geofence_references.forEach((geofence) => { geofence.setMap(null); });
  }

  //MARKERS OPTIONS
  /**
  * This method go through the points of a location array and add or remove markers according to the conditions.
  * @param {MapLocations} locations (MapLocations) is the object of location points that wanted to render.
  */
  private checkLocations(locations: MapLocations) {
    if (this.markersCluster) this.markersCluster.clearMarkers();
    let clusterMarkers = [];
    for (let key in locations) {
      if (locations[key].path && locations[key].path.length) {
        locations[key].path.forEach((point: MapPoint) => {
          if (point) {
            this.removeMarker(point.key);
            let lat = point.lat ? point.lat : point.latLong && point.latLong.lat ? point.latLong.lat : null;
            let lng = point.lng ? point.lng : point.latLong && point.latLong.lng ? point.latLong.lng : null;
            if (lat && lng && point.showMarker) {
              this.createMarker(point.key, lat, lng, point.contentInfoWindow, point.icon, point.labelIcon);
              if (point.setCenter && this.map) this.setCenterMap(lat, lng, point.zoom);
              clusterMarkers.push(this.markers[point.key]);
            }
          }
        });
      }
    }
    this.markersCluster = new MarkerClusterer({ markers: clusterMarkers, map: this.map });
  }
  /**
  * This method create a new instance google marker.
  * @param {string} key (string) is the unique identifier of the marker.
  * @param {number} lat (number) is the latitude of the marker.
  * @param {number} lng (number) is the longitude of the marker.
  * @param {string} contentInfoWindow (string - optional) is the content of the infowindow.
  * @param {string} icon (string - optional) is the icon of the marker.
  * @param {string} labelIcon (string - optional) is the label of the icon associate.
  */
  public createMarker(key: string, lat: number, lng: number, contentInfoWindow?: string, icon?: string, labelIcon?: string) {
    this.markers[key] = new google.maps.Marker({
      icon: icon ?
        (icon.includes('pin-destination.png') ?
          { url: icon, scaledSize: new google.maps.Size(20, 25) } :
          { url: icon, scaledSize: new google.maps.Size(25, 25) }) :
        (icon === 'Point' ?
          this.global.pathMarkerVehicleNoLastLocations :
          this.global.pathMarkerVehicle),
      position: { lat: lat, lng: lng },
      map: this.map,
    });
    if (labelIcon) {
      this.markers[key].set("label", {
        text: labelIcon,
        color: 'white', // Color del texto
        fontWeight: 'bold', // Estilo del texto
        fontSize: '16px',
      });
    }
    if (contentInfoWindow) {
      this.createInfoWindow(key, contentInfoWindow, this.markers[key]);
    }
    this.markers[key].setMap(this.map);
    if (!this.listMarkersKey.includes('key')) {
      this.listMarkersKey.push(key);
    }
  }
  /**
  * This method create an info window in a specific marker.
  * @param {string} key (string) is the unique identifier of the marker.
  * @param {string} content (string) is the content of the infowindow.
  * @param {google.maps.Marker} marker (google.maps.Marker) is google marker instance.
  */
  public createInfoWindow(key: string, content: string, marker: google.maps.Marker) {
    this.infoContentMarkers[key] = new google.maps.InfoWindow({
      content,
    });
    marker.addListener("click", () => {
      this.infoContentMarkers[key].open(this.map, marker);
    });
  }
  /**
* This method remove an specific key of the map.
* @param {string} key (string) is the unique identifier of the marker.
*/
  removeMarker(key: string) {
    if (this.listMarkersKey.includes(key)) {
      this.markers[key].setMap(null);
      this.listMarkersKey.splice(this.listMarkersKey.indexOf(key), 1);
    }
  }
  /**
  * This method remove all the markers of the map created.
  */
  removeAllMarkers() {
    if (this.listMarkersKey && this.listMarkersKey.length) {
      this.listMarkersKey.forEach(key => {
        this.markers[key].setMap(null);
      })
    }
    this.listMarkersKey = [];
  }
  /**
  * This method set the center of a map in a specific coordinates.
  * @param {number} lat (number) is the latitude in the map.
  * @param {number} lng (number) is the longitude in the map.
  * @param {number} zoom (number - optional) is the quantity of zoom in the map.
  */
  public setCenterMap(lat: number, lng: number, zoom: number = 13): void {
    const currentZoom: number = this.map.getZoom() || 0;
    if ((zoom && zoom > currentZoom)) this.map.setZoom(zoom);
    this.map.panTo(new google.maps.LatLng(lat, lng));
  }

  //GOOGLE ROUTE
  /**
  * This method create a simulated route google.
  * @param {MapLocations} locations (MapLocations) are the locations to render.
  * @param {Cargo} cargo (Cargo) is the information of the load.
  */
  processRouteGoogle(locations: MapLocations, cargo: Cargo) {
    for (let key in locations) {
      this.removePolylineGoogle(key);
      if (locations[key] && locations[key].showRouteGoogle && cargo) {
        this.freightForwarderDetailService.getRouteGoogleCargo(key).subscribe(
          (response) => {
            if (response && response['overview_polylines'] && response['overview_polylines'][0]) {
              this.routeGoogle = google.maps.geometry.encoding.decodePath(response['overview_polylines'][0]);
              this.createPolyline(key, this.routeGoogle, '#584796', 'google');
            }
          }
        );
      }
      else if (locations[key] && locations[key].routePlanItinerary && locations[key].showRoutePlan)
        this.processRoutePlanItinerary(locations[key], key);
      else
        this.routeGoogle = [];
    }
  }

  processRoutePlanItinerary(location: MapLocation, key: string) {
    this.routeGoogle = google.maps.geometry.encoding.decodePath(location.routePlanItinerary.overviewPolylines);
    this.createPolyline(key, this.routeGoogle, location.routePlanItinerary.active ? '#584796' : '#ff0000', 'google');
    if (this.markers && Object.keys(this.markers).some(marker => marker.includes(key)))
      Object.keys(this.markers).filter(marker => marker.includes(key)).forEach(marker => {
        this.removeMarker(marker);
      });

    if (location.routePlanItinerary.controlPoints && location.routePlanItinerary.controlPoints.length)
      location.routePlanItinerary.controlPoints.sort((a, b) => a.order - b.order).forEach((point, index) => {
        if (point.location && point.location.lat && point.location.lng)
          this.createMarker(`${key}-control-point-${index}`, point.location.lat, point.location.lng, null, '/assets/svg/icons/tl_ico__pin_google_maps_trip_stop.svg', `${index + 1}`);
      });

    if (location.routePlanItinerary.authorizedStops && location.routePlanItinerary.authorizedStops.length) {
      const icons = this.global.authorizedPoints.map(point => point.icon);
      location.routePlanItinerary.authorizedStops.forEach((stop, index) => {
        const icon = stop.details && stop.details.pointType && stop.details.pointType.id ? icons[Number(stop.details.pointType.id) - 1] : icons[0];
        if (stop.location && stop.location.lat && stop.location.lng)
          this.createMarker(`${key}-authorized-stop-${index}`, stop.location.lat, stop.location.lng, null, `/assets/svg/icons/${icon}`);
      });
    }
  }
  /**
  * This method create a simulated route according to the locations.
  * @param {Array<any>} listLocations (Array<any>) is the list of locations to stimate.
  * @param {string} key (string) is the unique identifier of the marker.
  */
  processAnyGoogleRoute(listLocations: Array<any>, key: string) {
    this.googleService
      .getRouteData(listLocations)
      .then(
        (success: { cargoDistancy; cargoEstimatedTime; cargoRoute }) => {
          const overviewPolyline = (
            success.cargoRoute.routes[0].overview_polyline.points || success.cargoRoute.routes[0].overview_polyline
          );
          if (overviewPolyline) {
            this.routeGoogle = google.maps.geometry.encoding.decodePath(overviewPolyline);
            this.createPolyline(key, this.routeGoogle, '#02d7dc', 'google');
          }
        }
      )
      .catch((error) => {
        console.error(error)
      });
  }

  //POLYLINES
  /**
  * This method remove and specific google polyline.
  * @param {string} key (string) is the unique identifier of the polyline.
  */
  private removePolylineGoogle(key: string) {
    if (this.listPolylineGoogleKeys && this.listPolylineGoogleKeys.length && this.listPolylineGoogleKeys.includes(key)) {
      this.polylinesGoogle[key].setMap(null);
      this.listPolylineGoogleKeys.splice(this.listPolylineGoogleKeys.indexOf(key), 1);
    }
  }
  /**
  * This method remove and specific real route polyline.
  * @param {string} key (string) is the unique identifier of the polyline.
  */
  private removePolylineReal(key: string) {
    if (this.listPolylineKeys && this.listPolylineKeys.length && this.listPolylineKeys.includes(key)) {
      this.polylines[key].setMap(null);
      this.listPolylineKeys.splice(this.listPolylineKeys.indexOf(key), 1);
    }
  }
  /**
  * This method remove all the realm polylines created.
  */
  private removeAllPolylineReal() {
    if (this.listPolylineKeys) {
      for (let i = 0; i < this.listPolylineKeys.length; i++) {
        let key = this.listPolylineKeys[i];
        if (this.polylines[key]) {
          this.polylines[key].setMap(null);
          this.listPolylineKeys.splice(i, 1);
          i--;
        }
      }
    }
  }
  /**
  * This method create a polyline according to a path and a color sended.
  * @param {google.maps.LatLngLiteral[]} path (google.maps.LatLngLiteral[]) is the array of elements to create the polyline.
  * @param {string} strokeColor (string) is the color of the polyline.
  * @returns {google.maps.Polyline} (google.maps.Polyline) returns the instance of polyline created.
  */
  public polyline(path: google.maps.LatLngLiteral[], strokeColor: string): google.maps.Polyline {
    const polyline = new google.maps.Polyline(
      {
        path,
        strokeColor,
        strokeOpacity: 1.0,
        strokeWeight: 2,
        geodesic: true,
      }
    );
    polyline.setMap(this.map);
    return polyline;
  }
  /**
  * This method create a new polyline instance of google.
  * @param {string} key (string) is the unique identifier of the marker.
  * @param {any} locations (any) is the object of location points that wanted to render.
  * @param {string} color (string - optional) is the color of the polyline.
  * @param {string} type (string) is the type of polyline that want to render could be: real or google.
  */
  private async createPolyline(key: string, locations: any, color: string, type: string) {
    if (locations.length) {
      let setpolylines;
      setpolylines = type && type === 'real' ? this.polylines : this.polylinesGoogle;
      const locationsWithLatAndLng = locations.filter(location => location.lat && location.lng);
      setpolylines[key] = new google.maps.Polyline({
        path: locationsWithLatAndLng,
        geodesic: false,
        strokeColor: color,
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      if (type === 'real') {
        this.polylines[key].setMap(this.map);
        this.listPolylineKeys.push(key);
      } else if (type === 'google') {
        this.polylinesGoogle[key].setMap(this.map);
        this.listPolylineGoogleKeys.push(key);
      }
    }
  }
  /**
  * This method remove or create polylines.
  * @param {MapLocations} locations (MapLocations) is the object of location points that wanted to render.
  * @param {string} type (string) is the type of polyline that want to render could be: real or google.
  * @param {string} color (string - optional) is the color of the polyline.
  */
  checkPolyline(locations: MapLocations, type: string, color?: string) {
    if (locations) {
      if (this.options.removeAllPolylines) this.removeAllPolylineReal();
      for (let key in locations) {
        this.removePolylineReal(key)
        if (locations[key] && locations[key].path && locations[key].path.length && locations[key].showPolyline) {
          const path = locations[key].path;
          this.createPolyline(key, path, color, type);
        }
      }
    }
  }

  ngOnDestroy() {
    this.cdRef.detach();
  }
}

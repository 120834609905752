import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuServiceService } from '../../menu-service/menu-service.service';
import { AdditionalServiceFormComponent } from '../additional-service-form/additional-service-form.component';
import { Router } from '@angular/router';
import { Fmt } from 'src/app/core/messages/fmt';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { AdditionalCostCargo } from 'src/app/core/interfaces/additionalCostCargo';
import { NgxSpinnerService } from 'ngx-spinner';
import { CargoDetailService } from 'src/app/modules/cargo/cargo-detail/cargo-detail.service';
import { BasicResponse } from 'src/app/core/interfaces/basicResponse';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { TravelExpensesService } from 'src/app/core/services/travel-expenses.service';
import { TravelConcept, TravelExpense } from 'src/app/core/interfaces/travel-expense';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/services/authentication.service';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { CompanyManager } from 'src/app/core/managers/company.manager';
import { CurrencyMaskDirective } from 'src/app/core/directives/currency-mask.directive';
import { delay } from 'rxjs/operators';
import { ShippingCost as ShippingCostEnum } from 'src/app/core/enums/shipping-cost.enum';

@Component({
  selector: 'app-additional-values-service-form',
  templateUrl: './additional-values-service-form.component.html',
  styleUrls: ['./additional-values-service-form.component.scss']
})
export class AdditionalValuesServiceFormComponent implements OnInit {
  @ViewChild(AdditionalServiceFormComponent, { static: false }) additionalServiceForm: AdditionalServiceFormComponent;
  @ViewChildren(CurrencyMaskDirective) currencyMasks: QueryList<CurrencyMaskDirective>;
  permission = Permission;

  rateControl = new FormControl(0, [Validators.required, Validators.min(this.hasExceptionRateOrCost ? 0 : 1)]);
  freightCostControl = new FormControl(0, [Validators.required, Validators.min(this.hasExceptionRateOrCost ? 0 : 1)]);
  advancePercentageControl = new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]);
  valueAdvanceControl = new FormControl(0, [Validators.required, Validators.min(0)]);
  paymentTimeControl = new FormControl(null, [Validators.required]);
  otherPaymentTimeControl = new FormControl(3, [Validators.required]);
  paymentTimeValues = [8, 15, 30, 60, 90];

  travelExpensesSaved: FormArray = new FormArray([]);
  originalTravelExpenses: TravelExpense[] = [];
  concepts: Array<TravelConcept> = [];
  maxPercentage: number = 100;
  minUtility: number = ShippingCostEnum.MINIMUN_UTILITY;

  formSubscription: Subscription;
  advancePercentageControlSubscription: Subscription;
  freightCostControlSubscription: Subscription;
  valueAdvanceControlSubscription: Subscription;

  constructor(
    public menuService: MenuServiceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private cargoDetailService: CargoDetailService,
    private travelExpensesService: TravelExpensesService,
    private permissionRole: PermissionRole,
    private authService: AuthService,
    private companyManager: CompanyManager
  ) { }

  ngOnInit() {
    this.getConcepts();
    this.setLocalValues();
    this.setSubscription();
  }

  /**
   * @description Gets travel expense concepts from service
   */
  private getConcepts() {
    this.travelExpensesService.getTypes().subscribe(
      (concepts: TravelConcept[]) => this.concepts = concepts && concepts.length ? concepts : [],
      () => this.concepts = []
    );
  }

  /**
   * @description Set initial values for the form
   */
  private setLocalValues() {
    this.rateControl.setValidators([Validators.required, Validators.min(this.hasExceptionRateOrCost ? 0 : 1)]);
    this.freightCostControl.setValidators([Validators.required, Validators.min(this.hasExceptionRateOrCost ? 0 : 1)]);
    this.rateControl.setValue(
      this.menuService.form.get('shippingCost.rate').value ? Number(this.menuService.form.get('shippingCost.rate').value) : 0
    );
    this.freightCostControl.setValue(
      this.menuService.form.get('shippingCost.freightCost').value ? Number(this.menuService.form.get('shippingCost.freightCost').value) : 0
    );
    this.minUtility = this.menuService.form.get('idCompany').value ? this.companyManager.getUtilityByCompanyId(this.menuService.form.get('idCompany').value) : ShippingCostEnum.MINIMUN_UTILITY;

    const companyPercentage = this.menuService.form.get('idCompany').value ? this.companyManager.getAdvancePercentageByCompanyId(this.menuService.form.get('idCompany').value) : null;
    this.maxPercentage = companyPercentage ? companyPercentage :
      this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit)
        ? AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI
        : AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES;

    const advancePercentage = this.menuService.form.get('shippingCost.advancePercentage').value;
    this.advancePercentageControl.setValue(
      advancePercentage ? (typeof advancePercentage === 'number' && advancePercentage <= this.maxPercentage ? advancePercentage : this.maxPercentage) : 0
    );
    this.advancePercentageControl.setValidators([Validators.required, Validators.min(0), Validators.max(this.maxPercentage)]);
    const advanceValue = this.menuService.form.get('shippingCost.valueAdvance').value;
    this.valueAdvanceControl.setValue(
      advanceValue ? (typeof advanceValue === 'number' && advanceValue <= this.maxAdvanceValue ? advanceValue : this.maxAdvanceValue) : 0
    );

    const paymentTime = this.menuService.form.get('shippingCost.paymentTime').value;
    if (this.utils.isDefined(paymentTime) && typeof paymentTime === 'number') {
      this.paymentTimeControl.setValue(this.paymentTimeValues.includes(paymentTime) ? paymentTime : -1);
      this.otherPaymentTimeControl.setValue(paymentTime);
    } else {
      this.paymentTimeControl.setValue(null);
      this.otherPaymentTimeControl.setValue(3);
    }
    this.getServiceTravelExpenses();
    this.checkPermissionsToEdit();
  }

  private async getServiceTravelExpenses() {
    this.travelExpensesSaved.clear();
    let travelExpenses: TravelExpense[] = [];
    try {
      travelExpenses = await this.travelExpensesService.byCargoId(this.menuService.cargo.id).toPromise();
    } catch (error) { }
    this.originalTravelExpenses = travelExpenses && travelExpenses.length ? travelExpenses : [];
    this.travelExpensesSaved = this.formBuilder.array(this.originalTravelExpenses.map((travelExpense) => this.formBuilder.group(travelExpense)));
  }

  private setSubscription() {
    this.formSubscription = this.menuService.formObservable.subscribe(() => this.setLocalValues());
    this.advancePercentageControlSubscription = this.advancePercentageControl.valueChanges.pipe(delay(10)).subscribe(() => this.updateValueAdvance());
    this.freightCostControlSubscription = this.freightCostControl.valueChanges.pipe(delay(10)).subscribe(() => this.updateValueAdvance());
    this.valueAdvanceControlSubscription = this.valueAdvanceControl.valueChanges.pipe(delay(10)).subscribe(() => this.updateAdvancePercentage());
  }

  private checkPermissionsToEdit() {
    if (!this.canEditFinancialInformation) {
      this.rateControl.disable();
      this.freightCostControl.disable();
      this.advancePercentageControl.disable();
      this.valueAdvanceControl.disable();
      this.paymentTimeControl.disable();
      this.otherPaymentTimeControl.disable();
    }
  }

  private updateValueAdvance() {
    const freightCost = this.freightCostControl.value || 0;
    const percentage = parseFloat(Number(this.advancePercentageControl.value).toFixed(1)) || 0;
    const valueAdvance = parseFloat(((freightCost * percentage) / 100).toFixed(1));
    this.valueAdvanceControl.setValue(valueAdvance, { emitEvent: false });
    const valueAdvanceMask = this.currencyMasks.find(mask => mask.maskId === 'valueAdvance');
    if (valueAdvanceMask)
      valueAdvanceMask.formatValue(valueAdvance);
  }
  private updateAdvancePercentage() {
    const freightCost = this.freightCostControl.value || 0;
    const valueAdvance = parseFloat(Number(this.valueAdvanceControl.value).toFixed(1)) || 0;
    const percentage = parseFloat(((valueAdvance * 100) / freightCost).toFixed(1)) || 0;
    this.advancePercentageControl.setValue(percentage, { emitEvent: false });
  }

  compareTravelExpensesType(a: TravelConcept, b: TravelConcept) {
    return a.id === b.id;
  }

  addTravelExpense() {
    this.travelExpensesSaved.push(this.menuService.createTravelExpenseGroup());
  }

  removeTravelExpense(index: number) {
    this.travelExpensesSaved.removeAt(index);
  }

  get hasExceptionRateOrCost(): boolean {
    return this.authService.getCompany().chargeOwnFleet || this.menuService.hasPermissionNoMinimumUtility;
  }

  get canSeeFinancialInformation(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.showFinancialInformationModule
    );
  }

  get canEditFinancialInformation(): boolean {
    return this.canSeeFinancialInformation &&
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.showEditFinancialInformationModule
      );
  }

  get canEditAndSeeTravelExpenses(): boolean {
    return !this.menuService.isEscortService() &&
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.editTravelExpenses
      );
  }

  get canDeleteTravelExpenses(): boolean {
    return this.canEditAndSeeTravelExpenses &&
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.removeTravelExpenses
      );
  }

  get maxAdvanceValue(): number {
    const freightCost = this.freightCostControl.value || 0;
    return freightCost * this.maxPercentage / 100;
  }

  get showAdditionalCosts(): boolean {
    const isSomeAdditionalCost = this.menuService.form.get('additionalCosts').value && this.menuService.form.get('additionalCosts').value.length;
    const hasPermissionToAdd = this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.additionalCostCreate
    );
    return this.canEditAdditionalCosts && (isSomeAdditionalCost || hasPermissionToAdd);
  }

  private get canEditAdditionalCosts(): boolean {
    return !this.menuService.isEscortService() &&
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.additionalCosts
      );
  }

  get utilityCargo(): number {
    if (!this.rateControl.value) return 0;
    const utilityValue = ((this.rateControl.value - this.freightCostControl.value) / this.rateControl.value * 100);
    return !Number.isNaN(utilityValue) ? utilityValue : 0;
  }

  get messageAdvancePercentageAllowed(): string {
    return `El porcentaje máximo permitido ${this.menuService.form.get('idCompany').value
      && this.companyManager.getNameByCompanyId(this.menuService.form.get('idCompany').value) !== this.menuService.form.get('idCompany').value
      ? `por ${this.companyManager.getNameByCompanyId(this.menuService.form.get('idCompany').value)}`
      : ''} es ${this.maxPercentage}%`;
  }

  backToPreviousStep() {
    this.router.navigate([Fmt.string(this.menuService.steps.loadCharacteristics.url, this.menuService.cargo.consecutive)]);
  }

  async saveAndContinue() {
    if (this.utilityCargo < this.minUtility && !this.hasExceptionRateOrCost)
      return this.snackBarService.openSnackBar(Fmt.string(FormMessages.MINIMUN_UTILITY_NOT_REACHED, this.minUtility), undefined, 'alert');
    if (this.utils.errorMessagesCustomized(this.advancePercentageControl, 'porcentaje de anticipo', null, null, 0, this.maxPercentage)) return;
    if (this.utils.errorMessagesCustomized(this.valueAdvanceControl, 'valor del anticipo', null, null, 0, this.maxAdvanceValue)) return;
    if (this.valueAdvanceControl.value > this.maxAdvanceValue)
      return this.snackBarService.openSnackBar(Fmt.string(FormMessages.MAX_VALUE_EXCEED, 'valor del anticipo', this.maxAdvanceValue), undefined, 'alert');

    const rateValue = this.utils.clone(this.rateControl.value);
    const freightCostValue = this.utils.clone(this.freightCostControl.value);
    const advancePercentageValue = this.advancePercentageControl.value
      ? this.advancePercentageControl.value <= this.maxPercentage
        ? this.utils.clone(this.advancePercentageControl.value)
        : this.utils.clone(this.maxPercentage)
      : 0;
    const valueAdvanceValue = this.valueAdvanceControl.value
      ? this.valueAdvanceControl.value <= this.maxAdvanceValue
        ? this.utils.clone(this.valueAdvanceControl.value)
        : this.utils.clone(this.maxAdvanceValue)
      : 0;
    const paymentTimeValue = this.paymentTimeControl.value === -1
      ? this.otherPaymentTimeControl.value ? Number(this.utils.clone(this.otherPaymentTimeControl.value)) : 0
      : this.paymentTimeControl.value ? this.utils.clone(this.paymentTimeControl.value) : 0;

    if (this.travelExpensesSaved.value && this.travelExpensesSaved.value.length && this.canEditAndSeeTravelExpenses)
      await this.processTravelExpenses();

    if (!this.menuService.isEscortService() && this.additionalServiceForm) {
      const additionalServiceForm = this.additionalServiceForm.additionalCostsArray;
      if (additionalServiceForm.value.length)
        await this.createAdditionalCosts(additionalServiceForm);
    }
    this.menuService.form.get('shippingCost.rate').setValue(rateValue);
    this.menuService.form.get('shippingCost.freightCost').setValue(freightCostValue);
    this.menuService.form.get('shippingCost.totalCost').setValue(freightCostValue);
    this.menuService.form.get('shippingCost.advancePercentage').setValue(advancePercentageValue);
    this.menuService.form.get('shippingCost.valueAdvance').setValue(valueAdvanceValue);
    this.menuService.form.get('shippingCost.paymentTime').setValue(paymentTimeValue);

    this.menuService.updateService().then(() => {
      this.snackBarService.openSnackBar('Servicio actualizado exitosamente');
      this.continueToNextStep();
    }).catch(() => {
      this.snackBarService.openSnackBar('Ocurrió un error al actualizar el servicio', undefined, 'error');
    });
  }

  continueToNextStep() {
    this.router.navigate([Fmt.string(this.menuService.steps.observationsService.url, this.menuService.cargo.consecutive)]);
  }

  private async processTravelExpenses() {
    const travelExpensesSaved = this.travelExpensesSaved.value;
    const originalTravelExpenses = this.originalTravelExpenses;
    const travelExpensesToAdd = travelExpensesSaved.filter((travelExpense) => !travelExpense.id);
    const travelExpensesToRemove = originalTravelExpenses.filter((travelExpense) => !travelExpensesSaved.some((savedTravelExpense) => savedTravelExpense.id === travelExpense.id));
    const travelExpensesToUpdate = travelExpensesSaved.filter((travelExpense) => travelExpense.id).filter((travelExpense) => {
      const originalTravelExpense = originalTravelExpenses.find((originalTravelExpense) => originalTravelExpense.id === travelExpense.id);
      return originalTravelExpense && (originalTravelExpense.totalPaid !== travelExpense.totalPaid || originalTravelExpense.travelExpensesType.id !== travelExpense.travelExpensesType.id);
    });

    const promises = [];
    promises.push(...await this.addTravelExpenses(travelExpensesToAdd));
    promises.push(...await this.removeTravelExpenses(travelExpensesToRemove));
    promises.push(...await this.updateTravelExpenses(travelExpensesToUpdate));
    if (!promises.length) return;

    this.spinner.show();
    Promise.all(promises)
      .then(() => this.spinner.hide(), () => this.spinner.hide())
      .catch(() => {
        this.snackBarService.openSnackBar('Uno o varios viáticos no se han guardado correctamente.', undefined, 'error');
        this.spinner.hide()
      });

  }

  private async addTravelExpenses(travelExpenses: TravelExpense[]): Promise<BasicResponse[]> {
    const addPromises = [];
    for (const travelExpense of travelExpenses) {
      addPromises.push(this.travelExpensesService.createTravelExpense(travelExpense).toPromise());
    }
    return addPromises;
  }

  private async removeTravelExpenses(travelExpenses: TravelExpense[]): Promise<BasicResponse[]> {
    const removePromises = [];
    for (const travelExpense of travelExpenses) {
      removePromises.push(this.travelExpensesService.removeTravelExpense(travelExpense.id).toPromise());
    }
    return removePromises;
  }

  private async updateTravelExpenses(travelExpenses: TravelExpense[]): Promise<BasicResponse[]> {
    const updatePromises = [];
    for (const travelExpense of travelExpenses) {
      updatePromises.push(this.travelExpensesService.updateTravelExpense(travelExpense.id, travelExpense).toPromise());
    }
    return updatePromises;
  }

  private async createAdditionalCosts(additionalCostsArray: FormArray) {
    let error = '';
    for (const additionalCost of additionalCostsArray.controls) {
      if (additionalCost.enabled && !additionalCost.valid) {
        const index = additionalCostsArray.controls.indexOf(additionalCost);
        if (additionalCost.errors) error = FormMessages.MINIMUN_UTILITY_NOT_REACHED_DEFAULT + ` en el servicio ${index + 1}`;
        else error = Fmt.string(FormMessages.MISSING_FIELD, `tarifa del servicio ${index + 1}`);
        break;
      }
    }
    if (error) return this.snackBarService.openSnackBar(error, undefined, 'alert');
    const formValue: AdditionalCostCargo[] = additionalCostsArray.value;
    const additionalCostsToCreate = formValue.filter((additionalCost) => !additionalCost.id);
    if (!additionalCostsToCreate.length) return;
    const body: AdditionalCostCargo[] = this.utils.clone(additionalCostsToCreate).map((additionalCost) => {
      delete additionalCost.type.disabled;
      delete additionalCost.type.whoToPaysUtilities;
      delete additionalCost.type.utility;
      return additionalCost;
    });
    this.spinner.show();
    let success: BasicResponse;
    try { success = await this.cargoDetailService.addAdditionalcost(this.menuService.cargo.id, body).toPromise() } catch (error) { }
    this.spinner.hide();
    if (!success || !success.message || success.message !== 'Update')
      return this.snackBarService.openSnackBar('Ocurrió un error al agregar los servicios adicionales', undefined, 'error');
    this.snackBarService.openSnackBar('Servicios adicionales registrados correctamente');
  }

  ngOnDestroy() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
    if (this.advancePercentageControlSubscription) this.advancePercentageControlSubscription.unsubscribe();
    if (this.freightCostControlSubscription) this.freightCostControlSubscription.unsubscribe();
    if (this.valueAdvanceControlSubscription) this.valueAdvanceControlSubscription.unsubscribe();
  }
}

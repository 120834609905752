import { MatDialog, MatDialogConfig } from "@angular/material";
import { AmountsCargoEnum } from "../enums/amountsCargo.enum";
import { Cargo } from "../interfaces/cargo";
import { Utils } from "../resources/utils";
import { Injectable } from "@angular/core";
import { ModalEnum } from "../enums/modal.enum";
import { TRIP_TYPES } from "../enums/tripTypes.enum";
import { CargoGenerateService } from "../services/cargoGenerate.service";
import { DialogSurveyComponent } from "src/app/shared/dialog-survey/dialog-survey.component";
import { LatLngLiteral } from "@agm/core";
import { AddressCargo } from "../interfaces/addressCargo";
import { LocationAddress } from "../interfaces/locationAddress";
import moment from "moment";
import { Observable } from "rxjs";
import { DateManager } from "./date.manager";
import { GoogleService } from "../services/google.service";
import { CargoApproval } from "../interfaces/cargo-approval";
import { BasicPerson } from "../interfaces/basicPerson";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { ServiceType } from "../interfaces/serviceType";
import { AuthService } from "../services/authentication.service";
import { CargoStateEnum } from "../enums/cargoState.enum";
import { CargoCreationModalComponent } from "src/app/modules/cargo/cargo-creation-modal/cargo-creation-modal.component";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ConsignmentCargo } from "../interfaces/consignmentCargo";

type Arrive = { location: LocationAddress, arrive: Date, address: string };

@Injectable()
export class CargoManager {

  public amountsCargo = [
    {
      start: AmountsCargoEnum.LOW_START,
      end: AmountsCargoEnum.LOW_END
    },
    {
      start: AmountsCargoEnum.MEDIUM_START,
      end: AmountsCargoEnum.MEDIUM_END
    },
    {
      start: AmountsCargoEnum.HIGH_START,
      end: AmountsCargoEnum.HIGH_END
    }
  ];

  constructor(
    public utils: Utils,
    public matDialog: MatDialog,
    public cargoGenerateService: CargoGenerateService,
    public googleService: GoogleService,
    private authService: AuthService,
    private router: Router
  ) {

  }

  public getTypeCargo(cargo: Cargo): TRIP_TYPES {
    let tripType: TRIP_TYPES;
    try {
      tripType = cargo.cargoModel.tripType.name;
    } catch (e) {
      console.error(e);
    }
    if (this.utils.isEmpty(tripType)) {
      tripType = TRIP_TYPES.URBAN;
    }
    return tripType;
  }

  public getProductNameCargo(cargo: Cargo): string {
    let productName: string;
    try {
      productName = cargo.cargoFeature.productType.name;
    } catch (e) {
      console.error(e);
    }
    if (this.utils.isEmpty(productName)) {
      productName = 'PRODUCTOS VARIOS';
    }
    return productName;
  }

  public checkInspection(cargo: Cargo, userId: BasicPerson, licensePlate: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      //Inspeccionar vehiculo
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        user: userId,
        licensePlate,
        id: cargo.id,
        cargo
      };
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      const dialoRef = this.matDialog.open(DialogSurveyComponent, dialogConfig);
      dialoRef.afterClosed().subscribe(result => {
        if (result) {
          resolve(true);
        } else {
          reject();
        }
      });
    });
  }

  public isTripTypeNational(cargo: Cargo): boolean {
    let state: boolean = false;
    try {
      return this.utils.getNestedValue(cargo, 'cargoModel.tripType.name') && cargo.cargoModel.tripType.name !== TRIP_TYPES.URBAN && cargo.cargoModel.tripType.name !== TRIP_TYPES.INTERNATIONAL && cargo.cargoModel.tripType.name !== TRIP_TYPES.LAST_MILE && ((this.utils.isDefined(cargo.cargoModel.tripType.category) && cargo.cargoModel.tripType.category !== TRIP_TYPES.URBAN) || !this.utils.isDefined(cargo.cargoModel.tripType.category));
    } catch (e) {
      console.error(e);
    }
    return state;
  }

  private timeAmPmTo24H(address: AddressCargo): string {
    if (!address.timeType) return address.time;
    if (address.time.startsWith('12') && address.timeType == 'AM') return address.time.replace('12', '00');
    if (address.time.startsWith('12') && address.timeType == 'PM') return address.time;
    if (address.timeType == 'PM') return `${parseInt(address.time.slice(0, 2)) + 12}:${address.time.slice(3, 5)}`;
    return address.time;
  }

  private processAddressToCheckTripTimes(address: AddressCargo, arrivalDate: Date | string): Arrive {
    // Completar con 0 a la izquierda 5:00 -> 05:00
    if (!address || !address.address)
      throw new Error(`No se ha diligenciado la dirección de algun origen o destino`);
    if (!address.time)
      throw new Error(`La dirección ${address.address} no tiene hora estimada`);
    address.time = address.time.padStart(5, '0');
    const time24H = this.timeAmPmTo24H(address);

    // Check if it is valid location
    if (!address.location || (!address.location.lat && !address.location.lng))
      throw new Error(`La dirección ${address.address} no tiene una geopocisión válida`);

    const arrivalDateDate = typeof arrivalDate === 'string' ? DateManager.stringToDate(arrivalDate) : arrivalDate;
    return {
      location: address.location as LatLngLiteral,
      arrive: (DateManager.stringToDate(`${DateManager.setStartOfDay(arrivalDateDate).toISOString().split('T')[0]}T${time24H}`, 'YYYY-MM-DDTHH:mm:ssZ')),
      address: address.address
    }
  }

  public checkTripTimes(cargo: Cargo, validateOrigin = true, validateDestination = true): Observable<{ valid: boolean, min: number, address: string }> {
    const arrivals: Array<Arrive> = [];
    const dateLoad = cargo.dateLoad;
    const origin = cargo.cargoFeature.uploadDownload.origin;
    const destinations = cargo.cargoFeature.uploadDownload.destination;

    try {
      if (validateOrigin)
        origin.addresses.forEach(address => arrivals.push(this.processAddressToCheckTripTimes(address, dateLoad)));
      if (validateDestination)
        destinations.forEach(destination => {
          const dateDownload = destination.downloadDate;
          destination.addresses.forEach(address => arrivals.push(this.processAddressToCheckTripTimes(address, dateDownload)));
        });
    } catch (err) {
      return new Observable(observer => observer.error(err));
    }
    if (!arrivals.length)
      return new Observable(observer => observer.error('No se han registrado direcciones de origen o destino'));
    const observables: Array<Observable<{ valid: boolean, min: number, address: string }>> = [];
    // Process all arrivals times from [1] ... [end]
    for (let i = 1; i < arrivals.length; i++) {
      const prev = arrivals[i - 1];
      const next = arrivals[i];

      observables.push(new Observable<{ valid: boolean, min: number, address: string }>(
        (observer) => this.googleService
          .calculateMinRouteTime(
            prev.location,
            next.location,
            (response, status) => {
              if (status !== "OK") { observer.error('No fue posible calcular una ruta entre las direcciones'); }
              else {
                const times: Array<number> = [];
                const distance: Array<number> = [];
                const description: Object = {};

                response.routes.forEach((route) => {
                  route.legs.forEach((leg) => {
                    times.push(leg.duration.value);
                    distance.push(leg.distance.value);

                    description[`${leg.duration.value}`] = {
                      duration: leg.duration.text,
                      distance: leg.distance.text,
                    };
                  });
                });
                const min = (4 / 5) * Math.min(...times); // Minimo tiempo estimado en segundos
                const duration = DateManager.dateDiff(next.arrive, null, prev.arrive, null, 'seconds');
                const isValid: boolean = !(duration - min < 0);
                observer.next({ valid: isValid, min, address: arrivals[i].address });
              }
              observer.complete();
            })));
    }

    return new Observable<{ valid: boolean, min: number, address: string }>(
      observer => {
        let pending = observables.length;
        let result = true;
        let min = 0;
        let address = '';
        observables.forEach(
          (subscribable, i) => {
            subscribable.subscribe({
              next: (success: { valid: boolean, min: number, address: string }) => {
                if (success && success.min) min = success.min;
                if (!success || !success.valid) {
                  observer.next({ valid: false, min, address: success.address });
                  observer.complete();
                } else {
                  pending--;
                  result = result && success.valid;
                  address = success.address;
                }
              },
              error: (err) => {
                observer.error(`Ocurrió un error al encontrar una ruta a la dirección ${arrivals[i + 1].address}, por favor verifique la dirección y vuelva a intentar`);
                observer.complete();
              },
              complete: () => {
                if (pending == 0) {
                  observer.next({ valid: result, min, address });
                  observer.complete();
                }
              }
            })
          }
        )
      }
    );
  }

  public checkRedirectCargo(cargo: Cargo, cameFrom: 'menu-service' | 'tracking' | 'detail'): { redirect: boolean, path: string } {
    if (!cargo) return { redirect: false, path: '' };
    if (cargo.state === CargoStateEnum.REQUEST)
      return { redirect: cameFrom !== 'menu-service', path: '/service-request-form/menu-service/' + cargo.consecutive.toString() };
    else if (cargo.state === CargoStateEnum.START_SERVICE)
      return { redirect: cameFrom !== 'tracking', path: '/cargo/tracking/' + cargo.consecutive };
    else
      return { redirect: cameFrom !== 'detail', path: '/cargo/detail/' + cargo.consecutive };
  }

  public requestEnvironmentType(vehicleType: string, bodyworkType: string): boolean {
    return vehicleType === "TRACTOCAMION" || bodyworkType === "FURGON";
  }

  public getRealDate(date: string, time: string): Date {
    return DateManager.stringToDate(`${DateManager.stringToDate(date).toISOString().split('T')[0]}T${time} -0500`, 'YYYY-MM-DDTHH:mm:ssZ');
  }

  public createCargoApproval(cargo: Cargo, cargoConsignments: ConsignmentCargo[]): CargoApproval {
    if (!this.isTripTypeNational(cargo)) return { cargoId: cargo.id };
    const lastAddressOrigin: AddressCargo = cargo.cargoFeature.uploadDownload.origin.addresses[cargo.cargoFeature.uploadDownload.origin.addresses.length - 1];
    const destinations = cargo.cargoFeature.uploadDownload.destination;
    const consignments = [];
    const OriginTimeCargo = this.getRealDate(cargo.dateLoad, lastAddressOrigin.time);
    for (const i in destinations) {
      for (const j in destinations[i].addresses) {
        const address = destinations[i].addresses[j];
        if (address && address.consignments) {
          for (const consignment of address.consignments) {
            const cargoConsignment = cargoConsignments.find(cargoConsignment => cargoConsignment.id === consignment);
            const originTime = cargoConsignment && cargoConsignment.load && cargoConsignment.load.date
              ? this.getRealDate(DateManager.formatDate(cargoConsignment.load.date, 'DD/MM/YYYY', 'YYYY-MM-DD'), cargoConsignment.load.appointmentTime)
              : OriginTimeCargo;
            const originTimeDeparture = cargoConsignment && cargoConsignment.load
              ? this.getDepartureDate(cargoConsignment.load.appointmentTime, cargoConsignment.load.hours, cargoConsignment.load.minutes, DateManager.formatDate(cargoConsignment.load.date, 'DD/MM/YYYY'))
              : this.getDepartureDate(address.time, address.timePact, address.minutePact, cargo.dateLoad);
            const dateDownload = cargoConsignment && cargoConsignment.unload && cargoConsignment.unload.date
              ? this.getDownloadDateBase(address, cargoConsignment.unload.appointmentTime, DateManager.formatDate(cargoConsignment.unload.date, 'DD/MM/YYYY'))
              : this.getDownloadDateBase(address, address.time, destinations[i].downloadDate);
            const dateDownloadDeparture = cargoConsignment && cargoConsignment.unload
              ? this.getDepartureDate(cargoConsignment.unload.appointmentTime, cargoConsignment.unload.hours, cargoConsignment.unload.minutes, DateManager.formatDate(cargoConsignment.unload.date, 'DD/MM/YYYY'))
              : this.getDepartureDate(address.time, address.timePact, address.minutePact, cargo.dateLoad);
            const consigmentForm = {
              id: address.consignments[0],
              destinationId: i,
              addressId: address.id,
              amountDelivered: cargoConsignment && cargoConsignment.totalWeight ? cargoConsignment.totalWeight
                : address.cargoMeasure && address.cargoMeasure.totalWeigth ? address.cargoMeasure.totalWeigth : 0,
              upload: {
                arrival: DateManager.formatDate(DateManager.add(originTime, 16, 'minutes')),
                entry: DateManager.formatDate(DateManager.add(originTime, 30, 'minutes')),
                departure: DateManager.formatDate(originTimeDeparture),
              },
              download: {
                arrival: DateManager.dateToString(DateManager.add(dateDownload, 0, 'minutes')),
                entry: DateManager.dateToString(DateManager.add(dateDownload, 16, 'minutes')),
                departure: DateManager.dateToString(dateDownloadDeparture),
              }
            };
            consignments.push(consigmentForm);
          }
        }
      }
    }
    return { cargoId: cargo.id, consignment: consignments };
  }

  public getDownloadDateBase(address: AddressCargo, addressTime: string, downloadDate: string): string {
    let dateDownload: string;
    if (address.dateTimeDownload && address.dateTimeDownload[0])
      dateDownload = address.dateTimeDownload[0];
    else if (address.durationTime && address.durationTime.startDate)
      dateDownload = address.durationTime.startDate;
    else dateDownload = `${downloadDate.slice(0, 10)} ${addressTime} -0500`;
    return dateDownload;
  }

  public getDepartureDate(addressTime: string, addressTimePact: number, addressMinutePact: number, dateLoad: string): Date {
    let originTime = this.getRealDate(dateLoad, addressTime);
    if (addressTimePact)
      originTime = DateManager.add(originTime, addressTimePact, 'hours');
    if (addressMinutePact)
      originTime = DateManager.add(originTime, addressMinutePact, 'minutes');
    if (!addressTimePact && !addressMinutePact)
      originTime = DateManager.add(originTime, 1, 'hours');
    return originTime;
  }

  public async modalPreoperationalInspection(cargo: Cargo, licensePlate?: string): Promise<'Delegated' | 'Inspected' | null> {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `Se requiere inspeccionar el vehículo`,

      iconAlert: true,
      description: `Se requiere realizar una inspección preoperacional del vehículo para verificar sus condiciones técnicas y de seguridad antes de iniciar el servicio. ¿Desea realizar la inspección ahora o delegarla al conductor?`,
      labelButton1: 'Delegar al conductor',
      labelButton2: 'Realizar inspección',
      hideBtnCancel: true,
      hideBtnConfirm: true,
    };
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialog = this.matDialog.open(DialogComponent, dialogConfig);
    const result = await dialog.afterClosed().toPromise();
    if (!result || !result.state) return null;
    if (result.refuse && result.refuse === "Delegar al conductor")
      return 'Delegated';

    dialogConfig.data = {
      user: this.authService.getUserSession().information,
      licensePlate: licensePlate ? licensePlate : cargo.licensePlate,
      id: cargo.id,
      cargo: cargo,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DialogSurveyComponent, dialogConfig);
    const resultSurvey = await dialogRef.afterClosed().toPromise();
    if (!!resultSurvey) return 'Inspected';
    return null;
  }

  public isEscortedService(cargo: Cargo): boolean {
    const serviceType: ServiceType = this.utils.getNestedValue(cargo, 'cargoModel.serviceType');
    return serviceType && serviceType.id === 'escortServices';
  }

  public async modalCargoCreation(cargos: Cargo[], title: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title,
      cargos
    };
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialog = this.matDialog.open(CargoCreationModalComponent, dialogConfig);
    const result = await dialog.afterClosed().toPromise();
    if (result && result.path) this.router.navigate([result.path]);
  }
}

<form [formGroup]="formDownload">
  <div class="container">
    <div id="mainFreightDownload" class="col-md-12 mb-md-0 mb-12">
      <div class="row">
        <!-- Title -->
        <div class="col-md-12 mb-md-0 mb-12">
          <label class="title">
            Datos de descargue
          </label>
        </div>
        <div class="col-md-12 mb-md-0 mb-12" *ngFor="let destination of destinations; let iDestination = index;">
          <label class="label-field">Destino #{{(iDestination + 1)}}: {{destination?.value?.name}}</label>
          <div class="row" *ngFor="let address of destination['controls'].addresses.controls; let i = index;">
            <mat-card-content class="col-md-12 mb-md-0 mb-12">
              <label class="label-field">Dirección #{{(i + 1)}}</label>
              <br>
              <span>{{address.controls.address.value}}</span>
              <div
                *ngIf="manualCreationCargoService.getVisualEstimatedTimeDownload(iDestination, i, address.controls.time.value,getPreviosAddTime(i, iDestination)) !== false">
                <div>
                  <mat-card class="mt-3 message-card" style="width: 90%;">
                    <mat-card-content class="d-flex flex-row">
                      <p class="color-alert  mr-2">
                        <i class="fas fa-ban"></i>
                      </p>
                      <p class="color-alert">
                        El tiempo de entrega no es válido, llegar a este punto requiere al menos
                        {{manualCreationCargoService.getVisualEstimatedTimeDownload(iDestination, i,
                        address.controls.time.value,getPreviosAddTime(i, iDestination))}} (Recordar que el tiempo
                        estimado incluye el tiempo pactado de la dirección anterior)
                      </p>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </mat-card-content>
            <div class="col-12 pl-0">
              <div class="row">
                <div class="col-12 col-xl-6">
                  <div class="row align-items-baseline">
                    <div class="col-12 col-md-8">
                      <mat-form-field>
                        <mat-label>Hora de descargue<span class="asterisk">*</span></mat-label>
                        <mat-select (selectionChange)="onSelectTime($event, 'time', iDestination, i)"
                          [formControl]="address.get('time')">
                          <mat-option *ngFor="let time of this.timeList" [value]="time.value">
                            {{time.title}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="address.get('time').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                      <mat-button-toggle-group class="justify-content-around align-items-baseline"
                        #group="matButtonToggleGroup" [value]="address.controls.timeType.value">
                        <mat-button-toggle value="AM" (change)="onSelectTime($event,'timeType', iDestination, i)">
                          AM
                        </mat-button-toggle>
                        <mat-button-toggle value="PM" (change)="onSelectTime($event,'timeType', iDestination, i)">
                          PM
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-6">
                  <mat-form-field>
                    <mat-label>
                      Celular
                    </mat-label>
                    <input matInput type="text" onlyNumbersInt placeholder="Escribe el número de celular"
                      [formControl]="address.get('cellphone')">
                    <mat-error
                      *ngIf="address.get('cellphone').errors?.minlength && !address.get('cellphone').errors?.pattern">
                      {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'celular', minLengthCellphone) }}
                    </mat-error>
                    <mat-error *ngIf="address.get('cellphone').errors?.maxlength">
                      {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'celular', maxLengthCellphone) }}
                    </mat-error>
                    <mat-error *ngIf="address.get('cellphone').errors?.pattern">
                      {{ utils.giveMessageError('INCORRECT_FORMAT', 'celular') }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12 pl-0">
              <div class="row">
                <div class="col-12 col-md-6">
                  <mat-form-field>
                    <mat-label>
                      Correo
                    </mat-label>
                    <input (keyup)="setUpperCase($event, iDestination, i)" matInput type="email"
                      placeholder="Escribe el Email" [formControl]="address.get('email')">
                    <i matSuffix *ngIf="address.get('email').errors && address.get('email').touched" matTooltip="No se permiten caracteres especiales al principio('.test@mail.com'),
                    caracteres especiales seguidos ('te..st@mail.com'), más de un arroba ('te@st@mail.com'),
                    espacios ('te st@mail.com'), caracteres especiales al terminar el dominio ('test.@mail.com'),
                    ni correos de extensión mayor a 100 caracteres"
                      class="fas fa-question-circle color-primary-dark cursor-default"></i>
                    <mat-error *ngIf="address.get('email').errors?.maxlength">
                      {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'correo', 100) }}
                    </mat-error>
                    <mat-error *ngIf="address.get('email').errors?.pattern">
                      {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                  <div class="row align-items-baseline">
                    <div class="col-5">
                      <mat-form-field>
                        <mat-label>Tiempo Pactado(horas):</mat-label>
                        <mat-select (selectionChange)="onSelectTime($event, 'timePact', iDestination, i)"
                          [(value)]="address.controls.timePact.value">
                          <mat-option *ngFor="let hour of hourList" [value]="hour.value">
                            {{hour.title}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-2 d-flex justify-content-center">
                      <label>:</label>
                    </div>
                    <div class="col-5">
                      <mat-form-field>
                        <mat-label>Tiempo Pactado(minutos):</mat-label>
                        <mat-select (selectionChange)="onSelectTime($event, 'minutePact', iDestination, i)"
                          [(value)]="address.controls.minutePact.value">
                          <mat-option *ngFor="let minute of minuteList" [value]="minute.value">
                            {{minute.title}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--div class="col-12 guide-container">
              <div class="row justify-content-end">
                <div class="col-12 col-lg-6">
                  <div class="container-guide" (click)="addGuide(iDestination, i)">
                    <section class="text-add-guide">Añadir guia</section><i
                      class="fas fa-plus-circle icon-plus-guide"></i>
                  </div>
                  <div *ngFor="let guidesId of address.get('guidesId').controls; let iGuide = index;">
                    <mat-form-field>
                      <mat-label>Nº de Guia</mat-label>
                      <input matInput type="text" (keyup)="onKeyUpGuideId($event, iDestination, i, iGuide)"
                        placeholder="Escribe el Nº de Guia">
                      <i matSuffix class="fas fa-times-circle icon-close" *ngIf="iGuide > 0"
                        (click)="removeGuideIdField(iDestination, i, iGuide)"></i>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div-->
            <div class="col-12 wrong-hour"
              *ngIf="!isValidAddress(i, iDestination) || !isValidToLast(i, iDestination) || !isValidToOrigin(i, iDestination)">
              <div class="message message--warning">
                {{!isValidToOrigin(i, iDestination)
                ?"La hora seleccionada de descargue no puede ser menor o igual a la última hora de cargue"
                :!isValidToLast(i, iDestination)
                ?"La hora seleccionada de descargue no puede ser menor o igual a la última hora de descargue del destino
                anterior"
                :"La hora seleccionada de descargue debe ser mayor a la hora de descargue de la anterior dirección"}}
              </div>
            </div>
            <div class="w-100 mb-3 ml-3">
              <div class="row mb-2"
                *ngFor="let guide of getDestinationAddressGuidesRecipients(iDestination, i).controls; let indexGuide = index;">
                <div class="col-md-12 d-flex justify-content-between text-bold">
                  <span>Guía N°{{indexGuide + 1}}</span>
                  <i class="fas fa-trash-alt cursor-pointer" [matTooltip]="'Eliminar guía'"
                    (click)="removeGuidesRecipients(iDestination, i, indexGuide)"></i>
                </div>
                <mat-form-field appearance="standard" class="col-md-6 m-0">
                  <mat-label>Nombre<span class="optional">(Opcional)</span></mat-label>
                  <input matInput type="text" [formControl]="guide.get('name')">
                </mat-form-field>
                <mat-form-field appearance="standard" class="col-md-6 m-0">
                  <mat-label>Celular<span class="optional">(Opcional)</span></mat-label>
                  <input matInput type="text" [formControl]="guide.get('phone')" onlyNumbersInt>
                </mat-form-field>
                <mat-form-field appearance="standard" class="col-md-6 m-0">
                  <mat-label>Correo<span class="optional">(Opcional)</span></mat-label>
                  <input matInput type="email" [formControl]="guide.get('email')">
                </mat-form-field>
              </div>
              <div class="w-100 text-bold underline cursor-pointer font-secondary-dark"
                (click)="addGuidesRecipients(iDestination, i)">
                <i class="fas fa-plus-circle"></i>
                <span>Agregar nueva guía</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Button -->
        <div class="col-12 button-container justify-content-center mt-3">
          <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
          <app-request-button></app-request-button>
          <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">Siguiente</button>
        </div>
      </div>
    </div>
  </div>
</form>
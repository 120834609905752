import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MenuServiceService } from './menu-service.service';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { CargoService } from 'src/app/core/services/cargo.service';
import { Utils } from 'src/app/core/resources/utils';
import { CargoTrackingService } from '../../cargo/cargo-tracking/cargo-tracking.service';
import { CargoDetailService } from '../../cargo/cargo-detail/cargo-detail.service';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { CargoStateDict, CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
import { Subscription } from 'rxjs';
import { Fmt } from 'src/app/core/messages/fmt';
import { ServiceResources } from '../resources/services';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { CargoConsignment } from 'src/app/core/interfaces/cargoEditConsignment';
import { ConsignmentCargo } from 'src/app/core/interfaces/consignmentCargo';

@Component({
  selector: 'app-menu-service',
  templateUrl: './menu-service.component.html',
  styleUrls: ['./menu-service.component.scss']
})
export class MenuServiceComponent implements OnInit, OnDestroy {
  cargo: Cargo;
  cargoChangesSub: Subscription;
  routerEventsSub: Subscription;
  steps: string[] = Object.values(this.serviceResources.serviceRequestUrls).map(url => url.split('/').pop());
  stepsRoutes: string[] = Object.keys(this.serviceResources.steps);
  showMenu: boolean = false;
  formSubscription: Subscription;
  stepsObject = [];
  consignmentsSubscription: Subscription;

  constructor(
    public menuService: MenuServiceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private serviceResources: ServiceResources,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private _cargoService: CargoService,
    public utils: Utils,
    private cargoDetailService: CargoDetailService,
    private cargoManager: CargoManager,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initSubscriptions();
    this.initCargo();
    this.initRouterEvents();
  }

  private async initCargo() {
    const params = this.route.snapshot.params;
    this.spinner.show();
    let initialCargo: Cargo;
    try { initialCargo = await this.getCargo(params.consecutive) } catch (error) { }
    this.spinner.hide();
    if (!initialCargo) {
      this.snackBarService.openSnackBar(`Ocurrió un error intentando traer el detalle del servicio`, undefined, "alert");
      this.goToList();
      return;
    }
    if (this.validateCargoState(initialCargo)) return;
    this.setCargo(initialCargo, false);
    await this.setCargoConsignments(initialCargo);
    this._cargoService.watch(this.cargo.id);
    this.cargoChangesSub = this._cargoService.onChange.subscribe(async (cargo: Cargo) => {
      if (cargo) {
        if (this.validateCargoState(cargo)) return;
        this.spinner.show();
        this.setCargo(cargo, true);
        await this.setCargoConsignments(cargo);
        this.spinner.hide();
      }
    }, console.error);

    if (!this.currentStep || !this.steps.includes(this.currentStep))
      this.setRoute(this.stepsRoutes[1]);
  }

  private initRouterEvents() {
    this.routerEventsSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && event && event.url) {
        if (event.url == `/service-request-form/menu-service/${this.cargo.consecutive}`)
          return history.back();
        const currentUrl = event.url.split('/').pop();
        if (!currentUrl || !this.steps.includes(currentUrl))
          this.setRoute(this.stepsRoutes[1]);
      }
    });
  }

  private initSubscriptions() {
    this.formSubscription = this.menuService.formObservable.subscribe(() => this.setStepsObject());
    this.consignmentsSubscription = this.menuService.consignmentsObservable.subscribe(() => this.setStepsObject());
  }

  private setStepsObject() {
    this.stepsObject = [
      {
        routeName: this.steps[0],
        routeUrl: this.stepsRoutes[1],
        isRequired: true,
        isValid: this.menuService.isValidContractorTripInfo(),
        title: 'Contratante del servicio',
        subSteps: [
          {
            isRequired: true,
            isValid: this.menuService.isValidTripType(),
            title: 'Tipo de viaje',
          },
          {
            isRequired: this.menuService.isRequiredMinistry(),
            isValid: this.menuService.isValidMinistry(),
            title: 'Requerimiento de manifiesto',
          },
          {
            isRequired: true,
            isValid: this.menuService.isValidCargoOwnerName(),
            title: 'Nombre del contratante',
          },
          {
            isRequired: this.menuService.isRequiredCargoOwnerBranchOffice(),
            isValid: this.menuService.isValidCargoOwnerBranchOffice(),
            title: 'Dirección del contratante',
          },
          {
            isRequired: true,
            isValid: { isValid: true },
            title: 'Código identificador',
          },
          {
            isRequired: true,
            isValid: { isValid: true },
            title: 'Etiquetas',
          }
        ],
      },
      {
        routeName: this.steps[1],
        routeUrl: this.stepsRoutes[2],
        isRequired: true,
        isValid: this.menuService.isValidOrigin(),
        title: 'Origen del servicio',
        subSteps: [
          {
            isRequired: true,
            isValid: this.menuService.isValidOriginCity(),
            title: 'Ciudad de origen',
          },
          {
            isRequired: true,
            isValid: this.menuService.isValidDateLoad(),
            title: 'Fecha estimada',
          },
          {
            isRequired: this.menuService.isRequiredReteica(),
            isValid: this.menuService.isValidReteica(),
            title: 'ReteIca',
          },
          {
            isRequired: true,
            isValid: this.menuService.isValidOriginAddresses(),
            title: 'Direcciones de origen',
          }
        ]
      },
      {
        routeName: this.steps[2],
        routeUrl: this.stepsRoutes[3],
        isRequired: true,
        isValid: this.menuService.isValidDestination(),
        title: 'Destino del servicio',
        subSteps: [
          {
            isRequired: true,
            isValid: this.menuService.isValidDestinationCities(),
            title: 'Ciudad de destino',
          },
          {
            isRequired: true,
            isValid: this.menuService.isValidDestinationAddresses(),
            title: 'Direcciones de destino',
          },
          {
            isRequired: !this.menuService.isEscortService(),
            isValid: { isValid: true },
            title: 'Guías',
          }
        ]
      },
      {
        routeName: this.steps[3],
        routeUrl: this.stepsRoutes[4],
        isRequired: true,
        isValid: this.menuService.isValidVehicleTypeInfo(),
        title: 'Vehículo',
        subSteps: [
          {
            isRequired: true,
            isValid: this.menuService.isValidVehicleType(),
            title: 'Tipo de vehículo',
          },
          {
            isRequired: true,
            isValid: this.menuService.isValidVehicleTypeBodyWorkType(),
            title: 'Tipo de carrocería',
          },
          {
            isRequired: this.menuService.isRequiredEnvironmentType(),
            isValid: this.menuService.isValidEnvironmentType(),
            title: 'Tipo de ambiente',
          }
        ]
      },
      {
        routeName: this.steps[4],
        routeUrl: this.stepsRoutes[5],
        isRequired: this.menuService.isRequiredLoadTypeService(),
        isValid: this.menuService.isValidLoadTypeService(),
        title: 'Tipo de servicio',
        subSteps: [
          {
            isRequired: this.menuService.isRequiredLetterRetirement(),
            isValid: this.menuService.isValidLetterRetirement(),
            title: 'Carta de retiro',
          },
          {
            isRequired: this.menuService.isRequiredRegimeType(),
            isValid: this.menuService.isValidRegimeType(),
            title: 'Tipo de régimen',
          },
          {
            isRequired: this.menuService.isRequiredDateVesselArrival(),
            isValid: this.menuService.isValidDateVesselArrival(),
            title: 'Fecha de descargue del buque',
          },
          {
            isRequired: this.menuService.isRequiredLoadType(),
            isValid: this.menuService.isValidLoadType(),
            title: 'Tipo de carga',
          },
          {
            isRequired: true,
            isValid: this.menuService.isValidServiceType(),
            title: 'Tipo de servicio',
          },
          {
            isRequired: this.menuService.isRequiredContainerSize(),
            isValid: this.menuService.isValidContainerSize(),
            title: 'Medida del contenedor',
          }
        ]
      },
      {
        routeName: this.steps[5],
        routeUrl: this.stepsRoutes[6],
        isRequired: true,
        isValid: this.menuService.isValidLoadCharacteristics(),
        title: 'Características del servicio',
        subSteps: [
          {
            isRequired: true,
            isValid: this.menuService.isValidRiskProfile(),
            title: 'Perfil de riesgo',
          },
          {
            isRequired: this.menuService.isRequiredContainerInfo(),
            isValid: this.menuService.isValidContainerInfo(),
            title: 'Datos del contenedor',
          },
          {
            isRequired: this.menuService.isRequiredMerchandiseCharacteristics(),
            isValid: this.menuService.isValidAmount(),
            title: 'Valor declarado de la mercancía',
          },
          {
            isRequired: this.menuService.isRequiredMerchandiseCharacteristics(),
            isValid: this.menuService.isValidTotalMeasurement(),
            title: 'Medida total',
          },
          {
            isRequired: this.menuService.isRequiredMerchandiseCharacteristics(),
            isValid: this.menuService.isValidQuantity(),
            title: 'Cantidad de unidades',
          },
          {
            isRequired: this.menuService.isRequiredMerchandiseCharacteristics(),
            isValid: this.menuService.isValidTotalWeight(),
            title: 'Peso total',
          },
          {
            isRequired: this.menuService.isRequiredMerchandiseCharacteristics(),
            isValid: this.menuService.isValidUnit(),
            title: 'Unidad de medida',
          },
          {
            isRequired: this.menuService.isRequiredMerchandiseCharacteristics(),
            isValid: this.menuService.isValidType(),
            title: 'Tipo de almacenamiento',
          }
        ]
      },
      {
        routeName: this.steps[6],
        routeUrl: this.stepsRoutes[7],
        isRequired: true,
        isValid: this.menuService.isValidServiceValue(),
        title: 'Valor del servicio',
        subSteps: [
          {
            isRequired: true,
            isValid: this.menuService.isValidServiceRate(),
            title: 'Valor de la tarifa',
          },
          {
            isRequired: true,
            isValid: this.menuService.isValidFreightCost(),
            title: 'Valor del flete',
          },
          {
            isRequired: true,
            isValid: this.menuService.isValidAdvancePercentage(),
            title: 'Porcentaje de anticipo',
          },
          {
            isRequired: true,
            isValid: this.menuService.isValidPaymentTime(),
            title: 'Tiempo de pago del saldo',
          },
          {
            isRequired: true,
            isValid: { isValid: true },
            title: 'Servicios adicionales',
          },
          {
            isRequired: true,
            isValid: { isValid: true },
            title: 'Viáticos',
          }
        ]
      },
      {
        routeName: this.steps[7],
        routeUrl: this.stepsRoutes[8],
        isRequired: true,
        isValid: { isValid: true },
        title: 'Observaciones',
        subSteps: [
          {
            isRequired: true,
            isValid: { isValid: true },
            title: 'Observaciones internas',
          },
          {
            isRequired: true,
            isValid: { isValid: true },
            title: 'Requisitos adicionales del conductor',
          }
        ]
      },
      {
        routeName: this.steps[8],
        routeUrl: this.stepsRoutes[9],
        isRequired: this.menuService.isRequiredConsignmentsService(),
        isValid: this.menuService.isValidConsignmentsService(),
        title: 'Manifiesto y remesas',
        subSteps: [
          {
            isRequired: true,
            isValid: this.menuService.isValidManifestType(),
            title: 'Tipo de manifiesto',
          },
          {
            isRequired: true,
            isValid: this.menuService.isValidOperationType(),
            title: 'Tipo de operación',
          },
          {
            isRequired: true,
            isValid: this.menuService.isValidConsignments(),
            title: 'Remesas',
          }
        ]
      },
      {
        routeName: this.steps[9],
        routeUrl: this.stepsRoutes[10],
        isRequired: true,
        isValid: this.menuService.isValidServicePublication(),
        title: 'Publicación',
        subSteps: []
      }
    ];
  }

  get currentStep(): string {
    const currentStep = this.router.url.split('/').pop();
    return currentStep && this.steps.includes(currentStep) ? currentStep : '';
  }

  private getCargo(consecutive: string): Promise<Cargo> {
    return this.cargoDetailService.detailCargoByConsecutive(consecutive).toPromise();
  }

  private validateCargoState(cargo: Cargo) {
    const { redirect, path } = this.cargoManager.checkRedirectCargo(cargo, 'menu-service');
    if (redirect) {
      this.showAlertChangeState(cargo);
      this.router.navigate([path]);
    }
    return redirect;
  }

  private showAlertChangeState(cargo: Cargo) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `El servicio ${cargo.consecutive} ha sido actualizado`,
      iconAlert: true,
      description: `El servicio ha pasado al estado de ${CargoStateDict[cargo.state] ? CargoStateDict[cargo.state].toLowerCase() : cargo.state}, se te redirigirá a la visual correpondiente`,
      hideBtnCancel: false,
      hideBtnConfirm: true,
    };
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.dialog.open(DialogComponent, dialogConfig);
  }

  private setCargo(cargo: Cargo, changesFromFirebase: boolean = true) {
    this.cargo = cargo;
    this.menuService.cargo = this.cargo;
    this.menuService.patchForm(this.cargo, changesFromFirebase);
  }

  private async setCargoConsignments(cargo: Cargo) {
    if (!cargo || !cargo.ministry) this.menuService.cargoConsignments = [];
    let consignments: ConsignmentCargo[] = [];
    try { consignments = await this.cargoDetailService.getCargoConsignments(cargo.id, 'Created').toPromise() } catch (error) { }
    this.menuService.cargoConsignments = consignments && consignments.length ? consignments : [];
  }

  /**
  * This method allows to go to an specific list of elements based on the path that pass it in the parameters.
  * @param {string} routeUrl (string - optional) is the path needed to redirect
  * @param {NavigationExtras} params (NavigationExtras - optional) extra parameters needed in the router navigate
  */
  private goToList(routeUrl?: string, params?: NavigationExtras) {
    if (routeUrl) {
      if (params) this.router.navigate([routeUrl, params]);
      else this.router.navigate([routeUrl]);
    }
    else this.router.navigate(["cargo/list/request"]);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  /**
   * @description Sets the route based on step number and validates progress
   * @param typeStep Type of step
   * @param step Step number
   */
  setRoute(step: string) {
    this.showMenu = false;
    this.router.navigate([Fmt.string(this.serviceResources.steps[step].url, this.cargo.consecutive)]);
  }

  ngOnDestroy() {
    if (this.cargoChangesSub) this.cargoChangesSub.unsubscribe();
    if (this.routerEventsSub) this.routerEventsSub.unsubscribe();
    if (this.formSubscription) this.formSubscription.unsubscribe();
    if (this.consignmentsSubscription) this.consignmentsSubscription.unsubscribe();
    this._cargoService.stopWatch();
  }
}

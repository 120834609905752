<aside class="col-md-12 mb-md-0 mb-12 h-100">
  <div class="container-list h-100">
    <div class="container-list-header">
      <div class="row">
        <div class="col-md-12">
          <app-autocomplete-city [inputFormControl]="cityControl" [options]="optionsCity">
        </app-autocomplete-city>
        </div>
      </div>
    </div>
    <div class="container-list-body" infinite-scroll [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScrollDown()">
      <div class="col-md-12 mb-md-0 mb-12" *ngFor="let City of listCities;">
        <app-city-item [city]="City"></app-city-item>
      </div>
    </div>
  </div>
</aside>

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CargoDetailComponent } from '../cargo-detail/cargo-detail.component';
import { CargoDetailService } from '../cargo-detail/cargo-detail.service';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cargo-generate',
  templateUrl: './cargo-generate.component.html',
  styleUrls: ['./cargo-generate.component.scss']
})
export class CargoGenerateComponent implements OnInit {

  public cargos: { consecutive: string, cargoId: string, message; string }[] = [];

  constructor(
    public matDialog: MatDialog,
    private cargoDetailService: CargoDetailService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
  }

  public onGeneretaFile($event) {
    if ($event && $event.length) {
      this.cargos = $event;
    } else {
      this.cargos = [];
    }
  }

  public async openDetailCargo(idCargo: string) {
    let cargo: Cargo;
    this.spinner.show();
    try { cargo = await this.cargoDetailService.detailCargo(idCargo).toPromise(); } catch (error) { }
    this.spinner.hide();
    if (!cargo) return this.snackBarService.openSnackBar(`Ocurrió un error intentando acceder al detalle del servicio`, undefined, "error");
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { cargo };
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    this.matDialog.open(CargoDetailComponent, dialogConfig);
  }

}
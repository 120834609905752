import { Injectable } from '@angular/core';
import { Config, driver, Driver, DriveStep } from 'driver.js';

@Injectable({
  providedIn: 'root'
})
export class OnboardingTourService {
  public driverInstance: Driver;
  public config: Config = {
    showProgress: true,
      animate: true,
      allowClose: true,
      doneBtnText: 'Finalizar',
      nextBtnText: 'Siguiente',
      prevBtnText: 'Anterior',
      onHighlightStarted: (element: HTMLElement) => {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  constructor() { 
    this.driverInstance = driver(this.config);
  }

  setDriverInstanceConfig(config?: Config) {
    this.driverInstance = driver(config ? config : this.config);
  }

  setSteps(steps: DriveStep[]) {
    this.driverInstance.setSteps(steps);
  }

  moveNext(): void {
    this.driverInstance.moveNext();
  }


  startTour(stepIndex: number = 0): void {
    this.driverInstance.drive(stepIndex);
  }

  stopTour(): void {
    this.driverInstance.destroy();
    this.driverInstance = null;
  }

}


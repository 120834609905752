<div class="main_guide_detail position-relative h-100">
  <i *ngIf="isDialog" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
    (click)="dialogRef.close()"></i>

  <div class="track-guide-aplication-detail overflow-scroll" [ngClass]="{'extra-margin-top': !hasSessionActive}">
    <div class="pl-2 pl-md-4 m-3 mx-md-5">
      <div class="row">
        <label class="label-field-2 text-break"> Número de Guía {{guide.guideId}} </label>
      </div>
      <div class="row mb-3">
        <div class="status">
          <div class="flex-left">
            <img src="/assets/svg/icons/icon-barcode-report.svg" alt="...">
            <div style="margin-left: 5px;">
              <div class="status-title">Estado actual</div>
              <div class="status-detail">{{guide.state | guideState}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="guide.cargoConsecutive">
        <i class="fas fa-chevron-right"></i>
        <span class="text-bold mr-2">No. de servicio</span>
        <span>{{guide.cargoConsecutive}}</span>
      </div>
      <div class="row" *ngIf="guide.creationDate">
        <i class="fas fa-calendar-week"></i>
        <span class="text-bold mr-2">Guía generada</span>
        <span>{{guide.creationDate | dateFormat: 'only-date'}}</span>
      </div>
      <div class="row" *ngIf="guide.deadline && !guide.deliveryDate">
        <i class="fas fa-calendar"></i>
        <span class="text-bold mr-2">Entrega estimada</span>
        <span>{{guide.deadline}}</span>
      </div>
      <div class="row" *ngIf="guide.deliveryDate">
        <i class="fas fa-calendar-check"></i>
        <span class="text-bold mr-2">Fecha de entrega</span>
        <span>{{guide.deliveryDate | dateFormat: 'only-date'}}</span>
      </div>

      <div class="row summary-top">

        <div class="col-12 col-lg-6">
          <div class="summary-title">Origen</div>
          <div class="summary-info">{{guide.originName}}</div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="summary-title">Destino</div>
          <div class="summary-info">
            {{guide?.recipient?.city ? guide?.recipient?.city : guide?.destinationName? guide.destinationName : '-'}}
          </div>
        </div>
      </div>
      <div class="mt-3" *ngIf="guide?.sender?.nameCompany">
        <div class="subtitle p-2 pl-4">Datos del remitente</div>
      </div>
      <div class="mx-2 mx-md-4 my-3">
        <div class="customer-data">
          <div class="d-flex flex-column flex-sm-row align-items-baseline" *ngIf="guide.sender?.nameCompany">
            <b class="text-bold mr-2">
              <i mat-list-icon class="fas fa-user"></i>
              Nombre
            </b>
            {{guide.sender?.nameCompany}}
          </div>
          <div class="d-flex flex-column flex-sm-row align-items-baseline" *ngIf="guide.sender?.phone">
            <b class="text-bold mr-2">
              <i mat-list-icon class="fas fa-phone-alt"></i>
              Celular
            </b>
            {{guide.sender?.phone}}
          </div>
        </div>
      </div>
      <div class="mt-3" *ngIf="guide?.recipient?.address">
        <div class="subtitle p-2 pl-4">Datos del destinatario</div>
      </div>
      <div class="mx-2 mx-md-4 my-3">
        <div class="customer-data">
          <div class="d-flex flex-column flex-sm-row align-items-baseline" *ngIf="guide.recipient?.name">
            <b class="text-bold mr-2">
              <i mat-list-icon class="fas fa-user"></i>
              Nombre
            </b>
            {{guide.recipient?.name}}
          </div>

          <div class="d-flex flex-column flex-sm-row align-items-baseline" *ngIf="guide.recipient?.address">
            <b class="text-bold mr-2">
              <i mat-list-icon class="fas fa-map-marker-alt"></i>
              Dirección
            </b>
            {{guide.recipient?.address}}
          </div>

          <div class="d-flex flex-column flex-sm-row align-items-baseline" *ngIf="guide.recipient?.phone">
            <b class="text-bold mr-2">
              <i mat-list-icon class="fas fa-phone-alt"></i>
              Celular
            </b>
            {{guide.recipient?.phone}}
          </div>

          <div class="d-flex flex-column flex-sm-row align-items-baseline" *ngIf="guide.recipient?.email">
            <b class="text-bold mr-2">
              <i mat-list-icon class="fas fa-envelope"></i>
              Correo
            </b>
            {{guide.recipient?.email}}
          </div>
        </div>
      </div>

      <div class="mt-3" *ngIf="guide?.stateHistoryGuide?.length">
        <div class="subtitle p-2 pl-4">Historial de la orden</div>
      </div>
      <div class="mt-5 mx-2 mx-md-5">
        <div class="flex-row align-items-stretch" *ngFor="let history of guide.stateHistoryGuide; let i = index"
          [ngClass]="{'d-none': hideAnomaly(history), 'd-flex': !hideAnomaly(history)}">
          <div class="caja">
            <div class="fs-1-5 text-bold">{{history?.date| dateFormat:'day'}}</div>
            <div class="gray-text">

              {{(history?.date | dateFormat:'month-sbt-3')}}
              {{history?.date | dateFormat:'year'}}</div>
            <i class="fas fa-exclamation-triangle color-red novelty-icon"
              [ngClass]="{'color-yellow': history?.type === 'Normal'}" *ngIf="isNovelty(history)"></i>
          </div>
          <div class="pl-2">
            <div class="fs-1-5 text-bold ml-2 ml-sm-3"
              [ngClass]="{'color-red': isNovelty(history), 'color-yellow': history?.type === 'Normal'}">
              {{showReturnToWarehouse(history) ? 'Retorno a bodega': (history?.name | guideState) }}
            </div>
            <div class="ml-2 ml-sm-3 gray-text">{{history?.date | dateFormat:'time'}}</div>
            <p class="ml-2 ml-sm-3 mt-2"
              *ngIf="history?.description && (history.visibleForRecipient || this.hasSessionActive)">
              {{history?.description}}
            </p>
            <div *ngIf="isNovelty(history) && hasSessionActive && historyImages[i]?.length">
              <p class="ml-2 ml-sm-3 mt-2 mb-0 text-bold">Evidencias</p>
              <div class="ml-2 ml-sm-3 popup-photos">
                <div class="photo" *ngFor="let image of historyImages[i]; let j = index;">
                  <img [src]="image?.url" alt="" (click)="openImageNewTab(i, j)"
                    (error)="utils.onErrorRenderImage($event)">
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>



</div>
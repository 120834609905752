<form [formGroup]="menuService.form" (ngSubmit)="saveAndContinue()" class="px-0 px-sm-3">
    <div class="container-fluid px-0 px-sm-3">
        <div *ngIf="canSeeDestinationDownloadService">
            <div class="d-flex flex-wrap" *ngFor="let destination of destinationsControls.controls; let i=index">
                <div class="col-12 px-0 d-flex justify-content-between align-items-center">
                    <p class="mb-2 text-bold">Destino {{i + 1}}</p>
                    <i class="fas fa-times cursor-pointer" matTooltip="Eliminar destino"
                        *ngIf="i && canEditDestinationDownloadService" (click)="removeDestinationForm(i)"></i>
                </div>
                <app-autocomplete-city [inputFormControl]="destination.get('destinationCity')"
                    [options]="destinationCityOptions[i]" [validate]="formValidate"
                    [ngClass]="{'col-md-6': !isInternationalTrip, 'col-md-12': isInternationalTrip}">
                </app-autocomplete-city>
                <div class="col-md-6 pb-3 pb-md-0" *ngIf="isInternationalTrip">
                    <label class="text-strong">¿Es Internacional?&nbsp;&nbsp;</label>
                    <mat-button-toggle-group [formControl]="destination.get('isInternational')"
                        #group="matButtonToggleGroup">
                        <mat-button-toggle [value]="true" (change)="onChangeInternational(i)">
                            Si
                        </mat-button-toggle>
                        <mat-button-toggle [value]="false" (change)="onChangeInternational(i)">
                            No
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>
                        Fecha estimada {{menuService?.isEscortService() ? 'de llegada' : 'de descargue'}}
                        <span class="asterisk">*</span>
                    </mat-label>
                    <input matInput
                        [min]="i ? destinationsControls?.controls[i - 1]?.get('destinationDate')?.value : originDate"
                        [matDatepicker]="pickerLoadDate" [formControl]="destination.get('destinationDate')"
                        name="destinationDate" (click)="pickerLoadDate.open()" readonly>
                    <mat-datepicker-toggle matSuffix [for]="pickerLoadDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerLoadDate></mat-datepicker>
                    <mat-error *ngIf="destination.get('destinationDate').errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                </mat-form-field>
                <mat-expansion-panel *ngFor="let address of getDestinationAddresses(i).controls; let j=index"
                    [expanded]="indexOpened.indexDestination === i && indexOpened.indexAddress === j"
                    [togglePosition]="'before'" class="col-12">
                    <mat-expansion-panel-header (click)="setIndexOpened(i, j)" class="cursor-pointer">
                        <mat-panel-title class="text-strong position-relative">
                            Direccion {{j + 1}}
                            <i class="fas fa-times-circle icon-delete" matTooltip="Eliminar dirección"
                                *ngIf="j && canEditDestinationDownloadService"
                                (click)="removeDestinationAddressForm(i, j)"></i>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="col-12 d-flex flex-wrap px-0"
                        *ngIf="indexOpened.indexDestination === i && indexOpened.indexAddress === j">
                        <mat-form-field appearance="outline" class="col-md-6">
                            <mat-label>Hora estimada <span class="asterisk">*</span></mat-label>
                            <input matInput [formControl]="address.get('time')" type="time">
                            <mat-error *ngIf="address.get('time').errors?.required">
                                {{ utils.giveMessageError('MANDATORY_FIELD') }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-md-6">
                            <mat-label>
                                Celular de contacto
                                <span class="optional">(opcional)</span>
                            </mat-label>
                            <input matInput onlyNumbersInt type="tel" [formControl]="address.get('cellphone')">
                            <mat-error *ngIf="address.get('cellphone').errors?.minlength">
                                {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'celular', 10) }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-12">
                            <mat-label>
                                Correo de contacto
                                <span class="optional">(opcional)</span>
                            </mat-label>
                            <input matInput type="email" [formControl]="address.get('email')">
                            <mat-error *ngIf="address.get('email').errors?.email">
                                {{ utils.giveMessageError('INVALID_EMAIL') }}
                            </mat-error>
                        </mat-form-field>
                        <app-autocomplete-address class="col-12" [appearance]="destinationAddressOptions.appearance"
                            [title]="destinationAddressOptions.title" [required]="destinationAddressOptions.required"
                            [addLatLong]="true"
                            [value]="initialAddresses[i]? initialAddresses[i][j]?.initialValue: null"
                            [simpleAddress]="true" [internationalCode]="destination.get('destinationCity')?.value?.code"
                            [disabled]="!canEditDestinationDownloadService"
                            (emitToParent)="onSelectAddress($event, i, j)">
                        </app-autocomplete-address>
                        <div class="col-12 row pl-3" *ngIf="!this.menuService.isEscortService()">
                            <p class="col-md-12 text-bold"
                                *ngIf="canEditDestinationDownloadService || getDestinationAddressGuidesRecipients(i, j)?.controls?.length > 0">
                                Informacion de guías
                            </p>
                            <div class="col-md-12 row mb-2 pr-0 mb-1"
                                *ngFor="let guide of getDestinationAddressGuidesRecipients(i, j).controls; let indexGuide = index;">
                                <div class="col-md-12 d-flex justify-content-between text-bold">
                                    <span>Guía N°{{indexGuide + 1}}</span>
                                    <i class="fas fa-trash-alt font-primary-light cursor-pointer"
                                        [matTooltip]="'Eliminar guía'"
                                        (click)="removeGuideRecipientForm(i, j, indexGuide)"></i>
                                </div>
                                <mat-form-field appearance="outline" class="col-md-4">
                                    <mat-label>Nombre<span class="optional">(Opcional)</span></mat-label>
                                    <input matInput type="text" [formControl]="guide.get('name')">
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-sm-6 col-md-4">
                                    <mat-label>Celular<span class="optional">(Opcional)</span></mat-label>
                                    <input matInput type="text" [formControl]="guide.get('phone')" onlyNumbersInt>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-sm-6 col-md-4">
                                    <mat-label>Correo<span class="optional">(Opcional)</span></mat-label>
                                    <input matInput type="email" [formControl]="guide.get('email')">
                                </mat-form-field>
                            </div>
                            <p class="text-bold font-secondary-dark pl-2 cursor-pointer"
                                (click)="addGuideRecipientForm(i, j)" *ngIf="canEditDestinationDownloadService">
                                <i class="fas fa-plus-circle"></i>
                                <span>Agregar guía</span>
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>

                <p class="text-bold font-secondary-dark pl-4 mt-2 mb-5 cursor-pointer"
                    (click)="addDestinationAddressForm(i, true)" *ngIf="canEditDestinationDownloadService">
                    <i class="fas fa-plus-circle"></i>
                    <span>Agregar otra dirección</span>
                </p>
            </div>
        </div>
        <p class="text-bold font-secondary-dark pl-2 cursor-pointer" *ngIf="canEditDestinationDownloadService"
            (click)="addDestinationForm(true)">
            <i class="fas fa-plus-circle"></i>
            <span>Agregar otro destino</span>
        </p>
        <div *ngIf="!canSeeDestinationDownloadService" class="no-permission-container">
            <i class="fas fa-exclamation-circle fa-lg m-2"></i>
            <h4 class="text-bold">No tienes permiso para acceder a esta información. Contacta a tu administrador para
                solicitar acceso.</h4>
        </div>

        <div class="button-container mt-4">
            <button mat-raised-button color="primary" class="button-width-auto primary-secondary button-small"
                [ngClass]="{'warn': true}" type="button" (click)="backToPreviousStep()">
                <span class="mat-button-wrapper">Atras</span>
            </button>
            <button type="submit" class="mx-2" mat-raised-button color="accent"
                *ngIf="canEditDestinationDownloadService">
                Guardar y Continuar
            </button>
            <button type="button" class="mx-2" mat-raised-button color="accent"
                *ngIf="!canEditDestinationDownloadService" (click)="continueToNextStep()">
                Continuar
            </button>
        </div>
    </div>
</form>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="row">
  <div class="col-md-12" formGroupName="cargoFeature"
    *ngIf="!isEscortedService && (!hiddenFields?.totalWeigth || !hiddenFields?.unit || !hiddenFields?.amountDeclared)">
    <div class="row" formGroupName="cargoMeasure"
      *ngIf="!hiddenFields?.totalWeigth || !hiddenFields?.unit || !hiddenFields?.amountDeclared">
      <div class="col-md-4" *ngIf="!hiddenFields?.totalWeigth">
        <mat-form-field appearance="standard">
          <mat-label>Peso total<span class="asterisk">*</span></mat-label>
          <input type="text" currencyMask onlyNumbersInt min="0" matInput placeholder="Peso"
            formControlName="totalWeigth">
          <mat-error *ngIf="cargoMeasure.get('totalWeigth').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
          <mat-error *ngIf="cargoMeasure.get('totalWeigth').errors?.min">
            {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'peso', minTotalWeight) }}
          </mat-error>
          <mat-error *ngIf="cargoMeasure.get('totalWeigth').errors?.max">
            {{ utils.giveMessageError('MAX_VALUE_EXCEED', 'peso', dataVehicle ? dataVehicle.weight:
            amountsCargoEnum.MAX_WEIGHT_ALLOWED_KG) }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4" *ngIf="!hiddenFields?.unit">
        <mat-form-field appearance="standard">
          <mat-label>Unidad<span class="asterisk">*</span></mat-label>
          <mat-select formControlName="unit" placeholder="Unidad">
            <mat-option [value]="'1'">
              Kilos
            </mat-option>
            <mat-option [value]="'2'">
              Galones
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4" *ngIf="!hiddenFields?.amountDeclared">
        <mat-form-field appearance="standard">
          <mat-label>Valor declarado de la mercancia</mat-label>
          <input type="text" currencyMask min="0" matInput placeholder="Escribe un valor COP" formControlName="amount">
          <mat-error *ngIf="cargoMeasure.get('amount').errors?.min">
            {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'valor', minAmount) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div formGroupName="cargoFeature" class="col-md-4" *ngIf="!hiddenFields?.vehicleType">
    <mat-card-content>
      <app-type-vehicle [options]="optionsTypeVehicle" [inputFormControl]="vehicleTypeControl"
        [validate]="validateTypeVehicle">
      </app-type-vehicle>
    </mat-card-content>
  </div>
  <div formGroupName="cargoFeature" class="col-md-4" *ngIf="!hiddenFields?.vehicleType">
    <mat-card-content>
      <app-vehicle-body-work-type *ngIf="vehicleType?.get('name')?.value" [inputFormControl]="bodyworkTypeControl"
        [validate]="validateBodyworkType" [options]="optionsBodyworkType">
      </app-vehicle-body-work-type>
    </mat-card-content>
  </div>
  <div formGroupName="cargoFeature" class="col-md-4">
    <div formGroupName="vehicleType">
      <mat-form-field *ngIf="canBeRefrigerated && !isEscortedService">
        <mat-label>Tipo de ambiente<span class="asterisk">*</span></mat-label>
        <mat-select formControlName="quality">
          <mat-option *ngFor="let environmentType of environmentTypes" [value]="environmentType">
            {{environmentType}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
<form class="routing__form--content py-4 px-2 px-sm-4" [formGroup]="form" (submit)="onSubmit()">
    <div class="routing__form--header">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            class="cursor-pointer" (click)="goBack()">
            <path
                d="M18.7912 11.005H7.62124L12.5012 6.12496C12.8912 5.73496 12.8912 5.09496 12.5012 4.70496C12.3144 4.51771 12.0608 4.41248 11.7962 4.41248C11.5317 4.41248 11.2781 4.51771 11.0912 4.70496L4.50124 11.295C4.11124 11.685 4.11124 12.315 4.50124 12.705L11.0912 19.295C11.4812 19.685 12.1112 19.685 12.5012 19.295C12.8912 18.905 12.8912 18.275 12.5012 17.885L7.62124 13.005H18.7912C19.3412 13.005 19.7912 12.555 19.7912 12.005C19.7912 11.455 19.3412 11.005 18.7912 11.005Z"
                fill="#495356" />
        </svg>
        <label class="routing__form--label">Valor declarado de la mercancia</label>
    </div>

    <div class="routing__form--body">
        <mat-form-field appearance="outline" class="plate-field">
            <mat-label>
                Tarífa
                <span class="optional">(Valor a pagar por el cliente)</span>
                <span class="asterisk">*</span>
            </mat-label>
            <input type="text" matInput formControlName="rate" currencyMask min="0" placeholder="$000.000">
            <mat-error *ngIf="form.get('rate').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="form.get('rate').errors?.min">
                {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'tarifa', minValue) }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="plate-field">
            <mat-label>
                Flete
                <span class="optional">(Valor a pagar al conductor)</span>
                <span class="asterisk">*</span>
            </mat-label>
            <input type="text" matInput formControlName="cost" currencyMask min="0" placeholder="$000.000">
            <mat-error *ngIf="form.get('cost').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="form.get('cost').errors?.min">
                {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'flete', minValue) }}
            </mat-error>
        </mat-form-field>

        <div class="alert alert-danger text-center" role="alert"
            *ngIf="utilityCargo < minUtility && !hasPermissionNoMinimumUtility">
            <i class="fas fa-exclamation-circle fa-lg mr-3"></i>
            <span class="text-bold">
                La utilidad no debe ser menor a {{minUtility}}%
            </span>
        </div>

        <div class="alert alert-warning text-center" role="alert"
            *ngIf="utilityCargo > 50 && form?.controls?.cost?.value > 0">
            <i class="fas fa-exclamation-circle fa-lg mr-3"></i>
            <span class="text-bold">La utilidad es mayor al 50%</span>
        </div>
    </div>

    <div class="button-container routing__form--footer">
        <button mat-raised-button color="primary" type="submit"
            [ngClass]="{'bg-color-primary':isValidStep, 'disabled-btn':!isValidStep}">
            Crear servicio
        </button>
    </div>

</form>
<form [formGroup]="form" class="d-flex flex-wrap">
    <div class="col-12 d-flex justify-content-between align-items-center">
        <p class="mb-2 text-bold">{{title}}</p>
        <i class="fas fa-times cursor-pointer" matTooltip="Eliminar remesa"
            *ngIf="canEditConsignmentServiceModule && canDeleteConsignment" (click)="removeConsignmentForm.emit()"></i>
    </div>
    <div class="col-12 alert alert-danger text-center text-bold" role="alert"
        *ngIf="form.get('errorCreation')?.value || form.get('errorInitialFulfillment')?.value || form.get('errorFinalFulfillment')?.value">
        {{form.get('errorCreation')?.value
        ? form.get('errorCreation')?.value :
        form.get('errorInitialFulfillment')?.value
        ? form.get('errorInitialFulfillment')?.value :
        form.get('errorFinalFulfillment')?.value
        ? form.get('errorFinalFulfillment')?.value : ''}}
    </div>
    <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>Tipo de remesa <span class="asterisk">*</span></mat-label>
        <mat-select [formControl]="operationTypeControl" [compareWith]="compareByCode"
            (selectionChange)="onChangeOperationType($event.value)">
            <mat-option *ngFor="let option of consignmentTypeOptions" [value]="option">
                <i matPrefix class="fas fa-info-circle color-secondary-dark cursor-pointer"
                    [matTooltip]="option.tooltip" matTooltipClass="tooltip__alert" matTooltipPosition="left"></i>
                {{ option.description }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="operationTypeControl?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>Naturaleza de la carga <span class="asterisk">*</span></mat-label>
        <mat-select [formControl]="cargoNatureControl" [compareWith]="compareByCode"
            (selectionChange)="onChangeCargoNature($event.value)">
            <mat-option *ngFor="let option of cargoNatureTypeOptions" [value]="option">
                {{ option.description }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="cargoNatureControl?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>Tipo de empaque<span class="asterisk">*</span></mat-label>
        <mat-select [formControl]="packagingTypeControl" (selectionChange)="onChangePackagingType($event.value)">
            <mat-option *ngFor="let packagingType of packagingTypeOptions" [value]="packagingType">
                {{ packagingType.description }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="packagingTypeControl?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <app-cargo-type-merchandise [inputFormControl]="merchandiseControl"
        [options]="{ appearance: 'outline', initialValue: form.get('merchandise')?.value?.description }"
        [validate]="formValidate" class="col-md-6">
    </app-cargo-type-merchandise>
    <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>Peso<span class="asterisk">*</span></mat-label>
        <input matInput currencyMask type="text" onlyNumbersInt min="0" formControlName="totalWeight">
        <mat-error *ngIf="form.get('totalWeight')?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="form.get('totalWeight')?.errors?.min">
            {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'peso', 0) }}
        </mat-error>
    </mat-form-field>
    <div class="col-md-6">
        <mat-label class="text-bold">Unidad de medida<span class="asterisk">*</span></mat-label>
        <mat-radio-group aria-label="Select an option" formControlName="weightUnit">
            <mat-radio-button value="1">Kilos</mat-radio-button>
        </mat-radio-group>
    </div>

    <p class="col-12 text-bold site-title">Sitio de cargue</p>
    <app-autocomplete-thirdparty class="col-md-6" [options]="{
        appearance: 'outline',
        title: 'Remitente',
        initialValue: form.get('sender')?.value?.documentNumber
    }" [inputFormControl]="senderThirdParty" [validate]="formValidate">
    </app-autocomplete-thirdparty>
    <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>Ciudad <span class="asterisk">*</span></mat-label>
        <mat-select (selectionChange)="onChangeCity($event.value, 'sender')" [formControl]="senderCityControl"
            [compareWith]="compareByKey">
            <mat-option *ngFor="let option of senderCityOptions | keyvalue; let j = index" [value]="option"
                [disabled]="!!isDisabledCity(option?.key, option?.value)"
                [matTooltip]="isDisabledCity(option?.key, option?.value)" matTooltipClass="tooltip__error"
                matTooltipPosition="right">
                {{ j + 1 }}. {{ option?.key? option?.key : 'Ciudad no diligenciada' }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="senderCityControl?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12">
        <mat-label>Dirección <span class="asterisk">*</span></mat-label>
        <mat-select (selectionChange)="onChangeAddress($event.value, 'sender')" [formControl]="senderAddressControl">
            <mat-option *ngFor="let option of senderCityControl?.value?.value?.addresses; let j = index"
                [value]="option" [disabled]="!!isDisabledAddress(option?.address, option)"
                [matTooltip]="isDisabledAddress(option?.address, option)" matTooltipClass="tooltip__error"
                matTooltipPosition="right">
                {{ j + 1 }}. {{ option?.address? option?.address : 'Dirección no diligenciada' }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="senderAddressControl?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>
            Fecha de cargue
            <span class="asterisk">*</span>
        </mat-label>
        <input matInput [matDatepicker]="pickerLoadDate" [formControl]="loadDateControl" name="date"
            (click)="pickerLoadDate.open()" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerLoadDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerLoadDate></mat-datepicker>
        <mat-error *ngIf="loadDateControl?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>Hora <span class="asterisk">*</span></mat-label>
        <input matInput [formControl]="form.get('load.appointmentTime')" type="time">
        <mat-error *ngIf="form.get('load.appointmentTime')?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <mat-form-field class="col-md-6" appearance="outline">
        <mat-label>Tiempo pactado<span class="asterisk">*</span></mat-label>
        <mat-select [formControl]="loadTimePactControl" [compareWith]="compareTimePact"
            (selectionChange)="onChangeTimePact($event.value, 'load')">
            <mat-option *ngFor="let option of timePactOptions" [value]="option">
                {{ option.description }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="loadTimePactControl?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <div class="col-md-6">
        <p role="alert" class="alert alert-warning text-bold d-flex align-items-center justify-content-center">
            Esta dirección de cargue será enviada al RNDC.
        </p>
    </div>

    <p class="col-12 text-bold site-title">Sitio de descargue</p>
    <app-autocomplete-thirdparty class="col-md-6" [options]="{
        appearance: 'outline',
        title: 'Destinatario',
        initialValue: form.get('recipient')?.value?.documentNumber
    }" [inputFormControl]="recipientThirdParty" [validate]="formValidate">
    </app-autocomplete-thirdparty>
    <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>Ciudad <span class="asterisk">*</span></mat-label>
        <mat-select (selectionChange)="onChangeCity($event.value, 'recipient')" [formControl]="recipientCityControl"
            [compareWith]="compareByKey">
            <mat-option *ngFor="let option of recipientCityOptions | keyvalue; let j = index" [value]="option"
                [disabled]="!!isDisabledCity(option?.key, option?.value)"
                [matTooltip]="isDisabledCity(option?.key, option?.value)" matTooltipClass="tooltip__error"
                matTooltipPosition="right">
                {{ j + 1 }}. {{ option?.key? option?.key : 'Ciudad no diligenciada' }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="recipientCityControl?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12">
        <mat-label>Dirección <span class="asterisk">*</span></mat-label>
        <mat-select (selectionChange)="onChangeAddress($event.value, 'recipient')"
            [formControl]="recipientAddressControl">
            <mat-option *ngFor="let option of recipientCityControl?.value?.value?.addresses; let j = index"
                [value]="option" [disabled]="!!isDisabledAddress(option?.address, option)"
                [matTooltip]="isDisabledAddress(option?.address, option)" matTooltipClass="tooltip__error"
                matTooltipPosition="right">
                {{ j + 1 }}. {{ option?.address? option?.address : 'Dirección no diligenciada' }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="recipientAddressControl?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>
            Fecha de descargue
            <span class="asterisk">*</span>
        </mat-label>
        <input matInput [matDatepicker]="pickerUnloadDate" [formControl]="unloadDateControl" name="date"
            (click)="pickerUnloadDate.open()" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerUnloadDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerUnloadDate></mat-datepicker>
        <mat-error *ngIf="unloadDateControl?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>Hora <span class="asterisk">*</span></mat-label>
        <input matInput [formControl]="form.get('unload.appointmentTime')" type="time">
        <mat-error *ngIf="form.get('unload.appointmentTime')?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <mat-form-field class="col-md-6" appearance="outline">
        <mat-label>Tiempo pactado<span class="asterisk">*</span></mat-label>
        <mat-select [formControl]="unloadTimePactControl" [compareWith]="compareTimePact"
            (selectionChange)="onChangeTimePact($event.value, 'unload')">
            <mat-option *ngFor="let option of timePactOptions" [value]="option">
                {{ option.description }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="unloadTimePactControl?.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <div class="col-md-6">
        <p role="alert" class="alert alert-warning text-bold d-flex align-items-center justify-content-center">
            Esta dirección de descargue será enviada al RNDC.
        </p>
    </div>
</form>
<form [formGroup]="menuService?.form" (ngSubmit)="saveAndContinue()" class="px-0 px-sm-3">
    <div class="container-fluid">
        <div class="row" *ngIf="canSeeGeneralData">
            <p class="col-12 my-3 text-bold">
                Tipo de viaje
            </p>
            <div class="col-md-6 mb-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Tipo de viaje<span class="asterisk">*</span></mat-label>
                    <mat-select [formControl]="tripTypeControl">
                        <mat-option *ngFor="let type of tripTypes" [value]="type.name">
                            {{ type.label }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="tripTypeControl.errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 mb-3" *ngIf="showMinistrySelector">
                <label class="text-strong mr-3">Requiere manifiesto de carga</label>
                <mat-button-toggle-group #group="matButtonToggleGroup" [formControl]="ministryControl">
                    <mat-button-toggle [value]="true">
                        Si
                    </mat-button-toggle>
                    <mat-button-toggle [value]="false">
                        No
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <p class="col-12 my-3">
                <span class="text-bold">Contratante del servicio</span>
                (Propietario del servicio / Generador de carga / Contratante)
            </p>
            <div class="col-12 px-0 d-flex flex-wrap" *ngIf="ministryControl.value; else ownerWithoutMinistry">
                <app-autocomplete-thirdparty class="col-md-6" [options]="cargoOwnerOptions"
                    [inputFormControl]="cargoOwnerControl" [validate]="cargoOwnerValidate">
                </app-autocomplete-thirdparty>
                <app-autocomplete-thirdparty-address class="col-md-6" *ngIf="cargoOwnerControl?.value?.document"
                    [inputFormControl]="cargoOwnerAddressControl" [validate]="cargoOwnerAddressValidate" [options]="{ 
                            title: 'Dirección del contratante',
                            appearance: 'outline',
                            companyId: cargoOwnerControl?.value?.document,
                            initialBranchOffice: menuService?.form?.get('cargoOwner.branchOffice')?.value
                        }">
                </app-autocomplete-thirdparty-address>
            </div>
            <ng-template #ownerWithoutMinistry>
                <app-select-company class="col-12" [options]="companyOptions" [inputFormControl]="companyControl"
                    [validate]="companyValidate">
                </app-select-company>
            </ng-template>
            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>
                    Código identificador de cliente
                    <span class="optional">(opcional)</span>
                </mat-label>
                <input matInput type="text" [formControl]="numberDocumentSenderControl">
                <i matSuffix class="fas fa-info-circle color-secondary-dark cursor-pointer"
                    matTooltip="Es el número del documento interno de identificación del cliente."
                    matTooltipClass="tooltip__alert" matTooltipPosition="right"></i>
            </mat-form-field>
            <div class="col-md-4" *ngIf="ministryControl.value && cargoOwnerAddressControl?.value?.address?.id">
                <small>Código de sede</small>
                <h5 class="text-bold p-0 pt-1">{{cargoOwnerAddressControl?.value?.address?.id}}</h5>
            </div>
        </div>
        <div class="row" *ngIf="canSeeTags">
            <p class="col-12 my-3 text-bold">Etiquetas</p>
            <div class="col-md-6 col-lg-4 mb-3" *ngFor="let tag of tagsSaved; let i = index">
                <div class="tag-card">
                    <p class="text-bold">{{tag}}</p>
                    <mat-icon *ngIf="canEditTags" class="close-icon" (click)="clearTag(i)">close</mat-icon>
                </div>
            </div>

            <mat-form-field appearance="outline" class="col-md-6 col-lg-4">
                <mat-label>Seleccionar etiqueta<span class="optional">(opcional)</span></mat-label>
                <mat-select [formControl]="tagControl" (selectionChange)="selectTag($event)">
                    <mat-option *ngFor="let tag of tags" [value]="tag.name"
                        [disabled]="menuService?.form?.get('labels')?.value?.includes(tag.name)">
                        {{tag.name}}
                    </mat-option>
                </mat-select>
                <i matPrefix class="fas fa-info-circle color-secondary-dark cursor-pointer"
                    matTooltip="Las etiquetas son identificadores que se le asignan al servicio."
                    matTooltipClass="tooltip__alert"></i>
            </mat-form-field>
        </div>
        <div *ngIf="!canSeeTags && !canSeeGeneralData" class="no-permission-container">
            <i class="fas fa-exclamation-circle fa-lg m-2"></i>
            <h4 class="text-bold">No tienes permiso para acceder a esta información. Contacta a tu administrador para
                solicitar acceso.</h4>
        </div>
        <div class="button-container mt-4">
            <button mat-raised-button color="primary" type="button" class="bg-color-primary"
                *ngIf="!canEditTags && !canEditGeneralData" (click)="continueToNextStep()">
                Continuar
            </button>
            <button mat-raised-button color="primary" type="submit" class="bg-color-primary"
                *ngIf="canEditTags || canEditGeneralData">
                Guardar y Continuar
            </button>
        </div>
    </div>
</form>
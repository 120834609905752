import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Utils } from 'src/app/core/resources/utils';
import * as firebase from 'firebase';
import { DatabaseReference } from '@angular/fire/database/interfaces';
import { AuthService } from 'src/app/core/services/authentication.service';
import { FirebaseDatabase } from 'src/app/core/services/firebaseDatabase.service';
import moment from 'moment';
import { StandardMapComponent } from 'src/app/shared/standard-map/standard-map.component';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Global } from 'src/app/core/resources/global';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { StandardMapService } from "src/app/shared/standard-map/standard-map.service";

@Component({
  selector: 'app-map-tracking',
  templateUrl: './map-tracking.component.html',
  styleUrls: ['./map-tracking.component.scss'],
  providers: [FirebaseDatabase]
})
export class MapTrackingComponent {

  @Input() public cargos: Cargo[] = [];
  @Input() public cargoSelected: Cargo;
  @ViewChild(StandardMapComponent, { static: false }) standardMapComponent: StandardMapComponent;
  cargoSelectedIndex: number;
  public referencesRealtimeCargos: Object = {};
  public cargoAnomaliesList: Object = {};
  public cargoTrackingList: Object = {};
  public lastPoints: Object = {};
  public locationsCargos: Object = {};
  private countCargos = 1;
  private tempLocationsCargos: Object = {};
  private tempLocationsCargo: Object = {};
  mapOptions: OptionsAutocomplete = {
    zoom: 16,
  };
  polyline: Array<Object> = [];
  cargosTemp: Cargo[] = [];
  constructor(
    public utils: Utils,
    private firebaseDatabase: FirebaseDatabase,
    public global: Global,
    public snackbarService: SnackBarService,
    private dialog: MatDialog,
    public standardMapService: StandardMapService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.cargos && changes.cargos.currentValue) {
      if (this.cargosTemp === null) {
        this.cargosTemp = this.cargosTemp;
      } else {
        this.cargosTemp = this.cargos;
      }
    }
    if (changes && changes.cargoSelected && changes.cargoSelected.currentValue || this.cargoSelected === null) {
      if (this.cargoSelected === null) {
        this.cargosTemp = this.cargos;
        this.cargoSelectedIndex = null;
        this.onRealtimeCargo();
      } else {
        this.cargoSelectedIndex = this.cargoSelected.consecutive;
        this.showAMarker(changes);
      }
    }
  }


  showAMarker(changes: SimpleChanges) {
    this.cargosTemp = [changes.cargoSelected.currentValue];
    if (!this.cargoSelected.lastPointLocation) {
      this.showMessageNoLocation(this.cargoSelected);
      this.cargosTemp = this.cargos;
    }
    this.onRealtimeCargo();
  }

  showMessageNoLocation(cargo: Cargo) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `La placa ${cargo.licensePlate} no tiene seguimientos registrados`,
      hideBtnConfirm: true,
      hideBtnCancel: true,
      iconError: true
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialog.open(DialogComponent, dialogConfig);
  }

  onRealtimeCargo() {
    if (this.cargosTemp) {
      this.standardMapComponent.removeAllMarkers();
      this.tempLocationsCargos = {};
      this.tempLocationsCargo = {};
      this.countCargos = 1;
      if (this.cargosTemp.length) {
        this.cargosTemp.forEach((cargo: Cargo) => {
          this.getDataTracking(cargo);
        });
      } else {
        this.refreshRoute('route');
      }
    }
  }

  /**
 * This method brings the anomalies of the load storage in a realtime collection of firebase according to the id of the load.
 */
  private getDataTracking(cargo: Cargo): void {
    this.referencesRealtimeCargos[cargo.consecutive] = this.firebaseDatabase.getRefDatabase(`cargo/${cargo.id}/tracking`);
    this.referencesRealtimeCargos[cargo.consecutive].on("value", async (response) => {
      try {
        const data = await this.firebaseDatabase.getDataTrackingSnapshot(response);
        this.cargoTrackingList[cargo.consecutive] = [...data.listLocations];
        this.getDataAnomalies(cargo);
      } catch (error) { console.error(error) }
    }, (error) => { console.error(error) })
  }

  /**
 * This method brings the anomalies of the load storage in a realtime collection of firebase according to the id of the load.
 */
  private getDataAnomalies(cargo: Cargo): void {
    const ref = this.firebaseDatabase.getRefDatabase(`cargo/${cargo.id}/anomalies`);
    ref.once('value')
      .then((data) => {
        const cargoAnomalies = data.val();
        this.cargoAnomaliesList[cargo.consecutive] =
          !cargoAnomalies || !Object.keys(cargoAnomalies) || !Object.keys(cargoAnomalies).length
            ? []
            : Object.keys(cargoAnomalies).map(key => {
              return { ...cargoAnomalies[key], key };
            });
        this.updateMarkers(cargo);
        ref.off();
      }).catch((err) => {
        console.error(err);
        this.updateMarkers(cargo);
        ref.off();
      });
  }

  async updateMarkers(cargo: Cargo) {
    const path = this.cargoTrackingList[cargo.consecutive];
    if (this.tempLocationsCargos[cargo.consecutive]) {
      if (path.length && path.length > 1) {
        this.standardMapComponent.removeMarker(path[path.length - 2].key);
      } else {
        this.standardMapComponent.removeMarker(cargo.consecutive + '_' + cargo.lastPointLocation.id);
      }
    }
    if (path.length) {
      if (this.cargosTemp.length === 1) {
        if (this.cargoSelected && this.cargoSelected.consecutive === cargo.consecutive) {
          this.polyline = [];
          this.setInfoStart();
          const polylineToSend = path.map(trackingLocation => {
            const index = this.cargoAnomaliesList[cargo.consecutive] ?
              this.cargoAnomaliesList[cargo.consecutive].findIndex(anomaly => {
                return !!(anomaly.latLng && anomaly.latLng.lat === trackingLocation.lat && anomaly.latLng.lng === trackingLocation.lng &&
                  anomaly.fingerprint && trackingLocation.fingerprint
                  && anomaly.fingerprint.date === trackingLocation.fingerprint.date)
              }) : -1;
            if (index !== -1) {
              if (this.cargoAnomaliesList[cargo.consecutive][index].name)
                trackingLocation['name'] = this.cargoAnomaliesList[cargo.consecutive][index].name;
              else if (this.cargoAnomaliesList[cargo.consecutive][index].anomaly)
                trackingLocation['anomaly'] = this.cargoAnomaliesList[cargo.consecutive][index].anomaly;
            }
            return trackingLocation;
          }).filter(location => location.lat && location.lng);
          this.polyline = polylineToSend;
          this.setInfoEnd();
          await this.createPoint(this.polyline, cargo);
          this.showAnomalies();
          this.refreshRoute('polyline');
        }
      }
      if (this.cargosTemp.length > 1) {
        await this.createLastPoint(path[path.length - 1], cargo);
      }
    } else if (cargo && cargo.lastPointLocation && cargo.lastPointLocation.location &&
      cargo.lastPointLocation.location.lat && cargo.lastPointLocation.location.lng) {
      let lastPoint = { ...cargo.lastPointLocation };
      lastPoint['lat'] = cargo.lastPointLocation.location.lat;
      lastPoint['lng'] = cargo.lastPointLocation.location.lng;
      lastPoint['key'] = cargo.consecutive + '_' + cargo.lastPointLocation.id;
      delete lastPoint['location'];

      await this.createLastPoint(lastPoint, cargo);
    }
    if (this.countCargos === this.cargosTemp.length) {
      if (this.cargosTemp.length > 1 || path.length == 0) {
        this.refreshRoute('route')
      }
    }
    if (this.countCargos !== this.cargosTemp.length) {
      this.countCargos++;
    }
    if (this.cargoSelected && this.cargoSelected.consecutive === cargo.consecutive && this.lastPoints[cargo.consecutive]) {
      this.standardMapComponent.setCenterMap(this.lastPoints[cargo.consecutive].lat, this.lastPoints[cargo.consecutive].lng, 16);
    }
  }

  async createLastPoint(lastPoint, cargo) {
    this.lastPoints[cargo.consecutive] = lastPoint;
    this.lastPoints[cargo.consecutive]['contentInfoWindow'] = await this.createContentInfoWindow(this.lastPoints[cargo.consecutive], cargo);
    this.lastPoints[cargo.consecutive]['showMarker'] = true;
    this.tempLocationsCargos[cargo.consecutive] = { path: [this.lastPoints[cargo.consecutive]], showPolyline: true, showRouteGoogle: false };
  }

  async createPoint(array, cargo) {
    for (const item of array) {
      this.lastPoints[item.position] = item;
      this.lastPoints[item.position]['contentInfoWindow'] = await this.createContentInfoWindow(this.lastPoints[item.position], cargo.consecutive.toString());
      this.lastPoints[item.position]['showMarker'] = !!(item.fingerprint && item.fingerprint.userId !== "SATRACK" && (item.anomaly || item.name));
      this.tempLocationsCargo[item.position || item.key] = { path: [this.lastPoints[item.position]], showPolyline: true, showRouteGoogle: true };
    }
  }

  showAnomalies() {
    if (this.polyline && this.polyline.length) {
      this.polyline.forEach(async (polyline, index, array) => {
        if (polyline) {
          const isLastElement = index === array.length - 2;
          if ((polyline.hasOwnProperty('name') && polyline['name'] !== 'Reiniciando ruta') || (polyline.hasOwnProperty('anomaly') && polyline['anomaly'] !== 'Reiniciando ruta')) {
            this.setMarkerProperties(polyline, isLastElement);
            polyline['showMarker'] = true;
          }
          if (isLastElement) {
            polyline['showMarker'] = true;
            polyline['setCenter'] = true;
            polyline['zoom'] = 16;
          }
        }
      })
    }
  }
  async setMarkerProperties(polyline, lastItem: boolean) {
    if (!lastItem) {
      let iconName = polyline.hasOwnProperty('name') && polyline.name ? polyline['name'] : polyline.hasOwnProperty('anomaly') ? polyline['anomaly'] : null;
      polyline['icon'] = iconName ? this.standardMapService.getIcon(iconName) : this.global.pathMarkerVehicleNoLastLocations;
      polyline['icon'] = polyline['icon'] ? polyline['icon'] : this.global.pathMarkerVehicleNoLastLocations;
    }
    polyline['contentInfoWindow'] = await this.createContentInfoPolylineWindow(polyline);
  }

  async refreshRoute(key): Promise<void> {
    this.locationsCargos = {};
    return new Promise((resolve, reject) => {
      try {
        if (key === 'polyline') {
          const path = this.polyline;
          path.pop()
          this.locationsCargos[this.cargoSelected.id] = { path, showPolyline: true, showRouteGoogle: true };
          this.mapOptions['route'] = this.tempLocationsCargo;
          this.mapOptions[key] = this.locationsCargos;
          this.mapOptions = { ...this.mapOptions };
        } else if (key === 'route') {
          this.locationsCargos = this.tempLocationsCargos;
          this.mapOptions['polyline'] = [];
          this.mapOptions[key] = this.locationsCargos;
          this.mapOptions = { ...this.mapOptions };
        }
        this.mapOptions['removeAllPolylines'] = true;
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  private createCargoPath(resp: any) {
    let path = [];
    const arrayRoute = this.utils.snapshotToArray(resp);
    if (arrayRoute.length) {
      path = arrayRoute.sort(
        (objA, objB) => Number(objA.date) - Number(objB.date),
      );
    }
    return path;
  }

  private createContentInfoWindow(lastPoint: any, cargo: Cargo) {
    let content = '';
    if (lastPoint && lastPoint.fingerprint) {
      let formattedDate = lastPoint && lastPoint.fingerprint && lastPoint.fingerprint.date && typeof lastPoint.fingerprint.date === 'object' ? lastPoint.fingerprint.date.time ? DateManager.formatDate(lastPoint.fingerprint.date.time) : '-' : lastPoint.fingerprint.date;
      content = `
      <b>${cargo.licensePlate ? cargo.licensePlate : '-'}</b><br/>
      <b>Fecha:</b> ${formattedDate ? formattedDate.slice(0, 16) : '-'}<br/>
      <b>Usuario:</b> ${lastPoint.fingerprint && lastPoint.fingerprint.userName ? lastPoint.fingerprint.userName : '-'}<br/>
      <b>Posición:</b> (${this.utils.isDefined(lastPoint.lat) && this.utils.isDefined(lastPoint.lng) ? lastPoint.lat + ', ' + lastPoint.lng : '-'})
      ${lastPoint && lastPoint.anomaly ? `<br/><b>Anomalía:</b> ${lastPoint.anomaly}` : ''}
      ${lastPoint && lastPoint.observation ? `<br/><b>Observación:</b> ${lastPoint.observation}` : ''}
      ${lastPoint && lastPoint.observation ? `<br/><b>Observación:</b> ${lastPoint.observation}` : ''}
      ${cargo.lastPointLocation ? `<br/><b>Último seguimiento:</b> ${cargo.lastPointLocation.name}` : ''}
      ${cargo.lastPointLocation && cargo.lastPointLocation.observation ? `${cargo.lastPointLocation.observation}` : ''}
      ${!!lastPoint ? `<br/><b>Registro:</b> ${lastPoint.fromWeb ? 'Web' : 'Conductor'}<br/>` : ''}`
    } else {
      const consecutiveValue = cargo.consecutive ? cargo.consecutive.toString() : '-';
      content = `${cargo.licensePlate ? cargo.licensePlate : '-'} <br> Servicio ${consecutiveValue}: <br> sin reporte`;
    }
    return content;
  }

  private createContentInfoPolylineWindow(polyline) {
    let content = '';
    const anomalyName = polyline && polyline.name ? polyline.name : polyline.anomaly;
    content =
      `
        <b>Novedad:</b> ${anomalyName}<br/>
        <b>Fecha:</b> ${polyline.fingerprint && polyline.fingerprint.date ? polyline.fingerprint.date.slice(0, 16) : '-'}<br/>
        <b>Observación:</b> ${polyline.observation ? polyline.observation : '-'}<br/>
        <b>Responsable:</b> (${polyline.fingerprint && polyline.fingerprint.userId ? polyline.fingerprint.userId : '-'}) ${polyline.fingerprint.userName}<br/>
        <b>Origen:</b> ${polyline.fromWeb ? 'Web' : 'Conductor'}<br/>
        `
    return content;
  }

  deleteAllObservables() {
    Object.keys(this.referencesRealtimeCargos).forEach((key) => {
      if (this.referencesRealtimeCargos[key]) this.referencesRealtimeCargos[key].off();
    })
  }

  ngOnDestroy() {
    this.deleteAllObservables();
  }

  setInfoStart() {
    if (!this.cargoSelected) return '';
    const addresses = [];
    this.cargoSelected.cargoFeature.uploadDownload.origin.addresses.forEach((address) => {
      addresses.push(address);
    });
    for (const destination of this.cargoSelected.cargoFeature.uploadDownload.destination) {
      destination.addresses.forEach((address) => {
        addresses.push(address);
      });
    }
    if (addresses.length > 1) {
      let start = addresses[0];
      if (start) {
        this.polyline.push({
          key: 'originMarker',
          icon: this.global.pathMarkerOrigin,
          lat: start.location && start.location.lat ? start.location.lat : null,
          lng: start.location && start.location.lng ? start.location.lng : null,
          contentInfoWindow: `
          <b>Dirección:</b> ${start.address ? start.address : '-'}<br/>
          <b>Inicio:</b> ${start.durationTime && start.durationTime.startDate ? start.durationTime.startDate.slice(0, 16) : '-'}
          `,
          showMarker: true
        });
      }
    }
  }

  setInfoEnd() {
    if (!this.cargoSelected) return '';
    const addresses = [];
    this.cargoSelected.cargoFeature.uploadDownload.origin.addresses.forEach((address) => {
      addresses.push(address);
    });
    for (const destination of this.cargoSelected.cargoFeature.uploadDownload.destination) {
      destination.addresses.forEach((address) => {
        addresses.push(address);
      });
    }
    if (addresses.length > 1) {
      let end = addresses[addresses.length - 1];
      if (end) {
        this.polyline.push({
          key: 'destinationMarker',
          icon: this.global.pathMarkerDestination,
          lat: end.location && end.location.lat ? end.location.lat : null,
          lng: end.location && end.location.lng ? end.location.lng : null,
          contentInfoWindow: `
          <b>Dirección:</b> ${end.address ? end.address : '-'}<br/>
          <b>fin:</b> ${end.durationTime && end.durationTime.endDate ? end.durationTime.endDate.slice(0, 16) : '-'}
          `,
          showMarker: true
        });
      }
    }
  }

}

import { Component, OnInit, Input, EventEmitter, SimpleChanges, Output, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MenuServiceService } from '../../menu-service/menu-service.service';
import { Utils } from 'src/app/core/resources/utils';
import { ActivatedRoute } from '@angular/router';
import { AdditionalCost } from 'src/app/core/interfaces/additionalCost';
import { AdditionalCostCargo } from 'src/app/core/interfaces/additionalCostCargo';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { DataDialogCargoAdditionalService } from 'src/app/core/interfaces/dataDialogCargoAdditionalService';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { Fingerprint } from 'src/app/core/interfaces/fingerprint';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { AuthService } from 'src/app/core/services/authentication.service';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Destination } from 'src/app/core/interfaces/destination';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { Subscription } from 'rxjs';

//TODO: Revisar logica para campos de servicios adicionales
@Component({
  selector: 'app-additional-service-form',
  templateUrl: './additional-service-form.component.html',
  styleUrls: ['./additional-service-form.component.scss']
})
export class AdditionalServiceFormComponent implements OnInit {

  additionalCostsArray = new FormArray([]);
  permission = Permission;
  listAdditionalService: AdditionalCost[] = [];
  selectedServiceControl = new FormControl();
  suggestionMessage: string = '';
  formSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private utils: Utils,
    public menuService: MenuServiceService,
    private authService: AuthService,
    private permissionRole: PermissionRole,
    private route: ActivatedRoute,
    private manualCreationCargoService: ManualCreationCargoService
  ) { }

  ngOnInit() {
    this.setLocalValues();
    this.getAdditionalCosts();
    this.setSubscription();
  }

  private setLocalValues() {
    this.additionalCostsArray.clear();
    const additionalCosts: AdditionalCostCargo[] = this.utils.getNestedValue(this.menuService.form.value, 'additionalCosts');
    if (additionalCosts && additionalCosts.length)
      additionalCosts.forEach(additionalCost => {
        const additionalCostGroup = new ReactiveForm(this.fb, additionalCost).form;
        additionalCostGroup.disable();
        this.additionalCostsArray.push(additionalCostGroup);
      });
    this.setSuggestionMessage();
  }

  private setSuggestionMessage() {
    this.suggestionMessage = '';
    const cargoAmount: number = this.utils.getNestedValue(this.menuService.form.value, 'cargoFeature.cargoMeasure.amount');
    if (!cargoAmount) return;
    const arrayAdditionalCosts: AdditionalCostCargo[] = this.additionalCostsArray.getRawValue();
    if (cargoAmount >= AmountsCargoEnum.MEDIUM_END) {
      const mustShow = !(arrayAdditionalCosts && arrayAdditionalCosts.length
        && arrayAdditionalCosts.reduce((acc, addServ) => { if (addServ.type.name === "Servicios de escolta") { return acc + 1 } else { return acc } }, 0) >= 2
        && arrayAdditionalCosts.some(addServ => addServ.type.name === "Sello satelital"));
      if (mustShow) this.suggestionMessage = "Se recomienda hacer uso de dos escoltas y candado satelital";
    } else if (cargoAmount >= AmountsCargoEnum.LOW_END) {
      const mustShow = !(arrayAdditionalCosts && arrayAdditionalCosts.length
        && arrayAdditionalCosts.some(addServ => addServ.type.name === "Servicios de escolta")
        && arrayAdditionalCosts.some(addServ => addServ.type.name === "Sello satelital"));
      if (mustShow) this.suggestionMessage = "Se recomienda hacer uso de escolta y candado satelital";
    } else {
      const mustShow = !(arrayAdditionalCosts && arrayAdditionalCosts.length
        && arrayAdditionalCosts.some(addServ => addServ.type.name === "Sello satelital"));
      if (mustShow) this.suggestionMessage = "Se recomienda hacer uso de candado satelital";
    }

  }

  private getAdditionalCosts() {
    this.route.data.subscribe((data: { aditionalServicesResolver: AdditionalCost[] }) => {
      if (data && data.aditionalServicesResolver)
        this.listAdditionalService = this.utils.clone(data.aditionalServicesResolver);
      else if (this.manualCreationCargoService.getListAdditionalCost().length)
        this.listAdditionalService = this.utils.clone(this.manualCreationCargoService.getListAdditionalCost());
      else
        this.manualCreationCargoService.getValueAdditionalCost().subscribe((data) => {
          this.listAdditionalService = this.utils.clone(data);
        });
    });
  }

  private setSubscription() {
    this.formSubscription = this.menuService.formObservable.subscribe(() => this.setLocalValues());
  }

  get canAttachService() {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.additionalCostCreate);
  }

  addAdditionalService(service: AdditionalCost) {
    const fingerprint: Fingerprint = {
      userId: this.authService.getUserSession().information.document,
      userName: this.authService.getUserSession().information.name,
      date: DateManager.dateToString(new Date(), 'YYYY-MM-DD HH:mm ZZ')
    };
    const additionalCostCargo = this.utils.createAdditionalCostCargo(service, this.canAttachService, fingerprint);
    const additionalCostGroup = new ReactiveForm(this.fb, additionalCostCargo).form;
    this.additionalCostsArray.push(additionalCostGroup);
    this.setSuggestionMessage();
    this.selectedServiceControl.setValue(null);
  }

  removeAdditionalService(index: number) {
    this.additionalCostsArray.removeAt(index);
    this.setSuggestionMessage();
  }

  ngOnDestroy() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }
}

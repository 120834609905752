<form [formGroup]="menuService?.form" (ngSubmit)="saveAndContinue()" class="px-0 px-sm-3">
    <div class="container-fluid d-flex flex-wrap px-0 px-sm-3" *ngIf="canSeeLoadCharacteristics">
        <!-- Risk profile -->
        <h6 class="text-bold mb-3 col-12">Perfil de riesgo del servicio</h6>
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>
                Perfil de riesgo
                <span class="asterisk" *ngIf="!menuService.isEscortService()">*</span>
                <span class="optional" *ngIf="menuService.isEscortService()">(opcional)</span>
            </mat-label>
            <mat-select [formControl]="riskProfileControl" [compareWith]="compareById">
                <mat-option *ngFor="let profile of riskProfiles" [value]="profile">
                    {{ profile.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="riskProfileControl.errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
        <!-- Container data -->
        <div class="col-12 d-flex flex-wrap p-0" *ngIf="menuService.isContainerService()">
            <h6 class="text-bold mb-3 col-12">Datos del contenedor</h6>
            <mat-form-field appearance="outline" class="col-md-6 mb-3">
                <mat-label>N° de contenedor <span class="optional">(opcional)</span></mat-label>
                <input matInput [formControl]="container" type="text" angularFormsMask="CCCCDDDDDDD" containerNumber>
                <mat-error *ngIf="container.errors?.pattern">
                    {{utils.giveMessageError('INVALID_CONTAINER_GENERAL')}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Precinto <span class="optional">(opcional)</span></mat-label>
                <input matInput [formControl]="seal">
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Fecha de expiración <span class="optional">(opcional)</span></mat-label>
                <input matInput type="text" [formControl]="containerExpirationDate" (click)="datePicker.open()"
                    [matDatepicker]="datePicker" readonly>
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
                <mat-error *ngIf="containerExpirationDate.errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Booking <span class="optional">(opcional)</span></mat-label>
                <input matInput [formControl]="booking">
            </mat-form-field>
        </div>

        <!-- Load characteristics -->
        <div class="col-12 d-flex flex-wrap p-0" *ngIf="!menuService.isEscortService()">
            <h6 class="text-bold mb-3 col-12">Características del servicio</h6>
            <mat-form-field appearance="outline" class="col-12 mb-3">
                <mat-label>Valor declarado de la mercancía<span class="asterisk">*</span></mat-label>
                <input type="text" currencyMask [min]="minAmount" matInput placeholder="Escribe un valor COP"
                    [formControl]="amountControl">
                <mat-error *ngIf="amountControl.errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
                <mat-error *ngIf="amountControl.errors?.min">
                    {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'valor declarado', minAmount) }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-6 mb-3">
                <mat-label>Medida total<span class="asterisk">*</span></mat-label>
                <input type="text" currencyMask matInput placeholder="Ejm: 100 Metros cúbicos"
                    [formControl]="totalMeasurementControl">
                <span matSuffix class="optional d-none d-sm-inline">metros cúbicos</span>
                <i class="fas fa-calculator ml-1" matSuffix [ngClass]="{'cursor-pointer': canEditLoadCharacteristics}"
                    (click)="openDialogCalculatorMeasurements()" matTooltip="Calcular metros cúbicos"></i>
                <mat-error *ngIf="totalMeasurementControl.errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
                <mat-error *ngIf="totalMeasurementControl.errors?.min">
                    {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'medida total',
                    minTotalWeigth) }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-6 mb-3">
                <mat-label>N° de unidades<span class="asterisk">*</span></mat-label>
                <input matInput type="text" onlyNumbersInt [min]="minQuantity" [formControl]="quantityControl">
                <mat-error *ngIf="quantityControl.errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
                <mat-error *ngIf="quantityControl.errors?.min">
                    {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'unidades', minQuantity) }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-6 mb-3">
                <mat-label>Peso total<span class="asterisk">*</span></mat-label>
                <input matInput currencyMask type="text" onlyNumbersInt [min]="minTotalWeigth"
                    [formControl]="totalWeigthControl">
                <mat-error *ngIf="totalWeigthControl.errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
                <mat-error *ngIf="totalWeigthControl.errors?.min">
                    {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'peso total', minTotalWeigth) }}
                </mat-error>
                <mat-error *ngIf="totalWeigthControl.errors?.max">
                    {{ utils.giveMessageError('MAX_VALUE_EXCEED', 'peso total', maxTotalWeigth) }}
                </mat-error>
            </mat-form-field>
            <div class="unit-field col-md-6">
                <mat-label>Unidad de medida<span class="asterisk">*</span></mat-label>
                <mat-radio-group aria-label="Select an option" [formControl]="unitControl">
                    <mat-radio-button [value]="'1'">Kilos</mat-radio-button>
                    <mat-radio-button [value]="'2'">Galones</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col-md-6">
                <mat-button-toggle-group class="value-load-button-toggle btn-toggle w-100" #group="matButtonToggleGroup"
                    [formControl]="typeControl">
                    <mat-button-toggle [value]="'Apilable'">Apilable</mat-button-toggle>
                    <mat-button-toggle [value]="'Delicado'">Delicado</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
    </div>
    <div *ngIf="!canSeeLoadCharacteristics" class="no-permission-container">
        <i class="fas fa-exclamation-circle fa-lg m-2"></i>
        <h4 class="text-bold">No tienes permiso para acceder a esta información. Contacta a tu administrador para
            solicitar acceso.</h4>
    </div>

    <!-- Action buttons -->
    <div class="button-container mt-4">
        <button mat-raised-button color="primary" class="button-width-auto primary-secondary button-small"
            [ngClass]="{'warn': true}" type="button" (click)="backToPreviousStep()">
            <span class="mat-button-wrapper">Atras</span>
        </button>
        <button class="mx-2" type="submit" mat-raised-button color="accent" *ngIf="canEditLoadCharacteristics">
            Guardar y Continuar
        </button>
        <button class="mx-2" type="button" mat-raised-button color="accent" (click)="continueToNextStep()"
            *ngIf="!canEditLoadCharacteristics">
            Continuar
        </button>
    </div>
</form>
<form [formGroup]="menuService?.form" (ngSubmit)="saveAndContinue()" class="px-0 px-sm-3">
    <div class="container-fluid">
        <div class="row" *ngIf="canEditVehicleService">
            <p class="col-12 my-3">
                <span class="text-bold">Vehiculo</span>
            </p>
            <app-type-vehicle class="col-md-6" [inputFormControl]="vehicleTypeControl" [options]="optionsTypeVehicle"
                [validate]="formValidate">
            </app-type-vehicle>
            <app-vehicle-body-work-type class="col-md-6" [inputFormControl]="bodyworkTypeControl"
                [options]="optionsBodyworkType" [validate]="formValidate">
            </app-vehicle-body-work-type>
            <div class="col-md-6" *ngIf="showEnvironmentControl">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Tipo de ambiente <span class="asterisk">*</span></mat-label>
                    <mat-select [formControl]="environmentControl">
                        <mat-option *ngFor="let environment of environments" [value]="environment.value">
                            {{environment.label}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="environmentControl?.errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="!canEditVehicleService" class="no-permission-container">
            <i class="fas fa-exclamation-circle fa-lg m-2"></i>
            <h4 class="text-bold">No tienes permiso para acceder a esta información. Contacta a tu administrador para
                solicitar acceso.</h4>
        </div>
        <div class="button-container mt-4">
            <button mat-raised-button color="primary" class="button-width-auto primary-secondary button-small"
                [ngClass]="{'warn': true}" type="button" (click)="backToPreviousStep()">
                <span class="mat-button-wrapper">Atras</span>
            </button>
            <button class="mx-2" type="submit" mat-raised-button color="accent" *ngIf="canEditVehicleService">
                Guardar y Continuar
            </button>
            <button class="mx-2" type="button" mat-raised-button color="accent" (click)="continueToNextStep()"
                *ngIf="!canEditVehicleService">
                Continuar
            </button>
        </div>
    </div>
</form>
<form (ngSubmit)="onSubmit()" [formGroup]="massCreationCargoForm">
  <aside class="h-100 w-100 py-4 px-2 px-sm-5 d-flex flex-wrap">
    <div class="title-component">
      Creación de Servicios Masivos
    </div>
    <div class="col-12 d-flex flex-wrap px-0 px-sm-2 align-items-baseline">
      <div class="col-12 p-0">
        <label class="text-strong">¿Requiere Manifiesto?&nbsp;&nbsp;</label>
        <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="manifest">
          <mat-button-toggle [value]="true">
            Si
          </mat-button-toggle>
          <mat-button-toggle [value]="false">
            No
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="col-12 advice-text my-2">
        <p>Descargue el siguiente formulario en Excel. Complételo y súbalo nuevamente a esta plataforma para crear
          servicios masivos:</p>
        <p class="underline font-secondary-dark text-bold" (click)="downloadFile()">
          <i class="fas fa-file-download"></i>
          Descargar formulario de servicios masivos
        </p>
      </div>
      <label class="label-field text-left mt-4 col-12">
        A continuación, suba su formulario de servicios masivos totalmente diligenciado para realizar el registro en la
        plataforma:
      </label>
      <div class="col-md-8">
        <mat-card-content>
          <app-file [inputFormControl]="fileControl" (emitToParent)="handleFileInput($event)"
            [options]="{storageName: 'massCreation', format: '.XLSX' }">
          </app-file>
        </mat-card-content>
      </div>
      <!-- Sheet Name -->
      <div class="col-md-4">
        <mat-form-field appearance="outline" *ngIf="showSheetSelector">
          <mat-label>Hoja</mat-label>
          <mat-select [formControl]="sheetNameControl">
            <mat-option *ngFor="let sheet of sheets" [value]="sheet.value">
              {{sheet.key}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="button-container col-12">
      <button type="submit" mat-raised-button color="primary" class="bg-color-primary">
        Crear servicios
      </button>
    </div>
  </aside>
</form>
<div class="modal-body wrapper-modal d-flex flex-column py-5 px-3 px-sm-5">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <h3 class="text-center text-bold mx-3" [ngClass]="{'mb-5': !data.address}">
        Guías asociadas
    </h3>
    <h5 class="text-bold text-center mb-5 initial-address" *ngIf="data.address">
        {{ data?.address?.address ? data?.address?.address : '-' }}
    </h5>
    <div class="row" *ngIf="!data.address">
        <mat-form-field appearance="outline" class="px-0 col-md-6">
            <mat-label class="text-bold">Filtrar por dirección</mat-label>
            <mat-select [formControl]="addressFilter">
                <mat-option value="">
                    Todas
                </mat-option>
                <mat-option *ngFor="let addressGroup of guideList" [value]="addressGroup?.address?.address"
                    [disabled]="!addressGroup?.guides?.length">
                    {{ addressGroup?.address?.address }}
                    <span class="text-bold" *ngIf="!addressGroup?.guides?.length">(Sin guías)</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngFor="let addressGroup of filteredGuideList; let i = index" class="mb-3">
        <p class="text-bold" *ngIf="!data.address && addressGroup?.guides?.length && !addressFilter?.value">{{
            addressGroup?.address?.address ?
            addressGroup?.address?.address : '-' }}</p>
        <div *ngFor="let guide of addressGroup?.guides; let j = index" class="guide-card">
            <div class="col-12">
                <p class="state-badge"
                    [ngClass]="{'finished': guide.generalState==='Finished', 'blocked': guide.generalState==='Locked'}">
                    {{guide?.state | guideState}}
                </p>
            </div>

            <div class="col-sm-6 col-md-4 col-xl-3 pr-0">
                <p class="m-0">N° de guía</p>
                <p class="text-bold mb-0 text-break">{{guide.guideId}}</p>
            </div>
            <div class="col-sm-6 col-md-3 col-xl-2">
                <p class="m-0">Destinatario</p>
                <p class="text-bold mb-0 text-break">
                    {{guide?.recipient?.name ? guide?.recipient?.name : 'Sin registrar' }}
                </p>
            </div>
            <div class="col-md-5 col-xl-4">
                <p class="m-0">Dirección del destinatario</p>
                <p class="text-bold mb-0 text-break">{{guide?.recipient?.address? guide?.recipient?.address :
                    addressGroup?.address?.address}}</p>
            </div>
            <i class="fas fa-ellipsis-v color-secondary-dark text-strong d-block d-xl-none cursor-pointer"
                mat-icon-button [matMenuTriggerFor]="guideMenu"></i>
            <mat-menu #guideMenu="matMenu" class="d-xl-none">
                <button mat-menu-item (click)="openDialogDetailGuide(guide)"
                    class="font-secondary-dark underline text-bold">
                    <i class="fas fa-eye"></i>
                    <span>Ver detalle</span>
                </button>
                <button mat-menu-item (click)="openDialogAddNovelty(guide.id, guide.guideId, addressGroup.address, i)"
                    *ngIf="canAddNovelty(guide)" class="font-secondary-dark underline text-bold">
                    <i class="fas fa-plus-circle"></i>
                    <span>Asignar novedad</span>

                </button>
            </mat-menu>
            <div class="col-3 flex-column font-secondary-dark d-none d-xl-flex">
                <div (click)="openDialogDetailGuide(guide)" class="cursor-pointer text-bold underline">
                    <i class="fas fa-eye"></i>
                    Ver detalle
                </div>
                <div (click)="openDialogAddNovelty(guide.id, guide.guideId, addressGroup.address, i)"
                    class="cursor-pointer text-bold underline" *ngIf="canAddNovelty(guide)">
                    <i class="fas fa-plus-circle"></i>
                    Asignar novedad

                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <h6 class="text-bold mt-3">Servicios Adicionales</h6>
    <!-- List of additional services -->
    <p class="text__color color--error text-strong e-message" *ngIf="suggestionMessage && canAttachService">
        {{suggestionMessage}}
    </p>
    <mat-form-field appearance="outline" *ngIf="canAttachService">
        <mat-label>Agregar un servicio adicional</mat-label>
        <mat-select [formControl]="selectedServiceControl" (selectionChange)="addAdditionalService($event.value)">
            <mat-option *ngFor="let additionalService of listAdditionalService" [value]="additionalService">
                {{additionalService.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <app-list-additional-services *ngIf="additionalCostsArray?.value?.length" [cargo]="menuService?.form?.value"
        [cargoId]="menuService?.form?.value?.id" [formArrayGroup]="additionalCostsArray"
        [companyId]="menuService?.form?.value?.idCompany" [approvalState]="menuService?.form?.value?.approval"
        [cargoApproval]="menuService?.form?.value?.approval" [listAdditionalService]="listAdditionalService"
        (removeAdditionalCostItem)="removeAdditionalService($event)">
    </app-list-additional-services>
</div>
<section class="modal-body wrapper-modal m-0">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <p class="title">
        Cumplido inicial para el servicio {{cargo.consecutive}}
    </p>

    <p class="subtitle">
        A continuación verifique las fechas de descargue antes de proceder con la finalización del servicio
    </p>

    <form [formGroup]="form" (submit)="onSubmit($event)" *ngIf="form">
        <!-- Origen -->
        <!--<div class="origin" formGroupName="origin">
            <p class="title text-left">
                Origen
            </p>

            <ul>
                <li class="address row"
                    *ngFor="let address of cargo.cargoFeature.uploadDownload.origin.addresses; let i = index;">
                    <div class="col-md-12">
                        {{address.address}}
                    </div>
                    <mat-form-field class="col-md-6">
                        <mat-label>Inicio del cargue</mat-label>
                        <input matInput readonly [ngxMatDatetimePicker]="origin_start_i" placeholder="Inicio del cargue"
                            [formControlName]="'startDate_'+i" [max]="current">
                        <mat-datepicker-toggle matSuffix [for]="origin_start_i"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #origin_start_i [showSeconds]="true">
                        </ngx-mat-datetime-picker>
                        <mat-error *ngIf="form.get('origin.startDate_'+i).hasError('minDate')">
                            Debe ser mayor a
                            {{
                            form.get('origin.startDate_'+i)
                            ?.errors
                            ?.minDate
                            ?.format('YYYY-MM-DD HH:mm:ss')
                            }}
                        </mat-error>
                        <mat-error *ngIf="form.get('origin.startDate_'+i).hasError('required')">
                            Esta fecha es obligatoria.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-md-6">
                        <mat-label>Fin del cargue</mat-label>
                        <input matInput readonly [ngxMatDatetimePicker]="origin_end_i" placeholder="Fin del cargue"
                            [formControlName]="'endDate_'+i" [max]="current">
                        <mat-datepicker-toggle matSuffix [for]="origin_end_i"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #origin_end_i [showSeconds]="true">
                        </ngx-mat-datetime-picker>
                        <mat-error *ngIf="form.get('origin.endDate_'+i).hasError('minDate')">
                            Debe ser mayor a
                            {{
                            form.get('origin.endDate_'+i)
                            ?.errors
                            ?.minDate
                            ?.format('YYYY-MM-DD HH:mm:ss')
                            }}
                        </mat-error>
                        <mat-error *ngIf="form.get('origin.endDate_'+i).hasError('required')">
                            Esta fecha es obligatoria.
                        </mat-error>
                    </mat-form-field>
                </li>
            </ul>
        </div>-->

        <div class="destinations" formGroupName="destinations">
            <p class="title text-left">
                Destinos
            </p>
            <ul class="p-0">
                <li *ngFor="let destination of cargo?.cargoFeature?.uploadDownload?.destination; let i = index;"
                    [formGroupName]="'destination_'+i">
                    <p class="title destination-title text-left" style="zoom: 0.62;">
                        {{destination.name}}
                    </p>
                    <ul class="p-0" *ngFor="let address of destination?.addresses; let j = index;">
                        <li class="address row pl-4 pl-sm-5 pt-2 pb-3"
                            *ngFor="let consignment of address?.consignments; let k = index;">
                            <p class="col-12 address-title text-bold">
                                {{consignment}} - {{address.address}}
                            </p>
                            <p class="col-12">Fecha prevista de descargue:</p>
                            <mat-form-field class="col-md-6" appearance="outline">
                                <mat-label>Inicio del descargue</mat-label>
                                <input matInput readonly [ngxMatDatetimePicker]="destination_min_start_j_k"
                                    placeholder="Inicio del descargue" [formControlName]="'minDate_'+j+'_'+k">
                                <mat-datepicker-toggle matSuffix
                                    [for]="destination_min_start_j_k"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #destination_min_start_j_k [showSeconds]="true">
                                </ngx-mat-datetime-picker>
                            </mat-form-field>
                            <mat-form-field class="col-md-6" appearance="outline">
                                <mat-label>Finalización del descargue</mat-label>
                                <input matInput readonly [ngxMatDatetimePicker]="destination_min_end_j_k"
                                    placeholder="Finalización del descargue" [formControlName]="'minDate_'+j+'_'+k">
                                <mat-datepicker-toggle matSuffix
                                    [for]="destination_min_end_j_k"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #destination_min_end_j_k [showSeconds]="true">
                                </ngx-mat-datetime-picker>
                            </mat-form-field>
                            <p class="col-12">Fecha registrada por el conductor</p>
                            <mat-form-field class="col-md-6" appearance="outline">
                                <mat-label>Inicio del descargue</mat-label>
                                <input matInput [disabled]="address.approvalInitialConsignment" readonly
                                    [ngxMatDatetimePicker]="destination_start_j_k" placeholder="Inicio del descargue"
                                    [formControlName]="'startDate_'+j+'_'+k">
                                <mat-datepicker-toggle matSuffix [for]="destination_start_j_k"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #destination_start_j_k [showSeconds]="true">
                                </ngx-mat-datetime-picker>
                            </mat-form-field>
                            <mat-form-field class="col-md-6" appearance="outline">
                                <mat-label>Finalización del descargue</mat-label>
                                <input matInput readonly [ngxMatDatetimePicker]="destination_end_j_k"
                                    placeholder="Finalización del descargue" [formControlName]="'endDate_'+j+'_'+k">
                                <mat-datepicker-toggle matSuffix [for]="destination_end_j_k"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #destination_end_j_k [showSeconds]="true">
                                </ngx-mat-datetime-picker>
                            </mat-form-field>
                            <p class="col-12 text-bold">Por favor confirme la fecha de llegada al descargue</p>
                            <mat-form-field class="col-md-6" appearance="outline">
                                <mat-label>llegada al descargue</mat-label>
                                <input matInput readonly [ngxMatDatetimePicker]="destination_real_j_k"
                                    [min]="form.get('destinations.destination_'+i+'.minDateAllowed_'+j+'_'+k).value"
                                    placeholder="Finalización del descargue" [formControlName]="'realDate_'+j+'_'+k">
                                <mat-datepicker-toggle matSuffix [for]="destination_real_j_k"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #destination_real_j_k [showSeconds]="true">
                                </ngx-mat-datetime-picker>
                                <mat-error
                                    *ngIf="form.get('destinations.destination_'+i+'.realDate_'+j+'_'+k).hasError('required')">
                                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                                </mat-error>
                                <mat-hint>*Recuerda que esta fecha debe ser mayor a la fecha de salida del cargue:
                                    {{formatDate(form.get('destinations.destination_'+i+'.minDateAllowed_'+j+'_'+k).value)}}</mat-hint>
                            </mat-form-field>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div class="button-container">
            <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
                (click)="dialogRef.close()" type="button">
                Cancelar
            </button>
            <!-- Yes -->
            <button mat-raised-button class="button-width-auto bg-color-primary" color="primary" type="submit">
                Guardar
            </button>
        </div>
    </form>
</section>
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Fingerprint } from 'src/app/core/interfaces/fingerprint';
import { ImageFingerprint } from 'src/app/core/interfaces/travel-expense';
import { DateManager } from 'src/app/core/managers/date.manager';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { Global } from 'src/app/core/resources/global';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';

interface Image { path: string };

@Component({
  selector: 'app-image-thumbnail-list',
  templateUrl: './image-thumbnail-list.component.html',
  styleUrls: ['./image-thumbnail-list.component.scss']
})
export class ImageThumbnailListComponent {

  @Input() images: Array<string> = [];
  @Input() imagesWithFingerprint: ImageFingerprint[] = [];
  @ViewChild('thumbnailContainer', { static: false }) thumbnailContainer!: ElementRef;
  public permission = Permission;

  constructor(
    public datePipe: DatePipe,
    public dateFormatPipe: DateFormatPipe,
    private global: Global,
    public utils: Utils,
  ) { }

  public removeImage(index: number): void {
    this.images.splice(index, 1);
  }

  public scrollLeft(): void {
    this.thumbnailContainer.nativeElement.scrollBy({
      left: -100,
      behavior: 'smooth'
    });
  }

  public scrollRight(): void {
    this.thumbnailContainer.nativeElement.scrollBy({
      left: 100,
      behavior: 'smooth'
    });
  }

  public openImageNewTab(url): void {
    window.open(url);
  }

  public handleFileInput(e: Event & { target: { files: FileList } }): void {
    if (e && e.target && e.target['files']) {
      for (let i = 0; i < e.target.files.length; i++) {
        console.log(e.target.files[i]);
      }
    }
  }

  public getFingerprintToolTip(fingerprint: Fingerprint): string {
    return 'Subido por: ' + fingerprint.userName + '\n' + 'Fecha: ' + this.formatDate(fingerprint.date) + '\n' + 'Hora: ' + this.dateFormatPipe.transform(fingerprint.date, 'time');
  }

  private formatDate(date: string): string {
    return this.datePipe.transform(DateManager.stringToDate(date));
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Global } from 'src/app/core/resources/global';
import { Utils } from 'src/app/core/resources/utils';
import { StandardMapComponent } from 'src/app/shared/standard-map/standard-map.component';
import { MenuServiceService } from '../../menu-service/menu-service.service';
import { Router } from '@angular/router';
import { Fmt } from 'src/app/core/messages/fmt';
import { Subscription } from 'rxjs';
import { Patterns } from 'src/app/core/resources/patterns';
import { Destination } from 'src/app/core/interfaces/destination';
import { DateManager } from 'src/app/core/managers/date.manager';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { Address } from 'src/app/core/interfaces/address';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { City } from 'src/app/core/interfaces/city';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Cargo } from 'src/app/core/interfaces/cargo';

interface DestinationForm {
  destinationCity?: City;
  destinationDate?: Date;
  isInternational?: boolean;
  destinationAddresses?: DestinationAddressForm[];
}
interface DestinationAddressForm {
  address: {
    address: string;
    location: {
      lat: number;
      lng: number;
    };
  };
  time: string;
  timeType?: string;
  cellphone?: string;
  email?: string;
  guidesRecipients?: Address[];
}
@Component({
  selector: 'app-destination-download-service-form',
  templateUrl: './destination-download-service-form.component.html',
  styleUrls: ['./destination-download-service-form.component.scss']
})
export class DestinationDownloadServiceFormComponent implements OnInit {
  permission = Permission;
  destinationsControls = new FormArray([]);
  initialAddresses: { initialValue: string }[][] = [];
  indexOpened: { indexDestination: number, indexAddress: number } = { indexDestination: 0, indexAddress: 0 };

  readonly baseDestinationCityOptions: OptionsAutocomplete = {
    title: 'Destino (Municipio o Ciudad)',
    appearance: 'outline',
    requireFullCity: true
  };
  destinationCityOptions: OptionsAutocomplete[] = [];
  destinationAddressOptions = {
    title: 'Dirección de destino',
    appearance: 'outline',
    required: true,
    showXMark: true
  };
  formValidate = '';
  originDate: Date = new Date();
  lastOriginHour: string = '';

  formSubscription: Subscription = new Subscription();

  constructor(
    public utils: Utils,
    private fb: FormBuilder,
    private global: Global,
    public menuService: MenuServiceService,
    private router: Router,
    private patterns: Patterns,
    private snackBarService: SnackBarService,
    private dateFormatPipe: DateFormatPipe,
    private permissionRole: PermissionRole
  ) { }

  /**
   * @description Initializes the component
   */
  ngOnInit() {
    this.setLocalValues();
    this.setSubscriptions();
  }

  private setLocalValues() {
    this.destinationCityOptions = [];
    this.initialAddresses = [];
    this.indexOpened = { indexDestination: 0, indexAddress: 0 };
    const dateLoad = this.utils.getNestedValue(this.menuService.form.value, 'dateLoad');
    const originAddresses = this.utils.getNestedValue(this.menuService.form.value, 'cargoFeature.uploadDownload.origin.addresses');
    this.lastOriginHour = originAddresses && originAddresses.length ? originAddresses[originAddresses.length - 1].time : '';
    this.originDate = dateLoad ? DateManager.stringToDate(dateLoad) : new Date();

    this.destinationsControls.clear();
    this.addDestinationForm();
    const destinations: Destination[] = this.utils.getNestedValue(this.menuService.form.value, 'cargoFeature.uploadDownload.destination');
    if (!destinations || !destinations.length) return;

    destinations.forEach((destination: Destination, index: number) => {
      if (index) this.addDestinationForm();
      const initialDestination = [];
      const destinationControl = this.destinationsControls.at(index);
      destinationControl.get('isInternational').setValue(destination && destination.country && destination.country.code !== 'CO');
      destinationControl.get('destinationCity').setValue(destination && destination.name ? destination.name : '');
      destinationControl.get('destinationDate').setValue(DateManager.stringToDate(destination.downloadDate));
      const destinationCity = this.utils.getNestedValue(destination, 'name');
      this.destinationCityOptions[index] = {
        ...this.baseDestinationCityOptions,
        initialValue: destinationCity ? destinationCity : '',
        isInternational: !!destinationControl.get('isInternational').value
      };
      this.destinationsControls.at(index).get('destinationCity').setValue({
        name: destinationCity ? destinationCity : '',
        id: destination && destination.municipalityCode ? destination.municipalityCode : '',
        country: destination && destination.country && destination.country.name ? destination.country.name : 'Colombia',
        code: destination && destination.country && destination.country.code ? destination.country.code : 'CO',
      });
      if (destination.addresses && destination.addresses.length) {
        this.getDestinationAddresses(index).clear();
        destination.addresses.forEach((address: AddressCargo, indexAddress: number) => {
          this.addDestinationAddressForm(index);
          initialDestination.push({ initialValue: address && address.address ? address.address : '' });
          const destinationAddressControl = this.getDestinationAddresses(index).at(indexAddress);
          destinationAddressControl.get('address').setValue({
            address: address && address.address ? address.address : '',
            location: {
              lat: address && address.location && address.location.lat ? address.location.lat : 0,
              lng: address && address.location && address.location.lng ? address.location.lng : 0
            }
          });
          destinationAddressControl.get('time').setValue(address && address.time ? address.time : '00:01');
          destinationAddressControl.get('cellphone').setValue(address && address.cellphone ? address.cellphone : '');
          destinationAddressControl.get('email').setValue(address && address.email ? address.email : '');
          this.getDestinationAddressGuidesRecipients(index, indexAddress).clear();
          if (address.guidesRecipients && address.guidesRecipients.length) {
            address.guidesRecipients.forEach((guideRecipient: Address, indexGuideRecipient: number) => {
              this.addGuideRecipientForm(index, indexAddress);
              const destinationAddressGuideRecipientControl = this.getDestinationAddressGuidesRecipients(index, indexAddress).at(indexGuideRecipient);
              destinationAddressGuideRecipientControl.get('name').setValue(guideRecipient && guideRecipient.name ? guideRecipient.name : '');
              destinationAddressGuideRecipientControl.get('email').setValue(guideRecipient && guideRecipient.email ? guideRecipient.email : '');
              destinationAddressGuideRecipientControl.get('phone').setValue(guideRecipient && guideRecipient.phone ? guideRecipient.phone : '');
            });
          }
        });
      }
      this.initialAddresses.push(initialDestination);
    });
    this.checkPermissionsToEdit();
  }

  private setSubscriptions() {
    this.formSubscription = this.menuService.form.valueChanges.subscribe(() => this.setLocalValues());
  }

  private checkPermissionsToEdit() {
    if (!this.canEditDestinationDownloadService) {
      this.formValidate = 'disable';
      this.destinationsControls.controls.forEach((destination, index) => {
        destination.get('destinationCity').disable();
        destination.get('destinationDate').disable();
        destination.get('isInternational').disable();
        this.getDestinationAddresses(index).controls.forEach((address, indexAddress) => {
          address.get('address').disable();
          address.get('time').disable();
          address.get('cellphone').disable();
          address.get('email').disable();
          this.getDestinationAddressGuidesRecipients(index, indexAddress).controls.forEach(guideRecipient => {
            guideRecipient.get('name').disable();
            guideRecipient.get('email').disable();
            guideRecipient.get('phone').disable();
          });
        });
      });
    }
  }

  get canSeeDestinationDownloadService(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.showLoadDownloadModule
    );
  }

  get canEditDestinationDownloadService(): boolean {
    return this.canSeeDestinationDownloadService && this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.editLoadDownloadModule
    );
  }

  setIndexOpened(indexDestination: number, indexAddress: number) {
    const isSame = indexAddress === this.indexOpened.indexAddress && indexDestination === this.indexOpened.indexDestination;
    this.indexOpened = { indexDestination, indexAddress: isSame ? null : indexAddress };
  }

  addDestinationForm(setIndexOpened: boolean = false) {
    this.destinationsControls.push(this.createDestinationGroup());
    this.destinationCityOptions.push({
      ...this.baseDestinationCityOptions,
      isInternational: false
    });
    if (setIndexOpened) this.setIndexOpened(this.destinationsControls.controls.length - 1, 0);
  }

  removeDestinationForm(index: number) {
    this.destinationsControls.removeAt(index);
    this.destinationCityOptions.splice(index, 1);
  }

  getDestinationAddresses(index: number): FormArray {
    return this.destinationsControls.at(index).get('destinationAddresses') as FormArray;
  }

  addDestinationAddressForm(index: number, setIndexOpened: boolean = false) {
    this.getDestinationAddresses(index).push(this.createDestinationAddressGroup());
    if (setIndexOpened) this.setIndexOpened(index, this.getDestinationAddresses(index).controls.length - 1);
  }

  removeDestinationAddressForm(index: number, addressIndex: number) {
    this.getDestinationAddresses(index).removeAt(addressIndex);
    if (index === this.indexOpened.indexDestination && addressIndex === this.indexOpened.indexAddress)
      this.setIndexOpened(index, this.getDestinationAddresses(index).controls.length - 1);
  }

  getDestinationAddressGuidesRecipients(index: number, addressIndex: number): FormArray {
    return this.getDestinationAddresses(index).at(addressIndex).get('guidesRecipients') as FormArray;
  }

  addGuideRecipientForm(index: number, addressIndex: number) {
    this.getDestinationAddressGuidesRecipients(index, addressIndex).push(this.createGuideRecipientGroup());
  }

  removeGuideRecipientForm(index: number, addressIndex: number, recipientIndex: number) {
    this.getDestinationAddressGuidesRecipients(index, addressIndex).removeAt(recipientIndex);
  }

  private createDestinationGroup(): FormGroup {
    return this.fb.group({
      destinationCity: ['', Validators.required],
      destinationDate: ['', Validators.required],
      isInternational: [false],
      destinationAddresses: this.fb.array([this.createDestinationAddressGroup()]),
    });
  }

  private createDestinationAddressGroup(): FormGroup {
    return this.fb.group({
      address: ['', Validators.required],
      time: ['00:01', Validators.required],
      cellphone: ['', [Validators.minLength(10), Validators.pattern(this.patterns.ONLY_NUMBERS.source)]],
      email: ['', [Validators.email]],
      guidesRecipients: this.fb.array([])
    });
  }

  private createGuideRecipientGroup(): FormGroup {
    return this.fb.group({
      name: [''],
      email: ['', [Validators.email]],
      phone: ['', [Validators.minLength(10), Validators.pattern(this.patterns.ONLY_NUMBERS.source)]]
    });
  }

  onChangeInternational(indexDestination: number) {
    this.destinationsControls.at(indexDestination).get('destinationCity').setValue('');
    this.getDestinationAddresses(indexDestination).controls.forEach(addressDestination => {
      addressDestination.get('address').setValue('');
    });
    this.destinationCityOptions[indexDestination] = {
      ...this.baseDestinationCityOptions,
      isInternational: !!this.destinationsControls.at(indexDestination).get('isInternational').value,
      initialValue: ''
    };
  }

  getAddressInitialValue(indexDestination: number, indexAddress: number): string {
    const address = this.utils.getNestedValue(this.menuService.form.value, `cargoFeature.uploadDownload.destination.${indexDestination}.addresses.${indexAddress}.address`);
    return address ? address : '';
  }

  onSelectAddress($event: "" | { address: string, location: { lat: number, lng: number } }, indexDestination: number, indexAddress: number) {
    if ($event === "") this.getDestinationAddresses(indexDestination).at(indexAddress).get('address').setValue('');
    else this.getDestinationAddresses(indexDestination).at(indexAddress).get('address').setValue($event);
    if ($event !== "") {
      if (!this.initialAddresses[indexDestination]) this.initialAddresses[indexDestination] = [];
      if (!this.initialAddresses[indexDestination][indexAddress]) this.initialAddresses[indexDestination][indexAddress] = { initialValue: '' };
      this.initialAddresses[indexDestination][indexAddress].initialValue = $event.address;
    }
  }

  get isInternationalTrip(): boolean {
    return this.utils.getNestedValue(this.menuService.form.value, 'cargoModel.tripType.name') === 'Internacional';
  }

  backToPreviousStep() {
    this.router.navigate([Fmt.string(this.menuService.steps.origin.url, this.menuService.cargo.consecutive)]);
  }

  private isValidEstimatedTime(): { isValid: boolean, error: string } {
    let currentTime: Date = this.lastOriginHour
      ? DateManager.stringToDate(`${DateManager.setStartOfDay(this.originDate).toISOString().split('T')[0]}T${this.lastOriginHour}`, 'YYYY-MM-DDTHH:mm:ssZ')
      : this.originDate;
    for (let i = 0; i < this.destinationsControls.controls.length; i++) {
      const destinationDate = DateManager.dateToString(this.destinationsControls.controls[i].get('destinationDate').value);
      for (let j = 0; j < this.getDestinationAddresses(i).controls.length; j++) {
        const address = this.getDestinationAddresses(i).controls[j];
        const time = address.get('time').value;
        const timeDate = DateManager.stringToDate(`${destinationDate.substring(0, 10)} ${time} -0500`);
        if (DateManager.isSameOrBefore(timeDate, currentTime, 'minutes'))
          return {
            isValid: false, error:
              !i && !j
                ? `La hora de la dirección ${j + 1} (destino ${i + 1}) es anterior o igual a la última hora de origen`
                : `La hora de la dirección ${j + 1} (destino ${i + 1}) es anterior o igual a la dirección previa`
          };
        currentTime = timeDate;
      }
    }
    return { isValid: true, error: '' };
  }

  async saveAndContinue() {
    const isValidEstimatedTime = this.isValidEstimatedTime();
    if (!isValidEstimatedTime.isValid)
      return this.snackBarService.openSnackBar(isValidEstimatedTime.error, undefined, 'alert');
    const formValues = this.utils.clone(this.destinationsControls.value);
    formValues.forEach((destination: DestinationForm, index: number) => {
      if (!this.menuService.form.get('cargoFeature.uploadDownload.destination').at(index))
        this.menuService.form.get('cargoFeature.uploadDownload.destination').push(this.menuService.createDestinationGroup());
      const formDestination = this.menuService.form.get('cargoFeature.uploadDownload.destination').at(index);
      const localDestinationCity = destination.destinationCity;
      formDestination.get('name').setValue(
        localDestinationCity && localDestinationCity.name ? localDestinationCity.name : ''
      );
      formDestination.get('municipalityCode').setValue(
        localDestinationCity && localDestinationCity.id ? localDestinationCity.id : ''
      );
      formDestination.get('country.name').setValue(
        localDestinationCity && localDestinationCity['country'] ? localDestinationCity['country'] : 'Colombia'
      );
      formDestination.get('country.code').setValue(
        localDestinationCity && localDestinationCity['code'] ? localDestinationCity['code'] : 'CO'
      );
      formDestination.get('downloadDate').setValue(DateManager.utcTransform(DateManager.setStartOfDay(destination.destinationDate)));

      destination.destinationAddresses.forEach((localAddress: DestinationAddressForm, indexAddress: number) => {
        if (!this.menuService.form.get(`cargoFeature.uploadDownload.destination.${index}.addresses`).at(indexAddress))
          this.menuService.form.get(`cargoFeature.uploadDownload.destination.${index}.addresses`).push(this.menuService.createDestinationAddressGroup());
        const formAddress = this.menuService.form.get(`cargoFeature.uploadDownload.destination.${index}.addresses`).at(indexAddress);
        formAddress.get('address').setValue(localAddress && localAddress.address && localAddress.address.address ? localAddress.address.address : '');
        formAddress.get('location.lat').setValue(localAddress && localAddress.address && localAddress.address.location && localAddress.address.location.lat ? localAddress.address.location.lat : 0);
        formAddress.get('location.lng').setValue(localAddress && localAddress.address && localAddress.address.location && localAddress.address.location.lng ? localAddress.address.location.lng : 0);
        formAddress.get('cellphone').setValue(localAddress && localAddress.cellphone ? localAddress.cellphone : '');
        formAddress.get('email').setValue(localAddress && localAddress.email ? localAddress.email : '');
        formAddress.get('time').setValue(localAddress && localAddress.time ? localAddress.time : '00:01');
        formAddress.get('timeType').setValue('');
        formAddress.get('guidesRecipients').clear();
        const localGuidesRecipients = localAddress && localAddress.guidesRecipients ? localAddress.guidesRecipients : [];
        localGuidesRecipients.forEach((localGuideRecipient: Address, indexGuideRecipient: number) => {
          formAddress.get('guidesRecipients').push(this.menuService.createGuideRecipientGroup());
          const formGuideRecipient = formAddress.get('guidesRecipients').at(indexGuideRecipient);
          formGuideRecipient.get('name').setValue(localGuideRecipient && localGuideRecipient.name ? localGuideRecipient.name : '');
          formGuideRecipient.get('email').setValue(localGuideRecipient && localGuideRecipient.email ? localGuideRecipient.email : '');
          formGuideRecipient.get('phone').setValue(localGuideRecipient && localGuideRecipient.phone ? localGuideRecipient.phone : '');
        });
        formAddress.get('createGuide').setValue(!!localGuidesRecipients.length);
        formAddress.get('numberOfGuides').setValue(localGuidesRecipients.length);
      });
    });

    const formDestination = this.menuService.form.get(`cargoFeature.uploadDownload.destination`) as FormArray;
    for (let i = formDestination.controls.length - 1; i >= 0; i--) {
      if (i >= formValues.length) {
        formDestination.removeAt(i);
        continue;
      }
      const formDestinationAddresses = formDestination.controls[i].get('addresses') as FormArray;
      for (let j = formDestinationAddresses.controls.length - 1; j >= 0; j--) {
        if (j >= formValues[i].destinationAddresses.length) {
          formDestinationAddresses.removeAt(j);
          continue;
        }
        const formDestinationGuidesRecipients = formDestinationAddresses.controls[j].get('guidesRecipients') as FormArray;
        for (let k = formDestinationGuidesRecipients.controls.length - 1; k >= 0; k--) {
          if (k >= formValues[i].destinationAddresses[j].guidesRecipients.length)
            formDestinationGuidesRecipients.removeAt(k);
        }
      }
    }

    const formValue: Cargo = this.menuService.form.value;
    const hasDestinationsEnabledToCheckTripTimes = formValue.cargoFeature.uploadDownload.destination.every(destination => destination.downloadDate &&
      destination.addresses.every(address => !!(address.address && address.location.lat && address.location.lng && address.time)));
    const hasMoreThanOneDestination = formValue.cargoFeature.uploadDownload.destination.length > 1 || formValue.cargoFeature.uploadDownload.destination.some(destination => destination.addresses.length > 1);
    if (hasDestinationsEnabledToCheckTripTimes && (hasMoreThanOneDestination || this.menuService.isValidOrigin().isValid)) {
      const checkTripTimes = await this.menuService.checkTripTimes(formValue, this.menuService.isValidOrigin().isValid, true);
      if (!checkTripTimes.valid)
        return this.snackBarService.openSnackBar(checkTripTimes.error, undefined, 'error');
    }

    this.menuService.updateService().then(() => {
      this.snackBarService.openSnackBar("Servicio actualizado exitosamente");
      this.continueToNextStep();
    }).catch(() => this.snackBarService.openSnackBar('Ocurrió un error al actualizar el servicio', undefined, 'error'));
  }

  continueToNextStep() {
    this.router.navigate([Fmt.string(this.menuService.steps.vehicle.url, this.menuService.cargo.consecutive)]);
  }

  ngOnDestroy() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }
}

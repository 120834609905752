<form [formGroup]="form" (ngSubmit)="onSubmit()" class="cargo__billing px-2 px-md-5">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="cargo__billing__header">
    <h4 class="text-center text-strong">Facturación</h4>
    <hr>
    <div>
      <h5 class="text-center text-strong">Total a facturar ({{cargos.length}}):
        {{cargoListService.getTotalValueRateCargos(cargos) |
        currency:'COP':'code'}}</h5>
      <ul class="cargo__billing__list">
        <li *ngFor="let cargo of cargos;">
          <span class="text-strong">
            Servicio {{cargo.consecutive}}:
          </span>
          <span>
            {{cargoListService.getTotalValueRateCargo(cargo) | currency:'COP':'code'}}
          </span>
          <figure class="icon__bill" *ngIf="hasSiigoIntegration">
            <img src="assets/img/logo_siigo.png">
          </figure>
        </li>
      </ul>
    </div>
  </div>
  <div class="cargo__billing__body">
    <mat-form-field appearance="standard" *ngIf="isBillIntegration">
      <mat-label>Fecha de Vencimiento<span class="asterisk"
          *ngIf="utils.isRequiredField(form.get('dueDate'))">*</span></mat-label>
      <input type="text" matInput [matDatepicker]="datePicker" formControlName="dueDate" (click)="datePicker.open()"
        (dateChange)="onChangeDatePicker($event)" readonly>
      <mat-error *ngIf="form.get('dueDate').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>

    <div *ngIf="isBillIntegration">
      <mat-form-field class="mt-2">
        <mat-label>Seleccione el vendedor</mat-label>
        <mat-select [formControl]="sellerControl">
        <mat-option *ngFor="let billingUser of listBillingUsers"
            [value]="billingUser">{{billingUser.firstName}} {{billingUser.lastName}} <span *ngIf="billingUser?.id === defaultSeller?.id"> (predeterminado) </span></mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="sellerControl && sellerControl.value && sellerControl.value.id && defaultSeller?.id !== sellerControl?.value?.id" (click)="onChangeSeller()">
        <u class="text-bold cursor-pointer color-secondary-dark">Establecer este vendedor como predeterminado</u>
      </div>
      <div 
      *ngIf="messageListBillingUsers" 
      class="alert alert-danger" 
      role="alert">
          {{messageListBillingUsers}}
      </div>
    </div>
    <div>
      <mat-form-field appearance="standard" *ngIf="isBillIntegration">
        <mat-label>Centro de Costo<span class="asterisk"
            *ngIf="utils.isRequiredField(form.get('costCenter'))">*</span></mat-label>
        <input type="text" #inputAutoComplete matInput [formControl]="costCenterControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCostCenterSelected">
          <mat-option *ngFor="let costCenter of listCostCenter | async" [value]="costCenter">
            {{costCenter.name}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="form.get('costCenter').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
      <div *ngIf="!(listCostCenter | async)?.length && !showAllCostCenters?.value && isBillIntegration" class="alert alert-info d-flex flex-column mt-2" role="alert">
        <span>No hay centros de costo asociados a la empresa. <span *ngIf="hasSiigoIntegration; else manualCostCenter" class="text-bold">Si deseas puedes asociar uno (recuerda que debe estar previamente creado en siigo)</span>
        <ng-template #manualCostCenter>
          <span>Si deseas puedes crear uno.</span>
        </ng-template></span>
        <button
        mat-raised-button
        type="button"
        color="primary"
        class="bg-color-primary button-width-auto text-bold cost-center-button mt-2"
        (click)="goToCostCenter()">
          <i class="fas fa-wrench hide-element mr-2"></i>
          <span>Configurar <span class="hide-element">centros de costo</span></span>
        </button>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-between align-items-baseline flex-wrap" *ngIf="isBillIntegration">
      <label class="text-strong">¿Mostrar todos los centros de costo?&nbsp;&nbsp;</label>
      <mat-button-toggle-group #group="matButtonToggleGroup" [formControl]="showAllCostCenters" class="h-auto m-0">
        <mat-button-toggle [value]="true" (change)="toggleListCostCenter()">
          Si
        </mat-button-toggle>
        <mat-button-toggle [value]="false" (change)="toggleListCostCenter()">
          No
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <mat-form-field appearance="standard" *ngIf="isBillIntegration && hasSiigoIntegration">
      <mat-label>Método de pago<span class="asterisk">*</span></mat-label>
      <mat-select formControlName="paymentType">
        <mat-option *ngFor="let payment of paymentTypes" [value]="{code: payment.id}" [disabled]="!payment.active">
          {{payment.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('paymentType').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
    </mat-form-field>

    <mat-card-content *ngIf="isBillIntegration">
      <mat-form-field appearance="standard">
        <mat-label>Observaciones</mat-label>
        <textarea matInput formControlName="observations"></textarea>
      </mat-form-field>
    </mat-card-content>

    <mat-form-field appearance="standard" *ngIf="((isBill && !isBillIntegration) || !isBill) && hasSiigoIntegration">
      <mat-label>
        {{isBill && !isBillIntegration ? 'Ingrese el Nº de Factura' : 'Ingrese el Nº de Comprobante'}}<span
          class="asterisk" *ngIf="utils.isRequiredField(form.get('billingId'))">*</span>
      </mat-label>
      <input matInput formControlName="billingId" type="text">
      <mat-error *ngIf="form.get('billingId').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="w-100 d-flex justify-content-between align-items-baseline flex-wrap mb-2" *ngIf="isBillIntegration">
    <label class="text-strong">¿Desea enviar reporte a la DIAN?&nbsp;&nbsp;</label>
    <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="sendDIAN" class="h-auto">
      <mat-button-toggle [value]="true">
        Si
      </mat-button-toggle>
      <mat-button-toggle [value]="false">
        No
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="w-100 d-flex justify-content-between align-items-baseline flex-wrap mb-2" *ngIf="isBillIntegration">
    <label class="text-strong">¿Desea notificar a los clientes por correo?&nbsp;&nbsp;</label>
    <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="sendEmail" class="h-auto">
      <mat-button-toggle [value]="true">
        Si
      </mat-button-toggle>
      <mat-button-toggle [value]="false">
        No
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="cargo__billing__footer">
    <mat-card-actions class="">
      <button type="button" mat-raised-button class="primary-secondary button-width-auto" color="primary"
        (click)="cancel()">
        Cancelar
      </button>
      <button type="submit" mat-raised-button color="primary" class="button-width-auto">
        Facturar
      </button>
    </mat-card-actions>
  </div>
</form>
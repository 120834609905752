<div class="compliment-list px-1 px-sm-4 py-2">
    <div class="d-flex flex-wrap flex-column-reverse flex-md-row mt-4">
        <mat-form-field appearance="outline" class="col-md-6 col-lg-4">
            <mat-label class="text-bold">Tipo de viaje</mat-label>
            <mat-select [formControl]="complimentsFilter" multiple>
                <mat-option *ngFor="let type of tripTypes" [value]="type.value">
                    {{type.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="col-md-6 col-lg-8 d-flex justify-content-end mb-2" *ngIf="canEditCompliments">
            <button mat-raised-button type="button" color="primary" class="bg-color-primary"
                (click)="createCompliment()">
                <i class="fas fa-plus-circle cursor-pointer"></i>
                <span>Crear requisito <span class="d-none d-md-inline"> de cumplido</span></span>
            </button>
        </div>
    </div>

    <div class="d-flex flex-column mt-2">
        <div *ngFor="let compliment of filteredCompliments; let i = index;"
            [ngClass]="{'disabled-card':!compliment?.state}"
            class="compliment-card d-flex flex-wrap align-items-center mt-2 py-4 px-2 px-md-4">
            <div class="col-sm-1" *ngIf="canEditCompliments">
                <mat-checkbox [checked]="!!compliment.state" (change)="onChangeState($event, compliment)"
                    [matTooltip]="compliment?.state ? 'Desactivar requisito': 'Activar requisito'"></mat-checkbox>
            </div>
            <div class="col-sm-10 d-flex flex-wrap align-items-center px-0">
                <div class="col-sm-6 col-xl-3">
                    <p class="m-0">Tipo de viaje</p>
                    <p class="text-bold mb-0 text-break">{{compliment?.tripTypes?.length ?
                        getJoinedTripTypes(compliment) :
                        '-'}}</p>
                </div>
                <div class="col-sm-6 col-xl-3">
                    <p class="m-0">Requisito</p>
                    <p class="text-bold mb-0 text-break">{{compliment?.title}}</p>
                </div>
                <div class="col-sm-6 col-xl-3">
                    <p class="m-0">Requiere documento físico</p>
                    <p class="text-bold mb-0 text-break">{{compliment?.requirePhysicalDocument ? 'Si': 'No'}}</p>
                </div>
                <div class=" col-sm-6 col-xl-3">
                    <p class="m-0">Ejemplo</p>
                    <p class="text-bold mb-0 text-break color-secondary-dark" (click)="getComplimentFile(compliment)"
                        [ngClass]="{'cursor-pointer': compliment?.example?.length}">
                        {{compliment?.example?.length ? compliment.example[0]: '-'}}
                        <i class="fas fa-eye" *ngIf="compliment?.example?.length"></i>
                    </p>
                </div>
            </div>

            <div class="d-flex justify-content-end col-sm-1" *ngIf="canEditCompliments">
                <i (click)="editCompliment(compliment)" matTooltip="Editar requisito" *ngIf="compliment?.state"
                    class="m-0 mr-sm-4 fas fa-pencil-alt color-primary cursor-pointer"></i>
            </div>

        </div>
        <div *ngIf="!filteredCompliments?.length" class="container_no_results">
            <div class="icon"><i class="fas fa-calendar-times"></i></div>
            <h4 class="text-bold text-center">
                {{complimentsList?.length
                ? 'No hay requisitos de cumplidos que coincidan con la búsqueda'
                : 'No se han registrado requisitos de cumplido'}}
            </h4>
        </div>
    </div>

</div>
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { GuideNovelty } from 'src/app/core/interfaces/guideNovelty';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CompaniesService } from '../list-companies.service';
import { Observable } from 'rxjs';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';

@Component({
  selector: 'app-guide-novelty-form',
  templateUrl: './guide-novelty-form.component.html',
  styleUrls: ['./guide-novelty-form.component.scss']
})
export class GuideNoveltyFormComponent implements OnInit {
  form: FormGroup;
  permission = Permission;

  constructor(
    public dialogRef: MatDialogRef<GuideNoveltyFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      companyId: string;
      isPrincipalCompany: boolean;
      novelty?: GuideNovelty;
      noveltyList?: GuideNovelty[];
      title: string;
      confirmBtn: string;
    },
    private snackBarService: SnackBarService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private companiesService: CompaniesService,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  private validateRepeatedName(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return new Promise(resolve => {
        if (!control.value || !this.data.noveltyList || !this.data.noveltyList.length)
          return resolve(null);

        const normalizedInputValue = control.value.replace(/\s+/g, '').toLowerCase();
        const exists = this.data.noveltyList.some(novelty => {
          const normalizedName = novelty.name.replace(/\s+/g, '').toLowerCase();
          return (!this.data.novelty || (this.data.novelty && this.data.novelty.name.replace(/\s+/g, '').toLowerCase() !== normalizedName)) &&
            normalizedName === normalizedInputValue;
        });

        resolve(exists ? { repeatedName: true } : null);
      });
    };
  }

  private initForm() {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      type: new FormControl('Normal', Validators.required),
      visibleForRecipient: new FormControl(false, Validators.required),
      global: new FormControl(false, Validators.required),
    });
    this.form.get('name').setAsyncValidators([this.validateRepeatedName()]);
    if (this.data && this.data.novelty)
      this.form.patchValue(this.data.novelty);
  }



  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      if (this.form.get('name').hasError('repeatedName'))
        return this.snackBarService.openSnackBar('Ya existe una novedad con este nombre', undefined, 'alert');

      if (this.utils.errorMessagesCustomized(this.form.get('name'), 'nombre')) return;
      return this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
    }

    let newNovelty: GuideNovelty = this.form.value;
    newNovelty['companyId'] = this.data.companyId;
    if (this.data && this.data.novelty) {
      if (!this.data.novelty.id) return this.snackBarService.openSnackBar('No se pudo actualizar la novedad', undefined, 'error');
      newNovelty['id'] = this.data.novelty.id;
      this.updateNovelty(newNovelty);
    } else
      this.createNovelty(newNovelty);
  }

  private createNovelty(newNovelty: GuideNovelty) {
    this.spinner.show();
    this.companiesService.createGuideNovelty([newNovelty]).subscribe(
      (response) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Novedad creada correctamente', undefined, 'success');
        this.dialogRef.close({ state: true });
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    );
  }

  private updateNovelty(newNovelty: GuideNovelty) {
    this.spinner.show();
    this.companiesService.updateGuideNovelty(newNovelty, newNovelty.id).subscribe(
      (response) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Novedad actualizada correctamente', undefined, 'success');
        this.dialogRef.close({ state: true });
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Global } from 'src/app/core/resources/global';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-evidence-viewer',
  templateUrl: './evidence-viewer.component.html',
  styleUrls: ['./evidence-viewer.component.scss']
})
export class EvidenceViewerComponent {
  evidences: { url: string, type: 'image' | 'pdf' }[];
  currentIndex: number;

  constructor(
    public utils: Utils,
    private global: Global,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      evidences: { url: string, type: 'image' | 'pdf' }[],
      index: number
    },
    public dialogRef: MatDialogRef<EvidenceViewerComponent>,
  ) {
    if (data.evidences && data.evidences.length) {
      this.evidences = data.evidences.map(evidence => ({
        url: evidence && evidence.url ? evidence.url : this.global.pathNoImage,
        type: evidence && evidence.type && evidence.url ? evidence.type : 'image'
      }));
      this.currentIndex = typeof data.index === 'number' ? data.index : 0;
    } else {
      this.snackBarService.openSnackBar('Ocurrió un error al cargar las evidencias', undefined, 'error');
      this.dialogRef.close();
    }
  }

  next() {
    if (this.currentIndex < this.evidences.length - 1)
      this.currentIndex++;
    else
      this.currentIndex = 0;
  }

  previous() {
    if (this.currentIndex > 0)
      this.currentIndex--;
    else
      this.currentIndex = this.evidences.length - 1;
  }

  getCurrentEvidence(): { url: string, type: 'image' | 'pdf' } | undefined {
    return this.evidences[this.currentIndex];
  }

  onErrorRenderPdf($event: Event) {
    ($event.target as HTMLEmbedElement).src = this.global.pathNoImage;
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Destination } from 'src/app/core/interfaces/destination';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AuthService } from 'src/app/core/services/authentication.service';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
import { ServiceType } from 'src/app/core/interfaces/serviceType';
import { CargoManager } from 'src/app/core/managers/cargo.manager';


@Component({
  selector: 'app-cargo-renew-dates',
  templateUrl: './cargo-renew-dates.component.html',
  styleUrls: ['./cargo-renew-dates.component.scss']
})
export class CargoRenewDatesComponent implements OnInit {

  public dateLoad: string;
  public dateDownload: string[] = [];
  public destinationList: any[] = [];
  public cargo: Cargo;
  configDateLoad = {
    min: new Date(),
    max: null
  };
  minDateDownload = null;
  private isDuplicate = false;

  constructor(
    private manualCreationCargoService: ManualCreationCargoService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<CargoRenewDatesComponent>,
    public dialog: MatDialog,
    public utils: Utils,
    public cargoManager: CargoManager,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: { cargo: Cargo, isDuplicate?: boolean },
    private router: Router,
  ) { }

  ngOnInit() {
    this.cargo = this.data.cargo;
    this.isDuplicate = !!this.data.isDuplicate;
    this.destinationList = this.cargo.cargoFeature.uploadDownload.destination;
  }

  async renewDates() {
    if (!this.dateLoad || !this.dateDownload.every(date => this.utils.isDefined(date))) {
      this.snackBarService.openSnackBar('Hay fechas sin llenar', undefined, 'alert');
      return;
    }
    let newCargo: Cargo = this.utils.clone(this.cargo);
    newCargo.dateLoad = this.dateLoad;
    newCargo.cargoFeature.uploadDownload.origin.addresses.forEach(address => delete address.idTracking);
    newCargo.cargoFeature.uploadDownload.destination.forEach((destination, index) => {
      destination.downloadDate = this.dateDownload[index]
      destination.addresses.forEach(address => delete address.idTracking);
    });
    this.spinner.show();
    this.manualCreationCargoService.processCargo(await this.clearDataCargoToRenew(newCargo), this.cargo.id)
      .then(async (data: Cargo[]) => {
        this.spinner.hide();
        await this.cargoManager.modalCargoCreation(data, `Tu servicio ha sido renovado con el siguiente consecutivo`);
        this.dialogRef.close();
      })
      .catch((error) => {
        this.spinner.hide();
        this.manualCreationCargoService.showResponseCreateCargo(
          'Ocurrió un error al crear el servicio',
          (Array.isArray(error) ? error : undefined)
        );
      });
  }

  private clearDataCargoToRenew(cargo: Cargo): Cargo {
    let newCargo: Cargo = {
      dateLoad: cargo.dateLoad,
      numberDocumentCreatorLoad: this.authService.getUserSession().information.document,
      idCompany: cargo.idCompany,
      exclusive: cargo.exclusive,
      shippingCost: cargo.shippingCost
    };

    const renewFields = [
      'observation',
      'numberDocumentSender',
      'distancy',
      'estimatedTime',
      'cargoOwner',
      'additionalCosts',
      'seal',
      'container',
      'booking',
      'cargoModel',
      'ministry',
      'escortedVehicle',
      'routePlanId',
      'itineraryId',
      'manifestType'
    ]
    renewFields.forEach(field => {
      if (this.utils.isDefined(this.utils.getNestedValue(cargo, field)))
        newCargo[field] = cargo[field];
    });

    if (!this.utils.isEmpty(cargo.cargoFeature)) {
      newCargo.cargoFeature = cargo.cargoFeature;
      if (!this.utils.isEmpty(cargo.cargoFeature.uploadDownload)) {
        newCargo.cargoFeature.uploadDownload.origin.addresses = cargo.cargoFeature.uploadDownload.origin.addresses.map((address) => {
          if (!this.utils.isEmpty(address.state)) {
            delete address.state;
          }
          return address;
        });
        newCargo.cargoFeature.uploadDownload.destination = cargo.cargoFeature.uploadDownload.destination.map((destination) => {
          destination.addresses.map((address) => {
            if (!this.utils.isEmpty(address.state))
              delete address.state;
            if (!this.utils.isEmpty(address.consignments))
              delete address.consignments;
            if (!this.utils.isEmpty(address.ministryError))
              delete address.ministryError;

            address.guidesId = [];
            return address;
          });
          return destination;
        });
      }
    }
    const serviceType: ServiceType = this.utils.getNestedValue(cargo, 'cargoModel.serviceType');
    if (serviceType && serviceType.id === 'escortServices')
      newCargo.state = CargoStateEnum.CREATED;
    else
      newCargo.state = CargoStateEnum.REQUEST;
    return newCargo;
  }

  onChangeDatePicker($event: any, type: string, index?: number) {
    if (type === "load") {
      this.dateLoad = DateManager.dateToString($event.value, 'YYYY-MM-DD HH:mm ZZ');
    } else {
      this.dateDownload[index] = DateManager.dateToString($event.value, 'YYYY-MM-DD HH:mm ZZ');
    }
  }

  focusDownloadInput(indexDownload: number) {
    if (indexDownload === 0) {
      this.minDateDownload = new Date(this.dateLoad);
    } else {
      this.minDateDownload = this.getMinDateDownload(indexDownload);
    }
  }

  getMinDateDownload(index: number) {
    const lastDate = this.dateDownload[index - 1];
    if (lastDate) {
      return new Date(lastDate);
    } else {
      return {};
    }
  }

}

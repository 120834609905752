<div id="menu-service" class="h-100 col-md-12 mb-md-0 mb-12">
    <div class="row h-100 position-relative">
        <i class="fas fa-bars cursor-pointer d-block d-xl-none" (click)="showMenu = true" matTooltip="Mostrar menú"></i>
        <!-- Left panel with steps -->
        <div class="container-steps" [ngClass]="{'show': showMenu}">
            <div class="d-flex justify-content-end d-block d-xl-none">
                <i class="fas fa-times cursor-pointer" (click)="showMenu = false" matTooltip="Ocultar menú"></i>
            </div>
            <div *ngFor="let step of stepsObject">
                <div class="row step" (click)="setRoute(step?.routeUrl)"
                    [ngClass]="{'step-active': currentStep === step?.routeName, 'd-none': !step?.isRequired}"
                    [matTooltip]="step?.isValid?.error" matTooltipClass="tooltip__error" matTooltipPosition="right">
                    <div class="col-md-12 mb-md-0 mb-12 container-step">
                        <i class="fa-lg" [ngClass]="{
                            'fas fa-check-circle': step?.isValid?.isValid,
                            'far fa-circle': !step?.isValid?.isValid
                        }"></i>
                        <div class="step-title">{{step?.title}}</div>
                    </div>
                </div>
                <div class="row sub-steps" *ngIf="currentStep === step?.routeName && step?.subSteps?.length">
                    <ul>
                        <li *ngFor="let subStep of step?.subSteps" [matTooltip]="subStep?.isValid?.error"
                            matTooltipClass="tooltip__error" matTooltipPosition="right"
                            [ngClass]="{'d-none': !subStep?.isRequired}">
                            <i [ngClass]="{
                                'fas fa-check-circle': subStep?.isValid?.isValid,
                                'far fa-circle': !subStep?.isValid?.isValid
                            }"></i>
                            <span class="label-sub-step">{{subStep?.title}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="d-block d-xl-none background-menu" *ngIf="showMenu" (click)="showMenu = false"></div>
        <!-- Right panel content -->
        <div class="container-data">
            <div class="container-data-step px-0 px-md-4 py-4" *ngIf="menuService?.form && cargo">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
import { DatePipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { Global } from 'src/app/core/resources/global';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { Patterns } from 'src/app/core/resources/patterns';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { GoogleService } from 'src/app/core/services/google.service';
import { VehicleBodyWorkTypeComponent } from '../manual-creation-cargo/components/vehicle-body-work-type/vehicle-body-work-type.component';
import { AutocompleteThirdpartyAddressComponent } from 'src/app/shared/autocomplete-thirdparty-address/autocomplete-thirdparty-address.component';
import { PlanningRouteService } from 'src/app/core/services/planning-route.service';
import { PlanningRoute } from 'src/app/core/interfaces/planning-route';
import { CargoService } from 'src/app/core/services/cargo.service';
import { CargoItineraryNearnessIndex } from 'src/app/core/interfaces/route-itinerary';
import { AuthService } from 'src/app/core/services/authentication.service';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';

@Component({
  selector: 'app-create-escort-service',
  templateUrl: './create-escort-service.component.html',
  styleUrls: ['./create-escort-service.component.scss']
})
export class CreateEscortServiceComponent implements OnInit {

  @ViewChild(VehicleBodyWorkTypeComponent, { static: false }) bodyworkTypeComponent: VehicleBodyWorkTypeComponent;
  @ViewChildren(AutocompleteThirdpartyAddressComponent) thirdPartyAddressesComponent: QueryList<AutocompleteThirdpartyAddressComponent>;
  permission = Permission;
  form: FormGroup;
  formAux: FormGroup;

  optionsCompany = {
    title: 'Nombre del cliente',
    optionAll: false,
    appearance: 'outline'
  };

  readonly timeList: Array<{ title: string, value: string }> = this.utils.getListTime();
  readonly minDate = new Date();
  originCityOptions = {
    title: 'Origen (Municipio o Ciudad)',
    appearance: 'outline',
    requireFullCity: true
  };
  originThirdPartyOptions = {
    title: "Remitente",
    appearance: 'outline'
  };
  destinationCityOptions = {
    title: 'Destino (Municipio o Ciudad)',
    appearance: 'outline',
    requireFullCity: true
  };
  destinationThirdPartyOptions = {
    title: "Destinatario",
    appearance: 'outline'
  };

  readonly tripTypes: { name: TRIP_TYPES }[] = [
    { name: TRIP_TYPES.IMPORT },
    { name: TRIP_TYPES.EXPORT },
    { name: TRIP_TYPES.NATIONAL },
    { name: TRIP_TYPES.URBAN },
    { name: TRIP_TYPES.LAST_MILE }
  ];
  tripTypesEnabled: { name: TRIP_TYPES }[];
  readonly riskProfiles = this.global.riskProfiles;

  documentTypes: CatalogItem[] = this.utils.clone(this.global.documenTypes);
  optionsTypeVehicle: OptionsAutocomplete = {
    showAutocomplete: true
  };
  optionsBodyWorkType: OptionsAutocomplete = {
    title: 'Tipo de carrocería'
  };

  formValidate = '';
  subscriptions: Subscription = new Subscription();

  constructor(
    private manualCreationCargoService: ManualCreationCargoService,
    private cargoManager: CargoManager,
    private snackBarService: SnackBarService,
    private planningRouteService: PlanningRouteService,
    private cargoService: CargoService,
    private spinner: NgxSpinnerService,
    private googleService: GoogleService,
    public datePipe: DatePipe,
    public dialogMaterial: MatDialog,
    public utils: Utils,
    private global: Global,
    private patterns: Patterns,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.documentTypes.splice(2, 1);
    this.initForm();
    this.setSubscriptions();
  }

  private initForm() {
    this.form = new FormGroup({
      dateLoad: new FormControl(''),
      ministry: new FormControl(false),
      idCompany: new FormControl(''),
      numberDocumentSender: new FormControl(),
      cargoModel: new FormGroup({
        tripType: new FormGroup({
          name: new FormControl('', Validators.required)
        }),
        serviceType: new FormGroup({
          id: new FormControl("escortServices"),
          name: new FormControl('Servicio de escolta'),
          cost: new FormControl(0)
        }),
      }),
      riskProfile: new FormGroup({
        name: new FormControl(),
        id: new FormControl()
      }),
      cargoFeature: new FormGroup({
        vehicleType: new FormGroup({
          name: new FormControl(''),
          quantity: new FormControl(1),
          bodyWorkType: new FormGroup({
            name: new FormControl(''),
          }),
        }),
        uploadDownload: new FormGroup({
          origin: new FormGroup({
            name: new FormControl(''),
            municipalityCode: new FormControl(''),
            country: new FormGroup({
              name: new FormControl("Colombia"),
              code: new FormControl("CO")
            }),
            addresses: new FormArray([new FormGroup({
              id: new FormControl("0"),
              address: new FormControl(''),
              location: new FormGroup({
                lat: new FormControl(''),
                lng: new FormControl('')
              }),
              time: new FormControl(''),
              thirdPartyConsignment: new FormGroup({
                documentType: new FormControl(''),
                documentNumber: new FormControl(''),
                name: new FormControl(''),
                branchOffice: new FormControl(''),
              }),
            })]),
          }),
          destination: new FormArray([this.getFormDestination()]),
        }),
      }),
      licensePlate: new FormControl(),
      shippingCost: new FormGroup({
        rate: new FormControl(0, [Validators.required, Validators.min(1)]),
        totalCost: new FormControl(0),
        freightCost: new FormControl(0),
        reteftePercentage: new FormControl(1),
        reteicaPercentage: new FormControl(0),
        advancePercentage: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
        paymentTime: new FormControl(null, [Validators.required, Validators.min(0)]),
      }),
      escortedVehicle: new FormGroup({
        licensePlate: new FormControl('', Validators.required),
        brand: new FormControl(),
        color: new FormControl(),
        vehicleType: new FormControl(),
        container: new FormControl('', Validators.pattern(this.patterns.CONTAINER_FORMAT_FULL.source)),
        driver: new FormGroup({
          documentTypeId: new FormControl("1"),
          documentTypeName: new FormControl("Cédula de ciudadanía"),
          document: new FormControl(),
          name: new FormControl('', Validators.required),
          phoneNumber: new FormControl('', Validators.required)
        }),
      }),
      cargoOwner: new FormGroup({
        documentType: new FormControl("3"),
        documentNumber: new FormControl(),
        name: new FormControl()
      }),
      observation: new FormControl(),
      observationDriver: new FormControl()
    });
    this.formAux = new FormGroup({
      company: new FormControl('', Validators.required),
      originCity: new FormControl('', Validators.required),
      originDate: new FormControl('', Validators.required),
      originTime: new FormControl('', Validators.required),
      originTimeType: new FormControl('AM', Validators.required),
      originThirdParty: new FormControl('', Validators.required),
      originThirdPartyConsignment: new FormControl('', Validators.required),
      destinations: new FormArray([this.getFormAuxDestination()]),
      riskProfile: new FormControl(),
      //Vehículo a escoltar
      vehicleType: new FormControl(),
      documentType: new FormControl({
        id: "1",
        name: "Cédula de ciudadanía"
      }),
      //Vehículo escolta
      escortVehicleType: new FormControl('', Validators.required),
      escortBodyWorkType: new FormControl('', Validators.required),
      cost: new FormControl(0, [Validators.required, Validators.min(0)]),
    })
    this.tripTypesEnabled = this.utils.clone(this.tripTypes);
  }

  private setSubscriptions() {
    const originSub = this.formAux.get('originCity').valueChanges.subscribe(() => this.checkTripTypes());
    const destinationsSub = this.formAux.get('destinations').valueChanges.subscribe(() => this.checkTripTypes());
    this.subscriptions.add(originSub);
    this.subscriptions.add(destinationsSub);
  }

  private getFormDestination(): FormGroup {
    return new FormGroup({
      id: new FormControl("0"),
      name: new FormControl(''),
      municipalityCode: new FormControl(''),
      downloadDate: new FormControl(''),
      country: new FormGroup({
        name: new FormControl("Colombia"),
        code: new FormControl("CO")
      }),
      addresses: new FormArray([this.getFormDestinationAddress()]),
    });
  }

  private getFormDestinationAddress(): FormGroup {
    return new FormGroup({
      id: new FormControl("0"),
      address: new FormControl(''),
      location: new FormGroup({
        lat: new FormControl(),
        lng: new FormControl()
      }),
      time: new FormControl(),
      thirdPartyConsignment: new FormGroup({
        documentType: new FormControl(''),
        documentNumber: new FormControl(''),
        name: new FormControl(''),
        branchOffice: new FormControl(''),
      }),
    });
  }

  private getFormAuxDestination() {
    return new FormGroup({
      destinationCity: new FormControl('', Validators.required),
      destinationDate: new FormControl('', Validators.required),
      destinationAddresses: new FormArray([this.getFormAuxDestinationAddress()]),
    });
  }

  private getFormAuxDestinationAddress() {
    return new FormGroup({
      destinationTime: new FormControl('', Validators.required),
      destinationTimeType: new FormControl('AM', Validators.required),
      destinationThirdParty: new FormControl('', Validators.required),
      destinationThirdPartyConsignment: new FormControl('', Validators.required),
    });
  }

  addDestination() {
    (this.formAux.get('destinations') as FormArray).push(this.getFormAuxDestination());
    (this.form.get('cargoFeature.uploadDownload.destination') as FormArray).push(this.getFormDestination());
  }

  deleteDestination(index: number) {
    (this.formAux.get('destinations') as FormArray).removeAt(index);
    (this.form.get('cargoFeature.uploadDownload.destination') as FormArray).removeAt(index);
  }

  addDestinationAddress(index: number) {
    ((this.formAux.get('destinations') as FormArray).at(index).get('destinationAddresses') as FormArray).push(this.getFormAuxDestinationAddress());
    ((this.form.get('cargoFeature.uploadDownload.destination') as FormArray).at(index).get('addresses') as FormArray).push(this.getFormDestinationAddress());
  }

  deleteDestinationAddress(destinationIndex: number, index: number) {
    ((this.formAux.get('destinations') as FormArray).at(destinationIndex).get('destinationAddresses') as FormArray).removeAt(index);
    ((this.form.get('cargoFeature.uploadDownload.destination') as FormArray).at(destinationIndex).get('addresses') as FormArray).removeAt(index);
  }

  get getDestinations() {
    return (this.formAux.get('destinations') as FormArray).controls;
  }
  getDestinationAddresses(index: number) {
    return ((this.formAux.get('destinations') as FormArray).at(index).get('destinationAddresses') as FormArray).controls;
  }



  private checkTripTypes() {
    const originCity: { id: string, name: string } = this.formAux.get('originCity').value;
    const destinationCity: { id: string, name: string }[] = this.formAux.get('destinations').value.map(dest => dest.destinationCity);
    if (!originCity || !originCity.name || !destinationCity || !destinationCity.length || destinationCity.some(dest => !dest || !dest.name)) {
      this.tripTypesEnabled = this.utils.clone(this.tripTypes);
      return;
    }
    if (destinationCity.every(dest => dest.name === originCity.name))
      this.tripTypesEnabled = [
        { name: TRIP_TYPES.IMPORT },
        { name: TRIP_TYPES.EXPORT },
        { name: TRIP_TYPES.URBAN },
        { name: TRIP_TYPES.LAST_MILE }
      ];
    else
      this.tripTypesEnabled = [
        { name: TRIP_TYPES.IMPORT },
        { name: TRIP_TYPES.EXPORT },
        { name: TRIP_TYPES.NATIONAL }
      ];
    if (!this.tripTypesEnabled.some(trip => trip.name === this.form.get('cargoModel.tripType.name').value))
      this.form.get('cargoModel.tripType.name').setValue(null);
  }

  private getMinEndDate(index: number) {
    if (index && this.formAux.value.destinations[index - 1].destinationDate)
      return this.formAux.value.destinations[index - 1].destinationDate;
    if (this.formAux.get('originDate').value)
      return this.formAux.get('originDate').value;
    return this.minDate;
  }

  updateDestinationDates() {
    for (let i = 0; i < this.getDestinations.length; i++) {
      const downloadDate: Date = this.formAux.get(`destinations.${i}.destinationDate`).value;
      if (downloadDate && DateManager.isBefore(downloadDate, this.getMinEndDate(i)))
        this.formAux.get(`destinations.${i}.destinationDate`).setValue(this.getMinEndDate(i));
    }
  }

  checkForm() {
    this.form.markAllAsTouched();
    this.formAux.markAllAsTouched();
    this.formValidate = 'touched';

    const originFields = {
      company: "nombre del cliente",
      originCity: "ciudad de origen",
      originDate: "fecha de origen",
      originTime: "hora de origen",
      originThirdParty: "remitente",
      originThirdPartyConsignment: "dirección de remitente",
    }
    let originError = false;
    for (let field of Object.keys(originFields)) {
      if (this.utils.errorMessagesCustomized(this.formAux.get(field), originFields[field])) {
        originError = true;
        break;
      }
    }
    if (originError) return;

    const destinationFields = {
      destinationCity: "ciudad de destino",
      destinationDate: "fecha de destino",
    }
    const destinationAddressFields = {
      destinationTime: "hora de destino",
      destinationThirdParty: "destinatario",
      destinationThirdPartyConsignment: "dirección de destinatario",
    }
    let destinationError = false;
    for (let i = 0; i < this.getDestinations.length; i++) {
      for (let field of Object.keys(destinationFields)) {
        if (this.utils.errorMessagesCustomized(this.formAux.get(`destinations.${i}.${field}`), `${destinationFields[field]} ${i + 1}`)) {
          destinationError = true;
          break;
        }
      }
      if (destinationError) break;
      for (let j = 0; j < this.getDestinationAddresses(i).length; j++) {
        for (let field of Object.keys(destinationAddressFields)) {
          if (this.utils.errorMessagesCustomized(this.formAux.get(`destinations.${i}.destinationAddresses.${j}.${field}`), `${destinationAddressFields[field]} ${j + 1} del destino ${i + 1}`)) {
            destinationError = true;
            break;
          }
        }
      }
      if (destinationError) break;
    }
    if (destinationError) return;
    const initialHours = parseInt(this.formAux.get('originTime').value.split(':')[0]) + (this.formAux.get('originTimeType').value === "PM" && parseInt(this.formAux.get('originTime').value.split(':')[0]) < 12 ? 12 : 0);
    const initialMinutes = parseInt(this.formAux.get('originTime').value.split(':')[1])
    const initialTime: Date = DateManager.setTimeToDate(
      this.formAux.get('originDate').value,
      initialHours,
      initialMinutes
    );
    let finalTimeError = false;
    let finalHours = [[]];
    let finalMinutes = [[]];
    for (let i = 0; i < this.getDestinations.length; i++) {
      finalHours[i] = [];
      finalMinutes[i] = [];
      for (let j = 0; j < this.getDestinationAddresses(i).length; j++) {
        const hours = parseInt(this.formAux.get(`destinations.${i}.destinationAddresses.${j}.destinationTime`).value.split(':')[0]) + (this.formAux.get(`destinations.${i}.destinationAddresses.${j}.destinationTimeType`).value === "PM" && parseInt(this.formAux.get(`destinations.${i}.destinationAddresses.${j}.destinationTime`).value.split(':')[0]) < 12 ? 12 : 0)
        const minutes = parseInt(this.formAux.get(`destinations.${i}.destinationAddresses.${j}.destinationTime`).value.split(':')[1]);
        const finalTime: Date = DateManager.setTimeToDate(
          this.formAux.get(`destinations.${i}.destinationDate`).value,
          hours,
          minutes
        );
        if (DateManager.isSameOrBefore(finalTime, initialTime, "minutes")) {
          this.snackBarService.openSnackBar(Fmt.string(FormMessages.TIME_INVALID2, `destino ${i + 1} dirección ${j + 1}`), undefined, 'alert');
          finalTimeError = true;
          break;
        }
        finalHours[i].push(hours);
        finalMinutes[i].push(minutes);
      }
      if (finalTimeError) break;
    }
    if (finalTimeError) return;

    if (this.utils.errorMessagesCustomized(this.form.get('cargoModel.tripType.name'), 'tipo de viaje')) return;

    if (this.utils.errorMessagesCustomized(this.form.get('escortedVehicle.licensePlate'), 'placa del vehículo a escoltar')) return;
    if (!this.patterns.GET_REGEX('LICENSE_PLATES').test(this.form.get('escortedVehicle.licensePlate').value)) {
      this.snackBarService.openSnackBar(
        Fmt.string(
          ServiceMessages.LICENSE_PLATE_FORMAT_INCORRECT,
          this.form.get('escortedVehicle.licensePlate').value
        ), undefined, 'alert');
      return;
    }
    if (this.utils.errorMessagesCustomized(this.form.get('escortedVehicle.container'), 'contenedor del vehículo')) return;
    if (this.utils.errorMessagesCustomized(this.form.get('escortedVehicle.driver.name'), 'nombre del conductor a escoltar')) return;
    if (this.utils.errorMessagesCustomized(this.form.get('escortedVehicle.driver.phoneNumber'), 'celular del conductor a escoltar')) return;

    if (this.utils.errorMessagesCustomized(this.formAux.get('escortVehicleType'), 'tipo de vehículo escolta')) return;
    if (this.utils.errorMessagesCustomized(this.formAux.get('escortBodyWorkType'), 'carrocería del vehículo escolta')) return;

    if (this.utils.errorMessagesCustomized(this.formAux.get('cost'), 'flete', null, null, 1)) return;
    if (this.utils.errorMessagesCustomized(this.form.get('shippingCost.rate'), 'tarifa', null, null, 1)) return;

    if (this.utils.errorMessagesCustomized(this.form.get('shippingCost.advancePercentage'), 'porcentaje de anticipo', null, null, 0, 100)) return;
    if (this.utils.errorMessagesCustomized(this.form.get('shippingCost.paymentTime'), 'tiempo de pago del saldo', null, null, 0)) return;

    this.createEscortService(initialHours, initialMinutes, finalHours, finalMinutes);
  }

  private async createEscortService(initialHours: number, initialMinutes: number, finalHours: number[][], finalMinutes: number[][]) {
    const cargo: Cargo = this.form.value;
    //Seteamos todos los campos que requirieron un formControl auxiliar
    //Compañía del servicio
    cargo.idCompany = this.formAux.get('company').value.companyId;
    cargo.cargoOwner.documentNumber = cargo.idCompany;
    cargo.cargoOwner.name = this.formAux.get('company').value.name;
    //Origen
    cargo.dateLoad = this.datePipe.transform(this.formAux.get('originDate').value, 'yyyy-MM-dd HH:mm Z', 'UTC');
    cargo.cargoFeature.uploadDownload.origin.name = this.formAux.get('originCity').value.name;
    cargo.cargoFeature.uploadDownload.origin.municipalityCode = this.formAux.get('originCity').value.id;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].address = this.formAux.get('originThirdPartyConsignment').value.address.address;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].location = this.formAux.get('originThirdPartyConsignment').value.address.location;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].time = `${initialHours <= 9 ? '0' + initialHours : initialHours}:${initialMinutes ? initialMinutes < 10 ? '0' + initialMinutes : initialMinutes : '00'}`;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].thirdPartyConsignment.name = this.formAux.get('originThirdParty').value.name;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].thirdPartyConsignment.branchOffice = this.formAux.get('originThirdPartyConsignment').value.address.id;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].thirdPartyConsignment.documentType = this.formAux.get('originThirdPartyConsignment').value.information.documentTypeId;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].thirdPartyConsignment.documentNumber = this.formAux.get('originThirdPartyConsignment').value.information.document;
    //Destino
    cargo.cargoFeature.uploadDownload.destination.forEach((destination, i) => {
      destination.id = i.toString();
      destination.downloadDate = this.datePipe.transform(this.formAux.get(`destinations.${i}.destinationDate`).value, 'yyyy-MM-dd HH:mm Z', 'UTC');
      destination.name = this.formAux.get(`destinations.${i}.destinationCity`).value.name;
      destination.municipalityCode = this.formAux.get(`destinations.${i}.destinationCity`).value.id;
      destination.addresses.forEach((address, j) => {
        address.id = j.toString();
        address.address = this.formAux.get(`destinations.${i}.destinationAddresses.${j}.destinationThirdPartyConsignment`).value.address.address;
        address.location = this.formAux.get(`destinations.${i}.destinationAddresses.${j}.destinationThirdPartyConsignment`).value.address.location;
        address.time = `${finalHours[i][j] <= 9 ? '0' + finalHours[i][j] : finalHours[i][j]}:${finalMinutes[i][j] ? finalMinutes[i][j] < 10 ? '0' + finalMinutes[i][j] : finalMinutes[i][j] : '00'}`;
        address.thirdPartyConsignment.name = this.formAux.get(`destinations.${i}.destinationAddresses.${j}.destinationThirdParty`).value.name;
        address.thirdPartyConsignment.branchOffice = this.formAux.get(`destinations.${i}.destinationAddresses.${j}.destinationThirdPartyConsignment`).value.address.id;
        address.thirdPartyConsignment.documentType = this.formAux.get(`destinations.${i}.destinationAddresses.${j}.destinationThirdPartyConsignment`).value.information.documentTypeId;
        address.thirdPartyConsignment.documentNumber = this.formAux.get(`destinations.${i}.destinationAddresses.${j}.destinationThirdPartyConsignment`).value.information.document;
      });
    });
    //Perfil de riesgo
    cargo.riskProfile = this.formAux.get('riskProfile').value;
    //Vehículo a escoltar
    if (this.formAux.get('vehicleType').value && this.formAux.get('vehicleType').value.name)
      cargo.escortedVehicle.vehicleType = this.formAux.get('vehicleType').value.name;
    if (this.formAux.get('documentType').value && this.formAux.get('documentType').value.id) {
      cargo.escortedVehicle.driver.documentTypeId = this.formAux.get('documentType').value.id;
      cargo.escortedVehicle.driver.documentTypeName = this.formAux.get('documentType').value.name;
    }
    //Vehículo escolta
    cargo.cargoFeature.vehicleType.name = this.formAux.get('escortVehicleType').value.name;
    cargo.cargoFeature.vehicleType.bodyWorkType.name = this.formAux.get('escortBodyWorkType').value.name;

    //Flete
    cargo.shippingCost.freightCost = this.formAux.get('cost').value;
    cargo.shippingCost.totalCost = this.formAux.get('cost').value;

    //Reteica
    cargo.shippingCost.reteicaPercentage = this.formAux.get('originCity').value.reteica ? this.formAux.get('originCity').value.reteica : 0;
    cargo.shippingCost.reteicaValue = Math.floor(cargo.shippingCost.totalCost * cargo.shippingCost.reteicaPercentage) / 100;

    cargo['numberDocumentCreatorLoad'] = this.authService.getUserSession().information.document.toString();
    cargo.state = CargoStateEnum.CREATED;
    try {
      let { cargoDistancy, cargoEstimatedTime } = await this.googleService.getRouteDataCargo(cargo);
      if (cargoDistancy) cargo['distancy'] = cargoDistancy;
      if (cargoEstimatedTime) cargo['estimatedTime'] = cargoEstimatedTime;
    } catch (error) {
      console.error(error);
    }
    try {
      const validTimes = await this.cargoManager.checkTripTimes(cargo).toPromise();
      if (!validTimes || !validTimes.valid) {
        let message = 'Los tiempos estimados entre las direcciones son imposibles de cumplir.';
        if (validTimes && validTimes.min && validTimes.address) {
          message = `Para llegar a la dirección ${validTimes.address} se requieren al menos `;
          const duration = DateManager.durationFormat(validTimes.min, 'seconds');
          if (duration.hours) message += `${duration.hours} horas `;
          if (duration.minutes) message += `${duration.minutes} minutos `;
          if (duration.seconds) message += `${duration.seconds} segundos `;
        }
        return this.snackBarService.openSnackBar(message, undefined, 'error');
      }
    } catch (error) {
      return this.snackBarService.openSnackBar('Los tiempos estimados entre las direcciones son imposibles de cumplir.', undefined, 'error');
    }
    this.spinner.show();
    this.manualCreationCargoService.createRenewCargo(cargo).subscribe({
      next: async (success: Cargo[]) => {
        if (success && success[0]) {
          await this.checkPlanningRoute(success[0]);
          this.spinner.hide();
          await this.cargoManager.modalCargoCreation(success, `Tu servicio ha sido creado exitosamente con el consecutivo`);
          this.initForm();
          this.cleanValues();
        }
        else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        this.manualCreationCargoService.showResponseCreateCargo(
          'Ocurrió un error al crear la solicitud de servicio',
          error
        );
      },
    });
  }

  private async checkPlanningRoute(cargo: Cargo) {
    let route: PlanningRoute;
    try { route = await this.planningRouteService.findRouteByCargo(cargo) } catch (e) { }
    const noRouteOrItineraries: boolean = !route || !route.id || !route.itineraries || !route.itineraries.length;
    const itinerariesIndexes: { id: string, indexes?: CargoItineraryNearnessIndex[] }[] = route && route.itineraries && route.itineraries.length
      ? route.itineraries.map(itinerary => { if (itinerary.active) return { id: itinerary.id, indexes: this.planningRouteService.getCargoNearnessIndexes(cargo, itinerary) } })
      : [];
    let bestItinerary: { id: string, indexes?: CargoItineraryNearnessIndex[] } | null = itinerariesIndexes.length
      ? itinerariesIndexes.find(itinerary => itinerary && itinerary.indexes && itinerary.indexes.length && itinerary.indexes.every(index => index && index.index >= 0.7))
      : null;
    if (noRouteOrItineraries || !bestItinerary) {
      try {
        const body = await this.planningRouteService.createRouteItineraryFromCargo(cargo);
        if (typeof body === 'string') return;
        let newItinerary = await this.planningRouteService.createRouteItinerary(body).toPromise();
        bestItinerary = { id: newItinerary.id };
        route = await this.planningRouteService.findRouteByCargo(cargo);
      } catch (error) { }
    }
    if (route && route.id)
      await this.updatePlanningRoute(cargo, route, bestItinerary);
  }

  private async updatePlanningRoute(cargo: Cargo, route: PlanningRoute, bestItinerary: { id: string }) {
    try {
      const body = {
        cargoId: cargo.id,
        routePlanId: route.id,
        itineraryId: bestItinerary.id
      };
      await this.planningRouteService.setCargoRouteAndItinerary([body]).toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  private cleanValues() {
    this.optionsCompany['initialNit'] = '';
    this.optionsCompany = { ...this.optionsCompany };
    this.originCityOptions['initialValue'] = '';
    this.originCityOptions = { ...this.originCityOptions };
    this.originThirdPartyOptions['initialValue'] = '';
    this.originThirdPartyOptions = { ...this.originThirdPartyOptions };
    this.destinationCityOptions['initialValue'] = '';
    this.destinationCityOptions = { ...this.destinationCityOptions };
    this.destinationThirdPartyOptions['initialValue'] = '';
    this.destinationThirdPartyOptions = { ...this.destinationThirdPartyOptions };
    this.optionsTypeVehicle['initialValue'] = '';
    this.optionsTypeVehicle = { ...this.optionsTypeVehicle };
    this.bodyworkTypeComponent.bodyWorkTypeCtrl.setValue('');
    this.thirdPartyAddressesComponent.forEach(comp => comp.formControlThirdPartyAddress.setValue(''));
    //Clean errors
    this.initForm();
    this.formValidate = 'untouched';
  }

  ngOnDestroy() {
    if (this.subscriptions) this.subscriptions.unsubscribe();
  }
}


<form [formGroup]="menuService?.form" (ngSubmit)="saveAndContinue()" class="px-0 px-sm-3">
    <div class="container-fluid">
        <div class="row" *ngIf="canSeeOriginLoadService">
            <p class="col-12 my-3 text-bold">
                Origen
            </p>
            <app-autocomplete-city [inputFormControl]="originControl" [options]="originCityOptions"
                [validate]="formValidate"
                [ngClass]="{'col-md-6': !isInternationalTrip, 'col-md-12': isInternationalTrip}">
            </app-autocomplete-city>
            <div class="col-md-6 pb-3 pb-md-0" *ngIf="isInternationalTrip">
                <label class="text-strong">¿Es Internacional?&nbsp;&nbsp;</label>
                <mat-button-toggle-group [formControl]="isInternationalControl" #group="matButtonToggleGroup">
                    <mat-button-toggle [value]="true" (change)="onChangeInternational()">
                        Si
                    </mat-button-toggle>
                    <mat-button-toggle [value]="false" (change)="onChangeInternational()">
                        No
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>
                    Fecha estimada {{menuService?.isEscortService() ? 'de llegada' : 'de cargue'}}
                    <span class="asterisk">*</span>
                </mat-label>
                <input matInput [min]="loadDateOptions.min" [matDatepicker]="pickerLoadDate"
                    [formControl]="loadDateControl" name="loadDate" (click)="pickerLoadDate.open()" readonly>
                <mat-datepicker-toggle matSuffix [for]="pickerLoadDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerLoadDate></mat-datepicker>
                <mat-error *ngIf="loadDateControl.errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>

            <div class="alert alert-warning col-12 text-center" role="alert" *ngIf="showErrorReteica">
                <i class="fas fa-exclamation-circle mr-3"></i>
                {{reteicaError}}
            </div>
            <p class="col-12 my-3 text-bold">
                Direcciones
            </p>
            <div class="col-12 row align-items-baseline" *ngFor="let address of originAddresses.controls; let i=index">
                <div class="col-12 d-flex justify-content-between align-items-center">
                    <p class="mb-2 text-bold">Direccion {{i + 1}}</p>
                    <i class="fas fa-times cursor-pointer" matTooltip="Eliminar parada"
                        *ngIf="i && canEditOriginLoadService" (click)="removeSenderAddress(i)"></i>
                </div>
                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Hora estimada <span class="asterisk">*</span></mat-label>
                    <input matInput [formControl]="address.get('time')" type="time">
                    <mat-error *ngIf="address.get('time').errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>
                        Celular de contacto
                        <span class="optional">(opcional)</span>
                    </mat-label>
                    <input matInput onlyNumbersInt type="tel" [formControl]="address.get('cellphone')">
                    <mat-error *ngIf="address.get('cellphone').errors?.minlength">
                        {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'celular', 10) }}
                    </mat-error>
                </mat-form-field>
                <app-autocomplete-address class="col-md-12" [appearance]="originAddressOptions.appearance"
                    [title]="originAddressOptions.title" [required]="originAddressOptions.required" [addLatLong]="true"
                    [value]="initialAddresses[i]?.initialValue" [simpleAddress]="true"
                    [disabled]="!canEditOriginLoadService" [internationalCode]="originControl.value?.code"
                    (emitToParent)="onSelectAddress($event, i)">
                </app-autocomplete-address>

            </div>

            <p class="text-bold font-secondary-dark pl-2 cursor-pointer" (click)="addAddressForm()"
                *ngIf="canEditOriginLoadService">
                <i class="fas fa-plus-circle"></i>
                <span>Agregar otra dirección</span>
            </p>
        </div>
        <div *ngIf="!canSeeOriginLoadService" class="no-permission-container">
            <i class="fas fa-exclamation-circle fa-lg m-2"></i>
            <h4 class="text-bold">No tienes permiso para acceder a esta información. Contacta a tu administrador para
                solicitar acceso.</h4>
        </div>
        <div class="button-container mt-4">
            <button mat-raised-button color="primary" class="button-width-auto primary-secondary button-small"
                [ngClass]="{'warn': true}" type="button" (click)="backToPreviousStep()">
                <span class="mat-button-wrapper">Atras</span>
            </button>
            <button type="submit" class="mx-2" mat-raised-button color="accent" *ngIf="canEditOriginLoadService">
                Guardar y Continuar
            </button>
            <button type="button" class="mx-2" mat-raised-button color="accent" *ngIf="!canEditOriginLoadService"
                (click)="continueToNextStep()">
                Continuar
            </button>
        </div>
    </div>
</form>
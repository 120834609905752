<form [formGroup]="form" class="modal-body wrapper-modal">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>

    <h3 class="title text-break text-bold mx-4 mt-3 mb-4 text-center">
        Inscripción de cuentas de vehículos
    </h3>
    <p class="mx-2 mx-md-5">
        Seleccione un tipo de cuenta por cada vehículo. Tenga en cuenta que,
        algunos vehículos no son aptos para ciertos tipos de cuenta.
    </p>

    <section *ngFor="let vehicle of form?.controls"
        class="row mx-2 vehicle-item p-2 justify-content-center align-items-center">
        <h5 class="col-md-3 px-0 text-bold text-left text-md-right">{{vehicle?.get('licensePlate')?.value}}</h5>
        <mat-form-field class="col-md-6 inscription-form-field" appearance="outline"
            *ngIf="options[vehicle?.get('licensePlate')?.value]?.length, else noOptions">
            <mat-label>Tipo de cuenta<span class="asterisk">*</span></mat-label>
            <mat-select [formControl]="vehicle.get('accountType')">
                <mat-option *ngFor="let option of options[vehicle.get('licensePlate').value]" [value]="option.option">
                    {{option.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <ng-template #noOptions>
            <p class="col-md-6 color-red text-bold mb-0">
                Este vehículo no es apto para inscripción de cuentas.
            </p>
        </ng-template>
    </section>
    <div class="button-container mt-3">
        <button mat-raised-button class="primary-secondary button-width-auto" color="secondary-dark"
            (click)="dialogRef.close()">
            {{areVehiclesToInscribe ? 'Cancelar' : 'Cerrar'}}
        </button>
        <button mat-raised-button *ngIf="areVehiclesToInscribe" color="primary" class="bg-color-primary"
            (click)="onSubmit()">
            Inscribir cuentas
        </button>
    </div>
</form>